import { makeStyles } from '@material-ui/core';
import { primaryFont } from '../../../shared/theme';

const useStyle = makeStyles(theme => ({
  root: {
    position: 'relative'
  }
}));

export default useStyle;
