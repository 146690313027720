import { makeStyles } from '@material-ui/core';
import { primaryFont } from '../../../shared/theme';

const useStyle = makeStyles(theme => ({
  root: {
    position: 'relative'
  },
  wrapp: {},
  title: {
    padding: '0 40px 20px',
    borderBottom: `1px solid #E6E6E6`
  },
  listBusiness: {
    display: 'flex',
    flexDirection: 'column',
    padding: '0 40px',
    marginTop: 20,
    marginBottom: 40
  },
  businessItem: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    padding: `15px 0`,
    borderBottom: `1px solid #F2F2F2`,
    cursor: 'pointer',
    transition: 'all .35s',
    '&:last-child': {
      borderBottom: 'none'
    }
  },
  businessItemDefault: {
    '&:hover': {
      // backgroundColor: theme.palette.color.white3
    },
    '&:hover h6': {
      color: theme.palette.color.yellow
    }
  },
  businessItemActive: {
    // backgroundColor: theme.palette.color.white3,
    '& h6': {
      color: theme.palette.color.yellow
    }
  },
  businessItemAdd: {
    '&:hover': {
      // backgroundColor: theme.palette.color.white2
    },
    '&:hover svg': {
      color: theme.palette.color.yellow
    },
    '&:hover h6': {
      color: theme.palette.color.yellow
    }
  },
  thumbBusiness: {
    width: 60,
    height: 60,
    borderRadius: '50%'
  },
  imgThumb: {
    display: 'block',
    maxWidth: '100%',
    height: '100%',
    width: '100%',
    borderRadius: '50%'
  },
  addBussiness: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    border: '1px dashed #E6E6E6',
    borderRadius: '50%'
  },
  iconAdd: {
    fontSize: 25,
    color: theme.palette.color.gray
  },
  descBusiness: {
    paddingLeft: 15,
    color: theme.palette.color.black1,
    fontFamily: primaryFont
  }
}));

export default useStyle;
