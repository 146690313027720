import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import clsx from 'clsx';
import MoreHorizIcon from '@material-ui/icons/MoreHoriz';
import { Typography } from '@material-ui/core';
import { Fade } from '@material-ui/core';
import { checkLocation } from '../../../utils/checkLocation';
import { typeWallet } from '../../../shared/constant/const';
import EdditWalletVodafone from '../EdditWalletVodafone';
import { useStyleWallet, useStyleModal } from './style';
import EdditWalletDigicel from '../EdditWalletDigicel';

const WalletItem = props => {
  const mainClass = useStyleWallet();
  const classModal = useStyleModal();
  const { t } = useTranslation();

  const [openMoreOpt, setOpenMoreOpt] = useState(false);
  const [
    isShowModalEditVodafoneWallet,
    setShowModalEditVodafoneWallet
  ] = useState(false);
  const [
    isShowModalEditDigicelWallet,
    setShowModalEditDigicelWallet
  ] = useState(false);

  const { service: walletType, ID: phoneNumber } = props.data;
  const classTypeWallet =
    walletType === typeWallet.digicel
      ? mainClass.walletRed
      : mainClass.walletBlue;
  const classWalletItem =
    walletType === typeWallet.digicel
      ? mainClass.walletItem
      : mainClass.walletVondafone;
  const classWalletItemTitle =
    walletType === typeWallet.digicel
      ? mainClass.walletItemTitle
      : mainClass.walletItemTitleVondafone;
  const classWalletItemContent =
    walletType === typeWallet.digicel
      ? mainClass.walletItemContent
      : mainClass.walletItemContentVondafone;
  const textTitle =
    walletType === typeWallet.digicel ? 'Digicel' : 'Vodafone M-Vatu';

  const handleOpenMoreOpt = e => {
    e.preventDefault();
    setOpenMoreOpt(prevData => !prevData);
  };

  const handleEdit = ev => {
    ev.preventDefault();

    if (walletType === typeWallet.digicel) {
      setShowModalEditDigicelWallet(true);
      setOpenMoreOpt(false);
      return;
    }
    setShowModalEditVodafoneWallet(true);
    setOpenMoreOpt(false);
  };

  const handleDelete = ev => {
    ev.preventDefault();
    setOpenMoreOpt(false);
  };

  const closeModalEditWalletVodafone = () => {
    setShowModalEditVodafoneWallet(false);
  };

  const closeModalEditWalletDigicel = () => {
    setShowModalEditDigicelWallet(false);
  };

  return (
    <div className={clsx(classWalletItem, classTypeWallet)}>
      <div className={clsx(mainClass.walletItemhr, mainClass.walletItemHead)}>
        <Typography variant="h5" className={classWalletItemTitle}>
          {textTitle}
        </Typography>
        <MoreHorizIcon
          className={mainClass.moreOpt}
          onClick={handleOpenMoreOpt}
        />
      </div>
      <div className={clsx(mainClass.walletItemhr, classWalletItemContent)}>
        <span>{t('mystore.label.Phone number')}</span>
        <span>
          ({checkLocation().defaultCode.value}) {phoneNumber}
        </span>
      </div>
      <Fade in={openMoreOpt}>
        <div className={mainClass.moreOptControl}>
          <p onClick={handleEdit}>{t('action.Edit')}</p>
          {/*<p onClick={handleDelete}>Delete</p>*/}
        </div>
      </Fade>
      {walletType === typeWallet.vodafone && isShowModalEditVodafoneWallet ? (
        <EdditWalletVodafone
          openModel={isShowModalEditVodafoneWallet}
          handleClose={closeModalEditWalletVodafone}
          classOutSide={classModal}
          phoneNumber={phoneNumber}
        />
      ) : null}
      {walletType === typeWallet.digicel && isShowModalEditDigicelWallet ? (
        <EdditWalletDigicel
          openModel={isShowModalEditDigicelWallet}
          handleClose={closeModalEditWalletDigicel}
          classOutSide={classModal}
          phoneNumber={phoneNumber}
        />
      ) : null}
    </div>
  );
};

export default WalletItem;
