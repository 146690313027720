import React, { useState } from 'react';
import {
  BottomNavigation,
  BottomNavigationAction,
  Tooltip,
  makeStyles
} from '@material-ui/core';
import { connect } from 'react-redux';
import Notification from '../layout/notification';
import { getUserProfile } from 'maua-redux-core/selectors/user';
import ModalCustom from '../ModalCustom';
import SwitchBusinessModal from '../Mystore/SwitchBusinessModal';
import ConfirmLogout from '../layout/header/ConfirmLogout';
import { history } from 'src/routes';
import { logout } from 'maua-redux-core/actions/user';
import { actDestroyProfile } from 'src/store/actions/user';
import { destroyCredentials } from 'src/shared/services/token';
import ExitToAppIcon from '@material-ui/icons/ExitToApp';
import LoopIcon from '@material-ui/icons/Loop';
const useStyle = makeStyles(theme => ({
  root: {
    position: 'fixed',
    bottom: 0,
    left: 0,
    right: 0,
    background: '#fdfcf9',
    zIndex: 99
  },
  appendItem: {
    padding: theme.spacing(1, 2),
    color: '#CECECE',
    fontWeight: 'bold',
    cursor: 'pointer',
    width: '100%',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    height: 48,
    display: 'flex',
    alignItems: 'center',
    '&:hover': {
      color: theme.palette.primary.main,
      background: theme.palette.background.hover
    }
  },
  controlIcon: {
    display: 'flex',
    height: '100%',
    width: 70,
    alignItems: 'center',
    justifyContent: 'center',
    cursor: 'pointer'
  }
}));

function MobileNavigator({ profile, onDestroyProfile, onLogout }) {
  const classes = useStyle();
  const [value, setValue] = useState(null);
  const [open, setOpen] = useState(false);
  const [openSwitchBusiness, setOpenSwitchBusiness] = useState(false);
  const isLogin = !!profile._id;
  const handleChange = (event, newValue) => {
    if (newValue === 0) {
      setOpen(true);
    }

    if (newValue === 1) {
      setOpenSwitchBusiness(true);
    }
  };

  const handleLogOut = () => {
    onDestroyProfile();
    onLogout();
    destroyCredentials();
    setOpen(false);
    history.replace('/');
  };

  const handleCloseSwitchBusinessModal = () => {
    setValue(null);
    setOpenSwitchBusiness(false);
  };

  const handleCloseLogoutModal = () => {
    setOpen(false);
    setValue(null);
  };

  return (
    <BottomNavigation
      value={value}
      onChange={handleChange}
      className={classes.root}>
      <BottomNavigationAction
        icon={
          <Tooltip title="Log out">
            <ExitToAppIcon fontSize="large" />
          </Tooltip>
        }
      />
      <BottomNavigationAction
        icon={
          <Tooltip title="Switch Business">
            <LoopIcon fontSize="large" />
          </Tooltip>
        }
      />
      <BottomNavigationAction icon={<Notification isLogin={isLogin} />} />
      <ModalCustom openModel={openSwitchBusiness}>
        <SwitchBusinessModal
          openModel={openSwitchBusiness}
          handleClose={handleCloseSwitchBusinessModal}
        />
      </ModalCustom>

      <ModalCustom openModel={open} handleClose={handleCloseLogoutModal}>
        <ConfirmLogout setOpen={setOpen} handleLogOut={handleLogOut} />
      </ModalCustom>
    </BottomNavigation>
  );
}

const mapStateToProps = state => {
  return {
    profile: getUserProfile(state)
  };
};

const mapDispatchToProps = dispatch => {
  return {
    onLogout: () => {
      dispatch(logout(null, null));
    },
    onDestroyProfile: () => {
      dispatch(actDestroyProfile());
    }
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(MobileNavigator);
