import { makeStyles } from '@material-ui/core';
import { primaryFont } from '../../../shared/theme';

const useStyle = makeStyles(theme => ({
  wrapp: {
    marginTop: 20
  },
  identity: {
    paddingLeft: 30,
    paddingRight: 30,
    color: theme.palette.color.orange,
    fontFamily: primaryFont,
    fontWeight: 600
  },
  wrappBtn: {
    margin: theme.spacing(1.5, 3)
  },
  divider: {
    margin: theme.spacing(2, 3, 0),
    paddingBottom: 20,
    borderBottom: '1px solid #E6E6E6'
  },
  errorField: {
    color: theme.palette.color.red,
    fontFamily: primaryFont
  }
}));
export default useStyle;
