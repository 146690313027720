import React from 'react';
import { makeStyles } from '@material-ui/core';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import clsx from 'clsx';

const useStyle = makeStyles(theme => ({
  closeButton: {
    position: 'absolute',
    top: 10,
    right: 10,
    cursor: 'pointer',
    color: theme.palette.grey[500]
  }
}));

const CloseButton = props => {
  const classes = useStyle();
  return (
    <IconButton
      aria-label="close"
      className={clsx(classes.closeButton, props.className)}
      {...props}>
      <CloseIcon />
    </IconButton>
  );
};
export default CloseButton;
