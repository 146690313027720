import React, { useState } from 'react';
import { connect } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { Typography } from '@material-ui/core';
import clsx from 'clsx';
import { actToggleNeedreloadMyStore } from '../../../store/actions/mystores';
import { getDataEmployeeAddStaff } from '../../../store/selectors/mystores';
import { toastMessage, TOAST_TYPE } from '../../../utils/toastify-notify';
import avatarDefault from '../../../assets/img/product-item-default.png';
import SelectCustom from '../../../components/FormFeild/SelectCustom';
import selectedIconImg from '../../../assets/img/icons/selected.png';
import { actSetLoading } from '../../../store/actions/loading';
import { addEmployee } from 'maua-redux-core/actions/store';
import ModalCustom from '../../ModalCustom';
import {
  actToggleModalFoundStaff,
  actToggleModalEnterStaffPhone
} from '../../../store/actions/modal';
import { useStyle } from './style';
import Button from '../../Button';

const ModalFoundStaff = props => {
  const mainClass = useStyle();
  const [choiseStaff, setChoiseStaff] = useState(false);
  const { dataEmployeeAddStaff, idStore } = props;
  const [roleStaff, setRoleStaff] = useState('');
  const dataEmployee = dataEmployeeAddStaff[0];
  const { avatar, firstName, lastName, _id: idUser } = dataEmployee;
  const { t } = useTranslation();

  const handleOnSubmit = async () => {
    try {
      props.setLoading({ status: true });
      const params = {
        store: idStore,
        employee: idUser
        //roleAction: roleStaff
      };

      await props.addEmployeeRequest(params);

      props.handleClose();
      props.toggleModalFoundStaff(false);
      props.toggleNeedReloadMyStore(true);

      toastMessage(
        TOAST_TYPE.SUCCESS,
        `${t('toastMessage.success.content.Add Employee Success')}`
      );
    } catch (error) {
      toastMessage(TOAST_TYPE.ERROR, error.message);
    } finally {
      props.setLoading({ status: false });
    }
  };
  const handleSelectStaff = () => {
    setChoiseStaff(!choiseStaff);
  };

  const handleUseAnotherNumber = () => {
    props.handleClose();
    props.toggleModalEnterStaffPhone(true);
    choiseStaff && setChoiseStaff(false);
  };

  const styleStaffItem = choiseStaff
    ? mainClass.staffItemActive
    : mainClass.staffItemDefault;
  const styleSelectIcon = choiseStaff
    ? mainClass.selectIconActive
    : mainClass.selectIconDefault;

  const avatarUrl = avatar && Array.isArray(avatar) ? avatar[0] : avatar;

  return (
    <div className={mainClass.root}>
      <ModalCustom {...props}>
        <Typography variant="h6" className={mainClass.title}>
          {t('mystore.editStore.title.Add Staff')}
        </Typography>
        <Typography variant="h4" className={mainClass.titlePrimary}>
          {t('mystore.staff.popup.title.We found your staff')}
        </Typography>
        <div
          className={clsx(mainClass.staffITem, styleStaffItem)}
          onClick={handleSelectStaff}>
          <img
            src={avatarUrl || avatarDefault}
            alt="Your Staff"
            className={mainClass.staffPicture}
          />
          <div className={mainClass.detail}>
            <Typography variant="h5" className={mainClass.name}>
              {`${firstName} ${lastName}`}
            </Typography>
            <p className={mainClass.typePerson}>
              {t('mystore.staff.position.Staff')}
            </p>
          </div>
          <img
            src={selectedIconImg}
            alt="selected"
            className={clsx(mainClass.selectIcon, styleSelectIcon)}
          />
        </div>
        {/* <div className={clsx(mainClass.staffRole, styleStaffItem)}>
          <SelectCustom
            label={t('mystore.staff.position.Role')}
            width="100%"
            options={[
              { label: 'Staff level 2', value: 'vendor_2' },
              { label: 'Staff level 3', value: 'vendor_3' },
              { label: 'Staff level 4', value: 'vendor_4' }
            ]}
            value={roleStaff}
            onChange={e => {
              let role = e.target.value;
              setRoleStaff(role);
            }}
          />
        </div> */}
        <div className={mainClass.wrappBtn}>
          <Button
            typeBtn="yellow"
            type="button"
            onClick={handleOnSubmit}
            //disabled={!choiseStaff || roleStaff === ''}
            disabled={!choiseStaff}>
            {t('mystore.editStore.title.Add Staff')}
          </Button>
          <p
            className={mainClass.useAnotherNumber}
            role="button"
            onClick={handleUseAnotherNumber}>
            {t('action.Use another number')}
          </p>
        </div>
      </ModalCustom>
    </div>
  );
};

const mapStateToProps = state => {
  return {
    dataEmployeeAddStaff: getDataEmployeeAddStaff(state)
  };
};
const mapDispatchToProps = dispatch => {
  return {
    setLoading: status => dispatch(actSetLoading(status)),
    toggleModalFoundStaff: value => dispatch(actToggleModalFoundStaff(value)),
    toggleNeedReloadMyStore: value =>
      dispatch(actToggleNeedreloadMyStore(value)),
    addEmployeeRequest: params => dispatch(addEmployee(params, null)),
    toggleModalEnterStaffPhone: value =>
      dispatch(actToggleModalEnterStaffPhone(value))
  };
};
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ModalFoundStaff);
