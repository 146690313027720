import { makeStyles } from '@material-ui/core';

const useStyle = makeStyles(theme => ({
  header: {
    backgroundColor: theme.palette.color.white,
    borderBottom: `1px solid #F2F2F2`,
    boxShadow: `-4px 0px 10px rgba(0, 0, 0, 0.07)`
  },

  contentHeader: {
    display: 'flex',
    alignItems: 'center',
    flexWrap: 'wrap',
    padding: theme.spacing(1.6, 0),
    justifyContent: 'space-between'
  },
  btnBack: {
    marginTop: 16,
    width: 148,
    height: 40,
    color: '#000'
  }
}));

export default useStyle;
