import React from 'react';
import { useTranslation } from 'react-i18next';
import useStyle from './style';
import ProductItem from '../ProductItem';

const ProductService = React.memo(props => {
  const mainClass = useStyle();
  const { products = [] } = props;
  const { t } = useTranslation();

  return (
    <div className={mainClass.root}>
      <h5 className={mainClass.title}>
        {t('order.product.title.Products/Service')} {` (${products.length}) `}
        {t('order.product.title.items')}
      </h5>
      <div className={mainClass.productList}>
        {products.map((item, i) => (
          <ProductItem key={i} {...item} />
        ))}
      </div>
    </div>
  );
});

export default ProductService;
