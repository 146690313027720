import { makeStyles } from '@material-ui/core';
import { secondaryFont, primaryFont } from '../../shared/theme';

const useStyle = makeStyles(theme => ({
  overviewContainer: {
    // maxHeight: 'calc(100vh - 68px)',
    // overflowY: 'auto'
  },
  container: {
    [theme.breakpoints.down('md')]: {
      flexDirection: 'column-reverse'
    }
  },
  innerOverview: {
    paddingTop: 30,
    backgroundColor: 'rgba(247, 247, 247, .5)',
    minHeight: 'calc(100vh - 68px)'
  },
  ratings: {
    marginTop: 30,
    padding: '40px 30px 30px',
    backgroundColor: theme.palette.color.white,
    borderRadius: 15,
    minHeight: 110,
    boxShadow: `0px 10px 20px rgba(0, 0, 0, 0.05)`
  },
  btnLoadmoreWrapp: {
    display: 'flex',
    justifyContent: 'center',
    marginTop: 30,
    marginBottom: 30,
    '& button': {
      maxWidth: 202
    }
  },
  staffsWrapper: {
    marginTop: 30,
    marginBottom: 15,
    backgroundColor: theme.palette.color.white,
    borderRadius: 15,
    padding: `40px 30px`,
    boxShadow: `0px 10px 20px rgba(0, 0, 0, 0.05)`
  },
  staffTitle: {
    margin: 0,
    fontFamily: secondaryFont,
    color: theme.palette.color.black
  },
  staffList: {
    marginTop: 30,
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap'
  },
  staffItem: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-start',
    width: 'calc(100% / 3)',
    marginBottom: 29,
    transition: 'all .35s',
    cursor: 'pointer',
    '&:hover': {
      '& img': {
        transform: 'scale(1.1)'
      },
      '& p:nth-child(2)': {
        color: theme.palette.color.orange
      },
      '& p:nth-child(3)': {
        color: theme.palette.color.gray1
      }
    }
  },
  staffItemEmployee: {
    position: 'relative',
    cursor: 'default',
    '&:hover': {
      '& button': {
        opacity: 1
      }
    }
  },
  imgAvatar: {
    width: 60,
    height: 60,
    display: 'block',
    margin: '0 auto',
    borderRadius: '50%',
    transition: 'all .35s'
  },
  fullName: {
    marginTop: 10,
    marginBottom: 0,
    fontSize: 14,
    color: theme.palette.color.black1,
    fontFamily: primaryFont,
    textAlign: 'center',
    transition: 'all .35s'
  },
  personType: {
    marginTop: 5,
    marginBottom: 0,
    fontSize: 14,
    color: theme.palette.color.gray,
    fontFamily: primaryFont,
    textAlign: 'center',
    transition: 'all .35s'
  },
  addEmployee: {
    alignItems: 'center',
    '&:hover svg': {
      fill: theme.palette.color.orange,
      transform: 'scale(1.1)'
    }
  },
  addEmployeeThumbWrapp: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    width: 60,
    height: 60,
    border: `1px dashed #E6E6E6`,
    borderRadius: '50%'
  },
  closeButton: {
    position: 'absolute',
    top: -25,
    right: 10,
    cursor: 'pointer',
    color: theme.palette.color.orange,
    padding: 5,
    opacity: 0
  }
}));
export default useStyle;

export const useStyleModal = makeStyles(theme => ({
  modalContent: {
    padding: `25px 0 0`
  }
}));

export const useStyleWallet = makeStyles(theme => ({
  wrappWallet: {
    marginTop: 30,
    backgroundColor: theme.palette.color.white,
    borderRadius: '15px',
    padding: 30,
    minHeight: 110,
    boxShadow: `0px 10px 20px rgba(0, 0, 0, 0.05)`
  },
  headerWallet: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between'
  },
  title: {
    color: theme.palette.color.black
  },
  addWallet: {
    color: theme.palette.color.white1,
    transition: 'all .35s',
    cursor: 'pointer',
    '&:hover': {
      transform: 'scale(1.1)',
      color: theme.palette.color.orange
    }
  }
}));
