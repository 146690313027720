import { makeStyles } from '@material-ui/core';
import { secondaryFont, primaryFont } from '../../../shared/theme';

const useStyle = makeStyles(theme => ({
  root: {
    padding: theme.spacing(2, 3, 0)
  },
  productList: {
    display: 'flex',
    marginTop: 4,
    flexDirection: 'column'
  },
  title: {
    margin: 0,
    fontSize: 18,
    fontFamily: secondaryFont,
    color: theme.palette.color.black1,
    fontWeight: 'bold'
  }
}));

export default useStyle;
