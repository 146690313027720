import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Container, Grid, IconButton, Typography } from '@material-ui/core';
import Box from '@material-ui/core/Box';
import Logo from '../../../assets/img/icons/logo.svg';
import ImgPhone from '../../../assets/img/phone.png';
import useStyle from './style';
import Languages from '../../Languages';
import clsx from 'clsx';
import MenuIcon from '@material-ui/icons/Menu';
import CloseIcon from '@material-ui/icons/Close';
import Drawer from 'src/components/Drawer';

function MobileHeader({ hotline, handleOpenMenu, handleCall }) {
  const classes = useStyle();
  const [openHamburger, setOpenHamburger] = useState(false);
  const { t } = useTranslation();
  const handleRedicrectHome = () => {
    window.location.href = `${window.location.origin}/orders/newOrders`;
  };

  const menuItems = [
    { name: t('header.nav.Orders'), url: 'orders/newOrders' },
    { name: t('header.nav.My Store'), url: 'myStore' },
    { name: t('header.nav.Products'), url: 'products' },
    { name: t('header.nav.Dashboard'), url: 'dashboard' },
    { name: t('header.nav.Transaction'), url: 'transaction' }
  ];

  const handleSelectMenuItem = item => {
    item.url && handleOpenMenu(item.url);
    setOpenHamburger(false);
  };

  return (
    <React.Fragment>
      <section className={classes.header}>
        <Container maxWidth="xl" className={classes.container}>
          <Grid container className={classes.headerContainer}>
            {/* HEADER LEFT */}
            <Grid
              style={{ display: 'flex', justifyContent: 'center' }}
              xs={2}
              sm={1}>
              <IconButton
                aria-label="more"
                aria-controls="long-menu"
                aria-haspopup="true"
                className={classes.mobileMenuIcon}
                onClick={() => setOpenHamburger(true)}>
                {openHamburger ? (
                  <CloseIcon fontSize="large" />
                ) : (
                  <MenuIcon fontSize="large" />
                )}
              </IconButton>
              <div component="nav" className={classes.navbar}>
                <Drawer
                  data={menuItems}
                  handleCloseMenu={() => setOpenHamburger(false)}
                  handleSelectItem={handleSelectMenuItem}
                  open={openHamburger}
                />
              </div>
            </Grid>
            <Grid item xs={2} sm={1} className={classes.headerContent}>
              <div onClick={handleRedicrectHome} className={classes.logo}>
                <img src={Logo} className={classes.logo} alt={Logo} />
              </div>
            </Grid>
            {/* HEADER RIGHT */}
            <Grid
              item
              xs={8}
              sm={10}
              className={clsx(classes.headerContent, classes.headerRight)}>
              <div style={{ display: 'flex' }} onClick={handleCall}>
                <Box className={classes.flag}>
                  <img src={ImgPhone} width="70" alt="hotline" />
                </Box>

                <Typography className={classes.phone}>{hotline}</Typography>
              </div>
              <Box display="flex" height="100%">
                <Languages />
              </Box>
            </Grid>
          </Grid>
        </Container>
      </section>
    </React.Fragment>
  );
}

export default MobileHeader;
