import { makeStyles } from '@material-ui/core';
import { primaryFont } from '../../../shared/theme';

const useStyle = makeStyles(theme => ({
  root: {
    width: 400,
    marginTop: 15,
    border: '1px solid #C4C4C4',
    position: 'relative',
    boxShadow: '0px -4px 6px rgba(236, 236, 236, 0.24)',
    borderRadius: '10px',
    backgroundColor: '#fff',
    [theme.breakpoints.down('md')]: {
      width: '90vw'
    }
  },
  timeStamp: {
    display: 'flex',
    flex: 2,
    fontSize: 12,
    color: theme.palette.color.gray,
    fontFamily: primaryFont,
    justifyContent: 'flex-end'
  },
  rootLoadMore: {
    scrollBehavior: 'smooth',
    position: 'relative',
    minHeight: 320,
    maxHeight: 325,
    padding: '5px 8px',
    overflowX: 'hidden',
    overflowY: 'auto',
    '&::-webkit-scrollbar': {
      width: 4
    },

    /* Track */
    '&::-webkit-scrollbar-track': {
      '-webkitBoxShadow': `inset 0 0 6px rgba(0,0,0,0.1)`,
      boxShadow: `inset 0 0 6px rgba(0,0,0,0.1)`,
      '-webkitBorderRadius': 10,
      borderRadius: 10
    },

    /* Handle */
    '&::-webkit-scrollbar-thumb': {
      '-webkitBorderRadius': 10,
      borderRadius: 10,
      backgroundColor: '#f5f5f5',
      '-webkitBoxShadow': `inset 0 0 6px rgba(0,0,0,0.3)`,
      boxShadow: `inset 0 0 6px rgba(0,0,0,0.3)`
    },
    '&::-webkitScrollbarThumb:window-inactive': {
      backgroundColor: '#f7f7f7'
    }
  },
  content: {
    borderRadius: '8px',
    paddingLeft: 8,
    paddingRight: 8,
    cursor: 'pointer',
    position: 'relative',
    display: 'flex',
    flexDirection: 'row',
    transition: 'all .35s',
    '& *': {
      position: 'relative'
    },
    alignItems: 'center',
    zIndex: 1,
    '&:hover:before': {
      content: '" "',
      borderRadius: '8px',
      position: 'absolute',
      width: '100%',
      height: '100%',
      top: 0,
      left: 0,
      backgroundColor: '#efefef'
    }
  },
  date: {
    display: 'flex',
    justifyContent: 'flex-end'
  },
  BodyWrapperItemChatBox: {
    cursor: 'pointer',
    display: 'flex',
    alignItems: 'center',
    padding: '16px 0',
    borderTop: ' 1px solid #F2F2F2',
    '& :last-child': {
      paddingBottom: 0,
      borderBottom: 'unset'
    },
    '& :first-child': {
      burderTop: 'unset'
    },
    '&:hover': {
      backgroundColor: 'rgba(0, 0, 0, .05)'
    }
  },
  BodyChatBoxItem: {
    display: 'flex',
    flexGrow: 1,
    flexDirection: 'column',
    textOverflow: 'ellipsis',
    overflow: 'hidden',
    whiteSpace: 'nowrap'
  },
  BodyChatBoxHeader: {
    display: 'flex',
    alignItems: 'center'
  },
  BodyChatBoxHeaderName: {
    flexGrow: 1,
    fontSize: 16,
    fontFamily: 'Helvetica',
    color: '#2F2E2E'
  },
  iconDot: {
    fontSize: '5px',
    color: 'inherit',
    margin: '0 4px'
  },
  large: {
    width: theme.spacing(5),
    height: theme.spacing(5)
  }
}));

export default useStyle;
