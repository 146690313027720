import { createSlice } from 'redux-starter-kit';

let initialState = {
  orderIdMessenger: null,
  showMessengerFromOrderDetail: false
};

export let messengerChatSlice = createSlice({
  initialState: initialState,
  slice: 'messengerChat',
  reducers: {
    setOrderIdMessenger(state, action) {
      state.orderIdMessenger = action.payload.state;
    },
    setShowMessengerFromOrderDetail(state, action) {
      state.showMessengerFromOrderDetail = action.payload.state;
    }
  }
});

export default messengerChatSlice.reducer;
