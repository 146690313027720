import React from 'react';
import { connect } from 'react-redux';
import { Field, Form, Formik } from 'formik';
import ModalCustom from '../../ModalCustom';
import Button from '../../Button';
import confirmImg from '../../../assets/img/confirm.png';
import { toastMessage, TOAST_TYPE } from '../../../utils/toastify-notify';
import { reasonSchema } from '../../../utils/schema';
import { CustomTextField } from '../../FormFeild';
import { vendorUpdateOrder } from 'maua-redux-core/actions/productOrder';
import { actionsVendor } from '../../../shared/constant/const';
import { actSetLoading } from '../../../store/actions/loading';
import { actSetNeedReloadCountProductOrders } from '../../../store/actions/orders';
import { useTranslation } from 'react-i18next';

import useStyle from './style';

const PickupAtStoreConfirm = React.memo(props => {
  const { productActive } = props;

  const mainClass = useStyle();
  const { t } = useTranslation();

  const handleOnSubmit = async values => {
    const { reason } = values;
    try {
      props.setLoading({ status: true });

      await props.handleRejectProductOrder(
        productActive,
        actionsVendor.reject,
        reason
      );

      props.handleRejectProduct();
      props.handleToggleNeedReloadCountProductOrders(true);

      toastMessage(
        TOAST_TYPE.SUCCESS,
        `${t('toastMessage.success.content.Reject Product Order Success')}`
      );
    } catch (err) {
      toastMessage(TOAST_TYPE.ERROR, err.message);
    } finally {
      props.setLoading({ status: false });
    }
  };

  const handleCheckValidation = validateForm => {
    validateForm().then(err => {
      if (Object.keys(err).length > 0) {
        toastMessage(TOAST_TYPE.ERROR, err[Object.keys(err)[0]]);
      }
    });
  };
  return (
    <div className={mainClass.root}>
      <ModalCustom {...props}>
        <Formik
          initialValues={{ reason: '' }}
          enableReinitialize={true}
          onSubmit={handleOnSubmit}
          validationSchema={reasonSchema}
          render={({ validateForm, isValid }) => {
            return (
              <Form>
                <div className={mainClass.content}>
                  <div className={mainClass.img}>
                    <img src={confirmImg} alt="Are you sure about that ?" />
                  </div>
                  <div className={mainClass.innerContent}>
                    <h4 className={mainClass.title}>
                      {t('order.reject.modal.title.Are you sure about that')}?
                    </h4>
                    <Field
                      name="reason"
                      margin="dense"
                      variant="outlined"
                      placeholder={t('order.reject.modal.label.Type a reason')}
                      label={t('order.reject.modal.label.Type a reason')}
                      className={mainClass.reason}
                      multiline
                      rows="4"
                      fullWidth
                      InputProps={{
                        classes: {
                          root: mainClass.rootTextarea,
                          notchedOutline: mainClass.notchedOutline,
                          input: mainClass.input,
                          focused: mainClass.notchedOutlineFocus
                        }
                      }}
                      component={CustomTextField}
                    />
                    <Button
                      typeBtn="yellow"
                      handleClick={() => handleCheckValidation(validateForm)}
                      disabled={!isValid}>
                      {t('action.OK')}
                    </Button>
                  </div>
                </div>
              </Form>
            );
          }}
        />
      </ModalCustom>
    </div>
  );
});
const mapDispatchToProps = dispatch => {
  return {
    handleRejectProductOrder: (_id, action, reason) =>
      dispatch(vendorUpdateOrder({ _id, action, reason })),
    setLoading: status => dispatch(actSetLoading(status)),
    handleToggleNeedReloadCountProductOrders: value =>
      dispatch(actSetNeedReloadCountProductOrders(value))
  };
};
export default connect(
  null,
  mapDispatchToProps
)(PickupAtStoreConfirm);
