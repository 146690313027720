import React from 'react';
import CloseIcon from '@material-ui/icons/Close';

import { useStyles } from './style';

const PictureComponentUpload = props => {
  const mainClass = useStyles();
  const { url, idx, handleRemovePicture } = props;
  return (
    <div className={mainClass.root} style={{ backgroundImage: `url(${url})` }}>
      <span
        className={mainClass.removeBtn}
        onClick={() => handleRemovePicture(idx)}>
        <CloseIcon className={mainClass.removeIcon} />
      </span>
    </div>
  );
};

export default PictureComponentUpload;
