import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';

import CloseButton from '../../CloseButton';
import InfiniteScroll from 'react-infinite-scroller';

import {
  channelsHistory,
  readMessages
} from 'maua-redux-core/actions/messageChannel';
import { getUserProfile } from 'maua-redux-core/selectors/user';

import { actSetOrderIdMessenger } from '../../../store/actions/messengerChat';

import { queryChannelsHistory } from '../../../store/query/messenger';

import userDefault from '../../../assets/img/product-item-default.png';
import UserItemSkeleton from '../UserItemSkeleton';

import { isEmptyObject, dateTimeHHmm } from '../../../utils/common';

import useStyle from './style';
import channelEmptyImg from '../../../assets/img/channelEmpty.png';
import { useTranslation } from 'react-i18next';

const UserList = props => {
  const { t } = useTranslation();
  const hasToken = isEmptyObject(props.profile);
  const mainClass = useStyle();
  const [dataMessengerChannel, setDataMessengerChannel] = useState({
    page: 1,
    data: [],
    pages: 1
  });
  const [loadMore, setLoadMore] = useState(false);

  const loadMoreChannel = () => {
    if (dataMessengerChannel.page < dataMessengerChannel.pages) {
      getChannelHistory(dataMessengerChannel.page + 1, true);
    } else {
      return;
    }
  };

  useEffect(() => {
    if (hasToken) {
      getChannelHistory();
    }
  }, [hasToken]);

  const getChannelHistory = async (dataPage, loadMore) => {
    try {
      setLoadMore(true);
      const params = {
        page: dataPage || dataMessengerChannel.page
        // sortBy: 'updatedAt'
      };

      await props.getChannelsHistory(params).then(({ channelsHistory }) => {
        if (loadMore) {
          setDataMessengerChannel(prevData => {
            return {
              page: channelsHistory.page,
              data: [...prevData.data, ...channelsHistory.docs],
              pages: channelsHistory.pages
            };
          });
        } else {
          setDataMessengerChannel({
            page: channelsHistory.page,
            data: channelsHistory.docs,
            pages: channelsHistory.pages
          });
        }
        setLoadMore(false);
      });
    } catch (error) {
      setLoadMore(false);
    }
  };
  const showChatBoxDetail = (channelId, orderId) => {
    props.readMessagesRequrest({ channel: channelId });
    props.setOrderIdMessengerRequest(orderId);
    props.handleShowChatBox();
    props.setChannelID(channelId);
  };

  return (
    <div className={mainClass.usersChatList}>
      <div className={mainClass.headUserList}>
        <h3 className={mainClass.titleHead}>
          {t('chat.title.Chatbox')}
          {/* {props.messengerUnread ? <span className={mainClass.countChat}>{props.messengerUnread}</span> : null} */}
        </h3>
        <CloseButton
          className={mainClass.closeBtn}
          onClick={props.handleCloseChat}
        />
      </div>
      {dataMessengerChannel.data.length ? (
        <div className={mainClass.userList}>
          <InfiniteScroll
            threshold={240}
            useWindow={false}
            initialLoad={false}
            loadMore={loadMoreChannel}
            hasMore={loadMore}
            loader={<UserItemSkeleton key={0} />}>
            {dataMessengerChannel.data.map((item, idx) => {
              const { messages, name, _id, order, updatedAt, member } =
                item || {};

              let lastedMessenger =
                messages && messages.length
                  ? messages[messages.length - 1]
                  : {};
              const isNotRead =
                member && member.seenAt >= member.messageAt ? false : true;
              return (
                <div
                  key={idx}
                  onClick={() => showChatBoxDetail(_id, order._id)}
                  className={mainClass.userItem}>
                  <img
                    src={userDefault}
                    alt="avatar"
                    className={mainClass.avatarUser}
                  />
                  <div className={mainClass.infoUser}>
                    <h4
                      className={mainClass.userName}
                      style={{ fontWeight: isNotRead ? 'bold' : 'normal' }}>
                      {name}
                    </h4>
                    <p className={mainClass.userDesc}>
                      {lastedMessenger.content}
                    </p>
                  </div>
                  <div className={mainClass.timeStamp}>
                    {updatedAt ? dateTimeHHmm(updatedAt) : ''}
                  </div>
                </div>
              );
            })}
          </InfiniteScroll>
        </div>
      ) : (
        <div className={mainClass.channelEmptyImg}>
          <img src={channelEmptyImg} alt="empty channel" />
          <p className={mainClass.textEmpty}>{t('chatbox.channel.empty')}</p>
        </div>
      )}
    </div>
  );
};

const mapStateToProps = state => {
  return {
    profile: getUserProfile(state)
    // messengerUnread: getCountNewMessages(state)
  };
};
const mapDispatchToProps = dispatch => {
  return {
    getChannelsHistory: params =>
      dispatch(channelsHistory(params, queryChannelsHistory)),
    setOrderIdMessengerRequest: orderId =>
      dispatch(actSetOrderIdMessenger(orderId)),
    readMessagesRequrest: params => dispatch(readMessages(params, null))
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(UserList);
