import { makeStyles } from '@material-ui/core';
const useStyle = makeStyles(theme => ({
  containerCarousel: {
    marginTop: 26,
    position: 'relative'
  },
  mainOrderPage: {},
  rootLoadMore: {
    maxHeight: 'calc(100vh - 136px)',
    overflowY: 'auto',
    paddingBottom: 15,
    paddingRight: 15,
    '&::-webkit-scrollbar': {
      width: 0
    },
    /* Track */
    '&::-webkit-scrollbar-track': {
      '-webkitBoxShadow': `inset 0 0 6px rgba(0,0,0,0)`,
      boxShadow: `inset 0 0 6px rgba(0,0,0,0)`,
      '-webkitBorderRadius': 10,
      borderRadius: 10
    },

    /* Handle */
    '&::-webkit-scrollbar-thumb': {
      '-webkitBorderRadius': 10,
      borderRadius: 10,
      backgroundColor: 'transparent',
      '-webkitBoxShadow': `inset 0 0 6px rgba(0,0,0,0)`,
      boxShadow: `inset 0 0 6px rgba(0,0,0,0)`
    },
    '&::-webkitScrollbarThumb:window-inactive': {
      backgroundColor: 'transparent'
    }
  },
  leftContent: {
    maxHeight: 'calc(100vh - 136px)',
    overflowY: 'auto',
    backgroundColor: theme.palette.color.white,
    borderLeft: `1px solid #F2F2F2`,
    minHeight: `calc(100vh - 140px)`
  },
  padr0: {
    paddingRight: '0 !important'
  },
  padl0: {
    paddingLeft: '0 !important'
  }
}));
export default useStyle;
