import { makeStyles } from '@material-ui/core';
import { primaryFont, secondaryFont } from '../../shared/theme';

export const useStyle = makeStyles(theme => ({
  innerOverview: {
    backgroundColor: 'rgba(247, 247, 247, .5)',
    minHeight: 'calc(100vh - 68px)'
  },
  root: {
    minHeight: 'calc(100vh - 68px)',
    margin: theme.spacing(1.6, 0)
  },
  background: {
    background: theme.palette.color.white
  },
  flex: {
    display: 'flex',
    alignItems: 'center'
  },
  header: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: theme.spacing(1.6, 2.4),
    borderBottom: '1px solid #dcd9d9a6'
  },
  chartHeader: {
    padding: theme.spacing(1.6, 2.4),
    display: 'flex',
    alignItems: 'center',
    [theme.breakpoints.down('md')]: {
      flexWrap: 'wrap',
      alignItems: 'flex-start'
    }
  },
  title: {
    fontSize: 14,
    lineHeight: 1.8,
    fontFamily: primaryFont
  },
  price: {
    display: 'flex',
    alignItems: 'center',
    fontFamily: primaryFont,
    color: theme.palette.primary.main,
    marginLeft: theme.spacing(0.8),
    marginTop: theme.spacing(-0.2)
  },
  levelItem: {
    width: 300,
    height: 180,
    fontFamily: secondaryFont,
    border: '1px solid #dcd9d9a6',
    [theme.breakpoints.up('lg')]: {
      '&:nth-of-type(1)': {
        borderRight: `1px solid ${theme.palette.color.white}`
      },
      '&:nth-of-type(2)': {
        borderRight: `1px solid ${theme.palette.color.white}`
      },
      '&:nth-of-type(3)': {
        borderRight: `1px solid ${theme.palette.color.white}`
      },
      '&:nth-of-type(4)': {
        borderRight: `1px solid ${theme.palette.color.white}`
      }
    },
    [theme.breakpoints.down('md')]: {
      width: '50%',
      '&:nth-of-type(1)': {
        borderRight: 0,
        borderBottom: 0
      },
      '&:nth-of-type(2)': {
        borderBottom: 0
      },
      '&:nth-of-type(3)': {
        borderRight: 0,
        borderBottom: 0
      }
    }
  },
  btnExport: {
    width: 150,
    height: 40,
    // borderRadius: 4,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center'
  },
  boxCountName: {
    height: 118,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center'
  },
  countOfLevel: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    fontSize: 50
  },
  nameOfLevel: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'flex-end',
    color: theme.palette.color.gray1,
    fontSize: 14
  },
  boxTime: {
    height: 62,
    color: theme.palette.color.gray1,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center'
  },
  timeOfLevel: {
    fontSize: 14,
    color: theme.palette.color.black
  },

  // top sellers
  boxProducts: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    padding: theme.spacing(1.6, 2.4)
  },
  productItem: {
    width: '200px !important',
    height: 180,
    outline: 'none',
    background: '#f7f7f7'
  },

  image: {
    display: 'block',
    width: '100%',
    height: 120,
    objectFit: 'cover'
  },
  boxTextProduct: {
    height: 60,
    padding: theme.spacing(0.8)
  },
  nameOfProduct: {
    fontFamily: secondaryFont,
    fontSize: 16,
    color: theme.palette.color.black,
    paddingBottom: theme.spacing(0.4),
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis'
  },
  countOfProduct: {
    fontFamily: secondaryFont,
    fontSize: 14,
    color: theme.palette.color.gray1,
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis'
  }
}));

export const useStyleModal = makeStyles(theme => ({
  modalContent: {
    padding: `25px 0 0`
  }
}));
