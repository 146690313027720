import React, { useMemo } from 'react';
import Grid from '@material-ui/core/Grid';
import { Field } from 'formik';
import useStyles from './style';
import PictureComponentUpload from '../PictureComponetUpload';
import UploadComponent from '../UploadComponent';
import { CustomTextField } from '../FormFeild';
import { useTranslation } from 'react-i18next';
import ErrorIcon from '@material-ui/icons/Error';
import SelectField from '../FieldWithoutFormik/Select';
import { Box } from '@material-ui/core';

function General({
  images,
  setImages,
  handleChangeImage,
  formik,
  options,
  setFieldValue,
  ...props
}) {
  const { t } = useTranslation();
  const mainClass = useStyles();

  return (
    <>
      <Grid item container xs={12} className={mainClass.wrapTab}>
        <Box className={mainClass.fieldContainer}>
          <Grid
            style={
              props.isSubmitMenu
                ? {
                    pointerEvents: 'none',
                    opacity: 0.5
                  }
                : {
                    pointerEvents: 'all',
                    opacity: 1
                  }
            }
            item
            className={mainClass.wrappImg}
            container
            spacing={3}>
            {images.map((image, idx) => (
              <Grid item xs={4} className={mainClass.itemShowImg} key={idx}>
                <PictureComponentUpload
                  url={image?.src}
                  idx={idx}
                  handleRemovePicture={idx =>
                    setImages(images => {
                      images.splice(idx, 1);
                      return [...images];
                    })
                  }
                />
              </Grid>
            ))}

            <Grid item xs={4} className={mainClass.itemAddImg}>
              {images.length < 3 && (
                <UploadComponent handleUpload={handleChangeImage} />
              )}
            </Grid>
          </Grid>

          <Grid item xs={12}>
            <SelectField
              error={
                formik?.touched?.subCategory && formik?.errors?.subCategory
              }
              fontSize={16}
              options={options}
              zIndex={99}
              onBlur={() => formik.setFieldTouched('subCategory', true)}
              value={formik.values.subCategory}
              label={t('product.label.Sub category')}
              name="subCategory"
              onChange={e => {
                setFieldValue('subCategory', e);
              }}
              disabled={props.isSubmitMenu}
            />
          </Grid>
          <Grid item xs={12}>
            <Field
              disabled={props.isSubmitMenu}
              name="productName"
              margin="dense"
              variant="outlined"
              placeholder={t('product.label.Product name')}
              label={t('product.label.Product name')}
              fullWidth
              component={CustomTextField}
            />
          </Grid>
          <Grid item xs={12}>
            <Field
              disabled={props.isSubmitMenu}
              name="description"
              margin="dense"
              variant="outlined"
              placeholder={t('product.label.Description')}
              label={t('product.label.Description')}
              multiline
              rows="4"
              fullWidth
              component={CustomTextField}
            />
          </Grid>
        </Box>

        <Grid item xs={12} container className={mainClass.noticeBox}>
          <Grid item xs={1}>
            <ErrorIcon color="primary" />
          </Grid>
          <Grid item xs={11}>
            <span className={mainClass.notice}>
              <b> {t('product.popup.notice.title')}</b>
              {t('product.popup.notice.firstContent')}
              <b> {t('product.popup.notice.label')} </b>
              {t('product.popup.notice.secondContent')}
            </span>
          </Grid>
        </Grid>
      </Grid>
    </>
  );
}

export default General;
