import { makeStyles } from '@material-ui/core';

const useStyle = makeStyles(theme => ({
  wrapp: {
    backgroundColor: theme.palette.color.white,
    borderBottom: `1px solid #F2F2F2`,
    boxShadow: `-4px 0px 10px rgba(0, 0, 0, 0.07)`
  },
  container: {
    height: '100%'
  }
}));
export default useStyle;
