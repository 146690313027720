import React, { useEffect } from 'react';
import { connect } from 'react-redux';
// import clsx from 'clsx';
import { useTranslation } from 'react-i18next';
import { Typography } from '@material-ui/core';

import useStyle from './style';
import ProductService from '../../Orders/ProductService';
import TotalPrice from '../../Orders/TotalPrice';
import FormCustomer from '../../FormCustomer';
import PaymentInfo from '../../Orders/PaymentInfo';
import Button from '../../Button';
import { vendorUpdateOrder } from 'maua-redux-core/actions/productOrder';
import { actionsVendor } from '../../../shared/constant/const';
import { toastMessage, TOAST_TYPE } from '../../../utils/toastify-notify';
import { actSetLoading } from '../../../store/actions/loading';
import { getLoadingStatus } from '../../../store/selectors/loading';
import { getOpenDetailOrderItem } from '../../../store/selectors/orders';
import { actSetNeedReloadCountProductOrders } from '../../../store/actions/orders';
import BackToDashBoard from '../../BackToDashBoard';

const DetailInprogressOrderItem = props => {
  const classess = useStyle();
  const { t } = useTranslation();
  useEffect(() => {
    if (props.loading?.status && props.showDetailOrderItem) {
      props.setLoading({ status: false });
    }
  }, [props.loading?.status]);

  const handleReadyPickup = async ev => {
    const {
      productActive,
      handleReadyForPickup,
      handleToggleNeedReloadCountProductOrders
    } = props;
    ev.preventDefault();
    try {
      props.setLoading({ status: true });
      await props
        .handleReadyForPickupFromServer(productActive, actionsVendor.ready)
        .then(response => {
          handleReadyForPickup();
          handleToggleNeedReloadCountProductOrders(true);
          toastMessage(
            TOAST_TYPE.SUCCESS,
            `${t('toastMessage.success.content.Product Order to Ready')}`
          );
        });
    } catch (err) {
      props.setLoading({ status: false });
      toastMessage(TOAST_TYPE.ERROR, err.messsage);
    } finally {
      props.setLoading({ status: false });
    }
  };

  const {
    _id,
    code,
    amount,
    address,
    products,
    customer,
    subTotal,
    discount,
    promotion,
    serviceFee,
    deliveryFee,
    productsPrice,
    paymentMethod
  } = props.data;

  const dataTotalPrice = {
    amount,
    discount,
    promotion,
    serviceFee,
    deliveryFee,
    productsPrice: subTotal || productsPrice
  };

  const dataCustomer = {
    customer,
    address,
    productOrderID: _id
  };

  return (
    <div className={classess.wrapp}>
      {/* <BackToDashBoard handleBackDashboard={props.handleshowDashboards} /> */}
      <Typography variant="h4" className={classess.identity}>
        #{code}
      </Typography>
      <div className={classess.main}>
        <ProductService products={products} />
        <TotalPrice dataTotalPrice={dataTotalPrice} />
        <FormCustomer dataCustomer={dataCustomer} />
        <PaymentInfo order={props.data} />
        <div className={classess.wrappBtn}>
          <Button typeBtn="yellow" onClick={handleReadyPickup}>
            {t('action.Ready for pick-up')}
          </Button>
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = (state, ownProps) => {
  return {
    loading: getLoadingStatus(state),
    showDetailOrderItem: getOpenDetailOrderItem(state)
  };
};

const mapDispatchToProps = dispatch => {
  return {
    handleReadyForPickupFromServer: (_id, action) =>
      dispatch(vendorUpdateOrder({ _id, action })),
    setLoading: status => dispatch(actSetLoading(status)),
    handleToggleNeedReloadCountProductOrders: value =>
      dispatch(actSetNeedReloadCountProductOrders(value))
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(DetailInprogressOrderItem);
