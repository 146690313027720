import makeStyles from '@material-ui/styles/makeStyles';
import { primary, primaryFont } from '../../shared/theme';

const useStyles = makeStyles(theme => ({
  btnExport: {
    height: 40,
    position: 'relative',
    width: 148,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    fontFamily: primaryFont,
    borderRadius: 200,
    fontSize: 14,
    color: theme.palette.color.white,
    cursor: 'pointer',
    background: theme.palette.primary.main,
    boxShadow:
      '0px 1px 5px 0px rgba(0,0,0,0.2), 0px 2px 2px 0px rgba(0,0,0,0.14), 0px 3px 1px -2px rgba(0,0,0,0.12)',
    [theme.breakpoints.only('xs')]: {
      width: 110
    }
  },
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center'
  },
  paper: {
    backgroundColor: 'white',
    borderRadius: 8,
    padding: theme.spacing(2),
    border: 'none'
  },
  contentExport: {
    padding: theme.spacing(1, 1, 1, 1),
    fontFamily: 'Mulish',
    fontStyle: 'normal',
    fontSize: '20px',
    color: '#2F2E2E'
  },
  btnCancel: {
    height: 40,
    marginRight: 20,
    position: 'relative',
    width: 148,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    fontFamily: primaryFont,
    borderRadius: 200,
    fontSize: 14,
    cursor: 'pointer',
    [theme.breakpoints.only('xs')]: {
      width: 80
    }
  },
  typePrimary: {
    background: theme.palette.primary.main,
    color: theme.palette.color.white,
    '& img': {
      filter: 'brightness(0) invert(1)'
    }
  },
  typeDefault: {
    background: theme.palette.color.white,
    color: theme.palette.color.black2,
    border: `1px solid ${theme.palette.primary.main}`,
    boxShadow: 'unset'
  }
}));

export default useStyles;
