export const queryCategories = `
  _id
  slug
  name
  type
  banner
  status
`;

export const queryCategoryInMystore = `
  _id
  slug
  name
  type
  featureImg
  status
  countChildrens
  createdAt
  updatedAt
`;
