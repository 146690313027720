import React, { useEffect, useRef, useState } from 'react';
import { isEmpty } from 'lodash';
import { connect, useSelector } from 'react-redux';
import {
  GoogleMap,
  Marker,
  withGoogleMap,
  withScriptjs
} from 'react-google-maps';
import { compose, withProps } from 'recompose';
import { fetchAddress } from 'maua-redux-core/actions/address';
import { getAddress } from 'maua-redux-core/selectors/address';
import { toastMessage, TOAST_TYPE } from '../../utils/toastify-notify';
import { getCoordinate } from '../../store/selectors/coordinate';
import { queryAddress } from '../../store/query/user';
import { ggApiKey } from '../../utils/helper';
import useStyle from './style';

const CustomMarker = ({ icon = '', position }) => (
  <Marker
    icon={icon}
    animation={window.google.maps.Animation.BOUNCE}
    position={position}
  />
);

const MyMapComponent = compose(
  withProps(props => {
    return {
      googleMapURL: `https://maps.googleapis.com/maps/api/js?key=${ggApiKey}&v=3.exp&libraries=geometry,drawing,places`,
      loadingElement: <div style={{ height: `100%` }} />,
      containerElement: <div style={{ height: `${props.size}px` }} />,
      mapElement: <div style={{ height: `100%` }} />
    };
  }),
  withScriptjs,
  withGoogleMap
)(
  ({
    isPinToMap = false,
    setCurrentPoint,
    currentPoint,
    defaultCenter = {},
    onFetchAddress
  }) => {
    const classes = useStyle();
    const map = useRef();
    const handleClickPinToMap = e => {
      const clickPoint = {
        lat: e.latLng.lat() || 0,
        lng: e.latLng.lng() || 0
      };

      onFetchAddress(clickPoint, err => {
        if (err && err.message) {
          return toastMessage(TOAST_TYPE.ERROR, err);
        }

        setCurrentPoint(clickPoint);
        if (map.current) {
          map.current.panTo(clickPoint);
        }
      });
    };

    const showPinMarker = () => {
      return isPinToMap && <CustomMarker position={currentPoint} />;
    };

    return (
      <div className={classes.myMap}>
        <GoogleMap
          defaultZoom={16}
          ref={map}
          center={defaultCenter}
          onClick={e => handleClickPinToMap(e)}
          options={{ mapTypeId: 'hybrid' }}>
          {showPinMarker()}
        </GoogleMap>
      </div>
    );
  }
);

const MainMap = props => {
  const locationDefault = useSelector(getCoordinate);

  const [defaultCenter, setDefaultCenter] = useState({});
  const { onFetchAddress, address } = props;
  const {
    location: { coordinates: addressCoordinates = [0, 0] } = {}
  } = address;
  const [currentPoint, setCurrentPoint] = useState({ lat: 0, lng: 0 });

  useEffect(() => {
    if (!isEmpty(address)) {
      const userLocation = {
        lat: addressCoordinates[1],
        lng: addressCoordinates[0]
      };
      setDefaultCenter(userLocation);
      setCurrentPoint(userLocation);
    } else {
      setDefaultCenter(locationDefault);
    }
    /* eslint-disable-next-line react-hooks/exhaustive-deps */
  }, [address]);

  return (
    <React.Fragment>
      {defaultCenter && (
        <MyMapComponent
          currentPoint={currentPoint}
          setCurrentPoint={setCurrentPoint}
          defaultCenter={defaultCenter}
          onFetchAddress={onFetchAddress}
          {...props}
        />
      )}
    </React.Fragment>
  );
};

const mapStateToProps = state => {
  return {
    address: getAddress(state)
  };
};
const mapDispatchToProps = dispatch => {
  return {
    onFetchAddress: (params, cb) => {
      dispatch(fetchAddress(params, queryAddress, cb));
    }
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(MainMap);
