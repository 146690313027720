import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { Box, Typography } from '@material-ui/core';
import StopIcon from '@material-ui/icons/Stop';
import { getUserProfile } from 'maua-redux-core/selectors/user';
import { getStoreDashboard } from 'maua-redux-core/actions/store';
import { getTimeZone, daysInThisMonth } from '../../utils/common';
import { xaxisTodayWeekly, optionsFullTime } from '../../utils/constant';
import { toastMessage, TOAST_TYPE } from '../../utils/toastify-notify';
import SelectCustom from '../../components/FormFeild/SelectCustom';
import StackedBar from '../../components/StackedBar';
import { theme } from '../../shared/theme';
import { useStyle } from './style';

const NumberOfOrder = props => {
  const classes = useStyle();
  const { t } = useTranslation();
  const [order, setOrder] = useState({
    series: [],
    xaxis: [],
    total: 0,
    New: 0,
    inProgress: 0,
    ready: 0,
    completed: 0,
    failed: 0
  });
  const [filter, setFilter] = useState(optionsFullTime[3]);
  const colors = ['#7dcea0', '#6baafb', '#074481', '#f5b041', '#ec7063'];

  const loadOrders = async () => {
    const payload = {
      type: 'GROUP_BY_STATUS',
      startTime: filter.startTime,
      endTime: filter.endTime,
      timezone: getTimeZone()
    };
    try {
      let { getStoreDashboard } = await props.getStoreDashboard(payload);
      const groupByStatus = getStoreDashboard?.groupByStatus?.sort(
        (a, b) => a.column - b.column
      );

      let { New, inProgress, ready, completed, failed } = groupByStatus.reduce(
        ({ New, inProgress, ready, completed, failed }, r) => ({
          New: New + r?.value.new,
          inProgress: inProgress + r?.value.inProgress,
          ready: ready + r?.value.ready,
          completed: completed + r?.value.completed,
          failed: failed + r?.value.failed
        }),
        {
          New: 0,
          inProgress: 0,
          ready: 0,
          completed: 0,
          failed: 0
        }
      );

      let total = New + inProgress + ready + completed + failed;

      // map data follow chart
      switch (filter.value) {
        case 'Today':
          let today = [];
          for (let i = 0; i <= 23; i++) {
            today.push(i);
          }

          let dataToday = [
            {
              name: 'New',
              data: Array(24).fill(0)
            },
            {
              name: 'In progress',
              data: Array(24).fill(0)
            },
            {
              name: 'Ready',
              data: Array(24).fill(0)
            },
            {
              name: 'Completed',
              data: Array(24).fill(0)
            },
            {
              name: 'Failed',
              data: Array(24).fill(0)
            }
          ];

          groupByStatus.forEach(({ column, value }) => {
            dataToday[0]['data'][column] = value.new;
            dataToday[1]['data'][column] = value.inProgress;
            dataToday[2]['data'][column] = value.ready;
            dataToday[3]['data'][column] = value.completed;
            dataToday[4]['data'][column] = value.failed;
          });

          setOrder(() => ({
            series: dataToday,
            xaxis: today,
            total,
            New,
            inProgress,
            ready,
            completed,
            failed
          }));
          break;
        case 'weekly':
          let dataWeekly = [
            {
              name: 'New',
              data: Array(7).fill(0)
            },
            {
              name: 'In progress',
              data: Array(7).fill(0)
            },
            {
              name: 'Ready',
              data: Array(7).fill(0)
            },
            {
              name: 'Completed',
              data: Array(7).fill(0)
            },
            {
              name: 'Failed',
              data: Array(7).fill(0)
            }
          ];

          groupByStatus.forEach(({ column, value }) => {
            dataWeekly[0]['data'][column - 1] = value.new;
            dataWeekly[1]['data'][column - 1] = value.inProgress;
            dataWeekly[2]['data'][column - 1] = value.ready;
            dataWeekly[3]['data'][column - 1] = value.completed;
            dataWeekly[4]['data'][column - 1] = value.failed;
          });

          setOrder(() => ({
            series: dataWeekly,
            xaxis: xaxisTodayWeekly,
            total,
            New,
            inProgress,
            ready,
            completed,
            failed
          }));
          break;
        case 'monthly':
          const daysTotal = daysInThisMonth();
          let monthly = [];
          for (let i = 1; i <= daysTotal; i++) {
            monthly.push(i);
          }

          let dataMonthly = [
            {
              name: 'New',
              data: Array(monthly?.length).fill(0)
            },
            {
              name: 'In progress',
              data: Array(monthly?.length).fill(0)
            },
            {
              name: 'Ready',
              data: Array(monthly?.length).fill(0)
            },
            {
              name: 'Completed',
              data: Array(monthly?.length).fill(0)
            },
            {
              name: 'Failed',
              data: Array(monthly?.length).fill(0)
            }
          ];

          groupByStatus.forEach(({ column, value }) => {
            dataMonthly[0]['data'][column - 1] = value.new;
            dataMonthly[1]['data'][column - 1] = value.inProgress;
            dataMonthly[2]['data'][column - 1] = value.ready;
            dataMonthly[3]['data'][column - 1] = value.completed;
            dataMonthly[4]['data'][column - 1] = value.failed;
          });

          setOrder(() => ({
            series: dataMonthly,
            xaxis: monthly,
            total,
            New,
            inProgress,
            ready,
            completed,
            failed
          }));
          break;

        default:
          let yearly = [];
          for (let i = 1; i <= 12; i++) {
            yearly.push(i);
          }

          let dataYearly = [
            {
              name: 'New',
              data: Array(12).fill(0)
            },
            {
              name: 'In progress',
              data: Array(12).fill(0)
            },
            {
              name: 'Ready',
              data: Array(12).fill(0)
            },
            {
              name: 'Completed',
              data: Array(12).fill(0)
            },
            {
              name: 'Failed',
              data: Array(12).fill(0)
            }
          ];

          groupByStatus.forEach(({ column, value }) => {
            dataYearly[0]['data'][column - 1] = value.new;
            dataYearly[1]['data'][column - 1] = value.inProgress;
            dataYearly[2]['data'][column - 1] = value.ready;
            dataYearly[3]['data'][column - 1] = value.completed;
            dataYearly[4]['data'][column - 1] = value.failed;
          });

          setOrder(() => ({
            series: dataYearly,
            xaxis: yearly,
            total,
            New,
            inProgress,
            ready,
            completed,
            failed
          }));
          break;
      }
    } catch (error) {
      toastMessage(TOAST_TYPE.ERROR, error.message);
    }
  };

  useEffect(() => {
    if (props.profile._id) {
      loadOrders();
    }
  }, [filter, props.profile]);

  const handleFilter = e => {
    const val = e.target.value;
    setFilter(() => optionsFullTime.filter(({ value }) => value === val)[0]);
  };

  const legends = [
    {
      color: colors[0],
      status: t('dashboard.orderReport.status.New'),
      value: order.New
    },
    {
      color: colors[1],
      status: t('dashboard.orderReport.status.In progress'),
      value: order.inProgress
    },
    {
      color: colors[2],
      status: t('dashboard.orderReport.status.Ready'),
      value: order.ready
    },
    {
      color: colors[3],
      status: t('dashboard.orderReport.status.Completed'),
      value: order.completed
    },
    {
      color: colors[4],
      status: t('dashboard.orderReport.status.Failed'),
      value: order.failed
    }
  ];

  return (
    <Box p={theme.spacing(2.4, 0)}>
      <Box className={classes.background}>
        <Box className={classes.header}>
          <Typography variant="h4">
            {t('dashboard.title.Number of orders')}
          </Typography>
          <SelectCustom
            options={optionsFullTime}
            value={filter?.value}
            onChange={handleFilter}
          />
        </Box>
        <Box className={classes.chartHeader}>
          <Box pr={4} className={classes.flex}>
            <Box className={classes.title} style={{ fontSize: '16px' }}>
              {t('dashboard.title.Total')}:
            </Box>
            <Box className={classes.price}>{order.total}</Box>
          </Box>
          {legends.map((l, i) => {
            return (
              <Box pr={4} className={classes.flex} key={i}>
                <StopIcon style={{ color: l.color }} />
                <Box className={classes.title}>{l.status}</Box>
                <Box className={classes.price}>{l.value}</Box>
              </Box>
            );
          })}
        </Box>
        <StackedBar colors={colors} xaxis={order.xaxis} series={order.series} />
      </Box>
    </Box>
  );
};

export default connect(
  state => ({
    profile: getUserProfile(state)
  }),
  dispatch => ({
    getStoreDashboard: params =>
      dispatch(
        getStoreDashboard(
          params,
          'groupByStatus{ column value { draft new inProgress ready failed completed }}'
        )
      )
  })
)(NumberOfOrder);
