import React, { useState } from 'react';
import { Box } from '@material-ui/core';
import { main } from '../../shared/theme';

const LoadMoreText = props => {
  const { string, size } = props;
  const [maxLength, setMaxLength] = useState(size);
  let text = string ? string.slice(0, maxLength) + ' ... ' : '';

  const btnMoreText = () => {
    return (
      <>
        {text}
        <Box
          onClick={() => setMaxLength(string ? string.length : 0)}
          color={main}>
          More
        </Box>
      </>
    );
  };

  return (
    <>
      {(string ? string.length : 0) > maxLength ? (
        btnMoreText()
      ) : (
        <span style={{ wordBreak: 'break-all' }}>{string}</span>
      )}
    </>
  );
};
export default LoadMoreText;
