/*************************************************
 * Mystore Page includes 3 components: Banner, Wallet, Review
 * ==========================================================
 * Banner Component: components/Mystore/BannerOverview,
 * in components has edit Store, switch Business || add Business
 * ========================================================
 * Wallet component: components/Mystore/WalletItem,
 * has 2 type: blue sky || Digicel, when add 2 type
 * button add wallet has hiden
 * ========================================================
 * Review show all Rating Item components from:  components/RatingItem'
 *
 *************************************************/
import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import clsx from 'clsx';
// import { compose } from 'redux';
// import { withRouter } from 'react-router-dom';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import { Typography } from '@material-ui/core';
import { Container } from '@material-ui/core';
import AddIcon from '@material-ui/icons/Add';
import Grid from '@material-ui/core/Grid';
import { getUserProfile } from 'maua-redux-core/selectors/user';
import { fetchWallets } from 'maua-redux-core/actions/wallet';
import { fetchReviews } from 'maua-redux-core/actions/review';
import {
  fetchStore,
  removeEmployee,
  fetchAllPermissionEmployees
} from 'maua-redux-core/actions/store';
import ThankyouModalAddBusiness from '../../components/Mystore/ThankyouModalAddBusiness';
import ModalEnterStaffPhone from '../../components/Mystore/ModalEnterStaffPhone';
import ModalStaffWaiting from '../../components/Mystore/ModalWaitingStaffPhone';
import SwitchBusinessModal from '../../components/Mystore/SwitchBusinessModal';
import EditYourStoreModal from '../../components/Mystore/EditYourStoreModal';
import ModalStaffNotFound from '../../components/Mystore/ModalStaffNotFound';
import EditUserInfoModal from '../../components/Mystore/EditUserInfoModal';
import { actToggleNeedreloadMyStore } from '../../store/actions/mystores';
import AddBusinessModal from '../../components/Mystore/AddBusinessModal';
import ModalFoundStaff from '../../components/Mystore/ModalFoundStaff';
import { toastMessage, TOAST_TYPE } from '../../utils/toastify-notify';
import { getNeedReloadMyStore } from '../../store/selectors/mystores';
import defaultAvatar from '../../assets/img/product-item-default.png';
import BannerOverview from '../../components/Mystore/BannerOverview';
import AddWalletModal from '../../components/Mystore/AddWalletModal';
import { actSetNeedFetchWallets } from '../../store/actions/wallets';
import useStyle, { useStyleWallet, useStyleModal } from './style';
import { getHasWalletEdit } from '../../store/selectors/wallets';
import { queryStoreInMystore } from '../../store/query/store';
import WalletItem from '../../components/Mystore/WalletItem';
import { getCredentials } from '../../shared/services/token';
import { actSetLoading } from '../../store/actions/loading';
import { queryWallets } from '../../store/query/wallet';
import { queryReviews } from '../../store/query/review';
import { TEXT_ROLE } from '../../shared/constant/const';
import AlertDialog from '../../components/AlertDialog';
import RatingItem from '../../components/RatingItem';
import { isEmptyObject } from '../../utils/common';
import Button from '../../components/Button';
import {
  getOpenModalEditUser,
  getOpenModalFoundStaff,
  getOpenModalAddBusiness,
  getOpenModalEditYouStore,
  getOpenModalStaffNotFound,
  getOpenModalSwitchBusiness,
  getOpenModalEnterStaffPhone,
  getOpenModalWaitingStaffPhone,
  getOpenModalThankyouAddBusiness
} from '../../store/selectors/modal';
import {
  actToggleModalEditUser,
  actToggleModalFoundStaff,
  actToggleModalAddBusiness,
  actToggleModalStaffNotFound,
  actToggleModalEditYourStore,
  actToggleMoadlSwitchBusiness,
  actToggleModalEnterStaffPhone,
  actToggleModalWaitingStaffPhone,
  actToggleModalThankyouAddBusiness
} from '../../store/actions/modal';

const Mystore = props => {
  const mainClass = useStyle();
  const { t } = useTranslation();
  const styleWallet = useStyleWallet();
  const [storeId, setStoreId] = useState(props.profile?.store?._id);
  const [storeData, setStoreData] = useState({
    datas: {},
    managers: [],
    pageIndex: 1
  });
  const [walletsData, setWalletsData] = useState([]);
  const [reviewsData, setReviewsData] = useState({
    datas: [],
    pageIndex: 1,
    pages: 0
  });
  const [
    toggoleConFirmremoveEmployee,
    SetToggleConFirmremoveEmployee
  ] = useState(false);
  const { profile, needReloadMyStore, hasWalletEdit } = props;
  const [editUserInfo, setEditUserInfo] = useState(null);
  const [employeeId, SetEmployeeId] = useState('');
  const hasToken = isEmptyObject(profile);

  const handleFetchStoreData = async (_id, page) => {
    try {
      props.setLoading({ status: true });
      const { store } = await props.fetchStoreRequest({
        _id,
        page
      });

      const mapStore = {
        ...store,
        openTimes: JSON.stringify(store.openTimes)
      };

      setStoreData(prev => ({
        ...prev,
        datas: mapStore,
        managers: [store.owner, ...store.employees]
      }));

      const { reviews } = await props.fetchReviewsRequest({
        // nameStore: store.name,
        store: store._id,
        page: reviewsData.pageIndex
      });

      setReviewsData(prevData => ({
        ...prevData,
        datas: reviews.docs,
        pageIndex: reviews.page,
        pages: reviews.pages
      }));
    } catch (err) {
      toastMessage(TOAST_TYPE.ERROR, err.message);
    } finally {
      props.setLoading({ status: false });
    }
  };

  const handleFetchWalletsData = () => {
    props
      .fetchWalletsRequest()
      .then(({ wallets }) => {
        setWalletsData(wallets);
      })
      .catch(err => {
        props.setLoading({ status: false });
        toastMessage(TOAST_TYPE.ERROR, err.message);
      });
  };

  const handleFetchReviewsData = (store, page) => {
    props.setLoading({ status: true });
    props
      .fetchReviewsRequest({ store, page })
      .then(({ reviews }) => {
        setReviewsData(prevData => ({
          ...prevData,
          datas: [...prevData.datas, ...reviews.docs],
          pageIndex: reviews.page,
          pages: reviews.pages
        }));
        props.setLoading({ status: false });
      })
      .catch(err => {
        props.setLoading({ status: false });
        toastMessage(TOAST_TYPE.ERROR, err.message);
      });
  };

  useEffect(() => {
    if (
      storeId &&
      ((getCredentials() && hasToken && profile && profile.store) ||
        (hasToken && needReloadMyStore))
    ) {
      handleFetchStoreData(storeId, storeData.pageIndex);
      if (needReloadMyStore) {
        props.toggleNeedReloadMyStore(false);
      }
    }
  }, [hasToken, needReloadMyStore]);

  useEffect(() => {
    if (getCredentials() && hasToken) {
      handleFetchWalletsData();
    }
  }, [hasToken]);

  useEffect(() => {
    if (getCredentials() && hasToken && hasWalletEdit) {
      handleFetchWalletsData();
      props.handleResetNeedFetchWallets();
    }
  }, [hasWalletEdit]);

  useEffect(() => {
    if (props.profile?.store?._id) {
      setStoreId(props.profile.store._id);
    }
  }, [props.profile]);

  useEffect(() => {
    if (storeId) {
      handleFetchStoreData(storeId, storeData.pageIndex);
    }
  }, [storeId]);

  const [openModalAddWallet, setOpenModalAddWallet] = useState(false);
  const classModal = useStyleModal();

  const handleLoadMore = () => {
    const { pageIndex, pages } = reviewsData;
    const { _id } = storeData.datas;

    if (pageIndex < pages) {
      handleFetchReviewsData(_id, pageIndex + 1);
    }
  };

  const onCloseModalAddWallet = () => {
    setOpenModalAddWallet(false);
  };

  const handleAddWallet = () => {
    setOpenModalAddWallet(true);
  };

  const onCloseModalEditYourStore = () => {
    props.toggleModalEditYourStore(false);
  };

  const onCloseModalEditUserInfo = () => {
    props.toggleModalEditUser(false);
  };

  const onEditUserInfo = () => {
    props.toggleModalEditUser(true);
  };

  const onCloseModalSwitchBusiness = () => {
    props.toggleModalSwitchBusiness(false);
  };

  const onCloseModalAddBusiness = () => {
    props.toggleModalAddBusiness(false);
  };

  const onCloseModalThankyouAddBusiness = () => {
    props.toggleModalThankyouAddBusiness(false);
  };

  const handleAddEmployee = () => {
    props.toggleModalEnterStaffPhone(true);
  };

  const handleCloseModalEnterStaffPhone = () => {
    props.toggleModalEnterStaffPhone(false);
  };

  const handleCloseModalStaffNotFound = () => {
    props.toggleModalStaffNotFound(false);
  };

  const handleUseAnotherNumber = () => {
    handleCloseModalStaffNotFound();
    props.toggleModalEnterStaffPhone(true);
  };

  const handleCloseModalStaffWaiting = () => {
    props.toggleModalWaitingStaffPhone(false);
  };

  const handleCloseModalFoundStaff = () => {
    props.toggleModalFoundStaff(false);
  };

  const onDeleteEmployee = (id, e) => {
    SetEmployeeId(id);
    e.stopPropagation();
    SetToggleConFirmremoveEmployee(true);
  };

  const handleCloseConfirmRemoveEmployee = () => {
    SetToggleConFirmremoveEmployee(false);
    employeeId && SetEmployeeId('');
  };

  const handleConfirmRemoveEmployee = async () => {
    try {
      props.setLoading({ status: true });
      const params = {
        store: storeData.datas._id,
        employee: employeeId
      };

      await props.removeEmployeeRequest(params).then(res => {
        handleCloseConfirmRemoveEmployee();
        props.toggleNeedReloadMyStore(true);
        props.setLoading({ status: false });
        toastMessage(
          TOAST_TYPE.SUCCESS,
          t('toastMessage.success.content.Remove Employee Success')
        );
      });
    } catch (error) {
      props.setLoading({ status: false });
      toastMessage(TOAST_TYPE.ERROR, error.message);
    }
  };

  const renderEmployees = employees => {
    return employees.map(employee => {
      const { _id, avatar: avatarEmployee, firstName, lastName } = employee;
      const avatarUrl =
        avatarEmployee && Array.isArray(avatarEmployee)
          ? avatarEmployee[0]
          : avatarEmployee;
      return (
        <div
          className={clsx(mainClass.staffItem, mainClass.staffItemEmployee)}
          onClick={() => {
            onEditUserInfo();
            setEditUserInfo(employee);
          }}
          key={_id}>
          <IconButton
            aria-label="close"
            className={mainClass.closeButton}
            onClick={e => onDeleteEmployee(_id, e)}>
            <CloseIcon />
          </IconButton>
          <img
            src={avatarUrl || defaultAvatar}
            alt={firstName + lastName}
            className={mainClass.imgAvatar}
          />
          <p className={mainClass.fullName}>{firstName + ' ' + lastName}</p>
          <p className={mainClass.personType}>
            {t('mystore.staff.position.Employee')}
          </p>
        </div>
      );
    });
  };

  const { pageIndex, pages } = reviewsData;
  const showBtnLoadMore = pageIndex < pages;
  const textReview =
    reviewsData && reviewsData.datas && reviewsData.datas.length;

  const { owner, employees } = storeData.datas || {};

  const { avatar, firstName = '', lastName = '' } = owner || {};

  return (
    <div className={mainClass.overviewContainer}>
      <div className={mainClass.innerOverview}>
        <Container maxWidth="xl">
          {storeData && storeData.datas && isEmptyObject(storeData.datas) ? (
            <BannerOverview data={storeData.datas} />
          ) : null}
          <Grid className={mainClass.container} container spacing={3}>
            <Grid item lg={7} xs={12}>
              <div className={mainClass.ratings}>
                <Typography variant="h4" className={mainClass.title}>
                  {t('mystore.review.title.Review')} {'(' + textReview + ')'}
                </Typography>
                <div className={mainClass.ratingList}>
                  {reviewsData.datas.length
                    ? reviewsData.datas.map((item, idx) => (
                        <RatingItem key={idx} item={item} />
                      ))
                    : null}
                </div>
              </div>
              {showBtnLoadMore ? (
                <div className={mainClass.btnLoadmoreWrapp}>
                  <Button typeBtn="yellow" onClick={handleLoadMore}>
                    {t('action.Load more')}
                  </Button>
                </div>
              ) : null}
            </Grid>
            <Grid item lg={5} xs={12}>
              <div className={styleWallet.wrappWallet}>
                <div className={styleWallet.headerWallet}>
                  <Typography variant="h4" className={styleWallet.title}>
                    {t('mystore.wallet.title.Wallet')}
                  </Typography>
                  {walletsData.length < 2 ? (
                    <AddIcon
                      className={styleWallet.addWallet}
                      onClick={handleAddWallet}
                    />
                  ) : null}
                </div>
                <div className={styleWallet.walletList}>
                  {walletsData.map(wallet => (
                    <WalletItem key={wallet._id} data={wallet} />
                  ))}
                </div>
              </div>
              <div className={mainClass.staffsWrapper}>
                <div className={mainClass.titleStaff}>
                  <Typography variant="h4" className={mainClass.staffTitle}>
                    {t('mystore.staff.title.Staffs')}
                  </Typography>
                </div>

                <div className={mainClass.staffList}>
                  <div
                    className={mainClass.staffItem}
                    onClick={() => {
                      onEditUserInfo();
                      setEditUserInfo(owner);
                    }}>
                    <img
                      src={avatar || defaultAvatar}
                      alt={firstName + lastName}
                      className={mainClass.imgAvatar}
                    />
                    <p className={mainClass.fullName}>
                      {firstName + ' ' + lastName}
                    </p>
                    <p className={mainClass.personType}>
                      {t('mystore.staff.position.Owner')}
                    </p>
                  </div>
                  {employees && employees.length
                    ? renderEmployees(employees)
                    : null}
                  {/* {storeData.managers.map(item => {
                    return (
                      <div
                        className={clsx(
                          mainClass.staffItem,
                          mainClass.staffItemEmployee
                        )}
                        onClick={() => {
                          onEditUserInfo();
                          setEditUserInfo(item);
                        }}>
                        <IconButton
                          aria-label="close"
                          className={mainClass.closeButton}
                          onClick={e => onDeleteEmployee(item._id, e)}>
                          <CloseIcon />
                        </IconButton>

                        <img
                          src={item.avatar || defaultAvatar}
                          alt={item.firstName + item.lastName}
                          className={mainClass.imgAvatar}
                        />
                        <p className={mainClass.fullName}>
                          {item.firstName + ' ' + item.lastName}
                        </p>
                        <p className={mainClass.personType}>
                          {TEXT_ROLE[item.roleAction]}
                        </p>
                      </div>
                    );
                  })} */}
                  <div
                    className={clsx(mainClass.staffItem, mainClass.addEmployee)}
                    onClick={handleAddEmployee}>
                    <div className={mainClass.addEmployeeThumbWrapp}>
                      <AddIcon className={styleWallet.addEmployeethumbIcon} />
                    </div>
                    <p className={mainClass.fullName}>{t('action.Add')}</p>
                  </div>
                </div>
              </div>
            </Grid>
          </Grid>
        </Container>

        {openModalAddWallet ? (
          <AddWalletModal
            openModel={openModalAddWallet}
            handleClose={onCloseModalAddWallet}
            classOutSide={classModal}
          />
        ) : null}
        {props.openModalEditYourStore ? (
          <EditYourStoreModal
            openModel={props.openModalEditYourStore}
            handleClose={onCloseModalEditYourStore}
            classOutSide={classModal}
            storeID={profile.store?._id}
          />
        ) : null}
        {props.openModalEditUser ? (
          <EditUserInfoModal
            openModel={props.openModalEditUser}
            handleClose={onCloseModalEditUserInfo}
            classOutSide={classModal}
            data={editUserInfo}
            userLogin={profile}
            idStore={storeData.datas._id}
          />
        ) : null}
        {props.openModalSwitchBusiness ? (
          <SwitchBusinessModal
            openModel={props.openModalSwitchBusiness}
            handleClose={onCloseModalSwitchBusiness}
            classOutSide={classModal}
          />
        ) : null}
        {props.openModalAddBusiness ? (
          <AddBusinessModal
            openModel={props.openModalAddBusiness}
            handleClose={onCloseModalAddBusiness}
            classOutSide={classModal}
          />
        ) : null}
        {props.openModalThankyouAddBusiness ? (
          <ThankyouModalAddBusiness
            openModel={props.openModalThankyouAddBusiness}
            handleClose={onCloseModalThankyouAddBusiness}
            classOutSide={classModal}
            isCenter={true}
            hasBtnClose={false}
          />
        ) : null}
        {props.openModalEnterStaffPhone ? (
          <ModalEnterStaffPhone
            openModel={props.openModalEnterStaffPhone}
            handleClose={handleCloseModalEnterStaffPhone}
            classOutSide={classModal}
            idStore={storeData.datas._id}
          />
        ) : null}
        {props.openModalStaffNotFound ? (
          <ModalStaffNotFound
            openModel={props.openModalStaffNotFound}
            handleClose={handleCloseModalStaffNotFound}
            handleUseAnotherNumber={handleUseAnotherNumber}
            classOutSide={classModal}
          />
        ) : null}
        {props.openModalWaitingStaffPhone ? (
          <ModalStaffWaiting
            openModel={props.openModalWaitingStaffPhone}
            handleClose={handleCloseModalStaffWaiting}
            classOutSide={classModal}
          />
        ) : null}
        {props.openModalFoundStaff ? (
          <ModalFoundStaff
            openModel={props.openModalFoundStaff}
            handleClose={handleCloseModalFoundStaff}
            classOutSide={classModal}
            idStore={storeData.datas._id}
          />
        ) : null}
        {toggoleConFirmremoveEmployee ? (
          <AlertDialog
            open={toggoleConFirmremoveEmployee}
            handleClose={handleCloseConfirmRemoveEmployee}
            handleConfirm={handleConfirmRemoveEmployee}
            textTileDialog={``}
          />
        ) : null}
      </div>
    </div>
  );
};

const mapStateToProps = state => {
  return {
    profile: getUserProfile(state),
    hasWalletEdit: getHasWalletEdit(state),
    needReloadMyStore: getNeedReloadMyStore(state),
    openModalEditUser: getOpenModalEditUser(state),
    openModalFoundStaff: getOpenModalFoundStaff(state),
    openModalAddBusiness: getOpenModalAddBusiness(state),
    openModalEditYourStore: getOpenModalEditYouStore(state),
    openModalStaffNotFound: getOpenModalStaffNotFound(state),
    openModalSwitchBusiness: getOpenModalSwitchBusiness(state),
    openModalEnterStaffPhone: getOpenModalEnterStaffPhone(state),
    openModalWaitingStaffPhone: getOpenModalWaitingStaffPhone(state),
    openModalThankyouAddBusiness: getOpenModalThankyouAddBusiness(state)
  };
};

const mapDispatchToProps = dispatch => {
  return {
    setLoading: status => dispatch(actSetLoading(status)),
    fetchWalletsRequest: () => dispatch(fetchWallets({}, queryWallets)),
    fetchReviewsRequest: params => dispatch(fetchReviews(params, queryReviews)),
    fetchStoreRequest: params =>
      dispatch(fetchStore(params, queryStoreInMystore)),
    toggleModalEditYourStore: value =>
      dispatch(actToggleModalEditYourStore(value)),
    toggleNeedReloadMyStore: value =>
      dispatch(actToggleNeedreloadMyStore(value)),
    toggleModalSwitchBusiness: value =>
      dispatch(actToggleMoadlSwitchBusiness(value)),
    toggleModalEnterStaffPhone: value =>
      dispatch(actToggleModalEnterStaffPhone(value)),
    toggleModalWaitingStaffPhone: value =>
      dispatch(actToggleModalWaitingStaffPhone(value)),
    toggleModalStaffNotFound: value =>
      dispatch(actToggleModalStaffNotFound(value)),
    toggleModalThankyouAddBusiness: value =>
      dispatch(actToggleModalThankyouAddBusiness(value)),
    toggleModalEditUser: value => dispatch(actToggleModalEditUser(value)),
    toggleModalFoundStaff: value => dispatch(actToggleModalFoundStaff(value)),
    handleResetNeedFetchWallets: () => dispatch(actSetNeedFetchWallets(false)),
    toggleModalAddBusiness: value => dispatch(actToggleModalAddBusiness(value)),
    removeEmployeeRequest: params => dispatch(removeEmployee(params, null))
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Mystore);
