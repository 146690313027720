export const saveCredentials = credentials => {
  return localStorage.setItem('credentials', JSON.stringify(credentials));
};

export function getCredentials() {
  return JSON.parse(localStorage.getItem('credentials'));
}

export const destroyCredentials = () => {
  return localStorage.removeItem('credentials');
};
