import React from 'react';
import { Box } from '@material-ui/core';
import makeStyles from '@material-ui/core/styles/makeStyles';
import Skeleton from '@material-ui/lab/Skeleton';

const useStyle = makeStyles(theme => ({
  date: {
    margin: theme.spacing(0, 0, 0.8, 0)
  },
  content: {
    margin: theme.spacing(0, 0, 0.8, 0)
  }
}));

export default function NotificationSkeleton(props) {
  const classes = useStyle();
  return (
    <React.Fragment>
      <Box className={props.className}>
        <Skeleton className={classes.date} height={18} width="30%" />
        <Skeleton className={classes.content} height={21} width="85%" />
        <Skeleton className={classes.content} height={21} width="48%" />
      </Box>
      <Box className={props.className}>
        <Skeleton className={classes.date} height={18} width="30%" />
        <Skeleton className={classes.content} height={21} width="98%" />
        <Skeleton className={classes.content} height={21} width="45%" />
      </Box>
      <Box className={props.className}>
        <Skeleton className={classes.date} height={18} width="30%" />
        <Skeleton className={classes.content} height={21} width="85%" />
      </Box>
    </React.Fragment>
  );
}
