import { makeStyles } from '@material-ui/core';
import { primaryFont } from '../../../shared/theme';

const useStyle = makeStyles(theme => ({
  title: {
    marginTop: theme.spacing(3.6),
    marginBottom: theme.spacing(3),
    color: theme.palette.color.black
  },
  loginModal: {
    width: '100%',
    padding: '35px 95px 60px',
    textAlign: 'center',
    outline: 'none',
    alignItems: 'center',
    [theme.breakpoints.down('md')]: {
      padding: '35px 30px 60px'
    }
    // '& .MuiAutocomplete-root': {
    //   backgroundColor: `#f2f2f2`
    // },
    // '& .MuiOutlinedInput-root': {
    //   backgroundColor: `#f2f2f2`
    // }
  },
  desc: {
    marginTop: theme.spacing(2.4),
    marginBottom: 0,
    fontSize: 12,
    fontFamily: primaryFont,
    color: theme.palette.color.gray3,
    lineHeight: '18px',
    textAlign: 'initial'
  },
  wrappBtn: {
    marginTop: 40
  },
  styleDisable: {
    '& button': {
      cursor: 'default',
      color: theme.palette.color.gray,
      border: `1px solid transparent`,
      backgroundColor: '#f2f2f2',
      pointerEvents: 'none'
    },
    '& button:hover': {
      border: `1px solid transparent`,
      backgroundColor: '#ccc',
      color: '#f2f2f2'
    }
  },
  field: {
    margin: 0
  },
  inptRoot: {
    borderRadius: 4,
    backgroundColor: `#f2f2f2`
  },
  notchedOutline: {
    borderWidth: 0,
    borderColor: 'transparent',
    borderRadius: 4
  }
}));
export default useStyle;
