import { makeStyles } from '@material-ui/core';
import { primaryFont, secondaryFont } from '../../../shared/theme';

export const useStyle = makeStyles(theme => ({
  root: {},
  title: {
    borderBottom: `1px solid #E6E6E6`,
    padding: `0 40px 15px`
  },
  content: {
    padding: `20px 70px 0`
  },
  titlePrimary: {
    padding: `0 50px`,
    marginTop: 45,
    marginBottom: 20,
    fontFamily: secondaryFont,
    fontWeight: 600,
    color: theme.palette.color.black2,
    textAlign: 'center'
  },
  wrappImg: {
    display: 'flex',
    marginTop: 40,
    justifyContent: 'center'
  },
  imgAddStaff: {
    maxWidth: '100%'
  },
  wrappBtn: {
    position: 'absolute',
    bottom: 0,
    left: 0,
    right: 0,
    padding: '20px 40px'
  }
}));
