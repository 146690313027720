import React from 'react';
import ReactApexChart from 'react-apexcharts';
import { financial } from '../../utils/common';
import './style.css';

const StackedBar = props => {
  const { colors, xaxis, series, unit } = props;

  const options = {
    chart: {
      type: 'bar',
      stacked: true,
      toolbar: {
        show: false
      }
    },
    xaxis: {
      type: 'category',
      categories: xaxis || [],
      tooltip: {
        enabled: false
      }
    },
    yaxis: {
      hideOverlappingLabels: true,
      labels: {
        formatter: function(val) {
          return financial(val);
        }
      },
      title: {
        text: unit,
        // rotate: -90,
        // offsetX: 30,
        // offsetY: -185,
        style: {
          fontFamily: 'Helvetica, Arial, sans-serif',
          fontWeight: 600,
          cssClass: 'apexcharts-yaxis-title'
        }
      }
    },
    colors: colors,
    fill: {
      opacity: 1
    },
    legend: {
      show: false,
      position: 'top'
    },
    dataLabels: {
      enabled: false
    },
    tooltip: {
      enabled: true,
      shared: false,
      custom: props => {
        let total = props.series.reduce(
          (total, item) => total + item[props.dataPointIndex],
          0
        );

        total = unit ? '$' + total + ' ' + unit : total;
        let name = series[props.seriesIndex].name;
        let val = props.series[props.seriesIndex][props.dataPointIndex];
        val = unit ? '$' + val + ' ' + unit : val;
        return (
          '<div class="dialog">' +
          '<div>' +
          '<span>Total:</span> ' +
          '<span class="item-value">' +
          total +
          '</span>' +
          '</div>' +
          '<span>' +
          name +
          ':' +
          '</span> ' +
          '<span class="item-value">' +
          val +
          '</span>' +
          '</div>'
        );
      }
    }
  };

  return (
    <ReactApexChart options={options} series={series} type="bar" height={400} />
  );
};

export default StackedBar;
