import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import clsx from 'clsx';
import { useTranslation } from 'react-i18next';
import { Typography } from '@material-ui/core';
import Fade from '@material-ui/core/Fade';
import CloseIcon from '@material-ui/icons/Close';

import useStyle from './style';
import ProductService from '../../Orders/ProductService';
import TotalPrice from '../../Orders/TotalPrice';
import FormCustomer from '../../FormCustomer';
import PaymentInfo from '../../Orders/PaymentInfo';
import { deliveryStatus, actionsVendor } from '../../../shared/constant/const';
import Button from '../../Button';
import { vendorUpdateOrder } from 'maua-redux-core/actions/productOrder';
import { toastMessage, TOAST_TYPE } from '../../../utils/toastify-notify';

import handSaveImg from '../../../assets/img/icons/hand-save.svg';
import angleRightImg from '../../../assets/img/icons/angle-right.svg';
import arrowRightLongImg from '../../../assets/img/icons/arrow-right-long.svg';
import { getLoadingStatus } from '../../../store/selectors/loading';
import { getOpenDetailOrderItem } from '../../../store/selectors/orders';
import { actSetLoading } from '../../../store/actions/loading';
import { actSetNeedReloadCountProductOrders } from '../../../store/actions/orders';
import BackToDashBoard from '../../BackToDashBoard';

const SelfDelidetailOrderComplete = props => {
  const classess = useStyle();
  const [open, setOpen] = useState(false);
  const { t } = useTranslation();

  const {
    _id,
    code,
    amount,
    address,
    products,
    delivery,
    discount,
    customer,
    promotion,
    serviceFee,
    deliveryFee,
    paymentMethod,
    productsPrice
  } = props.data;

  useEffect(() => {
    if (props.loading?.status && props.showDetailOrderItem) {
      props.setLoading({ status: false });
    }
  }, [props.loading?.status]);

  useEffect(() => {
    return () => {
      setOpen(false);
    };
  }, [props.data]);

  const dataTotalPrice = {
    amount,
    discount,
    promotion,
    serviceFee,
    deliveryFee,
    productsPrice
  };

  const dataCustomer = {
    customer,
    address,
    productOrderID: _id
  };

  const dataPaymentMethod = {
    paymentMethod
  };

  const statusDelivery =
    delivery && delivery.length && delivery[delivery.length - 1].status;
  const isFullStep =
    statusDelivery === deliveryStatus.completed ||
    statusDelivery === deliveryStatus.failed;

  const { productActive, handleFailDelivery, handleCompleteDelivery } = props;
  const onFailDelivery = async ev => {
    ev.preventDefault();

    try {
      props.setLoading({ status: true });
      await props
        .handleFailDeliveryRequest(productActive, actionsVendor.fail)
        .then(res => {
          handleFailDelivery();
          props.handleToggleNeedReloadCountProductOrders(true);
          toastMessage(
            TOAST_TYPE.SUCCESS,
            `${t('toastMessage.success.content.Product Order to Fail')}`
          );
        });
    } catch (error) {
      props.setLoading({ status: false });
      toastMessage(TOAST_TYPE.ERROR, error.message);
    } finally {
      props.setLoading({ status: false });
    }
  };
  const onCompleteDelivery = async () => {
    try {
      props.setLoading({ status: true });
      await props
        .handleFailDeliveryRequest(productActive, actionsVendor.complete)
        .then(res => {
          handleCompleteDelivery();
          props.handleToggleNeedReloadCountProductOrders(true);
          toastMessage(
            TOAST_TYPE.SUCCESS,
            `${t('toastMessage.success.content.Product Order to Completed')}`
          );
        });
    } catch (error) {
      props.setLoading({ status: false });
      toastMessage(TOAST_TYPE.ERROR, error.message);
    } finally {
      props.setLoading({ status: false });
    }
  };

  const classInnerWrapp = isFullStep
    ? classess.innerComplete
    : classess.innerDefault;
  return (
    <div className={classess.wrapp}>
      {/* <BackToDashBoard handleBackDashboard={props.handleshowDashboards} /> */}
      <Typography variant="h4" className={classess.identity}>
        #{code}
      </Typography>
      <div className={classess.deliveryStatus}>
        <div className={clsx(classess.innerStatus, classInnerWrapp)}>
          <span className={classess.deliveryThumb}>
            <img
              src={handSaveImg}
              alt="Delivery to me"
              className={classess.imgThumb}
            />
            <span className={classess.textInfo}>
              {t('order.delivery.title.Delivery to me')}
            </span>
          </span>
          <span className={classess.deliveryType}>
            {t('order.delivery.label.Self delivery')}
          </span>
          {isFullStep && deliveryStatus.completed === statusDelivery ? (
            <span className={clsx(classess.deliveryFinal, classess.complete)}>
              {t('order.tab.title.Completed')}
            </span>
          ) : null}
          {isFullStep && deliveryStatus.failed === statusDelivery ? (
            <span
              className={clsx(classess.deliveryFinal, classess.fail)}
              onClick={() => setOpen(true)}>
              {t('order.delivery.process.status.Fail')}
              <span className={classess.angleRight}>
                <img
                  src={angleRightImg}
                  alt="angle Right"
                  className={classess.angleRightImg}
                />
              </span>
            </span>
          ) : null}
          <Fade in={open} className={classess.tooltipWarpp}>
            <div className={classess.tooltipInner}>
              <CloseIcon
                className={classess.iconClose}
                onClick={() => setOpen(false)}
              />
              <span className={classess.failTitle}>
                {t('order.delivery.process.title.Delivery Status')}
              </span>
              <div className={classess.listStatusWrapp}>
                <ul className={classess.listStatus}>
                  <li className={classess.itemStatus}>
                    <Typography
                      variant="subtitle1"
                      className={classess.titleStatus}>
                      {t('order.delivery.process.status.Waiting for driver')}
                    </Typography>
                    {/*<span className={classess.noteStatus}>10:00</span>*/}
                  </li>
                  <li className={classess.itemStatus}>
                    <Typography
                      variant="subtitle1"
                      className={classess.titleStatus}>
                      {t('text.Assigned')}
                    </Typography>
                    {/*<span className={classess.noteStatus}>10:00</span>*/}
                  </li>
                  <li className={clsx(classess.itemStatus)}>
                    <Typography
                      variant="subtitle1"
                      className={classess.titleStatus}>
                      {t('order.delivery.process.status.On delivery')}
                    </Typography>
                    {/*<span className={classess.noteStatus}>10:00</span>*/}
                  </li>
                  <li
                    className={clsx(
                      classess.itemStatus,
                      classess.itemStatusCurrent,
                      classess.itemFail
                    )}>
                    <Typography
                      variant="subtitle1"
                      className={classess.titleStatus}>
                      {t('order.delivery.process.status.Fail')}
                    </Typography>
                    <span className={classess.noteStatus}>
                      {(delivery &&
                        delivery.length &&
                        delivery[delivery.length - 1].reason) ||
                        ''}
                    </span>
                    <span
                      className={clsx(classess.iconCurrent, classess.bgFail)}>
                      <img
                        src={arrowRightLongImg}
                        alt="current status"
                        className={classess.arrowCurrent}
                      />
                    </span>
                  </li>
                </ul>
              </div>
            </div>
          </Fade>
        </div>
      </div>
      <div className={classess.main}>
        <ProductService products={products} />
        <TotalPrice
          methodDelivery={props?.methodDelivery}
          typePromote="white"
          dataTotalPrice={dataTotalPrice}
        />
        <FormCustomer dataCustomer={dataCustomer} />
        <PaymentInfo order={props.data} />
      </div>
      {isFullStep ? null : (
        <div className={classess.wrappBtn}>
          <Button typeBtn="btnFail" onClick={onFailDelivery}>
            {t('action.Fail Delivery')}
          </Button>
          <Button typeBtn="green" onClick={onCompleteDelivery}>
            {t('action.Complete Delivery')}
          </Button>
        </div>
      )}
    </div>
  );
};

const mapStateToProps = state => {
  return {
    loading: getLoadingStatus(state),
    showDetailOrderItem: getOpenDetailOrderItem(state)
  };
};

const mapDispatchToProps = dispatch => {
  return {
    handleFailDeliveryRequest: (_id, action) =>
      dispatch(vendorUpdateOrder({ _id, action })),
    handleCompleteDeliveryRequest: (_id, action) =>
      dispatch(vendorUpdateOrder({ _id, action })),
    setLoading: status => dispatch(actSetLoading(status)),
    handleToggleNeedReloadCountProductOrders: value =>
      dispatch(actSetNeedReloadCountProductOrders(value))
  };
};
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(SelfDelidetailOrderComplete);
