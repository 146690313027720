import { createSlice } from 'redux-starter-kit';

let initialState = {
  hasWalletEdit: false
};

export let walletsSlice = createSlice({
  initialState: initialState,
  slice: 'wallets',
  reducers: {
    setNeedFetchWallets(state, action) {
      state.hasWalletEdit = action.payload.state;
    }
  }
});

export default walletsSlice.reducer;
