import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import clsx from 'clsx';
import { useTranslation } from 'react-i18next';
import { Typography } from '@material-ui/core';
import BackToDashBoard from '../BackToDashBoard';
import ClickAwayListener from '@material-ui/core/ClickAwayListener';
import Stepper from '@material-ui/core/Stepper';
import Step from '@material-ui/core/Step';
import StepLabel from '@material-ui/core/StepLabel';
import StepContent from '@material-ui/core/StepContent';
import Button from '@material-ui/core/Button';
import Paper from '@material-ui/core/Paper';
import ErrorIcon from '@material-ui/icons/Error';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import { withStyles } from '@material-ui/core/styles';
import StepConnector from '@material-ui/core/StepConnector';
import useStyle from './style';

const Connector = withStyles({
  active: {
    '& $line': {
      backgroundColor: '#E38D03'
    }
  },
  completed: {
    '& $line': {
      backgroundColor: '#E38D03'
    }
  },
  root: {
    padding: 0
  },

  line: {
    width: 2,
    border: 0,
    backgroundColor: '#eaeaf0',
    borderRadius: 1
  }
})(StepConnector);

const SteopBar = ({ delivery, STATUS, STATUS_DELIVERY }) => {
  const classess = useStyle();
  const { t } = useTranslation();
  const [fail, setFail] = useState(-1);
  const [activeStep, setActiveStep] = useState(0);
  const steps = ['Waiting for driver', 'Assigned', 'On delivery', 'Completed'];

  useEffect(() => {
    let currentStatus =
      delivery?.status !== 'failed'
        ? STATUS[(delivery?.status)]
        : STATUS[(delivery?.prevStatus)];

    let step = STATUS_DELIVERY.findIndex(el => currentStatus === el);
    setFail(currentStatus === 'Failed' ? step : null);

    setActiveStep(step);
  }, [delivery]);

  return (
    <Stepper
      activeStep={activeStep}
      orientation="vertical"
      connector={<Connector />}>
      {steps.map((label, index) => {
        const labelProps =
          index === fail
            ? {
                error: true,
                icon: <ErrorIcon style={{ color: 'red' }} />
              }
            : {
                completed: index <= activeStep,
                classes: {
                  labelContainer: classess.labelContainer
                }
              };
        return (
          <Step key={label}>
            <StepLabel {...labelProps}>
              {t(`order.delivery.status.${label}`)}
            </StepLabel>
          </Step>
        );
      })}
    </Stepper>
  );
};

export default SteopBar;
