import React from 'react';
import ReactDOM from 'react-dom';
import PropTypes from 'prop-types';

class LoadMore extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      page: 1
    };
    this.myRef = React.createRef();
  }

  componentDidMount() {
    this.onListener();
  }

  componentDidUpdate() {
    this.onListener();

    if (this.props.isscrolltotop) {
      this._scrollToTop();
    }
    if (this.props.needscrollToBottom) {
      this._scrollToBottom();
    }
  }

  componentWillUnmount() {
    this._removeListener();
  }

  onListener = () => {
    window.addEventListener('scroll', this._scroll, true);
  };

  _removeListener = () => {
    window.removeEventListener('scroll', this._scroll, true);
  };

  _scrollPosition = () => {
    const el = ReactDOM.findDOMNode(this);
    const totalHeight = el.scrollHeight;
    const scrollTop = el.scrollTop;
    const heightHas = el.clientHeight;

    return totalHeight - scrollTop - heightHas;
  };

  _scroll = () => {
    const childrenSize = this.props.children.size;
    const isCurrentScrollTop = this.myRef.current.scrollTop;
    if (childrenSize <= 0) return;
    const heightNeedScroll = this.props.heightScroll || 0;
    if (
      this._scrollPosition() < heightNeedScroll &&
      isCurrentScrollTop &&
      !this.props.scrollReverse
    ) {
      this.setState({ page: this.state.page + 1 }, () => {
        this.props.handleLoadMore('newest', this.state.page);
      });
      this._removeListener();
    }

    if (
      this._scrollPosition() &&
      this._scrollPosition() - isCurrentScrollTop < heightNeedScroll &&
      this.props.scrollReverse
    ) {
      this.setState({ page: this.state.page + 1 }, () => {
        this.props.handleLoadMore('newest', this.state.page);
      });
      this._removeListener();
    }
    return;
  };

  _scrollToTop = () => {
    let _this = this;
    let timeoutScroll;
    timeoutScroll && clearTimeout(timeoutScroll);
    timeoutScroll = setTimeout(() => {
      _this.myRef.current.scrollTo(0, 0);
    }, 300);
  };

  _scrollToBottom = () => {
    let _this = this;
    let timeoutScroll;

    timeoutScroll && clearTimeout(timeoutScroll);
    timeoutScroll = setTimeout(() => {
      const scrollHeight = _this.myRef.current.scrollHeight;
      const height = _this.myRef.current.clientHeight;
      const maxScrollTop = scrollHeight - height;
      _this.myRef.current.scrollTop = maxScrollTop ? maxScrollTop : 0;
    }, 300);
  };

  render() {
    const classWrapp =
      (this.props.outsideclass && this.props.outsideclass.rootLoadMore) || {};
    return (
      <div className={classWrapp} {...this.props} ref={this.myRef}>
        {this.props.children}
      </div>
    );
  }
}

LoadMore.propTypes = {
  handleLoadMore: PropTypes.func.isRequired,
  children: PropTypes.node.isRequired
};

export default LoadMore;
