import { useTranslation } from 'react-i18next';
import { ALA_NUM_REGEX } from 'maua-redux-core/utils/format';

export const MAUA_PHONE = 'MAUA_PHONE';
export const MAUA_PLATFORM_CURRENCY = 'MAUA_CURRENCY';
export const MAUA_DEFAULT_LOCATION_KEY = 'MAUA_DEFAULT_LOCATION';
const APP_URL = process.env.REACT_APP_URL;
const APP_API = process.env.REACT_APP_API;
const APP_VU_API = process.env.REACT_APP_VU_API;

export const OrderProgress = {
  NEW: 'newOrders',
  INPROGRESS: 'inprogress',
  READY: 'ready',
  COMPLETE: 'complete',
  FAILED: 'failed'
};

export const SELFDELIVERY = 'selfDelivery';
export const DELIVERYSERVICE = 'deliveryService';

export const PRODUCT_ORDER_STATUS = {
  NEW: 'new',
  INPROGRESS: 'in progress',
  READY: ['ready for delivery', 'ready for pickup'],
  COMPLETED: 'completed',
  FAILED: 'failed'
};

export const DELIVERY_TYPE = {
  DELIVERY: {
    type: 'service',
    text: 'Delivery to me'
  },
  SELFPICKUP: {
    type: 'self-pickup',
    text: 'Pick up at store'
  }
};

export const PAYMENTMETHOD = {
  DIGICEL: {
    type: 'digicel',
    text: 'Digicel'
  },
  VODAFONE: {
    type: 'vodafone',
    text: 'Vodafone'
  }
};

export const actionsVendor = {
  accept: 'accept',
  reject: 'reject',
  ready: 'ready',
  selfDelivery: 'self-delivery',
  deliveryService: 'delivery-service',
  complete: 'complete',
  fail: 'fail',
  failed: 'failed',
  completed: 'completed'
};

export const DELIVERY_METHOD = {
  selfDelivery: 'self-delivery',
  deliveryService: 'delivery-service'
};

export const deliveryStatus = {
  new: 'new',
  assigned: 'assigned',
  reworked: 'reworked',
  onDelivery: 'on delivery',
  failed: 'failed',
  completed: 'completed'
};

export const typeWallet = {
  digicel: 'digicel',
  vodafone: 'vodafone'
};

export const titleRatingFollowStar = starRating => {
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const { t } = useTranslation();

  switch (starRating) {
    case 1:
      return t('mystore.review.rate.Very Bad');
    case 2:
      return t('mystore.review.rate.Poor');
    case 3:
      return t('mystore.review.rate.Just OK');
    case 4:
      return t('mystore.review.rate.Good');
    default:
      return t('mystore.review.rate.Excellent');
  }
};

export const NameDayInWeek = {
  1: 'Monday',
  2: 'Tuesday',
  3: 'Wednesday',
  4: 'Thursday',
  5: 'Friday',
  6: 'Saturday',
  7: 'Sunday'
};

export const TEXT_ROLE = {
  vendor_1: 'Owner',
  vendor_2: 'Employee',
  vendor_3: 'Employee',
  vendor_4: 'Employee'
  // vendor_1: 'Owner',
  // vendor_2: 'Staff level 2',
  // vendor_3: 'Staff level 3',
  // vendor_4: 'Staff level 4'
};

export const ROLE_MAP = [
  {
    label: 'Owner',
    value: 'vendor_1'
  },
  {
    label: 'Staff level 2',
    value: 'vendor_2'
  },
  {
    label: 'Staff level 3',
    value: 'vendor_3'
  },
  {
    label: 'Staff level 4',
    value: 'vendor_4'
  }
];

export const GENDER_MAP = [
  {
    label: 'Male',
    value: 'male'
  },
  {
    label: 'Female',
    value: 'female'
  }
];

const flagSamoa =
  'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACgAAAAeCAMAAABpA6zvAAAABGdBTUEAALGPC/xhBQAAAAFzUkdCAK7OHOkAAAAgY0hSTQAAeiYAAICEAAD6AAAAgOgAAHUwAADqYAAAOpgAABdwnLpRPAAAAPBQTFRFAABrAABsAABoBgZmAABpAQFrBABpAAByWgBF/wAA+wABBQVuAABnLS2Ft7fVFRV3AgJrAABqAgJjAABjICB+ioq7DQ1zCwttBARtcnKtJyeCAABlBARjAABiVFScsLDRBgZutbXUPT2OAABkBwdwISF9RUWTAgJmAQFpbGyqLy+DAABeAgJsAgJoDw9sEhJzCgpxAgBqBQJrS0mUz83iJyV+BAJrBgBoAABxWwBF/AABAABwAgJxNDqRq7jaFxyAAQFwBABuAAB3WQBI+wACMQBWMgBWMAJVJQJPMgJXNABVJABcegA4/wMA/gAA////43SQlwAAAAFiS0dET25mQUkAAAAJcEhZcwAAAEgAAABIAEbJaz4AAACnSURBVDjLY2CAAEYmZhZGVhCLjZ2Dk4sTA0DVcfPw8vEzCRBUKCgkLCIqxiIuiF8hq6CEpJS0jKycPBMhhQqKSsoqPKpqLATdqK4hIayppa1DUKEAg66evgFeXxuCgZG0sYmptBmIaW5hyWmFRaE1GNiw2NrZMzqAmI5OzpwuWBS6QoCbu4cnhOXl7YPVRDjLlxMvYPCDAU4EC6tCTiLBqMJRhUNSIQD8STwfK4h9AgAAACV0RVh0ZGF0ZTpjcmVhdGUAMjAxMy0xMC0wN1QxMzoxNToyNCswMjowMMCeQ2UAAAAldEVYdGRhdGU6bW9kaWZ5ADIwMTMtMTAtMDdUMTM6MTU6MjQrMDI6MDCxw/vZAAAAAElFTkSuQmCC';

const flagVanuatu =
  'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACgAAAAeCAMAAABpA6zvAAAABGdBTUEAALGPC/xhBQAAAAFzUkdCAK7OHOkAAAAgY0hSTQAAeiYAAICEAAD6AAAAgOgAAHUwAADqYAAAOpgAABdwnLpRPAAAApFQTFRFQgQAFTcUMYYzogAA0AAAzwAAzgAAiosAPyQAdQAAwwAA0QAA+/sA1dcAYV4ARQoAnQAAYmIAz88A+PgApacAQS4AZwEAvAAAAAAAHBwAiIgA7u4A4eMAb28AQA8AkgAAzAAAAwMAPT0At7cAtbgARzoAWwIAtQAADg4AaGgA3d0A6usAf4AAPBYAhQAAyQAAJiYAmJgA8vIAxMcAUEkAUQQArAAABQUASkoAxcUA7/AAj5IAPB8AeAAAxAAAFBQAeHgA5eUA0tQAXFkASAgAoQAAAQEAMDAAqakAoKMAPyoAbAAAvwAAIiIAVlYAZGQAS0sAEhIACQkAWVkA0dEA3N0AamkAQQ0AlgAAzQAAjIwAVVUAQkIAh4cAICAAiYkA5+cAsLMARTYAYAEAtgAAywAACwsAjY0ANDQAVFQADAwARUUAe3sAuLgA4eIAenoAOBMAQAAAQQAAUlIArKwAkJAADw8ADQ0A4+MAyMoAoKQAoaQAFRUAGBgAIyMAUFAAMzMAaWkA5OQAycgAoqAAo6EAY2MAjo4ANzcAUVEAfX0AX18APj4AubkA4eEAeXkAHzIMFDYUQUEAf38AmpoAi4sAHR0AiooAsrAAOkIFHU8eOJc5P6g/Hh4AMTEAWloA0tIA3NsAaWoBHDgRLXwwQKlAQKtAQKpAqqoAoaAAMDsHIFkjO548QaxB09IAWVsCGz0VMIUzBgYAxsYA7+8AkI8AKDcJI2MnPaI9mZkAxsQAS08DG0QZNI426+oAf34AITYMJ24rPqY/trUAPkUEHEwdN5U54uEAbm4BHTcQK3guP6lAQatB0NAApqQAMz0GH1UhOps71tUAXmABGzsTL4IyiokALDoJImAlPKE9////Sn83tQAAAAN0Uk5T/v7+lRaJLAAAAAFiS0dE2u4DJoIAAAAJcEhZcwAAAEgAAABIAEbJaz4AAAGuSURBVDjLY2BgZmFlIwIwsHNwcnETo5CHl49fgI2woQyCQsIiomLiBA1lkJCUkpaRlZNXYCGkUEJRSVlYRVVNnZuVgEIJCQ1NLW0dXT19FkIKJSQMDI2MTUzN8IQUVKGEuYWllbWNrR03IYUSEvYOjk7OLq6srIQUSri5e0h7enn7cONW6OvnHxAIpIOCQ0LDwiMiWXAoVIqKjvGPjQNpkYxPSExKTlFIxQBAhWnpBhmZWdk5biCVikq5efkFhUUYAKgwLkqxuEQjsLQM4tRyzYrKqmoMAFRYY1hbVy/ZkB4EVdjY1NzSigFAbmxrV+ro7OoGK1Ps6e3rnzARE4AUmk+aPGWqbBZI3bTpCTNmzpo9BxNAwnGu3zxzUPDMX7Bw0eIlS5ctxwTIAT5vhfTKVavXrF2+HK/Cmn7Hdes3bFy2bDlehZsCNm/Zum37DuzGIRQa7DTatXvP3rXLluNVqNGotW//gYOHcBoHUQjKCoePHD2GTxlI4bR46eMnTp46fWY5foVtZ4XPnb9wEb9xIIU8ly5fubp8GSF1yxmuXb9xk6BxIIWMTGsJGwcEAK2Qk7NAptKcAAAAJXRFWHRkYXRlOmNyZWF0ZQAyMDEzLTEwLTA3VDEzOjE0OjQwKzAyOjAwHXwFzwAAACV0RVh0ZGF0ZTptb2RpZnkAMjAxMy0xMC0wN1QxMzoxNDo0MCswMjowMGwhvXMAAAAASUVORK5CYII=';

// Region
export const COUNTRIES = {
  //staging
  [APP_URL]: {
    code: 'ws',
    text: 'Maua Samoa',
    firebaseDoc: 'maua',
    defaultCode: {
      value: '+685',
      flag: flagSamoa
    },
    formatAlaNum: ALA_NUM_REGEX.ws,
    i18nLink:
      'https://maua-staging.s3-ap-southeast-1.amazonaws.com/Locales/WS_vendor-web_{{lng}}.json',
    api: APP_API
  },

  ['vu-' + APP_URL]: {
    code: 'vu',
    text: 'Maua Vanuatu',
    firebaseDoc: 'maua-vanuatu',
    defaultCode: {
      value: '+678',
      flag: flagVanuatu
    },
    formatAlaNum: ALA_NUM_REGEX.vu,
    i18nLink:
      'https://maua-staging.s3-ap-southeast-1.amazonaws.com/Locales/VU_vendor-web_{{lng}}.json',
    api: APP_VU_API
  },

  // production
  'vendor.maua.app': {
    code: 'ws',
    text: 'Maua Samoa',
    firebaseDoc: 'maua',
    defaultCode: {
      value: '+685',
      flag: flagSamoa
    },
    formatAlaNum: ALA_NUM_REGEX.ws,
    i18nLink: process.env.REACT_APP_I18N,
    api: 'https://api.maua.app'
  },

  'vu-vendor.maua.app': {
    code: 'vu',
    text: 'Maua Vanuatu',
    firebaseDoc: 'maua-vanuatu',
    defaultCode: {
      value: '+678',
      flag: flagVanuatu
    },
    formatAlaNum: ALA_NUM_REGEX.vu,
    i18nLink: process.env.REACT_APP_I18N_VU,
    api: 'https://vu-api.maua.app'
  }
};
