import React from 'react';
import { useTranslation } from 'react-i18next';
import { Typography } from '@material-ui/core';
import AddIcon from '@material-ui/icons/Add';

import { useStyles } from './style';
import cameraUploadImg from '../../assets/img/icons/camera-upload.png';
const UploadComponent = props => {
  const mainClass = useStyles();
  const { t } = useTranslation();
  const { name, handleUpload, outSideStyle } = props;
  const styleUpload = outSideStyle || mainClass;

  return (
    <div className={styleUpload.addImg}>
      <AddIcon className={styleUpload.addIcon} />
      <Typography variant="body1" className={styleUpload.addText}>
        {t('action.Add image')}
      </Typography>
      <input
        type="file"
        name={name || 'uploadfile'}
        onChange={handleUpload}
        className={mainClass.uploadInput}
        // multiple="multiple"
        accept="image/*"
      />
    </div>
  );
};

export const UploadComponentHasImg = props => {
  const mainClass = useStyles();
  const { name, handleUpload, imgUrl, classOutside } = props;
  const styleUpload = classOutside || mainClass;
  return (
    <div className={styleUpload.wrappUpload}>
      {!imgUrl ? (
        <div className={styleUpload.wrappIcon}>
          <AddIcon className={styleUpload.addIcon} />
        </div>
      ) : (
        <div className={styleUpload.wrappImg}>
          <img
            src={imgUrl}
            alt="Personal"
            className={styleUpload.pictureUser}
          />
        </div>
      )}
      <img
        src={cameraUploadImg}
        className={styleUpload.cameraIcon}
        alt="icon upload"
      />
      <input
        type="file"
        name={name || 'uploadfile'}
        onChange={handleUpload}
        className={mainClass.uploadInput}
        // multiple="multiple"
        accept="image/*"
      />
    </div>
  );
};
export default UploadComponent;
