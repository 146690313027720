import { makeStyles } from '@material-ui/core';
import { initial } from 'lodash';
import { primaryFont } from '../../../shared/theme';

const useStyle = makeStyles(theme => ({
  root: {
    width: 400,
    marginTop: 15,
    border: '1px solid #C4C4C4',
    position: 'relative',
    boxShadow: '0px -4px 6px rgba(236, 236, 236, 0.24)',
    borderRadius: '10px',
    backgroundColor: '#fff',
    [theme.breakpoints.down('md')]: {
      width: '90vw'
    }
  },
  header: { borderBottom: '1px solid #C4C4C4', maxHeight: 68 },

  content: {
    paddingLeft: 0,
    paddingRight: 0,
    maxHeight: 500,
    overflow: 'auto'
  },
  date: {
    display: 'flex',
    justifyContent: 'flex-end'
  },
  chatBox: {},
  headChat: {
    display: 'flex',
    flexDirection: 'row',
    // justifyContent: 'space-between',
    alignItems: 'center',
    borderBottom: `1px solid #F2F2F2`,
    padding: '0 24px 24px'
  },
  arrowBack: {
    width: 16,
    height: 16,
    marginRight: 10,
    cursor: 'pointer',
    transition: 'all .35s',
    '&:hover': {
      transform: 'scale(1.1)'
    }
  },
  userItem: {
    display: 'flex',
    flexDirection: 'row',
    paddingLeft: 22,
    flex: 3
  },
  rightControll: {
    display: 'flex',
    flexDirection: 'row'
  },
  closeBtn: {
    top: 'auto',
    right: 'auto',
    position: 'static',
    color: theme.palette.color.orange
  },
  avatarUser: {
    display: 'flex',
    width: 32,
    height: 32,
    borderRadius: '50%'
  },
  infoUser: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start'
  },
  userName: {
    color: '#969292',
    margin: '0px 0px 8px',
    fontSize: '12px',
    fontWeight: '600',
    fontFamily: '"Helvetica", sans-serif'
  },
  userDesc: {
    color: '#333436',
    margin: '0',
    display: 'flex',
    padding: '10px',
    fontSize: '12px',
    background: '#e7e9ee',
    minHeight: '36px',
    wordBreak: 'break-word',
    alignItems: 'center',
    fontFamily: '"Helvetica", sans-serif',
    borderRadius: '0px 10px 10px 10px',
    fontWeight: 'bold',
    maxWidth: '70%'
  },

  rootLoadMore: {
    // scrollBehavior: 'smooth',
    position: 'relative',
    minHeight: 273,
    maxHeight: 273,
    paddingBottom: 5,
    overflowX: 'hidden',
    overflowY: 'auto',
    padding: '5px 16px',
    '&::-webkit-scrollbar': {
      width: 4
    },

    /* Track */
    '&::-webkit-scrollbar-track': {
      '-webkitBoxShadow': `inset 0 0 6px rgba(0,0,0,0.1)`,
      boxShadow: `inset 0 0 6px rgba(0,0,0,0.1)`,
      '-webkitBorderRadius': 10,
      borderRadius: 10
    },

    /* Handle */
    '&::-webkit-scrollbar-thumb': {
      '-webkitBorderRadius': 10,
      borderRadius: 10,
      backgroundColor: '#f5f5f5',
      '-webkitBoxShadow': `inset 0 0 6px rgba(0,0,0,0.3)`,
      boxShadow: `inset 0 0 6px rgba(0,0,0,0.3)`
    },
    '&::-webkitScrollbarThumb:window-inactive': {
      backgroundColor: '#f7f7f7'
    }
  },
  innerChat: {
    paddingRight: 2
  },
  footerChat: {
    padding: '0 8px',
    width: '100%'
  },
  wrappInputChat: {
    position: 'relative'
  },
  inputChat: {
    display: 'block',
    width: '100%',
    backgroundColor: theme.palette.color.white,
    border: `1px solid #F2F2F2`,
    borderRadius: 30,
    padding: '10px 85px 10px 24px',
    fontSize: 13,
    fontFamily: primaryFont,
    outline: 0
  },
  groupControll: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    position: 'absolute',
    right: 10,
    top: '50%',
    transform: 'translateY(-50%)',
    paddingRight: 10
  },
  btnUpload: {
    position: 'relative',
    backgroundColor: 'transparent',
    border: 'none',
    '& img': {
      position: 'relative',
      display: 'block',
      maxWidth: 22,
      maxHeight: 20,
      zIndex: -1
    },
    padding: 0,
    marginRight: 7,
    transition: 'all .35s',
    '&:hover': {
      transform: 'scale(1.1)'
    },
    outline: 0
  },
  viewInputGallery: {
    position: 'absolute',
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
    opacity: 0,
    fontSize: '10px',
    zIndex: 1,
    cursor: 'pointer'
  },
  btnSendMess: {
    backgroundColor: 'transparent',
    border: 'none',
    '& img': {
      display: 'block',
      maxWidth: 22,
      maxHeight: 22
    },
    padding: 0,
    marginLeft: 7,
    cursor: 'pointer',
    transition: 'all .35s',
    '&:hover': {
      transform: 'scale(1.1)'
    },
    outline: 0
  },
  itemChat: {
    paddingTop: 5
  },
  currentUser: {
    display: 'flex',
    justifyContent: 'flex-end'
  },
  userItemChat: {
    display: 'flex',
    flexDirection: 'row'
  },
  avatarUserChat: {
    minWidth: 'initial',
    width: 20,
    height: 20,
    borderRadius: '50%'
  },
  infoUserChat: {
    // flex: 3,
    paddingLeft: 12
  },
  userNameChat: {
    margin: 0,
    fontSize: 12,
    color: theme.palette.color.gray1,
    marginBottom: 5
  },
  userDescChat: {
    margin: 0,
    fontSize: 14,
    color: theme.palette.color.black2,
    fontFamily: primaryFont
  },
  userDescChatAns: {
    padding: '10px 19px',
    backgroundColor: 'rgba(247, 247, 247, 0.6)',
    borderTopRightRadius: 20,
    borderBottomLeftRadius: 20,
    borderBottomRightRadius: 20
  },
  userDescChatCur: {
    padding: '10px 19px',
    backgroundColor: 'rgba(255, 207, 98, .2)',
    borderTopRightRadius: 20,
    borderBottomLeftRadius: 20,
    borderTopLeftRadius: 20
  },
  wrappMedia: {
    display: 'flex',
    flexWrap: 'wrap'
  },
  media: {
    maxWidth: '100%',
    width: 'calc(100% / 3)',
    padding: 3,
    borderRadius: 5,
    display: 'block'
  },
  col2: {
    width: '50%'
  },
  col1: {
    width: '100%'
  },
  colDefaul: {
    width: 'calc(100% / 3)'
  },
  messageBox: {
    padding: '8px 0',
    alignItems: 'self-start',
    flexDirection: 'column'
  },
  messageAvatar: {
    minWidth: 'initial'
  },
  messageText: {
    margin: '0 10px',
    flex: 'initial',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start'
  },
  own: {
    alignItems: 'flex-end'
  },
  ownMessage: {
    alignItems: 'flex-end'
  },
  ownContent: {
    backgroundColor: theme.palette.primary.main,
    borderRadius: '10px 0px 10px 10px',
    fontWeight: 'bold'
  },
  nameUserChat: {
    marginTop: 4,
    color: '#808080',
    fontSize: 12
  },
  infoLocked: {
    fontSize: 14,
    color: '#808080',
    padding: '8px 16px'
  }
}));

export default useStyle;
