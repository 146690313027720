import { ordersSlice } from '../reducers/orders';

export const actToggleDetailOrderItem = state => {
  return ordersSlice.actions.toggleDetailOrdeItem({
    state
  });
};

export const setMethodDeliveryService = state => {
  return ordersSlice.actions.setDeliveryServiceMethod({
    state
  });
};

export const actSetNeedReloadCountProductOrders = state => {
  return ordersSlice.actions.setNeedReloadCountProductOrders({
    state
  });
};

export const actDestroyOrder = () => {
  return ordersSlice.actions.clearProductOrder();
};
