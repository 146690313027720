// all format date follow moment, not allow Date js

import React, { useCallback, useMemo, useRef, useState } from 'react';
import moment from 'moment';
import PropTypes from 'prop-types';
import {
  DateRangePicker,
  isInclusivelyAfterDay,
  isInclusivelyBeforeDay
} from 'react-dates';
import 'react-dates/initialize'; // initial style
import 'react-dates/lib/css/_datepicker.css'; // initial style

import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import RemoveIcon from '@material-ui/icons/Remove';
import ClearIcon from '@material-ui/icons/Clear';
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';
import CalendarTodayIcon from '@material-ui/icons/CalendarToday';
import ArrowForwardIosIcon from '@material-ui/icons/ArrowForwardIos';

import { onClickOutside } from '../../../utils/common';
import useStyles from './style';
import { useIsMobile } from 'src/utils/hook';

const DateRangePickerUI = props => {
  const {
    startlabel,
    endlabel,
    endDate,
    startDate,
    minDate,
    maxDate,
    onChange,
    NavPrev,
    NavNext,
    focusedInput,
    customClass,
    displayFormat,
    CustomArrowIcon,
    inputIconPosition,
    CustomInputIcon
  } = props;

  const [focused, setFocused] = useState(focusedInput);

  const classes = useStyles({ ...props, focused });

  const rootRef = useRef(null);

  const isMobile = useIsMobile();

  onClickOutside(rootRef, () => setFocused(null));

  const onFocusChange = e => {
    setFocused(e);
  };

  const handleClear = e => {
    e.stopPropagation();
    setFocused(null);
    onChange({});
  };

  const isOutsideRange = useCallback(
    day => {
      if ((!minDate && !maxDate) || Date.parse(minDate) === Date.parse(maxDate))
        return false;

      return (
        (minDate && !isInclusivelyAfterDay(day, moment(new Date(minDate)))) ||
        (maxDate && !isInclusivelyBeforeDay(day, moment(new Date(maxDate))))
      );
    },
    [minDate, maxDate]
    //return true when date out of side false when date in of side
  );

  const customInputIcon = useMemo(
    () =>
      !Boolean(startDate) && !Boolean(endDate) ? <CustomInputIcon /> : null,
    [startDate, endDate]
  );

  return (
    <Box className={classes.rootDateRangePicker}>
      <Box
        ref={rootRef}
        className={[classes.dateRangePicker, customClass].join(' ')}>
        <DateRangePicker
          focusedInput={focused}
          endDate={endDate ? moment(endDate, displayFormat) : null} // momentPropTypes.momentObj or null,
          startDate={startDate ? moment(startDate, displayFormat) : null} // momentPropTypes.momentObj or null,
          onDatesChange={onChange}
          onFocusChange={onFocusChange}
          displayFormat={displayFormat}
          hideKeyboardShortcutsPanel={true}
          inputIconPosition={inputIconPosition}
          startDatePlaceholderText={startlabel}
          endDatePlaceholderText={endlabel}
          startDateId="date_picker_start_id"
          endDateId="date_picker_end_id"
          navNext={<NavNext className={classes.leftArrow} />}
          navPrev={<NavPrev className={classes.rightArrow} />}
          isOutsideRange={isOutsideRange}
          customArrowIcon={<CustomArrowIcon />}
          customInputIcon={customInputIcon}
          numberOfMonths={isMobile ? 1 : 2}
        />
      </Box>

      <ClearIcon onClick={handleClear} className={classes.clearBtn} />
    </Box>
  );
};

DateRangePickerUI.propTypes = {
  onChange: PropTypes.func,
  months: PropTypes.number,
  NavNext: PropTypes.elementType,
  NavPrev: PropTypes.elementType,
  CustomInputIcon: PropTypes.elementType,
  minDate: PropTypes.instanceOf(Date),
  maxDate: PropTypes.instanceOf(Date),
  inputIconPosition: PropTypes.oneOf(['before', 'after']),
  width: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  height: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  startPlaceholder: PropTypes.string,
  endPlaceholder: PropTypes.string,
  endDate: PropTypes.instanceOf(Date),
  startDate: PropTypes.instanceOf(Date),
  focusedInput: PropTypes.oneOf(['before', 'after']),
  showClearDates: PropTypes.bool
};

DateRangePickerUI.defaultProps = {
  height: 40,
  width: 300,
  maxDate: null, // momentPropTypes.momentObj or null,
  minDate: null, // momentPropTypes.momentObj or null,
  endDate: null, // momentPropTypes.momentObj or null,
  startDate: null, // momentPropTypes.momentObj or null,
  focusedInput: null,
  showClearDates: true,
  inputIconPosition: 'after',
  CustomArrowIcon: RemoveIcon,
  NavPrev: ArrowBackIosIcon,
  NavNext: ArrowForwardIosIcon,
  CustomInputIcon: CalendarTodayIcon,
  displayFormat: 'DD/MM/YYYY',
  startlabel: 'From',
  endlabel: 'To'
};

export default DateRangePickerUI;
