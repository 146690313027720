import React from 'react';
import { Container } from '@material-ui/core';

import useStyle from './style';

const SubHeader = props => {
  const classes = useStyle();

  return (
    <section className={classes.wrapp}>
      <Container maxWidth="xl" className={classes.container}>
        {props.children}
      </Container>
    </section>
  );
};

export default SubHeader;
