import React, { useState } from 'react';
import clsx from 'clsx';
import { get } from 'lodash';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { useTranslation } from 'react-i18next';
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';

import Button from '../../../components/Button';
import SelectCountry from '../../FormFeild/SelectCountry';
import TextField from '@material-ui/core/TextField';

import { toastMessage, TOAST_TYPE } from '../../../utils/toastify-notify';
import { sendLoginOTP } from 'maua-redux-core/actions/user';
import { actSetLoading } from '../../../store/actions/loading';
import { isNumeric } from '../../../utils/common';

import useStyle from './style';

function EnterPhone(props) {
  const mainClass = useStyle();
  const { t } = useTranslation();
  const [code, setCode] = useState('');
  const [phoneNumber, setPhoneNumber] = useState({
    value: '',
    error: ''
  });

  // const isWhatsapp =
  //   get(props, 'location.search').split('=')[1] === 'whatsapp' || false;

  const handleChange = e => {
    const enterKey = [e.keyCode, e.which, e.charCode, e.code];

    if (enterKey.includes(13) && phoneNumber?.value?.length >= 7)
      return sendOTPApi();

    const value = e.target.value;

    let validInput = value.match(/(^[0-9]+$)/) || value === '';

    if (!validInput) return;

    setPhoneNumber(prevState => ({
      ...prevState,
      value,
      error: !value ? 'Phone number is required.' : ''
    }));
  };

  const sendOTPApi = async () => {
    try {
      const phone = phoneNumber?.value?.replace(/^0*/, '');

      props.setLoading({ status: true });
      await props
        .sendOTPRequest({
          phone: (code?.value + phone).replace(/( )/g, '')
          // isWhatsapp
        })
        .then(res => {
          props.handleSavePhonenumber({
            stepLogin: 'OTP',
            phoneNumber: (code?.value + phone).replace(/( )/g, '')
          });
          props.setLoading({ status: false });
        });
    } catch (error) {
      props.setLoading({ status: false });
      toastMessage(TOAST_TYPE.ERROR, error.message);
    }
  };

  const isValid = phoneNumber?.value && phoneNumber?.value.length >= 7;
  const styleWrappBtn = !isValid
    ? mainClass.styleDisable
    : mainClass.styleNomal;

  return (
    <div className={mainClass.loginModal}>
      <Typography variant="h4" className={mainClass.title}>
        {t('login.title.Enter your phone number')}
      </Typography>
      <div className={mainClass.content}>
        <Grid>
          <Box display="flex">
            <SelectCountry setCode={setCode} />

            <Box width="100%">
              <TextField
                value={phoneNumber?.value}
                error={phoneNumber?.error ? true : false}
                helperText={phoneNumber?.error}
                name="phone"
                margin="dense"
                label=""
                placeholder={t('mystore.label.Phone number')}
                variant="outlined"
                className={mainClass.field}
                fullWidth
                onKeyDown={handleChange}
                onChange={handleChange}
                InputProps={{
                  classes: {
                    root: mainClass.inptRoot,
                    notchedOutline: mainClass.notchedOutline
                  }
                }}
              />
            </Box>
          </Box>
        </Grid>

        <div className={clsx(mainClass.wrappBtn, styleWrappBtn)}>
          <Button
            typeBtn="yellow"
            type="button"
            disabled={!isValid}
            onClick={sendOTPApi}>
            {t('action.Next')}
          </Button>
        </div>
      </div>
    </div>
  );
}

const mapDispatchToProps = dispatch => {
  return {
    setLoading: status => dispatch(actSetLoading(status)),
    // sendFirebaseOTPRequest: params => dispatch(sendFirebaseOTP(params, null))
    sendOTPRequest: params => dispatch(sendLoginOTP(params, null))
  };
};

export default connect(
  null,
  mapDispatchToProps
)(withRouter(EnterPhone));
