import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { Box, Typography } from '@material-ui/core';
import StopIcon from '@material-ui/icons/Stop';
import { getStoreDashboard } from 'maua-redux-core/actions/store';
import { getUserProfile } from 'maua-redux-core/selectors/user';
import { toastMessage, TOAST_TYPE } from '../../utils/toastify-notify';
import SelectCustom from '../../components/FormFeild/SelectCustom';
import { optionsFullTime } from '../../utils/constant';
import EmptyIcon from '../../assets/img/empty.svg';
import PieChart from '../../components/PieChart';
import { theme } from '../../shared/theme';
import { useStyle } from './style';

const RateOfOrder = props => {
  const classes = useStyle();
  const { t } = useTranslation();
  const [series, setSeries] = useState([]);
  const [filter, setFilter] = useState(optionsFullTime[3]);
  const labels = ['Pick-up at store', 'Delivery to me'];
  const colors = ['#F1B45B', '#7dcea0'];

  const loadOrder = async () => {
    const payload = {
      type: 'GROUP_BY_DELIVERY_METHOD',
      startTime: filter.startTime,
      endTime: filter.endTime
    };

    try {
      let { getStoreDashboard } = await props.getStoreDashboard(payload);
      let selfPickup =
        getStoreDashboard?.groupByDeliveryMethod?.selfPickup || 0;
      let service = getStoreDashboard?.groupByDeliveryMethod?.service || 0;

      if (selfPickup || service) {
        return setSeries(() => [selfPickup, service]);
      }

      return setSeries(() => []);
    } catch (error) {
      toastMessage(TOAST_TYPE.ERROR, error.message);
    }
  };

  useEffect(() => {
    if (props.profile._id) {
      loadOrder();
    }
  }, [filter, props.profile]);

  const handleFilter = e => {
    const val = e.target.value;
    setFilter(() => optionsFullTime.filter(({ value }) => value === val)[0]);
  };

  return (
    <Box>
      <Box className={classes.background}>
        <Box className={classes.header}>
          <Typography variant="h4">
            {t('dashboard.title.Pick-up versus delivery-to-me Ratio')}
          </Typography>
          <SelectCustom
            options={optionsFullTime}
            value={filter?.value}
            onChange={handleFilter}
          />
        </Box>
        <Box className={classes.header} borderBottom="none !important">
          <Box className={classes.flex} justifyContent="flex-end" width="100%">
            <Box pr={2} className={classes.flex}>
              <StopIcon style={{ color: colors[0] }} />
              <Box className={classes.title}>
                {t('dashboard.ratio.label.Pick-up at store')}
              </Box>
            </Box>
            <Box className={classes.flex}>
              <StopIcon style={{ color: colors[1] }} />
              <Box className={classes.title}>
                {t('dashboard.ratio.label.Delivery to me')}
              </Box>
            </Box>
          </Box>
        </Box>
        <Box
          className={classes.flex}
          justifyContent="center"
          p={theme.spacing(2, 0)}>
          {series?.length === 0 ? (
            <img src={EmptyIcon} alt="empty" width={500} height={400} />
          ) : (
            <PieChart labels={labels} colors={colors} series={series} />
          )}
        </Box>
      </Box>
    </Box>
  );
};

export default connect(
  state => ({
    profile: getUserProfile(state)
  }),
  dispatch => ({
    getStoreDashboard: params =>
      dispatch(
        getStoreDashboard(params, 'groupByDeliveryMethod{ selfPickup service}')
      )
  })
)(RateOfOrder);
