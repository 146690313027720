import { makeStyles } from '@material-ui/core';
import { primaryFont, secondaryFont } from '../../../shared/theme';

const useStyle = makeStyles(theme => ({
  rootSelect: {
    '&:hover fieldset': {
      borderColor: `${theme.palette.color.orange} !important`
    }
  },
  wrapp: {
    // position: 'relative',
    backgroundColor: theme.palette.color.white,
    padding: theme.spacing(3, 0, 3, 2.5),
    minHeight: `calc(100vh - 136px)`
  },
  selectCustom: {
    marginLeft: '10px !important',
    minWidth: '100px'
  },
  inputSelect: {
    border: 'none',
    borderRadius: '20px',
    padding: '5px 25px 5px 15px !important',
    fontSize: '14px !important',
    minWidth: '50px !important',
    outline: 0
  },
  notchedOutline: {
    border: `1px solid #E38D03 !important`,
    borderRadius: '20px !important',
    outline: 0
  },
  head: {
    display: 'flex',
    flexDirection: 'row'
  },
  imgRevenue: {
    position: 'absolute',
    bottom: 0,
    left: 0,
    maxWidth: '100%'
  },
  orderInfo: {
    display: 'flex',
    flexDirection: 'column',
    marginTop: 15
  },
  orderItem: {
    // flex: '0 50%',
    // marginTop: 30
  },
  quantityNumber: {
    fontSize: 30,
    fontFamily: primaryFont,
    color: theme.palette.color.black3
  },
  descOrderType: {
    color: theme.palette.color.gray2
  },
  wrappRevenue: {
    marginBottom: 34,
    borderBottom: `1px solid #E6E6E6`,
    paddingBottom: 10
  },
  headBottom: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between'
  },
  headBottomLeft: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center'
  },
  headBottomRight: {
    display: 'flex',
    justifyContent: 'flex-start',
    paddingRight: 30,
    alignItems: 'center'
  },
  revenueUnit: {
    fontSize: 14,
    lineHeight: '26px',
    fontFamily: primaryFont,
    color: theme.palette.color.black1,
    paddingRight: 3
  },
  revenueNum: {
    fontSize: 30,
    fontFamily: primaryFont,
    color: theme.palette.color.black1
  },
  wrappChart: {
    marginBottom: 15,
    borderBottom: `1px solid #E6E6E6`,
    paddingBottom: 20
  },
  titleDonutChart: {
    fontFamily: secondaryFont,
    margin: 0,
    marginBottom: 15
  },
  defaultDonutChart: {
    minHeight: 120,
    width: '99%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    border: `1px solid #E6E6E6`,
    borderRadius: 12,
    fontFamily: primaryFont,
    color: theme.palette.color.gray2
  },
  wrappBarchart: {
    overflowX: 'auto',
    '&::-webkit-scrollbar': {
      width: 4,
      height: 4
    },

    /* Track */
    '&::-webkit-scrollbar-track': {
      '-webkitBoxShadow': `inset 0 0 6px rgba(0,0,0,0.1)`,
      boxShadow: `inset 0 0 6px rgba(0,0,0,0.1)`,
      '-webkitBorderRadius': 10,
      borderRadius: 10
    },

    /* Handle */
    '&::-webkit-scrollbar-thumb': {
      '-webkitBorderRadius': 10,
      borderRadius: 10,
      backgroundColor: '#f5f5f5',
      '-webkitBoxShadow': `inset 0 0 6px rgba(0,0,0,0.3)`,
      boxShadow: `inset 0 0 6px rgba(0,0,0,0.3)`
    },
    '&::-webkitScrollbarThumb:window-inactive': {
      backgroundColor: '#f7f7f7'
    }
  },
  barChatSpecialWrapp: {
    maxWidth: 400
  },
  defaultBarChart: {
    minHeight: 120,
    width: '99%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    border: `1px solid #E6E6E6`,
    borderRadius: 12,
    fontFamily: primaryFont,
    color: theme.palette.color.gray2,
    marginBottom: 24,
    marginTop: 25
  }
}));
export default useStyle;
