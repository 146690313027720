import React, { useEffect } from 'react';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import { useTranslation } from 'react-i18next';
import useStyle from './style';
import { getCredentials } from '../../shared/services/token';
import { getUserProfile } from 'maua-redux-core/selectors/user';
import { isEmptyObject } from '../../utils/common';

const PageNotFound = props => {
  const { t } = useTranslation();
  const classess = useStyle();
  const { profile, history } = props;
  useEffect(() => {
    if (!getCredentials() && !isEmptyObject(profile)) {
      history.push('/');
      return;
    }
  }, []);

  return (
    <div className={classess.container}>
      <div className={classess.inner}>
        <h2 className={classess.title}>{t('pagenotfound.title.SORRY')}</h2>
        <p className={classess.desc}>{t(`pagenotfound.content.text`)}</p>
      </div>
    </div>
  );
};

const mapStateToProps = state => {
  return {
    profile: getUserProfile(state)
  };
};
export default compose(
  withRouter,
  connect(mapStateToProps)
)(PageNotFound);
