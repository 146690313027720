import CardHeader from '@material-ui/core/CardHeader';
import Divider from '@material-ui/core/Divider';
import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';
import CloseIcon from '@material-ui/icons/Close';
import { channelsHistory } from 'maua-redux-core/actions/messageChannel';
import { getUserProfile } from 'maua-redux-core/selectors/user';
import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { queryChannelsHistory } from '../../../store/query/messenger';
import { ensureArray, isEmptyObject } from '../../../utils/common';
import { toastMessage, TOAST_TYPE } from '../../../utils/toastify-notify';
import CustomCard from '../../Card';
import LoadMoreComponent from '../../LoadMoreComponent';
import Item from './Channel';
import LoadingChannel from './LoadingChannels';
import useStyles from './style';

function ChannelScreen(props) {
  const classes = useStyles();
  const [isLoadMore, setIsLoadMore] = useState(true);
  const [isLoading, setLoading] = useState(true);
  const [dataChannel, setDataChannel] = useState({
    page: 0,
    docs: [],
    pages: 1,
    limit: 6
  });
  const hasToken = isEmptyObject(props.profile);
  const { handleShowChatBox, handleClose } = props;

  const getChannels = async params => {
    if (isLoadMore) {
      try {
        if (dataChannel.page < dataChannel.pages) {
          let payload = {
            page: dataChannel.page + 1,
            limit: params?.limit || dataChannel.limit
          };
          const { channelsHistory } = await props.getChannelsHistory(payload);

          setDataChannel(preData => ({
            docs: [...preData.docs, ...channelsHistory?.docs],
            page: channelsHistory.page,
            pages: channelsHistory.pages,
            limit: channelsHistory.limit
          }));
        }
        let timeout;
        if (timeout) clearTimeout(timeout);
        timeout = setTimeout(() => {
          setIsLoadMore(false);
        }, 400);
      } catch (error) {
        setIsLoadMore(false);
        toastMessage(TOAST_TYPE.ERROR, error.message);
      }
    }
  };

  useEffect(() => {
    let interval = null;
    if (interval) clearInterval(interval);
    interval = setInterval(() => {
      getChannels({});
    }, 4000);

    return () => clearInterval(interval);
  });

  useEffect(() => {
    let timeout;

    if (isLoadMore && hasToken) {
      getChannels({});
    }
    if (isLoading) {
      timeout && clearTimeout(timeout);
      timeout = setTimeout(() => {
        setLoading(false);
      }, 300);
    }
    return () => {
      clearTimeout(timeout);
      setIsLoadMore(false);
    };
  }, [hasToken, isLoadMore]);

  const handleScroll = () => {
    if (dataChannel.page >= dataChannel.pages) return;
    setIsLoadMore(true);
  };

  return (
    <CustomCard classRoot={classes.root}>
      <CardHeader
        action={
          <IconButton onClick={handleClose} aria-label="settings">
            <CloseIcon color="primary" fontSize="small" />
          </IconButton>
        }
        className={classes.header}
        title={<Typography variant="h4">Chat</Typography>}
      />
      <Divider />
      <LoadMoreComponent
        handleLoadMore={handleScroll}
        outsideclass={classes}
        loadFollowBottom={true}>
        {isLoading ? (
          <LoadingChannel />
        ) : (
          ensureArray(dataChannel.docs).map((el, i) => (
            <div key={el._id}>
              <Item handleShowChatBox={handleShowChatBox} channel={el} />
            </div>
          ))
        )}
      </LoadMoreComponent>
    </CustomCard>
  );
}

const mapStateToProps = state => {
  return {
    profile: getUserProfile(state)
  };
};

const mapDispatchToProps = dispatch => {
  return {
    getChannelsHistory: params =>
      dispatch(channelsHistory(params, queryChannelsHistory))
  };
};
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ChannelScreen);
