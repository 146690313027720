import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Field, Form, Formik } from 'formik';
import { connect } from 'react-redux';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import Dialog from '@material-ui/core/Dialog';
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import Fab from '@material-ui/core/Fab';
import { editUserProfile, logout } from 'maua-redux-core/actions/user';
import { DATE_FORMAT } from 'maua-redux-core/utils/format';
import {
  checkAuthentication,
  getUserProfile
} from 'maua-redux-core/selectors/user';
import { CustomTextField, DatePickerField, GenderSelectField } from './fields';
import { toastMessage, TOAST_TYPE } from '../../utils/toastify-notify';
import { actDestroyProfile } from '../../store/actions/user';
import { queryProfile } from '../../store/query/user';
import { profileSchema } from './schema';
import { useStyles } from './styles';
import DialogTitle from './title';

function InputProfileModal(props) {
  const [open, setOpen] = useState(false);
  const classes = useStyles();
  const { t } = useTranslation();

  function handleClose() {
    setOpen(false);

    props.logout();
    props.actDestroyProfile();
  }

  useEffect(() => {
    if (
      props.loggedIn &&
      !profileSchema.isValidSync(props.profile) &&
      props.profile._id
    ) {
      setOpen(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.profile]);

  return (
    <Dialog
      open={open}
      maxWidth="sm"
      onClose={handleClose}
      disableEscapeKeyDown
      disableBackdropClick>
      <Box mx={8} my={6}>
        <DialogTitle onClose={handleClose}>Welcome here!!!</DialogTitle>
        <Formik
          enableReinitialize
          initialValues={props.profile}
          validationSchema={profileSchema}
          onSubmit={({ firstName, lastName, birthday, gender }, actions) => {
            actions.setSubmitting(true);
            props.editUserProfile(
              { firstName, lastName, birthday, gender },
              queryProfile,
              () => {
                actions.setSubmitting(false);
                toastMessage(
                  TOAST_TYPE.SUCCESS,
                  `${t(
                    'toastMessage.success.content.You profile has been saved'
                  )}!`
                );
                setOpen(false);
              }
            );
          }}
          render={props => (
            <Form onSubmit={props.handleSubmit}>
              <DialogContent>
                <DialogContentText>
                  The start is a piece of cake. Let's get started with your info
                </DialogContentText>
                <Grid>
                  <Grid container item spacing={1}>
                    <Grid item xs={6}>
                      <Field
                        name="firstName"
                        margin="dense"
                        variant="outlined"
                        placeholder="First Name"
                        component={CustomTextField}
                      />
                    </Grid>
                    <Grid item xs={6}>
                      <Field
                        name="lastName"
                        margin="dense"
                        variant="outlined"
                        placeholder="Last Name"
                        component={CustomTextField}
                      />
                    </Grid>
                  </Grid>
                  <Grid item>
                    <Field
                      name="birthday"
                      placeholder="Day of Birth"
                      format={DATE_FORMAT}
                      component={DatePickerField}
                      onChange={date =>
                        props.setFieldValue(
                          'birthday',
                          date ? date.toISOString() : date
                        )
                      }
                    />
                  </Grid>
                  <Grid item>
                    <Field name="gender" component={GenderSelectField} />
                  </Grid>
                </Grid>
              </DialogContent>
              <DialogActions className={classes.actions} disableSpacing>
                <Fab
                  type="submit"
                  color="primary"
                  size="medium"
                  variant="extended"
                  aria-label="Done"
                  style={{ width: '100%' }}
                  disabled={!props.isValid || props.isSubmitting}
                  onClick={props.submitForm}>
                  Done
                </Fab>
              </DialogActions>
            </Form>
          )}
        />
      </Box>
    </Dialog>
  );
}

export default connect(
  state => ({
    profile: getUserProfile(state),
    loggedIn: checkAuthentication(state)
  }),
  { editUserProfile, logout, actDestroyProfile }
)(InputProfileModal);
