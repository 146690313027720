import { makeStyles } from '@material-ui/core';

const btnStyle = {
  width: 45,
  height: 45,
  borderRadius: '50%',
  background: 'transparent',
  border: 'none'
};
const useStyle = makeStyles(theme => ({
  carouselDefault: {},
  carouselContainer: {
    position: 'relative'
  },
  carousel: {
    overflowX: 'hidden',
    position: 'relative'
  },
  carouselItem: {
    flexShrink: 0
  },
  controlBtnGroup: {
    position: 'absolute',
    top: 0,
    right: 20
  },
  controlBtn: {
    ...btnStyle,
    cursor: 'pointer',
    '& i': {
      fontSize: 25
    },
    '&:hover': {
      background: 'white',
      boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.1)'
    },
    '&:hover i': {
      color: theme.palette.color.orange
    },
    outline: 0
  },
  controlBtnDisable: {
    ...btnStyle,
    '& i': {
      fontSize: 25
    }
  },
  controlBtnCenter: {
    width: 45,
    height: 45,
    borderRadius: '50%',
    background: 'rgba(0,0,0,0.3)',
    border: 'none',
    position: 'absolute',
    zIndex: 10,
    top: '50%',
    transform: 'translateY(-50%)',
    transition: 'all 0.3s',
    cursor: 'pointer',
    '& i': {
      fontSize: 25,
      color: 'white'
    },
    '&:hover': {
      background: 'white',
      boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.1)'
    },
    '&:hover i': {
      color: theme.palette.color.orange
    },
    outline: 0
  },
  controlCenterNext: {
    right: 10
  },
  controlCenterPrev: {
    left: 10
  },
  controlBtnDisableCenter: {
    display: 'none'
  }
}));
export default useStyle;
