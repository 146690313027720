import { Box } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import DeleteIcon from '@material-ui/icons/Delete';
import EditIcon from '@material-ui/icons/Edit';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import UnfoldMoreIcon from '@material-ui/icons/UnfoldMore';
import { isEmpty } from 'lodash';
import { deleteProducts, editProduct } from 'maua-redux-core/actions/product';
import { deleteProductDrafts } from 'maua-redux-core/actions/productDraft';
import { exportStoreMenu } from 'maua-redux-core/actions/store';
import React, { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { useHistory } from 'react-router';
import { AutoSizer, List } from 'react-virtualized';
import ImportIcon from '../../assets/img/import-icon.svg';
import AlertDialog from '../../components/AlertDialog';
import Button from '../../components/Button';
import { CurrencySymbol } from '../../components/CurrencySymbol';
import ExportFileModal from '../../components/ExportFileModal';
import SwitchCustom from '../../components/FormFeild/SwitchCustom';
import AddProductModal from '../../components/Products/AddProductModal';
import EditProductModal from '../../components/Products/EditProductModal';
import { importStoreMenuCollection } from '../../push-notification';
import {
  actToggleModalAddProduct,
  actToggleModalEditProduct
} from '../../store/actions/modal';
import { queryProduct } from '../../store/query/menu';
import {
  getOpenModalAddProduct,
  getOpenModalEditProduct
} from '../../store/selectors/modal';
import { downloadFile, onClickOutside } from '../../utils/common';
import { STATUS, STATUS_COLOR, STATUS_TEXT } from '../../utils/constant';
import {
  messageErrorLanguage,
  toastMessage,
  TOAST_TYPE
} from '../../utils/toastify-notify';
import { useStyle } from './style';

const StyleRow = withStyles(theme => ({
  root: {
    height: 48,
    display: 'flex',
    alignItems: 'center',
    color: theme.palette.color.black2,
    backgroundColor: theme.palette.action.hover,
    borderBottom: `1px solid ${theme.palette.color.white}`
  }
}))(Box);

const RightColumn = props => {
  const { t } = useTranslation();
  const classes = useStyle();
  const history = useHistory();
  const [productList, setProductList] = useState();
  const statusFilterRef = useRef(null);
  const [product, setProduct] = useState({});
  const [openStatusFilter, setOpenStatusFilter] = useState(false);
  const [statusFilter, setStatusFilter] = useState('all');
  const [sortFields, setSortFields] = useState({ actived: null });
  const [modal, setModal] = useState({
    open: false,
    data: {},
    title: ''
  });
  const subscribeImportStoreMenuCollectionRef = useRef(null);

  const OPTIONS_STATUS = {
    all: t('product.table.filter.status.All'),
    'waiting-approve': t('product.table.filter.status.Pending'),
    active: t('product.table.filter.status.Active'),
    inactive: t('product.table.filter.status.Inactive'),
    reject: t('product.table.filter.status.Reject')
  };

  onClickOutside(statusFilterRef, () => setOpenStatusFilter(false));

  useEffect(() => {
    handleFilterStatus(statusFilter);
  }, [props?.menus, props?.keyword, sortFields]);

  const cleanListener = () => {
    subscribeImportStoreMenuCollectionRef.current &&
      subscribeImportStoreMenuCollectionRef.current();
  };

  const handleListenImportFile = key => {
    subscribeImportStoreMenuCollectionRef.current = importStoreMenuCollection
      .doc(key)
      .onSnapshot(async snapshot => {
        const { success, error } = snapshot.data();

        if (success) {
          toastMessage(TOAST_TYPE.SUCCESS, 'Import file successfully.');
          await props.loadMenu();
          cleanListener();
        } else {
          messageErrorLanguage(error, t);
        }
        props.setLoading(false);
      });
  };

  // handle export file
  const handleExport = async filters => {
    props.setLoading(true);
    try {
      const { exportStoreMenu } = await props.exportStoreMenu({
        filters,
        store: props.storeID
      });
      downloadFile(exportStoreMenu);

      toastMessage(
        TOAST_TYPE.SUCCESS,
        `${t('toastMessage.success.content.Export file successfully')}`
      );
    } catch (error) {
      toastMessage(TOAST_TYPE.ERROR, error.message);
    } finally {
      props.setLoading(false);
    }
  };

  const handleRemove = async product => {
    try {
      const action = product?.isDraft
        ? props.deleteProductDrafts
        : props.deleteProducts;

      const message = product?.isDraft
        ? `${t('toastMessage.success.content.Product draft has been removed')}`
        : `${t('toastMessage.success.content.Product has been removed')}`;

      await action({ _ids: [product._id] });
      props.setMenus(prev => prev.filter(item => item._id !== product._id));

      toastMessage(TOAST_TYPE.SUCCESS, message);
    } catch (error) {
      toastMessage(TOAST_TYPE.ERROR, error.message);
    }
  };

  const handleStatus = async product => {
    const status = product?.status === 'active' ? 'inactive' : 'active';

    try {
      const payload = {
        _id: product?._id,
        status,
        of: product?.of
      };

      await props.editProduct(payload);
      props.setMenus(prev =>
        prev.map(item => {
          if (item._id === product._id) {
            item.status = status;
          }

          return item;
        })
      );

      toastMessage(
        TOAST_TYPE.SUCCESS,
        `${t(
          'toastMessage.success.content.Status of product has been updated'
        )}`
      );
    } catch (error) {
      toastMessage(TOAST_TYPE.ERROR, error.message);
    }
  };

  const handleConfirm = () => {
    if (modal.type === 'status') {
      handleStatus(modal.data);
    }

    if (modal.type === 'remove') {
      handleRemove(modal.data);
    }

    setModal(() => ({ open: false, data: {}, title: '' }));
  };

  const rowProducts = row => {
    const product = productList[row.index];

    const statusProduct = convertStatusProduct(product);

    return (
      <Box
        key={row.key}
        style={row.style}
        className={classes.flex + ' ' + classes.productRow}>
        <Box className={classes.row}>{product.name}</Box>
        <Box className={classes.row}>
          <CurrencySymbol>{product.discountedPrice || 0}</CurrencySymbol>
        </Box>
        <Box className={classes.row}>
          <CurrencySymbol>{product.price || 0}</CurrencySymbol>
        </Box>
        <Box className={classes.row}>
          {product.quantity?.toLocaleString('en') || 0}
        </Box>
        <Box className={classes.row}>
          {product.countOrder?.toLocaleString('en') || 0}
        </Box>
        <Box className={classes.row}>
          <Box
            className={classes.status}
            style={{ background: STATUS_COLOR[statusProduct] }}>
            {STATUS_TEXT[statusProduct]}
          </Box>
        </Box>
        <Box id="btnProdcut" className={classes.boxActions}>
          <EditIcon
            color="primary"
            onClick={() => {
              setProduct(() => product);
              props.openModalEditProduct(true);
            }}
          />
          {(product.status === 'active' || product.status === 'inactive') && (
            <SwitchCustom
              handleChange={() =>
                setModal(() => ({
                  open: true,
                  data: product,
                  type: 'status',
                  title: `${t(
                    'product.status.warning.This will change status of the product'
                  )}!`
                }))
              }
              checkedVal={STATUS[product.status]}
              title={
                STATUS[product.status]
                  ? t('product.status.Active')
                  : t('product.status.Inactive')
              }
            />
          )}

          {product.status === 'inactive' && (
            <DeleteIcon
              style={{ color: 'red' }}
              onClick={() =>
                setModal(() => ({
                  open: true,
                  data: product,
                  type: 'remove',
                  title: `${t(
                    'product.delete.title.Do you want to remove item'
                  )}!`
                }))
              }
            />
          )}
        </Box>
      </Box>
    );
  };

  const convertStatusProduct = prod =>
    (prod.isReject && 'reject') ||
    (prod.isDraft && 'waiting-approve') ||
    prod.status;

  const sortFollowStatus = (first, second) => {
    const valueStatus = {
      Pending: 1,
      Active: 0,
      Inactive: -1,
      Reject: -2
    };
    return valueStatus[STATUS_TEXT[second]] - valueStatus[STATUS_TEXT[first]];
  };

  const sortFunc = (first, second) => {
    if (typeof first === 'string' || typeof second === 'string') {
      return first.localeCompare(second);
    } else return first - second;
  };

  const sortData = (field, data, type) => {
    switch (true) {
      case type === 2:
        return data.sort((a, b) =>
          sortFollowStatus(convertStatusProduct(a), convertStatusProduct(b))
        );
      case type === 1:
        return data.sort((a, b) => sortFunc(a[field], b[field]));

      case type === 0:
        return data.sort((a, b) => sortFunc(b[field], a[field]));

      default:
        return data.sort((a, b) =>
          sortFollowStatus(convertStatusProduct(a), convertStatusProduct(b))
        );
    }
  };

  const handleSortProduct = field => {
    const selectedField = isNaN(+sortFields[field]) ? 2 : sortFields[field];

    const sortType = selectedField === 2 ? 0 : selectedField + 1;

    return setSortFields(prev => ({
      ...prev,
      [field]: sortType,
      actived: field
    }));
  };

  const handleFilterStatus = status => {
    const productDefaultList = props.menus;
    const keyword = props.keyword;
    const currentSortField = sortFields.actived;
    const currentSortType = currentSortField ? sortFields[currentSortField] : 2;

    let listCategoryHaveProduct = [];
    const listCategoryEmptyProduct = [];

    for (let cateIdx = 0; cateIdx < productDefaultList.length; cateIdx++) {
      const cate = productDefaultList[cateIdx];

      const temp = [];
      for (
        let prodIdx = cateIdx + 1;
        prodIdx < productDefaultList.length;
        prodIdx++
      ) {
        if (!productDefaultList[prodIdx].isCategory) {
          cateIdx += 1;

          const prod = productDefaultList[prodIdx];

          const productStatus = convertStatusProduct(prod);
          const lowerCaseProductName = prod.name.toLowerCase();
          const lowerCaseKeyword = keyword.toLowerCase();

          status !== 'all'
            ? productStatus === status &&
              lowerCaseProductName.includes(lowerCaseKeyword) &&
              temp.push(prod)
            : lowerCaseProductName.includes(lowerCaseKeyword) &&
              temp.push(prod);
        } else {
          break;
        }
      }

      if (!isEmpty(temp) || (status === 'all' && keyword === ''))
        listCategoryHaveProduct.push(cate);
      else listCategoryEmptyProduct.push(cate);
      listCategoryHaveProduct = [
        ...listCategoryHaveProduct,
        ...sortData(sortFields.actived, temp, currentSortType)
      ];
    }

    setStatusFilter(status);

    setProductList([...listCategoryHaveProduct, ...listCategoryEmptyProduct]);
  };

  const rowCategories = row => {
    const category = productList[row.index];

    if (category?.isCategory) {
      return (
        <Box key={row.key} style={row.style}>
          <StyleRow>
            <Box width="85%" pl={0.8}>
              {category?.name}
            </Box>
            <Box
              width="15%"
              display="flex"
              justifyContent="flex-end"
              paddingRight="1%">
              <Box
                className={classes.status}
                style={{
                  background: STATUS_COLOR[(category?.status)]
                }}>
                {STATUS_TEXT[(category?.status)]}
              </Box>
            </Box>
          </StyleRow>
        </Box>
      );
    }

    return rowProducts(row);
  };

  const handleRedirectImportProductPage = () => {
    history.push({
      pathname: 'products/import',
      state: props.statusMenu
    });
  };

  return (
    <Box className={classes.rightColumn}>
      <Box className={classes.rightHeader}>
        <Box className={classes.addBtnGroup}>
          <ExportFileModal
            handleExport={handleExport}
            type="white"
            style={{
              marginRight: '8px'
            }}
            optionFile={[
              {
                text: 'Main category',
                name: 'maincategory',
                status: false
              },
              {
                text: 'Sub category',
                name: 'subcategory',
                status: false
              },
              {
                text: 'Product s general info',
                name: 'product_general_info',
                status: false
              },
              {
                text: 'Product s varriant',
                name: 'variants',
                status: false
              }
            ]}
          />

          <Button
            typeBtn="yellow"
            className={`${classes.btnImport} ${classes.btnHeader}`}
            onClick={handleRedirectImportProductPage}>
            {t('action.Import')}
          </Button>
        </Box>
        <Button
          typeBtn="yellow"
          disabled={
            !props.optionSub?.length || props.statusMenu === 'submitted'
          }
          className={classes.btnAdd}
          onClick={() => props.openModalAddProduct(true)}>
          {t('action.Add product')}
        </Button>
      </Box>

      <Box className={classes.productHeader}>
        <Box
          onClick={() => handleSortProduct('name')}
          className={classes.row}
          style={{ paddingLeft: 8 }}>
          {t('product.table.col.Name')}
          <UnfoldMoreIcon className={classes.sortArrows} />
        </Box>
        <Box
          onClick={() => handleSortProduct('discountedPrice')}
          className={classes.row}>
          {t('product.table.col.Discounted')}
          <UnfoldMoreIcon className={classes.sortArrows} />
        </Box>
        <Box onClick={() => handleSortProduct('price')} className={classes.row}>
          {t('product.table.col.Price')}
          <UnfoldMoreIcon className={classes.sortArrows} />
        </Box>
        <Box
          onClick={() => handleSortProduct('quantity')}
          className={classes.row}>
          {t('product.label.Quantity')}
          <UnfoldMoreIcon className={classes.sortArrows} />
        </Box>
        <Box
          onClick={() => handleSortProduct('countOrder')}
          className={classes.row}>
          {t('product.table.col.Orders')}
          <UnfoldMoreIcon className={classes.sortArrows} />
        </Box>

        <Box
          onClick={() => setOpenStatusFilter(prev => !prev)}
          ref={statusFilterRef}
          className={classes.row}>
          {t('product.table.col.Status')}
          <KeyboardArrowDownIcon
            className={
              openStatusFilter
                ? [classes.filterIcon, classes.openFilterStatus].join(' ')
                : classes.filterIcon
            }
          />
          {openStatusFilter && (
            <Box className={classes.statusFilterDropdown}>
              {Object.keys(OPTIONS_STATUS).map((status, idx) => (
                <Box
                  className={
                    statusFilter === status
                      ? [classes.statusItem, classes.activeItem].join(' ')
                      : classes.statusItem
                  }
                  onClick={() => handleFilterStatus(status)}
                  key={idx}>
                  {OPTIONS_STATUS[status]}
                </Box>
              ))}
            </Box>
          )}
        </Box>
      </Box>
      <Box className={classes.productsContent}>
        <AutoSizer>
          {({ width, height }) => (
            <List
              width={width}
              height={height}
              rowHeight={48}
              rowCount={productList?.length || 0}
              rowRenderer={rowCategories}
              overscanRowCount={20}
              className={classes.list}
              scrollToIndex={props.scrollToIndex}
            />
          )}
        </AutoSizer>
      </Box>

      {props.showModalAddProduct && (
        <AddProductModal
          storeID={props.storeID}
          loadMenu={props.loadMenu}
          optionSub={props.optionSub}
          openModel={props.showModalAddProduct}
          handleClose={() => props.openModalAddProduct(false)}
          classOutSide={classes}
        />
      )}

      {props.showModalEditProduct && (
        <EditProductModal
          product={product}
          storeID={props.storeID}
          loadMenu={props.loadMenu}
          optionSub={props.optionSub}
          statusMenu={props.statusMenu}
          openModel={props.showModalEditProduct}
          handleClose={() => props.openModalEditProduct(false)}
          classOutSide={classes}
        />
      )}

      {modal.open && (
        <AlertDialog
          open={modal.open}
          handleClose={() =>
            setModal(() => ({ open: false, data: {}, title: '' }))
          }
          handleConfirm={handleConfirm}
          textTileDialog={t(modal.title)}
        />
      )}
    </Box>
  );
};

export default connect(
  state => ({
    showModalAddProduct: getOpenModalAddProduct(state),
    showModalEditProduct: getOpenModalEditProduct(state)
  }),
  dispatch => ({
    openModalAddProduct: value => dispatch(actToggleModalAddProduct(value)),
    openModalEditProduct: value => dispatch(actToggleModalEditProduct(value)),
    editProduct: payload => dispatch(editProduct(payload, queryProduct)),
    deleteProducts: payload => dispatch(deleteProducts(payload, 'n ok')),
    deleteProductDrafts: payload => dispatch(deleteProductDrafts(payload)),
    exportStoreMenu: payload => dispatch(exportStoreMenu(payload))
  })
)(RightColumn);
