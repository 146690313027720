import React from 'react';
import { Dialog } from '@material-ui/core';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';

const DialogModal = props => {
  const {
    onClose,
    className,
    open,
    title,
    fullWidth,
    maxWidth,
    ContentComponent,
    ActiontComponent
  } = props;
  return (
    <Dialog
      fullWidth={fullWidth}
      className={className}
      onClose={onClose}
      maxWidth={maxWidth || 'sm'}
      open={open}>
      {title && <DialogTitle>{title}</DialogTitle>}
      {ContentComponent && (
        <DialogContent>{<ContentComponent />}</DialogContent>
      )}
      {ActiontComponent && (
        <DialogActions>{<ActiontComponent />}</DialogActions>
      )}
    </Dialog>
  );
};

DialogModal.defaultProps = {
  fullWidth: true
};

export default DialogModal;
