import React from 'react';
import Skeleton from '@material-ui/lab/Skeleton';
import { Box } from '@material-ui/core';
import useStyles from './style';

const LoadingChannel = React.memo(() => {
  const classes = useStyles();
  const array = ['1', '2', '3', '4', '5'];
  return (
    <React.Fragment>
      {array.map((r, i) => (
        <Box key={i} className={classes.BodyWrapperItemChatBox}>
          <Box>
            <Skeleton
              style={{ marginRight: 10 }}
              variant="circle"
              width={40}
              height={40}
            />
          </Box>
          <Box className={classes.BodyChatBoxItem}>
            <Box className={classes.BodyChatBoxHeader}>
              <Box className={classes.BodyChatBoxHeaderName}>
                <Skeleton
                  style={{ margin: '0px 0px 3px 0px' }}
                  variant="text"
                  width="50%"
                />
              </Box>
            </Box>
            <Box className={classes.Message}>
              <Skeleton style={{ margin: 0 }} variant="text" width="80%" />
            </Box>
          </Box>
        </Box>
      ))}
    </React.Fragment>
  );
});

export default LoadingChannel;
