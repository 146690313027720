import React, { useState, useRef, Fragment } from 'react';
import { useTranslation } from 'react-i18next';
import { Box, Divider } from '@material-ui/core';
import ExportIcon from '../../assets/img/export-icon.svg';
import { onClickOutside } from '../../utils/common';
import { useStyles } from './style';

const ExportFile = props => {
  const { type, title, handleExport, optionFile, style } = props;
  const rootRef = useRef();
  const { t } = useTranslation();
  const classes = useStyles();
  const [show, setShow] = useState(false);
  const className =
    type === 'primary' ? classes.typePrimary : classes.typeDefault;
  onClickOutside(rootRef, () => setShow(false));

  const optionChooseFile = () => {
    return optionFile.map((file, index) => (
      <Fragment key={index}>
        <Box
          className={classes.file}
          onClick={e => {
            handleExport(file.value);
            e.stopPropagation();
          }}>
          {file.icon}
          <Box ml={1}>{file.text}</Box>
        </Box>
        <Divider />
      </Fragment>
    ));
  };

  const handleClickBtn = () => {
    if (optionFile?.length === 0) {
      return handleExport();
    }

    return setShow(!show);
  };

  return (
    <>
      <Box
        className={classes.btnExport + ' ' + className}
        onClick={handleClickBtn}
        style={style}
        ref={rootRef}>
        <img
          src={ExportIcon}
          alt="export"
          style={{ marginRight: 8, width: 20 }}
        />
        <Box>{t(`action.${title}`)}</Box>

        {show && <Box className={classes.optionFile}>{optionChooseFile()}</Box>}
      </Box>
    </>
  );
};

ExportFile.defaultProps = {
  type: 'primary',
  title: 'Export',
  optionFile: []
};

export default ExportFile;
