import { makeStyles } from '@material-ui/core';

const useStyle = makeStyles(theme => ({
  root: {
    height: '100%'
    // marginTop: 67
  },
  mainContent: {
    background: theme.palette.color.white2,
    overflow: 'hidden',
    minHeight: '100%'
  },
  mainContentFullPage: {
    marginTop: 68,
    background: theme.palette.color.white,
    overflow: 'auto',
    minHeight: 'calc(100% - 68px)',
    [theme.breakpoints.down('md')]: {
      height: 'calc(100% - 124px)',
      minHeight: 'calc(100% - 124px)'
    }
  },
  btnDownload: {
    backgroundColor: theme.palette.color.white,
    boxShadow: '6px 6px 10px rgba(0, 0, 0, 0.3)',
    borderRadius: 10,
    padding: theme.spacing(0, 2.4),
    height: 43,
    border: 'none',
    position: 'fixed',
    bottom: theme.spacing(2),
    right: theme.spacing(2),
    zIndex: theme.zIndex.appBar,
    outline: 'none',
    cursor: 'pointer',
    '& span': {
      fontWeight: 'bold',
      color: theme.palette.color.black2
    }
  },
  footer: {
    position: 'fixed',
    bottom: 0,
    left: 0,
    width: '100%',
    background: 'white',
    zIndex: 1099,
    boxShadow: '0px -4px 8px rgba(130, 126, 90, 0.1)',
    [theme.breakpoints.down('md')]: {
      zIndex: theme.spacing(2),
      height: '100%'
    }
  }
}));
export default useStyle;
