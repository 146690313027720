import { Box } from '@material-ui/core';
import React from 'react';
import OtpInput from 'react-otp-input';
import useStyle from './style';

export default function InputOTP(props) {
  const { value, numInputs, onChange, hasErrored } = props;
  const classes = useStyle();
  return (
    <Box className={classes.otpInput}>
      <OtpInput
        className={classes.otpInput}
        value={value}
        onChange={onChange}
        numInputs={numInputs}
        hasErrored={hasErrored}
        errorStyle={classes.error}
        shouldAutoFocus
      />
    </Box>
  );
}
