import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import clsx from 'clsx';
import { useTranslation } from 'react-i18next';
import { Typography } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import useStyle from '../style';
import ProductService from '../../Orders/ProductService';
import TotalPrice from '../../Orders/TotalPrice';
import FormCustomer from '../../FormCustomer';
import PaymentInfo from '../../Orders/PaymentInfo';
import handSaveImg from '../../../assets/img/icons/hand-save.svg';
import { getLoadingStatus } from '../../../store/selectors/loading';
import { getOpenDetailOrderItem } from '../../../store/selectors/orders';
import { actSetLoading } from '../../../store/actions/loading';
import StepBar from '../StepBar';
import Fade from '@material-ui/core/Fade';
import CloseIcon from '@material-ui/icons/Close';
import angleRightImg from '../../../assets/img/icons/arrow-right-orange.svg';
import ClickAwayListener from '@material-ui/core/ClickAwayListener';

import StepConnector from '@material-ui/core/StepConnector';

const Connector = withStyles({
  active: {
    '& $line': {
      backgroundColor: '#E38D03'
    }
  },
  completed: {
    '& $line': {
      backgroundColor: '#E38D03'
    }
  },
  root: {
    padding: 0
  },

  line: {
    width: 2,
    border: 0,
    backgroundColor: '#eaeaf0',
    borderRadius: 1
  }
})(StepConnector);

const STATUS = {
  new: 'Waiting for driver',
  'pickup arrived': 'Assigned',
  assigned: 'Assigned',
  'on delivery': 'On delivery',
  'destination arrived': 'On delivery',
  completed: 'Completed',
  failed: 'Failed',
  reworked: 'Reworked'
};

const STATUS_DELIVERY = [
  'Waiting for driver',
  'Assigned',
  'On delivery',
  'Completed'
];

const DeliveryServiceOrderComplete = props => {
  const classess = useStyle();
  const { t } = useTranslation();
  const [open, setOpen] = useState(false);
  const [newDelivery, setNewDelivery] = useState({});

  useEffect(() => {
    if (props.loading?.status && props.showDetailOrderItem) {
      props.setLoading({ status: false });
    }
  }, [props?.loading?.status]);

  const {
    _id,
    code,
    amount,
    address,
    products,
    customer,
    discount,
    subTotal,
    delivery,
    promotion,
    serviceFee,
    deliveryFee,
    paymentMethod,
    productsPrice
  } = props.data;

  useEffect(() => {
    setNewDelivery(
      (Array.isArray(delivery) &&
        delivery.length > 0 &&
        delivery[delivery.length - 1]) ||
        {}
    );
  }, [delivery]);

  useEffect(() => {
    if (props?.loading?.status && props.showDetailOrderItem) {
      props.setLoading({ status: false });
    }
  }, [props?.loading?.status]);

  useEffect(() => {
    return () => {
      setOpen(false);
    };
  }, [props.data]);

  const dataTotalPrice = {
    amount,
    discount,
    promotion,
    deliveryFee,
    serviceFee,
    productsPrice: subTotal || productsPrice
  };

  const dataCustomer = {
    customer,
    address,
    productOrderID: _id
  };

  const dataPaymentMethod = {
    paymentMethod
  };

  const handleClickAway = () => setOpen(false);

  return (
    <ClickAwayListener onClickAway={handleClickAway}>
      <div className={classess.wrapp}>
        {/* <BackToDashBoard handleBackDashboard={props.handleshowDashboards} /> */}
        <Typography variant="h4" className={classess.identity}>
          #{code}
        </Typography>
        <div className={classess.deliveryStatus}>
          <div className={classess.deliveryStatusWrapp}>
            <div className={classess.innerStatus}>
              <span className={classess.deliveryThumb}>
                <img
                  src={handSaveImg}
                  alt="Delivery to me"
                  className={classess.imgThumb}
                />
                <span className={classess.textInfo}>
                  {t('order.delivery.title.Delivery to me')}
                </span>
              </span>
              <span className={classess.deliveryType}>
                {t('order.delivery.label.Delivery Service')}
              </span>
              <span
                className={clsx(classess.deliveryFinal, classess.progress)}
                onClick={() => setOpen(op => !op)}>
                {t(`order.delivery.status.${STATUS[newDelivery.status]}`)}
                <span className={classess.angleRight}>
                  <img
                    src={angleRightImg}
                    alt="angle Right"
                    className={classess.angleRightImg}
                  />
                </span>
              </span>
            </div>

            <Fade in={open} className={classess.tooltipWarpp}>
              <div>
                <div className={classess.tooltipInner}>
                  <CloseIcon
                    className={classess.iconClose}
                    onClick={() => setOpen(false)}
                  />
                  <span className={classess.failTitle}>
                    {t('order.delivery.process.title.Delivery Status')}
                  </span>
                </div>
                <div>
                  <StepBar
                    STATUS={STATUS}
                    STATUS_DELIVERY={STATUS_DELIVERY}
                    delivery={newDelivery}
                  />
                </div>
              </div>
            </Fade>
          </div>
        </div>
        <div className={classess.main}>
          <ProductService products={products} />
          <TotalPrice
            methodDelivery={props?.methodDelivery}
            typePromote="white"
            dataTotalPrice={dataTotalPrice}
          />
          <FormCustomer dataCustomer={dataCustomer} />
          <PaymentInfo order={props.data} />
        </div>
      </div>
    </ClickAwayListener>
  );
};

const mapStateToProps = state => {
  return {
    loading: getLoadingStatus(state),
    showDetailOrderItem: getOpenDetailOrderItem(state)
  };
};

const mapDispatchToProps = dispatch => {
  return {
    setLoading: status => dispatch(actSetLoading(status))
  };
};
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(DeliveryServiceOrderComplete);
