import React from 'react';
import { useTranslation } from 'react-i18next';
import { Typography } from '@material-ui/core';
import StaffNotFoundImg from '../../../assets/img/StaffNotFound.png';
import ModalCustom from '../../ModalCustom';
import { useStyle } from './style';
import Button from '../../Button';

const ModalStaffNotFound = props => {
  const mainClass = useStyle();
  const { t } = useTranslation();

  const handleOnSubmit = () => {
    props.handleUseAnotherNumber();
  };

  return (
    <div className={mainClass.root}>
      <ModalCustom {...props}>
        <Typography variant="h6" className={mainClass.title}>
          {t('mystore.editStore.title.Add Staff')}
        </Typography>
        <div className={mainClass.wrappImg}>
          <img
            src={StaffNotFoundImg}
            alt="Add Staff"
            className={mainClass.imgAddStaff}
          />
        </div>
        <Typography variant="h4" className={mainClass.titlePrimary}>
          {t('mystore.staff.title.Staff Not Found')}
        </Typography>
        <div className={mainClass.wrappBtn}>
          <Button typeBtn="yellow" type="button" onClick={handleOnSubmit}>
            {t('action.Use another number')}
          </Button>
        </div>
      </ModalCustom>
    </div>
  );
};

export default ModalStaffNotFound;
