/************************************************************************
 *
 * Order Page has 2 type product order: Pick at store and Delivery
 * * step control: new orders -> inprogress -> ready -> complete || failed
 *
 *************************************************************************
 *
 * +Pick at store: folder in: components/PickupAtStore
 *
 * + Delivery: has 2 type: includes 3 folder:
 * + + Delivery / DeliveryService / SelfDelivery
 *************************************************************************
 *
 * + + Delivery: step inprogress common for Self and Service:
 * + + + folder in: components/Delivery
 *
 * + + SelfDelivery: folder in: components/SelfDelivery
 *
 * + + DeliveryService: folder in: components/DeliveryService
 *
 ************************************************************************/
import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Container } from '@material-ui/core';
import { withRouter } from 'react-router';
import Grid from '@material-ui/core/Grid';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { countNewMessagesQuery } from 'maua-redux-core/queries/messageChannel';
import AddBusinessModal from '../../components/Mystore/AddBusinessModal';
import useStyle from './style';
import useStyleModal from './styleModal';
import Navbar from '../../components/layout/Navbar';
import SubHeader from '../../components/layout/SubHeader';
import OverView from '../../components/Orders/OverView';
import PickupAtStoreConfirm from '../../components/ModalTypesOrders/PickupAtStoreConfirm';
import DetailNewOrderItem from '../../components/PickupAtStore/DetailNewOrderItem';
import DetailInprogressOrderItem from '../../components/PickupAtStore/DetailInprogressOrderItem';
import DetailReadyOrderItem from '../../components/PickupAtStore/DetailReadyOrderItem';
import DetailFailedOrderITem from '../../components/PickupAtStore/DetailFailedOrderITem';
import DetailCompleteOrderItem from '../../components/PickupAtStore/DetailCompleteOrderItem';
import ChoiseDeliveryServiceModal from '../../components/ModalTypesOrders/ChoiseDeliveryService';
import { default as DeliveryDetailOrderItemInprogress } from '../../components/Delivery/DetailOrderItemInprogress';
import SelfDeliDetailOrderReady from '../../components/SelfDelivery/SelfDeliDetailOrderReady';
import SelfDelidetailOrderComplete from '../../components/SelfDelivery/SelfDelidetailOrderComplete';
import SelfDelidetailOrderFail from '../../components/SelfDelivery/SelfDelidetailOrderFail';
import DeliveryServiceOderFail from '../../components/DeliveryService/DeliveryServiceOderFail';
import DeliveryServiceOderReady from '../../components/DeliveryService/DeliveryServiceOderReady';
import DeliveryServiceOrderComplete from '../../components/DeliveryService/DeliveryServiceOrderComplete';
import LoadMore from '../../components/LoadMoreComponent';
import OrderList from '../../components/Orders/OrderList';
import FilterComponent from './Filter';

// call api
import {
  fetchProductOrders,
  fetchProductOrderById
} from 'maua-redux-core/actions/productOrder';
import { getUserProfile } from 'maua-redux-core/selectors/user';
import { countProductOrders } from 'maua-redux-core/actions/productOrder';

import {
  getOpenModalPickupConfirm,
  getOpenModalReasonOrder,
  getOpenModalChoiseDelivery,
  getOpenModalAddBusiness
} from '../../store/selectors/modal';
import {
  actToggleModalPickupConfirm,
  actToggleModalReason,
  actToggleModalChoiseDelivery,
  actToggleModalAddBusiness
} from '../../store/actions/modal';
import {
  getOpenDetailOrderItem,
  getDeliveryServiceMethod,
  getNeedReloadCountProductOrders
} from '../../store/selectors/orders';
import {
  actToggleDetailOrderItem,
  actSetNeedReloadCountProductOrders
} from '../../store/actions/orders';
import {
  OrderProgress,
  // SELFDELIVERY,
  // DELIVERYSERVICE,
  PRODUCT_ORDER_STATUS,
  DELIVERY_TYPE,
  DELIVERY_METHOD
} from '../../shared/constant/const';
import { OrderListProvider } from './OrderContext';
import {
  queryOrderList,
  queryProductOrderById,
  queryCountProductOrders
} from '../../store/query/orders';
import { toastMessage, TOAST_TYPE } from '../../utils/toastify-notify';
import { isEmptyObject } from '../../utils/common';
import { actSetLoading } from '../../store/actions/loading';
import ChatComponents from '../../components/ChatComponents';
import Chat from '../../components/Chat';
import { useIsMobile } from 'src/utils/hook';
import Drawer from 'src/components/Drawer';

const listOrderType = [
  'newOrders',
  'inprogress',
  'ready',
  'complete',
  'failed'
];

const OrdersPage = props => {
  const classess = useStyle();
  const { t } = useTranslation();
  const classessModal = useStyleModal();
  const isMobile = useIsMobile();
  const [openDetail, setOpenDetail] = useState(false);
  const { NEW, INPROGRESS, READY, COMPLETE, FAILED } = OrderProgress;
  const hasToken = isEmptyObject(props.userProfile);
  const [itemActive, setItemActive] = useState('newOrders');
  const [formFilter, setFormFilter] = useState({
    code: '',
    customerName: '',
    phone: '',
    deliveryMethod: '',
    paymentMethod: '',
    from: null,
    to: null
  });

  const isFilter = () => {
    for (let key in formFilter) {
      if (formFilter.hasOwnProperty(key)) {
        if (formFilter[key]) return formFilter[key];
      }
    }
    return false;
  };

  const [open, setOpen] = useState({
    filter: false,
    export: false
  });

  const [toTop, setToTop] = useState(false);
  const [dataNewOrders, setDataNewOrders] = useState({
    newOrders: {
      data: [],
      page: 1,
      pages: 10
    },
    inprogress: {
      data: [],
      page: 1,
      pages: 10
    },
    ready: {
      data: [],
      page: 1,
      pages: 10
    },
    complete: {
      data: [],
      page: 1,
      pages: 10
    },
    failed: {
      data: [],
      page: 1,
      pages: 10
    }
  });
  const [orderType, setOrderType] = useState('');
  const [dataProductDetail, setDataProductDetail] = useState({});
  const [productActive, setProductActive] = useState('');
  const { match, history } = props;
  const { params } = match;
  const [methodDelivery, setMethodDelivery] = useState('');

  const [reasonFail, setReasonFail] = useState('');

  const loadProductOrders = () => {
    props.setLoading({ status: true });
    updateDataOrders()
      .then(({ productOrders }) => {
        const { docs, page, pages } = productOrders;
        switch (itemActive) {
          case NEW:
            setDataNewOrders(prevData => {
              return {
                ...prevData,
                newOrders: {
                  data: docs,
                  page,
                  pages
                }
              };
            });
            break;
          case INPROGRESS:
            setDataNewOrders(prevData => {
              return {
                ...prevData,
                inprogress: {
                  data: docs,
                  page,
                  pages
                }
              };
            });
            break;
          case READY:
            setDataNewOrders(prevData => {
              return {
                ...prevData,
                ready: {
                  data: docs,
                  page,
                  pages
                }
              };
            });
            break;
          case COMPLETE:
            setDataNewOrders(prevData => {
              return {
                ...prevData,
                complete: {
                  data: docs,
                  page,
                  pages
                }
              };
            });
            break;
          case FAILED:
            setDataNewOrders(prevData => {
              return {
                ...prevData,
                failed: {
                  data: docs,
                  page,
                  pages
                }
              };
            });
            break;
          default:
            setDataNewOrders(prevData => {
              return {
                ...prevData,
                newOrders: {
                  data: docs,
                  page,
                  pages
                }
              };
            });
            break;
        }

        props.setLoading({ status: false });
      })
      .catch(err => {
        props.setLoading({ status: false });
        toastMessage(TOAST_TYPE.ERROR, err.message);
      });
  };

  const [dataNavbarHomePage, setDataNavbarHomePage] = useState([
    {
      text: 'order.progress.New Orders',
      active: true,
      num: 0,
      id: 'newOrders'
    },
    {
      text: 'order.progress.In Progress',
      active: false,
      num: 0,
      id: 'inprogress'
    },
    {
      text: 'order.progress.Ready',
      num: 0,
      id: 'ready'
    },
    {
      text: 'order.progress.Complete',
      active: false,
      num: 0,
      id: 'complete'
    },
    {
      text: 'order.progress.Failed',
      active: false,
      num: 0,
      id: 'failed'
    }
  ]);

  useEffect(() => {
    if (!listOrderType.includes(params.orderType)) {
      history.push('/pagenotfound');
      return;
    }
  }, [params.orderType]);

  // check param url order type has diffrent itemActive in local when page load
  useEffect(() => {
    if (
      params.orderType !== itemActive &&
      listOrderType.includes(params.orderType)
    ) {
      setItemActive(params.orderType);
    }
  }, [itemActive]);

  //fetch data when change item using type url Or get default page load
  useEffect(() => {
    setToTop(true);
    if (formFilter.from && formFilter.to && formFilter.from > formFilter.to)
      return toastMessage(
        TOAST_TYPE.ERROR,
        'From date should be less than to date'
      );

    if (hasToken && itemActive) {
      props.setLoading({ status: true });
      handleFetchCountProductOrders(formFilter);
      updateDataOrders(1, formFilter)
        .then(({ productOrders }) => {
          const { docs, page, pages } = productOrders;
          switch (itemActive) {
            case NEW:
              setDataNewOrders(prevData => {
                return {
                  ...prevData,
                  newOrders: {
                    data: docs,
                    page,
                    pages
                  }
                };
              });
              break;
            case INPROGRESS:
              setDataNewOrders(prevData => {
                return {
                  ...prevData,
                  inprogress: {
                    data: docs,
                    page,
                    pages
                  }
                };
              });
              break;
            case READY:
              setDataNewOrders(prevData => {
                return {
                  ...prevData,
                  ready: {
                    data: docs,
                    page,
                    pages
                  }
                };
              });
              break;
            case COMPLETE:
              setDataNewOrders(prevData => {
                return {
                  ...prevData,
                  complete: {
                    data: docs,
                    page,
                    pages
                  }
                };
              });
              break;
            case FAILED:
              setDataNewOrders(prevData => {
                return {
                  ...prevData,
                  failed: {
                    data: docs,
                    page,
                    pages
                  }
                };
              });
              break;
            default:
              setDataNewOrders(prevData => {
                return {
                  ...prevData,
                  newOrders: {
                    data: docs,
                    page,
                    pages
                  }
                };
              });
              break;
          }
          setToTop(false);
          props.setLoading({ status: false });
        })
        .catch(err => {
          setToTop(false);
          props.setLoading({ status: false });
          toastMessage(TOAST_TYPE.ERROR, err.message);
        });
    }
  }, [hasToken, itemActive, formFilter]);

  useEffect(() => {
    if (hasToken || props.needReloadCountProductOrders) {
      handleFetchCountProductOrders(formFilter);
      if (props.needReloadCountProductOrders) {
        props.handleToggleNeedReloadCountProductOrders(false);
      }
    }
  }, [hasToken, props.needReloadCountProductOrders]);

  const handleFetchCountProductOrders = async params => {
    const payload = {
      startTime: params?.from,
      endTime: params?.to,
      deliveryMethod: params?.deliveryMethod,
      code: params?.code,
      phone: params?.phone,
      customerName: params?.customerName,
      paymentMethod: params?.paymentMethod
    };

    Object.keys(payload).forEach(key => !payload[key] && delete payload[key]);
    if (payload.deliveryMethod === 'All') delete payload.deliveryMethod;
    if (payload.paymentMethod === 'All') delete payload.paymentMethod;

    try {
      await props.onFetchCountProductOrders(payload).then(res => {
        const { new: newPr, inProgress, ready, completed, failed } = res;
        setDataNavbarHomePage(prevData => {
          return prevData.map(item => {
            let numCount = 0;
            switch (item.id) {
              case 'newOrders':
                numCount = newPr;
                break;
              case 'inprogress':
                numCount = inProgress;
                break;
              case 'ready':
                numCount = ready;
                break;
              case 'complete':
                numCount = completed;
                break;
              case 'failed':
                numCount = failed;
                break;
              default:
                numCount = newPr;
                break;
            }
            return {
              ...item,
              num: numCount
            };
          });
        });
      });
    } catch (error) {
      toastMessage(TOAST_TYPE.ERROR, error.message);
    }
  };

  const updateDataOrders = async (pageIndex, params) => {
    let statusForCaller = '';
    const { newOrders, inprogress, ready, complete, failed } = dataNewOrders;
    let pageCurrent;

    switch (itemActive) {
      case NEW:
        statusForCaller = PRODUCT_ORDER_STATUS.NEW;
        pageCurrent = pageIndex || newOrders.page;
        break;
      case INPROGRESS:
        statusForCaller = PRODUCT_ORDER_STATUS.INPROGRESS;
        pageCurrent = pageIndex || inprogress.page;
        break;
      case READY:
        statusForCaller = PRODUCT_ORDER_STATUS.READY;
        pageCurrent = pageIndex || ready.page;
        break;
      case COMPLETE:
        statusForCaller = PRODUCT_ORDER_STATUS.COMPLETED;
        pageCurrent = pageIndex || complete.page;
        break;
      case FAILED:
        statusForCaller = PRODUCT_ORDER_STATUS.FAILED;
        pageCurrent = pageIndex || failed.page;
        break;
      default:
        statusForCaller = PRODUCT_ORDER_STATUS.NEW;
        pageCurrent = pageIndex || newOrders.page;
        break;
    }

    const payload =
      itemActive === READY
        ? {
            page: pageCurrent,
            statuses: statusForCaller,
            sortBy: '-updatedAt',
            ...params
          }
        : {
            page: pageCurrent,
            status: statusForCaller,
            sortBy: '-updatedAt',
            ...params
          };
    Object.keys(payload).forEach(key => !payload[key] && delete payload[key]);
    if (payload.deliveryMethod === 'All') delete payload.deliveryMethod;
    if (payload.paymentMethod === 'All') delete payload.paymentMethod;
    return props
      .fetchDataProductOrder(payload, queryOrderList)
      .then(response => {
        return response;
      });
  };

  const handleChangeItemActive = id => {
    if (id === itemActive) return;
    loadProductOrders();
    handleFetchCountProductOrders(formFilter);
    history.push(`/orders/${id}`);
    setItemActive(id);
    setDataNewOrders({
      newOrders: {
        data: [],
        page: 1,
        pages: 10
      },
      inprogress: {
        data: [],
        page: 1,
        pages: 10
      },
      ready: {
        data: [],
        page: 1,
        pages: 10
      },
      complete: {
        data: [],
        page: 1,
        pages: 10
      },
      failed: {
        data: [],
        page: 1,
        pages: 10
      }
    });
    setProductActive('');
    props.showDetailOrderItem && props.handleShowDetailOrderItem(false);
    // }
  };

  const fetchDataDetailITemById = _id => {
    props
      .onFetchProductOrderById(_id)
      .then(({ productOrder }) => {
        setDataProductDetail(productOrder);
        !props.showDetailOrderItem && props.handleShowDetailOrderItem(true);
      })
      .catch(err => {
        props.setLoading({ status: false });
        toastMessage(TOAST_TYPE.ERROR, err.message);
      });
  };

  const handleShowDetailItem = currentItem => {
    let debounceTimer;
    const { deliveryMethod, _id, delivery, reason } = currentItem;
    setOrderType(deliveryMethod);
    setProductActive(_id);

    if (
      itemActive !== NEW &&
      itemActive !== INPROGRESS &&
      deliveryMethod === DELIVERY_TYPE.DELIVERY.type
    ) {
      const deliveryMethodType = delivery[0].deliveryMethod;
      setMethodDelivery(deliveryMethodType);
    }

    if (
      itemActive === FAILED &&
      deliveryMethod === DELIVERY_TYPE.SELFPICKUP.type
    ) {
      setReasonFail(reason);
    } else {
      if (reasonFail) {
        setReasonFail(reason);
      }
    }

    clearTimeout(debounceTimer);

    debounceTimer = setTimeout(() => {
      fetchDataDetailITemById(_id);
      // Not show detail on first load
      productActive !== '' && setOpenDetail(true);
    }, 100);
  };

  const renderDetailOrderPageRight = () => {
    if (itemActive === NEW && props.showDetailOrderItem) {
      return (
        <DetailNewOrderItem
          productActive={productActive}
          handleAcceptProduct={handleAcceptProductOrder}
          data={dataProductDetail}
          handleshowDashboards={handleshowDashboards}
        />
      );
    }

    if (itemActive === INPROGRESS && props.showDetailOrderItem) {
      if (orderType === DELIVERY_TYPE.DELIVERY.type) {
        return (
          <DeliveryDetailOrderItemInprogress
            productActive={productActive}
            data={dataProductDetail}
            handleshowDashboards={handleshowDashboards}
          />
        );
      }

      return (
        <DetailInprogressOrderItem
          productActive={productActive}
          handleReadyForPickup={handleReadyForPickup}
          data={dataProductDetail}
          handleshowDashboards={handleshowDashboards}
        />
      );
    }

    if (itemActive === READY && props.showDetailOrderItem) {
      if (orderType === DELIVERY_TYPE.DELIVERY.type) {
        return methodDelivery === DELIVERY_METHOD.selfDelivery ? (
          <SelfDeliDetailOrderReady
            methodDelivery={methodDelivery}
            data={dataProductDetail}
            handleshowDashboards={handleshowDashboards}
          />
        ) : (
          <DeliveryServiceOderReady
            methodDelivery={methodDelivery}
            data={dataProductDetail}
            handleshowDashboards={handleshowDashboards}
          />
        );
      }

      return (
        <DetailReadyOrderItem
          data={dataProductDetail}
          productActive={productActive}
          methodDelivery={methodDelivery}
          handleReadyFail={handleReadyFail}
          handleReadyComplete={handleReadyComplete}
          handleshowDashboards={handleshowDashboards}
        />
      );
    }

    if (itemActive === FAILED && props.showDetailOrderItem) {
      if (orderType === DELIVERY_TYPE.DELIVERY.type) {
        return methodDelivery === DELIVERY_METHOD.selfDelivery ? (
          <SelfDelidetailOrderFail
            data={dataProductDetail}
            handleshowDashboards={handleshowDashboards}
          />
        ) : (
          <DeliveryServiceOderFail
            data={dataProductDetail}
            handleshowDashboards={handleshowDashboards}
          />
        );
      }
      return (
        <DetailFailedOrderITem
          reasonFail={reasonFail}
          data={dataProductDetail}
          handleNextOrder={handleNextOrder}
          handleshowDashboards={handleshowDashboards}
        />
      );
    }

    if (itemActive === COMPLETE && props.showDetailOrderItem) {
      if (orderType === DELIVERY_TYPE.DELIVERY.type) {
        return methodDelivery === DELIVERY_METHOD.selfDelivery ? (
          <SelfDelidetailOrderComplete
            methodDelivery={methodDelivery}
            handleCompleteDelivery={handleCompleteDelivery}
            handleFailDelivery={handleFailDelivery}
            productActive={productActive}
            data={dataProductDetail}
            handleshowDashboards={handleshowDashboards}
          />
        ) : (
          <DeliveryServiceOrderComplete
            methodDelivery={methodDelivery}
            data={dataProductDetail}
            handleshowDashboards={handleshowDashboards}
          />
        );
      }

      return (
        <DetailCompleteOrderItem
          methodDelivery={methodDelivery}
          handleNextOrder={handleNextOrder}
          data={dataProductDetail}
          handleshowDashboards={handleshowDashboards}
        />
      );
    }

    // return <OverView />;
  };

  const handleLoadMore = () => {
    const { newOrders, inprogress, ready, complete, failed } = dataNewOrders;
    let pageIndex,
      totalPage = 0;
    switch (itemActive) {
      case NEW:
        totalPage = newOrders.pages;
        pageIndex = newOrders.page + 1;
        break;
      case INPROGRESS:
        totalPage = inprogress.pages;
        pageIndex = inprogress.page + 1;
        break;
      case READY:
        totalPage = ready.pages;
        pageIndex = ready.page + 1;
        break;
      case COMPLETE:
        totalPage = complete.pages;
        pageIndex = complete.page + 1;
        break;
      case FAILED:
        totalPage = failed.pages;
        pageIndex = failed.page + 1;
        break;
      default:
        totalPage = newOrders.pages;
        pageIndex = newOrders.page + 1;
        break;
    }

    try {
      if (pageIndex > totalPage) return;
      if (pageIndex <= totalPage) {
        props.setLoading({ status: true });

        updateDataOrders(pageIndex, formFilter).then(({ productOrders }) => {
          const { docs, page, pages } = productOrders;
          switch (itemActive) {
            case NEW:
              setDataNewOrders(prevData => {
                return {
                  ...prevData,
                  newOrders: {
                    data: [...prevData.newOrders.data, ...docs],
                    page,
                    pages
                  }
                };
              });
              break;
            case INPROGRESS:
              setDataNewOrders(prevData => {
                return {
                  ...prevData,
                  inprogress: {
                    data: [...prevData.inprogress.data, ...docs],
                    page,
                    pages
                  }
                };
              });
              break;
            case READY:
              setDataNewOrders(prevData => {
                return {
                  ...prevData,
                  ready: {
                    data: [...prevData.ready.data, ...docs],
                    page,
                    pages
                  }
                };
              });
              break;
            case COMPLETE:
              setDataNewOrders(prevData => {
                return {
                  ...prevData,
                  complete: {
                    data: [...prevData.complete.data, ...docs],
                    page,
                    pages
                  }
                };
              });
              break;
            case FAILED:
              setDataNewOrders(prevData => {
                return {
                  ...prevData,
                  failed: {
                    data: [...prevData.failed.data, ...docs],
                    page,
                    pages
                  }
                };
              });
              break;
            default:
              setDataNewOrders(prevData => {
                return {
                  ...prevData,
                  newOrders: {
                    data: [...prevData.newOrders.data, ...docs],
                    page,
                    pages
                  }
                };
              });
              break;
          }
          props.setLoading({ status: false });
        });
      }
    } catch (error) {
      props.setLoading({ status: false });
      toastMessage(TOAST_TYPE.ERROR, error.message);
    }
  };

  const getDataCurrentScreen = () => {
    const { newOrders, inprogress, ready, complete, failed } = dataNewOrders;
    let dataRender = [];
    switch (itemActive) {
      case NEW:
        dataRender = newOrders.data;
        break;
      case INPROGRESS:
        dataRender = inprogress.data;
        break;
      case READY:
        dataRender = ready.data;
        break;
      case COMPLETE:
        dataRender = complete.data;
        break;
      case FAILED:
        dataRender = failed.data;
        break;
      default:
        dataRender = newOrders.data;
        break;
    }
    return dataRender;
  };

  // handle in all screen products orders
  // screen new Orders
  const onClosePickupConfirm = () => {
    props.handleClosePickupConfirm();
  };

  const handleRefreshDetailProductNewOrder = () => {
    setDataNewOrders(prevData => {
      return {
        ...prevData,
        newOrders: {
          data: prevData.newOrders.data.filter(
            item => item._id !== productActive
          ),
          page: prevData.newOrders.page,
          pages: prevData.newOrders.pages
        }
      };
    });
    setProductActive('');
    props.handleShowDetailOrderItem(false);
    setDataProductDetail({});
    setOrderType('');
  };

  const handleRejectProduct = () => {
    handleRefreshDetailProductNewOrder();
    props.handleClosePickupConfirm();
  };

  const handleAcceptProductOrder = () => {
    handleRefreshDetailProductNewOrder();
  };
  //end screen new orders

  //screen Inprogress
  const onCloseModalChoiseDelivery = () => {
    props.handleCloseModalChoiseDelivery(false);
  };

  const handleRefreshDetailProductInprogress = () => {
    setDataNewOrders(prevData => {
      return {
        ...prevData,
        inprogress: {
          data: prevData.inprogress.data.filter(
            item => item._id !== productActive
          ),
          page: prevData.inprogress.page,
          pages: prevData.inprogress.pages
        }
      };
    });
    setProductActive('');
    props.handleShowDetailOrderItem(false);
    setDataProductDetail({});
    setOrderType('');
  };

  const handleReadyForPickup = () => {
    handleRefreshDetailProductInprogress();
  };

  const handleReadyForDelivery = () => {
    handleRefreshDetailProductInprogress();
    props.handleCloseModalChoiseDelivery(false);
  };
  //end Screen Inprogress

  //screen ready
  const onCloseModalReason = () => {
    props.handleCloseModalReason();
  };

  const handleRefreshDetailProductReady = () => {
    setDataNewOrders(prevData => {
      return {
        ...prevData,
        ready: {
          data: prevData.ready.data.filter(item => item._id !== productActive),
          page: prevData.ready.page,
          pages: prevData.ready.pages
        }
      };
    });
    setProductActive('');
    props.handleShowDetailOrderItem(false);
    setDataProductDetail({});
    setOrderType('');
  };

  const handleReadyFail = () => {
    handleRefreshDetailProductReady();
    // props.handleCloseModalReason();
  };

  const handleReadyComplete = () => {
    handleRefreshDetailProductReady();
  };
  //end screen ready

  // on screen complete self delivery
  const handleRefreshDetailProductDeliveryComplete = () => {
    setDataNewOrders(prevData => {
      return {
        ...prevData,
        complete: {
          data: prevData.complete.data.filter(
            item => item._id !== productActive
          ),
          page: prevData.complete.page,
          pages: prevData.complete.pages
        }
      };
    });
    setProductActive('');
    props.handleShowDetailOrderItem(false);
    setDataProductDetail({});
    setOrderType('');
  };

  const handleCompleteDelivery = () => {
    handleRefreshDetailProductDeliveryComplete();
  };

  const handleFailDelivery = () => {
    handleRefreshDetailProductDeliveryComplete();
  };
  //end screen complete selfdelivery

  // handle next order of screen complete | failed
  const handleNextOrder = () => {
    setOpenDetail(false);
    handleChangeItemActive('newOrders');
  };
  // end handle next order

  const dataRenderScreeen = getDataCurrentScreen();

  const hasDataRenderForScreen = dataRenderScreeen && dataRenderScreeen.length;

  //handle show overview
  const handleshowDashboards = () => {
    props.handleShowDetailOrderItem(false);
    setProductActive('');
  };
  //end handle show overview

  // set default show detail right
  useEffect(() => {
    if (
      !productActive &&
      hasDataRenderForScreen &&
      !props.showDetailOrderItem
    ) {
      const itemDetail = dataRenderScreeen[0];
      props.handleShowDetailOrderItem(true);
      handleShowDetailItem(itemDetail);
    }
  }, [itemActive, hasDataRenderForScreen]);

  const onCloseModalAddBusiness = () => {
    props.toggleModalAddBusiness(false);
  };

  const handleClosePopupDetail = () => {
    setOpenDetail(false);
  };

  return (
    <React.Fragment>
      <SubHeader>
        <Navbar
          isFilter={isFilter()}
          data={dataNavbarHomePage}
          open={open}
          setOpen={setOpen}
          itemActive={itemActive}
          changeItemActive={handleChangeItemActive}
        />
      </SubHeader>

      {open?.filter && (
        <FilterComponent
          setToTop={setToTop}
          formFilter={formFilter}
          setFormFilter={setFormFilter}
        />
      )}

      {props.openModalAddBusiness ? (
        <AddBusinessModal
          openModel={props.openModalAddBusiness}
          handleClose={onCloseModalAddBusiness}
          classOutSide={classessModal}
        />
      ) : null}

      <section className={classess.mainOrderPage}>
        <Container maxWidth="xl">
          <Grid container spacing={3}>
            <Grid item xs={12} lg={7} className={classess.padr0}>
              <LoadMore
                toTop={toTop}
                handleLoadMore={handleLoadMore}
                loadFollowBottom={true}
                outsideclass={classess}>
                <div className={classess.orderList}>
                  <OrderListProvider
                    value={{
                      data: dataRenderScreeen,
                      handleShowDetailItem: handleShowDetailItem,
                      itemActive: productActive,
                      openDetail,
                      setOpenDetail
                    }}>
                    <OrderList />
                    {isMobile && (
                      <Drawer
                        handleCloseMenu={handleClosePopupDetail}
                        open={openDetail}
                        append={renderDetailOrderPageRight()}
                      />
                    )}
                  </OrderListProvider>
                </div>
              </LoadMore>
            </Grid>
            {!isMobile && (
              <Grid item md={5} className={classess.padl0}>
                <div className={classess.leftContent}>
                  {renderDetailOrderPageRight()}
                </div>
              </Grid>
            )}
          </Grid>
        </Container>

        <PickupAtStoreConfirm
          openModel={props.openModalPickupConfirm}
          handleClose={onClosePickupConfirm}
          classOutSide={classessModal}
          productActive={productActive}
          handleRejectProduct={handleRejectProduct}
        />

        <ChoiseDeliveryServiceModal
          orderDetail={dataProductDetail}
          openModel={props.openModalChoiseDelivery}
          handleClose={onCloseModalChoiseDelivery}
          classOutSide={classessModal}
          productActive={productActive}
          handleReadyForDelivery={handleReadyForDelivery}
        />
      </section>
      {/* <ChatComponents /> */}
      <Chat />
    </React.Fragment>
  );
};

const mapStateToProps = state => {
  return {
    userProfile: getUserProfile(state),
    deliveryMethod: getDeliveryServiceMethod(state),
    showDetailOrderItem: getOpenDetailOrderItem(state),
    openModalReasonOrder: getOpenModalReasonOrder(state),
    openModalAddBusiness: getOpenModalAddBusiness(state),
    openModalPickupConfirm: getOpenModalPickupConfirm(state),
    openModalChoiseDelivery: getOpenModalChoiseDelivery(state),
    needReloadCountProductOrders: getNeedReloadCountProductOrders(state)
  };
};

const mapDispatchToProps = dispatch => {
  return {
    setLoading: status => dispatch(actSetLoading(status)),
    handleClosePickupConfirm: () =>
      dispatch(actToggleModalPickupConfirm(false)),
    handleCloseModalReason: () => dispatch(actToggleModalReason(false)),
    handleShowDetailOrderItem: value =>
      dispatch(actToggleDetailOrderItem(value)),
    handleCloseModalChoiseDelivery: value =>
      dispatch(actToggleModalChoiseDelivery(value)),
    fetchDataProductOrder: (params, query) =>
      dispatch(fetchProductOrders(params, query)),
    onFetchProductOrderById: id =>
      dispatch(fetchProductOrderById({ _id: id }, queryProductOrderById)),
    onFetchCountProductOrders: params =>
      dispatch(countProductOrders(params, queryCountProductOrders)),
    handleToggleNeedReloadCountProductOrders: value =>
      dispatch(actSetNeedReloadCountProductOrders(value)),
    toggleModalAddBusiness: value => dispatch(actToggleModalAddBusiness(value))
    // setMethodDelivery: value => dispatch(setMethodDeliveryService(value))
  };
};

export default compose(
  withRouter,
  connect(
    mapStateToProps,
    mapDispatchToProps
  )
)(OrdersPage);
