import { createMuiTheme } from '@material-ui/core/styles';

export const primaryFont = 'Open Sans';

export const grey = {
  main: '#fafafc',
  dark: '#a1a4b1',
  light: '#c4c4c4',
  bar: '#f0f1f3'
};

export const black = {
  main: '#232735',
  dark: '#121232'
};

export const primary = {
  main: '#FFCF62'
};

export const white = '#ffffff';

export const theme = createMuiTheme({
  palette: {
    primary: {
      main: '#FFCF62',
      light: 'rgba(255, 255, 255, 1)',
      dark: 'rgba(226, 112, 1, 1)',
      contrastText: 'rgba(255, 255, 255, 1)'
    },
    gray: {
      main: '#232735',
      100: '#f5f5f5',
      200: '#efefef',
      300: '#CECECE',
      400: '#9F9F9F',
      500: '#9f9f9f',
      600: '#767676',
      700: '#626262',
      800: '#333333',
      900: '#222222'
    },
    color: {
      error: '#EB0000',
      warning: '#F5B100',
      success: '#27AE60',
      disabled: '#EFEFEF',
      background: '#f0f1f3',
      white: '#fff',
      black: '#000'
    },
    common: {
      black: 'rgba(47, 46, 46, 1)',
      white: 'rgba(255, 255, 255, 1)'
    },
    background: {
      paper: 'rgba(255, 255, 255, 1)',
      default: 'rgba(251, 251, 251, 1)',
      hover: '#cbd0ce45'
    },
    secondary: {
      light: 'rgba(47, 46, 46, 1)',
      main: 'rgba(86, 85, 84, 1)',
      dark: 'rgba(47, 46, 46, 1)',
      contrastText: '#fff'
    },
    text: {
      primary: 'rgba(47, 46, 46, 1)',
      secondary: 'rgba(86, 85, 84, 1)',
      disabled: 'rgba(155, 155, 155, 1)',
      hint: 'rgba(184, 184, 184, 0.38)'
    }
  },
  typography: {
    subtitle1: {
      fontSize: 24,
      fontWeight: 600,
      lineHeight: '33px',
      letterSpacing: 0.15,
      fontFamily: primaryFont
    },
    subtitle2: {
      fontSize: 16,
      fontWeight: 600,
      lineHeight: '26px',
      letterSpacing: 0.15,
      fontFamily: primaryFont
    },
    h3: {
      fontSize: 18,
      lineHeight: '26px',
      letterSpacing: 0.15,
      fontFamily: primaryFont
    },
    h4: {
      fontSize: 16,
      lineHeight: '26px',
      letterSpacing: 0.15,
      fontFamily: primaryFont
    },
    h5: {
      fontSize: 14,
      lineHeight: '26px',
      letterSpacing: 0.15,
      fontFamily: primaryFont
    },

    h6: {
      fontSize: 18,
      fontWeight: 600
    },
    body1: {
      fontSize: 16,
      fontWeight: 600
    },
    body2: {
      fontWeight: 400
    },
    fontSize: 14,
    fontFamily: primaryFont
  }
});
