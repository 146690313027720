import React, { useState, useEffect, useCallback } from 'react';
import { connect } from 'react-redux';
import { cloneDeep, isEqual } from 'lodash';
import Tabs from '../../TabsUI';
import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import { useTranslation } from 'react-i18next';
import { Typography } from '@material-ui/core';
import { Field, Form, Formik, getIn, FieldArray } from 'formik';
import InputAdornment from '@material-ui/core/InputAdornment';
import { getUserProfile } from 'maua-redux-core/selectors/user';
import { editProduct } from 'maua-redux-core/actions/product';
import { toastMessage, TOAST_TYPE } from '../../../utils/toastify-notify';
import { CustomTextField, GenderSelectField } from '../../FormFeild';
import PictureComponentUpload from '../../PictureComponetUpload';
import { uploadFile } from '../../../shared/services/uploadFile';
import { actSetLoading } from '../../../store/actions/loading';
import {
  productSamoaSchema,
  productVanuatuSchema
} from '../../../utils/schema';
import { queryProduct } from '../../../store/query/menu';
import UploadComponent from '../../UploadComponent';
import { formatCurrencyToNumberFloat, isFloat } from '../../../utils/common';
import ModalCustom from '../../ModalCustom';
import CheckCircleRoundedIcon from '@material-ui/icons/CheckCircleRounded';
import AddIcon from '@material-ui/icons/Add';
import RemoveIcon from '@material-ui/icons/Remove';
import ErrorIcon from '@material-ui/icons/Error';
import Button from '../../Button';
import VariantOptions from '../VariantOptions';
import General from '../General';
import Detail from '../StockAndPrice';
import useStyle from '../style';
import { element } from 'prop-types';
import { LocalGasStation } from '@material-ui/icons';

const tabsList = ['General', 'Stock & Price', 'Variants'];

const EditProductModal = props => {
  const mainClass = useStyle();
  const { product } = props;
  const { t } = useTranslation();
  const options = useCallback(props.optionSub, [props.optionSub]);
  const [imagesDefault, setImagesDefault] = useState([]);
  const [images, setImages] = useState([]);
  const [variants, setVariants] = useState(cloneDeep(product?.variants) || []);

  const defaultVariants = cloneDeep(product?.variants) || [];
  const closeModal = props.handleClose;
  const isVariantValid =
    JSON.stringify(variants) !== JSON.stringify(defaultVariants);

  const isValidNameVariant = Boolean(!variants?.every(v => v?.name));

  const isValidOptionName = !variants?.every(v =>
    v.options?.every(op => op?.name)
  );

  const isValidOptionPrice = window.location.host.includes('vu')
    ? !variants?.every(v =>
        v.options?.every(op => op?.price !== '' && op?.price % 5 === 0)
      )
    : !variants?.every(v => v.options?.every(op => op?.price !== ''));

  const errorPriceOption = window.location.host.includes('vu')
    ? 'Option price is required and must be divisible by 5!'
    : 'Option price is required!';

  const validStatusOption = variants?.every(v => {
    return v.options?.some(op => op?.status === 'active');
  });

  const validOneOption = variants?.every(v => {
    return v.options?.some(op => op);
  });

  const isSubmitMenu = props.statusMenu === 'submitted';

  const isVanuatu = window.location.host.includes('vu');

  const productSchema = isVanuatu ? productVanuatuSchema : productSamoaSchema;

  useEffect(() => {
    const images = cloneDeep(product.media).map(src => ({
      src
    }));

    setImagesDefault(() => [...images]);
    setImages(() => [...images]);
  }, [product]);

  const handleChangeImage = el => {
    let inputEl = el.target;
    const file = inputEl.files[0];
    const reader = new FileReader();
    if (file) {
      reader.readAsDataURL(file);
    }

    reader.onloadend = () => {
      setImages(i => [...i, { src: reader.result, file: file }]);
    };

    el.target.value = '';
  };

  const uploadProgress = progress => {
    let percent = Math.floor((progress.loaded / progress.total) * 100);
    percent !== props.loading.percent &&
      props.setLoading({ status: true, percent });
  };

  const uploadImage = _id => {
    const files = images
      .filter(({ file }) => file)
      .map(({ file }) => ({ label: 'media', file }));

    let payload = {
      files,
      of: product.of,
      url: `products/${_id}/media`
    };

    uploadFile(payload, uploadProgress)
      .then(() => {
        props.loadMenu();
        props.handleClose();
      })
      .catch(err => {
        props.setLoading({ status: false });
        toastMessage(TOAST_TYPE.ERROR, err.message);
      })
      .finally(() => {
        props.setLoading({ status: false, percent: 0 });
      });
  };

  const handleChangePrice = (el, setFieldValue, values) => {
    const { name, value } = el.target;
    let number = formatCurrencyToNumberFloat(value.trim());

    const decimal = /([0-9]+([.][0-9]*)?|[.][0-9]+)/;

    if ((!decimal.test(number) || isNaN(+number)) && number !== '') return;

    let discount = parseFloat(values.discount);

    let price = parseFloat(values.price);

    setFieldValue(name, number);

    if (name === 'price') {
      let valueDiscount = (number * (discount || 0)) / 100;

      const reducedPrice = isVanuatu
        ? Math.ceil(parseInt(number - valueDiscount) / 5) * 5 || '0'
        : parseFloat(number - valueDiscount).toFixed(2) || '0';

      setFieldValue('reducedPrice', reducedPrice);
    }

    if (name === 'discount') {
      let valueDiscount = (price * (number || 0)) / 100;

      const reducedPrice = isVanuatu
        ? Math.ceil(parseInt(price - valueDiscount) / 5) * 5 || '0'
        : parseFloat(price - valueDiscount).toFixed(2) || '0';

      setFieldValue('reducedPrice', reducedPrice);
    }

    if (name === 'reducedPrice') {
      const discount = isVanuatu
        ? Math.ceil(parseInt(100 - (number * 100) / price) / 5) * 5 || '0'
        : parseInt(100 - (number * 100) / price) || '0';

      setFieldValue('discount', discount);
    }
  };

  const handleEditProduct = async values => {
    const media = images.filter(({ file }) => !file).map(({ src }) => src);
    let variantsPayload = cloneDeep(variants);

    let arr = variantsPayload.map((v, i) => ({
      name: v?.name,
      _id: v?._id,
      isMultipleChoice: v?.isMultipleChoice,
      mandatory: v?.mandatory,
      options: v?.options?.map((op, i) => ({
        name: op?.name,
        _id: op?._id,
        price: parseFloat(op?.price),
        status: op.status || 'active'
      }))
    }));

    const payload = {
      _id: product._id,
      of: product.of,
      media,
      name: values.productName,
      sku: values.productSku,
      category: values.subCategory?.value,
      store: props.storeID,
      description: values.description,
      quantity: parseInt(values.qty),
      price: parseFloat(values.price),
      discount: {
        enable: parseFloat(values.discount) ? true : false,
        type: '%',
        percent: parseFloat(values.discount)
      },
      variants: arr
    };

    try {
      if (isValidNameVariant) {
        throw new Error('Variant name is required!');
      }
      if (isValidOptionName) {
        throw new Error('Option name is required!');
      }
      if (isValidOptionPrice) {
        throw new Error(errorPriceOption);
      }
      if (!validOneOption) {
        throw new Error('At least one option is required!');
      }
      if (!validStatusOption) {
        throw new Error(
          'At least one active option is required for each variant!'
        );
      }
      await props.editProduct(payload);

      if (!isEqual(imagesDefault, images)) {
        uploadImage(product._id);
      } else {
        props.loadMenu();
        props.handleClose();
      }
      toastMessage(
        TOAST_TYPE.SUCCESS,
        `${t('toastMessage.success.content.Product has been updated')}`
      );
    } catch (error) {
      toastMessage(TOAST_TYPE.ERROR, error.message);
    }
  };

  const initialValuesForm = {
    productName: product?.name,
    price: product?.price || '0',
    qty: product?.quantity || '0',
    productSku: product?.sku || '',
    subCategory: {
      label: options.find(op => op.value === product?.category?._id).label,
      value: product?.category?._id
    },
    description: product?.description,
    discount: parseFloat(product?.discount?.percent).toFixed(2) || '0',
    reducedPrice: parseFloat(product?.discountedPrice).toFixed(2) || '0'
  };

  const checkDisabledFormikSamoa = values => {
    const {
      qty,
      price,
      discount,
      description,
      productName,
      subCategory,
      reducedPrice
    } = values;

    const isValid =
      qty &&
      price &&
      !isNaN(+price) &&
      discount &&
      parseInt(discount) <= 100 &&
      parseInt(discount) >= 0 &&
      description &&
      productName &&
      subCategory &&
      reducedPrice &&
      parseInt(reducedPrice) >= 0;

    const isSameValue =
      isEqual(initialValuesForm, values) &&
      isEqual(imagesDefault, images) &&
      !isVariantValid;

    return !isValid || isSameValue;
  };

  const checkDisabledFormikVanuatu = values => {
    const {
      qty,
      price,
      discount,
      description,
      productName,
      subCategory,
      reducedPrice
    } = values;

    const isValid =
      qty &&
      price &&
      !isNaN(+price) &&
      parseInt(price) % 5 === 0 &&
      discount &&
      parseInt(discount) <= 100 &&
      parseInt(discount) >= 0 &&
      description &&
      productName &&
      subCategory &&
      reducedPrice &&
      parseInt(reducedPrice) >= 0 &&
      parseInt(reducedPrice) % 5 === 0;

    const isSameValue =
      isEqual(initialValuesForm, values) &&
      isEqual(imagesDefault, images) &&
      !isVariantValid;

    return !isValid || isSameValue;
  };

  return (
    <ModalCustom
      {...props}
      className={mainClass.root}
      styleOutside={{
        display: 'flex',
        flexDirection: 'column'
      }}>
      <Typography variant="h5" className={mainClass.title}>
        {t('product.title.Update product')}
      </Typography>

      <Formik
        closeModal={props.handleClose}
        enableReinitialize
        initialValues={initialValuesForm}
        validationSchema={productSchema}
        onSubmit={data => {}}
        render={formik => {
          return (
            <Form className={mainClass.wrappForm}>
              <Tabs tabsList={tabsList} mainClasses={mainClass}>
                <General
                  isSubmitMenu={isSubmitMenu}
                  images={images}
                  setImages={setImages}
                  setFieldValue={formik.setFieldValue}
                  handleChangeImage={handleChangeImage}
                  formik={formik}
                  options={options}
                />

                <Detail formik={formik} handleChangePrice={handleChangePrice} />

                <VariantOptions setVariants={setVariants} variants={variants} />
              </Tabs>

              <Grid container className={mainClass.actions}>
                <Grid
                  item
                  xs={12}
                  container
                  direction="row"
                  justify="flex-end"
                  alignItems="center"
                  spacing={2}>
                  <Grid item xs={4}>
                    <Button
                      onClick={closeModal}
                      className={mainClass.cancelBtn}>
                      {t('action.Cancel')}
                    </Button>
                  </Grid>

                  <Grid item xs={4}>
                    <Button
                      typeBtn="yellow"
                      type="button"
                      disabled={
                        isVanuatu
                          ? checkDisabledFormikVanuatu(formik.values)
                          : checkDisabledFormikSamoa(formik.values)
                      }
                      onClick={data => {
                        return handleEditProduct(formik.values);
                      }}>
                      {t('action.Save')}
                    </Button>
                  </Grid>
                </Grid>
              </Grid>
            </Form>
          );
        }}
      />
    </ModalCustom>
  );
};

export default connect(
  state => ({
    loading: state.loading,
    profile: getUserProfile(state)
  }),
  dispatch => ({
    setLoading: status => dispatch(actSetLoading(status)),
    editProduct: payload => dispatch(editProduct(payload, queryProduct))
  })
)(EditProductModal);
