import { makeStyles } from '@material-ui/core';
import { primaryFont, secondaryFont } from '../../../shared/theme';

export const useStyle = makeStyles(theme => ({
  root: {},
  title: {
    borderBottom: `1px solid #E6E6E6`,
    padding: `0 40px 15px`
  },
  content: {
    padding: `20px 40px 0`
  },
  titlePrimary: {
    padding: `0 50px`,
    marginTop: 45,
    marginBottom: 20,
    fontFamily: secondaryFont,
    fontWeight: 600,
    color: theme.palette.color.black,
    textAlign: 'center'
  },
  wrappImg: {},
  imgAddStaff: {
    maxWidth: '100%'
  },
  wrappBtn: {
    position: 'absolute',
    bottom: 0,
    left: 0,
    right: 0,
    padding: '20px 40px'
  },
  rootTextFeild: {
    '&:hover fieldset': {
      border: `none !important`
    },
    '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
      border: '2px solid #E38D03 !important'
    }
  },
  notchedOutline: {
    border: `none`,
    borderRadius: 4,
    backgroundColor: '#f2f2f2',
    outline: 0
  },
  inputTextFeild: {
    border: 'none',
    position: 'relative',
    zIndex: 1,
    '&:focus': {
      backgroundColor: 'transparent'
    }
  },
  notchedOutlineFocus: {
    borderWidth: 1
  }
}));
