import { makeStyles } from '@material-ui/core';
import { primaryFont } from '../../../shared/theme';
const useStyle = makeStyles(theme => ({
  root: {
    borderBottom: `1px dashed #E6E6E6`
  },
  productItem: {
    display: 'flex',
    // flexWrap: 'wrap',
    flexDirection: 'row',
    alignItems: 'center',
    paddingTop: 16,
    paddingBottom: 16
  },
  infoProduct: {
    display: 'flex',
    flexDirection: 'row',
    flex: 10
  },
  imgProduct: {
    display: 'flex',
    flex: 3,
    width: 71,
    height: 70,
    borderRadius: 10
  },
  productDetail: {
    flex: 9,
    paddingLeft: 12,
    paddingRight: 12,
    '& p': {
      margin: 0,
      fontSize: 18,
      color: theme.palette.color.black2,
      fontFamily: primaryFont,
      lineHeight: '26px'
    }
  },
  costProduct: {
    fontSize: '14px',
    color: theme.palette.color.black2,
    fontFamily: primaryFont,
    lineHeight: '20px',
    '& strike': {
      color: theme.palette.color.gray
    }
  },
  numberQuantity: {
    display: 'flex',
    flex: 2,
    justifyContent: 'center',
    '& span': {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      borderRadius: '50%',
      width: 34,
      height: 34,
      backgroundColor: theme.palette.color.white2,
      fontSize: 14,
      color: theme.palette.color.black1,
      fontFamily: primaryFont
    }
  },
  itemEdit: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    marginBottom: 11
  },
  editIcon: {
    width: 14,
    height: 14
  },
  editText: {
    marginLeft: 9,
    fontSize: 14,
    color: theme.palette.primary.main,
    fontFamily: primaryFont
  },
  variants: {
    color: '#565554',
    fontFamily: 'Helvetica, sans-serif',
    margin: '10px 0',
    padding: '0 0 0 0',
    listStyle: 'none'
  },
  variantName: {
    position: 'relative',
    fontWeight: 500,
    color: 'black'
  },
  wrapperVariant: {
    position: 'relative',
    textAlign: 'justify',
    marginBottom: theme.spacing(0.8),
    color: 'rgb(153, 153, 153)'
  },
  nameOption: {
    '& span': {
      marginRight: 10
    }
  }
}));
export default useStyle;
