import React from 'react';
import { connect } from 'react-redux';
// import clsx from 'clsx';
import { useTranslation } from 'react-i18next';
import { Typography } from '@material-ui/core';

import useStyle from './style';
import ProductService from '../../Orders/ProductService';
import TotalPrice from '../../Orders/TotalPrice';
import FormCustomer from '../../FormCustomer';
import PaymentInfo from '../../Orders/PaymentInfo';
import Button from '../../Button';
import { actToggleDetailOrderItem } from '../../../store/actions/orders';
import handSaveImg from '../../../assets/img/icons/hand-save.svg';
import BackToDashBoard from '../../BackToDashBoard';

const SelfDeliDetailOrderReady = props => {
  const classess = useStyle();
  const handleComplete = () => {
    props.closeDetailOrderReady();
  };

  const handleFailDelivery = () => {
    // console.log('handle Fail Delivery');
  };
  const { t } = useTranslation();

  const {
    _id,
    code,
    amount,
    address,
    products,
    customer,
    discount,
    promotion,
    serviceFee,
    deliveryFee,
    paymentMethod,
    productsPrice
  } = props.data;

  const dataTotalPrice = {
    amount,
    discount,
    promotion,
    serviceFee,
    deliveryFee,
    productsPrice
  };

  const dataCustomer = {
    customer,
    address,
    productOrderID: _id
  };

  const dataPaymentMethod = {
    paymentMethod
  };

  return (
    <div className={classess.wrapp}>
      {/* <BackToDashBoard handleBackDashboard={props.handleshowDashboards} /> */}
      <Typography variant="h4" className={classess.identity}>
        #{code}
      </Typography>
      <div className={classess.deliveryStatus}>
        <div className={classess.innerStatus}>
          <span className={classess.deliveryThumb}>
            <img
              src={handSaveImg}
              alt="Delivery to me"
              className={classess.imgThumb}
            />
            <span className={classess.textInfo}>
              {t('order.delivery.title.Delivery to me')}
            </span>
          </span>
          <span className={classess.deliveryType}>
            {t('order.delivery.label.Self delivery')}
          </span>
        </div>
      </div>
      <div className={classess.main}>
        <ProductService products={products} />
        <TotalPrice
          methodDelivery={props?.methodDelivery}
          typePromote="white"
          dataTotalPrice={dataTotalPrice}
        />
        <FormCustomer dataCustomer={dataCustomer} />
        <PaymentInfo order={props.data} />
        <div className={classess.wrappBtn}>
          <Button typeBtn="failBtn" onClick={handleFailDelivery}>
            {t('action.Fail Delivery')}
          </Button>
          <Button typeBtn="green" onClick={handleComplete}>
            {t('action.Complete')}
          </Button>
        </div>
      </div>
    </div>
  );
};

const mapDispatchToProps = dispatch => {
  return {
    closeDetailOrderReady: () => {
      dispatch(actToggleDetailOrderItem(false));
    }
  };
};

export default connect(
  null,
  mapDispatchToProps
)(SelfDeliDetailOrderReady);
