export const queryChannelsHistory = `
  _id
  name
  member {
      messageAt
      seenAt
      role
      channel {
          _id
      }
  }
  order {
      _id
      code
      status
      updatedAt
  }
  message {
      _id
      content
      media
      sender {
          _id
          name
          avatar
      }
      channel {
          _id
          name
      }
  }
  store {
      _id
      name
      media
  }
  createdAt
  updatedAt
`;

export const queryMessengerChannel = `
  _id
  name
  message {
    sender {
      _id
      name
      avatar
    }
  }
  order{
    _id
    status
    updatedAt
    code
    customer{
      _id
      lastName
      firstName
      name
    }
  }
  delivery{
    _id
    status
    updatedAt
    code
    customer{
      _id
      avatar
      lastName
      firstName
      name
    }
  }
  store {
    _id
    name
    media
  }
`;

export const queryMessages = `
  _id
  channel{
    _id
    name
  }  
  sender{
    _id
    avatar
    firstName
    lastName
    name
  }
  senderRole
  media
  content
  updatedAt
`;

export const querySendMessage = `
  _id
  channel{
    _id
    name
  }  
  sender{
    _id
    avatar
    firstName
    lastName
    name
  }
  senderRole
  content
  updatedAt
`;
