import { makeStyles } from '@material-ui/core';

const useStyle = makeStyles(theme => ({
  editor: props => ({
    pointerEvents: props.disabled ? 'none' : 'unset',
    background: props.disabled
      ? theme.palette.gray[200]
      : theme.palette.color.white,
    '& .ql-container': {
      borderBottomLeftRadius: '0.5em',
      borderBottomRightRadius: '0.5em',
      '& .ql-editor': {
        minHeight: '20em'
      }
    },
    '& .ql-snow.ql-toolbar': {
      display: 'block',
      borderTopLeftRadius: '0.5em',
      borderTopRightRadius: '0.5em'
    },

    '& .MuiSkeleton-text': {
      transform: 'scale(1)'
    }
  })
}));

export default useStyle;
