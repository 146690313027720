import { makeStyles } from '@material-ui/core';
import { primaryFont, secondaryFont } from '../../../shared/theme';

export const useStyle = makeStyles(theme => ({
  root: {},
  title: {
    borderBottom: `1px solid #E6E6E6`,
    padding: `0 40px 15px`
  },
  content: {
    padding: `20px 40px 0`
  },
  subtitle: {
    marginTop: 30,
    marginBottom: 10,
    fontFamily: secondaryFont,
    fontWeight: 600,
    color: theme.palette.color.black1
  },
  wrappBtn: {
    margin: 20
  },
  mt15: {
    marginTop: 15
  },
  wrappUploadImgUser: {
    marginTop: 35,
    marginBottom: 35,
    display: 'flex',
    justifyContent: 'center'
  }
}));
