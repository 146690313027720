import Guid from 'guid';

export const mauaApp = {
  appId: process.env.REACT_APP_MAUA_APP_ID,
  version: 'v1.3',
  state: Guid.raw(),
  fbAppEventsEnabled: true,
  redirect: 'https://docs.maua.efisoft.com.vn/docs/redux-core/get-data',
  debug: true
};

const isLocalhost = Boolean(
  window.location.hostname === 'localhost' ||
    // [::1] is the IPv6 localhost address.
    window.location.hostname === '[::1]' ||
    // 127.0.0.1/8 is considered localhost for IPv4.
    window.location.hostname.match(
      /^127(?:\.(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)){3}$/
    )
);

export const ggApiKey = isLocalhost
  ? process.env.REACT_APP_GOOGLE_API_KEY
  : process.env.REACT_APP_GOOGLE_API_KEY_PRODUCT;
