import { makeStyles } from '@material-ui/core';
const useStyles = makeStyles(theme => ({
  tab: {
    fontSize: '16px',
    lineHeight: '16px'
  },

  swipeableViews: {
    overflow: 'hidden',
    flex: 1
  }
}));
export default useStyles;
