import React, { useState, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { Formik, Form } from 'formik';
import { connect } from 'react-redux';
import { cloneDeep } from 'lodash';
import { Typography } from '@material-ui/core';
import { Grid } from '@material-ui/core';
import { getUserProfile } from 'maua-redux-core/selectors/user';
import { addProduct } from 'maua-redux-core/actions/product';
import { toastMessage, TOAST_TYPE } from '../../../utils/toastify-notify';
import { formatCurrencyToNumberFloat } from '../../../utils/common';
import { uploadFile } from '../../../shared/services/uploadFile';
import { actSetLoading } from '../../../store/actions/loading';
import { queryProduct } from '../../../store/query/menu';
import VariantOptions from '../VariantOptions';
import ModalCustom from '../../ModalCustom';
import Detail from '../StockAndPrice';
import Button from '../../Button';
import General from '../General';
import useStyle from '../style';
import Tabs from '../../TabsUI';
import {
  productSamoaSchema,
  productVanuatuSchema
} from '../../../utils/schema';

const tabsList = ['General', 'Stock & Price', 'Variants'];

const AddProductModal = props => {
  const mainClass = useStyle();
  const { t } = useTranslation();

  const [images, setImages] = useState([]);
  const [variants, setVariants] = useState([]);
  const options = useCallback(props.optionSub, [props.optionSub]);
  const closeModal = props.handleClose;

  const isValidNameVariant = Boolean(!variants?.every(v => v?.name));

  const isValidOptionName = !variants?.every(v =>
    v.options?.every(op => op?.name)
  );

  const isValidOptionPrice = window.location.host.includes('vu')
    ? !variants?.every(v =>
        v.options?.every(op => op?.price !== '' && op?.price % 5 === 0)
      )
    : !variants?.every(v => v.options?.every(op => op?.price !== ''));

  const errorPriceOption = window.location.host.includes('vu')
    ? 'Option price is required and must be divisible by 5!'
    : 'Option price is required!';

  const validStatusOption = variants?.every(v => {
    return v.options?.some(op => op?.status === 'active');
  });

  const validOneOption = variants?.every(v => {
    return v.options?.some(op => op);
  });

  const isVanuatu = window.location.host.includes('vu');

  const productSchema = isVanuatu ? productVanuatuSchema : productSamoaSchema;

  const handleChangeImage = el => {
    let inputEl = el.target;
    const file = inputEl.files[0];
    const reader = new FileReader();
    if (file) {
      reader.readAsDataURL(file);
    }

    reader.onloadend = () => {
      setImages(i => [...i, { src: reader.result, file: file }]);
    };

    el.target.value = '';
  };

  const uploadProgress = progress => {
    let percent = Math.floor((progress.loaded / progress.total) * 100);
    percent !== props.loading.percent &&
      props.setLoading({ status: true, percent });
  };

  const uploadImage = _id => {
    const files = images
      .filter(({ file }) => file)
      .map(({ file }) => ({ label: 'media', file }));

    let payload = {
      files,
      of: 'ProductDraft',
      url: `products/${_id}/media`
    };

    uploadFile(payload, uploadProgress)
      .then(() => {
        props.loadMenu();
        props.handleClose();
        props.setLoading({ status: false });
      })
      .catch(err => {
        props.setLoading({ status: false });
        toastMessage(TOAST_TYPE.ERROR, err.message);
      })
      .finally(() => {
        props.setLoading({ status: false, percent: 0 });
      });
  };

  const handleChangePrice = (el, setFieldValue, values) => {
    const { name, value } = el.target;
    let number = formatCurrencyToNumberFloat(value.trim());

    const decimal = /([0-9]+([.][0-9]*)?|[.][0-9]+)/;

    if ((!decimal.test(number) || isNaN(+number)) && number !== '') return;

    let discount = parseFloat(values.discount);

    let price = parseFloat(values.price);

    setFieldValue(name, number);

    if (name === 'price') {
      let valueDiscount = (number * (discount || 0)) / 100;

      const reducedPrice = isVanuatu
        ? Math.ceil(parseInt(number - valueDiscount) / 5) * 5 || '0'
        : parseFloat(number - valueDiscount).toFixed(2) || '0';

      setFieldValue('reducedPrice', reducedPrice);
    }

    if (name === 'discount') {
      let valueDiscount = (price * (number || 0)) / 100;

      const reducedPrice = isVanuatu
        ? Math.ceil(parseInt(price - valueDiscount) / 5) * 5 || '0'
        : parseFloat(price - valueDiscount).toFixed(2) || '0';

      setFieldValue('reducedPrice', reducedPrice);
    }

    if (name === 'reducedPrice') {
      const discount = isVanuatu
        ? Math.ceil(parseInt(100 - (number * 100) / price) / 5) * 5 || '0'
        : parseInt(100 - (number * 100) / price) || '0';

      setFieldValue('discount', discount);
    }
  };

  const handleAddProduct = async values => {
    let variantsPayload = cloneDeep(variants);

    variantsPayload = variantsPayload.map((v, i) => ({
      name: v?.name,
      isMultipleChoice: v?.isMultipleChoice,
      mandatory: v?.mandatory,
      options: v?.options?.map((op, i) => ({
        name: op?.name,
        price: parseFloat(op?.price),
        status: op?.status || 'active'
      }))
    }));

    const payload = {
      of: 'ProductDraft',
      name: values.productName,
      sku: values.productSku,
      category: values.subCategory?.value,
      store: props.storeID,
      description: values.description,
      quantity: parseInt(values.qty),
      price: parseFloat(values.price),
      discount: {
        enable: parseFloat(values.discount) ? true : false,
        type: '%',
        percent: parseFloat(values.discount)
      },
      variants: variantsPayload || []
    };

    try {
      if (isValidNameVariant) {
        throw new Error('Variant name is required!');
      }
      if (isValidOptionName) {
        throw new Error('Option name is required!');
      }
      if (isValidOptionPrice) {
        throw new Error(errorPriceOption);
      }
      if (!validOneOption) {
        throw new Error('At least one option is required!');
      }
      if (!validStatusOption) {
        throw new Error(
          'At least one active option is required for each variant!'
        );
      }

      const { addProduct } = await props.addProduct(payload);
      if (images?.length) {
        uploadImage(addProduct?.draft?._id);
      } else {
        props.loadMenu();
        props.handleClose();
      }
      toastMessage(
        TOAST_TYPE.SUCCESS,
        `${t('toastMessage.success.content.Product has been added')}`
      );
    } catch (error) {
      toastMessage(TOAST_TYPE.ERROR, error.message);
    }
  };

  const initialValuesForm = {
    qty: '0',
    price: '',
    discount: '0',
    productSku: '',
    subCategory: '',
    productName: '',
    description: '',
    reducedPrice: ''
  };

  return (
    <ModalCustom
      {...props}
      className={mainClass.root}
      styleOutside={{
        display: 'flex',
        flexDirection: 'column'
      }}>
      <Typography variant="h5" className={mainClass.title}>
        {t('action.Add product')}
      </Typography>

      <Formik
        closeModal={props.handleClose}
        enableReinitialize
        initialValues={initialValuesForm}
        validationSchema={productSchema}
        onSubmit={data => {}}
        render={formik => {
          return (
            <Form className={mainClass.wrappForm}>
              <Tabs tabsList={tabsList} mainClasses={mainClass}>
                <General
                  setFieldValue={formik.setFieldValue}
                  images={images}
                  setImages={setImages}
                  handleChangeImage={handleChangeImage}
                  formik={formik}
                  options={options}
                />

                <Detail formik={formik} handleChangePrice={handleChangePrice} />
                <VariantOptions variants={variants} setVariants={setVariants} />
              </Tabs>

              <Grid container className={mainClass.actions}>
                <Grid
                  item
                  xs={12}
                  container
                  direction="row"
                  justify="flex-end"
                  alignItems="center"
                  spacing={2}>
                  <Grid item xs={4}>
                    <Button
                      onClick={closeModal}
                      className={mainClass.cancelBtn}>
                      {t('action.Cancel')}
                    </Button>
                  </Grid>
                  <Grid item xs={4}>
                    <Button
                      typeBtn="yellow"
                      disabled={!formik.isValid}
                      onClick={() => {
                        return handleAddProduct(formik.values);
                      }}>
                      {t('action.Add')}
                    </Button>
                  </Grid>
                </Grid>
              </Grid>
            </Form>
          );
        }}
      />
    </ModalCustom>
  );
};

export default connect(
  state => ({
    loading: state.loading,
    profile: getUserProfile(state)
  }),
  dispatch => ({
    setLoading: status => dispatch(actSetLoading(status)),
    addProduct: params => dispatch(addProduct(params, queryProduct))
  })
)(AddProductModal);
