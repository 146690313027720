import { makeStyles } from '@material-ui/core';
import { primaryFont, secondaryFont } from '../../../shared/theme';

const useStyle = makeStyles(theme => ({
  wrapp: {
    marginTop: 20
  },
  identity: {
    paddingLeft: 30,
    paddingRight: 30,
    color: theme.palette.color.orange,
    fontFamily: primaryFont,
    fontWeight: 600
  },
  deliveryStatus: {
    margin: '20px 30px 0'
  },
  deliveryStatusWrapp: {
    position: 'relative'
  },
  innerStatus: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    border: '1px solid #3A8E07',
    borderRadius: '20px',
    fontSize: 0
  },
  deliveryThumb: {
    display: 'inline-block',
    verticalAlign: 'middle',
    padding: '7px 14px',
    flexDirection: 'row',
    alignItems: 'center',
    backgroundColor: theme.palette.color.green1,
    borderTopLeftRadius: '20px',
    borderBottomLeftRadius: '20px',
    fontSize: 0
  },
  imgThumb: {
    display: 'inline-block',
    verticalAlign: 'middle'
  },
  textInfo: {
    display: 'inline-block',
    marginLeft: 10,
    verticalAlign: 'middle',
    fontSize: 16,
    fontFamily: primaryFont,
    color: theme.palette.color.black2
  },
  deliveryType: {
    display: 'inline-block',
    verticalAlign: 'middle',
    fontSize: 16,
    fontFamily: primaryFont,
    color: theme.palette.color.black2,
    padding: '7px 12px 7px 14px'
  },
  deliveryFinal: {
    position: 'relative',
    display: 'inline-block',
    verticalAlign: 'middle',
    fontSize: 14,
    fontFamily: primaryFont,
    fontWeight: 'bold'
  },
  complete: {
    color: theme.palette.color.green,
    '&:before': {
      content: '" "',
      position: 'absolute',
      top: '50%',
      left: 0,
      width: 4,
      height: 4,
      borderRadius: '50%',
      backgroundColor: theme.palette.color.green,
      transform: 'translateY(-50%)'
    }
  },
  fail: {
    padding: '7px 30px 7px 10px',
    color: theme.palette.color.red,
    cursor: 'pointer',
    '&:before': {
      content: '" "',
      position: 'absolute',
      top: '50%',
      left: 0,
      width: 4,
      height: 4,
      borderRadius: '50%',
      backgroundColor: theme.palette.color.red,
      transform: 'translateY(-50%)'
    }
  },
  angleRight: {
    display: 'inline-block',
    width: 7,
    position: 'absolute',
    top: '50%',
    right: 15,
    transform: 'translateY(-50%)'
  },
  angleRightImg: {
    display: 'inline-block',
    width: 7
  },
  tooltipWarpp: {
    position: 'absolute',
    top: 50,
    left: 0,
    width: '100%',
    backgroundColor: theme.palette.color.white,
    boxShadow: `5px 5px 20px rgba(0, 0, 0, 0.12)`,
    borderRadius: 10,
    padding: '40px 30px 19px 20px',
    zIndex: 3
  },
  iconClose: {
    position: 'absolute',
    top: 10,
    right: 10,
    color: theme.palette.color.gray,
    cursor: 'pointer',
    transition: 'all .35s',
    fontSize: 20,
    borderRadius: '50%',
    '&:hover': {
      backgroundColor: '#ccc',
      color: '#fff'
    }
  },
  failTitle: {
    fontSize: 16,
    fontWeight: 'bold',
    fontFamily: secondaryFont,
    color: theme.palette.color.black1
  },
  failReason: {
    marginTop: 5
  },
  failReasonDesc: {
    marginLeft: 3,
    color: theme.palette.color.red
  },
  listStatusWrapp: {
    // position: 'relative',
    // '&:before': {
    //   content: '" "',
    //   position: 'absolute',
    //   top: 0,
    //   bottom: 0,
    //   left: 12,
    //   borderLeft: '1px dashed #E6E6E6'
    // }
  },
  listStatus: {
    margin: 0,
    padding: 0,
    listStyle: 'none'
  },
  itemStatus: {
    position: 'relative',
    paddingBottom: 15,
    paddingLeft: 35,
    '&:before': {
      content: '" "',
      position: 'absolute',
      top: 0,
      bottom: 0,
      left: 12,
      borderLeft: '1px dashed #E6E6E6'
    },
    '&:last-child': {
      paddingBottom: 0
    },
    '&:last-child:before': {
      bottom: 40
    },
    '&:first-child:before': {
      top: 15
    },
    '&:after': {
      content: '" "',
      position: 'absolute',
      top: 7,
      left: 7,
      width: 11,
      height: 11,
      borderRadius: '50%',
      backgroundColor: theme.palette.color.orange
    }
  },
  titleStatus: {
    color: theme.palette.color.black1
  },
  noteStatus: {
    fontSize: 16,
    color: theme.palette.color.gray,
    fontFamily: primaryFont,
    lineHeight: 1.8
  },
  iconCurrent: {
    position: 'absolute',
    top: -7,
    left: -7,
    display: 'inline-block',
    width: 39,
    height: 39,
    backgroundColor: theme.palette.color.orange,
    borderRadius: '50%',
    zIndex: 1,
    overflow: 'hidden'
  },
  bgFail: {
    backgroundColor: theme.palette.color.red
  },
  arrowCurrent: {
    position: 'absolute',
    top: '50%',
    left: 0,
    transform: 'translateY(-50%)',
    animation: `$bounceRight 2.5s infinite`
  },
  '@keyframes bounceRight': {
    '0%': {
      left: -5
    },
    '25%': {
      left: 0
    },
    '50%': {
      left: 5
    },
    '75%': {
      left: 10
    },
    '100%': {
      left: '100%'
    }
  },
  itemStatusCurrent: {
    '& h6': {
      fontSize: `24px !important`,
      lineHeight: 1,
      fontWeight: 'bold'
    }
  },
  itemFail: {
    '& h6': {
      color: theme.palette.color.red
    },
    '& span': {
      color: theme.palette.color.red
    }
  }
}));
export default useStyle;
