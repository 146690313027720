import { makeStyles } from '@material-ui/core';
import { primaryFont, secondaryFont } from '../../shared/theme';
import { isEmpty } from 'lodash';

const useStyle = makeStyles(theme => ({
  lableSelect: props =>
    !isEmpty(props.value)
      ? {
          position: 'absolute',
          fontFamily: primaryFont,
          fontWeight: '300',
          lineHeight: '1',
          display: 'flex',
          alignItems: 'center',
          background: '#fff',
          transition: 'all .2s ease-in-out',
          top: 0,
          color: props.error ? '#f44336' : theme.palette.color.gray3,
          left: 5,
          fontSize: 12,
          zIndex: props.zIndex,
          transform: 'translate(5px, -7px)',
          padding: theme.spacing(0, 0.5, 0, 0.5)
        }
      : {
          top: '50%',
          left: 14,
          position: 'absolute',
          fontSize: props.fontSize || '16px',
          transform: 'translate(0, -50%)',
          fontFamily: primaryFont,
          fontWeight: '300',
          lineHeight: '1',
          display: 'flex',
          alignItems: 'center',
          background: '#fff',
          color: props.error ? '#f44336' : theme.palette.color.gray3,
          transition: 'all .2s ease-in-out'
        },
  selectBox: {
    position: 'relative',

    '& svg, label': {
      color: 'rgba(0, 0, 0, 0.54)'
    },

    '& .css-1wa3eu0-placeholder': {
      display: 'none',
      padding: theme.spacing(0, 1),
      fontSize: 14,
      fontFamily: primaryFont,
      color: theme.palette.color.gray2
    },

    '&:focus-within': {
      '& lable': {
        top: 0,
        left: 5,
        fontSize: 12,
        color: 'rgb(158,98,2)',
        transform: 'translate(5px, -7px)',
        padding: theme.spacing(0, 0.5, 0, 0.5),
        background: '#fff',
        zIndex: props => props.zIndex + 1
      },

      '& .css-1vlwt5x-Control': {
        border: `1px solid ${theme.palette.primary.main}`
      },

      '& .css-1wa3eu0-placeholder': {
        display: 'block'
      }
    }
  },

  error: {
    color: '#f44336',
    fontSize: '0.75rem',
    marginTop: '8px',
    minHeight: '1em',
    textAlign: 'left',
    fontFamily: 'Helvetica, sans-serif',
    fontWeight: '400',
    margin: '8px 14px 0',
    lineHeight: '1em'
  }
}));

export default useStyle;
