export const hitBellQuery = `
    n
    ok
    nModified
    deletedCount
`;

export const getNotificationQuery = `
    _id
    updatedAt
    message
    createdAt
    seen
`;
