import React from 'react';
import { Box, Container } from '@material-ui/core';
import NumberOfOrder from './NumberOfOrder';
import StockLevels from './StockLevels';
import RateOfOrder from './RateOfOrder';
import TopSellers from './TopSellers';
import { useStyle } from './style';
import Revenue from './Revenue';

const Dashboard = () => {
  const classes = useStyle();

  return (
    <Box className={classes.innerOverview}>
      <Container maxWidth="xl">
        <StockLevels />
        <TopSellers />
        <Revenue />
        <RateOfOrder />
        <NumberOfOrder />
      </Container>
    </Box>
  );
};

export default Dashboard;
