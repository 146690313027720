import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import ReactMarkdown from 'react-markdown/with-html';
// import EditIcon from '@material-ui/icons/Edit';
import FiberManualRecordIcon from '@material-ui/icons/FiberManualRecord';
import EditIcon from '@material-ui/icons/Edit';
import Chip from '@material-ui/core/Chip';
import { Typography } from '@material-ui/core';
import Rating from '@material-ui/lab/Rating';
import Fade from '@material-ui/core/Fade';
import Box from '@material-ui/core/Box';
import Carousel from '../../Carousel';
import ExternalImage from '../../ImgWithOnload';
import imgBanner from '../../../assets/img/default-banner-store.png';
import { isValidStoreOpenTimes } from '../../../utils/common';
import { theme } from '../../../shared/theme';
import {
  actToggleModalEditYourStore,
  actToggleMoadlSwitchBusiness
} from '../../../store/actions/modal';
import useStyle from './style';
import clsx from 'clsx';

const CarouselItem = ({ item: { src, alt } }) => {
  const mainClass = useStyle();
  return <img src={src} alt={alt} className={mainClass.imgCarousel} />;
};

const BannerOverview = props => {
  const { t } = useTranslation();
  const {
    _id,
    name,
    media,
    phone,
    address,
    timezone,
    openTimes,
    categories,
    description,
    countRatings,
    totalStarRating
  } = props.data;

  const valRating =
    countRatings && totalStarRating
      ? parseFloat(totalStarRating / countRatings).toFixed(1)
      : 0;

  const [openMoreOpt, setOpenMoreOpt] = useState(false);
  const language =
    JSON.parse(localStorage.getItem('vendor_ln'))?.title || 'United Kingdom';

  const mainClass = useStyle();

  const statusOpenHouse =
    openTimes && isValidStoreOpenTimes(JSON.parse(openTimes), timezone);

  const handleEditYourStore = () => {
    setOpenMoreOpt(false);
    props.toggleModalEditYourStore(true);
  };

  // const handleShowMoreOpt = () => {
  //   setOpenMoreOpt(!openMoreOpt);
  // };

  // const handleSwitchBusiness = () => {
  //   setOpenMoreOpt(false);
  //   props.toggleModalSwitchBusiness(true);
  // };

  const iconMoreActive = openMoreOpt
    ? mainClass.iconMoreActive
    : mainClass.iconMoreDefault;

  return (
    <div className={mainClass.root}>
      <Chip
        className={clsx(mainClass.iconMore, iconMoreActive)}
        icon={<EditIcon className={mainClass.iconEdit} />}
        label={t('action.Edit')}
        onClick={handleEditYourStore}
      />
      {/* <EditIcon
        onClick={handleEditYourStore}
        label={t('action.Edit')}
        className={clsx(mainClass.iconMore, iconMoreActive)}
      />
      <Fade in={openMoreOpt}>
        <div className={mainClass.moreOptWrapp}>
          <ul className={mainClass.moreOpt}>
            <li className={mainClass.moreOptItem} onClick={handleEditYourStore}>
              {t('action.Edit')}
            </li>
            <li
              className={mainClass.moreOptItem}
              onClick={handleSwitchBusiness}>
              {t('mystore.title.Switch Business')}
            </li>
          </ul>
        </div>
      </Fade> */}
      {media && media.length < 2 ? (
        <ExternalImage
          src={media && media[0] ? media[0] : imgBanner}
          alt="Banner"
          className={mainClass.imgBanner}
        />
      ) : (
        <div className={mainClass.wrappCarousel}>
          <Carousel
            data={media.map(item => ({ src: item, alt: name }))}
            // propClassName={classess.orderCarousel}
            itemPerPage={1}
            renderComponent={customProps => <CarouselItem {...customProps} />}
            isControlCenter={true}
          />
        </div>
      )}
      {/*<img
        src={media && media[0] ? media[0] : imgBanner}
        alt="Banner"
        className={mainClass.imgBanner}
      /> */}
      <div className={mainClass.contentBanner}>
        <div className={mainClass.listCategory}>
          {categories && categories.length
            ? categories.map(category => {
                const cateName = JSON.parse(category.name);
                return (
                  <span key={category._id} className={mainClass.categoryItem}>
                    {cateName[language] || cateName['United Kingdom']}
                  </span>
                );
              })
            : null}
        </div>
        <Typography variant="h4" className={mainClass.title}>
          {name}
        </Typography>
        <div className={mainClass.ratingAndOpenTime}>
          <div className={mainClass.ratingWrapp}>
            <Box className={mainClass.ratingVal}>{valRating}</Box>
            <Rating
              className={mainClass.ratingControl}
              name="ratingBanner"
              value={parseFloat(valRating)}
              precision={0.5}
              readOnly
            />
          </div>
          <div className={mainClass.openTime}>
            <FiberManualRecordIcon
              style={{
                width: 8,
                height: 8,
                color: statusOpenHouse
                  ? theme.palette.color.green2
                  : theme.palette.color.red
              }}
            />
            {statusOpenHouse ? (
              <React.Fragment>
                <span className={mainClass.openTimeDesc}>
                  {t('mystore.status.Open now')}
                </span>
                {/* <span className={mainClass.openTimeVal}>
                  {statusOpenHouse && statusOpenHouse.timeStart} -{' '}
                  {statusOpenHouse && statusOpenHouse.timeEnd}
                </span> */}
              </React.Fragment>
            ) : (
              <span className={mainClass.closeTime}>
                {t('store.opentime.status.closed')}
              </span>
            )}
          </div>
        </div>
        <Typography variant="subtitle1" className={mainClass.info}>
          {address
            ? `${address.name ? address.name + ',' : ''} ${
                address.village ? address.village + ',' : ''
              } ${address.country ? address.country + ',' : ''} ${
                address.alaNum
              }`
            : ''}
        </Typography>
        {phone ? (
          <Typography variant="subtitle1" className={mainClass.info}>
            {phone}
          </Typography>
        ) : null}
        {description ? (
          <Box className={mainClass.descriptionOfVendor}>
            <ReactMarkdown source={description} escapeHtml={false} />
          </Box>
        ) : null}
      </div>
    </div>
  );
};

const mapDispatchToProps = dispatch => {
  return {
    toggleModalEditYourStore: value =>
      dispatch(actToggleModalEditYourStore(value)),
    toggleModalSwitchBusiness: value =>
      dispatch(actToggleMoadlSwitchBusiness(value))
  };
};
export default connect(
  null,
  mapDispatchToProps
)(BannerOverview);
