import React, { useRef, Fragment, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Box, Divider } from '@material-ui/core';
import ImportIcon from '../../assets/img/import-icon.svg';
import { onClickOutside } from '../../utils/common';
import { useStyle } from './style';
import Button from '../Button';

const ImportFile = props => {
  const { type, optionFile, handleChange, accept, disabled } = props;
  const inputRef = useRef();
  const rootRef = useRef();
  const { t } = useTranslation();
  const classes = useStyle();
  const [show, setShow] = useState(false);
  const [typeFile, setTypeFile] = useState(false);
  const className =
    type === 'primary' ? classes.typePrimary : classes.typeDefault;
  onClickOutside(rootRef, () => setShow(false));

  const onChange = e => {
    if (typeof handleChange === 'function') {
      handleChange(e.target.files[0], typeFile);
    }
    if (inputRef.current && inputRef.current.value) {
      inputRef.current.value = '';
    }
  };

  const optionChooseFile = () => {
    return optionFile.map((file, index) => (
      <Fragment key={index}>
        <Box
          className={classes.file}
          onClick={() => {
            inputRef.current.click();
            setTypeFile(file.value);
          }}>
          {file.icon}
          <Box ml={1}>{file.text}</Box>
        </Box>
        <Divider />
      </Fragment>
    ));
  };

  const handleClickBtn = () => {
    if (!!optionFile?.length) {
      return setShow(show => !show);
    }

    return inputRef.current.click();
  };

  return (
    <Button
      className={classes.btnImport + ' ' + className}
      onClick={handleClickBtn}
      disabled={disabled}
      ref={rootRef}>
      <img
        src={ImportIcon}
        alt="import"
        style={{ marginRight: 8, width: 20 }}
      />
      <Box>{t('action.Import')}</Box>

      {show && (
        <Box className={classes.optionFile} onClick={e => e.stopPropagation()}>
          {optionChooseFile()}
        </Box>
      )}

      <input
        ref={inputRef}
        accept={accept}
        type="file"
        onChange={onChange}
        style={{ display: 'none' }}
      />
    </Button>
  );
};

ImportFile.defaultProps = {
  type: 'primary',
  optionFile: [],
  accept: '.xlsx, .csv'
};

export default ImportFile;
