import React, { Fragment, useState, useEffect } from 'react';
import Radio from '@material-ui/core/Radio';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControl from '@material-ui/core/FormControl';
import { Divider } from '@material-ui/core';
import { ensureArray } from '../../utils/common';
import { useStyleRadio } from './style';

export default function RadioField(props) {
  const classes = useStyleRadio();
  const {
    dataRadios,
    valueDefault,
    getValue,
    column,
    divider,
    name = 'happened',
    noDividerLast = false
  } = props;
  const [value, setValue] = useState(valueDefault);

  useEffect(() => {
    setValue(valueDefault);
  }, [valueDefault]);

  const handleChange = event => {
    let value = event.target.value;
    setValue(value);
    getValue(value);
  };
  const hasDiverLast = divider && noDividerLast;

  return (
    <FormControl component="fieldset" className={classes.formControl}>
      <RadioGroup
        aria-label={name}
        name={name}
        className={column ? classes.default : classes.group}
        value={value}
        onChange={handleChange}>
        {ensureArray(dataRadios).map((radio, index) => {
          return (
            <Fragment key={index}>
              <FormControlLabel
                className={classes.formControlLabel}
                value={radio.value}
                control={
                  <Radio
                    disabled={radio.disabled}
                    classes={{ root: classes.radio, checked: classes.checked }}
                    color="primary"
                  />
                }
                label={radio.label}
              />
              {hasDiverLast && index === dataRadios.length - 1 ? null : (
                <Divider className={classes.divider} />
              )}
            </Fragment>
          );
        })}
      </RadioGroup>
    </FormControl>
  );
}

RadioField.defaultProps = {
  column: false,
  divider: false
};
