import { createSlice } from 'redux-starter-kit';
import { slice as productOrderSlice } from 'maua-redux-core/reducers/productOrder';

let initialState = {
  order: {},
  showDetailOrderItem: false,
  deliveryServiceMethod: null,
  needReloadCountProductOrders: false
};

export let ordersSlice = createSlice({
  initialState: initialState,
  slice: 'orders',
  reducers: {
    clearProductOrder(state) {
      state.order = {};
    },
    toggleDetailOrdeItem(state, action) {
      state.showDetailOrderItem = action.payload.state;
    },
    setDeliveryServiceMethod(state, action) {
      state.deliveryServiceMethod = action.payload.state;
    },
    setNeedReloadCountProductOrders(state, action) {
      state.needReloadCountProductOrders = action.payload.state;
    }
  },
  extraReducers: {
    [productOrderSlice.actions.addProductOrder](state, action) {
      state.order = action.payload.addProductOrder;
    }
  }
});

export default ordersSlice.reducer;
