import React from 'react';
import { isEmpty, get } from 'lodash';
import { useTranslation } from 'react-i18next';
import Box from '@material-ui/core/Box';
import { CurrencySymbol } from '../../../components/CurrencySymbol';
import TablePagination from '@material-ui/core/TablePagination';
import { formatDate } from 'maua-redux-core/utils/format';
import { useStyle } from './style';

const TableList = props => {
  const { data, loadTransactions } = props;

  const { total, docs, limit, page, pages } = data;
  const { t } = useTranslation();
  const classes = useStyle();

  const handleChangePage = (event, newPage) => {
    loadTransactions(newPage);
  };

  const sortData = data =>
    data?.sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt));

  return (
    <Box className={classes.rightColumn}>
      <Box className={[classes.flex, classes.headerTable].join(' ')}>
        <Box className={classes.rowHeader}>
          {t('transaction.table.col.Transaction ID')}
        </Box>
        <Box className={classes.rowHeader}>
          {t('transaction.table.col.Order ID')}
        </Box>
        <Box className={classes.rowHeader}>
          {t('transaction.table.col.Payment Method')}
        </Box>
        <Box className={classes.rowHeader}>
          {t('transaction.table.col.Paid Amount')}
        </Box>
        <Box className={classes.rowHeader}>
          {t('transaction.table.col.Paid Revenue')}
        </Box>
        <Box className={classes.rowHeader}>
          {t('transaction.table.col.Status')}
        </Box>
        <Box className={classes.rowHeader}>
          {t('transaction.table.col.Date Created')}
        </Box>
        <Box className={classes.rowHeader}>
          {t('transaction.table.col.Date Updated')}
        </Box>
      </Box>
      <Box className={classes.productsContent}>
        {docs.map(doc => (
          <Box className={classes.flex} key={doc._id}>
            <Box className={classes.row}>{doc?.code}</Box>
            <Box className={classes.row}>
              {!isEmpty(doc.order)
                ? get(doc, 'order.code')
                : get(doc, 'delivery.code')}
            </Box>
            <Box className={classes.row}>{doc.paymentMethod}</Box>
            <Box className={classes.row}>
              <CurrencySymbol>{doc?.amount}</CurrencySymbol>
            </Box>
            <Box className={classes.row}>
              <CurrencySymbol>{doc?.amount}</CurrencySymbol>
            </Box>
            <Box className={classes.row}>{doc?.status}</Box>
            <Box className={classes.row}>
              {formatDate(doc.createdAt, 'DD/MM/YYYY HH:mm')}
            </Box>
            <Box className={classes.row}>
              {formatDate(doc.updatedAt, 'DD/MM/YYYY HH:mm')}
            </Box>
          </Box>
        ))}
      </Box>

      <Box style={{ float: 'right' }}>
        <TablePagination
          count={total}
          rowsPerPage={limit || 20}
          page={(Number(page) || 1) - 1}
          onChangePage={handleChangePage}
          rowsPerPageOptions={[limit]}
          component="div"
          backIconButtonProps={{ 'aria-label': 'Previous Page' }}
          nextIconButtonProps={{ 'aria-label': 'Next Page' }}
        />
      </Box>
    </Box>
  );
};

export default TableList;
