import moment from 'moment';

import { date, object, string, array, boolean } from 'yup';

export const VALIDATE_MAX_DATE = moment().subtract(18, 'years');

export const userSchema = object().shape({
  name: string().required('All field is required')
});

export const profileSchema = object({
  firstName: string()
    .required('First Name is required')
    .nullable(),
  phone: string()
    .required('Phone Number is required')
    .test('Digits only', 'The field should have digits only', value =>
      /^\d+$/.test(value)
    ),
  lastName: string()
    .required('Last Name is required')
    .nullable(),
  gender: string()
    .matches(/(male|female)/)
    .required('Gender is required')
    .nullable(),
  birthday: date()
    .max(VALIDATE_MAX_DATE, 'You must be over 18 years old')
    .required('Date of Birth is required')
    .nullable(),
  name: string()
    .required('Business Name is required')
    .nullable(),
  categories: string()
    .required('Categories is required')
    .nullable(),
  alaNum: string()
    .required('Ala Number is required')
    .nullable(),
  // village: string()
  //   .required('Village is required')
  //   .nullable(),
  country: string()
    .required('Country is required')
    .nullable(),
  description: string()
    .required('Introduction is required')
    .nullable()
});

export const reasonSchema = object({
  reason: string()
    .required('Reason is required')
    .nullable()
});

export const productSamoaSchema = object({
  subCategory: string()
    .required('Sub Category is required')
    .nullable(),
  productName: string()
    .required('Product name is required')
    .nullable(),
  description: string()
    .required('Description is required')
    .nullable(),
  price: string()
    .required('Price is required')
    .nullable()
    .test('price-match', 'Price must be greater than 0', function(value) {
      return value && !isNaN(+value);
    })
    .test('price-less-100b', 'Price must be less than 100 billion', function(
      value
    ) {
      return value <= 100000000000;
    }),

  variants: array().of(
    object().shape({
      name: string()
        .required('Name of variant is required')
        .nullable(),
      status: string()
        .required('Status of variant is required')
        .nullable()
        .default('active'),
      isMultipleChoice: boolean().default(false),
      mandatory: boolean().default(false),
      options: array()
        .of(
          object().shape({
            name: string()
              .required('name of variant option is needed')
              .nullable(),
            price: string()
              .required('Price is required')
              .nullable()
              .test('Price must be greater than 0', 'price-match', function(
                value
              ) {
                return value && !isNaN(+value);
              })
              .test(
                'variant-price-less-100b',
                'Price must be less than 100 billion',
                function(value) {
                  return value <= 100000000000;
                }
              )
          })
        )
        .default([])
    })
  ),

  qty: string()
    .required('Qty is required')
    .nullable()
    .test('qty-match', 'Quantity must be greater than 0', function(value) {
      return value && parseInt(value) >= 0;
    })
    .test('qty-less-2b', 'Quantity must be less than 2 billion', function(
      value
    ) {
      return value <= 2000000000;
    }),
  discount: string()
    .required('Discount is required')
    .test(
      'discount-match',
      'Discount must be less than 100 and greater than 0',
      function(value) {
        return value && parseInt(value) <= 100 && parseInt(value) >= 0;
      }
    )
    .nullable(),
  reducedPrice: string()
    .required('ReducedPrice is required')
    .nullable()
    .test(
      'reducedPrice-match',
      'Reduced Price must be greater than 0',
      function(value) {
        return value && parseInt(value) >= 0;
      }
    )
});

export const productVanuatuSchema = object({
  subCategory: string()
    .required('Sub Category is required')
    .nullable(),
  productName: string()
    .required('Product name is required')
    .nullable(),
  description: string()
    .required('Description is required')
    .nullable(),
  price: string()
    .required('Price is required')
    .nullable()
    .test(
      'price-match',
      'Price must be divisible by 5 and greater than 0',
      function(value) {
        return value && !isNaN(+value) && parseInt(value) % 5 === 0;
      }
    )
    .test('price-less-100b', 'Price must be less than 100 billion', function(
      value
    ) {
      return value <= 100000000000;
    }),

  variants: array().of(
    object().shape({
      name: string()
        .required('Name of variant is required')
        .nullable(),
      status: string()
        .required('Status of variant is required')
        .nullable()
        .default('active'),
      isMultipleChoice: boolean().default(false),
      mandatory: boolean().default(false),
      options: array()
        .of(
          object().shape({
            name: string()
              .required('name of variant option is needed')
              .nullable(),
            price: string()
              .required('Price is required')
              .nullable()
              .test('Price must be greater than 0', 'price-match', function(
                value
              ) {
                return value && !isNaN(+value);
              })
              .test(
                'variant-price-less-100b',
                'Price must be less than 100 billion',
                function(value) {
                  return value <= 100000000000;
                }
              )
          })
        )
        .default([])
    })
  ),

  qty: string()
    .required('Qty is required')
    .nullable()
    .test('qty-match', 'Quantity must be greater than 0', function(value) {
      return value && parseInt(value) >= 0;
    })
    .test('qty-less-2b', 'Quantity must be less than 2 billion', function(
      value
    ) {
      return value <= 2000000000;
    }),
  discount: string()
    .required('Discount is required')
    .test(
      'discount-match',
      'Discount must be less than 100 and greater than 0',
      function(value) {
        return value && parseInt(value) <= 100 && parseInt(value) >= 0;
      }
    )
    .nullable(),
  reducedPrice: string()
    .required('ReducedPrice is required')
    .nullable()
    .test(
      'reducedPrice-match',
      'Reduced Price must be divisible by 5 and greater than 0',
      function(value) {
        return value && parseInt(value) >= 0 && parseInt(value) % 5 === 0;
      }
    )
});

export const editYourStoreSchema = object({
  name: string()
    .required('Name is required')
    .nullable(),
  phone: string()
    .required('Phone Number is required')
    .test('Digits only', 'The field should have digits only', value =>
      /^\d+$/.test(value)
    ),
  // village: string()
  //   .required('Village is required')
  //   .nullable(),
  alaNum: string()
    .required('AlaNumber is required')
    .nullable(),
  country: string()
    .required('Country is required')
    .nullable(),
  categories: string()
    .required('Categories is required')
    .nullable()
});

export const editUserInfoSchema = object({
  firstName: string()
    .required('First Name is required')
    .nullable(),
  lastName: string()
    .required('Last Name is required')
    .nullable(),
  birthday: date()
    .max(VALIDATE_MAX_DATE, 'You must be over 18 years old')
    .required('Date of Birth is required')
    .nullable()
});

export const enterStaffPhoneSchema = object({
  phone: string()
    .required('Phone number is required')
    .nullable()
});

export const enterPhoneNumberLoginSchema = object({
  phoneCode: string()
    .required('Code is required')
    .nullable(),
  phone: string()
    .required('Phone number is required')
    .nullable()
    .test('phone-match', 'Phone number is invalid', function(value) {
      return value.match(/^((?!(0))[0-9]{9})$|^[1-9]\d{9}$/g);
    })
});
