import React, { useState, useRef, useEffect } from 'react';
import TextField from '@material-ui/core/TextField';
import ArrowRightIcon from '@material-ui/icons/ArrowRight';
import { countries } from '../../../shared/constant/countries';
import { onClickOutside } from '../../../utils/common';
import { Box, InputAdornment } from '@material-ui/core';
import useStyle from './style';
import { checkLocation } from '../../../utils/checkLocation';

const initialOptions = countries().map(item => ({
  label: item.label,
  value: item.value,
  code: item.code,
  flag: item.flag
}));

const flagVN =
  'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACgAAAAeCAMAAABpA6zvAAAABGdBTUEAALGPC/xhBQAAAAFzUkdCAK7OHOkAAAAgY0hSTQAAeiYAAICEAAD6AAAAgOgAAHUwAADqYAAAOpgAABdwnLpRPAAAAPZQTFRF7AAV7AEV7AkV7RQU7AIV6wAW8DoQ9YAL7AMV7AQV6gAX9XoL/N4D+rsG//8A7ywS7h4T/vAB9XcL7AYU7AAW818O+8QF6wEW7AUV7yYS7hoT7iES7RQT+bIG//4A/vgB8UAQ7h0T7yUS7yMS7h4S8DAR7hkT9X0L//kA/vQB//oA//0A//wA//sA7hwT8lMO95YJ7iET+8kE/vYB810N6wAX95UJ/NsD7y4R7zkR+KoI7QYU9oYK/uwC+8oE/NQE+KEI8k8P7ygS//kB+bMG81wN95gJ9G8M9XYL/NUD/eYC7AcV+KMI+J8I8UYP6wUW7RIT////RWZ05wAAAAFiS0dEUZRpfCoAAAAJcEhZcwAAAEgAAABIAEbJaz4AAAD7SURBVDjL3ZFZN8RAFIQTuUW6USaW0QYJYxsi9tjGMmPf+f+/Rseb43TLo6Ne+j5859atriD4WwrrcnXBgajWTsHgEOIaoNLDI6hjLBjlGMSfQkTioJFwHBN2FOelwCSUniKbmIaVizMzrVZjdm6eabaw2F5aXll1pV9jZ72zkZNpvllkbG45wBDbCXOmZIVyx8B1ZIzdPUtWHPcPytgdXcEcVhtzHh2XylePwYl1LQqe6q6/FpwxS1nw/Ne+L8jLniX73r4FV9e8ifRtwjsvqMp7PkBM2X58evZ5h8FLXyuxp6rXN+/KboSvX5Hg/cOfJoy/vx7zn9P/0ydsjBUrBoCy/wAAACV0RVh0ZGF0ZTpjcmVhdGUAMjAxMy0xMC0wN1QxMzoxNDo0MSswMjowMLsLDnsAAAAldEVYdGRhdGU6bW9kaWZ5ADIwMTMtMTAtMDdUMTM6MTQ6NDErMDI6MDDKVrbHAAAAAElFTkSuQmCC';

const defaultCode =
  process.env.NODE_ENV === 'development'
    ? { value: '+84', flag: flagVN }
    : checkLocation().defaultCode;

const SelectCountry = props => {
  const rootRef = useRef();
  const timeout = useRef();
  const [show, setShow] = useState(false);
  const [options, setOptions] = useState(initialOptions);
  const [value, setValue] = useState(defaultCode);
  const [keyword, setKeyword] = useState('');

  const classes = useStyle({ show });

  onClickOutside(rootRef, () => setShow(false));

  useEffect(() => {
    timeout.current && clearTimeout(timeout.current);

    timeout.current = setTimeout(() => {
      handleSearchOption();
    }, 500);

    return () => clearTimeout(timeout.current);
  }, [keyword]);

  useEffect(() => {
    props.setCode(value);
  }, [value]);

  const handleSearchOption = e => {
    const regex = new RegExp(
      keyword.replace(/[-\/\\^$*+?.()|[\]{}]/g, '\\$&'),
      'i'
    );

    setOptions(() => {
      return initialOptions.filter(
        ({ label, code, value }) =>
          regex.test(label) || regex.test(code) || regex.test(value)
      );
    });
  };

  const handleChangeKeyword = e => {
    const val = e.target.value;

    setKeyword(val);
  };

  return (
    <Box height={60} position="relative" ref={rootRef}>
      <Box
        display="flex"
        alignItems="center"
        className={classes.countryCode}
        onClick={() => setShow(prev => !prev)}>
        <img
          src={value.flag}
          alt={value.label}
          className={classes.imgCountry}
        />
        {value.value || 'Code'}

        <ArrowRightIcon className={classes.drownIcon} />
      </Box>

      {show && (
        <Box className={classes.country}>
          <TextField
            margin="none"
            label=""
            autoFocus={show}
            value={keyword}
            onChange={handleChangeKeyword}
            placeholder="Country"
            className={classes.searchField}
            style={{ width: '100%' }}
          />
          <Box overflow="auto" height={350}>
            {options.map((c, index) => {
              return (
                <Box
                  key={index}
                  className={classes.itemCountry}
                  onClick={() => {
                    setValue(c);
                    props.setCode(c);
                    setShow(false);
                  }}>
                  <Box display="flex" alignItems="center">
                    <img
                      src={c.flag}
                      alt={c.label}
                      style={{ height: 16, marginRight: 8 }}
                    />
                    <span className={classes.labelCountry}>
                      {c.label}
                      {` (${c.value})`}
                    </span>
                  </Box>
                </Box>
              );
            })}
          </Box>
        </Box>
      )}
    </Box>
  );
};

export default SelectCountry;
