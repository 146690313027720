import React, { useEffect, useState } from 'react';
import Box from '@material-ui/core/Box';
import clsx from 'clsx';

import useStyle from './style';

let currentCount = 0;
const Carousel = React.memo(
  ({
    data,
    itemPerPage,
    renderComponent,
    spacing,
    noStretchBox = false,
    propClassName = '',
    isControlCenter = false,
    onClickItem = () => {}
  }) => {
    const classes = useStyle();
    const btnClasses = classes;
    const [itemWidth, setItemWidth] = useState(300);
    const [count, setCount] = useState(0);
    useEffect(() => {
      if (data) {
        setItemWidth(100 / itemPerPage);
      }
    }, [data]);

    const hanldeNext = () => {
      let availableNextItem = data.length - itemPerPage * (count + 1);
      let nextCount =
        availableNextItem >= itemPerPage
          ? count + 1
          : count + availableNextItem / itemPerPage;
      currentCount = count;
      setCount(nextCount);
    };
    const hanldePrev = () => {
      let availablePrevItem = itemPerPage * count;
      let prevCount = availablePrevItem >= itemPerPage ? count - 1 : 0;
      currentCount = count;
      setCount(prevCount);
    };
    let StyleContent = {
      transform: `translateX(${-100 * count}%)`,
      transition: `all ${Math.abs(currentCount - count) * 1}s`
    };

    const maxCount = (data.length - itemPerPage) / itemPerPage;

    return (
      <div>
        {!isControlCenter ? (
          <div className={btnClasses.controlBtnGroup}>
            <button
              className={clsx(
                btnClasses.controlPrev,
                count === 0
                  ? btnClasses.controlBtnDisable
                  : btnClasses.controlBtn
              )}
              disabled={count === 0}
              onClick={hanldePrev}>
              <i className="fa fa-angle-left" />
            </button>
            <button
              className={clsx(
                btnClasses.controlNext,
                count === maxCount
                  ? btnClasses.controlBtnDisable
                  : btnClasses.controlBtn
              )}
              disabled={count === maxCount}
              onClick={hanldeNext}>
              <i className="fa fa-angle-right" />
            </button>
          </div>
        ) : (
          <>
            <button
              className={clsx(
                btnClasses.controlCenterPrev,
                count === 0
                  ? btnClasses.controlBtnDisableCenter
                  : btnClasses.controlBtnCenter
              )}
              disabled={count === 0}
              onClick={hanldePrev}>
              <i className="fa fa-angle-left" />
            </button>
            <button
              className={clsx(
                btnClasses.controlCenterNext,
                count === maxCount
                  ? btnClasses.controlBtnDisableCenter
                  : btnClasses.controlBtnCenter
              )}
              disabled={count === maxCount}
              onClick={hanldeNext}>
              <i className="fa fa-angle-right" />
            </button>
          </>
        )}
        <Box className={clsx(classes.carousel, propClassName)}>
          <Box
            style={StyleContent}
            display="flex"
            mx={!noStretchBox ? -1.6 : 0}
            className={classes.carouselContent}>
            {data &&
              data.length &&
              data.map((item, index) => (
                <Box
                  key={index}
                  width={`${itemWidth}%`}
                  px={spacing || 0}
                  className={classes.carouselItem}
                  onClick={() => onClickItem(item)}>
                  {renderComponent({ item })}
                </Box>
              ))}
          </Box>
        </Box>
      </div>
    );
  }
);

export default Carousel;
