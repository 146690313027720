import React from 'react';
import CircularProgress from '@material-ui/core/CircularProgress';
import makeStyles from '@material-ui/core/styles/makeStyles';
const useStyles = makeStyles({
  shading: {
    position: 'absolute',
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
    background: 'rgba(255, 255, 255, .8)',
    zIndex: 1301
  },
  icon: {
    position: 'absolute',
    fontSize: '20px',
    top: 'calc(50% - 10px)',
    left: 'calc(50% - 10px)'
    // transform: 'translate(-50%, -50%)'
  }
});

/**
 * @return {null}
 */
function LoadingComponent(props) {
  const classes = useStyles();

  return props.show ? (
    <div className={classes.shading}>
      <CircularProgress className={classes.icon} />
    </div>
  ) : null;
}

export default LoadingComponent;
