const countriesJSON = require('./countries.json');
let Countries = [
  { label: 'Afghanistan', searchKey: 'Afghanistan', value: '+93', code: 'AF' },
  { label: 'Albania', searchKey: 'Albania', value: '+355', code: 'AL' },
  { label: 'Algeria', searchKey: 'Algeria', value: '+213', code: 'DZ' },
  {
    label: 'AmericanSamoa',
    searchKey: 'AmericanSamoa',
    value: '+1 684',
    code: 'AS'
  },
  { label: 'Andorra', searchKey: 'Andorra', value: '+376', code: 'AD' },
  { label: 'Angola', searchKey: 'Angola', value: '+244', code: 'AO' },
  { label: 'Anguilla', searchKey: 'Anguilla', value: '+1 264', code: 'AI' },
  { label: 'Antarctica', searchKey: 'Antarctica', value: '+672', code: 'AQ' },
  {
    label: 'Antigua and Barbuda',
    searchKey: 'Antigua and Barbuda',
    value: '+1268',
    code: 'AG'
  },
  { label: 'Argentina', searchKey: 'Argentina', value: '+54', code: 'AR' },
  { label: 'Armenia', searchKey: 'Armenia', value: '+374', code: 'AM' },
  { label: 'Aruba', searchKey: 'Aruba', value: '+297', code: 'AW' },
  { label: 'Australia', searchKey: 'Australia', value: '+61', code: 'AU' },
  { label: 'Austria', searchKey: 'Austria', value: '+43', code: 'AT' },
  { label: 'Azerbaijan', searchKey: 'Azerbaijan', value: '+994', code: 'AZ' },
  { label: 'Bahamas', searchKey: 'Bahamas', value: '+1 242', code: 'BS' },
  { label: 'Bahrain', searchKey: 'Bahrain', value: '+973', code: 'BH' },
  { label: 'Bangladesh', searchKey: 'Bangladesh', value: '+880', code: 'BD' },
  { label: 'Barbados', searchKey: 'Barbados', value: '+1 246', code: 'BB' },
  { label: 'Belarus', searchKey: 'Belarus', value: '+375', code: 'BY' },
  { label: 'Belgium', searchKey: 'Belgium', value: '+32', code: 'BE' },
  { label: 'Belize', searchKey: 'Belize', value: '+501', code: 'BZ' },
  { label: 'Benin', searchKey: 'Benin', value: '+229', code: 'BJ' },
  { label: 'Bermuda', searchKey: 'Bermuda', value: '+1 441', code: 'BM' },
  { label: 'Bhutan', searchKey: 'Bhutan', value: '+975', code: 'BT' },
  {
    label: 'Bolivia, Plurinational State of',
    searchKey: 'Bolivia, Plurinational State of',
    value: '+591',
    code: 'BO'
  },
  {
    label: 'Bosnia and Herzegovina',
    searchKey: 'Bosnia and Herzegovina',
    value: '+387',
    code: 'BA'
  },
  { label: 'Botswana', searchKey: 'Botswana', value: '+267', code: 'BW' },
  { label: 'Brazil', searchKey: 'Brazil', value: '+55', code: 'BR' },
  {
    label: 'British Indian Ocean Territory',
    searchKey: 'British Indian Ocean Territory',
    value: '+246',
    code: 'IO'
  },
  {
    label: 'Brunei Darussalam',
    searchKey: 'Brunei Darussalam',
    value: '+673',
    code: 'BN'
  },
  { label: 'Bulgaria', searchKey: 'Bulgaria', value: '+359', code: 'BG' },
  {
    label: 'Burkina Faso',
    searchKey: 'Burkina Faso',
    value: '+226',
    code: 'BF'
  },
  { label: 'Burundi', searchKey: 'Burundi', value: '+257', code: 'BI' },
  { label: 'Cambodia', searchKey: 'Cambodia', value: '+855', code: 'KH' },
  { label: 'Cameroon', searchKey: 'Cameroon', value: '+237', code: 'CM' },
  { label: 'Canada', searchKey: 'Canada', value: '+1', code: 'CA' },
  { label: 'Cape Verde', searchKey: 'Cape Verde', value: '+238', code: 'CV' },
  {
    label: 'Cayman Islands',
    searchKey: 'Cayman Islands',
    value: '+ 345',
    code: 'KY'
  },
  {
    label: 'Central African Republic',
    searchKey: 'Central African Republic',
    value: '+236',
    code: 'CF'
  },
  { label: 'Chad', searchKey: 'Chad', value: '+235', code: 'TD' },
  { label: 'Chile', searchKey: 'Chile', value: '+56', code: 'CL' },
  { label: 'China', searchKey: 'China', value: '+86', code: 'CN' },
  {
    label: 'Christmas Island',
    searchKey: 'Christmas Island',
    value: '+61',
    code: 'CX'
  },
  {
    label: 'Cocos (Keeling) Islands',
    searchKey: 'Cocos (Keeling) Islands',
    value: '+61',
    code: 'CC'
  },
  { label: 'Colombia', searchKey: 'Colombia', value: '+57', code: 'CO' },
  { label: 'Comoros', searchKey: 'Comoros', value: '+269', code: 'KM' },
  { label: 'Congo', searchKey: 'Congo', value: '+242', code: 'CG' },
  {
    label: 'Congo, The Democratic Republic of the',
    searchKey: 'Congo, The Democratic Republic of the',
    value: '+243',
    code: 'CD'
  },
  {
    label: 'Cook Islands',
    searchKey: 'Cook Islands',
    value: '+682',
    code: 'CK'
  },
  { label: 'Costa Rica', searchKey: 'Costa Rica', value: '+506', code: 'CR' },
  {
    label: "Cote d'Ivoire",
    searchKey: "Cote d'Ivoire",
    value: '+225',
    code: 'CI'
  },
  { label: 'Croatia', searchKey: 'Croatia', value: '+385', code: 'HR' },
  { label: 'Cuba', searchKey: 'Cuba', value: '+53', code: 'CU' },
  { label: 'Cyprus', searchKey: 'Cyprus', value: '+357', code: 'CY' },
  {
    label: 'Czech Republic',
    searchKey: 'Czech Republic',
    value: '+420',
    code: 'CZ'
  },
  { label: 'Denmark', searchKey: 'Denmark', value: '+45', code: 'DK' },
  { label: 'Djibouti', searchKey: 'Djibouti', value: '+253', code: 'DJ' },
  { label: 'Dominica', searchKey: 'Dominica', value: '+1 767', code: 'DM' },
  {
    label: 'Dominican Republic',
    searchKey: 'Dominican Republic',
    value: '+1 849',
    code: 'DO'
  },
  { label: 'Ecuador', searchKey: 'Ecuador', value: '+593', code: 'EC' },
  { label: 'Egypt', searchKey: 'Egypt', value: '+20', code: 'EG' },
  { label: 'El Salvador', searchKey: 'El Salvador', value: '+503', code: 'SV' },
  {
    label: 'Equatorial Guinea',
    searchKey: 'Equatorial Guinea',
    value: '+240',
    code: 'GQ'
  },
  { label: 'Eritrea', searchKey: 'Eritrea', value: '+291', code: 'ER' },
  { label: 'Estonia', searchKey: 'Estonia', value: '+372', code: 'EE' },
  { label: 'Ethiopia', searchKey: 'Ethiopia', value: '+251', code: 'ET' },
  {
    label: 'Falkland Islands (Malvinas)',
    searchKey: 'Falkland Islands (Malvinas)',
    value: '+500',
    code: 'FK'
  },
  {
    label: 'Faroe Islands',
    searchKey: 'Faroe Islands',
    value: '+298',
    code: 'FO'
  },
  { label: 'Fiji', searchKey: 'Fiji', value: '+679', code: 'FJ' },
  { label: 'Finland', searchKey: 'Finland', value: '+358', code: 'FI' },
  { label: 'France', searchKey: 'France', value: '+33', code: 'FR' },
  {
    label: 'French Guiana',
    searchKey: 'French Guiana',
    value: '+594',
    code: 'GF'
  },
  {
    label: 'French Polynesia',
    searchKey: 'French Polynesia',
    value: '+689',
    code: 'PF'
  },
  { label: 'Gabon', searchKey: 'Gabon', value: '+241', code: 'GA' },
  { label: 'Gambia', searchKey: 'Gambia', value: '+220', code: 'GM' },
  { label: 'Georgia', searchKey: 'Georgia', value: '+995', code: 'GE' },
  { label: 'Germany', searchKey: 'Germany', value: '+49', code: 'DE' },
  { label: 'Ghana', searchKey: 'Ghana', value: '+233', code: 'GH' },
  { label: 'Gibraltar', searchKey: 'Gibraltar', value: '+350', code: 'GI' },
  { label: 'Greece', searchKey: 'Greece', value: '+30', code: 'GR' },
  { label: 'Greenland', searchKey: 'Greenland', value: '+299', code: 'GL' },
  { label: 'Grenada', searchKey: 'Grenada', value: '+1 473', code: 'GD' },
  { label: 'Guadeloupe', searchKey: 'Guadeloupe', value: '+590', code: 'GP' },
  { label: 'Guam', searchKey: 'Guam', value: '+1 671', code: 'GU' },
  { label: 'Guatemala', searchKey: 'Guatemala', value: '+502', code: 'GT' },
  { label: 'Guernsey', searchKey: 'Guernsey', value: '+44', code: 'GG' },
  { label: 'Guinea', searchKey: 'Guinea', value: '+224', code: 'GN' },
  {
    label: 'Guinea-Bissau',
    searchKey: 'Guinea-Bissau',
    value: '+245',
    code: 'GW'
  },
  { label: 'Guyana', searchKey: 'Guyana', value: '+595', code: 'GY' },
  { label: 'Haiti', searchKey: 'Haiti', value: '+509', code: 'HT' },
  {
    label: 'Holy See (Vatican City State)',
    searchKey: 'Holy See (Vatican City State)',
    value: '+379',
    code: 'VA'
  },
  { label: 'Honduras', searchKey: 'Honduras', value: '+504', code: 'HN' },
  { label: 'Hong Kong', searchKey: 'Hong Kong', value: '+852', code: 'HK' },
  { label: 'Hungary', searchKey: 'Hungary', value: '+36', code: 'HU' },
  { label: 'Iceland', searchKey: 'Iceland', value: '+354', code: 'IS' },
  { label: 'India', searchKey: 'India', value: '+91', code: 'IN' },
  { label: 'Indonesia', searchKey: 'Indonesia', value: '+62', code: 'ID' },
  {
    label: 'Iran, Islamic Republic of',
    searchKey: 'Iran, Islamic Republic of',
    value: '+98',
    code: 'IR'
  },
  { label: 'Iraq', searchKey: 'Iraq', value: '+964', code: 'IQ' },
  { label: 'Ireland', searchKey: 'Ireland', value: '+353', code: 'IE' },
  { label: 'Isle of Man', searchKey: 'Isle of Man', value: '+44', code: 'IM' },
  { label: 'Israel', searchKey: 'Israel', value: '+972', code: 'IL' },
  { label: 'Italy', searchKey: 'Italy', value: '+39', code: 'IT' },
  { label: 'Jamaica', searchKey: 'Jamaica', value: '+1 876', code: 'JM' },
  { label: 'Japan', searchKey: 'Japan', value: '+81', code: 'JP' },
  { label: 'Jersey', searchKey: 'Jersey', value: '+44', code: 'JE' },
  { label: 'Jordan', searchKey: 'Jordan', value: '+962', code: 'JO' },
  { label: 'Kazakhstan', searchKey: 'Kazakhstan', value: '+7 7', code: 'KZ' },
  { label: 'Kenya', searchKey: 'Kenya', value: '+254', code: 'KE' },
  { label: 'Kiribati', searchKey: 'Kiribati', value: '+686', code: 'KI' },
  {
    label: "Korea, Democratic People's Republic of",
    searchKey: "Korea, Democratic People's Republic of",
    value: '+850',
    code: 'KP'
  },
  {
    label: 'Korea, Republic of',
    searchKey: 'Korea, Republic of',
    value: '+82',
    code: 'KR'
  },
  { label: 'Kuwait', searchKey: 'Kuwait', value: '+965', code: 'KW' },
  { label: 'Kyrgyzstan', searchKey: 'Kyrgyzstan', value: '+996', code: 'KG' },
  {
    label: "Lao People's Democratic Republic",
    searchKey: "Lao People's Democratic Republic",
    value: '+856',
    code: 'LA'
  },
  { label: 'Latvia', searchKey: 'Latvia', value: '+371', code: 'LV' },
  { label: 'Lebanon', searchKey: 'Lebanon', value: '+961', code: 'LB' },
  { label: 'Lesotho', searchKey: 'Lesotho', value: '+266', code: 'LS' },
  { label: 'Liberia', searchKey: 'Liberia', value: '+231', code: 'LR' },
  {
    label: 'Libyan Arab Jamahiriya',
    searchKey: 'Libyan Arab Jamahiriya',
    value: '+218',
    code: 'LY'
  },
  {
    label: 'Liechtenstein',
    searchKey: 'Liechtenstein',
    value: '+423',
    code: 'LI'
  },
  { label: 'Lithuania', searchKey: 'Lithuania', value: '+370', code: 'LT' },
  { label: 'Luxembourg', searchKey: 'Luxembourg', value: '+352', code: 'LU' },
  { label: 'Macao', searchKey: 'Macao', value: '+853', code: 'MO' },
  {
    label: 'Macedonia, The Former Yugoslav Republic of',
    searchKey: 'Macedonia, The Former Yugoslav Republic of',
    value: '+389',
    code: 'MK'
  },
  { label: 'Madagascar', searchKey: 'Madagascar', value: '+261', code: 'MG' },
  { label: 'Malawi', searchKey: 'Malawi', value: '+265', code: 'MW' },
  { label: 'Malaysia', searchKey: 'Malaysia', value: '+60', code: 'MY' },
  { label: 'Maldives', searchKey: 'Maldives', value: '+960', code: 'MV' },
  { label: 'Mali', searchKey: 'Mali', value: '+223', code: 'ML' },
  { label: 'Malta', searchKey: 'Malta', value: '+356', code: 'MT' },
  {
    label: 'Marshall Islands',
    searchKey: 'Marshall Islands',
    value: '+692',
    code: 'MH'
  },
  { label: 'Martinique', searchKey: 'Martinique', value: '+596', code: 'MQ' },
  { label: 'Mauritania', searchKey: 'Mauritania', value: '+222', code: 'MR' },
  { label: 'Mauritius', searchKey: 'Mauritius', value: '+230', code: 'MU' },
  { label: 'Mayotte', searchKey: 'Mayotte', value: '+262', code: 'YT' },
  { label: 'Mexico', searchKey: 'Mexico', value: '+52', code: 'MX' },
  {
    label: 'Micronesia, Federated States of',
    searchKey: 'Micronesia, Federated States of',
    value: '+691',
    code: 'FM'
  },
  {
    label: 'Moldova, Republic of',
    searchKey: 'Moldova, Republic of',
    value: '+373',
    code: 'MD'
  },
  { label: 'Monaco', searchKey: 'Monaco', value: '+377', code: 'MC' },
  { label: 'Mongolia', searchKey: 'Mongolia', value: '+976', code: 'MN' },
  { label: 'Montenegro', searchKey: 'Montenegro', value: '+382', code: 'ME' },
  { label: 'Montserrat', searchKey: 'Montserrat', value: '+1664', code: 'MS' },
  { label: 'Morocco', searchKey: 'Morocco', value: '+212', code: 'MA' },
  { label: 'Mozambique', searchKey: 'Mozambique', value: '+258', code: 'MZ' },
  { label: 'Myanmar', searchKey: 'Myanmar', value: '+95', code: 'MM' },
  { label: 'Namibia', searchKey: 'Namibia', value: '+264', code: 'NA' },
  { label: 'Nauru', searchKey: 'Nauru', value: '+674', code: 'NR' },
  { label: 'Nepal', searchKey: 'Nepal', value: '+977', code: 'NP' },
  { label: 'Netherlands', searchKey: 'Netherlands', value: '+31', code: 'NL' },
  {
    label: 'Netherlands Antilles',
    searchKey: 'Netherlands Antilles',
    value: '+599',
    code: 'AN'
  },
  {
    label: 'New Caledonia',
    searchKey: 'New Caledonia',
    value: '+687',
    code: 'NC'
  },
  { label: 'New Zealand', searchKey: 'New Zealand', value: '+64', code: 'NZ' },
  { label: 'Nicaragua', searchKey: 'Nicaragua', value: '+505', code: 'NI' },
  { label: 'Niger', searchKey: 'Niger', value: '+227', code: 'NE' },
  { label: 'Nigeria', searchKey: 'Nigeria', value: '+234', code: 'NG' },
  { label: 'Niue', searchKey: 'Niue', value: '+683', code: 'NU' },
  {
    label: 'Norfolk Island',
    searchKey: 'Norfolk Island',
    value: '+672',
    code: 'NF'
  },
  {
    label: 'Northern Mariana Islands',
    searchKey: 'Northern Mariana Islands',
    value: '+1 670',
    code: 'MP'
  },
  { label: 'Norway', searchKey: 'Norway', value: '+47', code: 'NO' },
  { label: 'Oman', searchKey: 'Oman', value: '+968', code: 'OM' },
  { label: 'Pakistan', searchKey: 'Pakistan', value: '+92', code: 'PK' },
  { label: 'Palau', searchKey: 'Palau', value: '+680', code: 'PW' },
  {
    label: 'Palestinian Territory, Occupied',
    searchKey: 'Palestinian Territory, Occupied',
    value: '+970',
    code: 'PS'
  },
  { label: 'Panama', searchKey: 'Panama', value: '+507', code: 'PA' },
  {
    label: 'Papua New Guinea',
    searchKey: 'Papua New Guinea',
    value: '+675',
    code: 'PG'
  },
  { label: 'Paraguay', searchKey: 'Paraguay', value: '+595', code: 'PY' },
  { label: 'Peru', searchKey: 'Peru', value: '+51', code: 'PE' },
  { label: 'Philippines', searchKey: 'Philippines', value: '+63', code: 'PH' },
  { label: 'Pitcairn', searchKey: 'Pitcairn', value: '+872', code: 'PN' },
  { label: 'Poland', searchKey: 'Poland', value: '+48', code: 'PL' },
  { label: 'Portugal', searchKey: 'Portugal', value: '+351', code: 'PT' },
  {
    label: 'Puerto Rico',
    searchKey: 'Puerto Rico',
    value: '+1 939',
    code: 'PR'
  },
  { label: 'Qatar', searchKey: 'Qatar', value: '+974', code: 'QA' },
  { label: 'Romania', searchKey: 'Romania', value: '+40', code: 'RO' },
  { label: 'Russia', searchKey: 'Russia', value: '+7', code: 'RU' },
  { label: 'Rwanda', searchKey: 'Rwanda', value: '+250', code: 'RW' },
  { label: 'Réunion', searchKey: 'Réunion', value: '+262', code: 'RE' },
  {
    label: 'Saint Barthélemy',
    searchKey: 'Saint Barthélemy',
    value: '+590',
    code: 'BL'
  },
  {
    label: 'Saint Helena, Ascension and Tristan Da Cunha',
    searchKey: 'Saint Helena, Ascension and Tristan Da Cunha',
    value: '+290',
    code: 'SH'
  },
  {
    label: 'Saint Kitts and Nevis',
    searchKey: 'Saint Kitts and Nevis',
    value: '+1 869',
    code: 'KN'
  },
  {
    label: 'Saint Lucia',
    searchKey: 'Saint Lucia',
    value: '+1 758',
    code: 'LC'
  },
  {
    label: 'Saint Martin',
    searchKey: 'Saint Martin',
    value: '+590',
    code: 'MF'
  },
  {
    label: 'Saint Pierre and Miquelon',
    searchKey: 'Saint Pierre and Miquelon',
    value: '+508',
    code: 'PM'
  },
  {
    label: 'Saint Vincent and the Grenadines',
    searchKey: 'Saint Vincent and the Grenadines',
    value: '+1 784',
    code: 'VC'
  },
  { label: 'Samoa', searchKey: 'Samoa', value: '+685', code: 'WS' },
  { label: 'San Marino', searchKey: 'San Marino', value: '+378', code: 'SM' },
  {
    label: 'Sao Tome and Principe',
    searchKey: 'Sao Tome and Principe',
    value: '+239',
    code: 'ST'
  },
  {
    label: 'Saudi Arabia',
    searchKey: 'Saudi Arabia',
    value: '+966',
    code: 'SA'
  },
  { label: 'Senegal', searchKey: 'Senegal', value: '+221', code: 'SN' },
  { label: 'Serbia', searchKey: 'Serbia', value: '+381', code: 'RS' },
  { label: 'Seychelles', searchKey: 'Seychelles', value: '+248', code: 'SC' },
  {
    label: 'Sierra Leone',
    searchKey: 'Sierra Leone',
    value: '+232',
    code: 'SL'
  },
  { label: 'Singapore', searchKey: 'Singapore', value: '+65', code: 'SG' },
  { label: 'Slovakia', searchKey: 'Slovakia', value: '+421', code: 'SK' },
  { label: 'Slovenia', searchKey: 'Slovenia', value: '+386', code: 'SI' },
  {
    label: 'Solomon Islands',
    searchKey: 'Solomon Islands',
    value: '+677',
    code: 'SB'
  },
  { label: 'Somalia', searchKey: 'Somalia', value: '+252', code: 'SO' },
  {
    label: 'South Africa',
    searchKey: 'South Africa',
    value: '+27',
    code: 'ZA'
  },
  {
    label: 'South Georgia and the South Sandwich Islands',
    searchKey: 'South Georgia and the South Sandwich Islands',
    value: '+500',
    code: 'GS'
  },
  { label: 'Spain', searchKey: 'Spain', value: '+34', code: 'ES' },
  { label: 'Sri Lanka', searchKey: 'Sri Lanka', value: '+94', code: 'LK' },
  { label: 'Sudan', searchKey: 'Sudan', value: '+249', code: 'SD' },
  { label: 'Suriname', searchKey: 'Suriname', value: '+597', code: 'SR' },
  {
    label: 'Svalbard and Jan Mayen',
    searchKey: 'Svalbard and Jan Mayen',
    value: '+47',
    code: 'SJ'
  },
  { label: 'Swaziland', searchKey: 'Swaziland', value: '+268', code: 'SZ' },
  { label: 'Sweden', searchKey: 'Sweden', value: '+46', code: 'SE' },
  { label: 'Switzerland', searchKey: 'Switzerland', value: '+41', code: 'CH' },
  {
    label: 'Syrian Arab Republic',
    searchKey: 'Syrian Arab Republic',
    value: '+963',
    code: 'SY'
  },
  { label: 'Taiwan', searchKey: 'Taiwan', value: '+886', code: 'TW' },
  { label: 'Tajikistan', searchKey: 'Tajikistan', value: '+992', code: 'TJ' },
  {
    label: 'Tanzania, United Republic of',
    searchKey: 'Tanzania, United Republic of',
    value: '+255',
    code: 'TZ'
  },
  { label: 'Thailand', searchKey: 'Thailand', value: '+66', code: 'TH' },
  { label: 'Timor-Leste', searchKey: 'Timor-Leste', value: '+670', code: 'TL' },
  { label: 'Togo', searchKey: 'Togo', value: '+228', code: 'TG' },
  { label: 'Tokelau', searchKey: 'Tokelau', value: '+690', code: 'TK' },
  { label: 'Tonga', searchKey: 'Tonga', value: '+676', code: 'TO' },
  {
    label: 'Trinidad and Tobago',
    searchKey: 'Trinidad and Tobago',
    value: '+1 868',
    code: 'TT'
  },
  { label: 'Tunisia', searchKey: 'Tunisia', value: '+216', code: 'TN' },
  { label: 'Turkey', searchKey: 'Turkey', value: '+90', code: 'TR' },
  {
    label: 'Turkmenistan',
    searchKey: 'Turkmenistan',
    value: '+993',
    code: 'TM'
  },
  {
    label: 'Turks and Caicos Islands',
    searchKey: 'Turks and Caicos Islands',
    value: '+1 649',
    code: 'TC'
  },
  { label: 'Tuvalu', searchKey: 'Tuvalu', value: '+688', code: 'TV' },
  { label: 'Uganda', searchKey: 'Uganda', value: '+256', code: 'UG' },
  { label: 'Ukraine', searchKey: 'Ukraine', value: '+380', code: 'UA' },
  {
    label: 'United Arab Emirates',
    searchKey: 'United Arab Emirates',
    value: '+971',
    code: 'AE'
  },
  {
    label: 'United Kingdom',
    searchKey: 'United Kingdom',
    value: '+44',
    code: 'GB'
  },
  {
    label: 'United States',
    searchKey: 'United States',
    value: '+1',
    code: 'US'
  },
  { label: 'Uruguay', searchKey: 'Uruguay', value: '+598', code: 'UY' },
  { label: 'Uzbekistan', searchKey: 'Uzbekistan', value: '+998', code: 'UZ' },
  { label: 'Vanuatu', searchKey: 'Vanuatu', value: '+678', code: 'VU' },
  {
    label: 'Venezuela, Bolivarian Republic of',
    searchKey: 'Venezuela, Bolivarian Republic of',
    value: '+58',
    code: 'VE'
  },
  { label: 'Viet Nam', searchKey: 'Viet Nam', value: '+84', code: 'VN' },
  {
    label: 'Virgin Islands, British',
    searchKey: 'Virgin Islands, British',
    value: '+1 284',
    code: 'VG'
  },
  {
    label: 'Virgin Islands, U.S.',
    searchKey: 'Virgin Islands, U.S.',
    value: '+1 340',
    code: 'VI'
  },
  {
    label: 'Wallis and Futuna',
    searchKey: 'Wallis and Futuna',
    value: '+681',
    code: 'WF'
  },
  { label: 'Yemen', searchKey: 'Yemen', value: '+967', code: 'YE' },
  { label: 'Zambia', searchKey: 'Zambia', value: '+260', code: 'ZM' },
  { label: 'Zimbabwe', searchKey: 'Zimbabwe', value: '+263', code: 'ZW' },
  {
    label: 'Åland Islands',
    searchKey: 'Åland Islands',
    value: '+358',
    code: 'AX'
  }
];

export const countries = () => {
  for (const key of Object.keys(countriesJSON)) {
    let { flag } = countriesJSON[key];
    let index = Countries.findIndex(({ code }) => code === key);
    Countries.map((c, i) => {
      if (index === i) {
        Countries[index] = { ...c, flag };
      }
      return c;
    });
  }
  return Countries;
};
