import { createSlice } from 'redux-starter-kit';

let initialState = {
  location: {}
};
export let locationSlice = createSlice({
  initialState: initialState,
  slice: 'location',
  reducers: {
    fetchLocation(state, action) {
      state.location = action.payload.location;
    }
  }
});
export default locationSlice.reducer;
