import React, { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { Field } from 'formik';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import DeleteOutlineIcon from '@material-ui/icons/DeleteOutline';
import InputAdornment from '@material-ui/core/InputAdornment';
import RemoveIcon from '@material-ui/icons/Remove';
import Checkbox from '@material-ui/core/Checkbox';
import textFieldStyles from './TextFieldStyles';
import Divider from '@material-ui/core/Divider';
import { useTranslation } from 'react-i18next';
import AddIcon from '@material-ui/icons/Add';
import Grid from '@material-ui/core/Grid';
import { Box } from '@material-ui/core';
import { getCurrencySymbol } from '../../../store/selectors/currency';
import SwitchCustom from '../../FormFeild/SwitchCustom';
import { CustomTextField } from '../../FormFeild';
import useStyles from '../style';
import {
  formatCurrencyField,
  formatCurrencyToNumberFloat
} from '../../../utils/common';

function Variant(props) {
  const { t } = useTranslation();
  const mainClass = useStyles();
  const textField = textFieldStyles();
  const symbol = useSelector(getCurrencySymbol);

  const handleMandatory = e => {
    props.setVariants(variants => {
      let val = variants[props.index].mandatory;
      variants[props.index].mandatory = !val;
      return [...variants];
    });
  };

  const handleChangeIsMultipleChoices = () => {
    props.setVariants(variants => {
      let val = variants[props.index].isMultipleChoice;
      variants[props.index].isMultipleChoice = !val;
      return [...variants];
    });
  };

  const handleName = e => {
    const val = e.target.value;
    props.setVariants(variants => {
      variants[props.index].name = val;
      return [...variants];
    });
  };

  const handleRemove = () => {
    props.setVariants(variants => {
      variants.splice(props.index, 1);
      return [...variants];
    });
  };

  const handleAddOptions = () => {
    const newOption = {
      key: `${Math.random()}-${new Date().getTime()}`,
      name: '',
      price: 0,
      status: 'active'
    };
    props.setVariants(variants => {
      variants[props.index].options = [
        ...variants[props.index].options,
        newOption
      ];
      return [...variants];
    });
  };

  const handleStatus = (index, currentValue) => {
    let val = currentValue === 'active' ? 'inactive' : 'active';
    props.setVariants(variants => {
      variants[props.index].options[index].status = val;
      return [...variants];
    });
  };

  const getName = useMemo(
    () => (
      <Grid item xs={12} container justify="space-around">
        <Grid xs={12} item>
          <Field
            component={CustomTextField}
            placeholder={t('product.label.Variant name')}
            label={t('product.label.Variant name')}
            fullWidth
            value={props.variant.name}
            onChange={handleName}
            name={`variantsName`}
            margin="dense"
            variant="outlined"
            className={textField.rootTextField}
            InputProps={{
              classes: {
                root: textField.root,
                notchedOutline: textField.notchedOutline,
                input: textField.input,
                focused: textField.notchedOutlineFocus
              }
            }}
          />
        </Grid>
      </Grid>
    ),
    [props.variant.name]
  );

  const handleChangeOptionsName = (e, index) => {
    let value = e.target.value;

    props.setVariants(variants => {
      variants[props.index].options[index].name = value;
      return [...variants];
    });
  };
  const handleChangeOptionsPrice = (e, index) => {
    const re = /([0-9]+([.][0-9]*)?|[.][0-9]+)/;
    let value = formatCurrencyToNumberFloat(e.target.value);

    if ((!re.test(value) || isNaN(+value)) && value !== '') return;
    props.setVariants(variants => {
      variants[props.index].options[index].price = value;
      return [...variants];
    });
  };

  const handleRemoveOption = index => {
    props.setVariants(variants => {
      variants[props.index].options.splice(index, 1);
      return [...variants];
    });
  };

  return (
    <Grid item xs={12} container className={mainClass.variant}>
      <Grid item xs={12} container style={{ padding: '15px 15px 0 15px' }}>
        <Grid
          item
          xs={12}
          container
          style={{
            margin: '10px 0px'
          }}>
          <Box>
            <FormControlLabel
              className={mainClass.formLabel}
              control={
                <Checkbox
                  color="primary"
                  className={mainClass.iconSpacing}
                  checked={props.variant?.mandatory}
                  onChange={handleMandatory}
                />
              }
              label={t('product.label.Mandatory')}
            />
            <FormControlLabel
              className={mainClass.formLabel}
              control={
                <Checkbox
                  color="primary"
                  className={mainClass.iconSpacing}
                  checked={props.variant?.isMultipleChoice}
                  onChange={handleChangeIsMultipleChoices}
                />
              }
              label={t('product.label.Multiple')}
            />
          </Box>

          <Grid
            xs={1}
            item
            container
            style={{
              display: 'flex',
              justifyContent: 'flex-end',
              alignItems: 'self-end',
              marginLeft: 'auto'
            }}>
            <Box
              onClick={handleRemove}
              className={mainClass.deleteVariant}
              component="button"
              type="button">
              <DeleteOutlineIcon
                style={{
                  fontSize: 24,
                  padding: 0,
                  color: '#999',
                  cursor: 'pointer',
                  '& svg': {
                    display: 'block'
                  }
                }}
              />
            </Box>
          </Grid>
        </Grid>

        {getName}
      </Grid>

      <Divider
        style={{
          width: '100%',
          height: '2px',
          margin: '10px 0'
        }}
        variant="fullWidth"
      />
      <Box>
        {props.variant?.options?.map((option, i) => (
          <Grid key={i} item xs={12} container style={{ padding: '0 15px' }}>
            <Grid
              item
              xs={12}
              container
              justify="space-around"
              spacing={1}
              display="flex"
              alignItems="center">
              <Grid item xs={4}>
                <Field
                  margin="dense"
                  variant="outlined"
                  placeholder={t('product.label.Option name')}
                  label={t('product.label.Option name')}
                  fullWidth
                  name={`variants[${props.variantIndex}].options[${props.optionIndex}].name`}
                  value={option.name}
                  onChange={e => handleChangeOptionsName(e, i)}
                  className={textField.rootTextField}
                  InputProps={{
                    classes: {
                      root: textField.root,
                      notchedOutline: textField.notchedOutline,
                      input: textField.input,
                      focused: textField.notchedOutlineFocus
                    }
                  }}
                  component={CustomTextField}
                />
              </Grid>

              <Grid item xs={5}>
                <Field
                  style={{ background: 'white' }}
                  margin="dense"
                  variant="outlined"
                  placeholder={t('product.label.Option price')}
                  label={t('product.label.Option price')}
                  fullWidth
                  name={`variants[${props.variantIndex}].options[${props.optionIndex}].price`}
                  value={formatCurrencyField(option.price)}
                  onChange={e => handleChangeOptionsPrice(e, i)}
                  className={textField.rootTextField}
                  component={CustomTextField}
                  InputProps={{
                    classes: {
                      root: textField.root,
                      notchedOutline: textField.notchedOutline,
                      input: textField.input,
                      focused: textField.notchedOutlineFocus
                    },
                    startAdornment: (
                      <InputAdornment position="start">$</InputAdornment>
                    ),
                    endAdornment: (
                      <InputAdornment
                        style={{
                          background: 'white'
                        }}
                        position="start">
                        <span style={{ fontWeight: 500 }}>{symbol}</span>
                      </InputAdornment>
                    )
                  }}
                />
              </Grid>
              <Grid item xs={2} container display="flex" justify="flex-end">
                <SwitchCustom
                  checkedVal={option.status === 'active' ? true : false}
                  handleChange={e => handleStatus(i, option.status)}
                />
              </Grid>
              <Grid item xs={1} container display="flex" justify="flex-end">
                <Box
                  // disabled={props.variant.options.length === 1}
                  onClick={e => handleRemoveOption(i)}
                  type="button"
                  component="button"
                  className={mainClass.removeIconOption}>
                  <RemoveIcon />
                </Box>
              </Grid>
            </Grid>
          </Grid>
        ))}

        <Box
          display="inline-block"
          className={mainClass.addMoreChoices}
          onClick={handleAddOptions}
          disabled={
            props.variant?.options?.length >= 10
            // !props.variant?.options?.every(op => op?.name)
          }
          component="button"
          type="button">
          <AddIcon />
          <span>{t('action.Add options')}</span>
        </Box>
      </Box>
    </Grid>
  );
}

export default Variant;
