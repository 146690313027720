import React from 'react';
import ReactApexChart from 'react-apexcharts';

const PieChart = props => {
  const { labels, colors, series } = props;
  const options = {
    chart: {
      width: 400,
      type: 'pie'
    },
    dataLabels: {
      style: {
        fontSize: 24,
        fontFamily: 'Helvetica, Arial, sans-serif',
        fontWeight: 'bold'
      }
    },
    plotOptions: {
      pie: {
        dataLabels: {
          offset: -50
        }
      }
    },
    labels,
    colors,
    legend: {
      show: false
    },
    responsive: [
      {
        breakpoint: 480,
        options: {
          chart: {
            width: '95vw'
          },
          legend: {
            position: 'bottom'
          }
        }
      }
    ]
  };

  return (
    <div id="chart">
      <ReactApexChart
        options={options}
        series={series}
        type="pie"
        width={500}
        height={400}
      />
    </div>
  );
};

export default PieChart;
