export const queryReviews = `
  _id
  starRating
  reason
  feedback
  media
  createdAt
  author {
    name
    avatar
  }
`;
