import React from 'react';
import { useTranslation } from 'react-i18next';
import Box from '@material-ui/core/Box';
import TablePagination from '@material-ui/core/TablePagination';
import CloudDownloadRoundedIcon from '@material-ui/icons/CloudDownloadRounded';
import { formatDate } from 'maua-redux-core/utils/format';
import { useStyle } from './style';
import { IconButton } from '@material-ui/core';
import LoadMoreText from '../../../components/LoadMoreText';

const TableList = props => {
  const { data, loadImportProducts, handleDownloadFile } = props;

  const { total, docs, limit, page } = data;
  const { t } = useTranslation();
  const classes = useStyle();

  const handleChangePage = (_, newPage) => {
    loadImportProducts(newPage);
  };

  return (
    <Box className={classes.rightColumn}>
      <Box className={[classes.flex, classes.headerTable].join(' ')}>
        <Box className={classes.rowHeader}>
          {t('import-product.table.col.File name')}
        </Box>
        <Box className={classes.rowHeader}>
          {t('import-product.table.col.No.Record')}
        </Box>
        <Box className={classes.rowHeader}>
          {t('import-product.table.col.Result')}
        </Box>
        <Box className={classes.rowHeader}>
          {t('import-product.table.col.By')}
        </Box>
        <Box className={classes.rowHeader}>
          {t('import-product.table.col.Uploaded at')}
        </Box>
        <Box className={classes.rowHeader}>
          {t('import-product.table.col.Download')}
        </Box>
      </Box>
      <Box className={classes.filesContent}>
        {docs.map(doc => (
          <Box className={classes.flex} key={doc._id}>
            <Box className={classes.row}>
              <LoadMoreText string={doc?.filename || ''} size={25} />
            </Box>
            <Box className={classes.row}>{doc?.numberOfRecords}</Box>
            <Box className={classes.row}>
              <div>
                <span>{`${doc?.successRecordsNumber} record Success`}</span>
                <br />
                <span>{`${doc?.failRecordsNumber} record Fail`}</span>
              </div>
            </Box>
            <Box className={classes.row}>{doc?.author?.name}</Box>
            <Box className={classes.row}>
              {formatDate(doc.uploadAt, 'DD/MM/YYYY HH:mm')}
            </Box>
            <Box className={classes.row}>
              <IconButton
                onClick={handleDownloadFile(doc?.location)}
                color="primary">
                <CloudDownloadRoundedIcon />
              </IconButton>
            </Box>
          </Box>
        ))}
      </Box>

      <Box style={{ float: 'right' }}>
        <TablePagination
          count={total}
          rowsPerPage={limit || 20}
          page={(Number(page) || 1) - 1}
          onChangePage={handleChangePage}
          rowsPerPageOptions={[limit]}
          component="div"
          backIconButtonProps={{ 'aria-label': 'Previous Page' }}
          nextIconButtonProps={{ 'aria-label': 'Next Page' }}
        />
      </Box>
    </Box>
  );
};

export default TableList;
