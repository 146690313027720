import React from 'react';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import { useTranslation } from 'react-i18next';
import PhoneIphoneIcon from '@material-ui/icons/PhoneIphone';
import WhatsAppIcon from '@material-ui/icons/WhatsApp';
import { Typography, Box } from '@material-ui/core';
import { fetchUserProfile } from 'maua-redux-core/actions/user';
import { actToggleModalChangeRegion } from '../../store/actions/modal';
import { getOpenChangeRegion } from '../../store/selectors/modal';
import AndroidImg from '../../assets/img/icons/android.svg';
import { checkLocation } from '../../utils/checkLocation';
import { queryProfile } from '../../store/query/user';
import IosImg from '../../assets/img/icons/ios.svg';
import ChangeRegion from './ChangeRegion';
import useStyle from './style';

function Login(props) {
  const { history } = props;
  const mainClass = useStyle();
  const { t } = useTranslation();

  // const countryCode = process.env.NODE_ENV === 'development' ? '+84' : '+685';

  const handleLogin = type => {
    history.push(`/enter-phone-number?type=${type}`);
  };

  const handleChangeRegion = () => {
    props.toggleModalChangeRegion(true);
  };

  return (
    <div className={mainClass.loginModal}>
      <Typography variant="h4" className={mainClass.title}>
        {t('login.title.Login to continue')}
      </Typography>
      <Typography
        variant="subtitle2"
        component="button"
        onClick={() => handleLogin('phone')}
        className={`${mainClass.loginButton} ${mainClass.loginSms}`}>
        <PhoneIphoneIcon style={{ marginRight: 4 }} />
        {t('action.With Phone')}
      </Typography>

      <Box mt={6}>
        <Box display="flex" justifyContent="center">
          <Box className={mainClass.textDownloadApp}>
            {t('login.label.You are accessing to')}
          </Box>
          <Box className={mainClass.textRegion}>{checkLocation().text}</Box>
        </Box>

        <Typography
          variant="h1"
          className={mainClass.textChangeRegion}
          onClick={handleChangeRegion}>
          {t('login.label.Change country / region')}
        </Typography>
      </Box>

      <Box mt={4.8}>
        <Typography variant="h4" className={mainClass.downloadTitle}>
          {t('login.label.Download app')}
        </Typography>
        <a href="https://apps.apple.com/bm/app/maua-buyer/id1468726583">
          <img src={IosImg} alt="ios" className={mainClass.iosImg} />
        </a>
        <a href="https://play.google.com/store/apps/details?id=com.app.buyer">
          <img
            src={AndroidImg}
            alt="android"
            className={mainClass.andriodImg}
          />
        </a>
      </Box>
      {props.isOpenChangeRegion && <ChangeRegion />}
    </div>
  );
}

const mapDispatchToProps = dispatch => {
  return {
    onFetchUserProfile: () => {
      return dispatch(fetchUserProfile(null, queryProfile));
    },
    toggleModalChangeRegion: params =>
      dispatch(actToggleModalChangeRegion(params))
  };
};

export default compose(
  withRouter,
  connect(
    state => ({
      isOpenChangeRegion: getOpenChangeRegion(state)
    }),
    mapDispatchToProps
  )
)(Login);
