import { makeStyles } from '@material-ui/styles';
import { secondaryFont } from '../../shared/theme';

export const useStyles = makeStyles(theme => ({
  categoriesMenu: {
    color: 'red'
  },
  timezone: {
    margin: theme.spacing(0.8, 0, 0.4)
  },
  yourShopLocation: {
    border: `1px solid #E6E6E6`,
    borderRadius: '4px',
    marginTop: 8,
    marginBottom: 3
  },
  locationTitle: {
    display: 'flex',
    margin: theme.spacing(2, 1.4, 0),
    justifyContent: 'space-between',
    verticalAlign: 'center',
    '& h6': {
      margin: 0,
      fontSize: 16,
      fontWeight: 600,
      fontFamily: secondaryFont,
      color: theme.palette.color.black1
    },
    '& img': {
      transition: `all .35s`,
      cursor: 'pointer',
      '&:hover': {
        transform: 'scale(1.1)'
      }
    }
  },
  locationList: {
    display: 'flex',
    flexDirection: 'column'
  },
  locationItem: {
    display: 'flex',
    borderTop: `1px solid #E6E6E6`,
    padding: theme.spacing(1, 1.4),
    justifyContent: 'space-between',
    alignItems: 'center',
    '&:first-child': {
      borderTop: 0
    }
  },
  locationLabel: {
    fontSize: 16,
    fontFamily: secondaryFont,
    color: theme.palette.color.gray3
  },
  locationValue: {
    fontSize: 16,
    fontFamily: secondaryFont,
    color: theme.palette.color.black1
  },
  mrT30: {
    marginTop: 30
  },
  titlePrimary: {
    fontSize: 24,
    fontWeight: 'bold',
    fontFamily: secondaryFont,
    color: theme.palette.color.black,
    textAlign: 'left'
  },
  subTitle: {
    color: theme.palette.color.black
  },
  root: {
    margin: 0,
    padding: theme.spacing(3, 7)
  },
  rootDialogTitle: {
    margin: 0,
    padding: theme.spacing(1, 2.4)
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500]
  },
  actions: {
    padding: theme.spacing(0, 2.4)
  },
  termCondition: {
    color: theme.palette.color.black2
  },
  wrappMap: {
    marginTop: '3px',
    borderRadius: '4px'
  },
  wrappBack: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    padding: '10px 0px',
    cursor: 'pointer',
    transition: 'all .35s',
    color: theme.palette.color.gray,
    marginBottom: 10,
    '&:hover img': {
      transform: 'scale(1.1)'
    }
  },
  arrowBack: {
    display: 'block',
    width: 16,
    height: 16,
    marginRight: 10
  },
  txtBack: {
    fontSize: 16,
    fontFamily: secondaryFont,
    fontWeight: 700
  }
}));
