import axios from 'axios';
import { checkLocation } from '../../utils/checkLocation';
import { getCredentials } from './token';

// const endpoint = process.env.REACT_APP_API || 'https://api-maua.efisoft.com.vn';

export const endpoint = checkLocation().api;

const callAPI = async (query, variables) => {
  const {
    data: { data }
  } = await axios.post(
    `${endpoint}/graphql`,
    {
      query,
      variables
    },
    {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Basic ${getCredentials().token}`
      }
    }
  );

  return data;
};

export default callAPI;
