import makeStyles from '@material-ui/styles/makeStyles';
import { isEmpty } from 'lodash';
import { primaryFont, textColor } from '../../../shared/theme';

const useStyles = makeStyles(theme => ({
  rootDatePicker: {
    position: 'relative',
    padding: 0,
    width: props => props.width,
    height: props => props.height,
    boxSizing: 'border-box',
    fontFamily: 'Helvetica',

    '& .SingleDatePicker, .SingleDatePickerInput ': {
      width: '100%'
    },

    '& .SingleDatePicker': {
      background: props => props.background || theme.palette.color.white,
      borderRadius: theme.spacing(0.4),
      boxSizing: 'border-box',
      border: `1px solid ${theme.palette.color.gray}`,
      '&:focus-within': {
        border: `1px solid ${theme.palette.primary.main}`
      }
    },

    '& .SingleDatePickerInput ': {
      padding: 0,
      border: 'unset',
      display: 'flex',
      boxSizing: 'border-box',
      borderRadius: theme.spacing(0.4)
    },

    '& .DateInput_input': {
      border: 'unset',
      background: 'unset'
    },
    '& input': {
      fontSize: 16,
      lineHeight: 1,
      padding: theme.spacing(1, 1.5),
      width: '100%',
      height: props => props.height,
      borderRadius: theme.spacing(0.4)
    },

    '& .CalendarDay__selected': {
      color: 'white',
      background: theme.palette.primary.main,
      border: `1px solid ${theme.palette.primary.main}`
    },

    '& .SingleDatePickerInput_clearDate': {
      outline: 'none',
      position: 'absolute',
      top: '50%',
      transform: 'translate(0, -50%)',
      right: theme.spacing(1.5),
      width: theme.spacing(2.5),
      height: theme.spacing(2.5),
      margin: 0,
      padding: 0,
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',

      '& svg': {
        width: theme.spacing(1.5),
        height: theme.spacing(1.5)
      },

      '&:hover': {
        '& svg': {
          fill: theme.palette.color.black2
        }
      }
    },

    '& .DateInput_input__focused': {
      border: 'unset',
      background: 'unset'
    },

    '& .SingleDatePickerInput_calendarIcon': {
      outline: 'none',
      position: 'absolute',
      top: '50%',
      transform: 'translate(0, -50%)',
      right: theme.spacing(1.5),
      width: theme.spacing(2),
      height: theme.spacing(2),
      margin: 0,
      padding: 0
    },

    '& .CalendarDay__default': {
      width: '35px !important',
      height: '35px !important',
      borderRadius: theme.spacing(0.4)
    },

    '& .DayPicker__withBorder ': {
      borderRadius: theme.spacing(0.4)
    },

    '& .DateInput ': {
      border: 'unset',
      background: 'unset',
      width: `calc(100% - 50px) !important`
    },

    '& .SingleDatePicker_picker': {
      top: `calc(100% + ${theme.spacing(1.5)}px) !important`
    },

    '& .DateInput_fang': {
      top: `calc(100% + ${theme.spacing(0.5)}px) !important`
    },

    '& .CalendarMonth_caption': {
      padding: theme.spacing(0, 0, 3.4),
      fontSize: 16,
      fontWeight: '600',
      fontFamily: 'Helvetica',
      color: textColor.dark,
      '& strong': {
        fontWeight: 500
      }
    },

    '& .DayPickerNavigation_button': {
      margin: theme.spacing(-0.2, 0, 0, 0)
    },

    '& .DayPicker_weekHeader': {
      top: theme.spacing(5)
    },

    '& .CalendarMonth_table ': {
      borderCollapse: 'unset',
      borderSpacing: `${theme.spacing(0.4)}px`
    },

    '& .MuiSvgIcon-root': {
      color: theme.palette.color.gray4,
      width: theme.spacing(2),
      height: theme.spacing(2)
    },
    '& .DayPicker_focusRegion': {
      padding: theme.spacing(2, 0)
    },

    '& .DayPickerNavigation__horizontal': {
      display: 'flex',
      padding: theme.spacing(0, 3),
      justifyContent: 'space-between'
    }
  }
}));

export default useStyles;
