import React, { useState, useEffect, useRef } from 'react';
import moment from 'moment';
import { cloneDeep } from 'lodash';
import { useTranslation } from 'react-i18next';

import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import MenuItem from '@material-ui/core/MenuItem';
import TextField from '@material-ui/core/TextField';
import { KeyboardDatePicker } from '@material-ui/pickers';
import BtnClearFilter from '../../../components/FieldWithoutFormik/ClearFilter';
import DateRangePickerUI from '../../../components/FieldWithoutFormik/DateRangePicker';

import { DATE_FORMAT } from '../../../utils/common';
import useStyles from './style';
import inputStyle from './inputStyle';

const Filter = props => {
  const mainClass = useStyles();
  const timeout = useRef(null);
  const textFieldStyles = inputStyle();
  const { t } = useTranslation();
  const [formFilter, setFormFilter] = useState(cloneDeep(props.formFilter));

  useEffect(() => {
    return () => {
      clearTimeout(timeout);
    };
  });

  const handleChange = e => {
    let field = e.target.name;
    let value = e.target.value;
    setFormFilter(prevData => ({
      ...prevData,
      [field]: value
    }));
    props.setToTop(true);

    if (timeout.current) clearTimeout(timeout.current);

    timeout.current = setTimeout(() => {
      props.setFormFilter(prevData => ({
        ...prevData,
        [field]: value
      }));
    }, 600);
  };

  const handleResetFilter = () => {
    setFormFilter({
      code: '',
      customerName: '',
      phone: '',
      deliveryMethod: '',
      paymentMethod: '',
      from: null,
      to: null
    });
    props.setToTop(true);
    if (timeout.current) clearTimeout(timeout.current);

    timeout.current = setTimeout(() => {
      props.setFormFilter({
        code: '',
        customerName: '',
        phone: '',
        deliveryMethod: '',
        paymentMethod: '',
        from: null,
        to: null
      });
    }, 600);
  };

  const handleSetDate = ({ startDate, endDate }) => {
    setFormFilter(prevData => {
      return {
        ...prevData,
        from: (startDate && startDate.startOf('day')) || null,
        to: (endDate && endDate.endOf('day')) || null
      };
    });
    props.setToTop(true);

    if (timeout.current) clearTimeout(timeout.current);

    timeout.current = setTimeout(() => {
      props.setFormFilter(prevData => ({
        ...prevData,
        from: (startDate && startDate.startOf('day')?.toISOString()) || null,
        to: (endDate && endDate.endOf('day')?.toISOString()) || null
      }));
    }, 1000);
  };

  return (
    <Box className={mainClass.root}>
      <Grid xs={12} item container className={mainClass.paper}>
        <Grid container xs={12} item className={mainClass.boxFilter}>
          <Grid item className={mainClass.filterItem}>
            <TextField
              variant="outlined"
              id="margin-none"
              onChange={handleChange}
              name="code"
              value={formFilter.code}
              label={t('order.filter.label.Order code')}
              className={textFieldStyles.rootTextField}
            />
          </Grid>

          <Grid item className={mainClass.filterItem}>
            <TextField
              fullWidth
              onChange={handleChange}
              name="phone"
              value={formFilter.phone}
              id="outlined-basic"
              label={t('order.filter.label.Phone')}
              variant="outlined"
              className={textFieldStyles.rootTextField}
            />
          </Grid>

          <Grid item className={mainClass.filterItem}>
            <TextField
              fullWidth
              label={t('order.filter.label.Delivery Method')}
              id="standard-select-currency"
              select
              name="deliveryMethod"
              value={formFilter.deliveryMethod}
              onChange={handleChange}
              variant="outlined"
              className={textFieldStyles.rootTextField}>
              {[
                {
                  title: 'All',
                  value: 'All'
                },
                {
                  title: 'Self pickup',
                  value: 'self-pickup'
                },
                {
                  title: 'Delivery to me',
                  value: 'service'
                }
              ].map((option, idx) => (
                <MenuItem key={idx} value={option.value}>
                  {option.title}
                </MenuItem>
              ))}
            </TextField>
          </Grid>

          <Grid item className={mainClass.filterItem}>
            <TextField
              fullWidth
              label={t('order.filter.label.Payment Method')}
              id="standard-select-currency"
              select
              name="paymentMethod"
              value={formFilter.paymentMethod}
              onChange={handleChange}
              variant="outlined"
              className={textFieldStyles.rootTextField}>
              {[
                {
                  title: 'All',
                  value: 'All'
                },
                {
                  title: 'Digicel',
                  value: 'digicel'
                },
                {
                  title: 'Vodafone',
                  value: 'vodafone'
                },
                {
                  title: 'Cash',
                  value: 'cash'
                },
                {
                  title: 'E-voucher',
                  value: 'e-voucher'
                }
              ].map((option, idx) => (
                <MenuItem key={idx} value={option.value}>
                  {option.title}
                </MenuItem>
              ))}
            </TextField>
          </Grid>
          <Grid
            item
            className={[mainClass.filterItem, mainClass.datePicker].join(' ')}>
            <DateRangePickerUI
              width={275}
              height={46}
              startlabel={t('order.filter.label.From')}
              endlabel={t('order.filter.label.To')}
              maxDate={new Date()}
              startDate={formFilter.from}
              endDate={formFilter.to}
              onChange={handleSetDate}
            />
          </Grid>
          <BtnClearFilter handleClearFilter={handleResetFilter} />
        </Grid>
      </Grid>
    </Box>
  );
};

export default Filter;
