import { makeStyles } from '@material-ui/styles';

export const useStyles = makeStyles(theme => ({
  container: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    width: '100%',
    minHeight: '100vh',
    background: theme.palette.color.white
  },
  inner: {
    width: 544,
    // height: 360,
    borderRadius: 4,
    boxShadow: `0px 4px 8px rgba(0, 0, 0, 0.08)`
  },
  modalWrapp: {},
  rootDialogTitle: {
    position: 'relative',
    margin: 0,
    padding: '15px 60px 45px'
  },
  closeButton: {
    position: 'absolute',
    right: '10px',
    top: '10px',
    color: theme.palette.color.gray1
  },
  title: {
    marginBottom: 0,
    fontSize: '24px',
    fontFamily: 'Muli',
    fontWeight: 700,
    color: theme.palette.color.black,
    paddingLeft: 36,
    paddingRight: 36
  },
  rootDialogContent: {
    margin: 0,
    padding: '0 96px 45px',
    [theme.breakpoints.down('md')]: {
      padding: '0 30px 45px'
    }
  },
  description: {
    color: theme.palette.color.black2
  },
  addBusinessItem: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    paddingTop: 1
  },
  thumbBusiness: {
    width: 80,
    height: 80,
    borderRadius: '50%'
  },
  imgThumb: {
    display: 'block',
    width: 80,
    height: 80,
    maxWidth: '100%',
    borderRadius: '50%',
    transition: 'all .35s',
    cursor: 'pointer',
    border: `1px solid transparent`,
    '&:hover': {
      border: `1px solid ${theme.palette.color.orange}`,
      transform: 'scale(1.03)'
    }
  },
  addBussiness: {
    border: `1px solid #CCC`,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    transition: 'all .35s',
    cursor: 'pointer',
    '&:hover': {
      border: `1px solid ${theme.palette.color.orange}`,
      transform: 'scale(1.03)'
    }
  },
  iconAdd: {
    color: theme.palette.color.orange
  },
  descBusiness: {
    color: theme.palette.color.black1,
    marginTop: 14,
    lineHeight: 1.2,
    wordBreak: `break-word`,
    textAlign: 'center'
  },
  wrappBtn: {
    marginTop: 70
  }
}));
