import { makeStyles } from '@material-ui/core';
import { secondaryFont, primaryFont } from '../../shared/theme';

const useStyle = makeStyles(theme => ({
  wrapp: {
    marginTop: 45
  },
  head: {
    display: 'flex',
    paddingBottom: 22,
    flexDirection: 'row',
    alignItems: 'center',
    borderBottom: `1px solid #F2F2F2`,
    marginBottom: 22
  },
  avatar: {
    display: 'block',
    width: 58,
    height: 58,
    borderRadius: '50%'
  },
  detailHead: {
    paddingLeft: 18,
    width: 'calc(100% - 58px)'
  },
  itemHcContent: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    marginBottom: 6,
    [theme.breakpoints.only('xs')]: {
      flexDirection: 'column',
      alignItems: 'flex-start'
    }
  },
  itemHcHeader: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    marginBottom: 6
  },
  title: {
    color: theme.palette.color.black1,
    fontWeight: 'bold'
  },
  desc: {
    wordBreak: 'break-all',
    color: theme.palette.color.black2,
    lineHeight: '26px'
  },
  date: {
    color: theme.palette.color.gray1
  },
  listCategory: {
    marginTop: 15
  },
  categoryItem: {
    display: 'inline-block',
    border: `1px solid #E6E6E6`,
    borderRadius: '20px',
    padding: '10px 22px',
    fontSize: 14,
    fontFamily: primaryFont,
    color: theme.palette.color.black2,
    marginBottom: 16,
    marginRight: 10,
    '&:last-child': {
      marginRight: 0
    }
  },
  media: {
    maxWidth: '100%',
    display: 'block',
    borderRadius: 5
  }
}));
export default useStyle;
