import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { Box, Typography } from '@material-ui/core';
import { getTopSellingProducts } from 'maua-redux-core/actions/product';
import { toastMessage, TOAST_TYPE } from '../../utils/toastify-notify';
import SelectCustom from '../../components/FormFeild/SelectCustom';
import ImageDefault from '../../assets/img/default.jpg';
import { queryProduct } from '../../store/query/menu';
import { optionsTime } from '../../utils/constant';
import EmptyIcon from '../../assets/img/empty.svg';
import SliderUI from '../../components/SliderUI';
import { useStyle } from './style';

const TopSellers = props => {
  const classes = useStyle();
  const { t } = useTranslation();
  const [products, setProducts] = useState([]);
  const [filter, setFilter] = useState(optionsTime[2]);
  const settings = {
    slidesToShow: 5,
    slidesToScroll: 5,
    responsive: [
      {
        breakpoint: 1140,
        settings: {
          slidesToShow: 4,
          slidesToScroll: 4
        }
      },
      {
        breakpoint: 960,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3
        }
      },
      {
        breakpoint: 576,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2
        }
      }
    ]
  };

  const loadProducts = async () => {
    const payload = {
      startTime: filter.startTime,
      endTime: filter.endTime
    };

    try {
      const { getTopSellingProducts } = await props.getTopSellingProducts(
        payload
      );

      setProducts(() => getTopSellingProducts);
    } catch (error) {
      toastMessage(TOAST_TYPE.ERROR, error.message);
    }
  };

  useEffect(() => {
    if (props.token) loadProducts();
  }, [filter, props.token]);

  const handleFilter = e => {
    const val = e.target.value;
    setFilter(() => optionsTime.filter(({ value }) => value === val)[0]);
  };

  const ProductItem = () => {
    return (
      <SliderUI settings={settings}>
        {products.map((p, i) => (
          <Box key={i} className={classes.productItem}>
            <img
              src={(p.media.length && p.media[0]) || ImageDefault}
              alt="product"
              className={classes.image}
            />
            <Box className={classes.boxTextProduct}>
              <Box className={classes.nameOfProduct}>{p.name}</Box>
              <Box className={classes.countOfProduct}>
                {`${t('dashboard.topSeller.label.Quantity')}: `}
                {p.qtySold || 0}
              </Box>
            </Box>
          </Box>
        ))}
      </SliderUI>
    );
  };

  return (
    <Box className={classes.background}>
      <Box className={classes.header}>
        <Typography variant="h4">
          {t('dashboard.title.Top selling items')}
        </Typography>
        <SelectCustom
          options={optionsTime}
          value={filter?.value}
          onChange={handleFilter}
        />
      </Box>
      <Box className={classes.boxProducts}>
        {products?.length ? (
          <ProductItem />
        ) : (
          <img src={EmptyIcon} alt="empty" />
        )}
      </Box>
    </Box>
  );
};

export default connect(
  state => ({
    token: state.user.user.credentials.token
  }),
  dispatch => ({
    getTopSellingProducts: payload =>
      dispatch(getTopSellingProducts(payload, `${queryProduct} qtySold`))
  })
)(TopSellers);
