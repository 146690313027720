import { locationSlice } from '../reducers/location';

export const actFetchLocation = () => {
  return dispatch => {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(pos => {
        dispatch(
          locationSlice.actions.fetchLocation({
            location: {
              lat: pos.coords.latitude,
              lng: pos.coords.longitude
            }
          })
        );
      });
    }
  };
};
