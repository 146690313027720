import React from 'react';
import clsx from 'clsx';
import { useTranslation } from 'react-i18next';

import Rating from '@material-ui/lab/Rating';
import Grid from '@material-ui/core/Grid';
import defaultImg from '../../assets/img/default.jpg';
import Typography from '@material-ui/core/Typography';

import avatarDefault from '../../assets/img/upload/avatar.png';
// import productRatingImg from '../../assets/img/upload/picture-rating.png';
import { dateTimehhmmDDMMYY } from '../../utils/common';
import { titleRatingFollowStar } from '../../shared/constant/const';

import useStyle from './style';

const RatingItem = props => {
  const { t } = useTranslation();
  const mainClass = useStyle();
  const { author, createdAt, feedback, media, reason, starRating } = props.item;
  const { avatar, name } = author || {};

  return (
    <div className={mainClass.wrapp}>
      <div className={mainClass.head}>
        <img
          src={avatar || avatarDefault}
          alt={name || 'avatar'}
          className={mainClass.avatar}
        />
        <div className={mainClass.detailHead}>
          <div className={mainClass.itemHcHeader}>
            <Typography
              variant="subtitle1"
              className={clsx(mainClass.title, mainClass.titleHead)}>
              {name}
            </Typography>
            <Typography variant="subtitle1" className={mainClass.desc}>
              {starRating}/5
            </Typography>
          </div>
          <div className={mainClass.itemHcContent}>
            <Typography variant="subtitle1" className={mainClass.date}>
              {(createdAt && dateTimehhmmDDMMYY(createdAt)) || ''}
            </Typography>
            <Rating
              className={mainClass.ratingControl}
              name="rating"
              value={starRating || 0}
              precision={0.5}
              readOnly
            />
          </div>
        </div>
      </div>
      <div className={mainClass.detail}>
        <Typography variant="subtitle1" className={mainClass.title}>
          {titleRatingFollowStar(starRating)}
        </Typography>
        <Typography variant="subtitle1" className={mainClass.desc}>
          {feedback}
        </Typography>
        {!!reason?.length && (
          <div className={mainClass.listCategory}>
            {reason.map((item, index) => (
              <span key={index} className={mainClass.categoryItem}>
                {item}
              </span>
            ))}
          </div>
        )}
        {!!media?.length && (
          <Grid container spacing={1}>
            {media.map((imgSrc, idx) => (
              <Grid key={idx} item xs={4}>
                <img
                  src={imgSrc || defaultImg}
                  alt="resultrating"
                  className={mainClass.media}
                />
              </Grid>
            ))}
          </Grid>
        )}
      </div>
    </div>
  );
};

export default RatingItem;
