import React, { useState } from 'react';
import { connect } from 'react-redux';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';
import CloseIcon from '@material-ui/icons/Close';
import Popover from '@material-ui/core/Popover';
import Button from '@material-ui/core/Button';
import Box from '@material-ui/core/Box';
import { logout } from 'maua-redux-core/actions/user';
import { useStyles } from './styles';

function DialogTitle(props) {
  const { children, onClose } = props;
  const [anchorEl, setAnchorEl] = useState(null);
  const classes = useStyles();

  function handleOpenClosePopover(e) {
    setAnchorEl(e.currentTarget);
  }

  function handleClosePopover() {
    setAnchorEl(null);
  }

  function logout() {
    handleClosePopover();
    props.onClose();
  }

  return (
    <MuiDialogTitle disableTypography className={classes.root}>
      {onClose ? (
        <IconButton
          aria-label="close"
          className={classes.closeButton}
          onClick={handleOpenClosePopover}>
          <CloseIcon />
        </IconButton>
      ) : null}
      <Popover
        open={Boolean(anchorEl)}
        onClose={handleClosePopover}
        anchorEl={anchorEl}
        elevation={3}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'right'
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right'
        }}>
        <Box p={2}>
          <Typography variant="subtitle2" color="error">
            You will be required to login to use our service!
          </Typography>
          <Box mt={1} display="flex" justifyContent="flex-end">
            <Box mr={1}>
              <Button variant="text" mr={1} onClick={logout}>
                Log out
              </Button>
            </Box>
            <Button variant="text" color="primary" onClick={handleClosePopover}>
              Cancel
            </Button>
          </Box>
        </Box>
      </Popover>
      <Typography variant="h5" color="textSecondary" align="center">
        {children}
      </Typography>
    </MuiDialogTitle>
  );
}

export default connect(
  null,
  { logout }
)(DialogTitle);
