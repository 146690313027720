import React, { useState } from 'react';
import SwipeableViews from 'react-swipeable-views';
import { useTranslation } from 'react-i18next';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import useStyles from './styles';

const TabUI = ({ tabsList, mainClasses, children }) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const tabClasses = { ...mainClasses, ...classes };
  const [value, setValue] = useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const handleChangeIndex = index => {
    setValue(index);
  };

  function applyProps(index) {
    return {
      id: `full-width-tab-${index}`,
      'aria-controls': `full-width-tabpanel-${index}`
    };
  }

  return (
    <>
      <Tabs
        value={value}
        onChange={handleChange}
        indicatorColor="primary"
        textColor="primary"
        variant="fullWidth"
        className={tabClasses.tabs}>
        {tabsList.map((tab, index) => (
          <Tab
            className={tabClasses.tab}
            key={index}
            label={t(`tab.title.${tab}`)}
            {...applyProps(index)}
          />
        ))}
      </Tabs>
      <SwipeableViews
        index={value}
        containerStyle={{
          height: '100%'
        }}
        onChangeIndex={handleChangeIndex}
        className={tabClasses.swipeableViews}>
        {children}
      </SwipeableViews>
    </>
  );
};

export default TabUI;
