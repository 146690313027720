import { makeStyles } from '@material-ui/core';
import bgWalletBlue from '../../../assets/img/wallet-vodafone.png';
import bgWalletRed from '../../../assets/img/wallet-red.png';
import { primaryFont } from '../../../shared/theme';

export const useStyleWallet = makeStyles(theme => ({
  walletItem: {
    position: 'relative',
    marginTop: 20,
    padding: '25px 18px 18px',
    borderRadius: '10px',
    backgroundRepeat: 'no-repeat',
    color: theme.palette.color.white
  },
  walletVondafone: {
    position: 'relative',
    marginTop: 20,
    padding: '25px 18px 18px',
    borderRadius: '10px',
    backgroundRepeat: 'no-repeat',
    color: theme.palette.color.red1
  },
  walletRed: {
    backgroundImage: `url(${bgWalletRed})`
  },
  walletBlue: {
    backgroundImage: `url(${bgWalletBlue})`
  },
  walletItemhr: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between'
  },
  walletItemHead: {
    color: 'inherit'
  },
  walletItemTitle: {
    color: 'inherit'
  },
  walletItemTitleVondafone: {
    color: theme.palette.color.red1,
    fontFamily: 'VodafoneExB-Regular',
    fontWeight: 'bold'
  },
  moreOpt: {
    position: 'absolute',
    top: 10,
    right: 10,
    color: 'inherit',
    cursor: 'pointer',
    transition: 'all .35s',
    '&:hover': {
      transform: 'scale(1.2)'
    }
  },
  walletItemContent: {
    marginTop: '10px',
    color: 'inherit',
    fontFamily: primaryFont,
    fontSize: 14,
    paddingBottom: 4,
    borderBottom: `1px dashed #fff`
  },
  walletItemContentVondafone: {
    marginTop: '10px',
    color: 'inherit',
    fontFamily: 'VodafoneRg-Regular',
    fontSize: 14,
    paddingBottom: 4,
    borderBottom: `1px dashed #fff`
  },
  moreOptControl: {
    position: 'absolute',
    top: '35px',
    right: '15px',
    width: '106px',
    backgroundColor: theme.palette.color.white,
    boxShadow: `5px 5px 20px rgba(0, 0, 0, 0.12)`,
    borderRadius: 10,
    padding: '0 0 4px',
    color: theme.palette.color.black2,
    fontFamily: primaryFont,
    '& p': {
      margin: 0,
      padding: '9px 19px',
      fontSize: 16,
      lineHeight: 1,
      transition: 'all .35s',
      cursor: 'pointer',
      '&:hover': {
        color: theme.palette.color.orange
      }
    }
  }
}));

export const useStyleModal = makeStyles(theme => ({
  modalContent: {
    padding: `25px 0 0`
  }
}));
