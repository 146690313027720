import { makeStyles } from '@material-ui/core';

const useStyle = makeStyles(theme => ({
  rootSyncLocaltion: {
    '& .MuiDialog-paper': {
      padding: 24,
      borderRadius: 10
    },

    '& .MuiDialogTitle-root': {
      padding: theme.spacing(0)
    },

    '& .MuiDialogActions-root': {
      padding: theme.spacing(0)
    },

    '& .MuiDialogContent-root': {
      padding: theme.spacing(1.6, 0)
    }
  },

  typeDefault: {
    background: theme.palette.color.white,
    color: theme.palette.color.black2,
    border: `1px solid ${theme.palette.primary.main}`,
    boxShadow: 'unset'
  },

  btn: {
    height: 40,
    width: 'fit-content',
    marginLeft: 10,
    padding: theme.spacing(0, 2)
  }
}));

export default useStyle;
