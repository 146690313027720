import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Field, Form, Formik } from 'formik';
import { connect } from 'react-redux';
import { Typography } from '@material-ui/core';
import Grid from '@material-ui/core/Grid';
import {
  editUserProfile,
  fetchUserProfile
} from 'maua-redux-core/actions/user';
import {
  CustomTextField,
  DatePickerFieldStandard,
  CheckboxCustom
} from '../../FormFeild';
import { actToggleNeedreloadMyStore } from '../../../store/actions/mystores';
import { toastMessage, TOAST_TYPE } from '../../../utils/toastify-notify';
import { GENDER_MAP, ROLE_MAP } from '../../../shared/constant/const';
import { actSetLoading } from '../../../store/actions/loading';
import { UploadComponentHasImg } from '../../UploadComponent';
import { uploadImg } from '../../../shared/services/upload';
import { editUserInfoSchema } from '../../../utils/schema';
import SelectFilter from '../../FieldWithoutFormik/Select';
import { editStaff } from 'maua-redux-core/actions/store';
import { queryProfile } from '../../../store/query/user';
import SelectCustom from '../../FormFeild/SelectCustom';
import { DATE_FORMAT } from '../../../utils/common';
import ModalCustom from '../../ModalCustom';
import { useStyle } from './style';
import Button from '../../Button';

const EditUserInfoModal = props => {
  const mainClass = useStyle();
  const { t } = useTranslation();
  const [hasDisableField, setHasDisableField] = useState(false);
  const [hasChangeGender, setHasChangeGender] = useState(false);
  const [hasImgUpdate, setHasImgUpdate] = useState(false);
  const [genderSelect, setGender] = useState('');
  const [filesUrl, setFilesUrl] = useState('');
  const [files, setFiles] = useState(null);

  const { data } = props;
  const { idStore } = props;
  const { userLogin } = props;

  const {
    _id,
    phone,
    gender,
    avatar,
    birthday,
    lastName,
    firstName
    //roleAction
  } = data || {};

  useEffect(() => {
    if (gender) {
      onChangeCheckbox(gender, true);
    }
  }, []);

  useEffect(() => {
    if (avatar) {
      setFilesUrl(avatar);
    }
    if (_id !== userLogin._id) {
      setHasDisableField(true);
    }
  }, []);

  const handleOnSubmit = async (e, values) => {
    e.preventDefault();
    try {
      props.setLoading({ status: true });
      const { firstName, lastName, birthday, role, gender } = values;

      // Call api for edit user role permission
      // if (roleAction !== role.value) {
      //   const payload = {
      //     user: _id,
      //     store: idStore,
      //     roleAction: role.value
      //   };

      //   await props.editStaff(payload);
      //   props.setLoading({ status: false });
      //   props.toggleNeedReloadMyStore(true);
      //   props.reFetchUserProfile();
      //   props.handleClose();
      //   toastMessage(
      //     TOAST_TYPE.SUCCESS,
      //     `${t('toastMessage.success.content.Edit User Role Success')}!`
      //   );
      // }

      // Call api for edit user profile
      if (!hasDisableField) {
        let params = {
          firstName,
          lastName,
          birthday,
          gender: gender.value
        };

        await props.editUserProfileRequest(params).then(({ editProfile }) => {
          if (!files) {
            props.setLoading({ status: false });
            props.toggleNeedReloadMyStore(true);
            props.reFetchUserProfile();
            props.handleClose();
            toastMessage(
              TOAST_TYPE.SUCCESS,
              `${t('toastMessage.success.content.Edit User Info Success')}`
            );
          } else {
            uploadImage(editProfile._id, files);
          }
        });
      }
    } catch (err) {
      props.setLoading({ status: false });
      toastMessage(TOAST_TYPE.ERROR, err.message);
    }
  };

  const handleCheckValidation = validateForm => {
    validateForm().then(err => {
      if (Object.keys(err).length > 0) {
        toastMessage(TOAST_TYPE.ERROR, err[Object.keys(err)[0]]);
      }
    });
  };

  const onChangeCheckbox = (name, isInit) => {
    setGender(name);
    if (!isInit && genderSelect !== name) {
      setHasChangeGender(true);
    }
  };

  const initialForm = {
    firstName: firstName || '',
    lastName: lastName || '',
    birthday: birthday || '',
    phone: phone || '',
    gender: GENDER_MAP.find(({ value }) => value === data.gender)
    //role: ROLE_MAP.find(({ value }) => value === data.roleAction)
  };

  const handleUploadImg = el => {
    let inputEl = el.target;
    const fileData = inputEl.files[0];
    const reader = new FileReader();
    if (fileData) {
      setFiles(fileData);
      reader.readAsDataURL(fileData);
    }

    reader.onloadend = () => {
      setFilesUrl(reader.result);
    };
    el.target.value = '';
    setHasImgUpdate(true);
  };

  const uploadProgress = progress => {
    let percent = Math.floor((progress.loaded / progress.total) * 100);
    percent !== props.loading.percent &&
      props.setLoading({ status: true, percent });
  };

  const uploadImage = (idProduct, filesData) => {
    const imgData = {
      _id: idProduct,
      model: 'User',
      field: 'avatar',
      file: filesData
    };
    uploadImg(imgData, uploadProgress)
      .then(() => {
        // props.actSetToggleReloadProductsRequest();
        props.reFetchUserProfile();
        props.handleClose();
        props.setLoading({ status: false });
        toastMessage(
          TOAST_TYPE.SUCCESS,
          `${t('toastMessage.success.content.Edit User Info Success')}`
        );
      })
      .catch(err => {
        props.setLoading({ status: false });
        toastMessage(TOAST_TYPE.ERROR, err.message);
      })
      .finally(() => {
        props.setLoading({ status: false, percent: 0 });
      });
  };

  return (
    <div className={mainClass.root}>
      <ModalCustom {...props}>
        <Typography variant="h5" className={mainClass.title}>
          {t('mystore.staff.editOwner.title.Edit your Information')}
        </Typography>
        <div className={mainClass.wrappUploadImgUser}>
          <UploadComponentHasImg
            name="uploadUserInfo"
            handleUpload={handleUploadImg}
            imgUrl={filesUrl}
          />
        </div>
        <Formik
          initialValues={initialForm}
          enableReinitialize={true}
          onSubmit={() => {}}
          validationSchema={editUserInfoSchema}
          render={({
            values,
            handleChange,
            errors,
            touched,
            handleBlur,
            validateForm,
            handleReset,
            setFieldValue,
            isValid
          }) => {
            return (
              <Form onSubmit={e => e.preventDefault()}>
                <div className={mainClass.content}>
                  <div className={mainClass.innerContent}>
                    <Grid>
                      <Grid container item spacing={3}>
                        <Grid item xs={6}>
                          <Field
                            name="firstName"
                            margin="dense"
                            label={t(
                              'mystore.staff.editOwner.label.FIRST NAME'
                            )}
                            className={mainClass.field}
                            fullWidth
                            component={CustomTextField}
                            disabled={hasDisableField}
                            onChange={handleChange}
                          />
                        </Grid>
                        <Grid item xs={6}>
                          <Field
                            name="lastName"
                            margin="dense"
                            label={t('mystore.staff.editOwner.label.LAST NAME')}
                            className={mainClass.field}
                            fullWidth
                            component={CustomTextField}
                            disabled={hasDisableField}
                            onChange={handleChange}
                          />
                        </Grid>
                      </Grid>
                      <Grid item>
                        <Field
                          name="birthday"
                          margin="dense"
                          label={t(
                            'mystore.staff.editOwner.label.DAY OF BIRTH'
                          )}
                          format={DATE_FORMAT}
                          className={mainClass.field}
                          fullWidth
                          required
                          component={DatePickerFieldStandard}
                          disabled={hasDisableField}
                          onChange={date =>
                            setFieldValue(
                              'birthday',
                              date ? date.toISOString() : date
                            )
                          }
                        />
                      </Grid>
                      <Grid item>
                        <Field
                          name="phone"
                          margin="dense"
                          label={t(
                            'mystore.staff.editOwner.label.PHONE NUMBER'
                          )}
                          className={mainClass.field}
                          fullWidth
                          disabled
                          component={CustomTextField}
                          onChange={handleChange}
                        />
                      </Grid>
                      <Grid style={{ marginTop: 12 }} item>
                        <SelectFilter
                          label={t('mystore.staff.editOwner.label.GENDER')}
                          zIndex={2}
                          name="gender"
                          value={values.gender}
                          defaultInputValue=""
                          options={GENDER_MAP}
                          disabled={hasDisableField}
                          onChange={e => setFieldValue('gender', e)}
                        />
                        {/* <Grid item>
                          <CheckboxCustom
                            onChangeCheckbox={onChangeCheckbox}
                            name="male"
                            disabled={hasDisableField}
                            isChecked={genderSelect === 'male'}
                            labelText="Male"
                          />
                        </Grid>
                        <Grid item>
                          <CheckboxCustom
                            onChangeCheckbox={onChangeCheckbox}
                            name="female"
                            disabled={hasDisableField}
                            isChecked={genderSelect === 'female'}
                            labelText="Female"
                          />
                        </Grid> */}
                      </Grid>
                      {/* <Grid style={{ marginTop: 16 }} item>
                        <SelectFilter
                          label={t('mystore.staff.editOwner.label.ROLE')}
                          zIndex={1}
                          name="role"
                          value={values.role}
                          defaultInputValue=""
                          options={ROLE_MAP}
                          onChange={e => setFieldValue('role', e)}
                        />
                      </Grid> */}
                    </Grid>
                    <div className={mainClass.wrappBtn}>
                      <Button
                        typeBtn="yellow"
                        type="button"
                        handleClick={() => handleCheckValidation(validateForm)}
                        onClick={e => handleOnSubmit(e, values)}
                        disabled={
                          !isValid && !hasChangeGender && !hasImgUpdate
                        }>
                        {t('action.OK')}
                      </Button>
                    </div>
                  </div>
                </div>
              </Form>
            );
          }}
        />
      </ModalCustom>
    </div>
  );
};

export default connect(
  state => ({
    loading: state.loading
  }),
  dispatch => ({
    toggleNeedReloadMyStore: value =>
      dispatch(actToggleNeedreloadMyStore(value)),
    editUserProfileRequest: params =>
      dispatch(editUserProfile(params, queryProfile)),
    setLoading: status => dispatch(actSetLoading(status)),
    editStaff: params => dispatch(editStaff(params, null)),
    reFetchUserProfile: () => dispatch(fetchUserProfile(null, queryProfile))
  })
)(EditUserInfoModal);
