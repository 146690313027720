import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import Box from '@material-ui/core/Box';
// import Slide from '@material-ui/core/Slide';
import Loading from '../../Loading';
import { actFetchLocation } from '../../../store/actions/location';
import { getLocation } from '../../../store/selectors/location';
import Header from '../header';
import useStyle from './style';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { getUserProfile } from 'maua-redux-core/selectors/user';
import SyncTimeZone from '../SyncTimeZone';
import MobileNavigator from 'src/components/MobileNavigator';
import { useIsMobile } from 'src/utils/hook';

const Layout = ({
  children,
  onFetchLocation,
  userLocation,
  loading,
  profile,
  ...otherProps
}) => {
  const classes = useStyle();

  const { location } = otherProps;
  const pathName = location.pathname;
  const isMobile = useIsMobile();
  const isHasBtnApp =
    location &&
    (pathName.includes('myStore') ||
      pathName.includes('orders') ||
      pathName.includes('products') ||
      pathName.includes('dashboard') ||
      pathName.includes('profile'));

  let classMainContent = !isHasBtnApp
    ? classes.mainContent
    : classes.mainContentFullPage;
  const authenticated = !!profile && profile._id;
  return (
    <React.Fragment>
      <div className={classes.root}>
        <Header {...otherProps} />
        <Box component="main" position="relative" className={classMainContent}>
          {/* main content */}
          {children}
          <Loading />

          <SyncTimeZone />
        </Box>
        {isMobile && authenticated && <MobileNavigator />}
      </div>
      <ToastContainer />
    </React.Fragment>
  );
};

const mapStateToProps = state => {
  return {
    userLocation: getLocation(state),
    profile: getUserProfile(state)
  };
};

const mapDispatchToProps = dispatch => {
  return {
    onFetchLocation: () => {
      dispatch(actFetchLocation());
    }
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(Layout));
