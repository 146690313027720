import { createSlice } from 'redux-starter-kit';

const initialState = {
  location: {
    lat: '',
    lng: ''
  }
};

export const coordinateSlice = createSlice({
  initialState: initialState,
  slice: 'coordinate',
  reducers: {
    setCoordinate: (state, action) => {
      state.location = action.payload.location;
    }
  }
});

export default coordinateSlice.reducer;
