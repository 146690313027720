import React from 'react';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { withRouter } from 'react-router';
import { logout } from 'maua-redux-core/actions/user';

import useStyle from './style';
import ThankyouModal from '../../components/ThankyouModal';
// import { destroyCredentials } from '../../shared/services/token';
import { actDestroyProfile } from '../../store/actions/user';

const ThankyouPage = props => {
  const classess = useStyle();
  const handleClose = () => {
    // props.logout();
    // destroyCredentials();
    // props.onDestroyProfile();
    // localStorage.clear();
    // sessionStorage.clear();
    props.history.push('/select-business');
    return;
  };

  return (
    <div className={classess.container}>
      <div className={classess.inner}>
        <ThankyouModal handleClose={handleClose} />
      </div>
    </div>
  );
};

const mapDispatchToProps = dispatch => {
  return {
    logout: () => dispatch(logout()),
    onDestroyProfile: () => dispatch(actDestroyProfile())
  };
};

export default compose(
  withRouter,
  connect(
    state => state,
    mapDispatchToProps
  )
)(ThankyouPage);
