import { makeStyles } from '@material-ui/core';

export const heightBoxNotification = 440;
export const useStyle = makeStyles(theme => ({
  notificationIcon: {
    // borderLeft: borderHeader
  },
  controlIcon: {
    display: 'flex',
    height: '100%',
    alignItems: 'center',
    justifyContent: 'center',
    width: 70,
    cursor: 'pointer'
  },
  dropdown: {
    position: 'absolute',
    top: 68,
    right: -69,
    background: 'white',
    width: 440,
    borderBottomLeftRadius: 15,
    borderBottomRightRadius: 15,
    boxShadow: '0px 4px 8px rgba(130, 126, 90, 0.1)',
    paddingBottom: theme.spacing(1),
    [theme.breakpoints.down('md')]: {
      top: 'unset',
      bottom: 56,
      right: 16,
      position: 'fixed',
      width: '90vw'
    }
  },
  wrapper: {
    'overflow-x': 'auto',
    'max-height': heightBoxNotification
  },
  dropDrownItem: {
    textDecoration: 'none',
    padding: theme.spacing(1, 2),
    display: 'block',
    '& span': {
      color: theme.palette.color.black2
    },
    '&:hover': {
      cursor: 'pointer',
      color: theme.palette.color.orange
    }
  },
  activeDropDownItem: {
    '& span': {
      color: theme.palette.color.orange
    }
  },
  warningIcon: {
    color: theme.palette.color.orange,
    paddingRight: '4px'
  },
  warning: {
    display: 'flex',
    '& h6': {
      fontWeight: 'bold',
      color: theme.palette.black1
    }
  },
  date: {
    color: theme.palette.color.gray1,
    fontSize: '12px'
  },
  unSeen: {
    backgroundColor: 'rgba(254, 176, 103, 0.1)'
  },
  badge: {
    backgroundColor: theme.palette.color.orange
  },
  img: {
    width: 45,
    height: 45
  },
  posAnchor: {
    top: 9,
    right: 9
  }
}));
