import { makeStyles } from '@material-ui/core';

const useStyle = makeStyles(theme => ({
  errorText: {
    color: 'red'
  },
  title: {
    marginBottom: '10px',
    color: '#565554'
  },
  textField: {
    color: theme.palette.text.secondary
  },
  root: {
    display: 'flex',
    position: 'relative',
    width: '100%'
  },
  paper: {
    marginRight: theme.spacing(2)
  },
  menuList: {
    '& li': {
      fontFamily: 'Helvetica !important',
      fontSize: '16px !important',
      color: '#212121'
    },
    '& svg': {
      height: 20,
      width: 20,
      marginRight: 12
    }
  },
  svgLoading: {
    display: 'flex',
    justifyContent: 'center',
    '& svg': {
      height: 40,
      width: 40
    }
  },
  popover: {
    zIndex: 80,
    transform: 'unset !important',
    position: 'absolute',
    top: '65px !important',
    left: 0,
    right: 0
  },
  contentMenu: {
    paddingLeft: 8
  }
}));

export default useStyle;
