import React, { useEffect } from 'react';
import { connect } from 'react-redux';
// import clsx from 'clsx';
import { useTranslation } from 'react-i18next';
import { Typography } from '@material-ui/core';

import useStyle from './style';
import ProductService from '../../Orders/ProductService';
import TotalPrice from '../../Orders/TotalPrice';
import FormCustomer from '../../FormCustomer';
import PaymentInfo from '../../Orders/PaymentInfo';
import Button from '../../Button';
import { getLoadingStatus } from '../../../store/selectors/loading';
import { getOpenDetailOrderItem } from '../../../store/selectors/orders';
import { actSetLoading } from '../../../store/actions/loading';
import BackToDashBoard from '../../BackToDashBoard';

const DetailFailedOrderITem = props => {
  const classess = useStyle();
  const { t } = useTranslation();

  const {
    _id,
    code,
    amount,
    address,
    discount,
    products,
    customer,
    subTotal,
    promotion,
    serviceFee,
    deliveryFee,
    productsPrice,
    paymentMethod
  } = props.data;

  const dataTotalPrice = {
    amount,
    discount,
    promotion,
    serviceFee,
    deliveryFee,
    productsPrice: subTotal || productsPrice
  };

  const dataCustomer = {
    customer,
    address,
    productOrderID: _id
  };

  const dataPaymentMethod = {
    paymentMethod
  };

  useEffect(() => {
    if (props.loading?.status && props.showDetailOrderItem) {
      props.setLoading({ status: false });
    }
  }, [props.loading?.status]);

  const handleNextOrder = () => {
    props.handleNextOrder();
  };

  return (
    <div className={classess.wrapp}>
      {/* <BackToDashBoard handleBackDashboard={props.handleshowDashboards} /> */}
      <Typography variant="h4" className={classess.identity}>
        #{code}
      </Typography>
      <div className={classess.divider}>
        <Typography variant="body1" className={classess.errorField}>
          {t('order.delivery.process.fail.Reason')}: {props.reasonFail}
        </Typography>
      </div>
      <div className={classess.main}>
        <ProductService products={products} />
        <TotalPrice dataTotalPrice={dataTotalPrice} />
        <FormCustomer dataCustomer={dataCustomer} />
        <PaymentInfo order={props.data} />
        <div className={classess.wrappBtn}>
          <Button typeBtn="yellow" onClick={handleNextOrder}>
            {t('action.Next Order')}
          </Button>
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = state => {
  return {
    loading: getLoadingStatus(state),
    showDetailOrderItem: getOpenDetailOrderItem(state)
  };
};

const mapDispatchToProps = dispatch => {
  return {
    setLoading: status => dispatch(actSetLoading(status))
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(DetailFailedOrderITem);
