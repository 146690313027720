import { makeStyles } from '@material-ui/core';
import { primaryFont, secondaryFont } from '../../shared/theme';

const useStyle = makeStyles(theme => ({
  root: {
    margin: theme.spacing(0, 3),
    padding: theme.spacing(1.6, 0, 2.5),
    borderBottom: `1px solid #E6E6E6`
  },
  title: {
    margin: 0,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between'
  },
  txtTitle: {
    fontSize: 18,
    fontFamily: secondaryFont,
    fontWeight: 'bold',
    color: theme.palette.color.black1
  },
  customerInfo: {
    marginTop: 20,
    display: 'flex',
    alignItems: 'center'
  },
  customerAvatar: {
    display: 'flex',
    width: 72,
    height: 72,
    flex: 2,
    borderRadius: '50%'
  },
  customerPicture: {
    display: 'block',
    width: 72,
    height: 72,
    borderRadius: '50%'
  },
  customerContent: {
    paddingLeft: 11,
    flex: 7.5,
    color: theme.palette.color.black2,
    fontFamily: primaryFont
  },
  distance: {
    display: 'flex',
    flex: 2.5,
    fontSize: 14,
    fontFamily: primaryFont,
    color: theme.palette.color.gray
  },
  customerName: {
    margin: 0,
    fontSize: 18,
    fontWeight: '600',
    [theme.breakpoints.only('xs')]: {
      fontSize: 16
    }
  },
  desc: {
    margin: 0,
    paddingTop: 8,
    fontSize: 18,
    lineHeight: '24px'
  },
  messengerControl: {
    display: 'inline-block',
    marginLeft: 15
  },
  messenger: {
    position: 'relative',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    // backgroundColor: '#3A8E07',
    // boxShadow: `0px 5px 15px rgba(65, 158, 44, 0.31)`,
    width: 28,
    height: 28,
    borderRadius: '50%',
    cursor: 'pointer',
    transition: 'all .35s',
    '& img': {
      maxWidth: 27,
      maxHeight: 27,
      display: 'block'
    },
    '&:hover': {
      transform: 'scale(1.1)'
    }
  }
}));

export default useStyle;
