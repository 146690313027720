import { makeStyles } from '@material-ui/core';

import { primaryFont } from '../../shared/theme';

const useStyle = makeStyles(theme => ({
  root: {
    position: 'relative',
    padding: 0,
    display: 'flex',
    flexDirection: 'column',
    fontFamily: 'Mulish',
    fontStyle: 'normal',
    fontSize: '16px',
    lineHeight: '16px'
  },

  fieldContainer: {
    width: '100%',
    marginTop: theme.spacing(2)
  },

  noticeBox: {
    alignSelf: 'flex-end',
    marginTop: theme.spacing(1)
  },

  title: {
    padding: '0 40px 20px',
    borderBottom: `1px solid #E6E6E6`
  },
  wrappImg: {
    marginBottom: 24
  },
  wrappForm: {
    flex: '1',
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    overflow: 'hidden'
  },
  switchWrapp: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    marginTop: 30,
    marginBottom: 24
  },
  textDescSwitch: {
    fontSize: 18,
    fontFamily: primaryFont,
    color: theme.palette.color.black2
  },
  listImg: {},

  actions: {
    display: 'flex',
    marginTop: 'auto',
    padding: theme.spacing(2, 2, 3, 2),
    justifyContent: 'space-between'
  },
  categoriesMenu: {
    '& .MuiSelect-select:focus': {
      backgroundColor: 'transparent'
    }
  },
  subCategoriesMenu: {
    '& .MuiSelect-select:focus': {
      backgroundColor: 'transparent'
    }
  },
  templateProduct: {
    '& .MuiSelect-select:focus': {
      backgroundColor: 'transparent'
    }
  },
  rootTextFeild: {
    '&:hover fieldset': {
      border: `1px solid ${theme.palette.color.gray} !important`
    },
    '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
      border: '1px solid #E38D03 !important'
    }
  },
  notchedOutline: {
    border: `1px solid #ccc`,
    outline: 0
  },
  inputTexFeild: {
    border: 'none',
    '&:focus': {
      backgroundColor: 'transparent'
    }
  },
  notchedOutlineFocus: {
    borderWidth: 1
  },
  modalContent: {
    display: 'flex',
    flexDirection: 'column',
    height: '100vh'
  },
  wrapTab: {
    height: '100%',
    padding: theme.spacing(0, 2)
  },
  radioGroup: {
    display: 'flex',
    flexWrap: 'inherit',
    flexDirection: 'row',
    fontSize: 14,
    justifyContent: 'space-between'
  },
  variant: {
    position: 'relative',
    background: '#F7F7F7',
    borderRadius: '4px 4px 0px 0px',
    margin: '0 0px 16px 0'
  },
  swipeableViews: {
    overflow: 'auto',
    flex: 1,
    '& .react-swipeable-view-container': {
      height: '100%'
    }
  },
  subCategory: {
    '& div': {
      fontSize: '16px !important'
    },
    '& h6': {}
  },

  addMoreChoices: {
    display: 'flex',
    alignItems: 'center',
    color: '#E38D03',
    borderRadius: '1px',
    cursor: 'pointer',
    border: 'none',
    outline: 'none',
    fontSize: 14,
    background: 'transparent',
    margin: '10px 0 20px 0',
    '&:disabled': {
      opacity: 0.5
    }
  },
  removeIconOption: {
    color: 'red',
    fontSize: 24,
    cursor: 'pointer',
    border: 'none',
    background: 'transparent',
    outline: 'none',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    '&:disabled': {
      opacity: 0.2,
      cursor: 'inherit'
    }
  },

  removeIcon: {
    background: 'red',
    borderRadius: '50%',
    color: 'white',
    cursor: 'pointer',
    marginTop: '10px'
  },
  variantTitle: {
    fontFamily: 'Helvetica',
    fontStyle: 'normal',
    fontSize: '14px',
    lineHeight: '24px',
    color: '#565554',
    mixBlendMode: 'normal'
  },
  variantsWrap: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center'
  },
  cancelBtn: {
    border: 'none',
    alignItems: 'center',
    textAlign: 'center',
    color: '#999999'
  },
  notice: {
    fontSize: '15px',
    lineHeight: '17px',
    color: '#565554',
    mixBlendMode: 'normal',
    '& > b': {
      fontSize: '17px'
    }
  },
  checkRounded: {
    width: 18,
    height: 18
  },

  formLabel: {
    margin: '0 16px 0 0',
    '& > span': {
      fontSize: '16px',
      fontWeight: 500
    }
  },
  iconSpacing: {
    padding: '0 8px 0 0'
  },
  deleteVariant: {
    color: '#E38D03',
    border: 'none',
    cursor: 'pointer',
    outline: 'none',
    padding: 0,
    background: 'transparent',
    opacity: '0.7'
  },
  addMoreVariant: {
    color: '#E38D03',
    border: 'none',
    cursor: 'pointer',
    display: 'flex',
    fontSize: 16,
    margin: '0 0 20px 0',
    outline: 'none',
    background: 'transparent',
    alignItems: 'center',
    borderRadius: '10px',
    '&:disabled': {
      opacity: 0.5,
      cursor: 'inherit'
    }
  }
}));
export default useStyle;
