import { makeStyles } from '@material-ui/core';
const useStyle = makeStyles(theme => ({
  modalWrapp: {
    maxHeight: '100%'
  },
  dialogPaper: {
    margin: 0,
    maxWidth: 512,
    borderRadius: 0,
    backgroundColor: theme.palette.color.white,
    maxHeight: '100%',
    [theme.breakpoints.down('md')]: {
      width: '100vw',
      maxWidth: '100vw'
    }
  },
  scrollPaper: {
    alignItems: 'stretch',
    justifyContent: 'flex-end'
  },
  scrollPaperCenter: {
    // alignItems: 'stretch',
    justifyContent: 'center'
  },
  modalContent: {}
}));
export default useStyle;
