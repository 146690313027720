import React from 'react';
import { useTranslation } from 'react-i18next';
import { Box, Typography } from '@material-ui/core';
import LogoutConfirmIcon from '../../../assets/img/logout-confirm.png';
import useStyle from './style';
import Button from '../../Button';

const ConfirmLogout = props => {
  const { setOpen, handleLogOut } = props;
  const { t } = useTranslation();
  const classes = useStyle();

  return (
    <Box className={classes.rootConfirmLogout}>
      <Box>
        <img src={LogoutConfirmIcon} alt="logout icon" />
      </Box>
      <Box mt={3} mb={4} textAlign="center">
        <Typography variant="h5">
          {t('logout.confirm.title.Oh no! You are leaving...')}
        </Typography>
        <Typography variant="h5">
          {t('logout.confirm.question.Are you sure?')}
        </Typography>
      </Box>
      <Box className={classes.btnAction}>
        <Box pr={1} width="100%">
          <Button handleClick={() => setOpen(false)}>{t('action.Stay')}</Button>
        </Box>
        <Box pl={1} width="100%">
          <Button typeBtn="yellow" handleClick={handleLogOut}>
            {t('action.Yes, log me out')}
          </Button>
        </Box>
      </Box>
    </Box>
  );
};

export default ConfirmLogout;
