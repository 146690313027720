import React from 'react';
import { groupBy } from 'lodash';
import productImg from '../../../assets/img/product-item-default.png';
import iconEdit from '../../../assets/img/icons/edit-note.svg';
import { CurrencySymbol } from '../../CurrencySymbol';
import ExternalImage from '../../ImgWithOnload';
import useStyle from './style';

const ProductItem = React.memo(props => {
  const mainClass = useStyle();
  const {
    note,
    qty,
    discountedPrice,
    price,
    media,
    product,
    discount,
    name,
    variantOptions
  } = props;
  const imgProductItem = media && media.length ? media[0] : productImg;

  const variants = groupBy(variantOptions, ({ variant }) => variant._id);

  const mapVariants = Object.keys(variants).map(key => ({
    id: key,
    name: variants[key][0].variant?.name,
    options: variants[key].reduce(
      (result, el) => [...result, { name: el.name, price: el.price }],
      []
    )
  }));

  return (
    <div className={mainClass.root}>
      <div className={mainClass.productItem}>
        <div className={mainClass.infoProduct}>
          <ExternalImage
            src={imgProductItem}
            alt="product item"
            className={mainClass.imgProduct}
          />
          <div className={mainClass.productDetail}>
            <p>{name}</p>
            <span className={mainClass.costProduct}>
              {/* SAT$ {!!discount && <strike>{price + ' '}</strike>} */}
              <CurrencySymbol>
                {discount ? discountedPrice : price}
              </CurrencySymbol>
              {/* {discount ? discountedPrice : price} */}
            </span>
          </div>
          <span className={mainClass.numberQuantity}>
            <span>{qty}</span>
          </span>
        </div>
      </div>
      <div>
        <div className={mainClass.variants}>
          {mapVariants.map((variant, index) => (
            <div key={variant.id} className={mainClass.wrapperVariant}>
              <span className={mainClass.variantName}>{variant?.name}</span>
              <div
                style={{
                  padding: '0 16px'
                }}>
                {variant.options.map((option, i) => (
                  <div
                    key={i}
                    style={{
                      listStyle: 'none'
                    }}
                    className={mainClass.nameOption}>
                    <span>-</span>
                    <span>{option.name}</span>
                    <span>
                      <CurrencySymbol>{option.price}</CurrencySymbol>
                    </span>
                  </div>
                ))}
              </div>
            </div>
          ))}
        </div>
      </div>
      {note && (
        <div className={mainClass.itemEdit}>
          <img src={iconEdit} alt="Edit" className={mainClass.editIcon} />
          <span className={mainClass.editText}>{note}</span>
        </div>
      )}
    </div>
  );
});
export default ProductItem;
