import { createSelector } from 'redux-starter-kit';
import { mystoresSlice } from '../reducers/mystores';

export const getNeedReloadMyStore = createSelector(
  [mystoresSlice.selectors.getMystores],
  mystores => mystores.needReloadMyStore
);

export const getNeedFetchVendorGetStore = createSelector(
  [mystoresSlice.selectors.getMystores],
  mystores => mystores.needFetchVendorGetStore
);

export const getDataEmployeeAddStaff = createSelector(
  [mystoresSlice.selectors.getMystores],
  mystores => mystores.dataEmployeeAddStaff
);
