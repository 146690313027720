// all format date follow moment, not allow Date js

import React, { useCallback, useState } from 'react';
import moment from 'moment';
import PropTypes from 'prop-types';
import {
  SingleDatePicker,
  isInclusivelyAfterDay,
  isInclusivelyBeforeDay
} from 'react-dates';
import 'react-dates/initialize'; // initial style
import 'react-dates/lib/css/_datepicker.css'; // initial style

import Box from '@material-ui/core/Box';
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';
import CalendarTodayIcon from '@material-ui/icons/CalendarToday';
import ArrowForwardIosIcon from '@material-ui/icons/ArrowForwardIos';

import useStyles from './style';

const DatePicker = props => {
  const {
    date,
    months,
    NavNext,
    NavPrev,
    minDate,
    disabled,
    maxDate,
    onChange,
    autoFocus,
    customClass,
    placeholder,
    displayFormat,
    CustomInputIcon,
    inputIconPosition,
    handleOnFocusChange
  } = props;

  const [focused, setFocused] = useState(autoFocus);

  const classes = useStyles({ ...props, focused });

  const onDateChange = date => {
    onChange(date);
  };

  const isOutsideRange = useCallback(
    day => {
      if (!minDate && !maxDate) return false;

      return (
        (minDate && !isInclusivelyAfterDay(day, moment(new Date(minDate)))) ||
        (maxDate && !isInclusivelyBeforeDay(day, moment(new Date(maxDate))))
      );
    },
    [minDate, maxDate]
    //return true when date out of side false when date in of side
  );

  const onFocusChange = e => {
    setFocused(e.focused);
  };

  return (
    <Box className={[classes.rootDatePicker, customClass].join(' ')}>
      <SingleDatePicker
        disabled={disabled}
        date={date ? moment(date, displayFormat) : null}
        focused={focused}
        showClearDate={true}
        numberOfMonths={months}
        placeholder={placeholder}
        onDateChange={onDateChange}
        displayFormat={displayFormat}
        onFocusChange={handleOnFocusChange || onFocusChange}
        isOutsideRange={isOutsideRange}
        hideKeyboardShortcutsPanel={true}
        inputIconPosition={inputIconPosition}
        navNext={<NavNext className={classes.leftArrow} />}
        navPrev={<NavPrev className={classes.rightArrow} />}
        customInputIcon={!Boolean(date) ? <CustomInputIcon /> : null}
      />
    </Box>
  );
};

DatePicker.propTypes = {
  label: PropTypes.string,
  onChange: PropTypes.func,
  months: PropTypes.number,
  NavNext: PropTypes.elementType,
  NavPrev: PropTypes.elementType,
  CustomInputIcon: PropTypes.elementType,
  inputIconPosition: PropTypes.oneOf(['before', 'after']),
  width: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  height: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
};

DatePicker.defaultProps = {
  date: null,
  months: 1,
  width: '100%',
  height: 40,
  label: 'label',
  autoFocus: false,
  maxDate: null,
  minDate: null,
  initialDate: null,
  onChange: () => {},
  handleOnFocusChange: null,
  placeholder: 'placeholder',
  displayFormat: 'DD/MM/YYYY',
  inputIconPosition: 'after',
  NavPrev: ArrowBackIosIcon,
  NavNext: ArrowForwardIosIcon,
  CustomInputIcon: CalendarTodayIcon
};

export default DatePicker;
