export const queryTransactionList = `

  _id
  code
  paymentMethod
  type
  status
  amount
  profit
  createdAt
  updatedAt
  transferFee
  order{
    code
  }
  delivery{
    code
  }

  
`;
