import React, { useState, useEffect, useRef } from 'react';
import { connect } from 'react-redux';
import clsx from 'clsx';
import isEqual from 'lodash/isEqual';
import { useTranslation } from 'react-i18next';

import CloseButton from '../../CloseButton';
// import ItemChatSkeleton from '../ItemChatSkeleton';

import {
  initMessageChannel,
  readMessages
} from 'maua-redux-core/actions/messageChannel';
import { messages, sendMessage } from 'maua-redux-core/actions/message';
import { getUserProfile } from 'maua-redux-core/selectors/user';

import {
  getOrderIdMessenger,
  getShowMessengerFromOrderDetail
} from '../../../store/selectors/messengerChat';
import {
  queryMessengerChannel,
  queryMessages,
  querySendMessage
} from '../../../store/query/messenger';

import { isEmptyObject } from '../../../utils/common';
import { uploadImg } from '../../../shared/services/upload';

import arrowBackImg from '../../../assets/img/icons/arrow-back.png';
import userDefault from '../../../assets/img/product-item-default.png';
import defaultImg from '../../../assets/img/default.jpg';
import addImg from '../../../assets/img/icons/addImg.png';
import sendMessegener from '../../../assets/img/icons/sendMessenger.png';
import { toastMessage, TOAST_TYPE } from '../../../utils/toastify-notify';
import { actSetLoading } from '../../../store/actions/loading';
import LoadingComponent from '../../Loading/LoadingComponent';
import useDebounce from '../../../utils/useDebounce';
import LoadMore from '../../LoadMore';
import LoadMoreComp from '../../LoadMoreComponent';
import ExternalImage from '../../ImgWithOnload';
import useStyle from './style';

const reverseArrByDatetime = arr => {
  // return arr;
  return arr.sort(function(a, b) {
    return new Date(`${a.updatedAt}`) - new Date(`${b.updatedAt}`);
  });
};
const ChatBox = props => {
  const mainClass = useStyle();
  const { t } = useTranslation();
  const [txtChat, setTxtChat] = useState('');
  const [channel, setChannel] = useState({});
  const [listMessFirst, setListMessFirst] = useState([]);
  const [isScrollingChat, setScrollingChat] = useState(false);
  const [needScrollBottom, setNeedScrollBottom] = useState(false);
  const [isScrollToBottom, setScrollToBottom] = useState(false);
  const [loadingPicture, setLoadingPicture] = useState(false);
  const [allowLoadmore, setAllowLoadmore] = useState(false);
  const [dataMessages, setDataMessages] = useState({
    page: 1,
    pages: 1,
    data: []
  });
  const debouncedLoadMore = useDebounce(allowLoadmore, 400);
  const debouncedScrollBottom = useDebounce(isScrollToBottom, 5000);

  const messagesEndRef = useRef(null);

  const { orderIdMessenger, profile, showMessengerFromOrderDetail } = props;
  const hasToken = isEmptyObject(profile);

  const { name: currentUser } = profile;

  useEffect(() => {
    if (
      (!isEmptyObject(channel) && orderIdMessenger) ||
      showMessengerFromOrderDetail
    ) {
      handleInitMessageChannel(orderIdMessenger);
    }
  }, [orderIdMessenger, showMessengerFromOrderDetail]);

  const handleInitMessageChannel = async orderId => {
    try {
      // setLoadingChannel(true);
      let params = {
        productOrder: orderId
      };
      await props
        .initMessageChannelRequest(params)
        .then(({ initMessageChannel }) => {
          setChannel(initMessageChannel);
          // setLoadingChannel(false);
        });
    } catch (error) {
      // setLoadingChannel(false);
      toastMessage(TOAST_TYPE.ERROR, error.message);
    }
  };

  useEffect(() => {
    if (channel._id) {
      handleGetListMessages({});
      setAllowLoadmore(true);
    }
  }, [channel._id]);

  const handleGetListMessages = async ({
    pageId,
    loadMore,
    needscrollBottom
  }) => {
    let timeoutScroll;
    try {
      // setLoadingMess(true);
      const params = {
        channel: channel._id,
        page: pageId || dataMessages.page
      };
      await props.getListMessagesRequest(params).then(({ messages }) => {
        if (loadMore) {
          let reserveArr = reverseArrByDatetime(messages.docs);

          setDataMessages(prevData => {
            return {
              page: messages.page,
              pages: messages.pages,
              data: [...reserveArr, ...prevData.data]
            };
          });
          setAllowLoadmore(true);
        } else {
          setDataMessages({
            page: messages.page,
            pages: messages.pages,
            data: reverseArrByDatetime(messages.docs)
          });
          setListMessFirst([...messages.docs]);
          if (isScrollingChat && needscrollBottom) {
            setNeedScrollBottom(true);

            timeoutScroll && clearTimeout(timeoutScroll);
            timeoutScroll = setTimeout(() => {
              setNeedScrollBottom(false);
              setScrollingChat(false);
            }, 300);
          }
        }

        // setLoadingMess(false);
      });
    } catch (error) {
      // setLoadingMess(false);
      toastMessage(TOAST_TYPE.ERROR, error.message);
    }
  };

  const scrollToBottom = () => {
    if (dataMessages.data.length && debouncedLoadMore && !isScrollingChat) {
      messagesEndRef.current.scrollIntoView({ behavior: 'smooth' });
      setScrollToBottom(true);
    }
  };

  useEffect(scrollToBottom, [dataMessages.data, debouncedLoadMore]);

  const handleListenListMessages = async channelId => {
    try {
      const params = {
        channel: channelId,
        page: 1
      };

      await props.getListMessagesRequest(params).then(({ messages }) => {
        setDataMessages(prevData => {
          return {
            page: messages.page,
            pages: messages.pages,
            data: reverseArrByDatetime(messages.docs)
          };
        });

        props.readMessages({ channel: props.channelID });
        isScrollingChat && setScrollingChat(false);
        allowLoadmore && setAllowLoadmore(false);
      });
    } catch (error) {
      toastMessage(TOAST_TYPE.ERROR, error.message);
    }
  };

  const handleChangeTextChat = e => {
    setTxtChat(e.target.value);
  };

  const handleLoadMoreMess = () => {
    const { page, pages } = dataMessages;
    if (page < pages && debouncedLoadMore && debouncedScrollBottom) {
      handleGetListMessages({ pageId: page + 1, loadMore: true });
      setAllowLoadmore(false);
      !isScrollingChat && setScrollingChat(true);
    } else {
      return;
    }
  };

  // let listenMessengerChange;
  // const listenerUpdateListMessenger = () => {
  //   if (listenMessengerChange) {
  //     clearInterval(listenMessengerChange);
  //   }
  //   const channelID = channel._id;
  //   listenMessengerChange = setInterval(
  //     () => handleListenListMessages(channelID),
  //     5000
  //   );
  // };

  useEffect(() => {
    let interval = null;

    if (hasToken && channel._id) {
      interval = setInterval(
        () => handleListenListMessages(channel?._id),
        5000
      );
    }

    return () => clearInterval(interval);
  }, [hasToken, channel._id]);

  const { name, order } = channel || {};
  const renderMedia = (medias, currUser) => {
    const classWrapp = currUser
      ? mainClass.userDescChatCur
      : mainClass.userDescChatAns;
    const hasCol2 = medias.length % 3 === 2;
    const hasCol1 = medias.length % 3 === 1;

    return (
      <div className={clsx(mainClass.wrappMedia, classWrapp)}>
        {medias.map((media, idx) => {
          const styleCol2 =
            hasCol2 && (medias.length - 1 === idx || medias.length - 2 === idx)
              ? mainClass.col2
              : mainClass.colDefaul;
          const clssSubMedia = hasCol2
            ? styleCol2
            : hasCol1 && medias.length - 1 === idx
            ? mainClass.col1
            : mainClass.colDefaul;
          return (
            <ExternalImage
              key={idx}
              src={media}
              alt="Media"
              className={clsx(mainClass.media, clssSubMedia)}
            />
          );
        })}
      </div>
    );
  };

  const handleSendMessages = async () => {
    if (txtChat) {
      const params = {
        channel: channel._id,
        content: txtChat
      };
      try {
        await props.sendMessegenerRequest(params).then(res => {
          handleGetListMessages({ pageId: 1, needscrollBottom: true });
          setTxtChat('');
        });
      } catch (error) {
        console.log('error ', error.messages);
      }
    }
  };
  const handleKeyDown = async e => {
    const { target } = e;
    const messageVal = target.value;
    if (!!messageVal.length && e.key === 'Enter') {
      handleSendMessages();
    }
  };

  const handleChangeImage = el => {
    let inputEl = el.target;
    const fileData = inputEl.files;
    // const reader = new FileReader();
    const filesUpload = [];
    let fileSizeValidate = true;
    if (fileData) {
      Object.keys(fileData)
        .map(key => fileData[key])
        .forEach(file => {
          // setFiles(prevState => [...prevState, file]);
          let fileSize = file.size / 1024 / 1024; // in MB
          if (fileSize >= 1) {
            fileSizeValidate = false;
            toastMessage(
              TOAST_TYPE.ERROR,
              t('toastMessage.error.content.Files Upload size greate than 1MB')
            );
            return;
          } else {
            filesUpload.push(file);
          }

          // reader.readAsDataURL(file);
        });

      fileSizeValidate && uploadImage(channel._id, filesUpload);
    }
    el.target.value = '';
  };

  const uploadProgress = progress => {
    let percent = Math.floor((progress.loaded / progress.total) * 100);
    percent !== props.loading.percent &&
      props.setLoading({ status: true, percent });
  };

  const uploadImage = (idProduct, filesData) => {
    setLoadingPicture(true);
    const imgData = {
      _id: idProduct,
      model: 'Message',
      field: 'media',
      files: filesData
    };
    uploadImg(imgData, uploadProgress)
      .then(res => {
        handleGetListMessages({ pageId: 1, needscrollBottom: true });
        setLoadingPicture(false);
      })
      .catch(err => {
        setLoadingPicture(false);
      })
      .finally(() => {
        props.setLoading({ status: false, percent: 0 });
      });
  };
  const renderRole = (senderRole, name) => {
    switch (senderRole) {
      case 'user':
        return `${name || ''} (Customer)`;
      case 'vendor':
        return (channel && channel.store.name) || '';
      case 'deliver':
        return `${name || ''} (Driver)`;
      case 'admin':
        return 'Maua Rep';
      default:
        break;
    }
  };

  return (
    <div className={mainClass.chatBox}>
      <div className={mainClass.headChat}>
        <img
          src={arrowBackImg}
          alt="back List"
          className={mainClass.arrowBack}
          onClick={props.handleBackUserList}
        />
        <div className={mainClass.userItem}>
          <img
            src={userDefault}
            alt="avatar"
            className={mainClass.avatarUser}
          />
          <div className={mainClass.infoUser}>
            <h4 className={mainClass.userName}>{name || ''}</h4>
            <p className={mainClass.userDesc}>
              {order && order.code ? `#${order.code}` : ''}
            </p>
          </div>
        </div>
        <div className={mainClass.rightControll}>
          <CloseButton
            className={mainClass.closeBtn}
            onClick={props.handleCloseChat}
          />
        </div>
      </div>
      <div className={mainClass.chatDetail}>
        <LoadMore
          handleLoadMore={handleLoadMoreMess}
          outsideclass={mainClass}
          scrollReverse={true}
          // isscrolltotop={needScrolltop}
          needscrollToBottom={needScrollBottom}>
          {dataMessages.data.map((item, idx) => {
            const { content, media, sender, senderRole } = item || {};
            const { avatar, name } = sender || {};
            const { sender: prevSender } =
              idx > 0 ? dataMessages.data[idx - 1] : {};
            const isSamePerson =
              idx > 0 && prevSender.name === name ? true : false;
            let avtarChat;
            if (senderRole === 'vendor') {
              avtarChat =
                channel && channel.store.media && channel.store.media.length
                  ? channel.store.media[0]
                  : userDefault;
            } else {
              avtarChat = avatar || userDefault;
            }

            return (
              <div key={item._id} className={mainClass.innerChat}>
                {name !== currentUser ? (
                  <div
                    className={clsx(mainClass.itemChat, mainClass.customerAns)}>
                    <div className={mainClass.userItemChat}>
                      {!isSamePerson ? (
                        <img
                          src={avtarChat}
                          alt="avatar"
                          className={mainClass.avatarUserChat}
                        />
                      ) : (
                        <span className={mainClass.avatarUserChat}></span>
                      )}
                      <div className={mainClass.infoUserChat}>
                        {!isSamePerson ? (
                          <p className={mainClass.userNameChat}>
                            {renderRole(senderRole, name)}
                          </p>
                        ) : null}
                        {content ? (
                          <p
                            className={clsx(
                              mainClass.userDescChat,
                              mainClass.userDescChatAns
                            )}>
                            {content}
                          </p>
                        ) : null}
                        {media && media.length
                          ? renderMedia(media, false)
                          : null}
                      </div>
                    </div>
                  </div>
                ) : (
                  <div
                    className={clsx(mainClass.itemChat, mainClass.currentUser)}>
                    <div className={mainClass.userItemChat}>
                      {!isSamePerson ? (
                        <img
                          src={avtarChat}
                          alt="avatar"
                          className={mainClass.avatarUserChat}
                        />
                      ) : (
                        <span className={mainClass.avatarUserChat}></span>
                      )}
                      <div className={mainClass.infoUserChat}>
                        {isSamePerson ? null : (
                          <p className={mainClass.userNameChat}>
                            {renderRole(senderRole, name)}
                          </p>
                        )}
                        {content ? (
                          <p
                            className={clsx(
                              mainClass.userDescChat,
                              mainClass.userDescChatCur
                            )}>
                            {content}
                          </p>
                        ) : null}
                        {media && media.length
                          ? renderMedia(media, true)
                          : null}
                      </div>
                    </div>
                  </div>
                )}
              </div>
            );
          })}
          <div ref={messagesEndRef} />
        </LoadMore>
        <LoadingComponent show={loadingPicture} />
      </div>
      <div className={mainClass.footerChat}>
        <div className={mainClass.wrappInputChat}>
          <input
            type="text"
            value={txtChat}
            className={mainClass.inputChat}
            onChange={handleChangeTextChat}
            onKeyDown={handleKeyDown}
          />
          <div className={mainClass.groupControll}>
            <span className={mainClass.btnUpload}>
              <img src={addImg} alt="uploadPicture" />
              <input
                accept="image/*"
                className={mainClass.viewInputGallery}
                type="file"
                onChange={handleChangeImage}
                multiple="multiple"
              />
            </span>
            <button
              type="button"
              className={mainClass.btnSendMess}
              onClick={handleSendMessages}>
              <img src={sendMessegener} alt="send Messenger" />
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = state => {
  return {
    loading: state.loading,
    profile: getUserProfile(state),
    orderIdMessenger: getOrderIdMessenger(state),
    showMessengerFromOrderDetail: getShowMessengerFromOrderDetail(state)
  };
};

const mapDispatchToProps = dispatch => {
  return {
    initMessageChannelRequest: params =>
      dispatch(initMessageChannel(params, queryMessengerChannel)),
    getListMessagesRequest: params => dispatch(messages(params, queryMessages)),
    sendMessegenerRequest: params =>
      dispatch(sendMessage(params, querySendMessage)),
    readMessages: params => dispatch(readMessages(params, null)),
    setLoading: params => dispatch(actSetLoading(params))
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ChatBox);
