import React, { useState, useEffect, useRef, useMemo } from 'react';
import ReactQuill from 'react-quill';
import { Box } from '@material-ui/core';
// import { uploadFile } from '../../../utils/fileFunc';
import useStyle from './style';

const EditorField = props => {
  const classes = useStyle(props);
  const quillRef = useRef(null);

  const [editorHtml, setEditorHtml] = useState('');

  const handleChange = html => {
    setEditorHtml(html);
    props.onChange(html);
  };

  const uploadImageToServer = () => {
    const input = document.createElement('input');
    input.setAttribute('type', 'file');
    input.setAttribute('multiple', '');
    input.click();

    // Listen upload local image and save to server
    input.onchange = async () => {
      let files = [];

      for (let index = 0; index < input.files?.length; index++) {
        if (/^image\//.test(input.files[index]?.type)) {
          files = [...files, input.files[index]];
        }
      }

      // // file type is only image.
      if (files?.length) {
        const formDataFiles = [
          { label: 'model', file: 'Other' },
          { label: 'field', file: 'description' },
          { label: 'subfield', file: props.model },
          ...files.map(file => ({ label: 'files', file }))
        ];

        // const media = await uploadFile(
        //   'upload',
        //   { files: formDataFiles },
        //   () => {}
        // );

        // // // push image url to rich editor.
        // const range = quillRef.current.editor.getSelection();

        // for (let index = 0; index < media.length; index++) {
        //   quillRef.current.editor.insertEmbed(
        //     range.index,
        //     'image',
        //     media[index]
        //   );
        // }
      }
    };
  };

  useEffect(() => {
    setEditorHtml(props.value);
  }, [props.value]);

  const MODULES = useMemo(
    () => ({
      toolbar: {
        container: [
          [{ header: '1' }, { header: '2' }, { font: [] }],
          [{ size: [] }],
          ['bold', 'italic', 'underline', 'strike', 'blockquote'],
          [
            { list: 'ordered' },
            { list: 'bullet' },
            { indent: '-1' },
            { indent: '+1' }
          ],
          ['link', 'image', 'video'],
          ['clean']
        ],
        handlers: {
          image: uploadImageToServer
        }
      },
      clipboard: {
        matchVisual: false
      }
    }),
    []
  );

  return (
    <Box className={classes.editor}>
      <ReactQuill
        ref={quillRef}
        theme="snow"
        onChange={handleChange}
        value={editorHtml}
        bounds="#quill-editor"
        placeholder={props.placeholder}
        modules={MODULES}
        formats={[
          'header',
          'font',
          'size',
          'bold',
          'italic',
          'underline',
          'strike',
          'blockquote',
          'list',
          'bullet',
          'indent',
          'link',
          'image',
          'video'
        ]}
      />
    </Box>
  );
};

EditorField.defaultProps = {
  value: '',
  placeholder: 'Write something...',
  onChange: () => {}
};

export default EditorField;
