import { createSelector } from 'redux-starter-kit';
import { messengerChatSlice } from '../reducers/messengerChat';

export const getOrderIdMessenger = createSelector(
  [messengerChatSlice.selectors.getMessengerChat],
  messengerChat => messengerChat.orderIdMessenger
);

export const getShowMessengerFromOrderDetail = createSelector(
  [messengerChatSlice.selectors.getMessengerChat],
  messengerChat => messengerChat.showMessengerFromOrderDetail
);
