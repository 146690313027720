import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { connect, useSelector } from 'react-redux';
import { getUserProfile } from 'maua-redux-core/selectors/user';
import { Box, Typography } from '@material-ui/core';
import { getStoreDashboard } from 'maua-redux-core/actions/store';
import StopIcon from '@material-ui/icons/Stop';
import { getTimeZone, daysInThisMonth, financial } from '../../utils/common';
import { toastMessage, TOAST_TYPE } from '../../utils/toastify-notify';
import { optionsTime, xaxisTodayWeekly } from '../../utils/constant';
import SelectCustom from '../../components/FormFeild/SelectCustom';
import { getCurrencySymbol } from '../../store/selectors/currency';
import { CurrencySymbol } from '../../components/CurrencySymbol';
import StackedBar from '../../components/StackedBar';
import { theme } from '../../shared/theme';
import { useStyle } from './style';

const Revenue = props => {
  const classes = useStyle();
  const { t } = useTranslation();
  const symbol = useSelector(getCurrencySymbol);
  const [revenue, setRevenue] = useState({
    series: [],
    xaxis: [],
    total: 0,
    product: 0,
    deliveryFee: 0
  });
  const [filter, setFilter] = useState(optionsTime[2]);
  const colors = ['#F1B45B', '#7dcea0'];

  const loadRevenue = async () => {
    const payload = {
      type: 'REVENUE',
      startTime: filter.startTime,
      endTime: filter.endTime,
      timezone: getTimeZone()
    };
    try {
      let { getStoreDashboard } = await props.getStoreDashboard(payload);
      const revenues = getStoreDashboard?.revenue?.sort(
        (a, b) => a.column - b.column
      );

      let { product, deliveryFee } = revenues.reduce(
        ({ product, deliveryFee }, r) => ({
          product: product + r?.value.subTotal,
          deliveryFee: deliveryFee + r?.value.deliveryFee
        }),
        {
          product: 0,
          deliveryFee: 0
        }
      );

      // map data follow chart
      switch (filter.value) {
        case 'weekly':
          let dataWeekly = [
            {
              name: 'Subtotal',
              data: Array(7).fill(0)
            },
            {
              name: 'Self delivery',
              data: Array(7).fill(0)
            }
          ];

          revenues.forEach(({ column, value }) => {
            dataWeekly[0]['data'][column - 1] = financial(value.subTotal);
            dataWeekly[1]['data'][column - 1] = financial(value.deliveryFee);
          });

          setRevenue(() => ({
            series: dataWeekly,
            xaxis: xaxisTodayWeekly,
            total: financial(product + deliveryFee),
            product: financial(product),
            deliveryFee: financial(deliveryFee)
          }));
          break;
        case 'monthly':
          const daysTotal = daysInThisMonth();
          let monthly = [];
          for (let i = 1; i <= daysTotal; i++) {
            monthly.push(i);
          }

          let dataMonthly = [
            {
              name: 'Subtotal',
              data: Array(monthly?.length).fill(0)
            },
            {
              name: 'Self delivery',
              data: Array(monthly?.length).fill(0)
            }
          ];

          revenues.forEach(({ column, value }) => {
            dataMonthly[0]['data'][column - 1] = financial(value.subTotal);
            dataMonthly[1]['data'][column - 1] = financial(value.deliveryFee);
          });

          setRevenue(() => ({
            series: dataMonthly,
            xaxis: monthly,
            total: financial(product + deliveryFee),
            product: financial(product),
            deliveryFee: financial(deliveryFee)
          }));
          break;
        default:
          let yearly = [];
          for (let i = 1; i <= 12; i++) {
            yearly.push(i);
          }

          let dataYearly = [
            {
              name: 'Subtotal',
              data: Array(12).fill(0)
            },
            {
              name: 'Self delivery',
              data: Array(12).fill(0)
            }
          ];

          revenues.forEach(({ column, value }) => {
            dataYearly[0]['data'][column - 1] = financial(value.subTotal);
            dataYearly[1]['data'][column - 1] = financial(value.deliveryFee);
          });

          setRevenue(() => ({
            series: dataYearly,
            xaxis: yearly,
            total: financial(product + deliveryFee),
            product: financial(product),
            deliveryFee: financial(deliveryFee)
          }));
          break;
      }
    } catch (error) {
      toastMessage(TOAST_TYPE.ERROR, error.message);
    }
  };

  useEffect(() => {
    if (props.profile._id) {
      loadRevenue();
    }
  }, [filter, props.profile]);

  const handleFilter = e => {
    const val = e.target.value;
    setFilter(() => optionsTime.filter(({ value }) => value === val)[0]);
  };

  return (
    <Box p={theme.spacing(2.4, 0)}>
      <Box className={classes.background}>
        <Box className={classes.header}>
          <Typography variant="h4">{t('dashboard.title.Revenue')}</Typography>
          <SelectCustom
            options={optionsTime}
            value={filter?.value}
            onChange={handleFilter}
          />
        </Box>
        <Box className={classes.chartHeader} borderBottom="none !important">
          <Box display="flex" alignItems="center" flexWrap="wrap">
            <Box pr={4} className={classes.flex}>
              <Box className={classes.title}>{t('dashboard.title.Total')}:</Box>
              <Box className={classes.price}>
                <Box>
                  <CurrencySymbol>{parseFloat(revenue.total)}</CurrencySymbol>
                </Box>
              </Box>
            </Box>
            <Box pr={4} className={classes.flex}>
              <Box className={classes.title}>
                {t('dashboard.revenue.label.Product')}:
              </Box>
              <Box className={classes.price}>
                <Box>
                  <CurrencySymbol>{parseFloat(revenue.product)}</CurrencySymbol>
                </Box>
              </Box>
            </Box>
            <Box className={classes.flex}>
              <Box className={classes.title}>
                {t('dashboard.revenue.label.Self delivery')}:
              </Box>
              <Box className={classes.price}>
                <Box>
                  <CurrencySymbol>
                    {parseFloat(revenue.deliveryFee)}
                  </CurrencySymbol>
                </Box>
              </Box>
            </Box>
          </Box>
          <Box className={classes.flex}>
            <Box pr={2} className={classes.flex}>
              <StopIcon style={{ color: colors[0] }} />
              <Box className={classes.title}>
                {t('dashboard.revenue.label.Subtotal')}
              </Box>
            </Box>
            <Box className={classes.flex}>
              <StopIcon style={{ color: colors[1] }} />
              <Box className={classes.title}>
                {t('dashboard.revenue.label.Self delivery')}
              </Box>
            </Box>
          </Box>
        </Box>
        <StackedBar
          unit={symbol}
          colors={colors}
          xaxis={revenue.xaxis}
          series={revenue.series}
        />
      </Box>
    </Box>
  );
};

export default connect(
  state => ({
    profile: getUserProfile(state)
  }),
  dispatch => ({
    getStoreDashboard: params =>
      dispatch(
        getStoreDashboard(
          params,
          'revenue{ column value { subTotal deliveryFee }}'
        )
      )
  })
)(Revenue);
