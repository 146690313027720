import { createSlice } from 'redux-starter-kit';

let initialState = {
  openLogin: false,
  openChangeRegion: false,
  openPickupConfirm: false,
  openModalReason: false,
  openModalChoiseDelivery: false,
  openModalAddProduct: false,
  openModalEditProdcut: false,
  openModalEditYourStore: false,
  openModalEditUser: false,
  openModalSwitchBusiness: false,
  openModalAddBusiness: false,
  openModalThankyouAddBusiness: false,
  openModalEnterStaffPhone: false,
  openModalWaitingStaffPhone: false,
  openModalStaffNotFound: false,
  openModalFoundStaff: false
};

export let modalSlice = createSlice({
  initialState: initialState,
  slice: 'modal',
  reducers: {
    toggleLoginModal(state, action) {
      state.openLogin = action.payload.state;
    },
    toggleChangeRegionModal(state, action) {
      state.openChangeRegion = action.payload.state;
    },
    toggleModalPickupConfirm(state, action) {
      state.openPickupConfirm = action.payload.state;
    },
    toggleModalReasonOrder(state, action) {
      state.openModalReason = action.payload.state;
    },
    toggleModalChoiseDelivery(state, action) {
      state.openModalChoiseDelivery = action.payload.state;
    },
    toggleModalAddProduct(state, action) {
      state.openModalAddProduct = action.payload.state;
    },
    toggleModalEditProduct(state, action) {
      state.openModalEditProdcut = action.payload.state;
    },
    toggleModalEditYourStore(state, action) {
      state.openModalEditYourStore = action.payload.state;
    },
    toggleModalEditUser(state, action) {
      state.openModalEditUser = action.payload.state;
    },
    toggleMoadlSwitchBusiness(state, action) {
      state.openModalSwitchBusiness = action.payload.state;
    },
    toggleModalAddBusiness(state, action) {
      state.openModalAddBusiness = action.payload.state;
    },
    toggleModalThankyouAddBusiness(state, action) {
      state.openModalThankyouAddBusiness = action.payload.state;
    },
    //------
    toggleModalEnterStaffPhone(state, action) {
      state.openModalEnterStaffPhone = action.payload.state;
    },
    toggleModalWaitingStaffPhone(state, action) {
      state.openModalWaitingStaffPhone = action.payload.state;
    },
    toggleModalStaffNotFound(state, action) {
      state.openModalStaffNotFound = action.payload.state;
    },
    toggleModalFoundStaff(state, action) {
      state.openModalFoundStaff = action.payload.state;
    }
  }
});

export default modalSlice.reducer;
