import React, { useState } from 'react';
import { connect } from 'react-redux';
import { useTranslation } from 'react-i18next';
import DialogContentText from '@material-ui/core/DialogContentText';
import CloseRoundedIcon from '@material-ui/icons/CloseRounded';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import { Box, IconButton } from '@material-ui/core';
import Dialog from '@material-ui/core/Dialog';
import { actToggleModalChangeRegion } from '../../../store/actions/modal';
import { getOpenChangeRegion } from '../../../store/selectors/modal';
import { IMG_SUPPORTED_COUNTRIES } from './contants';
import { theme } from '../../../theme';
import ButtonUI from '../../ButtonUI';
import { useStyle } from './style';

const ChangeRegion = props => {
  const classes = useStyle();
  const { t } = useTranslation();
  const [country, setCountry] = useState(null);

  const handleClose = () => {
    props.toggleModalChangeRegion(!props.isOpenChangeRegion);
  };

  const handleSelectCountry = _country => {
    setCountry(_country);
  };

  const handleConfirmChangeRegion = () => {
    window.location.href = country.url;
  };

  const styleFlag = {
    outline: `3px solid ${theme.palette.primary.main}`,
    opacity: 1
  };

  return (
    <Dialog
      open={props.isOpenChangeRegion}
      maxWidth="md"
      keepMounted
      onClose={handleClose}
      PaperProps={{
        className: classes.dialogContent
      }}>
      <DialogTitle id="alert-dialog-slide-title" align="right">
        <IconButton aria-label="close" onClick={handleClose}>
          <CloseRoundedIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent>
        <DialogContentText align="center" className={classes.title}>
          {t('login.label.Change country / region')}
        </DialogContentText>
        <DialogContentText align="center">
          {t('login.label.region.Select your preferred country website')}
        </DialogContentText>
        <Box className={classes.supportedCountries}>
          {IMG_SUPPORTED_COUNTRIES.map(_country => (
            <div
              key={_country.code}
              onClick={() => handleSelectCountry(_country)}>
              <div
                className={classes.imgContent}
                style={_country.name === country?.name ? styleFlag : {}}>
                <img
                  src={_country.flag}
                  title={`flag ${_country.name}`}
                  alt={`flag ${_country.name}`}
                />
              </div>
              <DialogContentText align="center">
                {_country.name}
              </DialogContentText>
            </div>
          ))}
        </Box>
      </DialogContent>
      <DialogActions className={classes.action}>
        <Box
          display="flex"
          alignItems="center"
          justifyContent="center"
          mt={2}
          width="100%">
          <ButtonUI
            disabled={!country}
            style={{ width: 216, cursor: 'pointer' }}
            onClick={handleConfirmChangeRegion}>
            {t('login.label.region.Done')}
          </ButtonUI>
        </Box>
      </DialogActions>
    </Dialog>
  );
};

export default connect(
  state => ({
    isOpenChangeRegion: getOpenChangeRegion(state)
  }),
  dispatch => ({
    toggleModalChangeRegion: param =>
      dispatch(actToggleModalChangeRegion(param))
  })
)(ChangeRegion);
