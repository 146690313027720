import { makeStyles } from '@material-ui/core';
// import { secondaryFont } from '../../shared/theme';

const useStyle = makeStyles(theme => ({
  countryCode: {
    width: 'fit-content',
    maxWidth: 140,
    background: '#fff',
    boxShadow: 'initial',
    padding: theme.spacing(0, 1, 0, 2),
    height: 40,
    marginRight: theme.spacing(0.5),
    boxSizing: 'border-box',
    color: 'rgba(47, 46, 46, 1)',
    fontSize: '14px',
    alignItems: 'center',
    fontFamily: '"Open Sans", sans-serif',
    fontWeight: '600',
    lineHeight: '1.1876em',
    cursor: 'pointer',
    border: props =>
      props.show
        ? `1px solid ${theme.palette.primary.main}`
        : '1px solid #c4c4c4 ',
    borderRadius: theme.spacing(0.5),

    '&:hover': {
      border: '1px solid #000',
      background: '#fff !important',
      boxShadow: 'initial !important'
    }
  },

  drownIcon: {
    marginLeft: 'auto',
    transform: props => !props.show && 'rotate(90deg)'
  },

  imgCountry: {
    height: 16,
    marginRight: theme.spacing(1)
  },

  inputField: {
    width: 120,
    background: '#f2f2f2',
    '& .MuiOutlinedInput-adornedEnd': {
      paddingRight: 6,
      cursor: 'pointer'
    },
    '&>p': {
      marginLeft: 0
    },
    '&>label': {
      marginTop: theme.spacing(-3 / 8),
      zIndex: 0,
      opacity: 0.4
    },
    '&>div>input': {
      padding: '10.5px 0'
    },
    '&>div>div>p': {
      wordBreak: 'normal'
    }
  },
  searchField: {
    width: '100%',
    '& .MuiOutlinedInput-adornedEnd': {
      paddingRight: 6,
      cursor: 'pointer'
    },
    '&>p': {
      marginLeft: 0
    },
    '&>label': {
      marginTop: theme.spacing(-3 / 8),
      zIndex: 0,
      opacity: 0.4
    },
    '&>div>input': {
      border: 'none',
      padding: 10
    },
    '&>div>div>p': {
      wordBreak: 'normal'
    }
  },
  country: {
    position: 'absolute',
    // overflow: 'scroll',
    background: '#fff',
    width: 300,
    height: 403,
    borderRadius: 4,
    marginTop: 4,
    padding: theme.spacing(1, 0),
    zIndex: 999,
    left: 0,
    boxShadow: '0px 2px 20px rgba(48, 83, 106, 0.09)'
  },

  itemCountry: {
    display: 'flex',
    height: 56,
    alignItems: 'center',
    padding: theme.spacing(1, 1, 1, 2),
    '&:hover': {
      background: '#fdd07a14',
      cursor: 'pointer'
    }
  },

  labelCountry: {
    textAlign: 'left'
  }
}));
export default useStyle;
