import React from 'react';
import Dialog from '@material-ui/core/Dialog';
import MuiDialogContent from '@material-ui/core/DialogContent';
import useStyle from './style';
import CloseButton from '../CloseButton';

const ModalCustom = React.memo(props => {
  const {
    id,
    openModel,
    handleClose,
    maxWidthDialog,
    fullWidthDialog,
    classOutSide,
    isCenter,
    hasBtnClose = true,
    styleOutside
  } = props;
  const mainClass = useStyle();
  const classContentOutside = classOutSide || mainClass;
  const isCenterPopupStyle = isCenter
    ? mainClass.scrollPaperCenter
    : mainClass.scrollPaper;
  return (
    <Dialog
      onClose={handleClose}
      aria-labelledby={id}
      open={openModel}
      className={mainClass.modalWrapp}
      fullWidth={fullWidthDialog ? fullWidthDialog : true}
      maxWidth={maxWidthDialog || 'sm'}
      classes={{ scrollPaper: isCenterPopupStyle }}
      PaperProps={{
        classes: { root: mainClass.dialogPaper }
      }}
      id={id}>
      {hasBtnClose ? <CloseButton onClick={handleClose} /> : null}
      <MuiDialogContent
        style={styleOutside}
        className={classContentOutside.modalContent}>
        {props.children}
      </MuiDialogContent>
    </Dialog>
  );
});

export default ModalCustom;
