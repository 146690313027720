import React from 'react';
// import Dialog from '@material-ui/core/Dialog';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import MuiDialogContent from '@material-ui/core/DialogContent';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import { useStyles } from './style';
import { useTranslation } from 'react-i18next';
import { Typography } from '@material-ui/core';

const DialogTitle = props => {
  const { children, onClose } = props;
  const classes = useStyles();

  return (
    <MuiDialogTitle disableTypography className={classes.rootDialogTitle}>
      <h4 className={classes.title}>{children}</h4>
      {onClose ? (
        <IconButton
          aria-label="Close"
          className={classes.closeButton}
          onClick={onClose}>
          <CloseIcon />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
};

const ThankyouModal = props => {
  const { handleClose } = props;
  const info = {
    id: 'thankyou-modal',
    title: 'Thank you'
  };
  const mainClass = useStyles();
  const { t } = useTranslation();

  return (
    <React.Fragment>
      <DialogTitle id={info.id} onClose={handleClose}>
        {info.title}
      </DialogTitle>
      <MuiDialogContent className={mainClass.rootDialogContent}>
        <Typography variant="body1">
          {t('business.thanks.Thankyou registration')}
        </Typography>
        <Typography variant="body1">
          {t('business.thanks.promise contact')}
        </Typography>
      </MuiDialogContent>
    </React.Fragment>
  );
};

export default ThankyouModal;
