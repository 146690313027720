import { messengerChatSlice } from '../reducers/messengerChat';

export const actSetOrderIdMessenger = state => {
  return messengerChatSlice.actions.setOrderIdMessenger({
    state
  });
};

export const actSetShowMessengerFromOrderDetail = state => {
  return messengerChatSlice.actions.setShowMessengerFromOrderDetail({
    state
  });
};
