import { makeStyles } from '@material-ui/core';
import { primaryFont } from '../../shared/theme';

export const useStyleRadio = makeStyles(theme => ({
  root: {
    display: 'flex'
  },
  formControl: {
    width: '100%'
  },
  group: {
    margin: theme.spacing(1, 0),
    display: 'flex',
    flexDirection: 'row'
  },
  formControlLabel: {
    fontFamily: 'Helvetica',
    fontSize: theme.spacing(1.8),
    color: theme.palette.color.black2
  },
  radio: {
    color: theme.palette.color.white1,
    '&$checked': {
      color: theme.palette.color.orange
    }
  },
  checked: {},
  divider: {
    margin: theme.spacing(1.4, 0)
  },
  menuItem: {
    fontSize: 14,
    fontFamily: 'Helvetica'
  }
}));

export const useStyleSelectFeild = makeStyles(theme => ({
  menuItem: {
    paddingTop: 8,
    paddingBottom: 8,
    fontSize: '14px !important',
    fontFamily: 'Helvetica'
  },
  rootSelect: {
    '&:hover fieldset': {
      borderColor: `${theme.palette.color.gray} !important`,
      boderWidth: 1
    },
    '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
      border: '1px solid #E38D03'
    }
  },
  inputSelect: {
    border: 'none',
    '&:focus': {
      backgroundColor: 'transparent'
    }
  },
  notchedOutline: {
    border: `1px solid #ccc`,
    outline: 0
  },
  notchedOutlineFocus: {
    borderWidth: 1
  }
}));

export const useStyleSwitchCustom = makeStyles(theme => ({
  input: {
    height: 0,
    width: 0,
    display: 'none',
    '&:checked + label': {
      background: theme.palette.color.orange
    },
    '&:checked + label:after': {
      left: 'calc(100% - 21px)'
    }
  },
  label: {
    cursor: 'pointer',
    textIndent: '-9999px',
    width: '48px',
    height: '24px',
    background: theme.palette.color.gray,
    display: 'block',
    borderRadius: '100px',
    position: 'relative',
    '&:after': {
      content: '" "',
      position: 'absolute',
      top: '50%',
      left: '5px',
      width: '16px',
      height: '16px',
      background: theme.palette.color.white,
      borderRadius: '50%',
      transition: 'all 0.35s',
      transform: 'translateY(-50%)'
    }
  }
}));

export const useStyleCheckbox = makeStyles(theme => ({
  checkboxCustom: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center'
  },
  labelFormControlCheckbox: {
    marginRight: 0
  },
  colorCheckbox: {
    color: `${theme.palette.color.white1} !important`
  },
  colorChecked: {
    color: `${theme.palette.color.orange} !important`
  },
  labelCheckbox: {
    fontSize: 18,
    color: theme.palette.color.black2,
    fontFamily: primaryFont
  }
}));

export const useStyleTextFeild = makeStyles(theme => ({
  rootTextField: {
    '& label': {
      fontSize: 14,
      zIndex: 0
    }
  },
  root: {
    '&:hover fieldset': {
      border: `1px solid ${theme.palette.color.gray} !important`
    },
    '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
      border: '1px solid #E38D03 !important'
    },
    '&.MuiInput-underline:hover:not(.Mui-disabled):before': {
      borderBottom: `1px solid rgba(0, 0, 0, 0.42)`
    },
    '&.MuiInput-underline:after': {
      borderBottom: `1px solid #E38D03 !important`
    },

    '& label.Mui-focused': {
      color: 'orange'
    },
    '& .MuiFormLabel-root': {
      color: 'blue'
    },
    '& > lable.MuiInputLabel-root': {
      fontSize: '12px'
    }
  },
  notchedOutline: {
    border: `1px solid #ccc`,
    outline: 0
  },
  input: {
    border: 'none',
    '&:focus': {
      backgroundColor: 'transparent'
    },
    '&::placeholder': {
      fontSize: '14px'
    }
  },
  notchedOutlineFocus: {
    borderWidth: 1
  },
  formControl: {
    fontSize: 12
  }
}));
