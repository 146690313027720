import axios from 'axios';
import { checkLocation } from '../../utils/checkLocation';

// const endpoint = process.env.REACT_APP_API || 'https://api-maua.efisoft.com.vn';

const endpoint = checkLocation().api;

export const get = (params = {}) =>
  axios.get(`${endpoint}/api/meta`, { params });

export default { get };
