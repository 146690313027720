import React, { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { Box, Tooltip } from '@material-ui/core';
import { getLanguages } from 'maua-redux-core/actions/language';
import { actToggleModalChangeRegion } from '../../store/actions/modal';
import { toastMessage, TOAST_TYPE } from '../../utils/toastify-notify';
import { getOpenChangeRegion } from '../../store/selectors/modal';
import { switchLocation } from 'maua-redux-core/actions/user';
import { queryLanguages } from '../../store/query/language';
import { actSetLoading } from '../../store/actions/loading';
import { checkLocation } from '../../utils/checkLocation';
import { onClickOutside } from '../../utils/function';
import ChangeRegion from '../Login/ChangeRegion';
import { useStyle } from './style';

const Languages = props => {
  const rootRef = useRef();
  const classes = useStyle();
  const { i18n, t } = useTranslation();

  const TEXT_LANGUAGES = {
    WS: t('list.language.name_Samoa'),
    VU: t('list.language.name_Vanuatu'),
    GB: t('list.language.name_United_Kingdom')
  };

  const [options, setOptions] = useState([]);

  const [language, setLanguage] = useState(
    JSON.parse(localStorage.getItem('vendor_ln'))?.shortCode || 'GB'
  );
  const [open, setOpen] = useState(false);

  const ln = options.find(l => l.shortCode === language) || options[0];
  onClickOutside(rootRef, () => setOpen(false));

  useEffect(() => {
    i18n.changeLanguage(language);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [language]);

  const loadLanguages = async () => {
    props.setLoading(true);
    try {
      // call action check location
      await props.switchLocation(checkLocation().code);

      // action getLanguages
      const { getLanguages } = await props.getLanguages({ enable: true });
      setOptions(getLanguages);
    } catch (error) {
      toastMessage(TOAST_TYPE.ERROR, error.message);
    } finally {
      props.setLoading(false);
    }
  };

  useEffect(() => {
    loadLanguages();
  }, []);

  const handleChangeLanguage = ln => {
    setLanguage(ln.shortCode);
    window.location.reload();

    localStorage.setItem(
      'vendor_ln',
      JSON.stringify({
        title: ln.title,
        shortCode: ln.shortCode
      })
    );
  };

  const handleChangeRegion = () => {
    props.toggleModalChangeRegion(true);
    setOpen(false);
  };

  const country = (
    <span
      style={{
        fontWeight: '700',
        fontFamily: "'Muli', sans-serif"
      }}>
      {checkLocation().text}
    </span>
  );

  return (
    <Box className={classes.root} ref={rootRef}>
      <Box className={classes.flag} onClick={() => setOpen(true)}>
        <Tooltip title="Language">
          <Box className={classes.icon}>
            <img src={ln?.flag} alt={ln?.title} />
          </Box>
        </Tooltip>

        {open && (
          <Box className={classes.optionLanguage}>
            {options.map(lang => (
              <Box
                className={classes.languageItem}
                key={lang.shortCode}
                onClick={() => handleChangeLanguage(lang)}>
                <img src={lang.flag} alt={lang.title} width="20" />
                <Box ml={0.8} className={classes.label}>
                  {TEXT_LANGUAGES[lang.shortCode]}
                </Box>
              </Box>
            ))}
            <Box className={classes.languageItem}>
              <Box className={classes.currentDomain}>
                {t('language.label.region.You are at')} {country}
              </Box>
            </Box>
            <Box className={classes.languageItem} onClick={handleChangeRegion}>
              <Box className={classes.changeCountryBtn}>
                {t('language.label.region.Change country/region')}
              </Box>
            </Box>
          </Box>
        )}
      </Box>
      {props.isOpenChangeRegion && <ChangeRegion />}
    </Box>
  );
};

export default connect(
  state => ({
    isOpenChangeRegion: getOpenChangeRegion(state)
  }),
  dispatch => ({
    setLoading: params => dispatch(actSetLoading(params)),
    switchLocation: params => dispatch(switchLocation(params)),
    getLanguages: params => dispatch(getLanguages(params, queryLanguages)),
    toggleModalChangeRegion: params =>
      dispatch(actToggleModalChangeRegion(params))
  })
)(Languages);
