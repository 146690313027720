import { makeStyles } from '@material-ui/core';

const useStyle = makeStyles(theme => ({
  header: {
    boxShadow: '0px 4px 8px rgba(130, 126, 90, 0.1)',
    zIndex: 20,
    position: 'fixed',
    top: 0,
    left: 0,
    right: 0,
    height: 68,
    width: '100vw',
    backgroundColor: theme.palette.color.black1
  },
  container: {
    height: '100%',
    [theme.breakpoints.down('md')]: {
      padding: 0
    }
  },
  headerContent: {
    display: 'flex',
    alignItems: 'center'
  },
  headerContainer: {
    position: 'relative',
    height: '100%'
  },
  headerRight: {
    position: 'relative',
    justifyContent: 'flex-end'
  },
  logo: {
    width: 49,
    height: 51,
    marginRight: theme.spacing(2.3),
    cursor: 'pointer'
  },
  navbar: {
    height: '100%',
    '& span': {
      position: 'relative',
      padding: theme.spacing(0, 2.2),
      color: theme.palette.color.gray2,
      cursor: 'pointer',
      display: 'flex',
      alignItems: 'center',
      fontSize: 18,
      '&::after': {
        content: '" "',
        position: 'absolute',
        bottom: 0,
        left: '50%',
        height: 2,
        width: 0,
        backgroundColor: 'transperant',
        transition: 'all .35s'
      },
      '&::before': {
        content: '" "',
        position: 'absolute',
        bottom: 0,
        right: '50%',
        height: 2,
        width: 0,
        backgroundColor: 'transperant',
        transition: 'all .35s'
      },
      '&.active': {
        color: theme.palette.color.white
      },
      '&:hover': {
        color: theme.palette.color.white
      },
      '&.active::after': {
        width: '50%',
        backgroundColor: theme.palette.color.orange
      },
      '&:hover::after': {
        width: '50%',
        color: theme.palette.color.white,
        backgroundColor: theme.palette.color.orange
      },
      '&.active::before': {
        width: '50%',
        backgroundColor: theme.palette.color.orange
      },
      '&:hover::before': {
        width: '50%',
        color: theme.palette.color.white,
        backgroundColor: theme.palette.color.orange
      }
    }
  },
  phone: {
    fontSize: 18,
    color: theme.palette.color.white,
    padding: theme.spacing(0, 1),
    fontWeight: 600,
    [theme.breakpoints.down('md')]: {
      lineHeight: 3.5
    },
    [theme.breakpoints.only('xs')]: {
      lineHeight: 1.5
    }
  },
  flag: {
    marginRight: '-20px',
    marginTop: '5px'
  },
  controlIcon: {
    display: 'flex',
    height: '100%',
    width: 70,
    alignItems: 'center',
    justifyContent: 'center',
    cursor: 'pointer'
  },
  rootConfirmLogout: {
    height: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'column'
  },
  btnAction: {
    display: 'flex',
    alignItems: 'center',
    width: '100%'
  },
  mobileMenuIcon: {
    marginLeft: '-10px',
    color: 'white'
  }
}));
export default useStyle;

export const useStyleModal = makeStyles(theme => ({
  modalContent: {
    padding: `25px 0 0`
  }
}));
