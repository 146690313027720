import React from 'react';

import useStyle from './style';

import WelcomeModal from '../../WelcomeModal';
import ModalCustom from '../../ModalCustom';

const AddBusinessModal = props => {
  const mainClass = useStyle();

  return (
    <div className={mainClass.root}>
      <ModalCustom {...props}>
        <WelcomeModal />
      </ModalCustom>
    </div>
  );
};

export default AddBusinessModal;
