import React, { useState, useEffect } from 'react';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import { useTranslation } from 'react-i18next';
import { get } from 'lodash';
import { Typography } from '@material-ui/core';
import Button from '../../../components/Button';
import InputOTP from '../../InputOTP';
import {
  loginWithOTP,
  fetchUserProfile,
  sendLoginOTP
} from 'maua-redux-core/actions/user';
import { actSetLoading } from '../../../store/actions/loading';
import { queryProfileLogin } from '../../../store/query/user';
import { queryLogin } from '../../../store/query/login';

import { toastMessage, TOAST_TYPE } from '../../../utils/toastify-notify';
import { deviceInformation } from '../../../utils/common';
import { loadCredentials } from 'maua-redux-core/actions/user';

import useStyle from './style';
import { saveCredentials } from '../../../shared/services/token';
import arrowBackImg from '../../../assets/img/icons/arrow-back.png';

let phoneNumberForOTP;
function EnterOTP(props) {
  const mainClass = useStyle();
  const { t } = useTranslation();
  const [otpCode, setOTP] = useState('');
  const [resendOTP, setResendOTP] = useState(false);
  const [otpCodeError, setOTPError] = useState(false);
  // const isWhatsapp =
  //   get(props, 'location.search').split('=')[1] === 'whatsapp' || false;

  useEffect(() => {
    if (otpCode?.length !== 6) return;

    loginAPI();
  }, [otpCode]);

  if (props.phoneNumber) {
    phoneNumberForOTP = props.phoneNumber;
  }

  const handleOnchange = value => {
    if (otpCodeError) {
      setOTPError(false);
    }
    setOTP(value);
  };

  const loginAPI = async () => {
    try {
      props.setLoading({ status: true });
      let { loginWithOTP } = await props.loginRequest({
        OTP: otpCode,
        phone: phoneNumberForOTP,
        role: 'vendor',
        device: deviceInformation()
      });
      saveCredentials({ token: loginWithOTP.token });

      await props.onFetchUserProfile();

      props.history.push('/select-business');

      props.setLoading({ status: false });
    } catch (error) {
      props.setLoading({ status: false });

      toastMessage(TOAST_TYPE.ERROR, error.message);
    }
  };

  const sendOTPApi = async () => {
    try {
      props.setLoading({ status: true });
      setOTP('');
      setResendOTP(false);
      await props
        .sendOTPRequest({
          phone: phoneNumberForOTP
          // isWhatsapp
        })
        .then(res => {
          setResendOTP(false);
          props.setLoading({ status: false });
        });
    } catch (error) {
      props.setLoading({ status: false });

      toastMessage(TOAST_TYPE.ERROR, error.message);
    }
  };

  return (
    <div className={mainClass.loginModal}>
      <div className={mainClass.wrappBtnBack}>
        <img
          src={arrowBackImg}
          alt="back"
          className={mainClass.arrowBack}
          onClick={props.handleBackInputPhone}
        />
      </div>
      <Typography variant="h4" className={mainClass.title}>
        {t('login.title.Enter your code')}
      </Typography>
      <div className={mainClass.content}>
        <div className={mainClass.innerContent}>
          <InputOTP
            value={otpCode}
            onChange={handleOnchange}
            hasErrored={otpCodeError}
            numInputs={6}
          />
        </div>
        <button
          className={mainClass.btnResend}
          onClick={sendOTPApi}
          disabled={resendOTP}>
          {t('action.Resend')}
        </button>

        <div className={mainClass.wrappBtn}>
          <Button
            typeBtn="yellow"
            type="button"
            onClick={loginAPI}
            disabled={!(otpCode.length === 6)}>
            {t('action.Next')}
          </Button>
        </div>
      </div>
    </div>
  );
}

const mapDispatchToProps = dispatch => {
  return {
    setLoading: status => dispatch(actSetLoading(status)),
    // sendFirebaseOTPRequest: params => dispatch(sendFirebaseOTP(params, null)),
    // loginFirebaseRequest: params => dispatch(loginFirebase(params, queryLogin)),
    loginRequest: params => dispatch(loginWithOTP(params, queryLogin)),
    loadCredentialsRequest: credentials =>
      dispatch(loadCredentials(credentials)),
    onFetchUserProfile: () =>
      dispatch(fetchUserProfile(null, queryProfileLogin)),
    sendOTPRequest: params => dispatch(sendLoginOTP(params, null))
  };
};

export default compose(
  withRouter,
  connect(
    null,
    mapDispatchToProps
  )
)(EnterOTP);
