import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { withRouter } from 'react-router';
import clsx from 'clsx';
import Box from '@material-ui/core/Box';
import { Typography } from '@material-ui/core';
// import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import Fab from '@material-ui/core/Fab';
import Grid from '@material-ui/core/Grid';
import { Field, Form, Formik } from 'formik';
import { editUserProfile, logout } from 'maua-redux-core/actions/user';
import {
  checkAuthentication,
  getUserProfile
} from 'maua-redux-core/selectors/user';
import { fetchCategories } from 'maua-redux-core/actions/category';
import { DATE_FORMAT } from 'maua-redux-core/utils/format';
import { getAddress } from 'maua-redux-core/selectors/address';
import GoogleMap from '../../components/GoogleMap';
import { profileSchema } from '../../utils/schema';
import { queryProfile } from '../../store/query/user';
import { querystore } from '../../store/query/store';
import { registerStore, editStore } from 'maua-redux-core/actions/store';
import Loading from '../Loading';
import {
  CustomTextField,
  DatePickerField,
  GenderSelectField,
  SelectFieldMutiple
} from '../FormFeild';
import DialogTitle from './DialogTitle';
import pointLocationImg from '../../assets/img/icons/point-location.svg';
import { useTranslation } from 'react-i18next';
import { queryCategories } from '../../store/query/categories';
import { actFetchLocation } from '../../store/actions/location';
import { toastMessage, TOAST_TYPE } from '../../utils/toastify-notify';
import { ensureArray } from 'maua-redux-core/utils/helpers';
import { useStyles } from './style';
import './styleCustom.css';
import { getTimeZone, isEmptyObject } from '../../utils/common';
import { getOpenModalAddBusiness } from '../../store/selectors/modal';
import {
  actToggleModalAddBusiness,
  actToggleModalThankyouAddBusiness
} from '../../store/actions/modal';
import { actToggleNeedFetchVendorGetStore } from '../../store/actions/mystores';
import { resetAddress } from 'maua-redux-core/actions/address';
import arrowBackImg from '../../assets/img/icons/arrow-back.png';
import SelectSingle from '../FieldWithoutFormik/Select';
import TIMEZONE_MAP from '../../shared/constant/timezone';

let setFeildForForm = null;

function WelcomeModal(props) {
  const [yourLocation, setYourLocation] = useState(false);
  const [categoriesList, setCategories] = useState([]);
  const [locationgetFromMap, setLocationGetFromMap] = useState({});
  const classes = useStyles();
  const { t } = useTranslation();

  useEffect(() => {
    props
      .fetchCategories({ limit: 100, mainOnly: true }, queryCategories)
      .then(({ categories }) => setCategories(categories.docs))
      .catch(error => {
        toastMessage(TOAST_TYPE.ERROR, error.message);
      });
  }, []);

  useEffect(() => {
    const { location: locationFromMap } = props.addressFromGetLocation || {};
    if (locationFromMap && setFeildForForm) {
      setLocationGetFromMap(locationFromMap);
    }
    return () => {
      props.resetAddress();
    };
  }, [props.addressFromGetLocation.location]);

  useEffect(() => {
    const { alaNum: alaNumFromMap } = props.addressFromGetLocation || {};
    if (alaNumFromMap && setFeildForForm) {
      setFeildForForm('alaNum', alaNumFromMap);
    }
    return () => {
      props.resetAddress();
    };
  }, [props.addressFromGetLocation.alaNum]);

  useEffect(() => {
    const { village: villageFromMap } = props.addressFromGetLocation || {};
    if (villageFromMap && setFeildForForm) {
      setFeildForForm('village', villageFromMap);
    }
  }, [props.addressFromGetLocation.village]);

  useEffect(() => {
    const { country: countryFromMap } = props.addressFromGetLocation || {};
    if (countryFromMap && setFeildForForm) {
      setFeildForForm('country', countryFromMap);
    }
  }, [props.addressFromGetLocation.country]);

  const genderData = [
    { value: 'male', label: 'Male' },
    { value: 'female', label: 'Female' }
  ];
  const categoriesData =
    categoriesList && categoriesList.length
      ? categoriesList.map(({ _id, name }) => ({ value: _id, label: name }))
      : [];
  // const { userLocation } = props;
  const getYourLocation = () => {
    setYourLocation(true);
  };
  const { address, birthday, firstName, lastName, gender, store, phone } =
    props.profile && isEmptyObject(props.profile) ? props.profile : {};

  const { categories, description, address: addressStore } = store || {};
  const { alaNum, country, village, name } =
    addressStore && isEmptyObject(addressStore) ? addressStore : address || {};
  const cateGoryDefault = ensureArray(categories).map(({ _id }) => {
    let val = categoriesData.find(({ value }) => value === _id);
    return {
      value: _id,
      label: (val && val.label) || 'Something went wrong.'
    };
  });
  const initialValuesForm = {
    name: name || '',
    alaNum: '',
    phone,
    country: '',
    village: '',
    birthday,
    firstName: firstName || '',
    lastName: lastName || '',
    gender,
    timezone: TIMEZONE_MAP.find(({ value }) => value === getTimeZone()),
    description: '',
    categories: null
  };

  const updateProfileAPI = async payload => {
    return props.editUserProfile(payload, queryProfile).then(response => {
      return response;
    });
  };

  const updateStoreAPI = async payload => {
    return props.registerStore(payload, querystore).then(response => {
      toastMessage(TOAST_TYPE.SUCCESS, 'You bussiness submit successfully!');
      return response;
    });
  };

  const handlerUpdateDetail = async (values, actions) => {
    let {
      firstName,
      lastName,
      birthday,
      gender,
      name,
      phone,
      categories,
      description,
      alaNum,
      country,
      village,
      timezone
    } = values;

    if (!alaNum) {
      return alert('You need share Geolocation !');
    }

    actions.setSubmitting(true);

    try {
      let [profileInfo, storeInfo] = await Promise.all([
        updateProfileAPI({ firstName, lastName, birthday, gender }),
        updateStoreAPI({
          name: name,
          categories: categories.map(category => category.value),
          description,
          phone,
          timezone: timezone?.value,
          address: {
            location: {
              type: 'Point',
              coordinates: locationgetFromMap.coordinates
            },
            village,
            country,
            alaNum
          }
        })
      ]);

      // setOpen(false);
      // setOpenThankyou(true);
      if (props.openModalAddBusiness) {
        props.actToggleModalAddBusiness(false);
        props.actToggleNeedFetchVendorGetStore(true);
        props.actToggleModalThankyouAddBusiness(true);
      } else {
        props.history.push('/thankyou-page');
      }
    } catch (error) {
      toastMessage(TOAST_TYPE.ERROR, error.message);
    }
    actions.setSubmitting(false);
  };
  const handleBackPrev = () => {
    props.history.push('/select-business');
  };
  return (
    <React.Fragment>
      <Box mx={8} my={6} className={classes.root}>
        <DialogTitle>
          {!props.openModalAddBusiness ? (
            <div onClick={handleBackPrev} className={classes.wrappBack}>
              <img
                src={arrowBackImg}
                alt="back"
                className={classes.arrowBack}
              />
              <span className={classes.txtBack}>Back </span>
            </div>
          ) : null}
          <Typography className={classes.titlePrimary}>
            {props.openModalAddBusiness
              ? t('business.create.title.Add Business')
              : t('business.create.title.Complete profile')}
          </Typography>
        </DialogTitle>
        <Formik
          enableReinitialize
          initialValues={initialValuesForm}
          validationSchema={profileSchema}
          onSubmit={(values, actions) => handlerUpdateDetail(values, actions)}
          render={({ touched, errors, ...props }) => {
            setFeildForForm = props.setFieldValue;

            const handleChangePhone = e => {
              const regExp = new RegExp('^(\\d)*$', 'g'); //check only number

              const { value, name } = e.target;

              if (!regExp.test(value)) return;

              setFeildForForm(name, value);
            };
            return (
              <Form>
                <DialogContent>
                  <Typography variant="h5" className={classes.subTitle}>
                    {t('business.create.title.Personal info')}
                  </Typography>

                  <Grid>
                    <Grid container item spacing={1}>
                      <Grid item xs={6}>
                        <Field
                          name="firstName"
                          margin="dense"
                          variant="outlined"
                          placeholder={t('business.create.label.First Name')}
                          label={t('business.create.label.First Name')}
                          required
                          component={CustomTextField}
                        />
                      </Grid>
                      <Grid item xs={6}>
                        <Field
                          name="lastName"
                          margin="dense"
                          variant="outlined"
                          placeholder={t('business.create.label.Last Name')}
                          label={t('business.create.label.Last Name')}
                          required
                          component={CustomTextField}
                        />
                      </Grid>
                    </Grid>
                    <Grid item>
                      <Field
                        name="birthday"
                        placeholder={t('business.create.label.Day of Birth')}
                        format={DATE_FORMAT}
                        label={t('business.create.label.Day of Birth')}
                        variant="outlined"
                        required
                        component={DatePickerField}
                        onChange={date =>
                          props.setFieldValue(
                            'birthday',
                            date ? date.toISOString() : date
                          )
                        }
                      />
                    </Grid>
                    <Grid item>
                      <Field
                        name="gender"
                        margin="dense"
                        variant="outlined"
                        placeholder={t('business.create.label.Gender')}
                        label={t('business.create.label.Gender')}
                        required
                        ranges={genderData}
                        component={GenderSelectField}
                      />
                    </Grid>
                  </Grid>

                  <Typography
                    variant="h5"
                    className={clsx(classes.subTitle, classes.mrT30)}>
                    {t('business.create.title.Business info')}
                  </Typography>

                  <Grid>
                    <Grid item>
                      <Field
                        name="name"
                        margin="dense"
                        variant="outlined"
                        placeholder={t('business.create.label.Business name')}
                        label={t('business.create.label.Business name')}
                        required
                        fullWidth
                        component={CustomTextField}
                      />
                    </Grid>

                    <Grid item>
                      <Field
                        name="phone"
                        margin="dense"
                        variant="outlined"
                        placeholder={t('business.create.label.Business phone')}
                        label={t('business.create.label.Business phone')}
                        required
                        onChange={handleChangePhone}
                        fullWidth
                        component={CustomTextField}
                      />
                    </Grid>

                    <Grid item>
                      <Field
                        name="categories"
                        margin="dense"
                        variant="outlined"
                        placeholder=""
                        label={t('business.create.label.Categories')}
                        className={classes.categoriesMenu}
                        required
                        suggestions={categoriesData}
                        setFieldValue={props.setFieldValue}
                        component={SelectFieldMutiple}
                      />
                    </Grid>

                    <Grid item>
                      <SelectSingle
                        className={classes.timezone}
                        label={t('mystore.editStore.title.Timezone')}
                        name="timezone"
                        value={props.values.timezone}
                        options={TIMEZONE_MAP}
                        zIndex={1}
                        error={
                          props?.touched?.timezone && props?.errors?.timezone
                        }
                        onBlur={() => props.setFieldTouched('timezone', true)}
                        onChange={e => setFeildForForm('timezone', e)}
                      />
                    </Grid>
                  </Grid>
                  <Grid>
                    {yourLocation && (
                      <Grid item className={classes.wrappMap}>
                        <GoogleMap size={400} isPinToMap={true} />
                      </Grid>
                    )}
                    <Grid item>
                      <div className={classes.yourShopLocation}>
                        <div className={classes.locationTitle}>
                          <h6>
                            {t('business.create.title.Get your shop location')}
                          </h6>
                          <img
                            src={pointLocationImg}
                            alt="Get your shop location"
                            onClick={getYourLocation}
                          />
                        </div>
                        <div className={classes.locationList}>
                          <div className={classes.locationItem}>
                            <span className={classes.locationLabel}>
                              {t('business.create.place.label.Ala Number')}
                            </span>
                            <span className={classes.locationValue}>
                              <Field
                                name="alaNum"
                                margin="dense"
                                className="input-custom-location"
                                placeholder={t(
                                  'business.create.place.label.Ala Number'
                                )}
                                required
                                component={CustomTextField}
                                disabled
                              />
                            </span>
                          </div>
                          <div className={classes.locationItem}>
                            <span className={classes.locationLabel}>
                              {t('business.create.place.label.Village')}
                            </span>
                            <span className={classes.locationValue}>
                              <Field
                                name="village"
                                margin="dense"
                                className="input-custom-location"
                                placeholder={t(
                                  'business.create.place.label.Village'
                                )}
                                required
                                component={CustomTextField}
                                disabled
                              />
                            </span>
                          </div>
                          <div className={classes.locationItem}>
                            <span className={classes.locationLabel}>
                              {t('business.create.place.label.Country')}
                            </span>
                            <span className={classes.locationValue}>
                              <Field
                                name="country"
                                margin="dense"
                                className="input-custom-location"
                                placeholder={t(
                                  'business.create.place.label.Country'
                                )}
                                required
                                component={CustomTextField}
                                disabled
                              />
                            </span>
                          </div>
                        </div>
                      </div>
                    </Grid>
                    <Grid item>
                      <Field
                        name="description"
                        margin="dense"
                        variant="outlined"
                        placeholder={t('business.create.label.Introduction')}
                        label={t('business.create.label.Introduction')}
                        multiline
                        rows="4"
                        fullWidth
                        component={CustomTextField}
                      />
                    </Grid>
                  </Grid>
                </DialogContent>
                <DialogActions className={classes.actions} disableSpacing>
                  <Fab
                    type="submit"
                    color="primary"
                    size="medium"
                    variant="extended"
                    aria-label="Done"
                    style={{ width: '100%' }}
                    disabled={!props.isValid || props.isSubmitting}
                    onClick={props.submitForm}>
                    {t('action.Done')}
                  </Fab>
                </DialogActions>
                <DialogContent>
                  <Typography variant="body1" className={classes.termCondition}>
                    {t('business.create.termCondion.joining')}
                    <b> {t('business.create.termCondion.label.term')}</b>
                  </Typography>
                </DialogContent>
              </Form>
            );
          }}
        />
      </Box>
      <Loading action="user/editUserProfile" />
    </React.Fragment>
  );
}

export default compose(
  withRouter,
  connect(
    state => ({
      profile: getUserProfile(state),
      loggedIn: checkAuthentication(state),
      // userLocation: getLocation(state),
      addressFromGetLocation: getAddress(state),
      openModalAddBusiness: getOpenModalAddBusiness(state)
    }),
    {
      editUserProfile,
      logout,
      fetchCategories,
      actFetchLocation,
      // actToggleModalLogin,
      registerStore,
      editStore,
      actToggleModalAddBusiness,
      actToggleModalThankyouAddBusiness,
      actToggleNeedFetchVendorGetStore,
      resetAddress
    }
  )
)(WelcomeModal);
