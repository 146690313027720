import React, { useState, useEffect, useRef, useMemo } from 'react';
import { connect } from 'react-redux';
import { isEmpty, get } from 'lodash';
import { messages, sendMessage } from 'maua-redux-core/actions/message';
import { getUserProfile } from 'maua-redux-core/selectors/user';
import {
  queryMessages,
  querySendMessage,
  queryMessengerChannel
} from '../../../store/query/messenger';
import StoreIcon from '@material-ui/icons/Store';
import CustomCard from '../../Card';
import Typography from '@material-ui/core/Typography';
import List from '@material-ui/core/List';
import Message from './Message';
import useStyles from './style';
import Avatar from '@material-ui/core/Avatar';
import Box from '@material-ui/core/Box';
import { ensureArray } from '../../../utils/common';
import addImg from '../../../assets/img/icons/addImg.png';
import { getOrderIdMessenger } from '../../../store/selectors/messengerChat';
import sendMessegener from '../../../assets/img/icons/sendMessenger.png';
import { initMessageChannel } from 'maua-redux-core/actions/messageChannel';
import { toastMessage, TOAST_TYPE } from '../../../utils/toastify-notify';
import SendMessage from './SendMessage';
import CardHeader from '@material-ui/core/CardHeader';
import CardContent from '@material-ui/core/CardContent';
import CardActions from '@material-ui/core/CardActions';
import LoadMore from '../../LoadMore';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import LoadMoreComponent from '../../LoadMoreComponent';
import LoadingComponent from '../../Loading/LoadingComponent';
import { actSetLoading } from '../../../store/actions/loading';
import arrowBackImg from '../../../assets/img/icons/arrow-back.png';
import { readMessages } from 'maua-redux-core/actions/messageChannel';
import PersonIcon from '@material-ui/icons/Person';
import { useTranslation } from 'react-i18next';

const ChatBox = props => {
  const { orderIdMessenger, dispatchReadMessage, channel } = props;

  const classes = useStyles();
  const { t } = useTranslation();

  const [isLoadMore, setIsLoadMore] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [loadingPicture, setLoadingPicture] = useState(false);
  const [scrollPosition, setScrollPosition] = useState(0);
  const [dataMessages, setDataMessages] = useState({
    docs: [],
    page: 1,
    pages: 1,
    limit: 20
  });

  const image = get(channel, 'message.sender.avatar');

  const getMoreMessages = async () => {
    if (isLoading || isLoadMore) return;
    try {
      const currentPage = dataMessages.page;
      if (currentPage >= dataMessages.pages) return;

      setIsLoadMore(true);
      let payload = {
        channel: channel._id,
        page: currentPage + 1,
        limit: dataMessages.limit || 20
      };

      const resp = await props.getListMessagesRequest(payload);

      const filler = filterMessage(resp.docs, dataMessages.docs);

      setDataMessages(preData => ({
        ...preData,
        docs: [...filler.reverse(), ...preData.docs],
        page: resp.page || preData.page,
        pages: resp.pages || preData.pages,
        limit: resp.limit || preData.limit
      }));

      setScrollPosition(filler.length);
    } catch (error) {
      toastMessage(TOAST_TYPE.ERROR, error.message);
    } finally {
      setIsLoadMore(false);
    }
  };

  const getMessages = async () => {
    if (isLoading || isLoadMore) return;

    try {
      setIsLoading(true);
      const resp = await props.getListMessagesRequest({
        channel: channel._id,
        page: 1,
        limit: dataMessages.limit
      });

      setDataMessages(preData => ({
        docs: [...resp.docs.reverse()],
        page: resp.page || preData.page,
        pages: resp.pages || preData.pages,
        limit: resp.limit || preData.limit
      }));

      setScrollPosition(resp?.docs?.length - 1);

      dispatchReadMessage(channel._id);
    } catch (error) {
      toastMessage(TOAST_TYPE.ERROR, error.message);
    } finally {
      setIsLoading(false);
    }
  };

  const filterMessage = (docs, preMessage) => {
    const filler = docs.reduce((result, doc) => {
      let isHas = preMessage.findIndex(mess => mess._id === doc._id);
      return isHas === -1 ? [...result, doc] : result;
    }, []);
    return filler;
  };

  const checkActiveChannel = () => {
    if (!['completed', 'failed'].includes(channel?.order?.status)) return true;

    const lastUpdatedAt = new Date(channel?.order?.updatedAt);

    const now = new Date();

    const validTime = 24 * 3600 * 1000 * 7;

    return now - lastUpdatedAt <= validTime;
  };

  const getNewMess = async () => {
    if (!channel._id) return;

    try {
      let payload = {
        channel: channel._id,
        page: 1,
        limit: 10
      };
      let docsLengthCrt = dataMessages?.docs?.length;
      const { docs } = await props.getListMessagesRequest(payload);
      const preMessage = dataMessages?.docs?.slice(-10);

      const filler = filterMessage(docs, preMessage);
      if (!isEmpty(filler)) {
        setDataMessages(prevData => ({
          ...prevData,
          docs: [...prevData.docs, ...filler.reverse()]
        }));
        setScrollPosition(docsLengthCrt + filler.length - 1);
      }
    } catch (error) {
      toastMessage(TOAST_TYPE.ERROR, error.message);
    }
  };

  const renderMessages = useMemo(() => {
    return dataMessages?.docs?.map((mess, i) => (
      <Message profile={props.profile} key={mess._id} message={mess} />
    ));
  }, [dataMessages?.docs]);

  useEffect(() => {
    if (!isEmpty(channel)) {
      getMessages();
    }
  }, [channel]);

  useEffect(() => {
    let timeInterval = null;
    if (timeInterval) clearInterval(timeInterval);
    timeInterval = setInterval(() => {
      getNewMess();
    }, 2000);
    return () => clearInterval(timeInterval);
  });

  return (
    <CustomCard classRoot={classes.root}>
      <CardHeader
        avatar={
          <Box display="flex" alignItems="center">
            <img
              src={arrowBackImg}
              alt="back List"
              className={classes.arrowBack}
              onClick={props.handleBackUserList}
            />
            {image ? (
              <Avatar src={image} alt={channel?.message?.sender?.name} />
            ) : (
              <Avatar>
                <PersonIcon />
              </Avatar>
            )}
          </Box>
        }
        action={
          <IconButton onClick={e => props.handleClose()} aria-label="settings">
            <CloseIcon fontSize="small" color="primary" />
          </IconButton>
        }
        className={classes.header}
        title={
          <Box>
            <Typography variant="h5" noWrap>
              {channel?.name}
            </Typography>
            <Typography className={classes.nameUserChat} variant="h5" noWrap>
              {channel?.message?.sender?.name}
            </Typography>
          </Box>
        }
      />

      <LoadMoreComponent
        handleLoadMore={getMoreMessages}
        outsideclass={classes}
        loadFollowTop={true}
        tolPosition={scrollPosition}>
        {renderMessages}
      </LoadMoreComponent>
      <LoadingComponent show={loadingPicture} />

      {checkActiveChannel() ? (
        <CardActions style={{ padding: '8px 16px' }}>
          <SendMessage
            datalength={dataMessages.docs.length}
            loading={props.loading}
            setLoading={props.setLoading}
            setDataMessages={setDataMessages}
            channelID={channel._id}
            setLoadingPicture={setLoadingPicture}
            setScrollPosition={setScrollPosition}
          />
        </CardActions>
      ) : (
        <Typography className={classes.infoLocked} variant="subtitle1">
          {t(
            'chatbox.info.msg.Conversation is closed after 7 days since the order has completed/ failed.'
          )}
        </Typography>
      )}
    </CustomCard>
  );
};

const mapStateToProps = state => {
  return {
    loading: state.loading,
    profile: getUserProfile(state),
    orderIdMessenger: getOrderIdMessenger(state)
  };
};

const mapDispatchToProps = dispatch => {
  return {
    initMessageChannelRequest: params =>
      dispatch(initMessageChannel(params, queryMessengerChannel)),
    getListMessagesRequest: params => dispatch(messages(params, queryMessages)),
    sendMessegenerRequest: params =>
      dispatch(sendMessage(params, querySendMessage)),
    dispatchReadMessage: _idChannel =>
      dispatch(readMessages({ channel: _idChannel })),

    setLoading: params => dispatch(actSetLoading(params))
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ChatBox);
