import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { useTranslation } from 'react-i18next';

import {
  Box,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  FormControlLabel,
  Typography
} from '@material-ui/core';
import Button from '../../../components/Button';

import { getUserProfile } from 'maua-redux-core/selectors/user';

import useStyles from './style';
import { getLoadingStatus } from '../../../store/selectors/loading';
import { editStore } from 'maua-redux-core/actions/store';
import { queryStoreInMystore } from '../../../store/query/store';
import { getTimeZone } from '../../../utils/common';
import { toastMessage, TOAST_TYPE } from '../../../utils/toastify-notify';
import { theme } from '../../../shared/theme';

export const SyncTimeZone = props => {
  const { profile, loading, handleEditStoreRequest } = props;

  const classes = useStyles();
  const { t } = useTranslation();

  const currentTimezone = getTimeZone();

  const isCancledSyncLocation = JSON.parse(
    localStorage.getItem('canceledSyncTimezone')
  );
  const autoSync = JSON.parse(localStorage.getItem('autoSyncTimezone'));

  const [status, setStatus] = useState({
    openDialog: true,
    dontAskAgain: false
  });

  const isOpenDialog =
    !loading &&
    !autoSync &&
    status.openDialog &&
    profile?.store?._id &&
    currentTimezone !== profile?.store?.timezone;

  useEffect(() => {
    setStatus({
      openDialog: !Boolean(isCancledSyncLocation),
      dontAskAgain: false
    });

    profile?.store?._id &&
      autoSync &&
      currentTimezone !== profile?.store?.timezone &&
      handleSyncTimeZone();
  }, [profile]);

  const handleClose = () => {
    setStatus(prev => ({ ...prev, openDialog: false }));
    status.dontAskAgain && localStorage.setItem('canceledSyncTimezone', true);
  };

  const handleSyncTimeZone = async () => {
    try {
      await handleEditStoreRequest({
        _id: profile?.store?._id,
        timezone: currentTimezone
      });
      setStatus(prev => ({ ...prev, openDialog: false }));
      status.dontAskAgain && localStorage.setItem('autoSyncTimezone', true);
      toastMessage(
        TOAST_TYPE.SUCCESS,
        t('toastMessage.success.content.timezoneSuccess')
      );
    } catch (error) {
      toastMessage(TOAST_TYPE.ERROR, error.message);
    }
  };

  return (
    <Dialog
      open={isOpenDialog}
      onClose={handleClose}
      className={classes.rootSyncLocaltion}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description">
      <Typography className={classes.title} variant="h6">
        {t('business.timezone.title.New timezone')}!
      </Typography>
      <DialogContent>
        <Typography variant="subtitle2" id="alert-dialog-description">
          {t('business.timezone.content')}
        </Typography>
        <FormControlLabel
          control={
            <Checkbox
              color="primary"
              style={{
                fontSize: 10
              }}
              checked={status.dontAskAgain}
              onChange={e =>
                setStatus(prev => ({
                  ...prev,
                  dontAskAgain: !prev.dontAskAgain
                }))
              }
              name="ask again"
            />
          }
          label={t('business.timezone.label.Don"t ask me again')}
        />
      </DialogContent>
      <DialogActions>
        <Button
          className={[classes.btn, classes.typeDefault].join(' ')}
          typeBtn="gray"
          onClick={handleClose}
          color="primary">
          {t('action.No')}
        </Button>
        <Button
          className={classes.btn}
          typeBtn="yellow"
          onClick={handleSyncTimeZone}
          color="primary">
          {t('action.Yes')}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

SyncTimeZone.propTypes = {
  profile: PropTypes.object,
  loading: PropTypes.bool,
  handleEditStoreRequest: PropTypes.func
};

export default connect(
  state => ({
    profile: getUserProfile(state),
    loading: getLoadingStatus(state)
  }),

  dispatch => ({
    handleEditStoreRequest: params =>
      dispatch(editStore(params, queryStoreInMystore))
  })
)(SyncTimeZone);
