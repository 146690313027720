import { makeStyles } from '@material-ui/core';

export const useStyle = makeStyles(theme => ({
  root: {
    position: 'relative',
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    '& .slick-list': {
      width: '100%',
      overflow: 'hidden'
    },
    '& .slick-track': {
      width: '100%',
      display: 'flex',
      alignItems: 'center'
    },
    '& .slick-arrow.slick-prev': {
      position: 'absolute',
      left: 0,
      zIndex: 1,
      borderRadius: 200,
      background: theme.palette.color.white,
      border: `1px solid ${theme.palette.primary.main}`,
      cursor: 'pointer'
    },
    '& .slick-arrow.slick-next': {
      position: 'absolute',
      right: 0,
      zIndex: 1,
      borderRadius: 200,
      background: theme.palette.color.white,
      border: `1px solid ${theme.palette.primary.main}`,
      cursor: 'pointer'
    },
    '& .slick-disabled': {
      pointerEvents: 'none',
      opacity: 0.5
    }
  }
}));
