import React from 'react';
import { FormControl, TextField, MenuItem } from '@material-ui/core';
import { useStyleTextFeild } from './style';

const SelectCustom = props => {
  const { width } = props;
  const mainClass = useStyleTextFeild();

  return (
    <FormControl style={{ width }}>
      <TextField
        select
        margin="dense"
        variant="outlined"
        label={props.label}
        value={props.value || ''}
        onChange={props.onChange}
        style={{ margin: 0 }}
        InputProps={{
          classes: {
            root: mainClass.rootSelect,
            notchedOutline: mainClass.notchedOutline,
            input: mainClass.inputSelect,
            focused: mainClass.notchedOutlineFocus
          }
        }}>
        {props.options.map(option => (
          <MenuItem
            key={option.value}
            value={option.value}
            className="select-menu-item">
            {option.label}
          </MenuItem>
        ))}
      </TextField>
    </FormControl>
  );
};

SelectCustom.defaultProps = {
  options: [],
  value: '',
  width: 150,
  label: '',
  onChange: () => {}
};

export default SelectCustom;
