import { createSelector } from 'redux-starter-kit';
import { modalSlice } from '../reducers/modal';

export const getOpenLogin = createSelector(
  [modalSlice.selectors.getModal],
  modal => modal.openLogin
);

export const getOpenChangeRegion = createSelector(
  [modalSlice.selectors.getModal],
  modal => modal.openChangeRegion
);

export const getOpenModalPickupConfirm = createSelector(
  [modalSlice.selectors.getModal],
  modal => modal.openPickupConfirm
);

export const getOpenModalReasonOrder = createSelector(
  [modalSlice.selectors.getModal],
  modal => modal.openModalReason
);

export const getOpenModalChoiseDelivery = createSelector(
  [modalSlice.selectors.getModal],
  modal => modal.openModalChoiseDelivery
);

export const getOpenModalAddProduct = createSelector(
  [modalSlice.selectors.getModal],
  modal => modal.openModalAddProduct
);

export const getOpenModalEditProduct = createSelector(
  [modalSlice.selectors.getModal],
  modal => modal.openModalEditProdcut
);

export const getOpenModalEditYouStore = createSelector(
  [modalSlice.selectors.getModal],
  modal => modal.openModalEditYourStore
);

export const getOpenModalEditUser = createSelector(
  [modalSlice.selectors.getModal],
  modal => modal.openModalEditUser
);

export const getOpenModalSwitchBusiness = createSelector(
  [modalSlice.selectors.getModal],
  modal => modal.openModalSwitchBusiness
);

export const getOpenModalAddBusiness = createSelector(
  [modalSlice.selectors.getModal],
  modal => modal.openModalAddBusiness
);

export const getOpenModalThankyouAddBusiness = createSelector(
  [modalSlice.selectors.getModal],
  modal => modal.openModalThankyouAddBusiness
);

export const getOpenModalEnterStaffPhone = createSelector(
  [modalSlice.selectors.getModal],
  modal => modal.openModalEnterStaffPhone
);

export const getOpenModalWaitingStaffPhone = createSelector(
  [modalSlice.selectors.getModal],
  modal => modal.openModalWaitingStaffPhone
);

export const getOpenModalStaffNotFound = createSelector(
  [modalSlice.selectors.getModal],
  modal => modal.openModalStaffNotFound
);

export const getOpenModalFoundStaff = createSelector(
  [modalSlice.selectors.getModal],
  modal => modal.openModalFoundStaff
);
