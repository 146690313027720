import { makeStyles } from '@material-ui/core';
import { primaryFont } from '../../../shared/theme';

const useStyle = makeStyles(theme => ({
  chatBox: {},
  headChat: {
    display: 'flex',
    flexDirection: 'row',
    // justifyContent: 'space-between',
    alignItems: 'center',
    borderBottom: `1px solid #F2F2F2`,
    padding: '0 24px 24px'
  },
  arrowBack: {
    width: 16,
    height: 16,
    cursor: 'pointer',
    transition: 'all .35s',
    '&:hover': {
      transform: 'scale(1.1)'
    }
  },
  userItem: {
    display: 'flex',
    flexDirection: 'row',
    paddingLeft: 22,
    flex: 3
  },
  rightControll: {
    display: 'flex',
    flexDirection: 'row'
  },
  closeBtn: {
    top: 'auto',
    right: 'auto',
    position: 'static',
    color: theme.palette.color.orange
  },
  avatarUser: {
    display: 'flex',
    width: 32,
    height: 32,
    borderRadius: '50%'
  },
  infoUser: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    paddingLeft: 15
  },
  userName: {
    margin: 0,
    fontSize: 16,
    color: theme.palette.color.black1,
    fontFamily: primaryFont
  },
  userDesc: {
    margin: 0,
    fontSize: 12,
    color: theme.palette.color.gray3,
    fontFamily: primaryFont,
    marginTop: 5
  },
  // rootLoadMore: {
  //   maxHeight: 'calc(100vh - 135px)',
  //   overflowY: 'auto',
  //   paddingBottom: 15,
  //   overflowX: 'hidden'
  // },
  rootLoadMore: {
    position: 'relative',
    minHeight: 320,
    maxHeight: 325,
    paddingBottom: 5,
    overflowX: 'hidden',
    overflowY: 'auto',
    padding: '0 24px',
    '&::-webkit-scrollbar': {
      width: 4
    },

    /* Track */
    '&::-webkit-scrollbar-track': {
      '-webkitBoxShadow': `inset 0 0 6px rgba(0,0,0,0.1)`,
      boxShadow: `inset 0 0 6px rgba(0,0,0,0.1)`,
      '-webkitBorderRadius': 10,
      borderRadius: 10
    },

    /* Handle */
    '&::-webkit-scrollbar-thumb': {
      '-webkitBorderRadius': 10,
      borderRadius: 10,
      backgroundColor: '#f5f5f5',
      '-webkitBoxShadow': `inset 0 0 6px rgba(0,0,0,0.3)`,
      boxShadow: `inset 0 0 6px rgba(0,0,0,0.3)`
    },
    '&::-webkitScrollbarThumb:window-inactive': {
      backgroundColor: '#f7f7f7'
    }
  },
  innerChat: {
    paddingRight: 2
  },
  footerChat: {
    padding: '0 24px 24px'
  },
  wrappInputChat: {
    position: 'relative'
  },
  inputChat: {
    display: 'block',
    width: '100%',
    backgroundColor: theme.palette.color.white,
    border: `1px solid #F2F2F2`,
    borderRadius: 30,
    padding: '16px 24px',
    fontSize: 13,
    fontFamily: primaryFont,
    outline: 0
  },
  groupControll: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    position: 'absolute',
    right: 10,
    top: '50%',
    transform: 'translateY(-50%)',
    paddingRight: 10
  },
  btnUpload: {
    position: 'relative',
    backgroundColor: 'transparent',
    border: 'none',
    '& img': {
      position: 'relative',
      display: 'block',
      maxWidth: 22,
      maxHeight: 20,
      zIndex: -1
    },
    padding: 0,
    marginRight: 7,
    transition: 'all .35s',
    '&:hover': {
      transform: 'scale(1.1)'
    },
    outline: 0
  },
  viewInputGallery: {
    position: 'absolute',
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
    opacity: 0,
    fontSize: '10px',
    zIndex: 1,
    cursor: 'pointer'
  },
  btnSendMess: {
    backgroundColor: 'transparent',
    border: 'none',
    '& img': {
      display: 'block',
      maxWidth: 22,
      maxHeight: 22
    },
    padding: 0,
    marginLeft: 7,
    cursor: 'pointer',
    transition: 'all .35s',
    '&:hover': {
      transform: 'scale(1.1)'
    },
    outline: 0
  },
  itemChat: {
    paddingTop: 5
  },
  currentUser: {
    display: 'flex',
    justifyContent: 'flex-end'
  },
  userItemChat: {
    display: 'flex',
    flexDirection: 'row'
  },
  avatarUserChat: {
    width: 20,
    height: 20,
    borderRadius: '50%'
  },
  infoUserChat: {
    // flex: 3,
    paddingLeft: 12
  },
  userNameChat: {
    margin: 0,
    fontSize: 12,
    color: theme.palette.color.gray1,
    fontFamily: primaryFont,
    marginBottom: 5
  },
  userDescChat: {
    margin: 0,
    fontSize: 14,
    color: theme.palette.color.black2,
    fontFamily: primaryFont
  },
  userDescChatAns: {
    padding: '10px 19px',
    backgroundColor: 'rgba(247, 247, 247, 0.6)',
    borderTopRightRadius: 20,
    borderBottomLeftRadius: 20,
    borderBottomRightRadius: 20
  },
  userDescChatCur: {
    padding: '10px 19px',
    backgroundColor: 'rgba(255, 207, 98, .2)',
    borderTopRightRadius: 20,
    borderBottomLeftRadius: 20,
    borderTopLeftRadius: 20
  },
  wrappMedia: {
    display: 'flex',
    flexWrap: 'wrap'
  },
  media: {
    maxWidth: '100%',
    width: 'calc(100% / 3)',
    padding: 3,
    borderRadius: 5,
    display: 'block'
  },
  col2: {
    width: '50%'
  },
  col1: {
    width: '100%'
  },
  colDefaul: {
    width: 'calc(100% / 3)'
  }
}));

export default useStyle;
