import { makeStyles } from '@material-ui/core';

const useStyle = makeStyles(theme => ({
  btn: {
    width: '100%',
    minWidth: 112,
    height: 56,
    borderRadius: 200,
    cursor: 'pointer',
    outline: 0,
    fontWeight: 600,
    fontFamily: `'Muli', sans-serif !important`,
    transition: 'all .35s'
  },
  whiteBtn: {
    border: '1px solid #E38D03',
    backgroundColor: theme.palette.color.white,
    color: theme.palette.color.black1,
    // '&:hover': {
    //   border: `1px solid #E38D03`,
    //   color: theme.palette.color.orange
    // }
    '&:disabled': {
      cursor: 'default',
      border: `1px solid transparent`,
      backgroundColor: theme.palette.color.white3,
      color: theme.palette.color.white
    }
  },
  failBtn: {
    border: '1px solid #BE0000',
    backgroundColor: theme.palette.color.white,
    color: theme.palette.color.red
    // '&:hover': {
    //   border: `1px solid #BE0000`,
    //   color: theme.palette.color.red
    // }
  },
  yellowBtn: {
    border: '1px solid #E38D03',
    backgroundColor: theme.palette.color.orange,
    color: theme.palette.color.white,
    // '&:hover': {
    //   border: `1px solid #E38D03`,
    //   backgroundColor: theme.palette.color.white,
    //   color: theme.palette.color.orange
    // },
    '&:disabled': {
      cursor: 'default',
      border: `1px solid transparent`,
      backgroundColor: theme.palette.color.white3,
      color: theme.palette.color.white
    }
  },
  greenBtn: {
    border: '1px solid #3A8E07',
    backgroundColor: theme.palette.color.green,
    color: theme.palette.color.white,
    // '&:hover': {
    //   border: `1px solid #3A8E07`,
    //   backgroundColor: theme.palette.color.white,
    //   color: theme.palette.color.green
    // },
    '&:disabled': {
      cursor: 'default',
      border: `1px solid transparent`,
      backgroundColor: theme.palette.color.white3,
      color: theme.palette.color.white
    }
  },
  grayBtn: {
    border: '1px solid #F2F2F2',
    backgroundColor: theme.palette.color.white3,
    color: theme.palette.color.gray
    // '&:hover': {
    //   border: `1px solid ${theme.palette.color.orange}`,
    //   backgroundColor: theme.palette.color.white,
    //   color: theme.palette.color.orange
    // }
  }
}));

export default useStyle;
