import { makeStyles } from '@material-ui/core';
import { primaryFont, secondaryFont } from '../../../shared/theme';
import { isEmpty } from 'lodash';

const useStyle = makeStyles(theme => ({
  rootTextField: {
    width: props => props.width,

    '& label': {
      fontSize: 14
    },
    '& .MuiInputLabel-outlined': {
      transform: 'translate(14px, 14px) scale(1)'
    },
    '& .MuiInputLabel-outlined.MuiInputLabel-marginDense': {
      transform: 'translate(14px, 14px) scale(0.75)'
    },
    '& .MuiInputLabel-outlined.MuiInputLabel-shrink': {
      transform: 'translate(14px, -6px) scale(0.75)'
    },
    '& .MuiOutlinedInput-input': {
      padding: '12px 24px'
    }
  },

  root: {
    '&:hover fieldset': {
      border: `1px solid ${theme.palette.color.gray} !important`
    },
    '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
      border: '1px solid #E38D03 !important'
    },
    '&.MuiInput-underline:hover:not(.Mui-disabled):before': {
      borderBottom: `1px solid rgba(0, 0, 0, 0.42)`
    },
    '&.MuiInput-underline:after': {
      borderBottom: `1px solid #E38D03 !important`
    },

    '& label.Mui-focused': {
      color: 'orange'
    },
    '& .MuiFormLabel-root': {
      color: 'blue'
    },
    '& > lable.MuiInputLabel-root': {
      fontSize: '12px'
    }
  },
  notchedOutline: {
    border: `1px solid #ccc`,
    outline: 0
  },
  input: {
    border: 'none',
    '&:focus': {
      backgroundColor: 'transparent'
    },
    '&::placeholder': {
      fontSize: '14px'
    }
  },
  notchedOutlineFocus: {
    borderWidth: 1
  },
  formControl: {
    fontSize: 12
  }
}));

export default useStyle;
