import { makeStyles } from '@material-ui/core';
import { primaryFont, secondaryFont } from '../../../shared/theme';

export const useStyle = makeStyles(theme => ({
  root: {},
  title: {
    borderBottom: `1px solid #E6E6E6`,
    padding: `0 40px 15px`
  },
  titlePrimary: {
    padding: `0 30px`,
    marginTop: 70,
    marginBottom: 30,
    fontFamily: secondaryFont,
    fontWeight: 600,
    color: theme.palette.color.black,
    textAlign: 'center'
  },
  staffITem: {
    position: 'relative',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-beetween',
    alignItems: 'center',
    margin: '0 20px',
    borderRadius: 12,
    backgroundColor: theme.palette.color.white2,
    padding: '22px 60px 22px 30px',
    transition: 'all .35s',
    cursor: 'pointer',
    '&:hover': {
      border: `1px solid ${theme.palette.color.orange}`
    },
    '&:hover img': {
      // opacity: 1
    }
  },
  staffRole: {
    position: 'relative',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    margin: '20px 20px',
    borderRadius: 12,
    backgroundColor: theme.palette.color.white2,
    padding: '22px 30px 22px 30px',
    transition: 'all .35s',
    cursor: 'pointer'
  },
  staffItemDefault: {
    border: `1px solid transparent`
  },
  staffItemActive: {
    border: `1px solid ${theme.palette.color.orange}`
  },
  staffPicture: {
    width: 80,
    height: 80,
    borderRadius: '50%',
    display: 'block'
  },
  selectIconDefault: {
    opacity: 0
  },
  selectIconActive: {
    opacity: 1
  },
  detail: {
    paddingLeft: 33
  },
  name: {
    color: theme.palette.color.black
  },
  selectIcon: {
    width: 29,
    height: 22,
    position: 'absolute',
    top: '50%',
    right: 30,
    transform: `translateY(-50%)`
  },
  typePerson: {
    color: theme.palette.color.black2,
    fontFamily: primaryFont,
    fontSize: 18,
    marginTop: 5,
    marginBottom: 0
  },
  wrappBtn: {
    position: 'absolute',
    bottom: 0,
    left: 0,
    right: 0,
    padding: '20px 40px'
  },
  useAnotherNumber: {
    fontSize: 16,
    color: theme.palette.color.orange,
    fontFamily: secondaryFont,
    textAlign: 'center',
    margin: 0,
    padding: 25,
    cursor: 'pointer',
    transition: 'all .35s',
    '&:hover': {
      textShadow: `1px 1px #E38D03`
    }
  }
}));
