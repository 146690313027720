import React, { useState, useRef } from 'react';
import DialogTitle from '@material-ui/core/DialogTitle';
import Box from '@material-ui/core/Box';
import Divider from '@material-ui/core/Divider';
import Paper from '@material-ui/core/Paper';
import Fade from '@material-ui/core/Fade';
import ExportIcon from '../../assets/img/export-icon.svg';
import Typography from '@material-ui/core/Typography';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import DialogModal from '../DialogModal';
import Item from './Item';
import { cloneDeep } from 'lodash';
import { onClickOutside } from '../../utils/common';
import { useTranslation } from 'react-i18next';

import useStyles from './style';

const ExportModal = props => {
  const classes = useStyles();
  const { t } = useTranslation();
  const [open, setOpen] = useState(false);
  const rootRef = useRef();
  const className =
    props.type === 'primary' ? classes.typePrimary : classes.typeDefault;
  onClickOutside(rootRef, () => setOpen(false));
  const [optionFile, setOptionFile] = useState(
    cloneDeep(props.optionFile) || []
  );
  const { title, style } = props;

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleExportFile = async () => {
    let filter = optionFile.reduce(
      (result, el, idx) => (el.status ? [...result, el.name] : result),
      []
    );
    await props.handleExport(filter);
    setOpen(false);
  };

  return (
    <Box style={style}>
      <Box className={classes.btnExport + ' ' + className} onClick={handleOpen}>
        <img
          src={ExportIcon}
          alt="export"
          style={{ marginRight: 8, width: 20 }}
        />
        <Box>{t(`action.${props.title}`)}</Box>
      </Box>

      <DialogModal
        open={open}
        onClose={handleClose}
        ContentComponent={() => (
          <Box className={classes.contentExport}>
            <Typography variant="h6" component="p">
              {t(`product.export.title.Which parts that you want to export`)}?
            </Typography>
            {optionFile.map((op, i) => (
              <Item
                key={i}
                option={op}
                setOptionFile={() =>
                  setOptionFile((opf, idx) => {
                    opf[i].status = !opf[i].status;
                    return opf;
                  })
                }
              />
            ))}
          </Box>
        )}
        ActiontComponent={() => (
          <Box display="flex" style={{ padding: '20px 20px' }}>
            <Box className={classes.btnCancel} onClick={handleClose}>
              <Box>{t(`action.Cancel`)}</Box>
            </Box>
            <Box
              className={classes.btnExport}
              disabled={optionFile.includes(op => op.status === true)}
              onClick={handleExportFile}>
              <Box>{t(`action.${title}`)}</Box>
            </Box>
          </Box>
        )}></DialogModal>
    </Box>
  );
};

ExportModal.defaultProps = {
  type: 'primary',
  title: 'Export',
  optionFile: []
};

export default ExportModal;
