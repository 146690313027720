import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { Typography } from '@material-ui/core';
import { actSetNeedReloadCountProductOrders } from '../../../store/actions/orders';
import { toastMessage, TOAST_TYPE } from '../../../utils/toastify-notify';
import { vendorUpdateOrder } from 'maua-redux-core/actions/productOrder';
import { getOpenDetailOrderItem } from '../../../store/selectors/orders';
import { actToggleModalReason } from '../../../store/actions/modal';
import { getLoadingStatus } from '../../../store/selectors/loading';
import { actionsVendor } from '../../../shared/constant/const';
import { actSetLoading } from '../../../store/actions/loading';
import ProductService from '../../Orders/ProductService';
import BackToDashBoard from '../../BackToDashBoard';
import PaymentInfo from '../../Orders/PaymentInfo';
import TotalPrice from '../../Orders/TotalPrice';
import FormCustomer from '../../FormCustomer';
import Button from '../../Button';
import useStyle from './style';

const DetailReadyOrderItem = props => {
  const classess = useStyle();
  const { t } = useTranslation();

  useEffect(() => {
    if (props.loading?.status && props.showDetailOrderItem) {
      props.setLoading({ status: false });
    }
  }, [props.loading?.status]);

  const handleComplete = async ev => {
    const {
      productActive,
      handleReadyComplete,
      handleToggleNeedReloadCountProductOrders
    } = props;
    ev.preventDefault();
    try {
      props.setLoading({ status: true });
      await props
        .handleVendorUpdateOrder(productActive, actionsVendor.complete)
        .then(response => {
          handleReadyComplete();
          handleToggleNeedReloadCountProductOrders(true);
          toastMessage(
            TOAST_TYPE.SUCCESS,
            `${t('toastMessage.success.content.Product Order to Complete')}`
          );
        });
    } catch (err) {
      props.setLoading({ status: false });
      toastMessage(TOAST_TYPE.ERROR, err.messsage);
    } finally {
      props.setLoading({ status: false });
    }
  };

  const handleFail = async ev => {
    const {
      productActive,
      handleReadyFail,
      handleToggleNeedReloadCountProductOrders
    } = props;
    ev.preventDefault();
    try {
      props.setLoading({ status: true });
      await props
        .handleVendorUpdateOrder(productActive, actionsVendor.fail)
        .then(response => {
          handleReadyFail();
          handleToggleNeedReloadCountProductOrders(true);
          toastMessage(
            TOAST_TYPE.SUCCESS,
            `${t('toastMessage.success.content.Product Order to Failed')}`
          );
        });
    } catch (err) {
      props.setLoading({ status: false });
      toastMessage(TOAST_TYPE.ERROR, err.messsage);
    } finally {
      props.setLoading({ status: false });
    }
  };

  const {
    _id,
    code,
    amount,
    address,
    products,
    customer,
    subTotal,
    discount,
    promotion,
    serviceFee,
    deliveryFee,
    paymentMethod,
    productsPrice
  } = props.data;

  const dataTotalPrice = {
    amount,
    discount,
    promotion,
    serviceFee,
    deliveryFee,
    productsPrice: subTotal || productsPrice
  };

  const dataCustomer = {
    customer,
    address,
    productOrderID: _id
  };

  const dataPaymentMethod = {
    paymentMethod
  };

  return (
    <div className={classess.wrapp}>
      {/* <BackToDashBoard handleBackDashboard={props.handleshowDashboards} /> */}
      <Typography variant="h4" className={classess.identity}>
        #{code}
      </Typography>
      <div className={classess.main}>
        <ProductService products={products} />
        <TotalPrice
          methodDelivery={props?.methodDelivery}
          dataTotalPrice={dataTotalPrice}
        />
        <FormCustomer dataCustomer={dataCustomer} />
        <PaymentInfo order={props.data} />
        <div className={classess.wrappBtn}>
          <Button typeBtn="btnFail" onClick={handleFail}>
            {t('order.delivery.process.status.Fail')}
          </Button>
          <Button typeBtn="yellow" onClick={handleComplete}>
            {t('action.Complete')}
          </Button>
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = state => {
  return {
    loading: getLoadingStatus(state),
    showDetailOrderItem: getOpenDetailOrderItem(state)
  };
};

const mapDispatchToProps = dispatch => {
  return {
    openModalReason: () => dispatch(actToggleModalReason(true)),
    handleVendorUpdateOrder: (_id, action) =>
      dispatch(vendorUpdateOrder({ _id, action })),
    setLoading: status => dispatch(actSetLoading(status)),
    handleToggleNeedReloadCountProductOrders: value =>
      dispatch(actSetNeedReloadCountProductOrders(value))
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(DetailReadyOrderItem);
