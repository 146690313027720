import React from 'react';

import useStyle from './style';
import ThankyouModal from '../../ThankyouModal';
import ModalCustom from '../../ModalCustom';

const ThankyouModalAddBusiness = props => {
  const mainClass = useStyle();
  return (
    <div className={mainClass.root}>
      <ModalCustom {...props}>
        <ThankyouModal handleClose={props.handleClose} />
      </ModalCustom>
    </div>
  );
};

export default ThankyouModalAddBusiness;
