import { makeStyles } from '@material-ui/core';
import { textColor, secondaryFont, primaryFont } from '../../../shared/theme';

export const useStyle = makeStyles(theme => ({
  flex: {
    display: 'flex',
    alignItems: 'center'
  },
  filesContent: {
    [theme.breakpoints.down('sm')]: {
      width: 1000
    }
  },
  headerTable: {
    height: 72,
    backgroundColor: '#f0f1f3',
    borderBottom: '1px solid rgba(224, 224, 224, 1)',
    borderTop: '1px solid rgba(224, 224, 224, 1)',
    boxShadow: '-4px 0px 10px rgba(0, 0, 0, 0.07)',
    [theme.breakpoints.down('sm')]: {
      width: 1000
    }
  },

  rowHeader: {
    color: textColor.light,

    '&:nth-child(odd)': {
      width: '15%',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center'
    },

    '&:nth-child(even)': {
      width: '17%',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center'
    },

    '&:first-child': {
      width: '25%',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center'
    },
    '&:last-child': {
      width: '12%',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center'
    }
  },

  rightColumn: {
    width: '100%',
    background: theme.palette.color.white,
    padding: 10,
    [theme.breakpoints.down('md')]: {
      width: '90vw',
      overflow: 'auto'
    }
  },

  row: {
    height: 64,
    textTransform: 'capitalize',
    color: theme.palette.color.black2,
    borderBottom: '1px solid #E7EEF3',
    '&:nth-child(odd)': {
      width: '15%',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center'
    },
    '&:nth-child(even)': {
      width: '17%',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center'
    },
    '&:first-child': {
      width: '25%',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center'
    },
    '&:last-child': {
      width: '12%',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center'
    }
  }
}));
