import { makeStyles } from '@material-ui/core';

const useStyle = makeStyles(theme => ({
  root: {
    width: '100%',
    height: 48,
    fontSize: 16,
    color: '#FFFFFF',
    borderRadius: 200,
    background: '#E38D03',
    '&:hover': {
      backgroundColor: '#FF9D00',
      opacity: 0.9
    },
    '&.MuiButton-root.Mui-disabled': {
      color: '#FFFFFF',
      cursor: 'not-allowed',
      opacity: 0.5
    }
  },
  cancel: {
    color: '#999',
    background: '#fff',
    '&:hover': {
      backgroundColor: '#fff',
      opacity: 0.9
    }
  }
}));

export default useStyle;
