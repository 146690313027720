import { createMuiTheme } from '@material-ui/core/styles';

export const textColor = {
  light: '#565554',
  main: '#999999',
  dark: '#2F2E2E'
};

export const secondaryFont = `'Muli', sans-serif`;
export const primaryFont = `'Helvetica', sans-serif`;

export const main = '#E38D03';

export const theme = createMuiTheme({
  breakpoints: {
    values: {
      xs: 0,
      sm: 576,
      md: 768,
      lg: 992,
      xl: 1200
    }
  },
  palette: {
    primary: {
      main,
      contrastText: '#fff'
    },
    color: {
      white: '#fff',
      white1: '#E6E6E6',
      white2: '#f7f7f7',
      white3: '#F2F2F2',
      black: '#000',
      black1: '#2F2E2E',
      black2: '#565554',
      black3: '#232735',
      yellow: '#FFCF62',
      yellow1: '#ffcf624d',
      orange: '#E38D03',
      orange1: '#FFF2F2',
      orange2: 'rgba(227, 141, 3, .3)',
      orange3: 'rgba(255, 207, 98, .3)',
      gray: '#ccc',
      gray1: '#999',
      gray2: '#B3B3B3',
      gray3: '#808080',
      gray4: '#666',
      secondary: '#E38D03',
      green: '#3A8E07',
      green1: 'rgba(58, 142, 7, .1)',
      green2: '#619D09',
      blue: '#008BCC',
      blue1: '#F0FAFF',
      violet: '#BB6BD9',
      red: '#BE0000',
      red1: 'red'
    }
  },
  typography: {
    body1: {
      fontSize: 16,
      fontWeight: 300
    },
    body2: {
      fontSize: 14,
      fontWeight: 300
    },
    subtitle2: {
      fontSize: '16px',
      fontWeight: 300
    },
    subtitle1: {
      fontSize: '18px',
      fontWeight: 300
    },
    h6: {
      fontSize: 18,
      fontWeight: '600',
      fontFamily: 'Helvetica',
      color: textColor.dark
    },
    h5: {
      fontSize: 20,
      fontWeight: 'bold',
      fontFamily: secondaryFont,
      color: textColor.light
    },
    h4: {
      fontSize: 24,
      fontFamily: secondaryFont,
      fontWeight: 'bold'
    },
    h3: {
      fontSize: 34,
      fontWeight: 'bold',
      fontFamily: secondaryFont,
      color: textColor.light
    },
    fontSize: 14,
    fontFamily: primaryFont
  },
  spacing: 10
});
export const errorText = {
  color: 'red'
};
