import { makeStyles } from '@material-ui/core';
import { secondaryFont, primaryFont } from '../../../shared/theme';
const useStyle = makeStyles(theme => ({
  root: {},
  modalContentOutside: {
    position: 'relative',
    minHeight: '100%'
  },
  img: {
    display: 'flex',
    justifyContent: 'flex-end'
    // width: '100%'
  },
  innerContent: {
    margin: theme.spacing(-17, 9, 0),
    textAlign: 'center'
  },
  title: {
    margin: theme.spacing(0, 0, 1.3),
    fontSize: 24,
    fontFamily: secondaryFont,
    color: theme.palette.color.black
  },
  reason: {
    // backgroundColor: 'transparent',
    // '& fieldset': {
    //   borderColor: 'transparent'
    // },
    marginBottom: 30
  },
  rootTextarea: {
    '&:hover fieldset': {
      border: `1px solid ${theme.palette.color.gray} !important`
    },
    '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
      border: '1px solid #E38D03 !important'
    },
    '&.MuiInput-underline:hover:not(.Mui-disabled):before': {
      borderBottom: `1px solid rgba(0, 0, 0, 0.42)`
    },
    '&.MuiInput-underline:after': {
      borderBottom: `1px solid #E38D03 !important`
    }
  },
  notchedOutline: {
    border: `1px solid #ccc`,
    outline: 0
  },
  input: {
    border: 'none',
    '&:focus': {
      backgroundColor: 'transparent'
    }
  },
  notchedOutlineFocus: {
    borderWidth: 1
  }
}));
export default useStyle;
