import { makeStyles } from '@material-ui/core';
import { primaryFont, secondaryFont } from '../../shared/theme';

const useStyle = makeStyles(theme => ({
  container: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    width: '100%',
    minHeight: '100vh',
    background: theme.palette.color.white
  },
  title: {
    fontSize: 60,
    fontFamily: secondaryFont,
    color: theme.palette.color.gray1,
    margin: 0
  },
  desc: {
    fontSize: 18,
    fontFamily: primaryFont,
    color: theme.palette.color.gray
  }
}));
export default useStyle;
