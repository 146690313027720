import React from 'react';
import isEqual from 'lodash/isEqual';
import ReactApexChart from 'react-apexcharts';
import './barchartStyle.css';

class BarChart extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      options: {
        chart: {
          animations: {
            enabled: false
          },
          zoom: {
            enabled: false
          },
          toolbar: {
            show: false
          }
        },
        dataLabels: {
          enabled: false
        },
        stroke: {
          curve: 'smooth'
        },
        theme: {
          palette: 'palette1'
        },
        xaxis: {
          type: 'category',
          categories: [],
          tooltip: {
            enabled: false
          },
          labels: {
            style: {
              color: '##B3B3B3',
              fontSize: '12px',
              fontFamily: 'Helvetica'
            }
          }
          // min: props.min || null,
          // max: props.max || null
        },
        yaxis: {
          hideOverlappingLabels: true,
          labels: {
            style: {
              color: '##B3B3B3',
              fontSize: '12px',
              fontFamily: 'Helvetica'
            }
          }
        },
        markers: {
          size: 5,
          hover: {
            size: 7
          }
        },
        colors: this.props.colors || ['#F1B45B'],
        tooltip: {
          enabled: true,
          shared: false,
          custom: ({ series, seriesIndex, dataPointIndex }) => {
            return (
              '<div class="arrow_box">' +
              '<span>' +
              'SAT$ ' +
              series[seriesIndex][dataPointIndex] +
              '</span>' +
              '</div>'
            );
          }
        },
        plotOptions: {
          bar: {
            columnWidth: '30%',
            dataLabels: {
              position: 'top',
              hideOverflowingLabels: true
            }
          }
        }
      },
      series: [
        {
          name: 'Revenue',
          data: []
        }
      ]
    };
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    if (
      !isEqual(nextProps.series, prevState.series) &&
      !isEqual(nextProps.xaxis, prevState.options.xaxis.categories)
    ) {
      return {
        ...prevState,
        series: nextProps.series,
        options: {
          ...prevState.options,
          xaxis: {
            categories: nextProps.xaxis
          }
        }
      };
    }
    if (!isEqual(nextProps.series, prevState.series)) {
      return {
        ...prevState,
        series: nextProps.series
      };
    }

    return null;
  }

  shouldComponentUpdate(nextProps, nextState) {
    if (
      !isEqual(nextProps.series, this.props.series) ||
      !isEqual(nextProps.xaxis, this.props.xaxis)
    ) {
      return true;
    }
    return false;
  }

  render() {
    return (
      <div className="chart-bar">
        <ReactApexChart
          options={this.state.options}
          series={this.state.series}
          type={this.props.type || 'bar'}
          height="380"
        />
      </div>
    );
  }
}

export default BarChart;
