import React, { useState, useRef } from 'react';
import DialogTitle from '@material-ui/core/DialogTitle';
import Box from '@material-ui/core/Box';
import Divider from '@material-ui/core/Divider';
import Paper from '@material-ui/core/Paper';
import Fade from '@material-ui/core/Fade';
import ExportIcon from '../../assets/img/export-icon.svg';
import Typography from '@material-ui/core/Typography';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import DialogModal from '../DialogModal';
import { cloneDeep } from 'lodash';
import { onClickOutside } from '../../utils/common';
import { useTranslation } from 'react-i18next';
import useStyles from './style';

const Item = props => {
  const classes = useStyles();
  const { t } = useTranslation();
  const [option, setOption] = useState(props.option);

  const handleCheckBox = () => {
    setOption(op => ({ ...op, status: !op.status }));
    props.setOptionFile();
  };

  return (
    <Box>
      <FormControlLabel
        className={classes.formLabel}
        control={
          <Checkbox
            color="primary"
            className={classes.iconSpacing}
            checked={option.status}
            onChange={handleCheckBox}
          />
        }
        label={t(`product.export.option.${option.text}`)}
      />
    </Box>
  );
};
export default Item;
