import React from 'react';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { Typography } from '@material-ui/core';
import './style.scss';

const Loading = props => {
  const { t } = useTranslation();
  const percent = props.app.loading?.percent;
  const classes = [
    'app-loading',
    props.app.loading?.status ? '' : 'hidden'
  ].join(' ');

  return (
    <div className={classes}>
      <div>
        <div className="sk-folding-cube">
          <div className="sk-cube1 sk-cube" />
          <div className="sk-cube2 sk-cube" />
          <div className="sk-cube4 sk-cube" />
          <div className="sk-cube3 sk-cube" />
        </div>
        <Typography variant="subtitle1" className="loadingText">
          {percent
            ? `${percent > 99 ? 99 : percent}%`
            : `${t('loading.title.Loading')}...`}
        </Typography>
      </div>
    </div>
  );
};

export default connect(state => ({
  app: state.loading
}))(Loading);
