import React, { useState } from 'react';

import { Typography } from '@material-ui/core';
import useStyle from './style';
import { connect } from 'react-redux';

import Button from '../../Button';
import ModalCustom from '../../ModalCustom';
import RadioField from '../../FormFeild/RadioFeild';

import deliveryServiceImg from '../../../assets/img/delivery-service.png';
// import { setMethodDeliveryService } from '../../../store/actions/orders';
// import { actToggleModalChoiseDelivery } from '../../../store/actions/modal';
import {
  SELFDELIVERY,
  DELIVERYSERVICE,
  actionsVendor
} from '../../../shared/constant/const';
import { vendorUpdateOrder } from 'maua-redux-core/actions/productOrder';
import { toastMessage, TOAST_TYPE } from '../../../utils/toastify-notify';
import { actSetLoading } from '../../../store/actions/loading';
import { actSetNeedReloadCountProductOrders } from '../../../store/actions/orders';
import { useTranslation } from 'react-i18next';

const dataRadios = [
  {
    value: SELFDELIVERY,
    label: 'Self delivery'
  },
  {
    value: DELIVERYSERVICE,
    label: 'Delivery service'
  }
];

const ChoiseDeliveryServiceModal = props => {
  const mainClass = useStyle();
  const { t } = useTranslation();
  const [method, setMethod] = useState('');
  const [changeMethod, setChangeMethod] = useState(false);
  const { productActive, handleToggleNeedReloadCountProductOrders } = props;
  const options = dataRadios.map(option => ({
    ...option,
    disabled:
      props.orderDetail.paymentMethod === 'cash' &&
      option.value === SELFDELIVERY
  }));

  const getHappened = method => {
    setMethod(method);
    setChangeMethod(true);
  };

  const handleSubmit = async () => {
    try {
      let action =
        method === SELFDELIVERY
          ? actionsVendor.selfDelivery
          : actionsVendor.deliveryService;
      props.setLoading({ status: true });

      await props.handleReadyDeliveryRequest(
        productActive,
        actionsVendor.ready
      );

      await props
        .handleReadyDeliveryRequest(productActive, action)
        .then(response => {
          props.handleReadyForDelivery();
          handleToggleNeedReloadCountProductOrders(true);
          toastMessage(
            TOAST_TYPE.SUCCESS,
            `${t('toastMessage.success.content.Product Order to Ready')}`
          );
        });

      setMethod(() => '');
      setChangeMethod(false);
    } catch (err) {
      props.setLoading({ status: false });
      toastMessage(TOAST_TYPE.ERROR, err.message);

      // props.setMethodDelivery(method);
      // props.closeModalChoiseDelivery();
      setChangeMethod(false);
      setMethod('');
    } finally {
      props.setLoading({ status: false });
    }
  };

  return (
    <div className={mainClass.root}>
      <ModalCustom {...props}>
        <div className={mainClass.detail}>
          <div className={mainClass.img}>
            <img src={deliveryServiceImg} alt="Choose delivery service" />
          </div>
          <div className={mainClass.content}>
            <Typography variant="h4" className={mainClass.title}>
              {t('order.delivery.choose.title.Choose delivery service')}
            </Typography>
            <RadioField
              dataRadios={options}
              getValue={getHappened}
              valueDefault={method}
              column={true}
            />
            <div className={mainClass.wrappBtn}>
              <Button
                typeBtn="yellow"
                handleClick={handleSubmit}
                disabled={!changeMethod}>
                {/* Submit */}
                {t('action.Submit')}
              </Button>
            </div>
          </div>
        </div>
      </ModalCustom>
    </div>
  );
};

const mapDispatchToProps = dispatch => {
  return {
    // setMethodDelivery: value => dispatch(setMethodDeliveryService(value)),
    // closeModalChoiseDelivery: () =>
    //   dispatch(actToggleModalChoiseDelivery(false)),
    handleReadyDeliveryRequest: (_id, action) =>
      dispatch(vendorUpdateOrder({ _id, action })),
    setLoading: status => dispatch(actSetLoading(status)),
    handleToggleNeedReloadCountProductOrders: value =>
      dispatch(actSetNeedReloadCountProductOrders(value))
  };
};

export default connect(
  null,
  mapDispatchToProps
)(ChoiseDeliveryServiceModal);
