import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { Typography } from '@material-ui/core';
import TextField from '@material-ui/core/TextField';
import Radio from '@material-ui/core/Radio';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import { addWallet } from 'maua-redux-core/actions/wallet';

import Button from '../../Button';
import ModalCustom from '../../ModalCustom';

import useStyle from './style';

import vodaFoneSamoaIcon from '../../../assets/img/vodafone-icon.jpg';
import vodaFoneVanuatuIcon from '../../../assets/img/vatu-icon.jpg';
import digicellIcon from '../../../assets/img/digicel-icon.png';
import addWalletImg from '../../../assets/img/addWallet.png';

import { queryAddEditWallet } from '../../../store/query/wallet';
import { actSetLoading } from '../../../store/actions/loading';
import { actSetNeedFetchWallets } from '../../../store/actions/wallets';

import { toastMessage, TOAST_TYPE } from '../../../utils/toastify-notify';
import { typeWallet } from '../../../shared/constant/const';
import { useTranslation } from 'react-i18next';

function StyledRadio(props) {
  const classes = useStyle();

  return (
    <Radio
      classes={{ root: classes.radio, checked: classes.checkedRadio }}
      {...props}
    />
  );
}

const AddWalletModal = props => {
  const mainClass = useStyle();
  const { t } = useTranslation();
  const vodaFoneIcon = window.location.host.includes('vu')
    ? vodaFoneVanuatuIcon
    : vodaFoneSamoaIcon;

  const [phoneDigicelWallet, setPhoneDigicelWallet] = useState('');
  const [phoneVodafoneWallet, setPhoneVodafoneWallet] = useState('');
  const [selectedWallet, setSelectedWallet] = useState({
    digicel: {
      checked: false
    },
    vodafone: {
      checked: false
    }
  });

  const handleChangeWalletOption = e => {
    setSelectedWallet(prevState => ({
      ...prevState,
      digicel: {
        checked:
          e.target.value === 'digicel' || prevState.digicel.checked
            ? !prevState.digicel.checked
            : prevState.digicel.checked
      },
      vodafone: {
        checked:
          e.target.value === 'vodafone' || prevState.vodafone.checked
            ? !prevState.vodafone.checked
            : prevState.vodafone.checked
      }
    }));
    setPhoneDigicelWallet('');
    setPhoneVodafoneWallet('');
  };

  const handleChangeFeild = e => {
    const regex = /^\d+$/;
    if (!regex.test(e.target.value)) return;
    if (selectedWallet.digicel.checked) {
      setPhoneDigicelWallet(e.target.value);
      setPhoneVodafoneWallet('');
      return;
    }
    setPhoneDigicelWallet('');
    setPhoneVodafoneWallet(e.target.value);
  };

  const handleAddWallet = async () => {
    try {
      props.setLoading({ status: true });

      let phoneNumber = selectedWallet.digicel.checked
        ? phoneDigicelWallet
        : phoneVodafoneWallet;
      let serviceType = selectedWallet.digicel.checked
        ? typeWallet.digicel
        : typeWallet.vodafone;

      await props
        .editWalletRequest({
          ID: phoneNumber,
          service: serviceType
        })
        .then(res => {
          props.handleNeedFetchWallets();
          props.handleClose();
          props.setLoading({ status: false });
          toastMessage(
            TOAST_TYPE.SUCCESS,
            t('toastMessage.success.content.Edit Wallet Success')
          );
        });
    } catch (err) {
      props.handleClose();
      props.setLoading({ status: false });
      toastMessage(TOAST_TYPE.ERROR, err.message);
    }
  };

  useEffect(() => {
    return () => {
      if (props.openModel) {
        setSelectedWallet({
          digicel: {
            checked: false
          },
          vodafone: {
            checked: false
          }
        });
        setPhoneDigicelWallet('');
        setPhoneVodafoneWallet('');
      }
    };
  }, [props.openModel]);

  return (
    <div className={mainClass.root}>
      <ModalCustom {...props}>
        <div className={mainClass.detail}>
          <img
            src={addWalletImg}
            alt="Add wallet thumb"
            className={mainClass.thumbAddWallet}
          />
          <Typography variant="h5" className={mainClass.title}>
            {t('mystore.wallet.popup.title.Add wallet')}
          </Typography>
          <div className={mainClass.walletList}>
            <div className={mainClass.walletItem}>
              <FormControlLabel
                value="digicel"
                onChange={handleChangeWalletOption}
                checked={selectedWallet.digicel.checked}
                name="addWalletOpt"
                control={<StyledRadio />}
                color="primary"
              />
              <div className={mainClass.walletContent}>
                <img
                  src={digicellIcon}
                  alt="Digicel Wallet"
                  className={mainClass.walletIcon}
                />
                <div className={mainClass.walletInfo}>
                  <TextField
                    id="digicel"
                    label={t('mystore.wallet.popup.label.ACCOUNT PHONE NUMBER')}
                    className={mainClass.textField}
                    value={phoneDigicelWallet}
                    onChange={handleChangeFeild}
                    margin="normal"
                    disabled={!selectedWallet.digicel.checked}
                    InputLabelProps={{
                      classes: {
                        root: mainClass.rootLabel,
                        focused: mainClass.labelFocus
                      }
                    }}
                    InputProps={{
                      classes: {
                        root: mainClass.rootInput,
                        focused: mainClass.focusedInputDigicel,
                        input: mainClass.inputStyle
                      },
                      disableUnderline: true
                    }}
                  />
                </div>
              </div>
            </div>
            <div className={mainClass.walletItem}>
              <FormControlLabel
                value="vodafone"
                onChange={handleChangeWalletOption}
                checked={selectedWallet.vodafone.checked}
                name="addWalletOpt"
                control={<StyledRadio />}
                color="primary"
              />
              <div className={mainClass.walletContent}>
                <img
                  src={vodaFoneIcon}
                  alt="Vodafone Wallet"
                  className={mainClass.walletIcon}
                />
                <div className={mainClass.walletInfo}>
                  <TextField
                    id="vodafone"
                    label={t('mystore.wallet.popup.label.ACCOUNT PHONE NUMBER')}
                    className={mainClass.textField}
                    value={phoneVodafoneWallet}
                    onChange={handleChangeFeild}
                    margin="normal"
                    disabled={!selectedWallet.vodafone.checked}
                    InputLabelProps={{
                      classes: {
                        root: mainClass.rootLabel,
                        focused: mainClass.labelFocus
                      }
                    }}
                    InputProps={{
                      classes: {
                        root: mainClass.rootInput,
                        focused: mainClass.focusedInputVodafone,
                        input: mainClass.inputStyle
                      },
                      disableUnderline: true
                    }}
                  />
                </div>
              </div>
            </div>
          </div>
          <div className={mainClass.wrappBtn}>
            <Button
              typeBtn="yellow"
              disabled={
                phoneDigicelWallet || phoneVodafoneWallet ? false : true
              }
              onClick={handleAddWallet}>
              {t('action.Add')}
            </Button>
          </div>
        </div>
      </ModalCustom>
    </div>
  );
};

const mapDispatchToProps = dispatch => {
  return {
    setLoading: status => dispatch(actSetLoading(status)),
    editWalletRequest: params =>
      dispatch(addWallet(params, queryAddEditWallet)),
    handleNeedFetchWallets: () => dispatch(actSetNeedFetchWallets(true))
  };
};

export default connect(
  null,
  mapDispatchToProps
)(AddWalletModal);
