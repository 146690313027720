import { makeStyles } from '@material-ui/core';
import { secondaryFont } from '../../shared/theme';

export const useStyle = makeStyles(theme => ({
  root: {
    position: 'relative',
    height: '100%',
    width: 68
  },
  flag: {
    height: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    cursor: 'pointer'
  },
  icon: {
    display: 'flex',
    alignItems: 'center',
    borderRadius: '50%',
    background: '#252525',
    padding: theme.spacing(1.6, 1.2),
    '& img': {
      width: 20
    }
  },
  optionLanguage: {
    position: 'absolute',
    maxHeight: 210,
    margin: theme.spacing(0.2, 0),
    background: '#fff',
    width: 215,
    overflow: 'auto',
    top: '100%',
    left: -122,
    boxShadow: '0px 4px 8px rgba(130, 126, 90, 0.1)',
    borderRadius: 4,
    [theme.breakpoints.down('md')]: {
      left: -156
    }
  },
  languageItem: {
    height: 48,
    display: 'flex',
    alignItems: 'center',
    padding: theme.spacing(0.8, 1.6),
    cursor: 'pointer',
    fontFamily: secondaryFont,
    '&:hover': {
      background: '#cbd0ce45'
    }
  },
  label: {
    width: 190,
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis'
  },
  changeCountryBtn: {
    color: theme.palette.primary.main
  }
}));
