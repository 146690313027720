import React from 'react';
import { useTranslation } from 'react-i18next';
import { Container, Grid, Typography } from '@material-ui/core';
import Box from '@material-ui/core/Box';
import SwitchBusinessModal from '../../Mystore/SwitchBusinessModal';
import Logo from '../../../assets/img/icons/logo.svg';
import ImgPhone from '../../../assets/img/phone.png';
import useStyle, { useStyleModal } from './style';
import Avatar from '../../../components/Avatar';
import SwitchBusiness from '../SwitchBusiness';
import ModalCustom from '../../ModalCustom';
import ConfirmLogout from './ConfirmLogout';
import Notification from '../notification';
import Languages from '../../Languages';
import clsx from 'clsx';

function DesktopHeader({
  profile,
  hotline,
  handleOpenMenu,
  handleLogOut,
  open,
  setOpen,
  openSwitchBusiness,
  setOpenSwitchBusiness,
  handleCall,
  location,
  handleRedicrectHome
}) {
  const classes = useStyle();
  const classModal = useStyleModal();
  const { t } = useTranslation();

  return (
    <React.Fragment>
      <section className={classes.header}>
        <Container maxWidth="xl" className={classes.container}>
          <Grid container className={classes.headerContainer}>
            {/* HEADER LEFT */}
            <Grid item xs={11} className={classes.headerContent}>
              <div onClick={handleRedicrectHome} className={classes.logo}>
                <img src={Logo} className={classes.logo} alt={Logo} />
              </div>
              <Box component="nav" display="flex" className={classes.navbar}>
                <Typography
                  component="span"
                  onClick={() => handleOpenMenu('orders/newOrders')}
                  className={`
                      ${location.pathname.includes('orders') ? 'active' : ''}`}>
                  {t('header.nav.Orders')}
                </Typography>
                <Typography
                  component="span"
                  onClick={() => handleOpenMenu('myStore')}
                  className={`
                      ${
                        location.pathname.includes('myStore') ? 'active' : ''
                      }`}>
                  {t('header.nav.My Store')}
                </Typography>
                <Typography
                  component="span"
                  onClick={() => handleOpenMenu('products')}
                  className={`
                      ${
                        location.pathname.includes('products') ? 'active' : ''
                      }`}>
                  {t('header.nav.Products')}
                </Typography>
                <Typography
                  component="span"
                  onClick={() => handleOpenMenu('dashboard')}
                  className={`
                      ${
                        location.pathname.includes('dashboard') ? 'active' : ''
                      }`}>
                  {t('header.nav.Dashboard')}
                </Typography>
                <Typography
                  component="span"
                  onClick={() => handleOpenMenu('transaction')}
                  className={`
                      ${
                        location.pathname.includes('transaction')
                          ? 'active'
                          : ''
                      }`}>
                  {t('header.nav.Transaction')}
                </Typography>
              </Box>
            </Grid>
            {/* HEADER RIGHT */}
            <Grid
              item
              xs={1}
              className={clsx(classes.headerContent, classes.headerRight)}>
              <div style={{ display: 'flex' }} onClick={handleCall}>
                <Box className={classes.flag}>
                  <img src={ImgPhone} width="70" alt="hotline" />
                </Box>

                <Typography className={classes.phone}>{hotline}</Typography>
              </div>
              <Box display="flex" height="100%">
                <Languages />
                <Notification isLogin={!!profile._id} />
                <SwitchBusiness
                  profile={profile}
                  controlIcon={classes.controlIcon}
                  switchBusiness={() => setOpenSwitchBusiness(true)}
                />
                <Avatar
                  profile={profile}
                  controlIcon={classes.controlIcon}
                  onLogout={() => setOpen(true)}
                />
              </Box>
            </Grid>
          </Grid>
        </Container>
      </section>

      <ModalCustom openModel={openSwitchBusiness}>
        <SwitchBusinessModal
          openModel={openSwitchBusiness}
          handleClose={() => setOpenSwitchBusiness(false)}
          classOutSide={classModal}
        />
      </ModalCustom>

      <ModalCustom openModel={open} handleClose={() => setOpen(false)}>
        <ConfirmLogout setOpen={setOpen} handleLogOut={handleLogOut} />
      </ModalCustom>
    </React.Fragment>
  );
}

export default DesktopHeader;
