import React from 'react';

import { compose } from 'redux';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';

import useStyle from './style';
import EnterPhone from '../../../components/Login/EnterPhone';
import EnterOTP from '../../../components/Login/EnterOTP';

const EnterPhoneNumber = props => {
  const classess = useStyle();

  return (
    <div className={classess.container}>
      <div className={classess.inner}>
        <EnterPhone {...props} />
      </div>
    </div>
  );
};

const EnterOTPNumber = props => {
  const classess = useStyle();

  return (
    <div className={classess.container}>
      <div className={classess.innerOTP}>
        <EnterOTP {...props} />
      </div>
    </div>
  );
};

class LoginFireBase extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      phoneNumber: '',
      sessionOTP: '',
      stepLogin: 'PHONE'
    };
  }

  handleSavePhonenumber = data => {
    const { session, stepLogin, phoneNumber } = data;
    this.setState(prevState => ({
      ...prevState,
      sessionOTP: session,
      stepLogin,
      phoneNumber
    }));
  };

  updateSessionOTP = ({ session }) => {
    this.setState(prevState => ({
      ...prevState,
      sessionOTP: session
    }));
  };

  handleBackInputPhone = () => {
    this.setState({
      phoneNumber: '',
      sessionOTP: '',
      stepLogin: 'PHONE'
    });
  };

  renderComponent = () => {
    const { phoneNumber, sessionOTP, stepLogin } = this.state;

    if (stepLogin === 'PHONE') {
      return (
        <EnterPhoneNumber handleSavePhonenumber={this.handleSavePhonenumber} />
      );
    }

    return (
      <EnterOTPNumber
        phoneNumber={phoneNumber}
        sessionOTP={sessionOTP}
        updateSessionOTP={this.updateSessionOTP}
        handleBackInputPhone={this.handleBackInputPhone}
      />
    );
  };

  render() {
    return <div>{this.renderComponent()}</div>;
  }
}
const mapStateToProps = state => {
  return {};
};

export default compose(
  withRouter,
  connect(mapStateToProps)
)(LoginFireBase);
