import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles(theme => ({
  rootTextField: {
    position: 'absolute',
    width: '100%',
    '& .MuiInputBase-root.MuiOutlinedInput-root.MuiInputBase-formControl': {
      height: 46
    },
    '& .MuiInputLabel-outlined': {
      position: 'absolute',
      top: '-4px'
    },
    '& .MuiOutlinedInput-adornedEnd': {
      paddingRight: 4,
      background: 'transparent'
    },
    '& label': {
      fontSize: 14
    },
    '& input': {
      paddingRight: '0',
      height: '46px',
      padding: '0 0 0 14px'
    },
    '& button': {
      padding: 0
    }
  }
}));

export default useStyles;
