import React from 'react';
import { useTranslation } from 'react-i18next';
import useStyle from './style';

const PaymentInfo = React.memo(props => {
  const mainClass = useStyle();
  const { t } = useTranslation();

  return (
    <div className={mainClass.root}>
      <h5 className={mainClass.title}>
        {t('order.payment.title.Payment Info')}
      </h5>
      <div className={mainClass.paymentDetail}>
        <p className={mainClass.paymentInfo}>
          <span className={mainClass.paymentName}>
            {t('order.payment.label.Payment ID')}
          </span>
          <span className={mainClass.paymentUnit}>
            {props.order?.transaction?.code || '-'}
          </span>
        </p>
        <p className={mainClass.paymentInfo}>
          <span className={mainClass.paymentName}>
            {t('order.payment.label.Payment Method')}
          </span>
          <span className={mainClass.paymentUnit}>
            {props.order?.paymentMethod}
          </span>
        </p>
      </div>
    </div>
  );
});

export default PaymentInfo;
