import React, { useMemo, useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { debounce } from 'lodash';
import {
  Box,
  MenuItem,
  // Grow,
  Paper,
  Popper,
  ClickAwayListener,
  InputLabel,
  Input,
  FormControl,
  CircularProgress,
  Grid,
  FormHelperText
} from '@material-ui/core';
import { getAddress, getAddresses } from 'maua-redux-core/selectors/address';
import { resetAddress } from 'maua-redux-core/actions/address';
import { searchLocation } from 'maua-redux-core/actions/address';
import MenuList from '@material-ui/core/MenuList';
import {
  ChangeAddress,
  ChangeAddresses,
  fetchAddressWithSearch
} from '../../store/actions/address';
import iconFlagMap from '../../assets/img/icons/pin-map.png';
import { checkLocation } from '../../utils/checkLocation';
import { queryAddress } from '../../store/query/user';

import useStyle from './style';

const textColor = {
  light: '#565554',
  main: '#999999',
  dark: '#2F2E2E',
  grey: '#B3B3B3'
};

const AlaNumSearch = React.memo(props => {
  const {
    dispatchSearchLocation,
    alaNumTextErr,
    ChangeAddress,
    resetAddress,
    ChangeAddresses,
    getAddressesObj,
    addressObj,
    fetchAddressWithSearch
  } = props;
  const classes = useStyle();
  const { t } = useTranslation();
  const [isLoading, setLoading] = useState(true);
  const [open, setOpen] = useState(false);
  const [valueInput, setValueInput] = useState('');
  const anchorRef = React.useRef(null);

  const handleClose = event => {
    if (anchorRef.current && anchorRef.current.contains(event.target)) {
      return;
    }
    setOpen(false);
    setLoading(true);
  };

  function handleListKeyDown(event) {
    if (event.key === 'Tab') {
      event.preventDefault();
      setOpen(false);
      setLoading(true);
    }
  }

  const prevOpen = React.useRef(open);
  useEffect(() => {
    if (prevOpen.current === true && open === false) {
      anchorRef.current.focus();
    }

    prevOpen.current = open;
  }, [open]);

  useEffect(() => {
    setValueInput(
      addressObj && addressObj.alaNum
        ? `${addressObj.alaNum}, ${
            addressObj.name ? addressObj.name + ',' : ''
          } ${addressObj.village ? addressObj.village + ',' : ''} ${
            addressObj.country
          }`
        : ''
    );
  }, [addressObj]);

  const SearchLocation = async keyword => {
    setLoading(true);
    try {
      if (checkLocation().formatAlaNum.test(keyword)) {
        const search = await fetchAddressWithSearch(keyword);
        ChangeAddresses([{ ...search.getAddress }]);
      } else {
        await dispatchSearchLocation(keyword);
      }
      setLoading(false);
    } catch (error) {
      console.log(error);
    }
  };

  const fetchDebounceSearch = useMemo(
    () => debounce(value => SearchLocation(value), 500),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  const onChangeInput = e => {
    const { value } = e.target;
    if (!value.length || value.length <= 2) {
      setOpen(false);
      setLoading(true);
    }
    if (value.length > 2) {
      !open && setOpen(true);
      fetchDebounceSearch(value);
    }
    setValueInput(value);
  };
  const onChooseAddress = r => {
    try {
      ChangeAddress(r);
      setOpen(false);
    } catch (error) {
      console.log(error);
    }
  };

  const renderOptions = () => {
    if (!open) {
      return null;
    }
    if (isLoading) {
      return (
        <Box className={classes.svgLoading}>
          <CircularProgress />
        </Box>
      );
    }
    if (!getAddressesObj.length) {
      return <MenuItem>{t('AlaNumSearch.option.label.No option')}</MenuItem>;
    } else {
      return getAddressesObj.map((r, i) => {
        return (
          <MenuItem key={i} onClick={() => onChooseAddress(r)}>
            <Box className={classes.iconFlagMap}>
              <img src={iconFlagMap} alt="pin" />
            </Box>
            <Box className={classes.contentMenu}>
              <Box>{r.alaNum},</Box>
              <Box style={{ whiteSpace: 'normal' }}>
                {r.name ? r.name + ',' : ''} {r.village ? r.village + ',' : ''}{' '}
                {r.country}
              </Box>
            </Box>
          </MenuItem>
        );
      });
    }
  };

  return (
    <div className={classes.root}>
      <Grid item md={12}>
        <FormControl fullWidth margin="normal">
          <InputLabel shrink={true}>
            {t('store.edit.label.AlaNumOrPlaces')}*
          </InputLabel>
          <Input
            fullWidth
            ref={anchorRef}
            style={{ color: textColor.light }}
            onFocus={() => {
              resetAddress({});
              setOpen(false);
            }}
            onChange={onChangeInput}
            value={valueInput || ''}
          />
          <FormHelperText className={classes.errorText}>
            {alaNumTextErr}
          </FormHelperText>
        </FormControl>
      </Grid>
      <div>
        <Popper
          open={open}
          className={classes.popover}
          anchorEl={anchorRef.current}
          disablePortal
          role={undefined}>
          <Paper>
            <ClickAwayListener onClickAway={handleClose}>
              <MenuList
                style={{
                  maxHeight: 300,
                  minHeight: 75,
                  maxWidth: 400,
                  minWidth: 300,
                  overflowY: 'auto'
                }}
                className={classes.menuList}
                id="menu-list-grow"
                onKeyDown={handleListKeyDown}>
                {renderOptions()}
              </MenuList>
            </ClickAwayListener>
          </Paper>
        </Popper>
      </div>
    </div>
  );
});

const mapStateToProps = state => {
  return {
    getAddressesObj: getAddresses(state),
    addressObj: getAddress(state)
  };
};

const mapDispatchToProps = dispatch => {
  return {
    fetchAddressWithSearch: keyword =>
      dispatch(fetchAddressWithSearch({ alaNum: keyword }, queryAddress)),
    dispatchSearchLocation: keyword =>
      dispatch(searchLocation({ keyword }, queryAddress)),
    ChangeAddresses: data => dispatch(ChangeAddresses(data)),
    ChangeAddress: data => dispatch(ChangeAddress(data)),
    resetAddress: () => dispatch(resetAddress())
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(AlaNumSearch);
