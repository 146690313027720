import axios from 'axios';
import { checkLocation } from '../../utils/checkLocation';
import { ensureArray } from '../../utils/common';
import { getCredentials } from './token';

// const endpoint = process.env.REACT_APP_API || 'https://api-maua.efisoft.com.vn';

const endpoint = checkLocation().api;

export const uploadImg = (payload, uploadProgress) => {
  let formData = new FormData();
  formData.append('_id', payload._id);
  formData.append('model', payload.model);
  formData.append('field', payload.field);

  /* single files */
  if (payload.file) {
    formData.append('file', payload.file);
  }

  /* multiple files */
  for (let file of ensureArray(payload.files)) {
    formData.append('files', file);
  }

  return new Promise((resolve, reject) => {
    const credentials = getCredentials() || {};
    axios
      .post(`${endpoint}/api/upload`, formData, {
        onUploadProgress:
          typeof uploadProgress === 'function'
            ? uploadProgress
            : progress => {
                // let percent = Math.floor(
                //   (progress.loaded / progress.total) * 100
                // );
                // console.log(percent);
              },
        headers: {
          'Content-Type': 'multipart/form-data',
          Authorization: `Bearer ${credentials.token}`
        }
      })
      .then(({ data }) => {
        resolve(data);
      })
      .catch(reject);
  });
};
