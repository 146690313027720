import { graphqlV1 } from 'maua-redux-core/graphql';
import { slice as address } from 'maua-redux-core/reducers/address';
import { cleanQuery } from 'maua-redux-core/utils/helpers';
import { fetchAddressQuery } from 'maua-redux-core/queries/address';

export const ChangeAddresses = data => {
  return address.actions.searchLocation({ searchLocation: data });
};

export const ChangeAddress = data => {
  return address.actions.fetchAddress({ getAddress: data });
};

export const fetchAddressWithSearch = (params, query) => {
  return async (dispatch, getState) => {
    try {
      const queryString =
        fetchAddressQuery instanceof Function
          ? fetchAddressQuery(query)
          : fetchAddressQuery;
      return await graphqlV1.request(cleanQuery(queryString), params);
    } catch (error) {
      return error;
    }
  };
};
