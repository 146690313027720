import Avatar from '@material-ui/core/Avatar';
import Box from '@material-ui/core/Box';
import ListItem from '@material-ui/core/ListItem';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import ListItemText from '@material-ui/core/ListItemText';
import Typography from '@material-ui/core/Typography';
import LensIcon from '@material-ui/icons/Lens';
import moment from 'moment';
import React from 'react';
import { connect } from 'react-redux';
import { theme } from '../../../shared/theme';
import { initChannel } from '../../../store/actions/message';
import useStyles from './style';

function Channel(props) {
  const { channel, dispatchInitChannel } = props;
  const lastestMess =
    Boolean(channel.message) &&
    (channel.message.content || channel.message.media);
  let countPhoto = lastestMess?.length > 1 ? 'photos' : 'photo';
  const prevMessage = Array.isArray(lastestMess)
    ? `${channel?.message?.sender?.name} has sent ${lastestMess.length} ` +
      countPhoto
    : lastestMess;
  const seenAt = channel.member.seenAt;
  const messageAt = channel.member.messageAt;
  const isSeen = Date.parse(seenAt) >= Date.parse(messageAt);
  const classes = useStyles();

  return (
    <Box onClick={() => dispatchInitChannel(channel)}>
      <ListItem className={classes.content}>
        <ListItemAvatar>
          <Avatar
            src={channel?.message?.sender?.avatar}
            alt={channel?.message?.sender?.name}
            className={classes.large}
          />
        </ListItemAvatar>
        <ListItemText
          primary={
            <Typography
              style={{
                color: isSeen ? 'rgb(104 104 105)' : 'rgb(47, 46, 46)',
                fontSize: '14px',
                fontWeight: isSeen ? 'normal' : 'bold'
              }}
              gutterBottom>
              {channel.name}
            </Typography>
          }
          secondary={
            <Box display="flex" alignItems="center">
              <Typography
                style={{
                  fontWeight: isSeen ? 100 : 600,
                  fontSize: 12,
                  color: isSeen ? 'inherit' : theme.palette.primary.main
                }}
                variant="subtitle1"
                noWrap>
                {prevMessage}
              </Typography>
              <Box display="flex" alignItems="center">
                <LensIcon fontSize="small" className={classes.iconDot} />
                <span
                  style={{
                    display: 'block',
                    fontSize: 12,
                    whiteSpace: 'nowrap'
                  }}>
                  {channel.updatedAt
                    ? moment(Date.parse(channel.updatedAt)).fromNow()
                    : ''}
                </span>
              </Box>
            </Box>
          }
        />
      </ListItem>
    </Box>
  );
}

export default connect(
  null,
  dispatch => ({
    dispatchInitChannel: payload => dispatch(initChannel(payload))
  })
)(Channel);
