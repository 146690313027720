import { createSlice } from 'redux-starter-kit';

let initialState = {
  setShowDetailTrial: null,
  needReloadProducts: false,
  dataProductEdit: null,
  setShowMoreOpt: null,
  deleteProductItem: null,
  archivedProductItem: null,
  unArchivedProductItem: null
};

export let productsSlice = createSlice({
  initialState: initialState,
  slice: 'products',
  reducers: {
    setShowDetailTrial(state, action) {
      state.showDetailTrial = action.payload.state;
    },
    setToggleReloadProducts(state, action) {
      state.needReloadProducts = action.payload.state;
    },
    setDataProductEdit(state, action) {
      state.dataProductEdit = action.payload.state;
    },
    setShowMoreOpt(state, action) {
      state.setShowMoreOpt = action.payload.state;
    },
    setDeleteProductItem(state, action) {
      state.deleteProductItem = action.payload.state;
    },
    setArchivedProductItem(state, action) {
      state.archivedProductItem = action.payload.state;
    },
    setUnArchivedProductItem(state, action) {
      state.unArchivedProductItem = action.payload.state;
    }
  }
});

export default productsSlice.reducer;
