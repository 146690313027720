import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import CheckCircleRoundedIcon from '@material-ui/icons/CheckCircleRounded';
import WarningRoundedIcon from '@material-ui/icons/WarningRounded';
import { Box, Typography } from '@material-ui/core';
import ErrorIcon from '@material-ui/icons/Error';
import { countProductStockLevels } from 'maua-redux-core/actions/product';
import { exportStoreStockProducts } from 'maua-redux-core/actions/store';
import AddBusinessModal from '../../components/Mystore/AddBusinessModal';
import { toastMessage, TOAST_TYPE } from '../../utils/toastify-notify';
import { getOpenModalAddBusiness } from '../../store/selectors/modal';
import { actToggleModalAddBusiness } from '../../store/actions/modal';
import { actSetLoading } from '../../store/actions/loading';
import LowIcon from '../../assets/img/low-icon.svg';
import { useStyle, useStyleModal } from './style';
import { downloadFile } from '../../utils/common';
import Button from '../../components/Button';
import { theme } from '../../shared/theme';

const StockLevels = props => {
  const classes = useStyle();
  const classModal = useStyleModal();
  const { t } = useTranslation();

  const [stockLevels, setStockLevels] = useState([
    {
      _id: 'outOfStock',
      name: 'Out of Stock',
      count: 0,
      time: '---',
      icon: <WarningRoundedIcon style={{ fontSize: 24, color: 'red' }} />,
      color: 'red'
    },
    {
      _id: 'tooLow',
      name: 'Too Low',
      count: 0,
      time: '< 1 Month',
      icon: <ErrorIcon style={{ fontSize: 24, color: '#f7990e' }} />,
      color: '#f7990e'
    },
    {
      _id: 'low',
      name: 'Low',
      count: 0,
      time: '1 - 2 Months',
      icon: <img src={LowIcon} alt="low" style={{ width: 20 }} />,
      color: '#f9d8a4'
    },
    {
      _id: 'ideal',
      name: 'Ideal',
      count: 0,
      time: '3 - 6 Months',
      icon: (
        <CheckCircleRoundedIcon style={{ fontSize: 24, color: '#70a734' }} />
      ),
      color: '#70a734'
    },
    {
      _id: 'high',
      name: 'High',
      count: 0,
      time: '> 6 Months',
      icon: <ErrorIcon style={{ fontSize: 24, color: '#999' }} />,
      color: '#999'
    }
  ]);

  const loadCountStockLevels = async () => {
    try {
      const { countProductStockLevels } = await props.countProductStockLevels();

      setStockLevels(stockLevels =>
        stockLevels.map(s => ({
          ...s,
          count: countProductStockLevels[s._id]
        }))
      );
    } catch (error) {
      toastMessage(TOAST_TYPE.ERROR, error.message);
    }
  };

  useEffect(() => {
    if (props.token) {
      loadCountStockLevels();
    }
  }, [props.token]);

  const handleExport = async () => {
    try {
      props.setLoading(true);
      const { exportStoreStockProducts } = await props.exportStoreStockProducts(
        {
          filetype: 'pdf'
        }
      );

      downloadFile(exportStoreStockProducts);
      toastMessage(
        TOAST_TYPE.SUCCESS,
        `${t('toastMessage.success.content.Export successfully')}`
      );
    } catch (error) {
      toastMessage(TOAST_TYPE.ERROR, error.message);
    } finally {
      props.setLoading(false);
    }
  };

  const LevelItem = () => {
    return stockLevels.map((s, i) => (
      <Box className={classes.levelItem} key={i}>
        <Box className={classes.boxCountName}>
          <Box className={classes.countOfLevel} color={s.color}>
            {s.count}
          </Box>
          <Box className={classes.nameOfLevel}>
            <Box mr={0.8} className={classes.flex}>
              {s.icon}
            </Box>
            {t(`dashboard.stock.label.${s.name}`)}
          </Box>
        </Box>

        <Box className={classes.boxTime}>
          <Box fontSize={14} mb={0.4}>
            {t('dashboard.stock.label.Will Last')}
          </Box>
          <Box className={classes.timeOfLevel}>{s.time}</Box>
        </Box>
      </Box>
    ));
  };

  const onCloseModalAddBusiness = () => {
    props.toggleModalAddBusiness(false);
  };

  return (
    <Box p={theme.spacing(2.4, 0)}>
      <Box className={classes.background}>
        <Box className={classes.header}>
          <Typography variant="h4">
            {t('dashboard.title.Stock levels')}
          </Typography>
          <Button onClick={handleExport} className={classes.btnExport}>
            {t('action.Export')}
          </Button>
        </Box>
        <Box className={classes.chartHeader} borderBottom="none !important">
          <LevelItem />
        </Box>

        {props.openModalAddBusiness ? (
          <AddBusinessModal
            openModel={props.openModalAddBusiness}
            handleClose={onCloseModalAddBusiness}
            classOutSide={classModal}
          />
        ) : null}
      </Box>
    </Box>
  );
};

export default connect(
  state => ({
    token: state.user.user.credentials.token,
    openModalAddBusiness: getOpenModalAddBusiness(state)
  }),
  dispatch => ({
    countProductStockLevels: () =>
      dispatch(
        countProductStockLevels(null, 'high ideal low tooLow outOfStock')
      ),
    exportStoreStockProducts: payload =>
      dispatch(exportStoreStockProducts(payload)),
    setLoading: status => dispatch(actSetLoading({ status })),
    toggleModalAddBusiness: value => dispatch(actToggleModalAddBusiness(value))
  })
)(StockLevels);
