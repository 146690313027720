import React, { useState, useEffect } from 'react';
import clsx from 'clsx';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import { Typography } from '@material-ui/core';
import AddIcon from '@material-ui/icons/Add';

import ModalCustom from '../../ModalCustom';
import { actSetLoading } from '../../../store/actions/loading';
import { queryVendorGetStores } from '../../../store/query/store';
import { queryProfile } from '../../../store/query/user';
import { actToggleModalAddBusiness } from '../../../store/actions/modal';

import {
  getStoresByVendor,
  vendorChooseStore
} from 'maua-redux-core/actions/store';
import { fetchUserProfile } from 'maua-redux-core/actions/user';

import { toastMessage, TOAST_TYPE } from '../../../utils/toastify-notify';
import defaultImg from '../../../assets/img/default.jpg';
import useStyle from './style';
import { getNeedFetchVendorGetStore } from '../../../store/selectors/mystores';
import { actToggleNeedFetchVendorGetStore } from '../../../store/actions/mystores';
import { getUserProfile } from 'maua-redux-core/selectors/user';
import { isEmptyObject } from '../../../utils/common';
import { useTranslation } from 'react-i18next';

const StoreItemBusiness = props => {
  const mainClass = useStyle();
  const { name, media } = props.data;
  const imgSrc = (media && media[0]) || defaultImg;
  const styleItem = props.active
    ? mainClass.businessItemActive
    : mainClass.businessItemDefault;

  return (
    <div
      className={clsx(mainClass.businessItem, styleItem)}
      onClick={props.onChoseBusinessItem}>
      <div className={mainClass.thumbBusiness}>
        <img src={imgSrc} alt={name} className={mainClass.imgThumb} />
      </div>
      <Typography variant="subtitle1" className={mainClass.descBusiness}>
        {name}
      </Typography>
    </div>
  );
};

const SwitchBusinessModal = props => {
  const mainClass = useStyle();
  const [listStore, setListStore] = useState([]);
  const [storeCurrent, setStoreCurrent] = useState({});
  const { t } = useTranslation();

  const { needFetchVendorGetStore, profile } = props;
  let timeOut;

  const { store } = profile;
  const hasToken = isEmptyObject(profile);
  useEffect(() => {
    if (hasToken && isEmptyObject(store) && !isEmptyObject(storeCurrent)) {
      setStoreCurrent(store);
    }
  }, [hasToken, storeCurrent]);

  useEffect(() => {
    if (!listStore.length || (needFetchVendorGetStore && hasToken)) {
      props.setLoading({ status: true });
      props
        .fetchVendorGetStore()
        .then(({ getStoresByVendor }) => {
          setListStore(getStoresByVendor);
          props.setLoading({ status: false });
        })
        .catch(err => {
          props.setLoading({ status: false });
          toastMessage(TOAST_TYPE.ERROR, err.message);
        });
      if (needFetchVendorGetStore) {
        props.toggleNeedFetchVendorGetStore(false);
      }
    }
  }, [needFetchVendorGetStore, hasToken]);

  const handleAddBusinessItem = () => {
    props.toggleModalAddBusiness(true);
    // props.history.push('/complete-profile');
    return;
  };
  const handleChoseBusinessItem = async idStore => {
    let params = {
      _id: idStore
    };

    try {
      props.setLoading({ status: true });
      let { vendorChooseStore } = await props.vendorChooseStoreRequest(params);

      if (vendorChooseStore) {
        props.reFetchUserProfile();
        // props.toggleNeedReloadMyStore(true);
        // props.handleClose();
        // props.setLoading({ status: false });
        toastMessage(TOAST_TYPE.SUCCESS, 'Switch business success!');
        // props.history.push('/myStore');
        timeOut && clearTimeout(timeOut);
        timeOut = setTimeout(() => {
          window.location.reload();
        }, 500);
      }
    } catch (err) {
      props.setLoading({ status: false });
      toastMessage(TOAST_TYPE.ERROR, err.message);
    } finally {
      props.setLoading({ status: false });
    }
  };

  return (
    <div className={mainClass.root}>
      <ModalCustom {...props}>
        <div className={mainClass.wrapp}>
          <Typography variant="h5" className={mainClass.title}>
            {t('mystore.title.Switch Business')}
          </Typography>
          <div className={mainClass.listBusiness}>
            {listStore.length
              ? listStore.map(itemStore => (
                  <StoreItemBusiness
                    key={itemStore._id}
                    data={itemStore}
                    onChoseBusinessItem={() =>
                      handleChoseBusinessItem(itemStore._id)
                    }
                    active={itemStore._id === storeCurrent._id}
                  />
                ))
              : null}
            <div
              className={clsx(
                mainClass.businessItem,
                mainClass.businessItemAdd
              )}
              onClick={handleAddBusinessItem}>
              <div
                className={clsx(
                  mainClass.thumbBusiness,
                  mainClass.addBussiness
                )}>
                <AddIcon className={mainClass.iconAdd} />
              </div>
              <Typography
                variant="subtitle1"
                className={mainClass.descBusiness}>
                {t('business.create.title.Add Business')}
              </Typography>
            </div>
          </div>
        </div>
      </ModalCustom>
    </div>
  );
};
const mapStateToProps = state => {
  return {
    profile: getUserProfile(state),
    needFetchVendorGetStore: getNeedFetchVendorGetStore(state)
  };
};
const mapDispatchToProps = dispatch => {
  return {
    setLoading: status => dispatch(actSetLoading(status)),
    fetchVendorGetStore: () =>
      dispatch(getStoresByVendor({}, queryVendorGetStores)),
    vendorChooseStoreRequest: params =>
      dispatch(vendorChooseStore(params, null)),
    reFetchUserProfile: () => dispatch(fetchUserProfile(null, queryProfile)),
    toggleModalAddBusiness: value => dispatch(actToggleModalAddBusiness(value)),
    toggleNeedFetchVendorGetStore: value =>
      dispatch(actToggleNeedFetchVendorGetStore(value))
  };
};
export default compose(
  withRouter,
  connect(
    mapStateToProps,
    mapDispatchToProps
  )
)(SwitchBusinessModal);
