import React from 'react';
import clsx from 'clsx';
import { useTranslation } from 'react-i18next';
import { CurrencySymbol } from '../../CurrencySymbol';
import useStyle from './style';

const TotalPrice = React.memo(props => {
  const mainClass = useStyle();
  const { t } = useTranslation();
  // let bgPromote = null;

  // switch (props.typePromote) {
  //   case 'white':
  //     bgPromote = mainClass.bgWhite;
  //     break;
  //   default:
  //     bgPromote = mainClass.bgViolte;
  //     break;
  // }

  const {
    amount,
    discount,
    promotion,
    serviceFee,
    deliveryFee,
    productsPrice
  } = props.dataTotalPrice;

  const { name: namePromo } = promotion || {};

  const { methodDelivery } = props;

  return (
    <div className={mainClass.root}>
      <p className={clsx(mainClass.itemSpacebetween, mainClass.subTotal)}>
        <span className={mainClass.unit}>
          {t('order.priceDetail.label.Subtotal')}
        </span>
        <span className={mainClass.price}>
          <CurrencySymbol>{parseFloat(productsPrice)}</CurrencySymbol>
        </span>
      </p>
      {deliveryFee ? (
        <p className={clsx(mainClass.itemSpacebetween, mainClass.subTotal)}>
          <span className={mainClass.unit}>
            {t('order.priceDetail.label.Delivery fee')}
          </span>
          <span className={mainClass.price}>
            <CurrencySymbol>{parseFloat(deliveryFee)}</CurrencySymbol>
          </span>
        </p>
      ) : null}
      {serviceFee ? (
        <p className={clsx(mainClass.itemSpacebetween, mainClass.subTotal)}>
          <span className={mainClass.unit}>
            {t('order.priceDetail.label.Service fee')}
          </span>
          <span className={mainClass.price}>
            <CurrencySymbol>{parseFloat(serviceFee)}</CurrencySymbol>
          </span>
        </p>
      ) : null}
      {namePromo ? (
        <p className={clsx(mainClass.itemSpacebetween, mainClass.subTotal)}>
          <span className={mainClass.unit}>
            {t('order.priceDetail.label.Promotion')}
            <span className={clsx(mainClass.promote)}>
              <span className={mainClass.stylePromoteTxt}>{namePromo}</span>

              <span
                className={clsx(mainClass.circle, mainClass.circleLeft)}></span>
              <span
                className={clsx(
                  mainClass.circle,
                  mainClass.circleRight
                )}></span>
            </span>
          </span>
          <span className={mainClass.price}>
            -<CurrencySymbol>{parseFloat(discount)}</CurrencySymbol>
          </span>
        </p>
      ) : null}

      {productsPrice ? (
        <p className={clsx(mainClass.itemSpacebetween, mainClass.subTotal)}>
          <span className={mainClass.unit}>
            {t('order.priceDetail.label.Total')}
          </span>
          <span className={mainClass.price}>
            <CurrencySymbol>{parseFloat(amount)}</CurrencySymbol>
          </span>
        </p>
      ) : null}

      <div className={clsx(mainClass.itemSpacebetween, mainClass.totalPrimary)}>
        <span className={mainClass.totalUnit}>
          {t('order.priceDetail.label.AMOUNT')}
        </span>
        <span className={mainClass.totalPrice}>
          {' '}
          {methodDelivery === 'self-delivery' ? (
            <CurrencySymbol>{productsPrice + deliveryFee}</CurrencySymbol>
          ) : (
            <CurrencySymbol>{productsPrice}</CurrencySymbol>
          )}
        </span>
      </div>
    </div>
  );
});

export default TotalPrice;
