import { Tooltip } from '@material-ui/core';
import Box from '@material-ui/core/Box';
import ListItem from '@material-ui/core/ListItem';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import ListItemText from '@material-ui/core/ListItemText';
import clsx from 'clsx';
import moment from 'moment';
import React from 'react';

import avatarAdmin from '../../../assets/img/icons/logo.svg';
import userDefault from '../../../assets/img/product-item-default.png';
import ExternalImage from '../../ImgWithOnload';
import useStyles from './style';

function Message(props) {
  const classes = useStyles();

  const { message, profile } = props;

  const { sender } = message;
  const senderRole = message.senderRole;

  const name = message.sender.name;

  let avtarChat =
    senderRole === 'super-admin' ? avatarAdmin : sender.avatar || userDefault;

  const renderRole = (Role, userName) => {
    switch (Role) {
      case 'user':
        return `${userName || ''} (Customer)`;
      case 'vendor':
        return `${userName || profile.name} (Seller)`;
      case 'deliver':
        return `${userName || ''} (Driver)`;
      case 'admin':
      case 'super-admin':
        return 'Maua Rep';
      default:
        break;
    }
  };

  const renderMedia = (medias, currUser) => {
    const classWrapp = currUser
      ? classes.userDescChatCur
      : classes.userDescChatAns;
    const hasCol2 = medias.length % 3 === 2;
    const hasCol1 = medias.length % 3 === 1;

    return (
      <Box className={clsx(classes.wrappMedia, classWrapp)}>
        {medias.map((media, idx) => {
          const styleCol2 =
            hasCol2 && (medias.length - 1 === idx || medias.length - 2 === idx)
              ? classes.col2
              : classes.colDefaul;
          const clssSubMedia = hasCol2
            ? styleCol2
            : hasCol1 && medias.length - 1 === idx
            ? classes.col1
            : classes.colDefaul;
          return (
            <ExternalImage
              key={idx}
              src={media}
              alt="Media"
              className={clsx(classes.media, clssSubMedia)}
            />
          );
        })}
      </Box>
    );
  };

  return (
    <>
      {senderRole !== 'vendor' ? (
        <ListItem className={classes.messageBox}>
          <Box display="flex" alignItems="flex-start">
            <ListItemAvatar className={classes.messageAvatar}>
              <img
                src={avtarChat}
                alt="avatar"
                className={classes.avatarUserChat}
              />
            </ListItemAvatar>
            <ListItemText
              className={classes.messageText}
              primary={
                <div className={classes.infoUser}>
                  <h4 className={classes.userName}>
                    {renderRole(senderRole, name)}
                  </h4>
                </div>
              }
            />
          </Box>
          <Tooltip
            title={moment(message.updatedAt).format('YYYY-MM-DD HH:mm:ss')}
            placement="right-start">
            <Box className={classes.userDesc} style={{ marginLeft: 6 }}>
              {message.content}
              {message.media && message.media.length
                ? renderMedia(message.media, false)
                : null}
            </Box>
          </Tooltip>
        </ListItem>
      ) : (
        <ListItem className={classes.messageBox + ' ' + classes.own}>
          <Tooltip
            title={moment(message.updatedAt).format('YYYY-MM-DD HH:mm:ss')}
            placement="left-start">
            <Box className={classes.userDesc + ' ' + classes.ownContent}>
              {message.content}
              {message.media && message.media.length
                ? renderMedia(message.media, false)
                : null}
            </Box>
          </Tooltip>
        </ListItem>
      )}
    </>
  );
}

export default Message;
