import { createSlice } from 'redux-starter-kit';

const initialState = {
  symbol: ''
};

export const currencySlice = createSlice({
  initialState: initialState,
  slice: 'currency',
  reducers: {
    setCurrency: (state, action) => {
      state.symbol = action.payload.symbol;
    }
  }
});

export default currencySlice.reducer;
