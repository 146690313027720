import { makeStyles } from '@material-ui/core';
import { primaryFont } from '../../shared/theme';

export const useStyle = makeStyles(theme => ({
  btnImport: {
    height: 40,
    position: 'relative',
    width: 148,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    fontFamily: primaryFont,
    borderRadius: 200,
    fontSize: 14,
    cursor: 'pointer'
  },
  icon: {
    marginRight: 8,
    width: 20
  },
  iconDisabled: {
    marginRight: 8,
    width: 20,
    cursor: 'default',
    border: `1px solid transparent`,
    backgroundColor: theme.palette.color.white3,
    color: theme.palette.color.white
  },
  btnAction: {
    width: 128,
    height: 48,
    textTransform: 'initial',
    marginLeft: '0 !important'
  },
  file: {
    position: 'relative',
    display: 'flex',
    alignItems: 'center',
    padding: theme.spacing(1.5, 1),
    '&:hover': {
      background: '#e38d03',
      color: theme.palette.color.white,
      transition: 'all 0.2s ease',
      '& img': {
        filter: 'brightness(0) invert(1)'
      }
    }
  },
  optionFile: {
    position: 'absolute',
    top: 48,
    zIndex: 999,
    color: '#2F2E2E',
    background: '#fff',
    width: '100%',
    borderRadius: '0 0 8px 8px',
    borderBottom: '1px solid #ccc'
  },
  typePrimary: {
    background: theme.palette.primary.main,
    color: theme.palette.color.white,
    '& img': {
      filter: 'brightness(0) invert(1)'
    }
  },
  typeDefault: {
    background: theme.palette.color.white,
    color: theme.palette.color.black2,
    border: `1px solid ${theme.palette.primary.main}`
  }
}));
