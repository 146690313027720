export const queryOrderList = `
  _id
  reason
  code
  phone
  customer {
    _id
    phone
    avatar
    name
    firstName
    lastName
    store {
      _id
    }
    address {
      _id
      distance
    }
  }
  products {
    product {
      _id
      store {
        _id
      }
      name
      status
      description
      countOrder
      quantity
      price
      media
      tags
      createdAt
    }
    qty
    price
    discount
    profit
    total
    note
    media
  }
  delivery {
    code
    deliveryMethod
    status
    reason
  }
  deliveryMethod
  paymentMethod
  status
  statusText
  reason
  productsPrice
  subTotal
  deliveryFee
  transferFee
  serviceFee
  discount
  amount
  profit
  note
  address {
    _id
    name
    village
    country
  }
  createdAt
  updatedAt
`;

export const queryProductOrderById = `
  products {
    product {
      _id
      quantity
      price

    }
    name
    media
    variantOptions {
      _id
      name
      price
      variant {
        _id
        name
      }
    }
    variantOptions {
      name 
      price
      variant {
        name
        _id
      }
       _id
    }
    qty
    price
    discount
    discountedPrice
    profit
    total
    note
    media
  }
  customer {
    _id
    avatar
    name
    phone
  }
  transaction {
    _id
    status
    amount
    code
    updatedAt
  }
  productsPrice
  deliveryMethod
  subTotal
  deliveryFee
  serviceFee
  statusText
  delivery{
    code
    status
    prevStatus
    deliveryMethod
    reason
    _id
  }
  address {
    name
    village
    alaNum
    country
    distance
  }
  amount
  discount
  profit
  paymentMethod
  status
  _id
  createdAt
  updatedAt
  store {
    name
    _id
  }
  code
  review{
    _id
  }
  promotion {
    name
    code
    startTime
    endTime
    status
    discount {
      type
      value
      min
      max
    }
    applyFor
  }
`;

export const queryCountProductOrders = `
  new
  inProgress
  ready
  failed
  completed
`;

export const queryStatisticAmount = `
  column
  value
`;
