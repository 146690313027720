import React from 'react';
import OutlinedInput from '@material-ui/core/OutlinedInput';
import FormControl from '@material-ui/core/FormControl';
import TextField from '@material-ui/core/TextField';
import { FormHelperText } from '@material-ui/core';
import MenuItem from '@material-ui/core/MenuItem';
import { DatePicker } from '@material-ui/pickers';
import Select from '@material-ui/core/Select';
import { ErrorMessage } from 'formik';
import { MAX_DATE } from './schema';

export function CustomTextField({
  field,
  form: { touched, errors },
  ...props
}) {
  return (
    <TextField
      {...field}
      value={field.value || ''}
      error={Boolean(touched[field.name] && errors[field.name])}
      helperText={errors[field.name]}
      {...props}
    />
  );
}

export function DatePickerField({
  field,
  form: { touched, errors },
  ...props
}) {
  return (
    <DatePicker
      {...field}
      error={Boolean(touched[field.name] && errors[field.name])}
      helperText={errors[field.name]}
      maxDate={MAX_DATE}
      margin="dense"
      variant="inline"
      inputVariant="outlined"
      autoOk
      fullWidth
      {...props}
    />
  );
}

export function GenderSelectField({
  field,
  form: { touched, errors },
  ...props
}) {
  return (
    <FormControl
      margin="dense"
      variant="outlined"
      error={Boolean(touched[field.name] && errors[field.name])}
      fullWidth>
      <Select
        {...field}
        value={field.value || ''}
        input={<OutlinedInput fullWidth />}
        displayEmpty
        {...props}>
        <MenuItem value="">Gender</MenuItem>
        <MenuItem value="male">Male</MenuItem>
        <MenuItem value="female">Female</MenuItem>
      </Select>
      <ErrorMessage
        name={field.name}
        render={message => (
          <FormHelperText variant="outlined" error>
            {message}
          </FormHelperText>
        )}
      />
    </FormControl>
  );
}
