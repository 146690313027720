import React, { memo } from 'react';
import Avatar from '@material-ui/core/Avatar';
import { Box, Tooltip } from '@material-ui/core';
import * as PropTypes from 'prop-types';
import switchIcon from '../../../assets/img/icons/repeat-icon.png';
import { useStyle } from './style';

function SwitchBusiness({ controlIcon, profile, switchBusiness }) {
  const classes = useStyle();

  return (
    <Box className={controlIcon}>
      {!!profile && profile._id && (
        <Tooltip title="Switch Business">
          <Avatar
            src={switchIcon}
            className={classes.icon}
            onClick={switchBusiness}
          />
        </Tooltip>
      )}
    </Box>
  );
}

SwitchBusiness.propTypes = {
  controlIcon: PropTypes.any
};

export default memo(SwitchBusiness);
