import { makeStyles } from '@material-ui/core';
import { secondaryFont, primaryFont } from '../../../shared/theme';

const useStyle = makeStyles(theme => ({
  wrappNavbar: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    margin: 0,
    padding: 0,
    listStyle: 'none',
    '& li:last-child': {
      paddingRight: 0
    }
  },

  boxContent: {
    padding: 24
  },

  search: {
    '& .MuiInputBase-input::-webkit-input-placeholder': {
      fontSize: 16
    },
    '& svg': {
      opacity: 0.42
    }
  },

  navItemFilter: {
    position: 'relative',
    display: 'flex',
    alignItems: 'center',
    padding: ' 8px 8px',
    overflow: 'hidden',
    cursor: 'pointer',
    background: theme.palette.color.orange,
    borderRadius: 5,
    transition: 'all .5s ease-in-out',
    width: 72,
    color: theme.palette.color.black2,
    marginLeft: theme.spacing(2),
    '& svg': {
      position: 'absolute',
      height: 20,
      width: 20,
      right: 8
    }
  },

  filter: {
    minWidth: 100,
    display: 'flex',
    justifyContent: 'space-between',
    [theme.breakpoints.only('xs')]: {
      minWidth: 'unset'
    }
  },

  active: {
    width: 140
  },
  navItem: {
    display: 'flex',
    padding: `20px 30px 20px 0`
  },
  navItemContent: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    cursor: 'pointer',
    '&:hover span:nth-child(1)': {
      color: theme.palette.color.orange
    },
    '&:hover span:nth-child(2)': {
      backgroundColor: theme.palette.color.orange,
      color: theme.palette.color.white
    }
  },
  text: {
    display: 'flex',
    alignItems: 'center',
    fontSize: 16,
    fontWeight: 700,
    fontFamily: secondaryFont,
    transition: 'all .35s'
  },
  num: {
    display: 'flex',
    marginLeft: '8px',
    width: 27,
    height: 27,
    borderRadius: '50%',
    backgroundColor: theme.palette.color.white2,
    justifyContent: 'center',
    alignItems: 'center',
    fontSize: 11,
    color: theme.palette.color.gray1,
    fontFamily: secondaryFont,
    transition: 'all .35s'
  },
  textActive: {
    color: theme.palette.color.orange
  },
  numActive: {
    backgroundColor: theme.palette.color.orange,
    color: theme.palette.color.white
  },
  rightItem: {
    marginLeft: 'auto',
    display: 'flex',
    alignItems: 'center',
    [theme.breakpoints.only('xs')]: {
      width: '100%'
    }
  },
  action: {
    marginLeft: 'auto',
    width: 'fit-content',
    marginTop: 24,
    [theme.breakpoints.down('md')]: {
      display: 'flex'
    }
  },
  btnExport: {
    height: 40,
    position: 'relative',
    width: 148,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    fontFamily: primaryFont,
    borderRadius: 200,
    fontSize: 14,
    color: theme.palette.color.white,
    cursor: 'pointer',
    background: theme.palette.primary.main,
    boxShadow:
      '0px 1px 5px 0px rgba(0,0,0,0.2), 0px 2px 2px 0px rgba(0,0,0,0.14), 0px 3px 1px -2px rgba(0,0,0,0.12)',
    [theme.breakpoints.only('xs')]: {
      width: 'auto'
    }
  },

  btnFilter: {
    height: 40,
    position: 'relative',
    width: 148,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    fontFamily: primaryFont,
    borderRadius: 200,
    fontSize: 14,
    color: theme.palette.color.white,
    cursor: 'pointer',
    background: theme.palette.primary.main,
    boxShadow:
      '0px 1px 5px 0px rgba(0,0,0,0.2), 0px 2px 2px 0px rgba(0,0,0,0.14), 0px 3px 1px -2px rgba(0,0,0,0.12)',
    [theme.breakpoints.only('xs')]: {
      width: '50%'
    }
  },
  typePrimary: {
    background: theme.palette.primary.main,
    color: theme.palette.color.white,
    '& img': {
      filter: 'brightness(0) invert(1)'
    }
  },
  typeDefault: {
    background: theme.palette.color.white,
    color: theme.palette.color.black2,
    border: `1px solid ${theme.palette.primary.main}`,
    boxShadow: 'unset'
  },
  field: {
    width: '100%',
    '& .MuiInputLabel-outlined.MuiInputLabel-marginDense': {
      transform: 'translate(14px, 14px) scale(0.75)'
    },
    '& .MuiInputLabel-outlined.MuiInputLabel-shrink': {
      transform: 'translate(14px, -6px) scale(0.75)'
    },
    '& .MuiOutlinedInput-input': {
      padding: '12px 14px'
    },
    '& .MuiInputLabel-outlined': {
      transform: 'translate(14px, 14px) scale(1)'
    }
  },

  exportTitile: {
    padding: '0 0 14px 0'
  },

  btn: {
    height: 40,
    width: 148,
    marginLeft: 10,
    [theme.breakpoints.only('xs')]: {
      width: 'unset'
    }
  },

  dialog: {
    '& .MuiDialog-paper': {
      overflow: 'visible',
      borderRadius: '10px'
    },
    '& .MuiDialogContent-root': {
      overflow: 'visible'
    }
  },

  checkBoxContainer: {
    borderRadius: 4,
    display: 'flex',
    flexDirection: 'row',
    border: '1px solid #ccc'
  },

  labelCheckbox: {
    color: 'rgb(128, 128, 128)',
    fontSize: '12px',
    marginLeft: '10px',
    padding: '0 0 0 5px'
  },

  checkBoxs: {
    display: 'flex',
    flexDirection: 'row',
    width: '100%',
    '& .MuiFormControlLabel-root': {
      width: '50%',
      margin: 0,
      paddingLeft: -10
    }
  },

  datePicker: {
    width: '100%',
    '&:hover fieldset': {
      border: `1px solid ${theme.palette.color.gray} !important`
    },
    '&:focus-within fieldset': {
      border: '1px solid #E38D03 !important'
    },
    '& .MuiInputBase-root.MuiOutlinedInput-root.MuiInputBase-formControl': {
      height: 46
    },
    '& .MuiInputLabel-outlined.MuiInputLabel-marginDense': {
      transform: 'translate(16px, 16px) scale(0.75)'
    },

    '& .MuiInputLabel-outlined.MuiInputLabel-shrink': {
      transform: 'translate(14px, -6px) scale(0.75)'
    },
    '& .MuiOutlinedInput-adornedEnd': {
      paddingRight: 4,
      background: 'transparent'
    },
    '& .MuiInputLabel-outlined': {
      transform: 'translate(16px, 16px) scale(1)'
    },
    '& label': {
      fontSize: 14
    },
    '& input': {
      paddingRight: '0',
      height: '46px',
      padding: '0 0 0 14px'
    },
    '& button': {
      padding: 0
    }
  }
}));
export default useStyle;
