import { makeStyles } from '@material-ui/core';
import { secondaryFont, primaryFont } from '../../shared/theme';

const useStyle = makeStyles(theme => ({
  root: {
    background: 'rgba(247, 247, 247, .5)',
    fontFamily: primaryFont,
    marginTop: 68
  },

  container: {
    margin: theme.spacing(2.4, 0),
    display: 'flex',
    flexDirection: 'column',
    boxShadow: '0px 8px 10px rgba(32, 71, 101, 0.02)',
    [theme.breakpoints.down('md')]: {
      overflowX: 'scroll',
      overflowY: 'auto',
      transform: 'rotateX(180deg)'
    }
  }
}));

export default useStyle;

export const useStyleModal = makeStyles(theme => ({
  modalContent: {
    padding: `25px 0 0`
  }
}));
