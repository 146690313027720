import moment from 'moment';

export const DELIVERY_METHOD = {
  DELIVERY: 'service',
  SELF_PICKUP: 'self-pickup',
  METHOD: 'method',
  OTP: 'otp',
  PAYMENT: 'payment'
};

export const optionsTime = [
  {
    label: 'This week',
    value: 'weekly',
    startTime: moment()
      .startOf('week')
      .toISOString(),
    endTime: moment()
      .endOf('week')
      .toISOString()
  },
  {
    label: 'This month',
    value: 'monthly',
    startTime: moment()
      .startOf('month')
      .toISOString(),
    endTime: moment()
      .endOf('month')
      .toISOString()
  },
  {
    label: 'This year',
    value: 'yearly',
    startTime: moment()
      .startOf('year')
      .toISOString(),
    endTime: moment()
      .endOf('year')
      .toISOString()
  }
];

export const optionsFullTime = [
  ...[
    {
      label: 'Today',
      value: 'Today',
      startTime: moment()
        .startOf('day')
        .toISOString(),
      endTime: moment()
        .endOf('day')
        .toISOString()
    }
  ],
  ...optionsTime
];

export const xaxisTodayWeekly = [
  'Sun',
  'Mon',
  'Tue',
  'Wed',
  'Thu',
  'Fri',
  'Sat'
];

export const STATUS = {
  active: true,
  inactive: false
};
export const STATUS_COLOR = {
  'waiting-approve': '#ffcf62',
  active: '#0f9e2e',
  inactive: '#ccc',
  reject: '#f44336'
};

export const STATUS_TEXT = {
  'waiting-approve': 'Pending',
  active: 'Active',
  inactive: 'Inactive',
  reject: 'Reject'
};
