import React, { useState, useEffect, useMemo } from 'react';
import { connect } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { AutoSizer, List } from 'react-virtualized';
import {
  addCategory,
  editCategory,
  deleteCategories
} from 'maua-redux-core/actions/category';
import FiberManualRecordIcon from '@material-ui/icons/FiberManualRecord';
import { Box, TextField } from '@material-ui/core';
import DeleteIcon from '@material-ui/icons/Delete';
import EditIcon from '@material-ui/icons/Edit';
import { TOAST_TYPE, toastMessage } from '../../utils/toastify-notify';
import SelectCustom from '../../components/FormFeild/SelectCustom';
import SwitchCustom from '../../components/FormFeild/SwitchCustom';
import { STATUS, STATUS_COLOR } from '../../utils/constant';
import AlertDialog from '../../components/AlertDialog';
import { queryMenus } from '../../store/query/menu';
import Button from '../../components/Button';
import { useStyle } from './style';

const LeftColumn = props => {
  const classes = useStyle();
  const { t } = useTranslation();
  const [formData, setFormData] = useState({
    data: [],
    record: {},
    mainCategory: '',
    subCategory: '',
    type: ''
  });
  const [modal, setModal] = useState({
    open: false,
    data: {},
    title: ''
  });

  const isValid = !(
    formData?.subCategory?.length &&
    formData?.mainCategory?.length &&
    formData?.type?.length
  );

  useEffect(() => {
    setFormData(d => ({
      ...d,
      data: props.menus.filter(({ isCategory }) => isCategory)
    }));
  }, [props.menus]);

  const handleScrollToIndex = row => {
    const index = props.menus.findIndex(({ _id }) => _id === row._id);
    props.setScrollToIndex(index);
  };

  const handleAdd = async () => {
    try {
      const payload = {
        name: formData.subCategory,
        parent: formData.mainCategory,
        store: props.storeID,
        type: formData.type
      };

      const { addCategory } = await props.addCategory(payload);

      props.setMenus(menu => [{ ...addCategory, isCategory: true }, ...menu]);

      setFormData(d => ({
        ...d,
        record: {},
        subCategory: '',
        mainCategory: '',
        type: ''
      }));

      toastMessage(
        TOAST_TYPE.SUCCESS,
        `${t('product.product.label.Sub category has been added')}.`
      );
    } catch (error) {
      toastMessage(TOAST_TYPE.ERROR, error.message);
    }
  };

  const handleEdit = async () => {
    try {
      const payload = {
        _id: formData.record?._id,
        name: formData.subCategory,
        parent: formData.mainCategory,
        store: props.storeID,
        type: formData.type
      };

      const { editCategory } = await props.editCategory(payload);

      props.setMenus(menu => {
        return menu.map(m => {
          if (m._id === editCategory._id) {
            return {
              ...m,
              ...editCategory
            };
          }
          return m;
        });
      });

      setFormData(d => ({
        ...d,
        record: {},
        subCategory: '',
        mainCategory: '',
        type: ''
      }));
      toastMessage(
        TOAST_TYPE.SUCCESS,
        `${t('product.product.label.Sub category has been updated')}.`
      );
    } catch (error) {
      toastMessage(TOAST_TYPE.ERROR, error.message);
    }
  };

  const handleRemove = async record => {
    record.e.stopPropagation();
    try {
      await props.deleteCategories({ _ids: [record._id] });

      props.setMenus(menu => {
        menu.splice(record.index, 1);
        return [...menu];
      });

      setFormData(d => ({
        ...d,
        record: {},
        subCategory: '',
        mainCategory: '',
        type: ''
      }));

      toastMessage(
        TOAST_TYPE.SUCCESS,
        `${t('product.product.label.Sub category has been removed')}.`
      );
    } catch (error) {
      toastMessage(TOAST_TYPE.ERROR, error.message);
    }
  };

  const handleStatus = async record => {
    const status = record.status === 'active' ? 'inactive' : 'active';

    try {
      const payload = {
        _id: record?._id,
        status
      };

      const { editCategory } = await props.editCategory(payload);

      props.setMenus(menu => {
        return menu.map(m => {
          if (m._id === editCategory._id) {
            m.status = status;
          }

          return m;
        });
      });

      toastMessage(
        TOAST_TYPE.SUCCESS,
        `${t(
          'toastMessage.success.content.Status of sub category has been updated'
        )}`
      );
    } catch (error) {
      toastMessage(TOAST_TYPE.ERROR, error.message);
    }
  };

  const handleConfirm = () => {
    if (modal.type === 'status') {
      handleStatus(modal.data);
    }

    if (modal.type === 'remove') {
      handleRemove(modal.data);
    }

    setModal(() => ({ open: false, data: {}, title: '' }));
  };

  const rowCategories = ({ key, index, style }) => {
    return (
      <Box
        key={key}
        style={style}
        className={classes.subCategoryItem}
        onClick={() => handleScrollToIndex(formData.data[index])}>
        <FiberManualRecordIcon
          style={{
            marginRight: 4,
            fontSize: 16,
            color: STATUS_COLOR[(formData.data[index]?.status)]
          }}
        />
        <Box width={250} className={classes.threedots}>
          {formData.data[index]?.name}
        </Box>
        <Box id="boxBtnSubCategory" className={classes.boxActions}>
          <EditIcon
            color="primary"
            onClick={e => {
              e.stopPropagation();
              setFormData(d => ({
                ...d,
                record: formData.data[index],
                subCategory: formData.data[index]?.name,
                mainCategory: formData.data[index]?.parent?._id,
                type: formData.data[index]?.type
              }));
            }}
          />

          {(formData.data[index]?.status === 'active' ||
            formData.data[index]?.status === 'inactive') && (
            <Box onClick={e => e.stopPropagation()}>
              <SwitchCustom
                handleChange={() =>
                  setModal(() => ({
                    open: true,
                    data: formData.data[index],
                    type: 'status',
                    title: `${t(
                      'product.modal.title.This will change status of the category'
                    )}.`
                  }))
                }
                checkedVal={STATUS[(formData.data[index]?.status)]}
                title={
                  STATUS[formData.data[index].status]
                    ? t('product.status.Active')
                    : t('product.status.Inactive')
                }
              />
            </Box>
          )}

          {formData.data[index].status === 'inactive' && (
            <DeleteIcon
              style={{ color: 'red' }}
              onClick={e =>
                setModal(() => ({
                  open: true,
                  data: { e, _id: formData.data[index]._id, index },
                  type: 'remove',
                  title: `${t(
                    'product.delete.title.This will remove the category'
                  )}.`
                }))
              }
            />
          )}
        </Box>
      </Box>
    );
  };

  return (
    <Box borderRight="1px solid #E7EEF3">
      <Box className={classes.leftColumn}>
        <Box className={classes.subHeader}>{t('product.title.Menu')}</Box>
        <Box className={classes.addMenu}>
          <SelectCustom
            label={t('product.label.Main Category')}
            width="100%"
            options={props.optionMain}
            value={formData?.mainCategory}
            onChange={e => {
              let mainCategory = e.target.value;
              setFormData(d => ({ ...d, mainCategory }));
            }}
          />
          <Box mt={1.6}>
            <SelectCustom
              label={t('product.label.Type')}
              width="100%"
              options={[
                { label: 'Product', value: 'Product' },
                { label: 'Service', value: 'Service' }
              ]}
              value={formData?.type}
              onChange={e => {
                let type = e.target.value;
                setFormData(d => ({ ...d, type }));
              }}
            />
          </Box>
          <TextField
            label={t('product.label.Sub category')}
            variant="outlined"
            id="margin-none"
            value={formData?.subCategory}
            className={classes.textField}
            onChange={e => {
              let subCategory = e.target.value;
              setFormData(d => ({ ...d, subCategory }));
            }}
          />

          <Box className={classes.flex} justifyContent="space-between">
            <Button
              className={classes.btnMenu}
              disabled={
                !(
                  formData?.subCategory?.length ||
                  formData?.mainCategory?.length ||
                  formData?.type?.length
                )
              }
              onClick={() =>
                setFormData(d => ({
                  ...d,
                  record: {},
                  subCategory: '',
                  mainCategory: '',
                  type: ''
                }))
              }>
              {t('action.Cancel')}
            </Button>
            <Button
              typeBtn="yellow"
              disabled={isValid}
              className={classes.btnMenu}
              onClick={formData?.record._id ? handleEdit : handleAdd}>
              {formData?.record._id ? t('action.Save') : t('action.Add')}
            </Button>
          </Box>
        </Box>
        <Box className={classes.leftColumContent}>
          <AutoSizer>
            {({ width, height }) => (
              <List
                width={width}
                height={height}
                rowHeight={42}
                rowCount={formData.data?.length}
                rowRenderer={rowCategories}
                className={classes.list}
              />
            )}
          </AutoSizer>
        </Box>
      </Box>

      {modal.open && (
        <AlertDialog
          open={modal.open}
          handleClose={() =>
            setModal(() => ({ open: false, data: {}, title: '' }))
          }
          handleConfirm={handleConfirm}
          textTileDialog={t(modal.title)}
        />
      )}
    </Box>
  );
};

export default connect(
  null,
  dispatch => ({
    addCategory: payload => dispatch(addCategory(payload, queryMenus)),
    editCategory: payload => dispatch(editCategory(payload, queryMenus)),
    deleteCategories: payload => dispatch(deleteCategories(payload))
  })
)(LeftColumn);
