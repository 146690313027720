import { makeStyles } from '@material-ui/core';

export const useStyle = makeStyles(theme => ({
  action: {
    padding: '0px 24px 24px'
  },
  dialogContent: {
    borderRadius: 8
  },
  supportedCountries: {
    position: 'relative',
    display: 'flex',
    justifyContent: 'center',
    gap: 10,
    alignItems: 'center',
    marginTop: 32,
    '& img': {
      width: '100%',
      height: '100%',
      objectFit: 'cover',
      borderRadius: 8
    },
    [theme.breakpoints.down('md')]: {
      flexDirection: 'column'
    }
  },
  imgContent: {
    width: 320,
    height: 160,
    borderRadius: 8,
    margin: '0px 10px 8px 10px',
    opacity: 0.6,
    overFlow: 'hidden',
    cursor: 'pointer',
    '&:hover': {
      outline: `3px solid ${theme.palette.primary.main}`,
      transition: 'outline 0.1s '
    },
    [theme.breakpoints.down('md')]: {
      width: '100%',
      height: 'auto',
      '& img': {
        objectFit: 'contain'
      },
      margin: 0
    }
  },
  flag: {
    width: 70,
    height: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    cursor: 'pointer',
    borderLeft: '1px solid #F2F2F2',

    '&:hover': {
      borderBottom: `2px solid ${theme.palette.primary.main}`
    }
  },
  title: {
    fontWeight: '700',
    fontSize: 24,
    lineHeight: '25px',
    fontFamily: "'Muli', sans-serif"
  }
}));
