import React, { useState, useEffect } from 'react';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import clsx from 'clsx';
import { useTranslation } from 'react-i18next';

import MuiDialogTitle from '@material-ui/core/DialogTitle';
import MuiDialogContent from '@material-ui/core/DialogContent';
import Grid from '@material-ui/core/Grid';
import AddIcon from '@material-ui/icons/Add';
// import { useTranslation } from 'react-i18next';
import { Typography } from '@material-ui/core';

import {
  getStoresByVendor,
  vendorChooseStore
} from 'maua-redux-core/actions/store';
import { logout, fetchUserProfile } from 'maua-redux-core/actions/user';
import { getUserProfile } from 'maua-redux-core/selectors/user';

import { useStyles } from './style';

import Button from '../../components/Button';

import { queryVendorGetStores } from '../../store/query/store';
import { actSetLoading } from '../../store/actions/loading';
import { toastMessage, TOAST_TYPE } from '../../utils/toastify-notify';
import { isEmptyObject } from '../../utils/common';
import { destroyCredentials } from '../../shared/services/token';
import { actDestroyProfile } from '../../store/actions/user';
import { queryProfile, queryProfileLogin } from '../../store/query/user';

import defaultImg from '../../assets/img/default.jpg';

const StoreItemBusiness = props => {
  const mainClass = useStyles();
  const { name, media } = props.data;
  const imgSrc = (media && media[0]) || defaultImg;
  return (
    <Grid item xs={4}>
      <div
        className={mainClass.addBusinessItem}
        onClick={props.onChoseBusinessItem}>
        <div className={mainClass.thumbBusiness}>
          <img src={imgSrc} alt={name} className={mainClass.imgThumb} />
        </div>
        <Typography variant="subtitle1" className={mainClass.descBusiness}>
          {name}
        </Typography>
      </div>
    </Grid>
  );
};

const SelectBusinessPage = props => {
  const { removeStore } = props;
  const mainClass = useStyles();
  const { t } = useTranslation();
  const [listStore, setListStore] = useState([]);
  const { profile } = props;
  const hasToken = isEmptyObject(profile);
  const { store } = profile && isEmptyObject(profile) ? profile : {};

  // useEffect(() => {
  //   if (hasToken && store && isEmptyObject(store)) {
  //     props.history.push('/orders/newOrders');
  //     return;
  //   }
  // }, [hasToken]);

  useEffect(() => {
    if (!listStore.length && hasToken) {
      removeStore();
      props.setLoading({ status: true });
      props
        .fetchVendorGetStore()
        .then(({ getStoresByVendor }) => {
          setListStore(getStoresByVendor);
          props.setLoading({ status: false });
        })
        .catch(err => {
          props.setLoading({ status: false });
          toastMessage(TOAST_TYPE.ERROR, err.message);
        });
    }
  }, [hasToken]);

  const handleLogout = () => {
    props.logout();
    destroyCredentials();
    props.onDestroyProfile();
    localStorage.clear();
    sessionStorage.clear();
    props.history.push('/');
  };

  const handleAddBusinessItem = () => {
    props.history.push('/complete-profile');
    return;
  };
  const handleChoseBusinessItem = async idStore => {
    sessionStorage.setItem('canceledSyncLocation', false);

    let params = {
      _id: idStore
    };

    try {
      props.setLoading({ status: true });
      let { vendorChooseStore } = await props.vendorChooseStoreRequest(params);

      if (vendorChooseStore) {
        props.setLoading({ status: false });
        // toastMessage(TOAST_TYPE.SUCCESS, 'Select business success!');
        props.reFetchUserProfile();
        props.history.push('/orders/newOrders');
        return;
      }
    } catch (err) {
      props.setLoading({ status: false });
      toastMessage(TOAST_TYPE.ERROR, err.message);
    }
  };
  return (
    <div className={mainClass.container}>
      <div className={mainClass.inner}>
        <MuiDialogTitle disableTypography className={mainClass.rootDialogTitle}>
          <h4 className={mainClass.title}>
            {t('business.select.title.Select business')}
          </h4>
        </MuiDialogTitle>
        <MuiDialogContent className={mainClass.rootDialogContent}>
          <Grid container spacing={3}>
            {listStore.length
              ? listStore.map(itemStore => (
                  <StoreItemBusiness
                    key={itemStore._id}
                    data={itemStore}
                    onChoseBusinessItem={() =>
                      handleChoseBusinessItem(itemStore._id)
                    }
                  />
                ))
              : null}
            <Grid item xs={4}>
              <div
                className={mainClass.addBusinessItem}
                onClick={handleAddBusinessItem}>
                <div
                  className={clsx(
                    mainClass.thumbBusiness,
                    mainClass.addBussiness
                  )}>
                  <AddIcon className={mainClass.iconAdd} />
                </div>
                <Typography
                  variant="subtitle1"
                  className={mainClass.descBusiness}>
                  {t('action.Add')}
                </Typography>
              </div>
            </Grid>
          </Grid>

          <div className={mainClass.wrappBtn}>
            <Button typeBtn="gray" onClick={handleLogout}>
              {t('action.Logout')}
            </Button>
          </div>
        </MuiDialogContent>
      </div>
    </div>
  );
};

const mapStateToProps = state => {
  return {
    profile: getUserProfile(state)
  };
};

const mapDispatchToProps = dispatch => {
  return {
    setLoading: status => dispatch(actSetLoading(status)),
    fetchVendorGetStore: () =>
      dispatch(getStoresByVendor({}, queryVendorGetStores)),
    logout: () => dispatch(logout()),
    onDestroyProfile: () => dispatch(actDestroyProfile()),
    vendorChooseStoreRequest: params =>
      dispatch(vendorChooseStore(params, null)),
    reFetchUserProfile: () => dispatch(fetchUserProfile(null, queryProfile)),
    removeStore: () => dispatch(fetchUserProfile(null, queryProfileLogin))
  };
};

export default compose(
  withRouter,
  connect(
    mapStateToProps,
    mapDispatchToProps
  )
)(SelectBusinessPage);
