export const queryWallets = `
  _id
  ID
  service
`;

export const queryAddEditWallet = `
  _id
  ID
  service
`;
