import { makeStyles } from '@material-ui/core';
import { secondaryFont, primaryFont } from '../../../shared/theme';

const useStyle = makeStyles(theme => ({
  root: {
    margin: theme.spacing(0, 3),
    paddingBottom: theme.spacing(1.4),
    borderBottom: `1px solid #E6E6E6`
  },
  itemSpacebetween: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center'
  },
  subTotal: {
    margin: theme.spacing(1.2, 0, 0),
    fontSize: 18,
    fontFamily: primaryFont,
    color: theme.palette.color.black2
  },
  totalPrimary: {
    marginTop: 10,
    fontSize: 18,
    fontFamily: primaryFont,
    fontWeight: 500
  },
  totalUnit: {
    color: theme.palette.color.black1,
    textTransform: 'uppercase',
    fontWeight: 600
  },
  totalPrice: {
    color: theme.palette.color.orange,
    fontWeight: 600
  },
  promote: {
    display: 'inline-block',
    verticalAlign: 'middle',
    marginLeft: 10,
    position: 'relative',
    overflow: 'hidden'
  },
  stylePromoteTxt: {
    display: 'inline-block',
    padding: theme.spacing(0.6, 1.2),
    fontSize: 14,
    fontFamily: primaryFont,
    border: '1px solid #3A8E07'
  },
  // bgViolte: {
  //   backgroundColor: 'rgba(187, 107, 217, .3)'
  // },
  // bgWhite: {
  //   backgroundColor: theme.palette.color.white2
  // },
  circle: {
    position: 'absolute',
    top: '50%',
    width: 10,
    height: 10,
    borderRadius: '50%',
    backgroundColor: theme.palette.color.white,
    transform: 'translateY(-50%)',
    border: '1px solid #3A8E07'
  },
  circleLeft: {
    left: -5
  },
  circleRight: {
    right: -5
  }
}));

export default useStyle;
