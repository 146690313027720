import React from 'react';
import { Button } from '@material-ui/core';
import useStyle from './style';

const ButtonUI = props => {
  const classes = useStyle();

  return (
    <>
      {props.type === 'default' && (
        <Button
          {...props}
          type="button"
          color="primary"
          className={[classes.root, props.className].join(' ')}>
          {props.children}
        </Button>
      )}

      {props.type === 'cancel' && (
        <Button
          {...props}
          type="button"
          color="primary"
          className={[classes.root, classes.cancel, props.className].join(' ')}>
          {props.children}
        </Button>
      )}

      {props.type === 'submit' && (
        <Button
          {...props}
          color="primary"
          className={[classes.root, props.className].join(' ')}>
          {props.children}
        </Button>
      )}
    </>
  );
};

ButtonUI.defaultProps = {
  type: 'default'
};

export default ButtonUI;
