import React, { memo } from 'react';
import * as PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { Box, Tooltip } from '@material-ui/core';
import Avatar from '@material-ui/core/Avatar';
import Profile from '../../assets/img/icons/profile.svg';
import LogoutImg from '../../assets/img/icons/logout.svg';
import { useStyle } from './style';

function CustomAvatar({ controlIcon, profile, onLogout, src = null }) {
  const classes = useStyle();

  const { t } = useTranslation();

  return (
    <Box className={controlIcon}>
      {!!profile && profile._id ? (
        <Tooltip title="Log out">
          <Avatar
            alt="Your profile"
            src={src ? src : LogoutImg}
            className={classes.avatar}
            onClick={onLogout}
          />
        </Tooltip>
      ) : (
        <Avatar
          alt="Login"
          src={!!profile.length ? profile : Profile}
          className={classes.defaultAvatar}
        />
      )}
    </Box>
  );
}

CustomAvatar.propTypes = {
  controlIcon: PropTypes.any,
  onLogout: PropTypes.func.isRequired
};

export default memo(CustomAvatar);
