import React, { useEffect } from 'react';
import { connect } from 'react-redux';
// import clsx from 'clsx';
import { useTranslation } from 'react-i18next';
import { Typography } from '@material-ui/core';

import useStyle from './style';
// import ProductService from '../../Orders/ProductService';
import TotalPrice from '../../Orders/TotalPrice';
import FormCustomer from '../../FormCustomer';
import PaymentInfo from '../../Orders/PaymentInfo';
import Button from '../../Button';
import ProductService from '../../Orders/ProductService';
import { actToggleModalChoiseDelivery } from '../../../store/actions/modal';
import { getLoadingStatus } from '../../../store/selectors/loading';
import { actSetLoading } from '../../../store/actions/loading';
import { getOpenDetailOrderItem } from '../../../store/selectors/orders';
import BackToDashBoard from '../../BackToDashBoard';

const DetailOrderItemInprogress = props => {
  const classess = useStyle();
  const { t } = useTranslation();

  useEffect(() => {
    if (props.loading?.status && props.showDetailOrderItem) {
      props.setLoading({ status: false });
    }
  }, [props.loading?.status]);

  const {
    _id,
    code,
    amount,
    address,
    customer,
    products,
    discount,
    subTotal,
    promotion,
    serviceFee,
    deliveryFee,
    paymentMethod,
    productsPrice
  } = props.data;

  const dataTotalPrice = {
    amount,
    discount,
    promotion,
    serviceFee,
    deliveryFee,
    productsPrice: subTotal || productsPrice
  };

  const dataCustomer = {
    customer,
    address,
    productOrderID: _id
  };

  const dataPaymentMethod = {
    paymentMethod
  };

  return (
    <div className={classess.wrapp}>
      {/* <BackToDashBoard handleBackDashboard={props.handleshowDashboards} /> */}
      <Typography variant="h4" className={classess.identity}>
        #{code}
      </Typography>
      <div className={classess.main}>
        <ProductService products={products} />
        <TotalPrice dataTotalPrice={dataTotalPrice} />
        <FormCustomer dataCustomer={dataCustomer} />
        <PaymentInfo order={props.data} />
        <div className={classess.wrappBtn}>
          <Button
            typeBtn="yellow"
            onClick={() => props.openModalChoiseDelivery()}>
            {t('action.Ready for delivery')}
          </Button>
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = (state, ownProps) => {
  return {
    loading: getLoadingStatus(state),
    showDetailOrderItem: getOpenDetailOrderItem(state)
  };
};

const mapDispatchToProps = dispatch => {
  return {
    openModalChoiseDelivery: () => dispatch(actToggleModalChoiseDelivery(true)),
    setLoading: status => dispatch(actSetLoading(status))
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(DetailOrderItemInprogress);
