import { createSelector } from 'redux-starter-kit';
import { productsSlice } from '../reducers/products';

export const getShowDetailTrial = createSelector(
  [productsSlice.selectors.getProducts],
  products => products.showDetailTrial
);

export const getNeedReloadProducts = createSelector(
  [productsSlice.selectors.getProducts],
  products => products.needReloadProducts
);

export const getDataProductEdit = createSelector(
  [productsSlice.selectors.getProducts],
  products => products.dataProductEdit
);

export const getSetShowMoreOpt = createSelector(
  [productsSlice.selectors.getProducts],
  products => products.setShowMoreOpt
);

export const getProductItemDelete = createSelector(
  [productsSlice.selectors.getProducts],
  products => products.deleteProductItem
);

export const getProductItemArchived = createSelector(
  [productsSlice.selectors.getProducts],
  products => products.archivedProductItem
);

export const getProductItemUnArchived = createSelector(
  [productsSlice.selectors.getProducts],
  products => products.unArchivedProductItem
);
