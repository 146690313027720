export const queryImportProducts = `
    _id
    importKey
    location
    filename
    numberOfRecords
    successRecordsNumber
    failRecordsNumber
    uploadAt
    author {
      name
    }
`;
