import { createSelector } from 'redux-starter-kit';
import { ordersSlice } from '../reducers/orders';

export const getOpenDetailOrderItem = createSelector(
  [ordersSlice.selectors.getOrders],
  orders => orders.showDetailOrderItem
);

export const getDeliveryServiceMethod = createSelector(
  [ordersSlice.selectors.getOrders],
  orders => orders.deliveryServiceMethod
);

export const getNeedReloadCountProductOrders = createSelector(
  [ordersSlice.selectors.getOrders],
  orders => orders.needReloadCountProductOrders
);
