import React from 'react';
import { useSelector } from 'react-redux';
import Grid from '@material-ui/core/Grid';
import { Field } from 'formik';
import InputAdornment from '@material-ui/core/InputAdornment';
import ErrorIcon from '@material-ui/icons/Error';
import { useTranslation } from 'react-i18next';
import { Box } from '@material-ui/core';
import { getCurrencySymbol } from '../../store/selectors/currency';
import { CustomTextField } from '../FormFeild';
import useStyles from './style';

function StockAndPrice({ formik, handleChangePrice }) {
  const { t } = useTranslation();
  const symbol = useSelector(getCurrencySymbol);
  const mainClass = useStyles();

  return (
    <Grid item container xs={12} className={mainClass.wrapTab} spacing={1}>
      <Box className={mainClass.fieldContainer}>
        <Grid item xs={12}>
          <Field
            name="productSku"
            margin="dense"
            variant="outlined"
            placeholder={t('product.label.Sku')}
            label={t('product.label.Sku')}
            fullWidth
            component={CustomTextField}
          />
        </Grid>
        <Grid item xs={12}>
          <Field
            isCurrency
            name="price"
            margin="dense"
            variant="outlined"
            placeholder={t('product.label.Price')}
            label={t('product.label.Price')}
            required
            fullWidth
            component={CustomTextField}
            onChange={e => {
              handleChangePrice(e, formik.setFieldValue, formik.values);
            }}
            InputProps={{
              classes: {
                root: mainClass.rootTextFeild,
                notchedOutline: mainClass.notchedOutline,
                input: mainClass.inputTexFeild,
                focused: mainClass.notchedOutlineFocus
              },
              startAdornment: (
                <InputAdornment position="start">$</InputAdornment>
              ),
              endAdornment: (
                <InputAdornment position="start">{symbol}</InputAdornment>
              )
            }}
          />
        </Grid>
        <Grid item xs={12}>
          <Field
            name="discount"
            margin="dense"
            variant="outlined"
            placeholder=""
            label={t('product.label.Discount')}
            required
            fullWidth
            component={CustomTextField}
            onChange={e => {
              handleChangePrice(e, formik.setFieldValue, formik.values);
            }}
            InputProps={{
              classes: {
                root: mainClass.rootTextFeild,
                notchedOutline: mainClass.notchedOutline,
                input: mainClass.inputTexFeild,
                focused: mainClass.notchedOutlineFocus
              },
              endAdornment: <InputAdornment position="end">%</InputAdornment>
            }}
          />
        </Grid>
        <Grid item xs={12}>
          <Field
            isCurrency
            name="reducedPrice"
            margin="dense"
            variant="outlined"
            placeholder={t('product.label.Reduced Price')}
            label={t('product.label.Reduced Price')}
            required
            fullWidth
            component={CustomTextField}
            onChange={e => {
              handleChangePrice(e, formik.setFieldValue, formik.values);
            }}
            InputProps={{
              classes: {
                root: mainClass.rootTextFeild,
                notchedOutline: mainClass.notchedOutline,
                input: mainClass.inputTexFeild,
                focused: mainClass.notchedOutlineFocus
              },
              startAdornment: (
                <InputAdornment position="start">$</InputAdornment>
              ),
              endAdornment: (
                <InputAdornment position="start">{symbol}</InputAdornment>
              )
            }}
          />
        </Grid>
        <Grid item xs={12}>
          <Field
            isCurrency
            name="qty"
            margin="dense"
            variant="outlined"
            placeholder={t('product.label.Quantity')}
            label={t('product.label.Quantity')}
            required
            fullWidth
            component={CustomTextField}
            onChange={e => {
              handleChangePrice(e, formik.setFieldValue, formik.values);
            }}
          />
        </Grid>
      </Box>
    </Grid>
  );
}

export default StockAndPrice;
