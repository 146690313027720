import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import HttpApi from 'i18next-http-backend';
import ChainedBackend from 'i18next-chained-backend';
import LocalStorageBackend from 'i18next-localstorage-backend';
import LanguageDetector from 'i18next-browser-languagedetector';
import { checkLocation } from './checkLocation';

const _CacheStorage = window.localStorage;
// const _CacheStorage = window.sessionStorage

const cachePrefix = 'i18next_res_';
const cacheVersionKey = 'i18next_version';
const isBeginWithoutCache = [
  null,
  undefined,
  false,
  'null',
  'undefined',
  'false',
  ''
].includes(_CacheStorage.getItem(cacheVersionKey));

export const getVersion = () => {
  const cVersion = _CacheStorage.getItem(cacheVersionKey);
  if (cVersion && cVersion.split('.').length === 3) {
    return cVersion;
  }

  return '1.0.0';
};

export const setVersion = version => {
  const currentVersion = getVersion();

  const newVersion = version || '1.0.0';
  if (newVersion === currentVersion) {
    return;
  }

  // re-cache version
  _CacheStorage.setItem(cacheVersionKey, newVersion);
  if (isBeginWithoutCache) {
    return;
  }

  // reloadResources
  const regex = new RegExp(`^${cachePrefix}`, 'i');
  for (const key of Object.keys(_CacheStorage)) {
    if (key.match(regex)) {
      _CacheStorage.removeItem(key);
    }
  }

  // update i18next config
  i18n.init(initOptions);
};

export const i18nextHttpApiOption = {
  loadPath: checkLocation().i18nLink,
  queryStringParams: { v: getVersion() }
};

const initOptions = {
  fallbackLng: 'GB',
  keySeparator: false,
  interpolation: {
    escapeValue: false // not needed for react as it escapes by default
  },
  react: {
    useSuspense: false
  },
  backend: {
    /** config for: https://github.com/i18next/i18next-chained-backend */
    backends: [
      // LocalStorage cache | production only
      LocalStorageBackend, // primary
      HttpApi // fallback
    ],
    backendOptions: [
      // https://github.com/i18next/i18next-http-backend#backend-options
      i18nextHttpApiOption
    ],
    /** general config */
    // https://github.com/i18next/i18next-http-backend#backend-options
    ...i18nextHttpApiOption
  }
};

i18n
  // load translation using xhr -> see /public/locales
  // learn more: https://github.com/i18next/i18next-xhr-backend
  .use(HttpApi)
  // detect user language
  // learn more: https://github.com/i18next/i18next-browser-languageDetector
  .use(LanguageDetector)
  // pass the i18n instance to react-i18next.
  .use(initReactI18next)
  // init i18next
  // for all options read: https://www.i18next.com/overview/configuration-options
  .init(initOptions);

export default i18n;
