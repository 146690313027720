import { makeStyles } from '@material-ui/core';
import { secondaryFont, primaryFont } from '../../shared/theme';

const useStyle = makeStyles(theme => ({
  rootDialog: {
    width: '100%'
  },
  wrappError: {
    display: 'flex',
    justifyContent: 'center',
    marginTop: 30
  },
  iconError: {
    color: theme.palette.color.orange,
    fontSize: 55
  },
  title: {
    textAlign: 'center',
    '& h2': {
      fontFamily: secondaryFont,
      fontSize: 28,
      color: theme.palette.color.black1
    }
  },
  textDesc: {
    textAlign: 'center',
    fontFamily: primaryFont
  },
  wrappBtn: {
    padding: '15px 24px'
  }
}));

export default useStyle;
