import { makeStyles } from '@material-ui/core';
import { primaryFont, secondaryFont } from '../../../shared/theme';

const useStyle = makeStyles(theme => ({
  headUserList: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    borderBottom: `1px solid #F2F2F2`,
    padding: '0 24px 24px'
  },
  titleHead: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    margin: 0,
    fontSize: 20,
    fontFamily: secondaryFont,
    color: theme.palette.color.black,
    flex: 1
  },
  countChat: {
    backgroundColor: '#F74040',
    display: 'flex',
    minWidth: 20,
    height: 20,
    borderRadius: 20,
    justifyContent: 'center',
    alignItems: 'center',
    color: '#fff',
    fontFamily: primaryFont,
    fontSize: 12,
    marginLeft: 5
  },
  closeBtn: {
    color: theme.palette.color.orange
  },
  userList: {
    maxHeight: 400,
    overflowX: 'hidden',
    overflowY: 'auto',
    padding: '0 24px',
    '&::-webkit-scrollbar': {
      width: 4
    },

    /* Track */
    '&::-webkit-scrollbar-track': {
      '-webkitBoxShadow': `inset 0 0 6px rgba(0,0,0,0.1)`,
      boxShadow: `inset 0 0 6px rgba(0,0,0,0.1)`,
      '-webkitBorderRadius': 10,
      borderRadius: 10
    },

    /* Handle */
    '&::-webkit-scrollbar-thumb': {
      '-webkitBorderRadius': 10,
      borderRadius: 10,
      backgroundColor: '#f5f5f5',
      '-webkitBoxShadow': `inset 0 0 6px rgba(0,0,0,0.3)`,
      boxShadow: `inset 0 0 6px rgba(0,0,0,0.3)`
    },
    '&::-webkitScrollbarThumb:window-inactive': {
      backgroundColor: '#f7f7f7'
    }
  },
  userItem: {
    position: 'relative',
    display: 'flex',
    flexDirection: 'row',
    paddingTop: 12,
    paddingBottom: 12,
    borderBottom: `1px solid #F2F2F2`,
    cursor: 'pointer',
    transition: 'all .35s',
    '&:hover': {
      borderBottom: `1px solid transparent`
    },
    '& *': {
      position: 'relative'
    },
    paddingRight: 2,
    alignItems: 'center',
    zIndex: 1,
    '&:hover:before': {
      content: '" "',
      position: 'absolute',
      top: 0,
      bottom: 0,
      left: -25,
      right: -25,
      borderBottom: `1px solid #F2F2F2`,
      backgroundColor: '#f7f7f7'
    }
  },
  avatarUser: {
    display: 'flex',
    width: 40,
    height: 40,
    borderRadius: '50%'
  },
  infoUser: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    flex: 3,
    paddingLeft: 15
  },
  timeStamp: {
    display: 'flex',
    flex: 2,
    fontSize: 12,
    color: theme.palette.color.gray,
    fontFamily: primaryFont,
    justifyContent: 'flex-end'
  },
  userName: {
    margin: 0,
    fontSize: 16,
    color: theme.palette.color.black1,
    fontFamily: primaryFont
  },
  userDesc: {
    margin: 0,
    fontSize: 12,
    color: theme.palette.color.black2,
    fontFamily: primaryFont,
    marginTop: 5
  },
  channelEmptyImg: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    flexDirection: 'column',
    '& img': {
      marginTop: 20,
      maxWidth: '100%'
    }
  },
  textEmpty: {
    margin: 0,
    marginTop: 15,
    fontSize: 18,
    color: theme.palette.color.gray,
    fontFamily: primaryFont
  }
}));

export default useStyle;
