import { makeStyles } from '@material-ui/core';
import { primaryFont } from '../../../shared/theme';

const useStyle = makeStyles(theme => ({
  wrapp: {
    marginTop: theme.spacing(3),
    border: `1px solid #F2F2F2`,
    backgroundColor: theme.palette.color.white,
    borderRadius: '10px',
    cursor: 'pointer',
    transition: `all .35s`,
    '&:hover': {
      border: `1px solid #E38D03`
    },
    '&:hover h6': {
      color: theme.palette.color.orange
    }
  },
  active: {
    border: `1px solid #E38D03`,
    '& h6': {
      color: theme.palette.color.orange
    }
  },
  header: {
    display: 'flex',
    justifyContent: 'space-between',
    padding: theme.spacing(1.5, 3),
    [theme.breakpoints.down('md')]: {
      padding: theme.spacing(1.5, 0.8)
    }
  },
  customerInfos: {
    display: 'flex',
    paddingBottom: 22
  },
  customerInfo: {
    display: 'flex',
    maxWidth: 275,
    flexDirection: 'column',
    paddingLeft: 30,
    paddingRight: 30,
    borderRight: `1px solid #F2F2F2`,
    '&:last-child': {
      borderRight: 'none'
    },
    [theme.breakpoints.down('md')]: {
      paddingLeft: 8,
      paddingRight: 3
    }
  },
  customerLabel: {
    fontSize: 12,
    fontFamily: primaryFont,
    color: theme.palette.color.gray2,
    textTransform: 'uppercase'
  },
  customerName: {
    fontSize: 18,
    lineHeight: '26px',
    fontFamily: primaryFont,
    color: theme.palette.color.black2,
    [theme.breakpoints.only('xs')]: {
      fontSize: 16
    }
  },
  main: {
    padding: theme.spacing(2, 1.5)
  },
  time: {
    fontSize: 12,
    color: theme.palette.color.gray2
  },
  infoHr: {
    display: 'flex',
    marginRight: 15,
    flexDirection: 'row',
    alignItems: 'center',
    borderRadius: '20px',
    padding: theme.spacing(0.5, 1.5),
    '& img': {
      maxWidth: 20,
      maxHeight: 20
    },
    '&:last-child': {
      marginRight: 0
    }
  },
  pickup: {
    backgroundColor: theme.palette.color.orange3
  },
  digicel: {
    backgroundColor: theme.palette.color.orange1
  },
  delivery: {
    backgroundColor: theme.palette.color.green1
  },
  vodafone: {
    backgroundColor: theme.palette.color.blue1
  },
  textInfo: {
    fontSize: 16,
    fontFamily: primaryFont,
    marginLeft: 8,
    color: theme.palette.color.black2
  },
  footer: {
    display: 'flex',
    justifyContent: 'space-between',
    flexDirection: 'row',
    alignItems: 'center',
    borderTop: `1px solid #F2F2F2`,
    padding: theme.spacing(1.1, 3),
    [theme.breakpoints.only('xs')]: {
      padding: theme.spacing(1.1, 0.8),
      flexDirection: 'column',
      gap: '8px'
    }
  },
  footerLeft: {
    display: 'flex',
    flexDirection: 'row',
    alignSelf: 'flex-start'
  },
  footerRight: {
    display: 'flex',
    flexDirection: 'row'
  },
  numberItem: {
    fontSize: 18,
    fontWeight: 'bold',
    fontFamily: primaryFont,
    color: theme.palette.color.black1
  },
  numberCost: {
    fontSize: 18,
    fontWeight: 'bold',
    fontFamily: primaryFont,
    marginLeft: 20,
    color: theme.palette.color.black1,
    position: 'relative',
    '&::before': {
      content: '" "',
      position: 'absolute',
      top: '50%',
      left: -13,
      width: 5,
      height: 5,
      backgroundColor: theme.palette.color.white1,
      borderRadius: '50%',
      transform: 'translateY(-50%)'
    }
  }
}));
export default useStyle;
