import React, { useState, useRef } from 'react';
import moment from 'moment';
import { isEmpty } from 'lodash';
import { useTranslation } from 'react-i18next';
import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import Dialog from '@material-ui/core/Dialog';
import Checkbox from '@material-ui/core/Checkbox';
import FormLabel from '@material-ui/core/FormLabel';
import SearchIcon from '@material-ui/icons/Search';
import FormGroup from '@material-ui/core/FormGroup';
import Typography from '@material-ui/core/Typography';
import FormControl from '@material-ui/core/FormControl';
import { KeyboardDatePicker } from '@material-ui/pickers';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Button from '../../../components/Button';
import { TextFieldCustom } from '../../FormFeild/TextField';
import SelectFilter from '../../FieldWithoutFormik/Select';
import ExportIcon from '../../../assets/img/export-icon.svg';
import {
  DATE_FORMAT,
  getTimeZone,
  onClickOutside
} from '../../../utils/common';
import useStyles from './style';
import { toastMessage, TOAST_TYPE } from '../../../utils/toastify-notify';
import { IconButton, InputAdornment } from '@material-ui/core';
import DatePicker from '../../FieldWithoutFormik/DatePicker';
import { useIsXs } from 'src/utils/hook';

const OPTIONS_STATUS = [
  { value: 'new', label: 'New' },
  { value: 'completed', label: 'Completed' },
  { value: 'in progress', label: 'Progress' },
  { value: 'ready for pickup', label: 'Ready for pickup' },
  { value: 'ready for delivery', label: 'Ready for delivery' },
  { value: 'failed', label: 'Failed' }
];

const OPTIONS_PAYMENT_METHOD = [
  { value: 'digicel', label: 'Digicel' },
  { value: 'vodafone', label: 'Vodafone' },
  { value: 'cash', label: 'Cash' },
  { value: 'MauaPay', label: 'Maua Pay' },
  { label: 'E-voucher', value: 'e-voucher' },
  { value: 'bank-transfer', label: 'Bank Transfer' }
];

const ExportModal = props => {
  const { style } = props;

  const { t } = useTranslation();
  const rootRef = useRef();

  const [open, setOpen] = useState(false);

  onClickOutside(rootRef, () => setOpen(false));

  const [formExport, setFormExport] = useState({
    search: '',
    statuses: [],
    customerName: '',
    deliveryMethods: ['self-pickup', 'service'],
    paymentMethods: [],
    from: null,
    to: null
  });

  const classes = useStyles({ search: formExport?.search });

  const className =
    props.type === 'primary' ? classes.typePrimary : classes.typeDefault;

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleChangeInput = e => {
    const { name, value } = e.target;

    setFormExport(prevData => ({
      ...prevData,
      [name]: value
    }));
  };

  const handleChangeSelect = (e, name) => {
    setFormExport(prevData => ({
      ...prevData,
      [name]: e
    }));
  };

  const handleChangeCheckBox = e => {
    let { name, value } = e.target;

    if (formExport[name].includes(value))
      return setFormExport(prevData => ({
        ...prevData,
        [name]: prevData[name].filter(el => el !== value)
      }));

    return setFormExport(prevData => ({
      ...prevData,
      [name]: [...prevData[name], value]
    }));
  };

  const handleSetDate = (name, data) => {
    if (data === null)
      setFormExport(prevData => {
        return {
          ...prevData,
          [name]: data
        };
      });

    const date = moment(data, DATE_FORMAT, true);
    if (date.isValid()) {
      let value =
        name === 'from'
          ? date?.startOf('day') || null
          : date?.endOf('day') || null;

      setFormExport(prevData => {
        return {
          ...prevData,
          [name]: value
        };
      });
    }
  };

  const handleExportFile = async e => {
    try {
      const timezone = getTimeZone();

      let payload = {
        keyword: formExport.search,
        statuses: formExport.statuses?.map(el => el.value) || null,
        customerName: formExport.customerName,
        deliveryMethods: formExport.deliveryMethods,
        paymentMethods: formExport.paymentMethods?.map(el => el.value) || null,
        from: (formExport.from && formExport.from.toISOString()) || null,
        to: (formExport.to && formExport.to.toISOString()) || null,
        timezone
      };

      payload = Object.keys(payload).reduce((result, key, idx) => {
        return !isEmpty(payload[key])
          ? { ...result, [key]: payload[key] }
          : result;
      }, {});

      await props.handleExport(payload);

      setFormExport({
        search: '',
        statuses: [],
        customerName: '',
        deliveryMethods: ['self-pickup', 'service'],
        paymentMethods: [],
        from: null,
        to: null
      });

      setOpen(false);
    } catch (error) {
      toastMessage(TOAST_TYPE.ERROR, error.message);
    }
  };

  const isXs = useIsXs();

  return (
    <Box style={style}>
      <Box className={classes.btnExport + ' ' + className} onClick={handleOpen}>
        <img
          src={ExportIcon}
          alt="export"
          style={{ marginRight: isXs ? 0 : 8, width: 20 }}
        />
        {!isXs && <Box>{t('exportModal.title.Export')}</Box>}
      </Box>

      <Dialog open={open} onClose={handleClose} className={classes.dialog}>
        <Box className={classes.boxContent}>
          <Typography className={classes.exportTitile} variant="h6">
            {t('order.export.filter.title.Export filter')}
          </Typography>

          <Grid
            container
            spacing={2}
            style={{
              overflow: 'none'
            }}>
            <Grid item xs={12}>
              <TextFieldCustom
                variant="outlined"
                id="margin-none"
                onChange={handleChangeInput}
                name="search"
                value={formExport.code}
                placeholder={t(
                  'order.export.filter.placeholder.Search on order code, name, phone'
                )}
                className={[classes.field, classes.search].join(' ')}
                label={t('order.export.filter.label.Search')}
              />
            </Grid>

            <Grid item xs={12}>
              <SelectFilter
                value={formExport.statuses}
                label={t('order.export.filter.label.Status')}
                onChange={e => handleChangeSelect(e, 'statuses')}
                isMulti={true}
                options={OPTIONS_STATUS}
                zIndex={100}
              />
            </Grid>

            <Grid item xs={12}>
              <SelectFilter
                value={formExport.paymentMethods}
                onChange={e => handleChangeSelect(e, 'paymentMethods')}
                isMulti={true}
                label={t('order.export.filter.label.Delivery Method')}
                options={OPTIONS_PAYMENT_METHOD}
                zIndex={98}
              />
            </Grid>

            <Grid item xs={12}>
              <FormControl
                component="fieldset"
                className={classes.checkBoxContainer}>
                <FormLabel className={classes.labelCheckbox} component="legend">
                  {t('order.export.filter.label.Delivery Method')}:
                </FormLabel>
                <FormGroup className={classes.checkBoxs}>
                  <FormControlLabel
                    control={
                      <Checkbox
                        value="self-pickup"
                        color="primary"
                        checked={formExport.deliveryMethods.includes(
                          'self-pickup'
                        )}
                        onChange={handleChangeCheckBox}
                        name="deliveryMethods"
                      />
                    }
                    label={t('order.export.filter.label.Self pickup')}
                  />
                  <FormControlLabel
                    control={
                      <Checkbox
                        value="service"
                        color="primary"
                        checked={formExport.deliveryMethods.includes('service')}
                        onChange={handleChangeCheckBox}
                        name="deliveryMethods"
                      />
                    }
                    label={t('order.export.filter.label.Delivery to me')}
                  />
                </FormGroup>
              </FormControl>
            </Grid>

            <Grid item xs={6}>
              <KeyboardDatePicker
                className={classes.datePicker}
                format={DATE_FORMAT}
                placeholder="DD/MM/YYYY"
                inputVariant="outlined"
                variant="inline"
                label={t('order.filter.label.From')}
                maxDate={formExport.to || moment()}
                autoOk
                inputProps="outlined"
                autoOk
                name="from"
                value={formExport.from}
                onChange={data => handleSetDate('from', data)}
              />
            </Grid>

            <Grid item xs={6}>
              <KeyboardDatePicker
                className={classes.datePicker}
                format={DATE_FORMAT}
                placeholder="DD/MM/YYYY"
                inputVariant="outlined"
                variant="inline"
                label={t('order.filter.label.To')}
                maxDate={moment()}
                autoOk
                minDate={formExport.from || false}
                inputProps="outlined"
                autoOk
                name="to"
                value={formExport.to}
                onChange={data => handleSetDate('to', data)}
              />
            </Grid>
          </Grid>

          <Box className={classes.action}>
            <Button
              className={[classes.btn, classes.typeDefault].join(' ')}
              typeBtn="gray"
              onClick={handleClose}
              color="primary">
              {t('action.Cancel')}
            </Button>
            <Button
              className={classes.btn}
              typeBtn="yellow"
              onClick={handleExportFile}
              color="primary">
              {t('action.Export')}
            </Button>
          </Box>
        </Box>
      </Dialog>
    </Box>
  );
};

ExportModal.defaultProps = {
  type: 'primary',
  title: 'Export'
};

export default ExportModal;
