import React, { useState, useEffect } from 'react';
import defaultImage from '../../assets/img/default.jpg';

const ExternalImage = props => {
  const [loaded, setLoaded] = useState(false);
  const [image, setImage] = useState(null);
  //if image is wrong
  useEffect(() => {
    if (props.src) {
      setImage(props.src);
    } else {
      setImage(defaultImage);
    }
  }, [props.src]);

  const onLoad = () => {
    setLoaded(true);
  };
  //if image is error
  const setDefaultImg = () => {
    setImage(defaultImage);
  };
  return (
    <React.Fragment>
      <img
        style={{ display: loaded ? 'block' : 'none' }}
        onLoad={onLoad}
        onError={setDefaultImg}
        src={image}
        alt={props.alt || 'alt default'}
        {...props}
      />
      {!loaded ? (
        <div
          className={props.className}
          style={{ backgroundColor: '#ccc' }}></div>
      ) : null}
    </React.Fragment>
  );
};

export default ExternalImage;
