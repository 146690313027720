import React, { useState, useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import moment from 'moment';
import clsx from 'clsx';
import ClickAwayListener from '@material-ui/core/ClickAwayListener';
import { Box, Tooltip, Fade, Badge } from '@material-ui/core';
import Typography from '@material-ui/core/Typography';
import InfiniteScroll from 'react-infinite-scroller';
import IconError from '@material-ui/icons/Error';
import { getCredentials } from 'maua-redux-core/selectors/user';
import { DATE_TIME_FORMAT } from 'maua-redux-core/utils/format';
import {
  countNewNotifications,
  fetchNotifications,
  hitBell,
  subscribeTopic,
  readNotifications
} from 'maua-redux-core/actions/notification';
import {
  getNotificationQuery,
  hitBellQuery
} from '../../../store/query/notification';
import Notify from '../../../assets/img/icons/noti.svg';
import MobileNotify from '../../../assets/img/icons/mobile-noti.png';
import { requestNotificationPermission } from '../../../push-notification';
import { toastMessage, TOAST_TYPE } from '../../../utils/toastify-notify';
import NotificationSkeleton from './NotificationSkeleton';
import { history } from '../../../routes';
import uniqBy from 'lodash/uniqBy';
import { useStyle } from './style';
import { useIsMobile } from 'src/utils/hook';

function Notification(props) {
  const { t } = useTranslation();
  const classes = useStyle();
  const isMobile = useIsMobile();
  const [open, setOpen] = useState(false);
  const countNewMessage = useRef(null);
  const [notifications, setNotifications] = useState({
    data: [],
    page: 1,
    pages: 1,
    total: 0,
    countNew: 0
  });

  const { data, countNew } = notifications;

  // load list notifications
  const loadNotifications = async page => {
    const payload = {
      page: ++page,
      limit: 10
    };

    try {
      const { notifications } = await props.fetchNotifications(payload);

      setNotifications(prev => ({
        ...prev,
        data: uniqBy([...prev.data, ...notifications.docs], '_id'),
        page: notifications.page,
        pages: notifications.pages,
        total: notifications.total
      }));
    } catch (error) {
      toastMessage(TOAST_TYPE.ERROR, error.message);
    }
  };

  // load number notifications
  const countNewNotifications = async () => {
    const { countNewNotifications } = await props.countNewNotifications();
    setNotifications(noti => ({ ...noti, countNew: countNewNotifications }));
  };

  // open modal show notifications, remove new message
  const handleLoadNotifications = () => {
    setOpen(open => !open);

    if (props.credentials.token) {
      setNotifications({
        data: [],
        page: 1,
        pages: 1,
        total: 0,
        countNew: 0
      });
      loadNotifications(0);
      props.hitBell();
    }
  };

  // load interval for notifications
  const loadCountNewNotifications = async () => {
    // request device permission
    requestNotificationPermission().then(token => {
      if (token) {
        props.subscribeTopic(token);
      }
    });

    countNewNotifications();

    // if (props.credentials.token) {
    //   countNewMessage.current = setInterval(countNewNotifications, 10000); // 20 sec
    // }

    // // init interval load count notifications
    // if (countNewMessage.current) {
    //   clearInterval(countNewMessage);
    // }
  };

  // read notifications
  const readNotifications = async record => {
    try {
      await props.readNotification({ _ids: [record._id] });

      setNotifications(noti => ({
        ...noti,
        data: noti.data.map(n => {
          if (n._id === record._id) {
            n.seen = true;
          }

          return n;
        })
      }));
    } catch (error) {
      toastMessage(TOAST_TYPE.ERROR, error.message);
    }
  };

  useEffect(() => {
    if (props.credentials.token) {
      loadCountNewNotifications();
      countNewNotifications();
    }
  }, [props.credentials.token]);

  return (
    <Box height="100%" display="flex" onClick={handleLoadNotifications}>
      <ClickAwayListener onClickAway={() => setOpen(false)}>
        <Box position="relative">
          <div className={clsx(classes.controlIcon, classes.notificationIcon)}>
            <Tooltip title="Notification">
              <Badge
                color="primary"
                badgeContent={countNew}
                classes={{ anchorOriginTopRightRectangle: classes.posAnchor }}>
                <img
                  src={isMobile ? MobileNotify : Notify}
                  alt="notification"
                  width={54}
                />
              </Badge>
            </Tooltip>
          </div>
          <Fade in={open}>
            <div className={classes.dropdown}>
              <div className={classes.wrapper}>
                <Box className={classes.dropDrownItem}>
                  <Box className={classes.warning}>
                    <IconError className={classes.warningIcon} />
                    <Typography variant="subtitle2">
                      {t('noti.location.title.Allow Location Service')}
                    </Typography>
                  </Box>
                  <Typography>{t('noti.location.content')}</Typography>
                </Box>
                <InfiniteScroll
                  threshold={240}
                  useWindow={false}
                  initialLoad={false}
                  loadMore={() => loadNotifications(notifications.page)}
                  hasMore={notifications.pages > notifications.page}
                  loader={
                    <NotificationSkeleton
                      key={0}
                      className={classes.dropDrownItem}
                    />
                  }>
                  {data.map(item => {
                    const message = JSON.parse(item?.message) || {};
                    const date = moment(item?.createdAt).format(
                      DATE_TIME_FORMAT
                    );
                    const path = {
                      'order>new': `/orders/newOrders`,
                      'menu>detail': `/products`
                    };
                    const background = {
                      false: '#FFF4DC',
                      true: 'unset'
                    };

                    return (
                      <Box
                        key={item._id}
                        className={classes.dropDrownItem}
                        onClick={() => {
                          readNotifications(item);
                          history.push(path[(message?.data?.callback)]);
                        }}
                        style={{
                          background: background[item.seen]
                        }}>
                        <Typography className={classes.date}>{date}</Typography>
                        <Typography className={classes.warning}>
                          {message?.data?.title
                            ? message?.data?.title + ': ' + message?.data?.body
                            : message?.data?.body}
                        </Typography>
                      </Box>
                    );
                  })}
                </InfiniteScroll>
              </div>
            </div>
          </Fade>
        </Box>
      </ClickAwayListener>
    </Box>
  );
}

export default connect(
  state => ({
    credentials: getCredentials(state)
  }),
  dispatch => ({
    hitBell: () => dispatch(hitBell(null, hitBellQuery)),
    readNotification: payload =>
      dispatch(readNotifications(payload, hitBellQuery)),
    fetchNotifications: payload =>
      dispatch(fetchNotifications(payload, getNotificationQuery)),
    countNewNotifications: () => dispatch(countNewNotifications()),
    subscribeTopic: token => dispatch(subscribeTopic({ token }))
  })
)(Notification);
