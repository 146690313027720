import React from 'react';
import { isEqual } from 'lodash';

import './style.css';
import ReactApexChart from 'react-apexcharts';

class DonutChart extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      options: {
        chart: {
          height: 2000
        },
        plotOptions: {
          pie: {
            donut: {
              size: '65%',
              labels: {
                show: true,
                name: {
                  show: true,
                  fontSize: '14px',
                  fontFamily: 'Helvetica',
                  color: '#909090',
                  offsetY: -10
                },
                value: {
                  show: true,
                  fontSize: '16px',
                  fontFamily: 'Helvetica',
                  color: '#333333;',
                  offsetY: 16,
                  formatter: function(val) {
                    return val;
                  }
                }
              }
            }
          }
        },
        dataLabels: {
          enabled: true
        },
        responsive: [
          {
            breakpoint: 321,
            options: {
              chart: {
                width: 250
                // height: 300
              },
              legend: {
                // show: false
              }
            }
          }
        ],
        fill: {
          opacity: 1,
          colors: this.props.colors
        },
        colors: this.props.colors,
        title: {
          text: this.props.title,
          align: 'left',
          style: {
            fontSize: '16px',
            color: '#333',
            fontFamily: 'Helvetica'
          }
        },
        labels: this.props.labels,
        legend: {
          position: 'right',
          offsetY: 50,
          // height: 250,
          markers: {
            onClick: (seriesName, opts) => {
              this.setSelectedItem(opts);
            }
          }
        }
      },
      series: [],
      selectedItem: { name: '', value: '' }
    };
  }

  setSelectedItem = item => {
    this.setState({
      ...this.state,
      selectedItem: {
        name: this.state.options.labels[item],
        value: this.state.series[item]
      }
    });
  };

  authorize = () => {};

  componentDidMount = () => {
    setTimeout(() => {
      //Auto trigger with first item
      const { className } = this.props;
      let firstItem = document.querySelector(`.${className} span[rel="1"]`);
      if (!firstItem) {
        return;
      }
      firstItem.addEventListener('click', this.authorize);
      setTimeout(() => {
        firstItem.click();
      }, 500);
    }, 500);
  };

  static getDerivedStateFromProps(nextProps, prevState) {
    if (!isEqual(nextProps.series, prevState.series)) {
      return {
        ...prevState,
        series: nextProps.series
      };
    }

    return null;
  }

  componentDidUpdate(prevProps) {
    // Typical usage (don't forget to compare props):
    if (!isEqual(this.props.series, prevProps.series)) {
      const { className } = this.props;
      let firstItem = document.querySelector(`.${className} span[rel="1"]`);
      if (!firstItem) {
        return;
      }
      firstItem.addEventListener('click', this.authorize);
      setTimeout(() => {
        firstItem.click();
      }, 500);
    }
  }
  render() {
    const { className } = this.props;
    return (
      <div className={`donut-chart ${className}`}>
        <ReactApexChart
          options={this.state.options}
          series={this.state.series}
          type="donut"
          width="358"
          height="295"
        />
      </div>
    );
  }
}

export default DonutChart;
