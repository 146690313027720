import Box from '@material-ui/core/Box';
import Fade from '@material-ui/core/Fade';
import { isEmpty } from 'lodash';
import { countNewMessages } from 'maua-redux-core/actions/messageChannel';
import { getCountNewMessages } from 'maua-redux-core/selectors/messageChannel';
import { getUserProfile } from 'maua-redux-core/selectors/user';
import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import messengerImg from '../../assets/img/icons/chat-comment.png';
import { initChannel } from '../../store/actions/message';
import {
  actSetOrderIdMessenger,
  actSetShowMessengerFromOrderDetail
} from '../../store/actions/messengerChat';
import { getShowMessengerFromOrderDetail } from '../../store/selectors/messengerChat';
import { isEmptyObject } from '../../utils/common';
import ChannelScreen from './ChannelScreen';
import ChatBox from './ChatBox';
import useStyles from './style';

function Chat(props) {
  const { showMessengerFromOrderDetail, channel, dispatchInitChannel } = props;

  const mainClass = useStyles();

  const handleToggleChat = () => {
    props.actSetShowMessengerFromOrderDetailRequest(
      !showMessengerFromOrderDetail
    );
  };

  const handleClose = () => {
    showMessengerFromOrderDetail &&
      props.actSetShowMessengerFromOrderDetailRequest(false);
    dispatchInitChannel();
  };

  const handleBackUserList = () => {
    dispatchInitChannel();
  };

  useEffect(() => {
    let interval = null;

    if (isEmptyObject(props.userProfile)) {
      interval = setInterval(async () => {
        await props.countNewMessagesRequest();
      }, 4000);
    }
    return () => clearInterval(interval);
  }, [props.userProfile]);

  return (
    <Box className={mainClass.root}>
      <div className={mainClass.inner}>
        <div className={mainClass.messengerControl}>
          <div className={mainClass.messenger} onClick={handleToggleChat}>
            {props.messengerUnread ? (
              <span className={mainClass.messengerCount}>
                {props.messengerUnread}
              </span>
            ) : null}
            <img src={messengerImg} alt="messenger" />
          </div>
        </div>
        {showMessengerFromOrderDetail ? (
          <Fade
            in={showMessengerFromOrderDetail}
            className={mainClass.contentBox}>
            {!isEmpty(channel) ? (
              <ChatBox
                handleBackUserList={handleBackUserList}
                handleClose={handleClose}
                channel={channel}
              />
            ) : (
              <ChannelScreen handleClose={handleClose} />
            )}
          </Fade>
        ) : null}
      </div>
    </Box>
  );
}

const mapStateToprops = state => {
  return {
    userProfile: getUserProfile(state),
    messengerUnread: getCountNewMessages(state),
    showMessengerFromOrderDetail: getShowMessengerFromOrderDetail(state),
    channel: state.messageChannel.messageChannel
  };
};
const mapDispatchToProps = dispatch => {
  return {
    dispatchInitChannel: payload => dispatch(initChannel(payload)),
    setOrderIdMessengerRequest: value =>
      dispatch(actSetOrderIdMessenger(value)),
    countNewMessagesRequest: () => dispatch(countNewMessages()),
    actSetShowMessengerFromOrderDetailRequest: value =>
      dispatch(actSetShowMessengerFromOrderDetail(value))
  };
};

export default connect(
  mapStateToprops,
  mapDispatchToProps
)(Chat);
