import React, { useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { TextField, InputAdornment, Box } from '@material-ui/core';
import SearchIcon from '@material-ui/icons/Search';
import { useStyle } from './style';

const Search = props => {
  const { placeholder, className } = props;
  const classes = useStyle();
  const { t } = useTranslation();
  const timeout = useRef(null);

  const handleChange = event => {
    const val = event.target.value;

    if (timeout.current) {
      clearTimeout(timeout.current);
    }

    timeout.current = setTimeout(() => props.setKeyword(val), 400);
  };

  return (
    <Box className={classes.root + ' ' + className}>
      <TextField
        fullWidth
        onChange={handleChange}
        placeholder={placeholder || t('input.search.placeholder?')}
        InputProps={{
          disableUnderline: true,
          classes: {
            input: classes.inputInner
          },
          endAdornment: (
            <InputAdornment position="end">
              <SearchIcon />
            </InputAdornment>
          )
        }}
      />
    </Box>
  );
};

Search.defaultProps = {
  setKeyword: () => {}
};

export default Search;
