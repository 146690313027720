import React from 'react';

import OrderItem from '../OrderItem';
import { OrderListConsumer } from '../../../pages/OrdersPage/OrderContext';

const OrderList = props => {
  return (
    <React.Fragment>
      <OrderListConsumer>
        {({ data, handleShowDetailItem, itemActive }) => (
          <React.Fragment>
            {data.map(item => (
              <OrderItem
                key={item._id}
                itemActive={itemActive}
                item={item}
                onClick={() => handleShowDetailItem(item)}
              />
            ))}
          </React.Fragment>
        )}
      </OrderListConsumer>
    </React.Fragment>
  );
};

export default React.memo(OrderList);
