import React from 'react';
import clsx from 'clsx';
import { useTranslation } from 'react-i18next';

import { Field } from 'formik';
import Checkbox from '@material-ui/core/Checkbox';
import Typography from '@material-ui/core/Typography';
import CircleCheckedFilled from '@material-ui/icons/CheckCircle';
import CircleUnchecked from '@material-ui/icons/RadioButtonUnchecked';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Grid from '@material-ui/core/Grid';
import RemoveIcon from '@material-ui/icons/Remove';
import AddIcon from '@material-ui/icons/Add';
import { TimePickerField } from '../FormFeild';

import { NameDayInWeek } from '../../shared/constant/const';
import { useStyle } from './style';

const OpenHourTimeItem = props => {
  const mainClass = useStyle();
  const { t } = useTranslation();
  const {
    weeekdayData,
    onChangeCheckbox,
    handleChangeTime,
    handleRemoveTime,
    handleAddTime
  } = props;

  const { weekday, enable, start, end } = weeekdayData;
  const indexUseDay = `${weekday}`;
  let currentDay = new Date();
  let dayNumber = currentDay.getDay();
  dayNumber = dayNumber === 0 ? 7 : dayNumber;
  const isCurrentDay =
    weekday === dayNumber ? mainClass.currentDay : mainClass.dayDefault;

  return (
    <Grid container>
      <Grid item xs={4} className={mainClass.day}>
        <FormControlLabel
          classes={{
            root: mainClass.labelFormControlCheckbox
          }}
          control={
            <Checkbox
              icon={<CircleUnchecked />}
              checkedIcon={<CircleCheckedFilled />}
              checked={enable}
              name={indexUseDay}
              onChange={onChangeCheckbox(indexUseDay)}
              color="primary"
              classes={{
                root: mainClass.colorCheckbox,
                checked: mainClass.colorChecked
              }}
            />
          }
        />
        <Typography className={clsx(mainClass.weekdayLabel, isCurrentDay)}>
          {t(`business.opentime.day.${NameDayInWeek[indexUseDay]}`)}
        </Typography>
      </Grid>
      <Grid item xs={8} container className={mainClass.openHourControll}>
        {enable ? (
          weeekdayData?.opening?.map((timeline, i) => {
            let isMaxOption = weeekdayData?.opening.length;
            return (
              <Grid
                key={i}
                item
                container
                xs={12}
                justify="space-between"
                alignItems="center">
                <Field
                  className={mainClass.timeStart}
                  name={`start_${weekday}`}
                  variant="outlined"
                  component={TimePickerField}
                  onChange={time =>
                    handleChangeTime(time, `${i}_start_${weekday}`)
                  }
                  value={timeline.start}
                />
                <div className={mainClass.spaceTime}>-</div>
                <Field
                  className={mainClass.timeEnd}
                  name={`end_${weekday}`}
                  variant="outlined"
                  component={TimePickerField}
                  onChange={time =>
                    handleChangeTime(time, `${i}_end_${weekday}`)
                  }
                  value={timeline.end}
                />
                {isMaxOption > 1 ? (
                  <RemoveIcon
                    className={mainClass.icons}
                    onClick={e => handleRemoveTime(e, `${i}_${weekday}`)}
                  />
                ) : (
                  <AddIcon
                    className={mainClass.icons}
                    onClick={e => handleAddTime(e, `${i}_${weekday}`)}
                  />
                )}
              </Grid>
            );
          })
        ) : (
          <Grid className={mainClass.closeTime}>
            {t('store.opentime.status.closed')}
          </Grid>
        )}
      </Grid>
    </Grid>
  );
};

export default OpenHourTimeItem;
