import { makeStyles } from '@material-ui/core';
import { primaryFont, secondaryFont } from '../../../shared/theme';

const useStyle = makeStyles(theme => ({
  title: {
    marginTop: theme.spacing(3.6),
    marginBottom: theme.spacing(3),
    color: theme.palette.color.black
  },
  loginModal: {
    width: '100%',
    padding: '35px 95px 60px',
    textAlign: 'center',
    outline: 'none',
    alignItems: 'center',
    position: 'relative',
    [theme.breakpoints.down('md')]: {
      padding: '35px 30px 60px'
    }
  },
  desc: {
    marginTop: theme.spacing(2.4),
    marginBottom: 0,
    fontSize: 12,
    fontFamily: primaryFont,
    color: theme.palette.color.gray3,
    lineHeight: '18px',
    textAlign: 'initial'
  },
  wrappBtn: {
    marginTop: 40
  },
  btnResend: {
    marginTop: theme.spacing(2.5),
    marginBottom: 10,
    fontSize: 16,
    fontFamily: secondaryFont,
    color: theme.palette.color.orange,
    padding: '5px 15px',
    border: '1px solid transparent',
    backgroundColor: 'transparent',
    borderRadius: 10,
    '&:hover': {
      border: `1px solid ${theme.palette.color.orange}`,
      backgroundColor: theme.palette.color.white,
      borderRadius: 10
    }
  },
  wrappBtnBack: {
    position: 'absolute',
    top: 30,
    left: 30,
    transition: 'all .35s',
    '&:hover': {
      transform: 'scale(1.05)',
      left: 28
    }
  },
  arrowBack: {
    width: 28,
    height: 28,
    display: 'block',
    cursor: 'pointer'
  }
}));
export default useStyle;
