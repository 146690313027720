import React from 'react';
import { getIn } from 'formik';
import FormControl from '@material-ui/core/FormControl';
import MenuItem from '@material-ui/core/MenuItem';
import TextField from '@material-ui/core/TextField';
import Checkbox from '@material-ui/core/Checkbox';
import CircleCheckedFilled from '@material-ui/icons/CheckCircle';
import CircleUnchecked from '@material-ui/icons/RadioButtonUnchecked';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import {
  DatePicker,
  TimePicker,
  KeyboardDatePicker
} from '@material-ui/pickers';
import { VALIDATE_MAX_DATE } from '../../utils/schema';
import SelectMutiple from './SelectMutiple';
import SelectCountry from './SelectCountry';
import {
  useStyleCheckbox,
  useStyleSelectFeild,
  useStyleTextFeild
} from './style';
import { formatCurrencyField } from '../../utils/common';

export function CustomTextField({
  field,
  form: { touched, errors },
  ...props
}) {
  const error = getIn(errors, field.name);
  const touch = getIn(touched, field.name);

  const mainClass = useStyleTextFeild();
  return (
    <TextField
      {...field}
      value={props.isCurrency ? formatCurrencyField(field.value) : field.value}
      error={Boolean(touch && error)}
      helperText={touch && error}
      className={mainClass.rootTextField}
      InputProps={{
        classes: {
          root: mainClass.root,
          notchedOutline: mainClass.notchedOutline,
          input: mainClass.input,
          focused: mainClass.notchedOutlineFocus
        }
      }}
      {...props}
    />
  );
}

export function DatePickerField({
  field,
  form: { touched, errors },
  ...props
}) {
  return (
    <DatePicker
      {...field}
      error={Boolean(touched[field.name] && errors[field.name])}
      helperText={touched[field.name] && errors[field.name]}
      maxDate={props.maxDate}
      margin="dense"
      id="date-picker-dialog"
      variant="inline"
      inputVariant={props.inputVariant || 'outlined'}
      fullWidth
      {...props}
    />
  );
}

export function KeyboardDatePickerField({
  field,
  form: { touched, errors },
  ...props
}) {
  const mainClass = useStyleTextFeild();
  return (
    <KeyboardDatePicker
      {...field}
      error={Boolean(touched[field.name] && errors[field.name])}
      helperText={touched[field.name] && errors[field.name]}
      maxDate={props.maxDate}
      margin="dense"
      id="date-picker-dialog"
      variant="inline"
      inputVariant={props.inputVariant || 'outlined'}
      fullWidth
      {...props}
      disableToolbar
      format="MM/dd/yyyy"
      label="Date picker inline"
      InputProps={{
        classes: {
          root: mainClass.root,
          notchedOutline: mainClass.notchedOutline,
          input: mainClass.input,
          focused: mainClass.notchedOutlineFocus
        }
      }}
      KeyboardButtonProps={{
        'aria-label': 'change date'
      }}
    />
  );
}

export function DatePickerFieldStandard({
  field,
  form: { touched, errors },
  ...props
}) {
  const mainClass = useStyleTextFeild();
  return (
    <DatePicker
      {...field}
      error={Boolean(touched[field.name] && errors[field.name])}
      helperText={touched[field.name] && errors[field.name]}
      maxDate={VALIDATE_MAX_DATE}
      margin="dense"
      variant="inline"
      inputVariant={props.inputVariant || 'standard'}
      fullWidth
      InputProps={{
        classes: {
          root: mainClass.root,
          notchedOutline: mainClass.notchedOutline,
          input: mainClass.input,
          focused: mainClass.notchedOutlineFocus
        }
      }}
      {...props}
    />
  );
}

export function TimePickerField({
  field,
  form: { touched, errors },
  ...props
}) {
  const mainClass = useStyleTextFeild();
  return (
    <TimePicker
      {...field}
      error={Boolean(touched[field.name] && errors[field.name])}
      helperText={touched[field.name] && errors[field.name]}
      margin="dense"
      variant="inline"
      inputVariant="outlined"
      autoOk
      fullWidth
      ampm={false}
      InputProps={{
        classes: {
          root: mainClass.root,
          notchedOutline: mainClass.notchedOutline,
          input: mainClass.input,
          focused: mainClass.notchedOutlineFocus
        }
      }}
      {...props}
    />
  );
}

export function GenderSelectField({
  field,
  form: { touched, errors },
  ...props
}) {
  const mainClass = useStyleSelectFeild();
  const rangesVal = props.ranges && props.ranges.length ? props.ranges : [];

  return (
    <FormControl fullWidth>
      <TextField
        select
        {...field}
        value={field.value || ''}
        error={Boolean(touched[field.name] && errors[field.name])}
        helperText={touched[field.name] && errors[field.name]}
        InputProps={{
          classes: {
            root: mainClass.rootSelect,
            notchedOutline: mainClass.notchedOutline,
            input: mainClass.inputSelect,
            focused: mainClass.notchedOutlineFocus
          }
        }}
        {...props}>
        {rangesVal.map(option => (
          <MenuItem
            key={option.value}
            value={option.value}
            className="select-menu-item">
            {option.label}
          </MenuItem>
        ))}
      </TextField>
    </FormControl>
  );
}

export function SelectFieldMutiple({
  field,
  form: { touched, errors },
  ...props
}) {
  return (
    <FormControl margin="dense" fullWidth>
      <SelectMutiple
        {...field}
        {...props}
        isError={Boolean(touched[field.name] && errors[field.name])}
        helperTextErr={errors[field.name]}
      />
    </FormControl>
  );
}

export function SelectCountryWithValidate({
  field,
  form: { touched, errors },
  ...props
}) {
  return (
    <FormControl margin="dense" fullWidth>
      <SelectCountry
        {...field}
        {...props}
        isError={Boolean(touched[field.name] && errors[field.name])}
        helperTextErr={errors[field.name]}
      />
    </FormControl>
  );
}

export function CheckboxCustom(props) {
  const mainClass = useStyleCheckbox();
  const { onChangeCheckbox, name, isChecked, labelText } = props;

  return (
    <div className={mainClass.checkboxCustom}>
      <FormControlLabel
        classes={{
          root: mainClass.labelFormControlCheckbox
        }}
        control={
          <Checkbox
            icon={<CircleUnchecked />}
            checkedIcon={<CircleCheckedFilled />}
            checked={isChecked || false}
            name={name}
            onChange={() => onChangeCheckbox(name)}
            classes={{
              root: mainClass.colorCheckbox,
              checked: mainClass.colorChecked
            }}
            color="primary"
          />
        }
      />
      <span className={mainClass.labelCheckbox}>{labelText}</span>
    </div>
  );
}
