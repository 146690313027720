import { makeStyles } from '@material-ui/core';
import { secondaryFont } from '../../shared/theme';

const useStyle = makeStyles(theme => ({
  wrappBack: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    padding: '10px 30px',
    cursor: 'pointer',
    transition: 'all .35s',
    color: theme.palette.color.gray,
    marginBottom: 10,
    '&:hover img': {
      transform: 'scale(1.1)'
    }
  },
  arrowBack: {
    display: 'block',
    width: 12,
    height: 12,
    marginRight: 10
  },
  txtBack: {
    fontSize: 14,
    fontFamily: secondaryFont,
    fontWeight: 500
  }
}));

export default useStyle;
