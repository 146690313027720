import { makeStyles } from '@material-ui/core';
import { primaryFont } from '../../../shared/theme';

const useStyle = makeStyles(theme => ({
  wrapp: {
    marginTop: 20
  },
  identity: {
    paddingLeft: 30,
    paddingRight: 30,
    color: theme.palette.color.orange,
    fontFamily: primaryFont,
    fontWeight: 600
  },
  wrappBtn: {
    margin: theme.spacing(1.5, 3),
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    '& button:nth-child(1)': {
      marginRight: 10
    },
    '& button:nth-child(2)': {
      marginLeft: 10
    }
  },
  deliveryStatus: {
    position: 'relative'
  },
  innerStatus: {
    display: 'inline-block',
    border: '1px solid #3A8E07',
    borderRadius: '20px',
    margin: '20px 30px 0',
    fontSize: 0
  },
  deliveryThumb: {
    display: 'inline-block',
    verticalAlign: 'middle',
    padding: '7px 20px 7px 15px',
    flexDirection: 'row',
    alignItems: 'center',
    backgroundColor: theme.palette.color.green1,
    borderTopLeftRadius: '20px',
    borderBottomLeftRadius: '20px',
    fontSize: 0
  },
  imgThumb: {
    display: 'inline-block',
    verticalAlign: 'middle'
  },
  textInfo: {
    display: 'inline-block',
    marginLeft: 10,
    verticalAlign: 'middle',
    fontSize: 16,
    fontFamily: primaryFont,
    color: theme.palette.color.black2
  },
  deliveryType: {
    display: 'inline-block',
    verticalAlign: 'middle',
    fontSize: 16,
    fontFamily: primaryFont,
    color: theme.palette.color.black2,
    padding: '7px 20px 7px 15px'
  },
  deliveryFinal: {
    position: 'relative',
    display: 'inline-block',
    verticalAlign: 'middle',
    fontSize: 14,
    fontFamily: primaryFont,
    fontWeight: 'bold',
    padding: '7px 15px'
  },
  complete: {
    color: theme.palette.color.green,
    '&:before': {
      content: '" "',
      position: 'absolute',
      top: '50%',
      left: 0,
      width: 4,
      height: 4,
      borderRadius: '50%',
      backgroundColor: theme.palette.color.green,
      transform: 'translateY(-50%)'
    }
  },
  fail: {
    color: theme.palette.color.red,
    '&:before': {
      content: '" "',
      position: 'absolute',
      top: '50%',
      left: 0,
      width: 4,
      height: 4,
      borderRadius: '50%',
      backgroundColor: theme.palette.color.red,
      transform: 'translateY(-50%)'
    }
  }
}));
export default useStyle;
