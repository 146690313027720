import React, { useState, useEffect } from 'react';
import { isEmpty } from 'lodash';
import { connect } from 'react-redux';
import { useTranslation } from 'react-i18next';
import Box from '@material-ui/core/Box';
import Container from '@material-ui/core/Container';
import { getUserProfile } from 'maua-redux-core/selectors/user';
import {
  fetchTransactions,
  exportTransactions
} from 'maua-redux-core/actions/transaction';
import AddBusinessModal from '../../components/Mystore/AddBusinessModal';
import { toastMessage, TOAST_TYPE } from '../../utils/toastify-notify';
import { queryTransactionList } from '../../store/query/transactions';
import { getOpenModalAddBusiness } from '../../store/selectors/modal';
import { actToggleModalAddBusiness } from '../../store/actions/modal';
import { actSetLoading } from '../../store/actions/loading';
import useStyle, { useStyleModal } from './style';
import { downloadFile } from '../../utils/common';
import FilterComponent from './Filter';
import TableList from './TableList';

const Transactions = props => {
  const {
    profile,
    fetchTransactions,
    setLoading,
    getExportTransactions
  } = props;

  const { t } = useTranslation();

  const classes = useStyle();
  const classModal = useStyleModal();

  const [filter, setFilter] = useState({
    of: '',
    type: 'vendor payment',
    paymentMethod: '',
    status: '',
    code: '', //transaction code is ID field on UI
    orderCode: '',
    from: null,
    to: null
  });

  const [data, setData] = useState({
    docs: [],
    limit: 20,
    offset: 0,
    page: 0,
    pages: 1,
    total: 0
  });

  useEffect(() => {
    if (!profile?._id) return;

    loadTransactions();
  }, [profile, filter]);

  const loadTransactions = async (page = 0) => {
    try {
      setLoading({ status: true });

      let payload = {
        ...filter
      };

      payload = Object.keys(payload).reduce((result, key) => {
        return !isEmpty(payload[key])
          ? { ...result, [key]: payload[key] }
          : result;
      }, {});

      payload.page = page + 1 || 1;

      payload.limit = data?.limit || 20;

      payload.offset = data?.offset || 0;

      const resp = await fetchTransactions(payload);

      setData({
        docs: resp?.docs,
        limit: resp?.limit,
        offset: resp?.offset,
        page: resp?.page,
        pages: resp?.pages,
        total: resp?.total
      });
    } catch (error) {
      toastMessage(TOAST_TYPE.ERROR, error.message);
    } finally {
      setLoading({ status: false });
    }
  };

  // handle export file
  const handleExport = async () => {
    setLoading({ status: true });

    try {
      let payload = {
        ...filter
      };

      payload = Object.keys(payload).reduce((result, key, idx) => {
        return !isEmpty(payload[key])
          ? { ...result, [key]: payload[key] }
          : result;
      }, {});

      const { exportTransactions } = await getExportTransactions(payload);

      downloadFile(exportTransactions);

      toastMessage(
        TOAST_TYPE.SUCCESS,
        `${t('toastMessage.success.content.Export file successfully')}`
      );
    } catch (error) {
      toastMessage(TOAST_TYPE.ERROR, error.message);
    } finally {
      setLoading({ status: false });
    }
  };

  const onCloseModalAddBusiness = () => {
    props.toggleModalAddBusiness(false);
  };

  return (
    <Box className={classes.root}>
      <FilterComponent
        filter={filter}
        setFilter={setFilter}
        handleExport={handleExport}
      />

      <Container maxWidth="xl">
        <Box className={classes.container}>
          <TableList loadTransactions={loadTransactions} data={data} />
        </Box>
      </Container>

      {props.openModalAddBusiness ? (
        <AddBusinessModal
          openModel={props.openModalAddBusiness}
          handleClose={onCloseModalAddBusiness}
          classOutSide={classModal}
        />
      ) : null}
    </Box>
  );
};

export default connect(
  state => ({
    profile: getUserProfile(state),
    openModalAddBusiness: getOpenModalAddBusiness(state)
  }),
  dispatch => ({
    setLoading: status => dispatch(actSetLoading(status)),
    fetchTransactions: payload =>
      dispatch(fetchTransactions(payload, queryTransactionList)),
    getExportTransactions: payload => dispatch(exportTransactions(payload)),
    toggleModalAddBusiness: value => dispatch(actToggleModalAddBusiness(value))
  })
)(Transactions);
