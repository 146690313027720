import React, { useEffect } from 'react';
import { BrowserRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { SnackbarProvider } from 'notistack';
import MomentUtils from '@date-io/moment';
import { MuiThemeProvider } from '@material-ui/core/styles';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import {
  fetchUserProfile,
  loadCredentials
} from 'maua-redux-core/actions/user';
import { fetchSettingByKey } from 'maua-redux-core/actions/setting';
import { switchLocation } from 'maua-redux-core/actions/user';
import { toastMessage, TOAST_TYPE } from './utils/toastify-notify';
import {
  MAUA_DEFAULT_LOCATION_KEY,
  MAUA_PHONE,
  MAUA_PLATFORM_CURRENCY
} from './shared/constant/const';
import { getUserProfile } from 'maua-redux-core/selectors/user';
import InputProfileModal from './components/InputProfileModal';
import { setCoordinate } from './store/actions/coordinate';
import { getCredentials } from './shared/services/token';
import { setCurrency } from './store/actions/currency';
import { ErrorBoundary } from './utils/errorBoundary';
import { checkLocation } from './utils/checkLocation';
import { queryProfile } from './store/query/user';
import MetaService from './shared/services/meta';
import { setVersion } from './utils/i18n.js';
import { theme } from './shared/theme';
import { Routes } from './routes';
import './App.css';

function App(props) {
  // call action check location
  const switchLocation = async () => {
    try {
      await props.switchLocation(checkLocation().code);
    } catch (error) {
      toastMessage(TOAST_TYPE.ERROR, error.message);
    }
  };

  useEffect(() => {
    switchLocation();
  }, []);

  // function to load currency for country
  const loadCurrency = async () => {
    try {
      const { setting } = await props.fetchSettingByKey({
        key: MAUA_PLATFORM_CURRENCY
      });

      props.setCurrency({ symbol: JSON.parse(setting) });
    } catch (error) {
      toastMessage(TOAST_TYPE.ERROR, error.message);
    }
  };

  const loadCoordinate = async () => {
    try {
      const { setting } = await props.fetchSettingByKey({
        key: MAUA_DEFAULT_LOCATION_KEY
      });

      const data = {
        lng: JSON.parse(setting)?.location?.coordinates[0],
        lat: JSON.parse(setting)?.location?.coordinates[1]
      };

      props.setCoordinate({ location: data });
    } catch (error) {
      toastMessage(TOAST_TYPE.ERROR, error.message);
    }
  };

  useEffect(() => {
    loadCurrency();
    loadCoordinate();
    props.fetchSettingByKey({ key: MAUA_PHONE });
  }, []);

  const { i18n } = useTranslation();

  useEffect(() => {
    const fetchMetaData = async () => {
      const { data } = await MetaService.get();

      // update language version
      setVersion(data.lang_ver);
    };

    const credentials = getCredentials();
    i18n.changeLanguage(
      JSON.parse(localStorage.getItem('vendor_ln'))?.shortCode || 'GB'
    );

    if (credentials) {
      props.loadCredentials(credentials);
      props.fetchUserProfile(null, queryProfile);
    }

    fetchMetaData();
  }, []);

  return (
    <ErrorBoundary>
      <MuiThemeProvider theme={theme}>
        <MuiPickersUtilsProvider utils={MomentUtils}>
          <SnackbarProvider
            anchorOrigin={{ vertical: 'top', horizontal: 'right' }}>
            <BrowserRouter children={Routes} />
            <InputProfileModal />
          </SnackbarProvider>
        </MuiPickersUtilsProvider>
      </MuiThemeProvider>
    </ErrorBoundary>
  );
}

export default connect(
  state => ({ profile: getUserProfile(state) }),
  {
    setCurrency,
    setCoordinate,
    switchLocation,
    loadCredentials,
    fetchUserProfile,
    fetchSettingByKey
  }
)(App);
