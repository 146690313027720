import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { Field, Form, Formik } from 'formik';
import { Typography } from '@material-ui/core';
import Grid from '@material-ui/core/Grid';

import { useStyle } from './style';

import ModalCustom from '../../ModalCustom';
import { CustomTextField, SelectCountryWithValidate } from '../../FormFeild';
import Button from '../../Button';
import {
  actToggleModalWaitingStaffPhone,
  actToggleModalStaffNotFound,
  actToggleModalFoundStaff
} from '../../../store/actions/modal';

// import { actSetLoading } from '../../../store/actions/loading';
// import { addEmployee } from 'maua-redux-core/actions/store';
import { vendorSearchUsers } from 'maua-redux-core/actions/user';
import { queryEmployee } from '../../../store/query/store';

import { toastMessage, TOAST_TYPE } from '../../../utils/toastify-notify';
import { enterStaffPhoneSchema } from '../../../utils/schema';

import AddStaffImg from '../../../assets/img/AddStaff.png';
import { actSetDataEmployeeAddStaff } from '../../../store/actions/mystores';
import { useTranslation } from 'react-i18next';

let setFieldEnterStaff,
  setFieldErrorEnterStaff,
  setTouchedEnterStaff,
  touchedEnterStaff;

const ModalEnterStaffPhone = props => {
  const mainClass = useStyle();
  const { t } = useTranslation();
  const [code, setCode] = useState('');
  const [valuePhoneCode, setValuePhoneCode] = useState(null);

  useEffect(() => {
    if (valuePhoneCode !== null) {
      setFieldEnterStaff('phoneCode', valuePhoneCode);
    }
  }, [valuePhoneCode]);

  const handleSearchStaff = async params => {
    try {
      await props
        .handleSearchStaffRequest(params)
        .then(({ vendorSearchUsers }) => {
          props.toggleModalWaitingStaffPhone(false);

          if (vendorSearchUsers.length) {
            props.setDataEmployeeAddStaff(vendorSearchUsers);
            props.toggleModalFoundStaff(true);
          } else {
            props.toggleModalStaffNotFound(true);
          }
        });
    } catch (err) {
      toastMessage(TOAST_TYPE.ERROR, err.message);
    }
  };

  const handleOnSubmit = (e, values) => {
    let timeOutSearch;
    e.preventDefault();
    props.handleClose();
    props.toggleModalWaitingStaffPhone(true);

    let phone = values.phone;
    const existStartWithPhoneEqual0 = values.phone.split('')[0] === '0';

    if (existStartWithPhoneEqual0) {
      phone = values.phone.substring(1);
    }

    const params = {
      phone: code?.value?.replace('+', '') + phone
    };

    timeOutSearch && clearTimeout(timeOutSearch);
    timeOutSearch = setTimeout(() => {
      handleSearchStaff(params);
    }, 1000);
  };

  const initialForm = {
    phone: ''
  };

  return (
    <div className={mainClass.root}>
      <ModalCustom {...props}>
        <Typography variant="h5" className={mainClass.title}>
          {t('mystore.editStore.title.Add Staff')}
        </Typography>
        <div className={mainClass.wrappImg}>
          <img
            src={AddStaffImg}
            alt="Add Staff"
            className={mainClass.imgAddStaff}
          />
        </div>
        <Typography variant="h4" className={mainClass.titlePrimary}>
          {t('mystore.staff.popup.title.Enter Staff Phone Number')}
        </Typography>

        <Formik
          initialValues={initialForm}
          enableReinitialize={true}
          onSubmit={() => {}}
          validationSchema={enterStaffPhoneSchema}
          render={({
            values,
            handleChange,
            errors,
            touched,
            handleBlur,
            validateForm,
            handleReset,
            setFieldValue,
            setFieldError,
            isValid,
            setTouched
          }) => {
            setFieldEnterStaff = setFieldValue;
            setFieldErrorEnterStaff = setFieldError;
            setTouchedEnterStaff = setTouched;
            touchedEnterStaff = touched;

            return (
              <Form onSubmit={e => e.preventDefault()}>
                <div className={mainClass.content}>
                  <div className={mainClass.innerContent}>
                    <Grid>
                      <Grid container item spacing={1}>
                        <Grid item xs={4}>
                          <Field
                            name="phoneCode"
                            margin="dense"
                            label=""
                            className={mainClass.field}
                            fullWidth
                            required
                            variant="outlined"
                            // handleOnchange={handleOnchangeCountry}
                            component={SelectCountryWithValidate}
                            setCode={setCode}
                            // onChange={() => {
                            //   handleChange();
                            // }}
                          />
                        </Grid>
                        <Grid item xs={8}>
                          <Field
                            name="phone"
                            margin="dense"
                            label=""
                            placeholder={t('mystore.label.Phone number')}
                            variant="outlined"
                            className={mainClass.field}
                            fullWidth
                            component={CustomTextField}
                            onChange={handleChange}
                            InputProps={{
                              classes: {
                                root: mainClass.rootTextFeild,
                                notchedOutline: mainClass.notchedOutline,
                                input: mainClass.inputTextFeild,
                                focused: mainClass.notchedOutlineFocus
                              }
                            }}
                          />
                        </Grid>
                      </Grid>
                    </Grid>
                  </div>
                </div>
                <div className={mainClass.wrappBtn}>
                  <Button
                    typeBtn="yellow"
                    type="button"
                    onClick={e => handleOnSubmit(e, values)}
                    disabled={!isValid}>
                    {t('action.OK')}
                  </Button>
                </div>
              </Form>
            );
          }}
        />
      </ModalCustom>
    </div>
  );
};

const mapStateToProps = state => {
  return {
    // addressFromGetLocation: getAddress(state)
  };
};
// queryUserInfo
const mapDispatchToProps = dispatch => {
  return {
    // setLoading: status => dispatch(actSetLoading(status)),
    handleSearchStaffRequest: params =>
      dispatch(vendorSearchUsers(params, queryEmployee)),
    toggleModalWaitingStaffPhone: value =>
      dispatch(actToggleModalWaitingStaffPhone(value)),
    toggleModalStaffNotFound: value =>
      dispatch(actToggleModalStaffNotFound(value)),
    toggleModalFoundStaff: value => dispatch(actToggleModalFoundStaff(value)),
    setDataEmployeeAddStaff: value =>
      dispatch(actSetDataEmployeeAddStaff(value))
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ModalEnterStaffPhone);
