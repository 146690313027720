import React, { useEffect } from 'react';
import moment from 'moment';
import { get } from 'lodash';

export const getDistance = distance => {
  return (distance / 1000).toFixed(1);
};
export const checkArrExist = arr => {
  return arr ? arr : [];
};
export const mapValuesToText = (vals = [], end = false) => {
  return vals.filter(val => val).join(', ');
};
export const _joinString = (array, symbol) => {
  return array.filter(Boolean).join(`${symbol}`);
};
export const calcDeliveryTime = distance => {
  return Math.round(Math.abs(distance / 1000 / 30) * 60);
};
export const formatDate = (createAt, formatType) => {
  return moment(createAt).format(formatType);
};
export const storeToSessionStorage = (key, value) => {
  sessionStorage.setItem(key, JSON.stringify(value));
};
export const getFromSessionStorage = key => {
  return JSON.parse(sessionStorage.getItem(key)) || {};
};

export const cloneDeep = data => {
  return JSON.parse(JSON.stringify(data));
};
export const upperCase = (text = '') => text.toUpperCase();
export const getError = err => get(err, 'data.response.errors[0].message', '');

export const formatCurrency = num => {
  const value = +num || 0;
  return value.toLocaleString('en-US', {
    minimumFractionDigits: 2,
    maximumFractionDigits: 2
  });
};

export const isArray = arr => Array.isArray(arr);

export const ensureArray = (arr, defaultValue) =>
  isArray(arr) ? arr : isArray(defaultValue) ? defaultValue : [];
/**
 * Get base64 from input file
 * @param {File} file
 * @returns {Promise<String>}
 */
export const toBase64 = file => {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = error => reject(error);
  });
};

export const deviceInformation = () => {
  const browsers = {
    Android: /Android/.test(navigator.userAgent),
    Cordova: !!window.cordova,
    Edge: /Edge/.test(navigator.userAgent),
    Firefox: /Firefox/.test(navigator.userAgent),
    Chrome: /Google Inc/.test(navigator.vendor),
    ChromeIOS: /CriOS/.test(navigator.userAgent),
    ChromiumBased: !!window.chrome && !/Edge/.test(navigator.userAgent),
    IE: /Trident/.test(navigator.userAgent),
    IOS: /(iPhone|iPad|iPod)/.test(navigator.platform),
    Opera: /OPR/.test(navigator.userAgent),
    Safari:
      /Safari/.test(navigator.userAgent) && !/Chrome/.test(navigator.userAgent)
  };

  return Object.keys(browsers)
    .filter(key => browsers[key])
    .reduce(
      (result, key) => {
        result.push(key);
        return result;
      },
      [navigator.oscpu || navigator.platform]
    )
    .join(' ');
};

export const isEmptyObject = obj => Object.keys(obj).length;

export const dateTimeHHmm = valDateTime =>
  moment(valDateTime).format('DD/MM/YYYY | hh:mm');
export const dateTimehhmmDDMMYY = valDateTime =>
  moment(valDateTime).format('hh:mm DD/MM/YYYY');
export const timehhmm = valDateTime => moment(valDateTime).format('hh:mm');

export const returenAddress = address => (
  <span>
    {`${address.name ? address.name + ', ' : ''}`}
    {`${address.village ? address.village + ', ' : ''}`}
    {address.country}
  </span>
);

export const getOpenHouse = openTimes => {
  let currentDay = new Date();
  let dayNumber = currentDay.getDay();
  dayNumber = dayNumber === 0 ? 7 : dayNumber;
  let currentMinutes = currentDay.getMinutes();
  let currentHours = currentDay.getHours();
  let getDayOpenTime = openTimes.filter(
    openTime => openTime.weekday === dayNumber
  )[0];
  const { enable, timeIntervals } = getDayOpenTime;
  const { end, start } = timeIntervals && timeIntervals[0];

  if (!enable) {
    return {
      enable: false
    };
  } else {
    let hasEnable =
      end.hour >= currentHours &&
      currentHours >= start.hour &&
      end.min >= currentMinutes &&
      currentMinutes >= start.min;
    return {
      enable: hasEnable,
      timeStart: `${start.hour}:${
        start.min < 10 ? '0' + start.min : start.min
      }`,
      timeEnd: `${end.hour}:${end.min < 10 ? '0' + end.min : end.min}`
    };
  }
};

export const convertTimeTo24H = time => {
  let sHours = new Date(time).getHours();
  let sMinutes = new Date(time).getMinutes();
  return {
    hour: sHours,
    min: sMinutes
  };
};

export const DATE_FORMAT = 'DD/MM/YYYY';
export const TIME_FORMAT = 'HH:mm';
export const TIME_DATE = `${TIME_FORMAT} ${DATE_FORMAT}`;

export function isFloat(n) {
  return Number(n) === n && n % 1 !== 0;
}

export const getWeekNumber = date => {
  const DAY_TO_MS = 3600 * 24 * 1000;
  const nDay = date.getDay();
  const nDate = date.getDate();
  const month = date.getMonth();
  const year = date.getFullYear();
  const sundayOfWeek = new Date(year, month);
  sundayOfWeek.setDate(nDate - nDay);
  if (sundayOfWeek.getFullYear() < year) {
    return 0;
  }
  const startOfYear = new Date(year, 0, 1);
  return Math.ceil(((sundayOfWeek - startOfYear) / DAY_TO_MS + 1) / 7);
};

export const financial = val => {
  return val > 0 ? Number.parseFloat(val).toFixed(2) : 0;
};

export const getTimeZone = () => {
  let offset = new Date().getTimezoneOffset(),
    o = Math.abs(offset);
  return (
    (offset < 0 ? '+' : '-') +
    ('00' + Math.floor(o / 60)).slice(-2) +
    ':' +
    ('00' + (o % 60)).slice(-2)
  );
};

export const isValidStoreOpenTimes = (openTimes, timezone) => {
  const localTimezone = getTimeZone();
  const normalizeTime = openTimes.reduce((acc, curr) => {
    if (curr.enable) {
      if (curr.weekday === 7) {
        curr.weekday = 0;
      }
      acc[curr.weekday] = {
        timeIntervals: curr.timeIntervals
      };
    }
    return acc;
  }, {});

  const now = moment().utcOffset(timezone || localTimezone);

  const [weekday, hour, min] = [
    now.days().toString(),
    now.hours(),
    now.minutes()
  ];

  const validWeekday = Object.keys(normalizeTime).includes(weekday);
  if (!validWeekday) {
    return false;
  }
  return normalizeTime[weekday].timeIntervals.some(time => {
    const caseStartTimeEqual =
      time.start.hour === hour && time.start.min <= min;
    const caseEndTimeEqual = time.end.hour === hour && time.end.min >= min;
    const caseNormal = time.start.hour < hour && time.end.hour > hour;
    return caseStartTimeEqual || caseEndTimeEqual || caseNormal;
  });
};

export const daysInThisMonth = () => {
  var now = new Date();
  return new Date(now.getFullYear(), now.getMonth() + 1, 0).getDate();
};

export function downloadFile(url) {
  const link = document.createElement('a');
  link.href = url;
  link.setAttribute('download', '');
  document.body.appendChild(link);
  link.click();
}

export const onClickOutside = (ref, handler) => {
  // eslint-disable-next-line react-hooks/rules-of-hooks
  useEffect(
    () => {
      const listener = event => {
        // Do nothing if clicking ref's element or descendent elements
        if (!ref.current || ref.current.contains(event.target)) {
          return;
        }

        handler(event);
      };

      document.addEventListener('mousedown', listener);
      document.addEventListener('touchstart', listener);

      return () => {
        document.removeEventListener('mousedown', listener);
        document.removeEventListener('touchstart', listener);
      };
    },
    // Add ref and handler to effect dependencies
    // It's worth noting that because passed in handler is a new ...
    // ... function on every render that will cause this effect ...
    // ... callback/cleanup to run every render. It's not a big deal ...
    // ... but to optimize you can wrap handler in useCallback before ...
    // ... passing it into this hook.
    [ref, handler]
  );
};

/**
 * Escape special characters in string
 * @param {String} string
 * @return {String}
 */
export const regexEscape = string => {
  /* eslint-disable-next-line */
  return String(string).replace(/[-\/\\^$*+?.()|[\]{}]/g, '\\$&');
};

/**
 * Convert Vietnamese to no sign
 * @param {String} str
 */
export const nonAccentVietnamese = str => {
  return String(str)
    .replace(/À|Á|Ạ|Ả|Ã|Â|Ầ|Ấ|Ậ|Ẩ|Ẫ|Ă|Ằ|Ắ|Ặ|Ẳ|Ẵ/g, 'A')
    .replace(/à|á|ạ|ả|ã|â|ầ|ấ|ậ|ẩ|ẫ|ă|ằ|ắ|ặ|ẳ|ẵ/g, 'a')
    .replace(/Ò|Ó|Ọ|Ỏ|Õ|Ô|Ồ|Ố|Ộ|Ổ|Ỗ|Ơ|Ờ|Ớ|Ợ|Ở|Ỡ/g, 'O')
    .replace(/ò|ó|ọ|ỏ|õ|ô|ồ|ố|ộ|ổ|ỗ|ơ|ờ|ớ|ợ|ở|ỡ/g, 'o')
    .replace(/Ù|Ú|Ụ|Ủ|Ủ|Ũ|Ư|Ừ|Ứ|Ự|Ử|Ữ/g, 'U')
    .replace(/ù|ú|ụ|ủ|ũ|ư|ừ|ứ|ự|ử|ữ/g, 'u')
    .replace(/È|É|Ẹ|Ẻ|Ẽ|Ê|Ề|Ế|Ệ|Ể|Ễ/g, 'E')
    .replace(/è|é|ẹ|ẻ|ẽ|ê|ề|ế|ệ|ể|ễ/g, 'e')
    .replace(/Ì|Í|Ị|Ỉ|Ĩ/g, 'I')
    .replace(/ì|í|ị|ỉ|ĩ/g, 'i')
    .replace(/Ỳ|Ý|Ỵ|Ỷ|Ỹ/g, 'Y')
    .replace(/ỳ|ý|ỵ|ỷ|ỹ/g, 'y')
    .replace(/Đ/g, 'D')
    .replace(/đ/g, 'd');
};

/**
 * Get full text search string
 * @param {string} str
 */
export const fullTextSearch = str => {
  return nonAccentVietnamese(regexEscape(str))
    .replace(/ +/g, ' ')
    .replace(/a/gi, '[aáàảãạâấầẩẫậăắằẳẵặAÁÀẢÃẠÂẤẦẨẪẬĂẮẰẲẴẶ]')
    .replace(/o/gi, '[oóòỏõọôốồổỗộơớờởỡợOÓÒỎÕỌÔỐỒỔỖỘƠỚỜỞỠỢ]')
    .replace(/e/gi, '[eéèẻẽẹêếềểễệEÉÈẺẼẸÊẾỀỂỄỆ]')
    .replace(/u/gi, '[uúùủũụưứừửữựUÚÙỦŨỤƯỨỪỬỮỰ]')
    .replace(/i/gi, '[iíìỉĩịIÍÌỈĨỊ]')
    .replace(/y/gi, '[yýỳỷỹỵYÝỲỶỸỴ]')
    .replace(/d/gi, '[dđDĐ]');
};

/**
 * Get full text search regex
 * @param {string} str
 */
export const fullTextSearchRegex = str => {
  return new RegExp(fullTextSearch(str), 'i');
};

export const formatNumberFloat = value => {
  return value?.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,') || '';
};

export const formatCurrencyField = amount => {
  const number = amount.toString().split('.');

  if (number?.length > 1) {
    return formatNumberFloat(number[0]) + '.' + number[1];
  }

  return formatNumberFloat(number[0]);
};

export const formatCurrencyToNumberFloat = string => {
  return string?.toString().replaceAll(',', '');
};

export const removeToLocalStorage = key => {
  localStorage.removeItem(key);
};
