import { createSlice } from 'redux-starter-kit';

let initialState = {
  needReloadMyStore: false,
  needFetchVendorGetStore: false,
  dataEmployeeAddStaff: null
};

export let mystoresSlice = createSlice({
  initialState: initialState,
  slice: 'mystores',
  reducers: {
    setToggleNeedReloadMyStore(state, action) {
      state.needReloadMyStore = action.payload.state;
    },
    setToggleNeedFetchVendorGetStore(state, action) {
      state.needFetchVendorGetStore = action.payload.state;
    },
    setDataEmployeeAddStaff(state, action) {
      state.dataEmployeeAddStaff = action.payload.state;
    }
  }
});

export default mystoresSlice.reducer;
