import React, { useState } from 'react';
import { connect } from 'react-redux';
import { Typography } from '@material-ui/core';
import TextField from '@material-ui/core/TextField';
import { addWallet } from 'maua-redux-core/actions/wallet';

import Button from '../../Button';
import ModalCustom from '../../ModalCustom';

import { useStyleEditWallet } from '../../../assets/styles/wallet';
import digicellIcon from '../../../assets/img/digicel-icon.png';
import addWalletImg from '../../../assets/img/addWallet.png';
import { queryAddEditWallet } from '../../../store/query/wallet';
import { actSetLoading } from '../../../store/actions/loading';
import { actSetNeedFetchWallets } from '../../../store/actions/wallets';

import { toastMessage, TOAST_TYPE } from '../../../utils/toastify-notify';
import { typeWallet } from '../../../shared/constant/const';
import { useTranslation } from 'react-i18next';

const EdditWalletDigicel = props => {
  const mainClass = useStyleEditWallet();
  const { t } = useTranslation();
  const [phoneDigicelWallet, setPhoneDigicelWallet] = useState(
    props.phoneNumber
  );
  const [isChangeFeild, setChangeFeild] = useState(false);

  const handleChangeFeild = e => {
    const regex = /^\d+$/;
    if (!regex.test(e.target.value)) return;
    setPhoneDigicelWallet(e.target.value);
    setChangeFeild(true);
  };
  const handleEditWallet = () => {
    try {
      props.setLoading({ status: true });
      props
        .editWalletRequest({
          ID: phoneDigicelWallet,
          service: typeWallet.digicel
        })
        .then(res => {
          props.handleNeedFetchWallets();
          props.handleClose();
          props.setLoading({ status: false });
          toastMessage(
            TOAST_TYPE.SUCCESS,
            t('toastMessage.success.content.Edit Wallet Success')
          );
        });
    } catch (err) {
      props.setLoading({ status: false });
      toastMessage(TOAST_TYPE.ERROR, err.message);
    }
  };

  return (
    <div className={mainClass.root}>
      <ModalCustom {...props}>
        <div className={mainClass.detail}>
          <img
            src={addWalletImg}
            alt="Add wallet thumb"
            className={mainClass.thumbAddWallet}
          />
          <Typography variant="h5" className={mainClass.title}>
            {t('mystore.wallet.popup.title.Edit wallet')}
          </Typography>
          <div className={mainClass.walletItem}>
            <div className={mainClass.walletContent}>
              <img
                src={digicellIcon}
                alt="Digicel Wallet"
                className={mainClass.walletIcon}
              />
              <div className={mainClass.walletInfo}>
                <TextField
                  id="digicel"
                  label={t('mystore.wallet.popup.label.ACCOUNT PHONE NUMBER')}
                  className={mainClass.textField}
                  value={phoneDigicelWallet}
                  onChange={handleChangeFeild}
                  margin="normal"
                  InputLabelProps={{
                    classes: {
                      root: mainClass.rootLabel,
                      focused: mainClass.labelFocus
                    }
                  }}
                  InputProps={{
                    classes: {
                      root: mainClass.rootInput,
                      focused: mainClass.focusedInputDigicel,
                      input: mainClass.inputStyle
                    },
                    disableUnderline: true
                  }}
                />
              </div>
            </div>
          </div>
          <div className={mainClass.wrappBtn}>
            <Button
              typeBtn="yellow"
              disabled={!phoneDigicelWallet.trim() || !isChangeFeild}
              onClick={handleEditWallet}>
              {t('action.Add')}
            </Button>
          </div>
        </div>
      </ModalCustom>
    </div>
  );
};
const mapDispatchToProps = dispatch => {
  return {
    setLoading: status => dispatch(actSetLoading(status)),
    editWalletRequest: params =>
      dispatch(addWallet(params, queryAddEditWallet)),
    handleNeedFetchWallets: () => dispatch(actSetNeedFetchWallets(true))
  };
};

export default connect(
  null,
  mapDispatchToProps
)(EdditWalletDigicel);
