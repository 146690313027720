import { makeStyles } from '@material-ui/core';
import { primaryFont } from '../../shared/theme';

export const useStyle = makeStyles(theme => ({
  weekday: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    width: '50%'
  },
  weekdayLabel: {
    fontSize: 18,
    color: theme.palette.color.gray1,
    fontFamily: primaryFont
  },
  day: {
    display: 'flex'
  },
  labelFormControlCheckbox: {
    marginRight: 0
  },
  colorCheckbox: {
    color: `${theme.palette.color.white1} !important`
  },
  colorChecked: {
    color: `${theme.palette.color.orange} !important`
  },
  timeStart: {
    width: 100
  },
  timeEnd: {
    width: 100
  },
  spaceTime: {
    marginLeft: 15,
    marginRight: 15
  },
  closeTime: {
    fontSize: 18,
    color: theme.palette.color.red,
    fontFamily: primaryFont
  },
  currentDay: {
    color: theme.palette.color.black1,
    fontWeight: 600
  },
  icons: {
    cursor: 'pointer',
    color: '#E38D03'
  },
  openHourControll: {
    justifyContent: 'center'
  }
}));
