import React from 'react';
import Card from '@material-ui/core/Card';
import CardHeader from '@material-ui/core/CardHeader';
import CardContent from '@material-ui/core/CardContent';
import CardActions from '@material-ui/core/CardActions';
import Typography from '@material-ui/core/Typography';

function CustomCard(props) {
  const {
    classRoot,
    // classHeader,
    // classContent,
    // Title,
    // ContentComponent,
    // HeaderAction,
    // Avatar,
    // ActionComponent,
    // subheader,
    children
  } = props;
  return (
    <Card className={classRoot}>
      {/* {Title && (
        <CardHeader
          avatar={Avatar && <Avatar />}
          action={HeaderAction && <HeaderAction />}
          className={classHeader}
          title={<Title />}
          subheader={subheader}
        />
      )}

      {ContentComponent && (
        <CardContent className={classContent}>
          <ContentComponent />
        </CardContent>
      )}

      {ActionComponent && (
        <CardActions>
          <ActionComponent />
        </CardActions>
      )} */}
      {children}
    </Card>
  );
}

export default CustomCard;
