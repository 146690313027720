import { makeStyles } from '@material-ui/core';

export const useStyle = makeStyles(theme => ({
  avatar: {
    width: 50,
    height: 50
  },
  defaultAvatar: {
    width: 45,
    height: 45,
    '& img': {
      objectFit: 'none',
      borderRadius: '50%',
      backgroundColor: theme.palette.color.black1
    }
  },
  dropdown: {
    position: 'absolute',
    top: 59,
    right: -10,
    background: theme.palette.color.white,
    width: 200,
    borderBottomLeftRadius: 15,
    borderBottomRightRadius: 15,
    boxShadow: ' 0px 4px 8px rgba(130, 126, 90, 0.1)',
    paddingBottom: theme.spacing(1)
  },

  dropDrownItem: {
    textDecoration: 'none',
    padding: theme.spacing(1, 2),
    display: 'block',
    '& span': {
      color: theme.palette.color.black2
    },
    cursor: 'pointer',
    '&:hover span': {
      color: theme.palette.color.orange
    }
  },
  activeDropDownItem: {
    '& span': {
      color: theme.palette.color.orange
    }
  }
}));
