import React, { useEffect, useState } from 'react';
import { connect, useSelector } from 'react-redux';
import { Field, Form, Formik } from 'formik';
import { Checkbox, FormControlLabel, Typography } from '@material-ui/core';
import Grid from '@material-ui/core/Grid';
import { editStore, fetchStore } from 'maua-redux-core/actions/store';
import { fetchMoreCategories } from 'maua-redux-core/actions/category';
import { getAddress } from 'maua-redux-core/selectors/address';
import { fetchAddress } from 'maua-redux-core/actions/address';
import moment from 'moment';
import { useStyle } from './style';

import ModalCustom from '../../ModalCustom';
import {
  CustomTextField,
  SelectFieldMutiple,
  SelectFieldSingle
} from '../../FormFeild';
import Button from '../../Button';
import OpenHourTimeItem from '../../OpenHourTimeItem';
import GoogleMap from '../../GoogleMap';
import UploadComponent from '../../UploadComponent';
import PictureComponentUpload from '../../PictureComponetUpload';

import { actSetLoading } from '../../../store/actions/loading';
import { queryStoreInMystore } from '../../../store/query/store';
import { queryCategoryInMystore } from '../../../store/query/categories';
import { queryAddress } from '../../../store/query/user';
import { actToggleNeedreloadMyStore } from '../../../store/actions/mystores';

import {
  timehhmm,
  ensureArray,
  isEmptyObject,
  convertTimeTo24H,
  getTimeZone
} from '../../../utils/common';
import { login } from 'maua-redux-core/actions/user';
import { toastMessage, TOAST_TYPE } from '../../../utils/toastify-notify';
import { getCoordinate } from '../../../store/selectors/coordinate';
import EditorField from '../../FieldWithoutFormik/EditorField';
import TIMEZONE_MAP from '../../../shared/constant/timezone';
import { uploadImg } from '../../../shared/services/upload';
import { editYourStoreSchema } from '../../../utils/schema';
import SelectFilter from '../../FieldWithoutFormik/Select';
import SelectCustom from '../../FormFeild/SelectCustom';
import Divider from '@material-ui/core/Divider';
import { useTranslation } from 'react-i18next';
import AlaNumSearch from '../../AlaNumSearch';

let setFeildForFormEditYourStore = null;

const EditYourStoreModal = props => {
  const mainClass = useStyle();
  const { t } = useTranslation();
  const locationDefault = useSelector(getCoordinate);
  // const stylesUpload = useStyleUpload();
  const autoSyncTimeZone = JSON.parse(localStorage.getItem('autoSyncTimezone'));

  const [weekdayCheck, setWeekdayCheck] = useState({});
  const [data, setData] = useState({});
  const [categoriesList, setCategories] = useState([]);
  const [hasChangeOpenTime, setHasChangeOpenTime] = useState(false);
  const { address, categories, name, phone, description, openTimes, media } =
    data || {};
  const [hasImgUpdate, setHasImgUpdate] = useState(false);
  const [files, setFiles] = useState([]);
  const [filesUrl, setFilesUrl] = useState([]);
  const [filesMedia, setFilesMedia] = useState([]);

  const loadStore = async () => {
    try {
      props.setLoading({ status: true });
      const { store } = await props.fetchStoreRequest({
        _id: props.storeID
      });

      const initialOpenTimes = store.openTimes.reduce((result, time) => {
        const { weekday, timeIntervals, enable } = time;

        const initialTimeInterval = timeIntervals.map(interval => {
          const { start, end } = interval;

          const startTime = new Date();
          const endTime = new Date();

          startTime.setHours(start.hour);
          startTime.setMinutes(start.min);

          endTime.setHours(end.hour);
          endTime.setMinutes(end.min);
          return { start: startTime, end: endTime };
        });

        return {
          ...result,
          [weekday]: {
            enable,
            opening: initialTimeInterval,
            weekday
          }
        };
      }, {});

      setFiles(store.media);
      setFilesUrl(store.media);
      setFilesMedia(store.media);

      setData(store);
      setWeekdayCheck(initialOpenTimes);
    } catch (err) {
      toastMessage(TOAST_TYPE.ERROR, err.message);
    } finally {
      props.setLoading({ status: false });
    }
  };

  useEffect(() => {
    props
      .handleFetchCateGory({ limit: 100, mainOnly: true })
      .then(({ categories }) => setCategories(categories.docs))
      .catch(error => {
        toastMessage(TOAST_TYPE.ERROR, error.message);
      });
  }, []);

  useEffect(() => {
    if (props.storeID) {
      loadStore();
    }
  }, [props.storeID]);
  // handle for map
  useEffect(() => {
    if (address && isEmptyObject(address)) {
      getGeoLocation(locationDefault);
    }
  }, [address]);

  const getGeoLocation = async location => {
    if (navigator.geolocation && address) {
      props.onFetchAddress({ alaNum: address.alaNum });
    } else {
      if (!isEmptyObject(navigator.geolocation)) {
        props.onFetchAddress(location);
      } else {
        await navigator.geolocation.getCurrentPosition(position => {
          const dataPinMapDefault = {
            lat: position.coords.latitude,
            lng: position.coords.longitude
          };

          props.onFetchAddress(dataPinMapDefault, err => {
            if (err) {
              toastMessage(TOAST_TYPE.ERROR, err.message);
            }
          });
        });
      }
    }
  };

  useEffect(() => {
    const { alaNum: alaNumFromMap } = props.addressFromGetLocation || {};
    if (alaNumFromMap && setFeildForFormEditYourStore) {
      setFeildForFormEditYourStore('alaNum', alaNumFromMap);
    } else {
      setFeildForFormEditYourStore &&
        setFeildForFormEditYourStore('alaNum', '');
    }
  }, [props.addressFromGetLocation.alaNum]);

  useEffect(() => {
    const { village: villageFromMap } = props.addressFromGetLocation || {};
    if (villageFromMap && setFeildForFormEditYourStore) {
      setFeildForFormEditYourStore('village', villageFromMap);
    } else {
      setFeildForFormEditYourStore &&
        setFeildForFormEditYourStore('village', '');
    }
  }, [props.addressFromGetLocation]);

  useEffect(() => {
    const { country: countryFromMap } = props.addressFromGetLocation || {};
    if (countryFromMap && setFeildForFormEditYourStore) {
      setFeildForFormEditYourStore('country', countryFromMap);
    } else {
      setFeildForFormEditYourStore &&
        setFeildForFormEditYourStore('country', '');
    }
  }, [props.addressFromGetLocation]);

  const handleCheckOpenTime = name => event => {
    setWeekdayCheck(prevState => {
      return {
        ...prevState,
        [name]: {
          ...prevState[name],
          enable: event.target.checked
        }
      };
    });
    setHasChangeOpenTime(true);
  };

  const handleChangeTime = (time, dayTime) => {
    const arr = dayTime.split('_');
    const timeline = arr[0];
    const type = arr[1];
    const day = arr[2];

    let timeFormat = timehhmm(time).split(':'),
      hourCurrent = timeFormat[0],
      minutesCurrent = timeFormat[1];

    let timePrev = weekdayCheck[`${day}`][`${type}`];

    let timePrevFormat = timehhmm(timePrev).split(':'),
      hourPrev = timePrevFormat[0],
      minutesPrev = timePrevFormat[1];
    let opening = weekdayCheck[day].opening?.map((el, i) => {
      if (i == timeline) el[`${type}`] = time;
      return el;
    });

    if (hourCurrent !== hourPrev || minutesCurrent !== minutesPrev) {
      setWeekdayCheck(prevState => ({
        ...prevState,
        [`${day}`]: {
          ...prevState[`${day}`],
          opening: opening
        }
      }));
      setHasChangeOpenTime(true);
    }
  };

  // const handleKeyDown = async e => {
  //   const { target } = e;
  //   const alaNumValue = target.value;
  //   if (!!alaNumValue.length && e.key === 'Enter') {
  //     await props.onFetchAddress({ alaNum: alaNumValue });
  //   }
  // };

  // const handleOnblur = async e => {
  //   const { target } = e;
  //   const alaNumValue = target.value;
  //   if (!!alaNumValue.length) {
  //     await props.onFetchAddress({ alaNum: alaNumValue });
  //   }
  // };

  const handleOnSubmit = async (e, values) => {
    e.preventDefault();

    try {
      props.setLoading({ status: true });
      const { _id } = data || {};
      const { categories, name, phone, timezone, description } = values;

      let openTimes = Object.keys(weekdayCheck).map(key => {
        const item = weekdayCheck[key];

        let timeInterval = item?.opening.reduce((result, timeline, idx) => {
          let { hour: hourStart, min: minutesStart } = convertTimeTo24H(
            timeline.start
          );
          let { hour: hourEnd, min: minutesEnd } = convertTimeTo24H(
            timeline.end
          );

          return [
            ...result,
            {
              start: {
                hour: hourStart,
                min: minutesStart
              },
              end: {
                hour: hourEnd,
                min: minutesEnd
              }
            }
          ];
        }, []);

        return {
          weekday: item.weekday ? item.weekday : 7,
          enable: item.enable,
          timeIntervals: timeInterval
        };
      });

      const dataFiles = [];

      files.forEach(itemFile => {
        if (typeof itemFile === 'object') {
          dataFiles.push(itemFile);
        }
      });

      const {
        country,
        village,
        alaNum,
        location
      } = props.addressFromGetLocation;

      let params = {
        _id,
        name,
        phone,
        description,
        categories: categories.map(category => category.value),
        address: {
          village: village || '',
          country,
          alaNum,
          location: {
            type: 'Point',
            coordinates: location.coordinates
          }
        },
        media: filesMedia,
        openTimes,
        timezone: timezone?.value
      };

      await props.handleEditStoreRequest(params).then(response => {
        if (response && dataFiles.length) {
          uploadImage(response.editStore._id, dataFiles);
        } else {
          props.handleClose();
          props.toggleNeedReloadMyStore(true);

          toastMessage(
            TOAST_TYPE.SUCCESS,
            `${t('toastMessage.success.content.Edit Your Store Success')}`
          );
        }
      });
      localStorage.setItem('autoSyncTimezone', values.autoSyncTimeZone);

      !values.autoSyncTimeZone &&
        localStorage.setItem('canceledSyncTimezone', false);

      props.setLoading({ status: false });
    } catch (err) {
      props.setLoading({ status: false });
      toastMessage(TOAST_TYPE.ERROR, err.message);
    } finally {
      props.setLoading({ status: false });
    }
  };

  const handleCheckValidation = validateForm => {
    validateForm().then(err => {
      if (Object.keys(err).length > 0) {
        toastMessage(TOAST_TYPE.ERROR, err[Object.keys(err)[0]]);
      }
    });
  };

  const handleRemovePicture = idx => {
    let cloneFiles = [...files];
    let cloneFilesUrl = [...filesUrl];
    const fileImgDelete = cloneFilesUrl[idx];
    setFilesMedia(prevListUrl => {
      return prevListUrl.filter(urlLink => urlLink !== fileImgDelete);
    });
    cloneFiles.splice(idx, 1);
    cloneFilesUrl.splice(idx, 1);
    setFiles(cloneFiles);
    setFilesUrl(cloneFilesUrl);
    setHasImgUpdate(true);
  };

  const handleChangeImage = el => {
    let inputEl = el.target;
    const fileData = inputEl.files[0];
    const reader = new FileReader();
    if (fileData) {
      setFiles(prevState => [...prevState, fileData]);
      reader.readAsDataURL(fileData);
    }

    reader.onloadend = () => {
      setFilesUrl(prevState => [...prevState, reader.result]);
    };
    el.target.value = '';
    setHasImgUpdate(true);
  };

  const uploadProgress = progress => {
    let percent = Math.floor((progress.loaded / progress.total) * 100);
    percent !== props.loading.percent &&
      props.setLoading({ status: true, percent });
  };

  const handleRemoveTime = (e, dayTime) => {
    const arr = dayTime.split('_');
    const timeline = arr[0];
    const day = arr[1];
    let opening = weekdayCheck[day].opening?.filter((el, i) => i != timeline);
    setWeekdayCheck(prevState => ({
      ...prevState,
      [`${day}`]: {
        ...prevState[`${day}`],
        opening: opening
      }
    }));

    setHasChangeOpenTime(true);
  };

  const handleAddTime = (e, dayTime) => {
    const arr = dayTime.split('_');
    const timeline = arr[0];
    const day = arr[1];
    let now = moment();
    const startTime = now.startOf('day').toString();
    const endTime = now.endOf('day').toString();

    setWeekdayCheck(prevState => ({
      ...prevState,
      [`${day}`]: {
        ...prevState[`${day}`],
        opening: [
          ...prevState[`${day}`].opening,
          { start: startTime, end: endTime }
        ]
      }
    }));

    setHasChangeOpenTime(true);
  };

  const uploadImage = (_idStore, filesData) => {
    const imgData = {
      _id: _idStore,
      model: 'Store',
      field: 'media',
      files: filesData
    };
    uploadImg(imgData, uploadProgress)
      .then(() => {
        props.handleClose();
        props.setLoading({ status: false });
        props.toggleNeedReloadMyStore(true);
        toastMessage(TOAST_TYPE.SUCCESS, 'Edit Store Success!');
      })
      .catch(err => {
        props.setLoading({ status: false });
        toastMessage(TOAST_TYPE.ERROR, err.message);
      })
      .finally(() => {
        props.setLoading({ status: false, percent: 0 });
      });
  };

  const categoriesData =
    categoriesList && categoriesList.length
      ? categoriesList.map(({ _id, name }) => ({ value: _id, label: name }))
      : [];

  const { village, alaNum, country } = address || {};

  const initialForm = {
    autoSyncTimeZone: Boolean(autoSyncTimeZone),
    name: name || '',
    phone: phone || '',
    description: description || '',
    village: village || '',
    alaNum: alaNum || '',
    country: country || '',
    timezone: TIMEZONE_MAP.find(
      ({ value }) => value === data.timezone
      // && data.timezone.country
    ),
    categories: ensureArray(categories).map(({ _id }) => {
      let val = categoriesData.find(({ value }) => value === _id);
      return {
        value: _id,
        label: (val && val.label) || 'Something went wrong.'
      };
    })
  };

  return (
    <div className={mainClass.root}>
      <ModalCustom {...props}>
        <Typography variant="h5" className={mainClass.title}>
          {t('mystore.editStore.title.Edit your store')}
        </Typography>
        <div className={mainClass.wrappUploadContainer}>
          <Grid container className={mainClass.listImg} spacing={3}>
            {filesUrl.length
              ? filesUrl.map((urlImg, idx) => (
                  <Grid item xs={4} className={mainClass.itemShowImg} key={idx}>
                    <PictureComponentUpload
                      url={urlImg}
                      idx={idx}
                      handleRemovePicture={handleRemovePicture}
                    />
                  </Grid>
                ))
              : null}
            <Grid item xs={4} className={mainClass.itemAddImg}>
              {filesUrl.length < 4 ? (
                <UploadComponent handleUpload={handleChangeImage} />
              ) : null}
            </Grid>
          </Grid>
        </div>
        <Formik
          initialValues={initialForm}
          enableReinitialize={true}
          onSubmit={() => {}}
          validationSchema={editYourStoreSchema}
          render={({
            values,
            handleChange,
            errors,
            touched,
            handleBlur,
            validateForm,
            handleReset,
            setFieldValue,
            isValid
          }) => {
            setFeildForFormEditYourStore = setFieldValue;

            const handleChangePhone = e => {
              const regExp = new RegExp('^(\\d)*$', 'g'); //check only number

              const { value, name } = e.target;

              if (!regExp.test(value)) return;

              setFieldValue(name, value);
            };

            const handleUpdateTimezone = () => {
              setFieldValue('autoSyncTimeZone', !values.autoSyncTimeZone);

              if (!values.autoSyncTimeZone) {
                const CurrTimezone = TIMEZONE_MAP.find(
                  ({ value }) => value === getTimeZone()
                  // && data.timezone.country
                );

                setFieldValue('timezone', CurrTimezone);
              }
            };
            return (
              <Form onSubmit={e => e.preventDefault()}>
                <div className={mainClass.content}>
                  <div className={mainClass.innerContent}>
                    <Grid>
                      <Grid item>
                        <Field
                          name="name"
                          margin="dense"
                          label={t('mystore.editStore.label.NAME')}
                          className={mainClass.field}
                          fullWidth
                          component={CustomTextField}
                          onChange={handleChange}
                        />
                      </Grid>
                      <Grid item>
                        <Field
                          name="phone"
                          margin="dense"
                          label={t('mystore.editStore.label.PHONE NUMBER')}
                          className={mainClass.field}
                          fullWidth
                          required
                          component={CustomTextField}
                          onChange={handleChangePhone}
                        />
                      </Grid>
                      <Grid item>
                        <AlaNumSearch />
                      </Grid>
                      <Grid item className={mainClass.mt15}>
                        <GoogleMap
                          size={400}
                          address={address}
                          isPinToMap={true}
                        />
                      </Grid>
                      <Grid item>
                        <Typography
                          variant="subtitle2"
                          className={mainClass.subtitle}>
                          {t('mystore.editStore.title.Categories')}
                        </Typography>
                        <Field
                          name="categories"
                          margin="dense"
                          variant="outlined"
                          placeholder=""
                          // label=""
                          className={mainClass.categoriesMenu}
                          required
                          suggestions={categoriesData}
                          setFieldValue={setFieldValue}
                          component={SelectFieldMutiple}
                          onChange={handleChange}
                        />
                      </Grid>
                      <Grid item>
                        <Typography
                          variant="subtitle2"
                          className={mainClass.subtitle}>
                          {t('mystore.editStore.title.Timezone')}
                        </Typography>
                        <SelectFilter
                          label=""
                          zIndex={1}
                          name="timezone"
                          value={values.timezone}
                          defaultInputValue=""
                          options={TIMEZONE_MAP}
                          onChange={e => setFieldValue('timezone', e)}
                        />
                        <FormControlLabel
                          control={
                            <Checkbox
                              color="primary"
                              style={{
                                fontSize: 10
                              }}
                              checked={values.autoSyncTimeZone}
                              onChange={handleUpdateTimezone}
                              name="Sync Timezone"
                              classes={{
                                root: mainClass.colorCheckbox,
                                checked: mainClass.colorChecked
                              }}
                            />
                          }
                          label={`${t(
                            'mystore.editStore.label.Enable auto update'
                          )}?`}
                        />
                      </Grid>
                      <Grid>
                        <Typography
                          variant="subtitle2"
                          className={mainClass.subtitle}>
                          {t('mystore.editStore.title.Hours of Operation')}
                        </Typography>
                        <div className={mainClass.listOpenHour}>
                          <Divider />
                          {isEmptyObject(weekdayCheck)
                            ? Object.keys(weekdayCheck).map(key => (
                                <React.Fragment key={key}>
                                  <OpenHourTimeItem
                                    weeekdayData={weekdayCheck[key]}
                                    onChangeCheckbox={handleCheckOpenTime}
                                    handleChangeTime={handleChangeTime}
                                    handleRemoveTime={handleRemoveTime}
                                    handleAddTime={handleAddTime}
                                  />
                                  <Divider />
                                </React.Fragment>
                              ))
                            : null}
                        </div>
                      </Grid>
                      <Grid item>
                        <Typography
                          variant="subtitle2"
                          className={mainClass.subtitle}>
                          {t('mystore.editStore.label.Description')}
                        </Typography>
                        <EditorField
                          model="Store"
                          value={description}
                          onChange={value =>
                            handleChange({
                              target: { name: 'description', value }
                            })
                          }
                        />
                      </Grid>
                    </Grid>
                    <div className={mainClass.wrappBtn}>
                      <Button
                        typeBtn="yellow"
                        type="button"
                        handleClick={() => handleCheckValidation(validateForm)}
                        onClick={e => handleOnSubmit(e, values)}
                        disabled={
                          !isValid && !hasChangeOpenTime && !hasImgUpdate
                        }>
                        {t('action.OK')}
                      </Button>
                    </div>
                  </div>
                </div>
              </Form>
            );
          }}
        />
      </ModalCustom>
    </div>
  );
};

const mapStateToProps = state => {
  return {
    loading: state.loading,
    addressFromGetLocation: getAddress(state)
  };
};

const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    setLoading: status => dispatch(actSetLoading(status)),
    fetchStoreRequest: params =>
      dispatch(fetchStore(params, queryStoreInMystore)),
    handleEditStoreRequest: params =>
      dispatch(editStore(params, queryStoreInMystore)),
    handleFetchCateGory: params =>
      dispatch(fetchMoreCategories(params, queryCategoryInMystore)),
    onFetchAddress: params => dispatch(fetchAddress(params, queryAddress)),
    toggleNeedReloadMyStore: value =>
      dispatch(actToggleNeedreloadMyStore(value))
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(EditYourStoreModal);
