import moment from 'moment';
import { date, object, string } from 'yup';

export const MAX_DATE = moment().subtract(18, 'years');

export const profileSchema = object({
  firstName: string()
    .required('First Name is required')
    .nullable(),
  lastName: string()
    .required('Last Name is required')
    .nullable(),
  gender: string()
    .matches(/(male|female)/)
    .required('Gender is required')
    .nullable(),
  birthday: date()
    .max(MAX_DATE, 'You must be over 18 years old')
    .required('Date of Birth is required')
    .nullable()
});
