import React, { useEffect, useState } from 'react';
import clsx from 'clsx';
import { connect } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { exportProductOrdersForVendor } from 'maua-redux-core/actions/productOrder';
// import { useTranslation } from 'react-i18next';

import Export from './Export';
import Box from '@material-ui/core/Box';
import ArrowRightIcon from '@material-ui/icons/ArrowRight';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import FilterIcon from '../../../assets/img/icons/filter-icon.svg';

import { downloadFile } from '../../../utils/common';
import { queryProductOrders } from '../../../store/query/orders';
import { toastMessage, TOAST_TYPE } from '../../../utils/toastify-notify';
import { actSetLoading } from '../../../store/actions/loading';
import useStyle from './style';
import { useIsMobile } from 'src/utils/hook';
import { MenuItem, Select } from '@material-ui/core';

const NavItem = React.memo(props => {
  const { id, num, text, itemActive, changeItemActive } = props;

  const { t } = useTranslation();

  const classes = useStyle();

  let classText = itemActive === id ? classes.textActive : classes.textDefault;
  let classNumber = itemActive === id ? classes.numActive : classes.numDefault;

  return (
    <li className={classes.navItem}>
      <span
        className={classes.navItemContent}
        onClick={() => changeItemActive(id)}>
        <span className={clsx(classes.text, classText)}>{t(text)}</span>
        <span className={clsx(classes.num, classNumber)}>{num || 0}</span>
      </span>
    </li>
  );
});

const Navbar = props => {
  const classes = useStyle();
  const isMobile = useIsMobile();
  const { t } = useTranslation();
  const [activeItemId, setActiveItemId] = useState();

  const {
    data = [],
    open,
    setOpen,
    isFilter,
    setLoading,
    exportProductOrdersOfVendor,
    itemActive = null
  } = props;

  useEffect(() => {
    if (itemActive) {
      setActiveItemId(itemActive);
    }
  }, [itemActive]);

  // handle export file
  const handleExport = async filters => {
    setLoading({ status: true });

    try {
      const {
        exportProductOrdersForVendor
      } = await exportProductOrdersOfVendor(filters);

      downloadFile(exportProductOrdersForVendor);

      toastMessage(
        TOAST_TYPE.SUCCESS,
        `${t('toastMessage.success.content.Export file successfully')}.`
      );
    } catch (error) {
      toastMessage(TOAST_TYPE.ERROR, error.message);
    } finally {
      setLoading({ status: false });
    }
  };

  const handleChange = event => {
    setActiveItemId(event.target.value);
  };

  return (
    <ul className={classes.wrappNavbar}>
      {!isMobile &&
        data.map(item => <NavItem key={item.id} {...item} {...props} />)}

      {isMobile && (
        <Select value={activeItemId} onChange={handleChange}>
          {data.map(item => (
            <MenuItem value={item.id}>
              <NavItem key={item.id} {...item} {...props} />
            </MenuItem>
          ))}
        </Select>
      )}
      <Box className={classes.rightItem}>
        <Export
          handleExport={handleExport}
          type="white"
          style={{
            marginRight: '8px',
            width: '50%'
          }}
        />

        <Box
          className={classes.btnFilter}
          onClick={() =>
            setOpen(prev => ({
              ...prev,
              filter: !prev.filter
            }))
          }>
          <Box className={classes.filter}>
            <span className={classes.text}>
              {!open.filter && isFilter
                ? t('action.Applied filter')
                : t('action.Filter')}
            </span>
            {!open.filter ? <ArrowDropDownIcon /> : <ArrowRightIcon />}
          </Box>
        </Box>
      </Box>
    </ul>
  );
};

export default connect(
  null,
  dispatch => ({
    setLoading: status => dispatch(actSetLoading(status)),
    exportProductOrdersOfVendor: param =>
      dispatch(exportProductOrdersForVendor({ ...param }))
  })
)(Navbar);
