import { makeStyles } from '@material-ui/core';
import { primaryFont, secondaryFont } from '../../../shared/theme';

export const useStyle = makeStyles(theme => ({
  title: {
    borderBottom: `1px solid #E6E6E6`,
    padding: `0 40px 15px`
  },
  content: {
    padding: `20px 40px 0`
  },
  innerContent: {
    '& .MuiGrid-item': {
      marginBottom: 10,
      alignItems: 'center'
    }
  },
  subtitle: {
    marginTop: 30,
    marginBottom: 10,
    fontFamily: secondaryFont,
    fontWeight: 600,
    color: theme.palette.color.black1
  },
  wrappBtn: {
    margin: 20
  },
  mt15: {
    marginTop: 15
  },
  wrappUploadContainer: {
    padding: '20px 40px 10px'
  },

  colorCheckbox: {
    color: `${theme.palette.color.white1} !important`
  },
  colorChecked: {
    color: `${theme.palette.color.orange} !important`
  }
}));

export const useStyleUpload = makeStyles(theme => ({
  wrappUpload: {
    position: 'relative',
    width: '100%',
    height: 150,
    '&:hover svg': {
      fill: theme.palette.color.orange,
      transform: 'scale(1.1)'
    }
  },
  wrappIcon: {
    position: 'relative',
    width: '100%',
    height: '100%',
    border: `1px dashed #E6E6E6`,
    borderRadius: 10,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center'
    // '&:hover': {
    //   border: `1px dashed ${theme.palette.color.orange}`,
    // }
  },
  wrappImg: {
    width: '100%',
    height: '100%',
    borderRadius: 10
  },
  pictureUser: {
    width: '100%',
    height: '100%',
    borderRadius: 10,
    display: 'block'
  },
  cameraIcon: {
    position: 'absolute',
    bottom: -30,
    right: -25,
    width: 60,
    height: 60
  }
}));
