import { makeStyles } from '@material-ui/core';
import { primaryFont } from '../../shared/theme';

const useStyle = makeStyles(theme => ({
  root: {
    background: 'rgba(247, 247, 247, .5)',
    fontFamily: primaryFont
  },

  container: {
    margin: theme.spacing(2.4, 0),
    display: 'flex',
    flexDirection: 'column',
    boxShadow: '0px 8px 10px rgba(32, 71, 101, 0.02)'
  }
}));

export default useStyle;
