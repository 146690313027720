import React from 'react';
import TextField from '@material-ui/core/TextField';
import { useStyleTextFeild } from './style';

export function TextFieldCustom(props) {
  const { value, touch, error } = props;

  const mainClass = useStyleTextFeild();

  return (
    <TextField
      value={value || ''}
      error={Boolean(touch && error)}
      helperText={touch && error}
      className={mainClass.rootTextField}
      InputProps={{
        classes: {
          root: mainClass.root,
          notchedOutline: mainClass.notchedOutline,
          input: mainClass.input,
          focused: mainClass.notchedOutlineFocus
        },
        ...props.inputProps
      }}
      {...props}
    />
  );
}
