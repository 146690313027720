import { makeStyles } from '@material-ui/core';
import { primaryFont, secondaryFont } from '../../shared/theme';

const useStyle = makeStyles(theme => ({
  title: {
    color: theme.palette.color.black1
  },
  andriodImg: {
    transition: `all .35s`,
    '&:hover': {
      transform: `scale(1.1)`
    }
  },
  iosImg: {
    marginRight: theme.spacing(2),
    transition: `all .35s`,
    '&:hover': {
      transform: `scale(1.1)`
    }
  },
  downloadTitle: {
    fontSize: 16,
    marginBottom: theme.spacing(1),
    color: theme.palette.color.black1
  },
  loginSms: {
    border: 'none',
    backgroundColor: `${theme.palette.color.orange}`,
    color: theme.palette.color.white,
    marginTop: theme.spacing(4.8),
    '&:hover': {
      backgroundColor: '#ee9608',
      color: theme.palette.color.white
    }
  },
  loginWhatsapp: {
    border: `1px solid ${theme.palette.color.orange}`,
    backgroundColor: '#fff',
    color: theme.palette.color.orange,
    marginTop: theme.spacing(1.6),
    '&:hover': {
      background: '#00000009',
      border: `1px solid #ee9608`,
      color: '#ee9608'
    }
  },
  loginButton: {
    width: '100%',
    height: 48,
    borderRadius: 200,
    cursor: 'pointer',
    position: 'relative',
    outline: 0,
    fontSize: 16,
    fontFamily: secondaryFont,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center'
  },
  loginModal: {
    width: '100%',
    padding: theme.spacing(6.4, 8),
    textAlign: 'center',
    outline: 'none',
    alignItems: 'center',
    border: '1px solid #80808026',
    borderRadius: 6,
    display: 'flex',
    flexDirection: 'column',
    [theme.breakpoints.down('md')]: {
      padding: theme.spacing(6.4, 3)
    }
  },
  textDownloadApp: {
    fontSize: 16,
    fontFamily: primaryFont,
    fontWeight: 500
  },
  textRegion: {
    fontSize: 16,
    color: '#818597',
    marginLeft: '6px',
    fontWeight: 'bold',
    fontFamily: primaryFont
  },
  textChangeRegion: {
    fontSize: 16,
    fontWeight: 500,
    color: '#f5b041',
    cursor: 'pointer',
    fontFamily: primaryFont,
    textDecoration: 'underline'
  }
}));
export default useStyle;
