import React from 'react';
import FormControl from '@material-ui/core/FormControl';
import MenuItem from '@material-ui/core/MenuItem';
import TextField from '@material-ui/core/TextField';
import { useStyleSelectFeild } from './style';

export function SelectField(props) {
  const mainClass = useStyleSelectFeild();
  const rangesVal = props.ranges && props.ranges.length ? props.ranges : [];

  return (
    <FormControl>
      <TextField
        select
        value={props.value || ''}
        error={props.error || false}
        helperText={props.error || ''}
        {...props}>
        {rangesVal.map(option => (
          <MenuItem
            key={option.value}
            value={option.value}
            className={mainClass.menuItem}>
            {option.label}
          </MenuItem>
        ))}
      </TextField>
    </FormControl>
  );
}
