import { makeStyles } from '@material-ui/core';
import MarkerIcon from '../../assets/img/icons/marker.png';

const useStyle = makeStyles(theme => ({
  myMap: {
    position: 'relative'
  },
  popupContent: {
    width: 300,
    background: 'white',
    borderRadius: 15,
    padding: theme.spacing(2),
    position: 'relative',
    boxShadow: '0px 10px 20px rgba(0, 0, 0, 0.05)',
    zIndex: 100,
    '& h6': {
      color: theme.palette.color.black2,
      marginBottom: theme.spacing(1.6)
    },
    '& p': {
      color: theme.palette.color.black2,
      display: 'flex',
      lineHeight: '26px',
      marginBottom: theme.spacing(1.2)
    },
    '& p:last-child': {
      marginBottom: 0
    },
    '& span': {
      color: theme.palette.color.black2,
      marginLeft: theme.spacing(2),
      fontWeight: 300
    }
  },
  btnCancelIcon: {
    position: 'absolute',
    top: 10,
    right: 10,
    cursor: 'pointer'
  },

  marker: {
    width: 51,
    height: 73,
    background: `url(${MarkerIcon}) no-Repeat center`,
    backgroundSize: 'cover',

    '& img': {
      width: 51,
      height: 51,
      borderRadius: '50%',
      transform: 'scale(0.8)',
      boxShadow: '0px 6px 12px rgba(0, 0, 0, 0.1)'
    }
  },
  btnLocated: {
    position: 'absolute',
    bottom: '25px',
    right: 60,
    textTransform: 'capitalize',
    zIndex: 10
  },
  animation: {
    animation: 'bounce 0.5s'
  }
}));
export default useStyle;
