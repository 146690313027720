import React, { useRef, useState } from 'react';
import { cloneDeep } from 'lodash';
import { useTranslation } from 'react-i18next';

import Box from '@material-ui/core/Box';
import Container from '@material-ui/core/Container';
import TextFieldCustom from '../../../components/FieldWithoutFormik/TextField';
import BtnClearFilter from '../../../components/FieldWithoutFormik/ClearFilter';

import useStyle from './style';
import DateRangePickerUI from '../../../components/FieldWithoutFormik/DateRangePicker';
import ImportFile from '../../../components/ImportFile';
import Button from '../../../components/Button';

import { uploadFile } from '../../../shared/services/uploadFile';
import {
  messageErrorLanguage,
  toastMessage,
  TOAST_TYPE
} from '../../../utils/toastify-notify';
import { importStoreMenuCollection } from '../../../push-notification';
import { useHistory } from 'react-router';

const Filter = props => {
  const { filter, setFilter, loadImportProducts, statusMenu } = props;

  const [filterForm, setFilterForm] = useState(cloneDeep(filter));
  const subscribeImportStoreMenuCollectionRef = useRef(null);

  const timeout = useRef(null);

  const history = useHistory();

  const { t } = useTranslation();

  const classes = useStyle();

  const handleChangeInput = e => {
    const { name, value } = e?.target;

    setFilterForm(prevData => ({
      ...prevData,
      [name]: value
    }));

    timeout.current && clearTimeout(timeout.current);

    timeout.current = setTimeout(() => {
      setFilter(prevData => ({
        ...prevData,
        [name]: value
      }));
    }, 800);
  };

  const handleDateChange = ({ startDate, endDate }) => {
    setFilterForm(prevData => {
      return {
        ...prevData,
        from: (startDate && startDate.startOf('day')) || null,
        to: (endDate && endDate.endOf('day')) || null
      };
    });

    if (timeout.current) clearTimeout(timeout.current);

    timeout.current = setTimeout(() => {
      setFilter(prevData => ({
        ...prevData,
        from: (startDate && startDate.startOf('day')?.toISOString()) || null,
        to: (endDate && endDate.endOf('day')?.toISOString()) || null
      }));
    }, 1000);
  };

  const handleClearFilter = () => {
    setFilterForm({
      name: '',
      from: null,
      to: null,
      sortBy: '-uploadAt'
    });

    timeout.current && clearTimeout(timeout.current);

    timeout.current = setTimeout(() => {
      setFilter({
        name: '',
        from: null,
        to: null,
        sortBy: '-uploadAt'
      });
    }, 800);
  };

  const cleanListener = () => {
    subscribeImportStoreMenuCollectionRef.current &&
      subscribeImportStoreMenuCollectionRef.current();
  };

  const handleListenImportFile = key => {
    subscribeImportStoreMenuCollectionRef.current = importStoreMenuCollection
      .doc(key)
      .onSnapshot(async snapshot => {
        const data = snapshot.data();
        if (data?.success) {
          toastMessage(TOAST_TYPE.SUCCESS, 'Import file successfully.');
          cleanListener();
          await loadImportProducts();
          props.setLoading(false);
        }
      });
  };

  const handleUploadFile = async file => {
    try {
      let payload = {
        file,
        url: `imports/store-menu`,
        store: props.storeID
      };
      props.setLoading({ status: true });

      const res = await uploadFile(payload);
      toastMessage(TOAST_TYPE.INFO, res.message);

      handleListenImportFile(res.key);
    } catch (error) {
      props.setLoading({ status: false });
      messageErrorLanguage(error, t);
    }
  };

  const handleBack = () => {
    history.replace('/products');
  };

  return (
    <Box className={classes.header}>
      <Container maxWidth="xl">
        <Button
          onClick={handleBack}
          className={classes.btnBack}
          typeBtn="gray"
          autoFocus>
          Back
        </Button>
        <Box
          flexWrap="wrap"
          display="flex"
          justifyContent="space-between"
          alignItems="center">
          <Box className={classes.contentHeader}>
            <Box mr={2}>
              <TextFieldCustom
                variant="outlined"
                id="margin-none"
                name="name"
                width={135}
                value={filterForm.name}
                onChange={handleChangeInput}
                label={t('import-product.table.col.By')}
              />
            </Box>

            <Box mr={2}>
              <DateRangePickerUI
                width={250}
                endlabel={t('order.filter.label.To')}
                startlabel={t('order.filter.label.From')}
                height={46}
                maxDate={new Date()}
                startDate={filterForm.from}
                endDate={filterForm.to}
                onChange={handleDateChange}
              />
            </Box>

            <Box mr={2}>
              <BtnClearFilter handleClearFilter={handleClearFilter} />
            </Box>
          </Box>

          <ImportFile
            type="white"
            accept=".csv"
            handleChange={handleUploadFile}
            disabled={statusMenu === 'submitted'}
          />
        </Box>
      </Container>
    </Box>
  );
};

export default Filter;
