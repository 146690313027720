import samoaFlag from '../../../assets/img/flags/ws.png';
import vanuatuFlag from '../../../assets/img/flags/vu.png';

export const IMG_SUPPORTED_COUNTRIES = [
  {
    code: 'ws',
    name: 'Samoa',
    flag: samoaFlag,
    url: `https://${process.env.REACT_APP_URL}`
  },
  {
    code: 'vu',
    name: 'Vanuatu',
    flag: vanuatuFlag,
    url: `https://vu-${process.env.REACT_APP_URL}`
  }
];
