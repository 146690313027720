import { COUNTRIES } from '../shared/constant/const';
const APP_URL = process.env.REACT_APP_URL;

export const checkLocation = (country = 'ws') => {
  if (
    window.location?.host.includes('localhost')
  ) {
    if (country === 'ws') {
      return COUNTRIES[APP_URL];
    }

    return COUNTRIES['vu-' + APP_URL];
  }

  return COUNTRIES[(window.location?.host)];
};
