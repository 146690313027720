import { makeStyles } from '@material-ui/core';

export const useStyle = makeStyles(theme => ({
  root: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    borderRadius: '20px',
    padding: '0 15px',
    border: `1px solid ${theme.palette.color.orange}`
  },
  icon: {
    marginLeft: 15
  },
  inputInner: {
    padding: '11px 10px'
  }
}));
