export const queryUser = `
    _id
    phone
    name
    createdAt
    provider
`;

export const queryUserDetail = `
  _id
  phone
  name
  createdAt
  provider
  status
  avatar
  verified
`;

export const queryProfile = `
  _id
  avatar
  firstName
  lastName
  name
  gender
  phone
  email
  birthday
  address {
    alaNum
    village
    country
    location{
      coordinates
    }
  }
  store {
    _id
    timezone
    address {
      name
      village
      country
      alaNum
      distance
      location {
        type
        coordinates
      }
    }
    categories {
      _id
      name
    }
    phone
    website
    description
    totalOrder
    totalStarRating
    countRatings
    createdAt
    updatedAt
  }
`;

export const queryProfileLogin = `
  _id
  avatar
  firstName
  lastName
  name
  gender
  phone
  email
  birthday
  address {
    alaNum
    village
    country
    location{
      coordinates
    }
  }
`;

export const queryAddress = `
    alaNum
    village
    country
    location{
      coordinates
    }
    distance,
    name
`;

export const authQuery = `
token exp `;

export const queryUserInfo = `
  _id
  name
`;
