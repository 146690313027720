import makeStyles from '@material-ui/styles/makeStyles';
import { primaryFont } from '../../shared/theme';

export const useStyles = makeStyles(theme => ({
  btnExport: {
    height: 40,
    position: 'relative',
    width: 148,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    fontFamily: primaryFont,
    borderRadius: 200,
    fontSize: 14,
    color: theme.palette.color.white,
    cursor: 'pointer',
    background: theme.palette.primary.main,
    boxShadow:
      '0px 1px 5px 0px rgba(0,0,0,0.2), 0px 2px 2px 0px rgba(0,0,0,0.14), 0px 3px 1px -2px rgba(0,0,0,0.12)'
  },
  btnExportDefault: {
    background: theme.palette.color.white,
    color: theme.palette.color.black2,
    boxShadow: 'unset',
    border: `1px solid ${theme.palette.primary.main}`
  },
  arrowIcon: {
    display: 'flex',
    alignItems: 'center',
    position: 'absolute',
    right: 0
  },
  optionFile: {
    position: 'absolute',
    top: 47,
    zIndex: 999,
    color: '#2F2E2E',
    background: '#fff',
    width: '100%',
    borderRadius: '0 0 8px 8px',
    borderBottom: '1px solid #ccc'
  },
  file: {
    display: 'flex',
    alignItems: 'center',
    padding: theme.spacing(1.5, 1),
    '&:hover': {
      background: '#e38d03',
      color: theme.palette.color.white,
      transition: 'all 0.2s ease',
      '& img': {
        filter: 'brightness(0) invert(1)'
      }
    }
  },
  typePrimary: {
    background: theme.palette.primary.main,
    color: theme.palette.color.white,
    '& img': {
      filter: 'brightness(0) invert(1)'
    }
  },
  typeDefault: {
    background: theme.palette.color.white,
    color: theme.palette.color.black2,
    border: `1px solid ${theme.palette.primary.main}`,
    boxShadow: 'unset'
  }
}));
