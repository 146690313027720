import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';

// import clsx from 'clsx';
import Fade from '@material-ui/core/Fade';

import { getUserProfile } from 'maua-redux-core/selectors/user';

import useStyle from './style';

import messengerImg from '../../assets/img/icons/chat-comment.png';
import UserList from './UserList';
import ChatBox from './ChatBox';

import {
  actSetOrderIdMessenger,
  actSetShowMessengerFromOrderDetail
} from '../../store/actions/messengerChat';
import {
  getOrderIdMessenger,
  getShowMessengerFromOrderDetail
} from '../../store/selectors/messengerChat';

import { getCountNewMessages } from 'maua-redux-core/selectors/messageChannel';
import { countNewMessages } from 'maua-redux-core/actions/messageChannel';
import { isEmptyObject } from '../../utils/common';

const ChatComponent = props => {
  const mainClass = useStyle();
  const [screenShow, setScreenShow] = useState('userList');
  const [openChat, setOpenChat] = useState(false);
  const [channelID, setChannelID] = useState(null);
  const { showMessengerFromOrderDetail } = props;

  useEffect(() => {
    if (showMessengerFromOrderDetail) {
      handleShowMessengerFromOrderDetail();
    }
  }, [showMessengerFromOrderDetail]);

  useEffect(() => {
    let interval = null;

    if (isEmptyObject(props.userProfile)) {
      interval = setInterval(() => {
        props.countNewMessagesRequest();
      }, 5000);
    }
    return () => clearInterval(interval);
  }, [props.userProfile]);

  const handleShowMessengerFromOrderDetail = () => {
    if (screenShow === 'userList') {
      handleShowChatBox();
      !openChat && setOpenChat(true);
    }
  };

  const resetOrderId = () => {
    if (props.orderId) {
      props.setOrderIdMessengerRequest(null);
    }
  };

  const handleShowChatBox = () => {
    setScreenShow('chatbox');
  };

  const handleBackUserList = () => {
    setScreenShow('userList');
    resetOrderId();
    showMessengerFromOrderDetail &&
      props.actSetShowMessengerFromOrderDetailRequest(false);
  };

  const handleToggleChat = () => {
    setOpenChat(!openChat);
    setScreenShow('userList');
    resetOrderId();
    showMessengerFromOrderDetail &&
      props.actSetShowMessengerFromOrderDetailRequest(false);
  };

  const renderScreen = () => {
    if (screenShow === 'chatbox') {
      return (
        <ChatBox
          handleBackUserList={handleBackUserList}
          handleCloseChat={handleToggleChat}
          channelID={channelID}
        />
      );
    }
    return (
      <UserList
        handleShowChatBox={handleShowChatBox}
        handleCloseChat={handleToggleChat}
        setChannelID={setChannelID}
      />
    );
  };

  return (
    <div className={mainClass.root}>
      <div className={mainClass.inner}>
        <div className={mainClass.messengerControl}>
          <div className={mainClass.messenger} onClick={handleToggleChat}>
            <span className={mainClass.messengerCount}>
              {props.messengerUnread || 0}
            </span>
            <img src={messengerImg} alt="messenger" />
          </div>
        </div>
        {openChat ? (
          <div className={mainClass.boxChat}>
            <Fade in={openChat}>
              <div className={mainClass.contentBox}>{renderScreen()}</div>
            </Fade>
          </div>
        ) : null}
      </div>
    </div>
  );
};

const mapStateToprops = state => {
  return {
    userProfile: getUserProfile(state),
    orderId: getOrderIdMessenger(state),
    showMessengerFromOrderDetail: getShowMessengerFromOrderDetail(state),
    messengerUnread: getCountNewMessages(state)
  };
};
const mapDispatchToProps = dispatch => {
  return {
    setOrderIdMessengerRequest: value =>
      dispatch(actSetOrderIdMessenger(value)),
    actSetShowMessengerFromOrderDetailRequest: value =>
      dispatch(actSetShowMessengerFromOrderDetail(value)),
    countNewMessagesRequest: () => dispatch(countNewMessages())
  };
};

export default connect(
  mapStateToprops,
  mapDispatchToProps
)(ChatComponent);
