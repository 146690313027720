import { makeStyles } from '@material-ui/styles';
import { secondaryFont } from '../../shared/theme';

export const useStyles = makeStyles(theme => ({
  addImg: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    border: `1px dashed #E6E6E6`,
    borderRadius: '10px',
    height: 124,
    width: '100%',
    position: 'relative',
    '&:hover svg': {
      fill: theme.palette.color.orange,
      transform: 'scale(1.1)'
    },
    '&:hover p': {
      color: theme.palette.color.orange
    }
  },
  addIcon: {
    color: theme.palette.color.gray,
    fontSize: 30
  },
  addText: {
    marginTop: '10px',
    color: theme.palette.color.gray,
    fontFamily: secondaryFont
  },
  uploadInput: {
    position: 'absolute',
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
    opacity: 0,
    fontSize: '10px',
    zIndex: 1,
    cursor: 'pointer'
  },
  wrappUpload: {
    position: 'relative',
    width: 104,
    height: 104,
    overflow: 'hidden',
    '&:hover svg': {
      fill: theme.palette.color.orange,
      transform: 'scale(1.1)'
    }
  },
  wrappIcon: {
    position: 'relative',
    width: '100%',
    height: '100%',
    border: `1px dashed #E6E6E6`,
    borderRadius: '50%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center'
    // '&:hover': {
    //   border: `1px dashed ${theme.palette.color.orange}`,
    // }
  },
  wrappImg: {
    width: '100%',
    height: '100%',
    borderRadius: '50%'
  },
  pictureUser: {
    width: '100%',
    height: '100%',
    borderRadius: '50%',
    display: 'block'
  },
  cameraIcon: {
    position: 'absolute',
    bottom: -15,
    right: -15,
    width: 60,
    height: 60
  }
}));
