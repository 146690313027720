import React from 'react';
import { Typography } from '@material-ui/core';

import { useStyle } from './style';

import ModalCustom from '../../ModalCustom';

// import Button from '../../Button';
import { useTranslation } from 'react-i18next';

import StaffWatingImg from '../../../assets/img/StaffWating.png';
import './styleLoading.css';

const ModalStaffWaiting = props => {
  const mainClass = useStyle();
  const { t } = useTranslation();

  // const handleVerify = () => {
  //   props.handleClose();
  // };
  return (
    <div className={mainClass.root}>
      <ModalCustom {...props}>
        <Typography variant="h6" className={mainClass.title}>
          {t('mystore.editStore.title.Add Staff')}
        </Typography>
        <div className={mainClass.wrappImg}>
          <img
            src={StaffWatingImg}
            alt="Add Staff"
            className={mainClass.imgAddStaff}
          />
        </div>
        <Typography variant="h4" className={mainClass.titlePrimary}>
          {t('mystore.staff.popup.Waiting')}
        </Typography>
        <p className={mainClass.desc}>
          {t('mystore.staff.popup.Waiting content')}
        </p>
        <div className={mainClass.wrappLoading}>
          <div className="lds-ellipsis">
            <div></div>
            <div></div>
            <div></div>
            <div></div>
          </div>
        </div>
        {/*<div className={mainClass.wrappBtn}>
          <Button
            typeBtn="yellow"
            type="button"
            onClick={handleVerify}
            disabled={true}>
            Verify
          </Button>
          </div> */}
      </ModalCustom>
    </div>
  );
};

export default ModalStaffWaiting;
