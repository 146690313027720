import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';

// export function formatCurrency(amount) {
//   return `${(amount || 0).toLocaleString('en', {
//     minimumFractionDigits: 2,
//     maximumFractionDigits: 2
//   })}`;
// }

export const formatNumberFloat = value => {
  return value?.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,') || '';
};

export const checkNumber = value => {
  return /^-?[\d.]+(?:e-?\d+)?$/.test(value);
};

export const formatCurrency = (amount, currency, digits = 2) => {
  if (!checkNumber(amount)) {
    return '';
  }

  if (currency) {
    return (
      formatNumberFloat('$' + parseFloat(amount).toFixed(digits)) +
      ' ' +
      currency
    );
  }

  return formatNumberFloat(parseFloat(amount).toFixed(digits));
};

export const onClickOutside = (ref, handler) => {
  // eslint-disable-next-line react-hooks/rules-of-hooks
  useEffect(
    () => {
      const listener = event => {
        // Do nothing if clicking ref's element or descendent elements
        if (!ref.current || ref.current.contains(event.target)) {
          return;
        }

        handler(event);
      };

      document.addEventListener('mousedown', listener);
      document.addEventListener('touchstart', listener);

      return () => {
        document.removeEventListener('mousedown', listener);
        document.removeEventListener('touchstart', listener);
      };
    },
    // Add ref and handler to effect dependencies
    // It's worth noting that because passed in handler is a new ...
    // ... function on every render that will cause this effect ...
    // ... callback/cleanup to run every render. It's not a big deal ...
    // ... but to optimize you can wrap handler in useCallback before ...
    // ... passing it into this hook.
    [ref, handler]
  );
};

export const checkURL = url => {
  return String(url).match(/\.(jpeg|jpg|gif|png)$/) != null;
};

export const useQuery = () => {
  return new URLSearchParams(useLocation().search);
};
