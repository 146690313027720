import React, { useRef } from 'react';
import Select from 'react-select';
import TextField from '@material-ui/core/TextField';
import Box from '@material-ui/core/Box';
import { theme, primaryFont } from '../../shared/theme';
import useStyles from './style';
import { Typography } from '@material-ui/core';

const SelectFilter = props => {
  const { zIndex, label, error, fontSize, onBlur } = props;

  const classes = useStyles(props);

  const styles = {
    container: provided => ({
      ...provided,
      zIndex,
      width: props.width
    }),
    menu: styles => ({
      ...styles,
      boxShadow: '0px 2px 20px rgba(48, 83, 106, 0.09)',
      margin: theme.spacing(1, 0, 0, 0),
      background: '#fff',
      zIndex: 10
    }),
    option: (provided, state) => {
      return {
        ...provided,
        padding: 16,
        fontSize: fontSize || 14,
        fontWeight: 400,
        fontFamily: primaryFont,
        // background: state.isSelected ? theme.palette.color.gray3 : null,

        '&:hover': {
          background: theme.palette.color.gray
        }
      };
    },
    control: () => ({
      // none of react-select's styles are passed to <Control />
      display: 'flex',
      minHeight: 42,
      border: '1px solid #ccc',
      borderRadius: props.borderRadius ? props.borderRadius : 4,
      fontSize: fontSize || 14,
      fontWeight: 400,
      fontFamily: primaryFont,
      color: '#0000',
      outline: 'none',
      pointerEvents: props.disabled ? 'none' : 'auto',
      '&:focus-within': {
        border: `1px solid ${theme.palette.primary.main}`
      },
      '& .css-1okebmr-indicatorSeparator': {
        background: 'none'
      },
      '&:focused': {
        opacity: 0
      }
    }),

    singleValue: (provided, state) => {
      return {
        ...provided,
        '&:focused': {
          opacity: state.isFocused ? 0 : 1
        }
      };
    },

    multiValueRemove: (styles, { data }) => ({
      ...styles,
      color: theme.palette.color.gray3,
      ':hover': {
        backgroundColor: theme.palette.primary.main,
        color: 'white'
      }
    })
  };

  return (
    <>
      <Box onBlur={onBlur} className={classes.selectBox}>
        <Select className="react-select" styles={styles} {...props} />
        <Typography variant="subtitle2" className={classes.lableSelect}>
          {label}
        </Typography>
      </Box>
      {error && (
        <Typography className={classes.error} variant="subtitle2">
          {error}
        </Typography>
      )}
    </>
  );
};

SelectFilter.defaultProps = {
  width: '100%',
  isMulti: false,
  options: [],
  value: {},
  placeholder: '',
  label: 'Label',
  onBlur: () => {}
};
export default SelectFilter;
