import React from 'react';
import { Drawer as MUIDrawer, Typography, makeStyles } from '@material-ui/core';

const useStyles = makeStyles(theme => ({
  drawer: {
    height: 'calc(100% - 68px)',
    maxWidth: '90vw',
    top: 68
  },
  name: {
    padding: theme.spacing(1, 2),
    color: 'black',
    cursor: 'pointer',
    width: '100%',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    height: 48,
    display: 'flex',
    alignItems: 'center',
    '&:hover': {
      color: theme.palette.color.white,
      backgroundColor: theme.palette.color.orange
    }
  }
}));

const Drawer = props => {
  const classes = useStyles();
  const {
    open,
    data,
    handleCloseMenu,
    handleSelectItem,
    anchor,
    append
  } = props;

  return (
    <React.Fragment>
      <MUIDrawer
        anchor={anchor}
        open={open}
        classes={{
          paperAnchorLeft: classes.drawer
        }}
        onClose={handleCloseMenu}>
        {data.map((item, index) => {
          return (
            <div key={index}>
              <Typography
                variant="subtitle1"
                className={classes.name}
                onClick={() => handleSelectItem(item)}>
                {item.name}
              </Typography>
            </div>
          );
        })}
        {append}
      </MUIDrawer>
    </React.Fragment>
  );
};

Drawer.defaultProps = {
  open: false,
  data: [],
  anchor: 'left',
  append: null
};

export default Drawer;
