export const queryProduct = `
    _id
    name
    status
    countOrder
    description
    quantity
    price
    sku
    variants {
      _id
      name
      status
      isMultipleChoice
      mandatory
      options {
        _id
        name
        price
        status
        createdAt
        updatedAt
      }
      createdAt
      updatedAt
    }
    discountedPrice
    media
    createdAt
    discount {
      enable
      type
      value
      percent
    }
    category {
      _id
      slug
      name
      type
      banner
      featureImg
      parent {
        _id
        slug
        name
      }
      childrens {
        _id
        name
        parent {
          _id
          slug
          name
        }
      }
      status
      countChildrens
      createdAt
      updatedAt
    }
    draft { 
        _id
      name
      status
      description
      quantity
      price
      discountedPrice
      media
      status
      variants {
        _id
        name
        status
        isMultipleChoice
        mandatory
        options {
          _id
          name
          price
          status
          createdAt
          updatedAt
        }
        createdAt
        updatedAt
      }
      discount {
        enable
        type
        value
        percent
      }
      category {
        _id
      }
      createdAt
    }
`;

export const queryProductDraft = `
    _id
    name
    status
    sku
    variants {
      _id
      name
      status
      isMultipleChoice
      mandatory
      options {
        _id
        name
        status
        price
        createdAt
        updatedAt
      }
      createdAt
      updatedAt
    }
    description
    quantity
    price
    discountedPrice
    media
    createdAt
    published { ${queryProduct} }
    discount {
      enable
      type
      value
      percent
    }
    category {
      _id
      slug
      name
      type
      banner
      featureImg
      parent {
        _id
        slug
        name
      }
      childrens {
        _id
        name
        parent {
          _id
          slug
          name
        }
      }
      status
      countChildrens
      createdAt
      updatedAt
    }
`;

export const queryMenus = `
    _id
    slug
    name
    type
    store {
      _id
      name
    }
    products {
      ${queryProduct}
    }
    banner
    featureImg
    parent {
      _id
      name
    }
    status
    createdAt
    updatedAt
`;

export const queryMenuDraft = `
    status
    reasonRejected
    categories{
      _id
      slug
      name
      type
      store {
        _id
        name
      }
      products { ${queryProduct} }
      productDrafts { ${queryProductDraft} }
      banner
      featureImg
      parent {
        _id
        name
      }
      status
      countChildrens
      createdAt
      updatedAt
    }
`;
