import axios from 'axios';
import { checkLocation } from '../../utils/checkLocation';
import { ensureArray } from '../../utils/common';
import { getCredentials } from './token';

// const endpoint = process.env.REACT_APP_API || 'https://api-maua.efisoft.com.vn';

const endpoint = checkLocation().api;

export const uploadFile = (payload, uploadProgress) => {
  let formData = new FormData();

  for (let key in payload) {
    if (key === 'url') {
      continue;
    }
    if (key === 'files') {
      ensureArray(payload.files).forEach(file => {
        formData.append(file.label, file.file);
      }); // multiple file
    } else formData.append(key, payload[key]); //single file
  }

  return new Promise((resolve, reject) => {
    const credentials = getCredentials() || {};
    axios
      .post(`${endpoint}/api/${payload.url}`, formData, {
        onUploadProgress:
          typeof uploadProgress === 'function'
            ? uploadProgress
            : progress => {
                // let percent = Math.floor(
                //   (progress.loaded / progress.total) * 100
                // );
                // console.log(percent);
              },
        headers: {
          'Content-Type': 'multipart/form-data',
          Authorization: `Bearer ${credentials.token}`
        }
      })
      .then(({ data }) => {
        resolve(data);
      })
      .catch(({ response }) => {
        reject(response?.data);
      });
  });
};
