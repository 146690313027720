import React from 'react';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { initMessageChannel } from 'maua-redux-core/actions/messageChannel';
import { getOrderIdMessenger } from '../../store/selectors/messengerChat';
import messengerImg from '../../assets/img/icons/messenger-blue.png';
import { queryMessengerChannel } from '../../store/query/messenger';
import avatarImg from '../../assets/img/upload/avatar.png';
import { returenAddress } from '../../utils/common';
import {
  actSetShowMessengerFromOrderDetail,
  actSetOrderIdMessenger
} from '../../store/actions/messengerChat';
import useStyle from './style';
import { OrderListConsumer } from '../../pages/OrdersPage/OrderContext';
import { noop } from 'lodash';

const FormCustomer = React.memo(props => {
  const mainClass = useStyle();
  const { customer, address, productOrderID } = props.dataCustomer || {};
  const { name, avatar } = customer || {};
  const { orderID } = props;
  const { t } = useTranslation();

  const handleShowChat = () => {
    props.initMessageChannel(productOrderID);
    props.actSetOrderIdMessengerRequest(productOrderID);
    props.actSetShowMessengerFromOrderDetailRequest(true);
  };
  return (
    <div className={mainClass.root}>
      <h5 className={mainClass.title}>
        <span className={mainClass.txtTitle}>
          {t('order.customer.title.From Customer')}
        </span>

        <span className={mainClass.messengerControl}>
          <OrderListConsumer>
            {context => {
              let openDetail = false;
              let setOpenDetail = noop;
              if (context) {
                openDetail = context.openDetail;
                setOpenDetail = context.setOpenDetail;
              }

              return (
                <span
                  className={mainClass.messenger}
                  onClick={() => {
                    handleShowChat();
                    openDetail && setOpenDetail(false);
                  }}>
                  <img src={messengerImg} alt="messenger" />
                </span>
              );
            }}
          </OrderListConsumer>
        </span>
      </h5>
      <div className={mainClass.customerInfo}>
        <div className={mainClass.customerAvatar}>
          <img
            src={avatar || avatarImg}
            alt="avatar"
            className={mainClass.customerPicture}
          />
        </div>
        <div className={mainClass.customerContent}>
          <p className={mainClass.customerName}>{name}</p>
          <p className={mainClass.desc}>
            {(address && returenAddress(address)) || ''}
          </p>
          <p className={mainClass.desc}>{customer?.phone || ''}</p>
        </div>
        <span className={mainClass.distance}>
          {(address && address.distance) || ''}
        </span>
      </div>
    </div>
  );
});

const mapStateToProps = state => {
  return {
    orderID: getOrderIdMessenger(state)
  };
};

const mapDispatchToProps = dispatch => {
  return {
    initMessageChannel: id =>
      dispatch(initMessageChannel({ productOrder: id }, queryMessengerChannel)),
    actSetShowMessengerFromOrderDetailRequest: value =>
      dispatch(actSetShowMessengerFromOrderDetail(value)),
    actSetOrderIdMessengerRequest: orderId =>
      dispatch(actSetOrderIdMessenger(orderId))
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(FormCustomer);
