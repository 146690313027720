import React from 'react';
import TextField from '@material-ui/core/TextField';
import useStyleTextFeild from './style';

const TextFieldCustom = props => {
  const { value, touch, error } = props;

  const mainClass = useStyleTextFeild(props);

  return (
    <TextField
      {...props}
      value={value || ''}
      error={Boolean(touch && error)}
      helperText={touch && error}
      className={mainClass.rootTextField}
      InputProps={{
        classes: {
          root: mainClass.root,
          notchedOutline: mainClass.notchedOutline,
          input: mainClass.input,
          focused: mainClass.notchedOutlineFocus
        }
      }}
      {...props}
    />
  );
};

TextFieldCustom.defaultProps = {
  width: '100%'
};

export default TextFieldCustom;
