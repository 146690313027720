import { makeStyles } from '@material-ui/core';
const useStyle = makeStyles(theme => ({
  container: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    width: '100%',
    minHeight: '100vh',
    background: theme.palette.color.white
  },
  inner: {
    width: 544,
    // height: 360,
    borderRadius: 4,
    boxShadow: `0px 4px 8px rgba(0, 0, 0, 0.08)`
  }
}));
export default useStyle;
