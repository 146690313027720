import { mystoresSlice } from '../reducers/mystores';

export const actToggleNeedreloadMyStore = state => {
  return mystoresSlice.actions.setToggleNeedReloadMyStore({
    state
  });
};

export const actToggleNeedFetchVendorGetStore = state => {
  return mystoresSlice.actions.setToggleNeedFetchVendorGetStore({
    state
  });
};

export const actSetDataEmployeeAddStaff = state => {
  return mystoresSlice.actions.setDataEmployeeAddStaff({
    state
  });
};
