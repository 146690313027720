import { makeStyles } from '@material-ui/core';
import { primaryFont, secondaryFont } from '../../../shared/theme';

const useStyle = makeStyles(theme => ({
  wrapp: {
    marginTop: 20
  },
  identity: {
    paddingLeft: 30,
    paddingRight: 30,
    color: theme.palette.color.orange,
    fontFamily: primaryFont,
    fontWeight: 600
  },
  deliveryStatus: {
    margin: '20px 30px 0'
  },
  deliveryStatusWrapp: {
    position: 'relative'
  },
  innerStatus: {
    display: 'inline-block',
    border: '1px solid #3A8E07',
    borderRadius: '20px',
    fontSize: 0
  },
  deliveryThumb: {
    display: 'inline-block',
    verticalAlign: 'middle',
    padding: '7px 18px',
    flexDirection: 'row',
    alignItems: 'center',
    backgroundColor: theme.palette.color.green1,
    borderTopLeftRadius: '20px',
    borderBottomLeftRadius: '20px',
    fontSize: 0
  },
  imgThumb: {
    display: 'inline-block',
    verticalAlign: 'middle'
  },
  textInfo: {
    display: 'inline-block',
    marginLeft: 10,
    verticalAlign: 'middle',
    fontSize: 16,
    fontFamily: primaryFont,
    color: theme.palette.color.black2
  },
  deliveryType: {
    display: 'inline-block',
    verticalAlign: 'middle',
    fontSize: 16,
    fontFamily: primaryFont,
    color: theme.palette.color.black2,
    padding: '7px 12px 7px 18px'
  },
  deliveryFinal: {
    position: 'relative',
    display: 'inline-block',
    verticalAlign: 'middle',
    fontSize: 14,
    fontFamily: primaryFont,
    fontWeight: 'bold'
  },
  complete: {
    color: theme.palette.color.green,
    '&:before': {
      content: '" "',
      position: 'absolute',
      top: '50%',
      left: 0,
      width: 4,
      height: 4,
      borderRadius: '50%',
      backgroundColor: theme.palette.color.green,
      transform: 'translateY(-50%)'
    }
  },
  fail: {
    padding: '7px 45px 7px 10px',
    color: theme.palette.color.red,
    cursor: 'pointer',
    '&:before': {
      content: '" "',
      position: 'absolute',
      top: '50%',
      left: 0,
      width: 4,
      height: 4,
      borderRadius: '50%',
      backgroundColor: theme.palette.color.red,
      transform: 'translateY(-50%)'
    }
  },
  angleRight: {
    display: 'inline-block',
    width: 7,
    position: 'absolute',
    top: '50%',
    right: 15,
    transform: 'translateY(-50%)'
  },
  angleRightImg: {
    display: 'inline-block',
    width: 7
  },
  tooltipWarpp: {
    position: 'absolute',
    top: 50,
    left: 0,
    width: '100%',
    backgroundColor: theme.palette.color.white,
    boxShadow: `5px 5px 20px rgba(0, 0, 0, 0.12)`,
    borderRadius: 10,
    padding: '19px 30px 19px 20px'
  },
  iconClose: {
    position: 'absolute',
    top: 10,
    right: 10,
    color: theme.palette.color.gray,
    cursor: 'pointer',
    transition: 'all .35s',
    fontSize: 20,
    borderRadius: '50%',
    '&:hover': {
      backgroundColor: '#ccc',
      color: '#fff'
    }
  },
  failTitle: {
    fontSize: 16,
    fontWeight: 'bold',
    fontFamily: secondaryFont,
    color: theme.palette.color.black1
  },
  failReason: {
    marginTop: 5
  },
  failReasonDesc: {
    marginLeft: 3,
    color: theme.palette.color.red
  }
}));
export default useStyle;
