import React from 'react';

import useStyle from './style';
import WelcomeModal from '../../components/WelcomeModal';

const CompleteProfile = props => {
  const classess = useStyle();

  return (
    <div className={classess.container}>
      <div className={classess.inner}>
        <WelcomeModal />
      </div>
    </div>
  );
};

export default CompleteProfile;
