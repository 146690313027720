import { makeStyles } from '@material-ui/styles';

export const useStyles = makeStyles(theme => ({
  modalWrapp: {},
  rootDialogTitle: {
    position: 'relative',
    margin: 0,
    padding: '24px 30px 15px'
  },
  closeButton: {
    position: 'absolute',
    right: '10px',
    top: '10px',
    color: theme.palette.color.gray1
  },
  title: {
    marginBottom: 0,
    fontSize: '24px',
    fontFamily: 'Muli',
    fontWeight: 700,
    color: theme.palette.color.black,
    paddingLeft: 36,
    paddingRight: 36
  },
  rootDialogContent: {
    margin: 0,
    padding: '0 66px 80px',
    [theme.breakpoints.down('md')]: {
      padding: '0 30px 80px'
    }
  },
  description: {
    color: theme.palette.color.black2
  }
}));
