import { createSlice } from 'redux-starter-kit';
import { removeToLocalStorage } from '../../utils/common';

let initialState = {
  loading: { status: false, percent: 0 }
};
export let loadingSlice = createSlice({
  initialState: initialState,
  slice: 'loading',
  reducers: {
    setLoading(state, action) {
      state.loading = action.payload;
    }
  },
  extraReducers: {
    'user/logout': () => {
      removeToLocalStorage('credentials');
    }
  }
});
export default loadingSlice.reducer;
