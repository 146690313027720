import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { debounce } from 'lodash';
import { useTranslation } from 'react-i18next';
import { Container, Box } from '@material-ui/core';
import { getUserProfile } from 'maua-redux-core/selectors/user';
import {
  getStoreMenuDraft,
  cancelMenuDraft,
  submitMenuDraft
} from 'maua-redux-core/actions/menuDraft';
import { fetchStore, getStoreMenu } from 'maua-redux-core/actions/store';
import { fetchCategories } from 'maua-redux-core/actions/category';
import { fetchProducts } from 'maua-redux-core/actions/product';
import { actSetToggleReloadProducts } from '../../store/actions/products';
import AddProductModal from '../../components/Products/AddProductModal';
import { getNeedReloadProducts } from '../../store/selectors/products';
import { toastMessage, TOAST_TYPE } from '../../utils/toastify-notify';
import {
  actToggleModalAddBusiness,
  actToggleModalAddProduct
} from '../../store/actions/modal';
import {
  getOpenModalAddBusiness,
  getOpenModalAddProduct
} from '../../store/selectors/modal';
import { queryMenus, queryMenuDraft } from '../../store/query/menu';
import { queryCategories } from '../../store/query/categories';
import { queryStoreInMystore } from '../../store/query/store';
import { uploadFile } from '../../shared/services/uploadFile';
import { actSetLoading } from '../../store/actions/loading';
import { queryProducts } from '../../store/query/products';
import AlertDialog from '../../components/AlertDialog';
import ExportFile from '../../components/ExportFile';
import ImportFile from '../../components/ImportFile';
import callAPI from '../../shared/services/callAPI';
import { downloadFile, fullTextSearchRegex } from '../../utils/common';
import { useQuery } from '../../utils/function';
import Button from '../../components/Button';
import Search from '../../components/Search';
import RightColumn from './RightColumn';
import LeftColumn from './LeftColumn';
import { useStyle, useStyleModal } from './style';
import AddBusinessModal from '../../components/Mystore/AddBusinessModal';

const ProductPage = props => {
  const { t } = useTranslation();
  const classes = useStyle();
  const classModal = useStyleModal();
  const storeID = props.profile?.store?._id;
  const [menusDefault, setMenusDefault] = useState([]);
  const [menus, setMenus] = useState([]);
  const [statusMenu, setStatusMenu] = useState(null);
  const [keyword, setKeyword] = useState('');
  const [scrollToIndex, setScrollToIndex] = useState(0);
  const [categories, setCategories] = useState({
    optionMain: [],
    optionSub: []
  });
  const [modal, setModal] = useState({
    open: false,
    title: ''
  });

  const sortData = data =>
    data?.sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt));

  // load menu
  const loadMenu = async () => {
    try {
      props.setLoading(true);

      const queryStoreMenus = `
      query getStoreMenu($store: String!) {
        getStoreMenu(store: $store) {
        ${queryMenus}
        }
      }
    `;

      const queryStoreMenusDraft = `
      query getStoreMenuDraft($store: String!) {
        getStoreMenuDraft(store: $store) {
        ${queryMenuDraft}
        }
      }
    `;

      const variables = {
        store: storeID
      };

      const [{ getStoreMenu }, { getStoreMenuDraft }] = await Promise.all([
        callAPI(queryStoreMenus, variables),
        callAPI(queryStoreMenusDraft, variables)
      ]);

      // name, description, status, media, countOrder, quantity, price ,discount
      const menusDraft = getStoreMenuDraft?.categories?.map(category => ({
        ...category,
        products: [
          ...category.products,
          ...category.productDrafts.map(productDraft => ({
            _id: productDraft._id,
            of: 'ProductDraft',
            isDraft: true,
            isReject: getStoreMenuDraft.status === 'rejected' ? true : false,
            status: productDraft.published?.status || productDraft.status,
            category: productDraft.category || productDraft.published?.category,
            name: productDraft.name || productDraft.published?.name,
            media: productDraft.media || productDraft.published?.media,
            description:
              productDraft.description || productDraft.published?.description,

            price: productDraft.price || productDraft.published?.price || 0,
            discountedPrice:
              productDraft.discountedPrice ||
              productDraft.published?.discountedPrice ||
              0,
            sku: productDraft.sku || productDraft.published?.sku,
            quantity:
              productDraft.quantity || productDraft.published?.quantity || 0,
            countOrder: productDraft.published?.countOrder || 0,
            variants:
              productDraft.variants?.length > 0
                ? productDraft.variants
                : productDraft.published?.variants || [],
            discount: productDraft.discount ||
              productDraft.published?.discount || {
                enable: false,
                percent: 0,
                type: '%',
                value: 0
              },
            createdAt:
              productDraft.published?.createdAt || productDraft.createdAt
          }))
        ]
      }));

      const menusSort =
        sortData(menusDraft)?.map(menuDraft => ({
          ...menuDraft,
          products: sortData(menuDraft.products)
        })) ||
        sortData(getStoreMenu)?.map(menu => ({
          ...menu,
          products: sortData(menu.products)
        }));

      const menus = menusSort
        .reduce((result, item) => {
          return [...result, { ...item, isCategory: true }, item.products];
        }, [])
        .flat();

      setMenus(() => menus);
      setMenusDefault(() => menus);

      // set status of menu
      if (getStoreMenuDraft) {
        const isProductDraft = getStoreMenuDraft?.categories.some(
          ({ productDrafts }) => productDrafts.some(({ _id }) => !!_id)
        );

        if (!isProductDraft) {
          return setStatusMenu(() => null);
        }

        return setStatusMenu(() => getStoreMenuDraft.status);
      }
    } catch (error) {
      toastMessage(TOAST_TYPE.ERROR, error.message);
    } finally {
      props.setLoading(false);
    }
  };

  // call api load main categories
  const loadCategories = async () => {
    try {
      const { store } = await props.fetchStore({ _id: storeID, version: 'v3' });
      setCategories(c => ({
        ...c,
        optionMain: store.categories.map(c => ({
          label: JSON.parse(c.name)['United Kingdom'],
          value: c._id
        }))
      }));
    } catch (error) {
      toastMessage(TOAST_TYPE.ERROR, error.message);
    }
  };

  // load categories, products
  useEffect(() => {
    if (storeID) {
      Promise.all([loadMenu(), loadCategories()]);
    }
  }, [storeID, props.location]);

  // map option sub categories
  useEffect(() => {
    let optionSub = menus.reduce((result, item) => {
      if (item.isCategory) {
        return [...result, { label: item.name, value: item._id }];
      }

      return [...result];
    }, []);

    setCategories(c => ({ ...c, optionSub }));
  }, [menus]);

  // handle request submit menu, cancel menu
  const handleMenu = async () => {
    if (modal.type === 'cancel') {
      try {
        await props.cancelMenuDraft();
        setStatusMenu(() => null);
        toastMessage(
          TOAST_TYPE.SUCCESS,
          `${t('toastMessage.success.content.Menu request has been cancelled')}`
        );
      } catch (error) {
        toastMessage(TOAST_TYPE.ERROR, error.message);
      }
    }
    if (modal.type === 'sendMenu') {
      try {
        await props.submitMenuDraft();
        toastMessage(
          TOAST_TYPE.SUCCESS,
          `${t('toastMessage.success.content.Menu request has been submitted')}`
        );
      } catch (error) {
        toastMessage(TOAST_TYPE.ERROR, error.message);
      }
    }

    setModal(() => ({ open: false, data: {}, title: '' }));
    return loadMenu();
  };

  const onCloseModalAddBusiness = () => {
    props.toggleModalAddBusiness(false);
  };

  return (
    <Box className={classes.root}>
      <Box className={classes.header}>
        <Container maxWidth="xl">
          <Box className={classes.contentHeader}>
            <Search
              className={classes.search}
              setKeyword={setKeyword}
              placeholder={`${t('product.search.placeholder.Search')} ...`}
            />
            <Box className={classes.btnHeaderContainer}>
              <Button
                disabled={!statusMenu}
                className={classes.btnHeader}
                onClick={() =>
                  setModal(() => ({
                    open: true,
                    type: 'cancel',
                    title: `${t(
                      'product.text.This action will revert all changes and this action cannot be undone'
                    )}.`
                  }))
                }>
                {t('action.Reset')}
              </Button>
              <Button
                disabled={statusMenu !== 'new'}
                typeBtn="yellow"
                className={classes.btnHeader}
                onClick={() =>
                  setModal(() => ({
                    open: true,
                    type: 'sendMenu',
                    title: `${t(
                      'product.text.This action will submit the menu for review and this action cannot be undone'
                    )}.`
                  }))
                }>
                {t('action.Submit menu')}
              </Button>
            </Box>
          </Box>
        </Container>
      </Box>

      <Container maxWidth="xl">
        <Box className={classes.container}>
          <LeftColumn
            menus={menus}
            storeID={storeID}
            setMenus={setMenus}
            setScrollToIndex={setScrollToIndex}
            optionMain={categories.optionMain}
          />

          <RightColumn
            menus={menus}
            keyword={keyword}
            storeID={storeID}
            setMenus={setMenus}
            loadMenu={loadMenu}
            statusMenu={statusMenu}
            menusDefault={menusDefault}
            scrollToIndex={scrollToIndex}
            setLoading={props.setLoading}
            optionSub={categories.optionSub}
          />
        </Box>
      </Container>

      {modal.open && (
        <AlertDialog
          open={modal.open}
          handleClose={() => setModal(() => ({ open: false, title: '' }))}
          handleConfirm={handleMenu}
          textTileDialog={t(modal.title)}
        />
      )}

      {props.openModalAddBusiness ? (
        <AddBusinessModal
          openModel={props.openModalAddBusiness}
          handleClose={onCloseModalAddBusiness}
          classOutSide={classModal}
        />
      ) : null}
    </Box>
  );
};

export default connect(
  state => ({
    profile: getUserProfile(state),
    openModalAddBusiness: getOpenModalAddBusiness(state)
  }),
  dispatch => ({
    fetchStore: payload =>
      dispatch(fetchStore(payload, '_id categories { _id name }')),
    setLoading: status => dispatch(actSetLoading({ status })),
    submitMenuDraft: () => dispatch(submitMenuDraft()),
    cancelMenuDraft: () => dispatch(cancelMenuDraft()),
    toggleModalAddBusiness: value => dispatch(actToggleModalAddBusiness(value))
  })
)(ProductPage);
