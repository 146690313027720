import React from 'react';
import { connect } from 'react-redux';
import { Redirect, Route } from 'react-router-dom';
import { isEmpty } from 'lodash';
import { slice } from 'maua-redux-core/reducers/user';
import { getUserProfile } from 'maua-redux-core/selectors/user';
import { destroyCredentials, getCredentials } from '../shared/services/token';

const PrivateRoute = ({ path, component: Component, profile }) => {
  return (
    <Route
      path={path}
      render={routeProps => {
        if (getCredentials()) {
          return <Component {...routeProps} />;
        }
        if (isEmpty(profile)) {
          slice.actions.logout();
          destroyCredentials();
        }
        return <Redirect to="/" />;
      }}
    />
  );
};

export default connect(
  state => ({ profile: getUserProfile(state) }),
  null
)(PrivateRoute);
