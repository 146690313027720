import React from 'react';
import Slider from 'react-slick';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import { useStyle } from './style';

const ArrowNext = props => (
  <ChevronRightIcon
    color="primary"
    className={props.className}
    onClick={props.onClick}
  />
);
const ArrowPre = props => (
  <ChevronLeftIcon
    color="primary"
    className={props.className}
    onClick={props.onClick}
  />
);

const SliderUI = props => {
  const classes = useStyle();
  let settings = {
    dots: false,
    infinite: false,
    speed: 500,
    nextArrow: <ArrowNext />,
    prevArrow: <ArrowPre />,
    ...props.settings
  };

  return (
    <Slider {...settings} className={classes.root}>
      {props.children}
    </Slider>
  );
};

export default SliderUI;
