import { makeStyles } from '@material-ui/core';
import { secondaryFont, primaryFont } from '../../../shared/theme';

const useStyle = makeStyles(theme => ({
  header: {
    backgroundColor: theme.palette.color.white,
    borderBottom: `1px solid #F2F2F2`,
    boxShadow: `-4px 0px 10px rgba(0, 0, 0, 0.07)`
  },

  contentHeader: {
    display: 'flex',
    alignItems: 'center',
    flexWrap: 'wrap',
    padding: theme.spacing(1.6, 0),
    justifyContent: 'space-between'
  },

  btnExport: {
    background: theme.palette.color.white,
    color: theme.palette.color.black2,
    border: `1px solid ${theme.palette.primary.main}`,
    boxShadow: 'unset',
    height: 40,
    position: 'relative',
    width: 130,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    fontFamily: primaryFont,
    borderRadius: 200,
    fontSize: 14,
    cursor: 'pointer'
  }
}));

export default useStyle;
