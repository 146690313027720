import { makeStyles } from '@material-ui/core';
import { primaryFont } from '../../shared/theme';

const useStyle = makeStyles(theme => ({
  root: {
    position: 'fixed',
    bottom: 20,
    right: 20,
    zIndex: 1098,
    [theme.breakpoints.down('md')]: {
      bottom: 64
    }
  },
  inner: {
    position: 'relative'
  },
  messengerControl: {
    display: 'flex',
    justifyContent: 'flex-end'
  },
  messenger: {
    position: 'relative',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: '#3A8E07',
    boxShadow: `0px 5px 15px rgba(65, 158, 44, 0.31)`,
    width: 60,
    height: 60,
    borderRadius: '50%',
    cursor: 'pointer',
    transition: 'all .35s',
    '&:hover': {
      transform: 'scale(1.1)'
    }
  },
  messengerCount: {
    position: 'absolute',
    top: -5,
    right: -10,
    width: 'auto',
    minWidth: 25,
    height: 25,
    padding: 3,
    background: '#F74040',
    border: `3px solid #FDFCF9`,
    borderRadius: '50%',
    fontSize: 12,
    color: '#fff',
    fontFamily: primaryFont,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center'
  },
  boxChat: {
    position: 'relative',
    backgroundColor: theme.palette.color.white,
    boxShadow: `0px -4px 6px rgba(236, 236, 236, 0.24)`,
    border: `1px solid #C4C4C4`,
    borderRadius: 10,
    padding: `24px 0`,
    marginTop: 15
    // '&:before': {
    //   content: "' '",
    //   position: 'absolute',
    // }
  },
  contentBox: {
    width: 400,
    height: 451
  }
}));

export default useStyle;
