import React from 'react';
import { Tooltip, Switch } from '@material-ui/core';

const SwitchCustom = props => {
  return (
    <Tooltip title={props.title || ''}>
      <Switch
        checked={props.checkedVal}
        onChange={props.handleChange}
        color="primary"
      />
    </Tooltip>
  );
};

export default SwitchCustom;
