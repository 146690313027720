import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import Fade from '@material-ui/core/Fade';
import CloseIcon from '@material-ui/icons/Close';
import clsx from 'clsx';
import { useTranslation } from 'react-i18next';
import { Typography } from '@material-ui/core';

import useStyle from './style';
import ProductService from '../../Orders/ProductService';
import TotalPrice from '../../Orders/TotalPrice';
import FormCustomer from '../../FormCustomer';
import PaymentInfo from '../../Orders/PaymentInfo';
import handSaveImg from '../../../assets/img/icons/hand-save.svg';
import angleRightImg from '../../../assets/img/icons/angle-right.svg';
import { getLoadingStatus } from '../../../store/selectors/loading';
import { getOpenDetailOrderItem } from '../../../store/selectors/orders';
import { actSetLoading } from '../../../store/actions/loading';
import BackToDashBoard from '../../BackToDashBoard';

const SelfDelidetailOrderFail = props => {
  const classess = useStyle();
  const [open, setOpen] = useState(false);
  const { t } = useTranslation();

  const {
    _id,
    code,
    amount,
    address,
    products,
    customer,
    discount,
    delivery,
    promotion,
    serviceFee,
    deliveryFee,
    paymentMethod,
    productsPrice
  } = props.data;

  useEffect(() => {
    if (props.loading?.status && props.showDetailOrderItem) {
      props.setLoading({ status: false });
    }
  }, [props.loading?.status]);

  useEffect(() => {
    return () => {
      setOpen(false);
    };
  }, [props.data]);

  const dataTotalPrice = {
    amount,
    discount,
    promotion,
    serviceFee,
    deliveryFee,
    productsPrice
  };

  const dataCustomer = {
    customer,
    address,
    productOrderID: _id
  };

  const dataPaymentMethod = {
    paymentMethod
  };

  return (
    <div className={classess.wrapp}>
      {/* <BackToDashBoard handleBackDashboard={props.handleshowDashboards} /> */}
      <Typography variant="h4" className={classess.identity}>
        #{code}
      </Typography>
      <div className={classess.deliveryStatus}>
        <div className={classess.deliveryStatusWrapp}>
          <div className={classess.innerStatus}>
            <span className={classess.deliveryThumb}>
              <img
                src={handSaveImg}
                alt="Delivery to me"
                className={classess.imgThumb}
              />
              <span className={classess.textInfo}>
                {t('order.delivery.title.Delivery to me')}
              </span>
            </span>
            <span className={classess.deliveryType}>
              {t('order.delivery.label.Self delivery')}
            </span>
            <span
              className={clsx(classess.deliveryFinal, classess.fail)}
              onClick={() => setOpen(true)}>
              {t('order.delivery.process.status.Fail')}
              <span className={classess.angleRight}>
                <img
                  src={angleRightImg}
                  alt="angle Right"
                  className={classess.angleRightImg}
                />
              </span>
            </span>
          </div>
          <Fade in={open} className={classess.tooltipWarpp}>
            <div className={classess.tooltipInner}>
              <CloseIcon
                className={classess.iconClose}
                onClick={() => setOpen(false)}
              />
              <span className={classess.failTitle}>
                {t('order.delivery.process.title.Delivery Status')}
              </span>
              <Typography variant="body1" className={classess.failReason}>
                {t('order.delivery.process.fail.Reason')}:
                <span className={classess.failReasonDesc}>
                  {(delivery &&
                    delivery.length &&
                    delivery[delivery.length - 1].reason) ||
                    ''}
                </span>
              </Typography>
            </div>
          </Fade>
        </div>
      </div>
      <div className={classess.main}>
        <ProductService products={products} />
        <TotalPrice typePromote="white" dataTotalPrice={dataTotalPrice} />
        <FormCustomer dataCustomer={dataCustomer} />
        <PaymentInfo order={props.data} />
      </div>
    </div>
  );
};

const mapStateToProps = state => {
  return {
    loading: getLoadingStatus(state),
    showDetailOrderItem: getOpenDetailOrderItem(state)
  };
};

const mapDispatchToProps = dispatch => {
  return {
    setLoading: status => dispatch(actSetLoading(status))
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(SelfDelidetailOrderFail);
