import React, { useState, useEffect } from 'react';
import Box from '@material-ui/core/Box';
import addImg from '../../../assets/img/icons/addImg.png';
import sendMessegener from '../../../assets/img/icons/sendMessenger.png';
import { uploadImg } from '../../../shared/services/upload';
import useStyles from './style';
import { sendMessage } from 'maua-redux-core/actions/message';
import { actSetLoading } from '../../../store/actions/loading';
import { querySendMessage } from '../../../store/query/messenger';
import { toastMessage, TOAST_TYPE } from '../../../utils/toastify-notify';
import { connect } from 'react-redux';
import { useTranslation } from 'react-i18next';

function SendMessage(props) {
  const {
    setLoading,
    datalength,
    setDataMessages,
    setLoadingPicture,
    setScrollPosition,
    sendMessegenerRequest
  } = props;

  const { t } = useTranslation();
  const [txtChat, setTxtChat] = useState('');
  const classes = useStyles();
  const handleChangeTextChat = e => {
    setTxtChat(e.target.value);
  };

  const handleKeyDown = e => {
    if (Boolean(e.target.value) && e.key === 'Enter') {
      handleSendMessages();
    }
  };

  const uploadProgress = progress => {
    let percent = Math.floor((progress.loaded / progress.total) * 100);
    percent !== props.loading.percent && setLoading({ status: true, percent });
  };

  const uploadImage = (idProduct, filesData) => {
    setLoadingPicture(true);
    const imgData = {
      _id: idProduct,
      model: 'Message',
      field: 'media',
      files: filesData
    };
    setLoadingPicture(false);

    uploadImg(imgData, uploadProgress)
      .then(res => {
        setDataMessages(preData => ({
          ...preData,
          docs: [...preData?.docs, res]
        }));
        setScrollPosition(datalength);
      })
      .catch(error => {
        setLoadingPicture(false);

        toastMessage(TOAST_TYPE.ERROR, error?.response?.data?.message);
      })
      .finally(() => {
        setLoading({ status: false, percent: 0 });
      });
  };

  const handleChangeImage = el => {
    let inputEl = el.target;
    const fileData = inputEl.files;
    // const reader = new FileReader();
    const filesUpload = [];
    let fileSizeValidate = true;
    if (fileData) {
      Object.keys(fileData)
        .map(key => fileData[key])
        .forEach(file => {
          // setFiles(prevState => [...prevState, file]);
          let fileSize = file.size / 1024 / 1024; // in MB
          if (fileSize >= 1) {
            fileSizeValidate = false;
            toastMessage(
              TOAST_TYPE.ERROR,
              t('toastMessage.error.content.Files Upload size greate than 1MB')
            );
            return;
          } else {
            filesUpload.push(file);
          }

          // reader.readAsDataURL(file);
        });

      fileSizeValidate && uploadImage(props.channelID, filesUpload);
    }
    el.target.value = '';
  };

  const handleSendMessages = async () => {
    if (txtChat) {
      const params = {
        channel: props.channelID,
        content: txtChat
      };
      try {
        const { sendMessage } = await sendMessegenerRequest(params);
        if (sendMessage) {
          setTxtChat('');

          setDataMessages(preData => ({
            ...preData,
            docs: [...preData?.docs, sendMessage]
          }));

          setScrollPosition(datalength);
        }
      } catch (error) {
        toastMessage(TOAST_TYPE.ERROR, error.message);
      }
    }
  };
  return (
    <Box className={classes.footerChat}>
      <div className={classes.wrappInputChat}>
        <input
          type="text"
          value={txtChat}
          className={classes.inputChat}
          onChange={handleChangeTextChat}
          onKeyPress={handleKeyDown}
        />
        <div className={classes.groupControll}>
          <span className={classes.btnUpload}>
            <img src={addImg} alt="uploadPicture" />
            <input
              accept="image/*"
              className={classes.viewInputGallery}
              type="file"
              onChange={handleChangeImage}
              multiple="multiple"
            />
          </span>
          <button
            type="button"
            className={classes.btnSendMess}
            onClick={handleSendMessages}>
            <img src={sendMessegener} alt="send Messenger" />
          </button>
        </div>
      </div>
    </Box>
  );
}

const mapDispatchToProps = dispatch => {
  return {
    sendMessegenerRequest: params =>
      dispatch(sendMessage(params, querySendMessage))
  };
};

export default connect(
  null,
  mapDispatchToProps
)(SendMessage);
