import { makeStyles } from '@material-ui/core';
import { secondaryFont, primaryFont } from '../../shared/theme';

export const useStyle = makeStyles(theme => ({
  root: {
    background: 'rgba(247, 247, 247, .5)',
    height: 'calc(100vh - 68px)',
    fontFamily: primaryFont
  },
  threedots: {
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis'
  },
  flex: {
    display: 'flex',
    alignItems: 'center'
  },
  productHeader: {
    display: 'flex',
    alignItems: 'center',
    [theme.breakpoints.down('sm')]: {
      width: 700,
      overflowX: 'scroll',
      overflowY: 'hidden'
    }
  },
  header: {
    backgroundColor: theme.palette.color.white,
    borderBottom: `1px solid #F2F2F2`,
    boxShadow: `-4px 0px 10px rgba(0, 0, 0, 0.07)`
  },
  contentHeader: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    padding: theme.spacing(1.6, 0),
    flexWrap: 'wrap'
  },
  btnHeaderContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    [theme.breakpoints.down('md')]: {
      width: '100%',
      marginTop: 8
    }
  },
  btnHeader: {
    width: 148,
    height: 40,
    marginLeft: theme.spacing(0.8),
    [theme.breakpoints.down('md')]: {
      width: '40%',
      margin: 0
    },
    [theme.breakpoints.only('xs')]: {
      width: '45%'
    }
  },
  btnAdd: {
    width: 148,
    height: 40,
    [theme.breakpoints.down('md')]: {
      width: '100%',
      marginTop: 8
    },
    [theme.breakpoints.only('xs')]: {
      width: '100%',
      marginTop: 8
    }
  },
  addBtnGroup: {
    display: 'flex',
    [theme.breakpoints.down('md')]: {
      justifyContent: 'space-between',
      width: '100%'
    }
  },
  btnImport: {
    background: theme.palette.color.white,
    color: theme.palette.color.black2,
    border: `1px solid ${theme.palette.primary.main}`
  },
  container: {
    margin: theme.spacing(2.4, 0),
    height: 'calc(100vh - 192px)',
    display: 'flex',
    boxShadow: '0px 8px 10px rgba(32, 71, 101, 0.02)',
    [theme.breakpoints.down('md')]: {
      flexDirection: 'column',
      overflowX: 'scroll'
    }
  },
  subHeader: {
    fontSize: 18,
    padding: theme.spacing(1.3, 2.4),
    borderBottom: '1px solid #E7EEF3'
  },
  search: {
    width: 300,
    [theme.breakpoints.down('md')]: {
      width: '100%'
    }
  },

  // left column
  leftColumn: {
    width: 300,
    minWidth: 300,
    overflow: 'hidden',
    position: 'relative',
    color: '#565554',
    background: theme.palette.color.white,
    borderRight: '1px solid #E7EEF3',
    [theme.breakpoints.down('md')]: {
      width: '100%'
    }
  },

  leftColumContent: {
    height: 'calc(100vh - 496px)'
  },

  list: {
    outline: 'unset',
    [theme.breakpoints.down('sm')]: {
      width: '700px !important',
      overflowX: 'scroll !important',
      overflowY: 'hidden !important',
      '& .ReactVirtualized__Grid__innerScrollContainer': {
        maxWidth: '100% !important'
      }
    }
  },

  addMenu: {
    width: 300,
    padding: theme.spacing(2.4),
    backgroundColor: theme.palette.color.white,
    [theme.breakpoints.down('md')]: {
      width: '100%'
    },
    [theme.breakpoints.only('xs')]: {
      paddingLeft: 8,
      paddingRight: 24
    }
  },
  textField: {
    margin: theme.spacing(1.6, 0),
    width: '100%',
    '& .MuiInputBase-root.MuiOutlinedInput-root.MuiInputBase-formControl': {
      height: 40
    },
    '& .MuiInputLabel-outlined': {
      position: 'absolute',
      top: -7
    }
  },
  btnMenu: {
    width: 120,
    height: 40,
    // borderRadius: 4,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center'
  },
  subCategoryItem: {
    transition: 'all 0.5s ease',
    position: 'relative',
    cursor: 'pointer',
    display: 'flex',
    alignItems: 'center',
    padding: theme.spacing(1.2, 2.4),
    '&:hover': {
      background: '#f4f8fb',
      '& #boxBtnSubCategory': {
        width: 'auto',
        zIndex: 99,
        height: 42,
        padding: theme.spacing(0, 1.2)
      }
    }
  },
  boxActions: {
    position: 'absolute',
    transition: 'all 0.25s ease',
    top: 0,
    zIndex: 2,
    right: 0,
    overflow: 'hidden',
    width: 0,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    background:
      'linear-gradient(to right, rgba(244, 248, 251, 0) 0%, rgba(244, 248, 251, 0.75) 15%, #f4f8fb 30%, #f4f8fb 100%)',
    '& svg': {
      cursor: 'pointer'
    }
  },

  // right column
  rightColumn: {
    width: '100%',
    background: theme.palette.color.white
  },
  rightHeader: {
    padding: theme.spacing(1.45, 2.4),
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    borderBottom: '1px solid #E7EEF3',
    flexWrap: 'wrap'
  },
  productsContent: {
    height: 'calc(100vh - 312px)'
  },
  productRow: {
    position: 'relative',
    '&:hover': {
      background: '#f4f8fb',
      '& #btnProdcut': {
        width: 200,
        zIndex: 99,
        height: 48,
        paddingRight: '1%'
      }
    },
    [theme.breakpoints.down('sm')]: {
      width: '700px !important',
      overflowX: 'scroll',
      overflowY: 'hidden',
      '&::-webkit-scrollbar': {
        display: 'none'
      }
    }
  },
  row: {
    height: 48,
    color: theme.palette.color.black2,
    borderBottom: '1px solid #E7EEF3',
    cursor: 'pointer',
    position: 'relative',

    '&:hover': {
      '& svg': {
        opacity: 1
      }
    },

    '&:nth-child(odd)': {
      width: '15%',
      display: 'flex',
      alignItems: 'center',
      padding: theme.spacing(1),
      justifyContent: 'space-between'
    },
    '&:nth-child(even)': {
      width: '15%',
      display: 'flex',
      alignItems: 'center',
      padding: theme.spacing(1),
      justifyContent: 'space-between'
    },
    '&:nth-of-type(1)': {
      width: '25%',
      justifyContent: 'space-between',
      paddingLeft: theme.spacing(2.4)
    },
    '&:nth-of-type(5)': {
      justifyContent: 'center'
    },
    '&:nth-of-type(6)': {
      justifyContent: 'flex-end',
      paddingRight: '1%'
    }
  },

  sortArrows: {
    margin: theme.spacing(0, 1),
    opacity: 0
  },

  statusItem: {
    cursor: 'pointer',
    minWidth: theme.spacing(14),
    height: theme.spacing(5),
    textAlign: 'right',
    width: 'fit-content',
    padding: theme.spacing(1, 2),
    '&:hover': {
      background: '#bbd8f7c7'
    }
  },

  activeItem: {
    background: '#268effc7',
    color: theme.palette.color.white
  },

  statusFilterDropdown: {
    position: 'absolute',
    boxShadow: '0px 4px 8px rgb(130 126 90 / 10%)',
    right: theme.spacing(3.2),
    padding: theme.spacing(0.5, 0),
    zIndex: 999,
    background: theme.palette.color.white,
    top: '100%',
    borderRadius: theme.spacing(0.5)
  },

  filterIcon: {
    opacity: '0.7 !important',
    marginRight: theme.spacing(2),
    borderRadius: theme.spacing(0.5),
    margin: theme.spacing(0, 1),

    '&:hover': {
      border: `1px solid ${theme.palette.color.gray1}`,
      opacity: '1 !important'
    }
  },

  openFilterStatus: {
    border: `1px solid ${theme.palette.primary.main}`,
    opacity: '1 !important'
  },

  status: {
    fontSize: 12,
    height: 24,
    width: 80,
    padding: theme.spacing(1.2),
    marginRight: 4,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    borderRadius: 200,
    color: theme.palette.color.white,
    textTransform: 'capitalize'
  },
  modalContent: {
    padding: theme.spacing(2.4, 0, 0)
  },
  exportDrop: {
    position: 'absolute'
  }
}));

export const useStyleModal = makeStyles(theme => ({
  modalContent: {
    padding: `25px 0 0`
  }
}));
