import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { Typography } from '@material-ui/core';

import useStyle from './style';
import { SelectField } from '../../FormFeild/SelectFeild';

import moment from 'moment';
import BarChart from '../../Barchart';

import {
  countProductOrders,
  // fetchStatisticAmount,
  earningsVendor
} from 'maua-redux-core/actions/productOrder';

import {
  queryCountProductOrders,
  queryStatisticAmount
} from '../../../store/query/orders';
import { dashboardByStore } from 'maua-redux-core/actions/combine';
import { getUserProfile } from 'maua-redux-core/selectors/user';
import { toastMessage, TOAST_TYPE } from '../../../utils/toastify-notify';
import {
  isEmptyObject,
  getWeekNumber,
  financial,
  getTimeZone
} from '../../../utils/common';
import DonutChart from '../../DonutChart';
import StackedBar from '../../StackedBar';

function daysInThisMonth() {
  var now = new Date();
  return new Date(now.getFullYear(), now.getMonth() + 1, 0).getDate();
}

const OverView = props => {
  const classess = useStyle();
  const { t } = useTranslation();
  const dataRange = [
    { value: 'today', label: 'Today' },
    { value: 'weekly', label: 'Weekly' },
    { value: 'monthly', label: 'Monthly' },
    { value: 'yearly', label: 'Yearly' }
  ];
  const dataRangeRevenue = [
    { value: 'weekly', label: 'Weekly' },
    { value: 'monthly', label: 'Monthly' },
    { value: 'yearly', label: 'Yearly' }
  ];

  const [selectOverview, setSelectOverview] = useState('today');
  const [selectRevenue, setSelectRevenue] = useState('weekly');
  const [dataOverViewSelfpickup, setDataOverViewSelfpickup] = useState({});
  const [dataOverViewService, setDataOverViewservice] = useState({});

  const [dataRevenue, setDataRevenue] = useState([]);
  const { profile } = props;
  const hasToken = profile && isEmptyObject(profile);

  let today = new Date();
  let todayMoment = moment(today);

  useEffect(() => {
    let params = {
      startTime: '',
      endTime: ''
    };

    switch (selectOverview) {
      case 'today':
        params = {
          startTime: todayMoment._d.toISOString(),
          endTime: todayMoment._d.toISOString()
        };
        break;
      case 'weekly': {
        params = {
          startTime: todayMoment.day('Sunday')._d.toISOString(),
          endTime: todayMoment.day('Saturday')._d.toISOString()
        };
        break;
      }

      case 'monthly':
        params = {
          startTime: todayMoment.startOf('month')._d.toISOString(),
          endTime: todayMoment.endOf('month')._d.toISOString()
        };
        break;
      case 'yearly':
        params = {
          startTime: todayMoment.startOf('year')._d.toISOString(),
          endTime: todayMoment.endOf('year')._d.toISOString()
        };
        break;
      default:
        params = {
          startTime: todayMoment._d.toISOString(),
          endTime: todayMoment._d.toISOString()
        };
        break;
    }

    if (hasToken) {
      let paramsSelfPickup = { ...params, deliveryMethod: 'self-pickup' };
      let paramsService = { ...params, deliveryMethod: 'service' };
      handleOverviewCaller(paramsSelfPickup);
      handleOverviewCaller(paramsService);
    }
  }, [selectOverview, hasToken]);

  useEffect(() => {
    let params = {};
    // const monthly = today.getMonth() + 1;
    // const year = today.getFullYear();
    // const weekly = getWeekNumber(today);
    // const currentDay = moment();
    const from_date = moment()
      .startOf('week')
      .valueOf();
    const to_date = moment()
      .endOf('week')
      .valueOf();
    const from_month = moment()
      .startOf('month')
      .valueOf();
    const to_month = moment()
      .endOf('month')
      .valueOf();
    const from_year = moment()
      .startOf('year')
      .valueOf();
    const to_year = moment()
      .endOf('year')
      .valueOf();

    switch (selectRevenue) {
      case 'weekly':
        params = {
          period: 'weekly',
          from: from_date,
          to: to_date,
          timezone: getTimeZone()
        };
        break;
      case 'monthly':
        params = {
          period: 'monthly',
          from: from_month,
          to: to_month,
          timezone: getTimeZone()
        };
        break;
      case 'yearly':
        params = {
          period: 'yearly',
          from: from_year,
          to: to_year,
          timezone: getTimeZone()
        };
        break;
      default:
        params = {
          period: 'weekly',
          from: from_date,
          to: to_date,
          timezone: getTimeZone()
        };
        break;
    }
    if (hasToken) {
      handleFetchRevenue(params);
    }
  }, [selectRevenue, hasToken]);

  const handleFetchRevenue = async params => {
    try {
      await props.dashboardByStore(params).then(({ dashboardByStore }) => {
        const data = dashboardByStore.sort((a, b) => a.column - b.column);
        setDataRevenue(data);
      });
    } catch (error) {
      toastMessage(TOAST_TYPE.ERROR, error.message);
    }
  };
  const handleOverviewCaller = async params => {
    try {
      await props
        .onFetchCountProductOrders(params)
        .then(({ countProductOrders }) => {
          if (params.deliveryMethod === 'service') {
            setDataOverViewservice(countProductOrders);
          } else {
            setDataOverViewSelfpickup(countProductOrders);
          }
        });
    } catch (error) {
      toastMessage(TOAST_TYPE.ERROR, error.message);
    }
  };

  const handleChangeOverview = e => {
    const val = e.target.value;
    setSelectOverview(val);
  };

  const handleChangeRevenue = e => {
    const val = e.target.value;
    setSelectRevenue(val);
  };

  // define for donutchart of self pickup and service
  const colorsDonutChart = [
    '#128B17',
    '#8E048E',
    '#2C5BC5',
    '#E38D03',
    '#BE0000'
  ];

  const labelsDonutChart = [
    'New', //t('orders.labelChart.New')
    'In progress', //t('order.tab.title.In Progress')
    'Ready', //t('orders.labelChart.Ready')
    'Completed', //t('order.tab.title.Completed')
    'Failed' //t('orders.labelChart.Failed')
  ];

  const dataDonutChartSelfpickup =
    Object.keys(dataOverViewSelfpickup || {}).map(
      key => dataOverViewSelfpickup[key]
    ) || [];

  const dataDonutChartService =
    Object.keys(dataOverViewService || {}).map(
      key => dataOverViewService[key]
    ) || [];

  const showDonutChartSelfpickup = dataDonutChartSelfpickup.reduce((a, b) => {
    return a + b;
  }, 0);

  const showDonutChartService = dataDonutChartService.reduce((a, b) => {
    return a + b;
  }, 0);

  const {
    completed: completedSelf,
    failed: failedSelf,
    inProgress: inProgressSelf,
    new: newSelf,
    ready: readySelf
  } = dataOverViewSelfpickup;

  const {
    completed: completedService,
    failed: failedService,
    inProgress: inProgressService,
    new: newService,
    ready: readyService
  } = dataOverViewService;

  const seriesDonutChartSelfpickup = [
    newSelf,
    inProgressSelf,
    readySelf,
    completedSelf,
    failedSelf
  ];

  const seriesDonutChartService = [
    newService,
    inProgressService,
    readyService,
    completedService,
    failedService
  ];

  //define for barchart revenue

  let xaxisRouteChart = [];
  let flatDataRevenue = [];

  switch (selectRevenue) {
    case 'weekly':
      xaxisRouteChart = ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'];
      let DataChart = [
        {
          name: 'Subtotal',
          data: Array(7).fill(0)
        },
        {
          name: 'Self delivery',
          data: Array(7).fill(0)
        }
      ];

      dataRevenue.forEach(({ column, value }) => {
        DataChart[0]['data'][column - 1] = financial(value.subTotal);
        DataChart[1]['data'][column - 1] = financial(value.deliveryFee);
      });
      flatDataRevenue = DataChart;
      break;
    case 'monthly':
      const daysTotal = daysInThisMonth();
      for (var i = 1; i <= daysTotal; i++) {
        xaxisRouteChart.push(i.toString());
      }
      let DataChartMonth = [
        {
          name: 'Subtotal',
          data: Array(daysTotal).fill(0)
        },
        {
          name: 'Self delivery',
          data: Array(daysTotal).fill(0)
        }
      ];

      dataRevenue.forEach(({ column, value }) => {
        DataChartMonth[0]['data'][column - 1] = financial(value.subTotal);
        DataChartMonth[1]['data'][column - 1] = financial(value.deliveryFee);
      });

      flatDataRevenue = DataChartMonth;
      break;
    case 'yearly':
      for (let i = 1; i <= 12; i++) {
        xaxisRouteChart.push(i.toString());
      }
      let DataChartYear = [
        {
          name: 'Subtotal',
          data: Array(12).fill(0)
        },
        {
          name: 'Self delivery',
          data: Array(12).fill(0)
        }
      ];
      dataRevenue.forEach(({ column, value }) => {
        DataChartYear[0]['data'][column - 1] = financial(value.subTotal);
        DataChartYear[1]['data'][column - 1] = financial(value.deliveryFee);
      });

      flatDataRevenue = DataChartYear;
      break;
    default:
      xaxisRouteChart = ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'];
      let DataChartW = [
        {
          name: 'Subtotal',
          data: Array(7).fill(0)
        },
        {
          name: 'Self delivery',
          data: Array(7).fill(0)
        }
      ];
      dataRevenue.forEach(({ column, value }) => {
        DataChartW[0]['data'][column - 1] = financial(value.subTotal);
        DataChartW[1]['data'][column - 1] = financial(value.deliveryFee);
      });
      flatDataRevenue = DataChartW;
      break;
  }

  const sumTotal = data => {
    return (
      data.reduce((a, b) => {
        return parseInt(a) + parseInt(b);
      }, 0) || 0
    );
  };

  const totalRevenue =
    sumTotal(flatDataRevenue[0].data) + sumTotal(flatDataRevenue[1].data);

  const styleBarChatSpecial =
    selectRevenue === 'monthly'
      ? classess.barChatSpecialWrapp
      : classess.barChatDefaultWrapp;

  return (
    <div className={classess.wrapp}>
      <div className={classess.wrappRevenue}>
        <div className={classess.headBottom}>
          <div className={classess.headBottomLeft}>
            <Typography variant="h4" className={classess.title}>
              Revenue
            </Typography>
            <SelectField
              ranges={dataRangeRevenue}
              value={selectRevenue}
              onChange={handleChangeRevenue}
              variant="outlined"
              className={classess.selectCustom}
              InputProps={{
                classes: {
                  notchedOutline: classess.notchedOutline,
                  input: classess.inputSelect
                }
              }}
            />
          </div>
          <div className={classess.headBottomRight}>
            <span className={classess.revenueUnit}>SAT$</span>
            <span className={classess.revenueNum}>{`${financial(
              parseFloat(totalRevenue)
            ) || 0}`}</span>
          </div>
        </div>
        <div className={classess.wrappBarchart}>
          {dataRevenue.length > 0 ? (
            <div className={styleBarChatSpecial}>
              <StackedBar data={flatDataRevenue} xaxis={xaxisRouteChart} />
              {/* <BarChart
                series={seriesRouteChart}
                xaxis={xaxisRouteChart}
                title=""
                subTitle=""
              /> */}
            </div>
          ) : (
            <div className={classess.defaultBarChart}>Data is empty</div>
          )}
          {/*<BarChart
            series={seriesRouteChart}
            xaxis={xaxisRouteChart}
            colors={colorsRouteChart}
            title=""
            subTitle=""
          /> */}
        </div>
      </div>
      <div className={classess.head}>
        <Typography variant="h4" className={classess.title}>
          Overview
        </Typography>
        <SelectField
          ranges={dataRange}
          value={selectOverview}
          variant="outlined"
          className={classess.selectCustom}
          onChange={handleChangeOverview}
          InputProps={{
            classes: {
              notchedOutline: classess.notchedOutline,
              input: classess.inputSelect
            }
          }}
        />
      </div>
      <div className={classess.orderInfo}>
        <div className={classess.wrappChart}>
          <h3 className={classess.titleDonutChart}>
            {t('order.delivery.status.Pick-up at store')}
          </h3>
          {showDonutChartSelfpickup > 0 ? (
            <DonutChart
              colors={colorsDonutChart}
              labels={labelsDonutChart}
              series={seriesDonutChartSelfpickup}
              className="selfPickup-donurt"
            />
          ) : (
            <div className={classess.defaultDonutChart}>Data is empty</div>
          )}
        </div>
        <div>
          <h3 className={classess.titleDonutChart}>
            {t('order.delivery.title.Delivery to me')}
          </h3>
          {showDonutChartService > 0 ? (
            <DonutChart
              colors={colorsDonutChart}
              labels={labelsDonutChart}
              series={seriesDonutChartService}
              className="delivery-donurt"
            />
          ) : (
            <div className={classess.defaultDonutChart}>Data is empty</div>
          )}
        </div>
        {/* <div className={classess.orderItem}>
          <span className={classess.quantityNumber}>500</span>
          <Typography variant="subtitle1" className={classess.descOrderType}>
            Ordered Received
          </Typography>
        </div>
        <div className={classess.orderItem}>
          <span className={classess.quantityNumber}>128</span>
          <Typography variant="subtitle1" className={classess.descOrderType}>
            Order Delivered
          </Typography>
        </div>
        <div className={classess.orderItem}>
          <span className={classess.quantityNumber}>02</span>
          <Typography variant="subtitle1" className={classess.descOrderType}>
            Pending Payments
          </Typography>
        </div>
        <div className={classess.orderItem}>
          <span className={classess.quantityNumber}>{0}</span>
          <Typography variant="subtitle1" className={classess.descOrderType}>
            Cancelled Orders
          </Typography>
        </div> */}
      </div>
    </div>
  );
};

const mapStateToProps = (state, ownProps) => {
  return {
    profile: getUserProfile(state)
  };
};

const mapDispatchToProps = dispatch => {
  return {
    onFetchCountProductOrders: params =>
      dispatch(countProductOrders(params, queryCountProductOrders)),
    // onFetchStatisticAmount: params =>
    //   dispatch(earningsVendor(params, queryStatisticAmount)),
    dashboardByStore: params =>
      dispatch(
        dashboardByStore(params, 'column value { subTotal deliveryFee }')
      )
  };
};
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(OverView);
