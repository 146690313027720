export const querystore = `
    _id
    name
    address{
      village
      country
      alaNum
    }
    categories{
      _id
      name
    }
    description
`;

export const queryStoreInMystore = `
  _id
  name
  phone
  media
  description
  address {
    name
    village
    country
    alaNum
    location {
      coordinates
    }
  }
  totalStarRating
  countRatings
  owner {
    _id
    lastName
    firstName
    phone
    gender
    birthday
    roles
    avatar
  }
  employees {
    _id
    lastName
    firstName
    phone
    gender
    birthday
    roles
    avatar
  }
  categories{
    _id
    name
    banner
  }
  website
  timezone
  openTimes {
    weekday
    timeIntervals{
      start{
        hour
        min
      }
      end{
        hour
        min
      }
    }
    enable
  }
  media
`;

export const queryVendorGetStores = `
  _id
  name
  media
  phone
`;

export const queryVendorChooseStore = `
  _id
`;

export const queryEmployee = `
  _id
  firstName,
  lastName
  phone
  verified
  avatar
  store {
    _id
  }
  roles
`;
