import { makeStyles } from '@material-ui/core';
import { secondaryFont, primaryFont } from '../../../shared/theme';

const useStyle = makeStyles(theme => ({
  root: {
    padding: theme.spacing(1.6, 3, 1)
  },
  title: {
    margin: 0,
    fontSize: 18,
    fontFamily: secondaryFont,
    fontWeight: 'bold',
    color: theme.palette.color.black1
  },
  paymentInfo: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    margin: theme.spacing(1, 0, 0),
    fontSize: 18,
    lineHeight: '26px',
    fontFamily: primaryFont
  },
  paymentName: {
    color: theme.palette.color.gray1
  },
  paymentUnit: {
    color: theme.palette.color.black2
  }
}));

export default useStyle;
