import { makeStyles } from '@material-ui/core';

const useStyle = makeStyles(theme => ({
  root: {},
  title: {
    paddingBottom: 12
  },
  img: {
    display: 'flex',
    justifyContent: 'flex-end'
  },
  content: {
    margin: theme.spacing(3, 9, 0)
  },
  wrappBtn: {
    marginTop: 35
  }
}));

export default useStyle;
