import React from 'react';
import clsx from 'clsx';
import { useTranslation } from 'react-i18next';
import { Typography } from '@material-ui/core';
import handSaveImg from '../../../assets/img/icons/hand-save.svg';
import handPriceImg from '../../../assets/img/icons/hand-price.svg';
import { dateTimeHHmm, returenAddress } from '../../../utils/common';
import { CurrencySymbol } from '../../../components/CurrencySymbol';
import { DELIVERY_TYPE } from '../../../shared/constant/const';
import { theme } from '../../../shared/theme';
import useStyle from './style';

const OrderItem = ({ item, onClick, itemActive }) => {
  const classess = useStyle();
  const { t } = useTranslation();
  const {
    code,
    // createdAt,
    updatedAt,
    customer,
    phone,
    address,
    paymentMethod,
    deliveryMethod,
    products = [],
    amount,
    subTotal,
    _id
  } = item;
  const classDeliver =
    DELIVERY_TYPE.SELFPICKUP.type === deliveryMethod
      ? classess.pickup
      : classess.delivery;
  const textDeliver =
    DELIVERY_TYPE.SELFPICKUP.type === deliveryMethod
      ? t('order.delivery.status.Pick-up at store')
      : t('order.delivery.title.Delivery to me');

  const walletColor = {
    vodafone: 'rgb(255 254 240)',
    digicel: theme.palette.color.orange1,
    'e-voucher': theme.palette.color.blue1,
    cash: 'rgba(0, 0, 0, 0.05)'
  };

  const classActive = itemActive === _id ? classess.active : '';

  return (
    <div className={clsx(classess.wrapp, classActive)} onClick={onClick}>
      <div className={classess.header}>
        <Typography variant="h6" className={classess.identity}>
          #{code}
        </Typography>
        <Typography className={classess.time}>
          {dateTimeHHmm(updatedAt)}
        </Typography>
      </div>
      <div className={classess.customerInfos}>
        <div className={classess.customerInfo}>
          <span className={classess.customerLabel}>
            {t('order.label.CUSTOMER')}
          </span>
          <span className={classess.customerName}>
            {customer && customer.name ? customer.name : ''}
          </span>
        </div>
        <div className={classess.customerInfo}>
          <span className={classess.customerLabel}>
            {t('order.label.phone')}
          </span>
          <span className={classess.customerName}>+{phone}</span>
        </div>
        <div className={classess.customerInfo}>
          <span className={classess.customerLabel}>
            {t('order.label.address')}
          </span>
          <span className={classess.customerName}>
            {' '}
            {(address && returenAddress(address)) || ''}
          </span>
        </div>
      </div>
      <div className={classess.footer}>
        <div className={classess.footerLeft}>
          <span className={clsx(classDeliver, classess.infoHr)}>
            <img src={handSaveImg} alt={textDeliver} />
            <span className={classess.textInfo}>{textDeliver}</span>
          </span>
          <span
            className={classess.infoHr}
            style={{ backgroundColor: walletColor[paymentMethod] }}>
            <img src={handPriceImg} alt="wallet" />
            <span
              className={classess.textInfo}
              style={{ textTransform: 'capitalize' }}>
              {paymentMethod}
            </span>
          </span>
        </div>
        <div className={classess.footerRight}>
          <span className={classess.numberItem}>
            {products.length} {t('order.product.title.items')}
          </span>
          <span className={classess.numberCost}>
            <CurrencySymbol>{parseFloat(subTotal)}</CurrencySymbol>
          </span>
        </div>
      </div>
    </div>
  );
};

export default React.memo(OrderItem);
