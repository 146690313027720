import React from 'react';

import CardHeader from '@material-ui/core/CardHeader';
import Skeleton from '@material-ui/lab/Skeleton';

const UserItemSkeleton = () => {
  return (
    <React.Fragment>
      <CardHeader
        avatar={<Skeleton variant="circle" width={40} height={40} />}
        action={null}
        title={<Skeleton height={10} width="80%" style={{ marginBottom: 6 }} />}
        subheader={<Skeleton height={10} width="40%" />}
      />
      <CardHeader
        avatar={<Skeleton variant="circle" width={40} height={40} />}
        action={null}
        title={<Skeleton height={10} width="80%" style={{ marginBottom: 6 }} />}
        subheader={<Skeleton height={10} width="40%" />}
      />
      <CardHeader
        avatar={<Skeleton variant="circle" width={40} height={40} />}
        action={null}
        title={<Skeleton height={10} width="80%" style={{ marginBottom: 6 }} />}
        subheader={<Skeleton height={10} width="40%" />}
      />
      <CardHeader
        avatar={<Skeleton variant="circle" width={40} height={40} />}
        action={null}
        title={<Skeleton height={10} width="80%" style={{ marginBottom: 6 }} />}
        subheader={<Skeleton height={10} width="40%" />}
      />
    </React.Fragment>
  );
};

export default UserItemSkeleton;
