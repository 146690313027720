import { configureStore, combineReducers } from 'redux-starter-kit';
import user from 'maua-redux-core/reducers/user';
import category from 'maua-redux-core/reducers/category';
import location from './reducers/location';
import modal from './reducers/modal';
import loading from './reducers/loading';
// import { history } from '../routes';
// import { destroyCredentials } from '../shared/services/token';
import CoreReducers from 'maua-redux-core/reducers';
import currency from './reducers/currency';
import ordersSlice from './reducers/orders';
import coordinate from './reducers/coordinate';
import mystoresSlice from './reducers/mystores';
import walletsSlice from './reducers/wallets';
import productsSlice from './reducers/products';
import messengerChatSlice from './reducers/messengerChat';

const appReducer = combineReducers({
  user,
  modal,
  loading,
  location,
  category,
  currency,
  coordinate,
  ...CoreReducers,
  orders: ordersSlice,
  mystores: mystoresSlice,
  wallets: walletsSlice,
  products: productsSlice,
  messengerChat: messengerChatSlice
});

const rootReducer = (state, action) => {
  if (action.type === 'user/logout') {
    state = undefined;
  }
  return appReducer(state, action);
};

export const store = configureStore({
  reducer: rootReducer,
  devTools: process.env.NODE_ENV !== 'production'
});
