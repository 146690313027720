import React, { useState, useEffect, useMemo } from 'react';
import Grid from '@material-ui/core/Grid';
import useStyles from '../style';
import { Box } from '@material-ui/core';
import AddIcon from '@material-ui/icons/Add';
import { useTranslation } from 'react-i18next';
import Variant from './Variant';

const VariantOptions = props => {
  const { t } = useTranslation();
  const mainClass = useStyles();

  const handleAddVariant = () => {
    props.setVariants(variants => [
      ...variants,
      {
        key: `${Math.random()}-${new Date().getTime()}+${new Date().getTime()}`,
        name: '',
        options: [
          {
            key: `${Math.random()}-${new Date().getTime()}`,
            name: '',
            price: '0',
            status: 'active'
          }
        ],
        isMultipleChoice: false,
        mandatory: false
      }
    ]);
  };

  return (
    <Grid item container xs={12} className={mainClass.wrapTab}>
      <Box className={mainClass.fieldContainer}>
        <Grid
          item
          container
          xs={12}
          className={mainClass.variantsWrap}
          display="flex"
          justify="space-between">
          <Box
            display="inline-block"
            disabled={
              props.variants.length >= 3 ||
              !props.variants.every(variant => variant.name)
            }
            className={mainClass.addMoreVariant}
            onClick={handleAddVariant}
            component="button"
            type="button">
            <AddIcon />
            <span>{t('action.Add variant (maximum 3)')}</span>
          </Box>
        </Grid>
        <Grid
          item
          xs={12}
          style={{
            overflowX: 'hidden',
            overflowY: 'auto'
          }}>
          {props.variants?.map((variant, index) => (
            <Variant
              key={index}
              index={index}
              variant={variant}
              setVariants={props.setVariants}
            />
          ))}
        </Grid>
      </Box>
    </Grid>
  );
};

export default VariantOptions;
