import makeStyles from '@material-ui/styles/makeStyles';
import { isEmpty } from 'lodash';
import { primaryFont, textColor } from '../../../shared/theme';

const useStyles = makeStyles(theme => ({
  rootDateRangePicker: {
    width: props => props.width,
    position: 'relative'
  },

  inputDate: {
    width: props => props.width,
    height: props => props.height,
    position: 'absolute',
    margin: 0,
    background: theme.palette.color.white,
    border: `1px solid ${theme.palette.color.gray}`,
    display: 'flex',
    alignItems: 'center',
    borderRadius: theme.spacing(0.4),
    padding: theme.spacing(0, 1.4),

    '& svg': {
      width: theme.spacing(1.5),
      height: theme.spacing(1.5),
      margin: theme.spacing(0, 0.5)
    }
  },

  startDate: {
    color: props => (props?.startDate ? '#2F2E2E' : 'rgba(0, 0, 0, 0.54)')
  },

  endDate: {
    color: props => (props?.endDate ? '#2F2E2E' : 'rgba(0, 0, 0, 0.54)')
  },

  dateRangePicker: {
    width: props => props.width,
    position: 'relative',
    height: props => props.height,
    margin: 0,
    background: theme.palette.color.white,
    border: `1px solid ${theme.palette.color.gray}`,
    display: 'flex',
    alignItems: 'center',
    borderRadius: theme.spacing(0.4),

    '&:focus-within': {
      '& h6': {
        display: 'none'
      }
    },

    '& .DateRangePicker': {
      width: '100%',
      borderRadius: theme.spacing(0.8),
      background: 'transparent'
    },

    '& input': {
      fontSize: '16px',
      fontWeight: 300,
      letterSpacing: 0.1,
      fontFamily: primaryFont
    },

    '& .DayPicker': {
      position: 'absolute',
      background: '#fff',
      right: 0,
      zIndex: 1000,
      [theme.breakpoints.down('md')]: {
        left: -20,
        right: 'unset'
      }
    },

    '& .DateInput': {
      width: theme.spacing(8)
    },

    '& .DateRangePickerInput__withBorder': {
      padding: theme.spacing(0, 1.4),
      height: props => props.height,
      background: 'transparent',
      boxSizing: 'border-box',
      border: 'none',
      display: 'flex',
      alignItems: 'center'
    },

    '& .CalendarDay__default': {
      width: '35px !important',
      height: '35px !important',
      borderRadius: theme.spacing(0.8)
    },

    '& .DateRangePickerInput_arrow': {
      margin: theme.spacing(0, 0.5),
      alignItems: 'center',
      height: theme.spacing(2)
    },

    '& .DateRangePicker_picker': {
      position: 'absolute',
      right: '0px !important',
      top: `calc(100% + 15px) !important`,
      zIndex: 1000
    },

    '& .CalendarMonth_table ': {
      borderCollapse: 'unset',
      borderSpacing: `${theme.spacing(0.4)}px`
    },

    '& .CalendarMonth_caption': {
      padding: theme.spacing(2, 0, 5),
      fontSize: 16,
      fontWeight: '600',
      fontFamily: 'Helvetica',
      color: textColor.dark,
      '& strong': {
        fontWeight: 500
      }
    },

    '& .DayPicker_weekHeader': {
      padding: '0 16px !important',

      '&:first-child': {
        padding: '0 12px !important'
      }
    },

    '& .DateInput_input__focused': {
      border: '1px solid',
      borderColor: 'transparent',
      background: 'unset'
    },

    '& .DateInput_input': {
      border: 'unset',
      padding: 0,
      lineHeight: '23px'
    },

    '& .SingleDatePickerInput_clearDate': {
      display: 'none'
    },

    '& .CalendarDay__selected': {
      color: 'white',
      background: theme.palette.primary.main,
      border: `1px solid ${theme.palette.primary.main}`
    },

    '& .CalendarDay__selected_span': {
      color: 'white',
      background: theme.palette.color.gray2,
      border: `1px solid ${theme.palette.color.gray2}`
    },

    '& .CalendarDay__hovered_span': {
      color: 'white',
      background: theme.palette.color.gray2,
      border: `1px solid ${theme.palette.color.gray2}`
    },

    '& .DateRangePickerInput_clearDates': {
      width: 15,
      height: 15,
      marginLeft: 'auto',
      outline: 'none',
      position: 'relative',
      top: 0,
      padding: 0,
      margin: 0,
      transform: 'unset',
      font: 'unset',
      '&:hover': {
        background: 'transparent',
        '& svg': {
          fill: theme.palette.color.black2
        }
      }
    },

    '& .MuiSvgIcon-root': {
      color: theme.palette.color.gray4,
      width: theme.spacing(2),
      height: theme.spacing(2)
    },

    '& .DayPickerNavigation__horizontal': {
      display: 'flex',
      padding: theme.spacing(0, 3),
      justifyContent: 'space-between'
    },

    '& .DayPickerNavigation_button': {
      margin: theme.spacing(2, 0, 0, 0)
    },

    '& .DateRangePickerInput_clearDates_svg': {
      width: 12,
      height: 15,
      verticalAlign: 'unset'
    },

    '& .DateInput_fang': {
      top: `calc(100% + 16px) !important`,
      zIndex: 1001
    },

    '& .DateRangePickerInput_calendarIcon': {
      flex: 1,
      textAlign: 'right',
      top: 0,
      font: 'unset',
      width: 20,
      height: 20,
      margin: '0',
      outline: 'none',
      padding: '0',
      position: 'relative',
      transform: 'unset',
      marginLeft: 'auto',

      '& svg': {
        width: 20,
        height: 20
      }
    }
  },

  clearBtn: {
    right: 14,
    top: '50%',
    position: 'absolute',
    transform: 'translate(0, -50%)',
    display: props => (props?.startDate || props?.endDate ? 'block' : 'none'),
    cursor: 'pointer',
    fill: theme.palette.color.gray3,

    '& svg': {
      width: theme.spacing(2),
      height: theme.spacing(2)
    },

    '&:hover': {
      borderRadius: theme.spacing(2),
      background: theme.palette.color.gray,
      fill: theme.palette.color.gray4
    }
  }
}));

export default useStyles;
