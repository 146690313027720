import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles(theme => ({
  rootTextField: {
    position: 'relative',
    '& label': {
      fontSize: 14
    }
  },
  root: {
    '&:hover fieldset': {
      border: `1px solid ${theme.palette.color.gray} !important`
    },

    '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
      border: '1px solid #E38D03 !important'
    },
    '&.MuiInput-underline:hover:not(.Mui-disabled):before': {
      borderBottom: `1px solid rgba(0, 0, 0, 0.42)`
    },
    '&.MuiInput-underline:after': {
      borderBottom: `1px solid #E38D03 !important`
    },

    '& label.Mui-focused': {
      color: 'orange'
    },
    '& .MuiFormLabel-root': {
      color: 'blue'
    },
    '& > lable.MuiInputLabel-root': {
      fontSize: '12px'
    }
  },

  positionStart: {
    margin: 0
  },
  notchedOutline: {
    border: `1px solid #ccc`,
    outline: 0
  },
  input: {
    background: 'white',
    border: 'none',
    fontSize: '16px',
    paddingRight: 0,
    fontWeight: 500,
    '&::placeholder': {
      fontSize: '14px'
    }
  },

  notchedOutlineFocus: {
    borderWidth: 1
  },
  formControl: {
    fontSize: 12
  }
}));

export default useStyles;
