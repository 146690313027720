import React from 'react';
import Button from '@material-ui/core/Button';
import ResetIcon from '@material-ui/icons/Refresh';
import makeStyles from '@material-ui/styles/makeStyles';

const useStyle = makeStyles(theme => ({
  iconButton: {
    background: '#fff',
    border: '1px solid #ccc',
    boxShadow: 'none',
    color: '#8b90a0',
    textTransform: 'initial',
    width: 44,
    minWidth: 44,
    height: 44,
    padding: 0,
    '&:hover': {
      background: '#fff'
    }
  }
}));

const BtnClearFilter = props => {
  const { style, handleClearFilter } = props;
  const classes = useStyle();

  return (
    <Button
      className={classes.iconButton}
      style={style}
      onClick={handleClearFilter}
      variant="contained">
      <ResetIcon />
    </Button>
  );
};
export default BtnClearFilter;
