import React, { useRef, useEffect, useState } from 'react';
import PropTypes from 'prop-types';

const LoadMore = props => {
  const myRef = useRef(null);
  const {
    tolPosition,
    outsideclass,
    loadFollowTop,
    loadFollowBottom,
    handleLoadMore
  } = props;
  const classWrapp = (outsideclass && outsideclass.rootLoadMore) || {};

  const scrollToPosition = () => {
    myRef.current.children[tolPosition] &&
      myRef.current.children[tolPosition].scrollIntoView();
  };

  const handleScroll = () => {
    const scrollHeight = Math.ceil(myRef.current.scrollHeight);
    const scrollTop = Math.ceil(myRef.current.scrollTop);
    const clientHeight = Math.ceil(myRef.current.clientHeight);

    if (
      (scrollTop === 0 && loadFollowTop) ||
      (loadFollowBottom && scrollTop + clientHeight >= scrollHeight)
    ) {
      handleLoadMore();
    }
  };

  useEffect(() => {
    if (tolPosition) scrollToPosition(tolPosition);
  }, [myRef?.current?.children[tolPosition]]);

  return (
    <div className={classWrapp} onScroll={handleScroll} ref={myRef}>
      {props.children}
    </div>
  );
};

LoadMore.propTypes = {
  handleLoadMore: PropTypes.func.isRequired,
  children: PropTypes.node.isRequired
};

export default LoadMore;
