import { captureException } from '@sentry/browser';
import firebase from 'firebase/app';
import 'firebase/messaging';
import 'firebase/firestore';
import 'firebase/auth';
import { checkLocation } from './utils/checkLocation';
// const apiKeyFireBase = "6Leup8IUAAAAAO51WKjncJWuf3W9owlo9efa0xAS";//process.env.REACT_APP_ENVIRONMENT !== 'production' ? 'AIzaSyCPsToIeqLzpY24mWbsqQwk328Uztu_XyA' :

export function initializeFirebase() {
  firebase.initializeApp({
    apiKey: 'AIzaSyCPsToIeqLzpY24mWbsqQwk328Uztu_XyA',
    authDomain: 'maua-staging.firebaseapp.com',
    databaseURL: 'https://maua-staging.firebaseio.com',
    projectId: 'maua-staging',
    storageBucket: 'maua-staging.appspot.com',
    messagingSenderId: '526724798248',
    appId: '1:526724798248:web:c4184fb7c97f3952'
  });
}

try {
  if (!firebase.apps.length) {
    initializeFirebase();
  }
} catch (error) {
  console.error(error);
}

export async function requestNotificationPermission() {
  try {
    if (!('Notification' in window)) return null;

    const messaging = firebase.messaging();

    // Let's check whether notification permissions have alredy been granted
    if (Notification.permission === 'granted') {
      // If it's okay let's create a notification
      return await messaging.getToken();
    }

    // Otherwise, we need to ask the user for permission
    else if (
      Notification.permission !== 'denied' ||
      Notification.permission === 'default'
    ) {
      const permission = await Notification.requestPermission();
      if (permission === 'granted') {
        return await messaging.getToken();
      } else {
        return null;
      }
    }
  } catch (error) {
    captureException(error);
  }

  return null;
}

export async function destroyToken() {
  try {
    const messaging = firebase.messaging();
    const token = await messaging.getToken();
    await messaging.deleteToken(token);
  } catch (e) {
    captureException(e);
  }
}

const firestore = firebase.firestore();
firestore.settings({ timestampsInSnapshots: true });

export const importStoreMenuCollection = firestore
  .collection('db')
  .doc(checkLocation().firebaseDoc)
  .collection('importStoreMenu');

export { firebase };
