/**********************************************************
 * Login Page includes 5 screen:
 * - Screen request Login with Phone: components/Login/index.js
 * - Screen Enter Phone: components/Login/EnterPhone
 * - Screen Enter OTP: components/Login/EnterOTP
 * - Screen SelectBusiness: pages/SelectBusinessPage
 * - Screen AddBusiness: pages/CompleteProfile
 *
 ***********************************************************/
import React, { useEffect } from 'react';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';

import { getCredentials, getUserProfile } from 'maua-redux-core/selectors/user';

import useStyle from './style';
import Login from '../../components/Login';
import {
  saveCredentials,
  getCredentials as getCredentialToken
} from '../../shared/services/token';
import { isEmptyObject } from '../../utils/common';
// import { mauaApp } from '../../utils/helper';

//init Account kit
// let timer = setInterval(() => {
//   if (window.AccountKit && window.AccountKit.init) {
//     window.AccountKit.init(mauaApp);
//     clearInterval(timer);
//   }
// }, 10);

const LoginPage = ({ credentials, profile, ...otherProps }) => {
  const classess = useStyle();

  useEffect(() => {
    if (
      isEmptyObject(credentials) &&
      !getCredentialToken() &&
      isEmptyObject(profile)
    ) {
      saveCredentials(credentials);
    }
  }, [credentials]);

  useEffect(() => {
    if (isEmptyObject(profile)) {
      otherProps.history.push('/select-business');
      return;
    }
  }, [profile]);

  return (
    <div className={classess.container}>
      <div className={classess.inner}>
        <Login />
      </div>
    </div>
  );
};

const mapStateToProps = state => {
  return {
    profile: getUserProfile(state),
    credentials: getCredentials(state)
  };
};

export default compose(
  withRouter,
  connect(mapStateToProps)
)(LoginPage);
