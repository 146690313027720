import { makeStyles } from '@material-ui/core';
import { unset } from 'lodash';

const useStyles = makeStyles(theme => ({
  root: {
    margin: '0px auto 20px',
    boxShadow: '0px 0px 8px rgba(0, 0, 0, 0.1)',
    justifyContent: 'space-between'
  },
  filterItem: {
    width: 165,
    margin: '0 14px 14px 0',
    height: 46,
    alignItems: 'center',
    justifyContent: 'space-between',
    position: 'relative'
  },

  datePicker: {
    width: 'fit-content'
  },

  filterAction: {
    display: 'flex',
    width: '15%',
    // marginTop: 16,
    marginLeft: 'auto',
    justifyContent: 'space-around'
  },
  btn: {
    background: theme.palette.color.orange,
    height: '100%',
    borderRadius: 5
  },
  'btn--clear': {
    border: 'none',
    background: 'none',
    '&:hover': {
      background: '#eae9e9'
    }
  },
  'btn--primary': {
    color: 'white'
  },
  rootSelect: {
    position: 'relative'
  },
  dropDown: {
    position: 'absolute',
    top: 0
  },
  select: {
    position: 'relative',
    display: 'flex',
    width: '100%',
    padding: '8px 0',
    height: '100%',
    lineHeight: '3',
    overflow: 'hidden',
    '& select': {
      borderRadius: '.25em',
      border: '1px solid #ccc',
      outline: '0',
      flex: '1',
      top: '100%',
      color: 'black',
      cursor: 'pointer'
    }
  },
  time: {
    position: 'relative'
  },
  iconDate: {
    height: '51px',
    border: '1px solid #b5b3b3',
    borderRadius: '5px'
    // margin: '8px 0'
  },
  content: {
    width: 200,
    position: 'absolute',
    zIndex: theme.zIndex.drawer,
    padding: theme.spacing(1.5)
  },
  selectBox: {
    width: '100%',
    height: '100%',
    border: 'solid 1px #c3c3c3',
    borderRadius: '5px',
    padding: '14px',
    outline: '#E38D03'
  },
  paper: {
    padding: '14px 40px 16px',
    maxWidth: '1200px',
    margin: 'auto'
  },
  boxFilter: {
    justifyContent: 'flex-start'
  },
  selectFiled: {
    width: '100%',
    height: '46px',
    padding: '0 8px 0 14px',
    border: '1px solid #cccccc',
    position: 'relative',
    borderRadius: '5px',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    cursor: 'pointer',
    margin: '16px 0px',
    background: 'transparent'
  },
  dropDownSelect: {
    height: 'auto',
    left: '0',
    position: 'absolute',
    width: '100%',
    zIndex: 10000,
    top: '100%',
    margin: '4px 0',
    boxShadow: '0px 0px 15px gray',
    border: '1px solid black'
  }
}));

export default useStyles;
