import React from 'react';
import { createBrowserHistory } from 'history';
import { Route, Router, Switch } from 'react-router-dom';
import SelectBusinessPage from '../pages/SelectBusinessPage';
import LoginFireBase from '../pages/LoginPage/LoginFireBase';
import ImportProductPage from '../pages/ImportProductPage';
import CompleteProfile from '../pages/CompleteProfile';
import PageNotFound from '../pages/PageNotFound';
import ThankyouPage from '../pages/ThankyouPage';
import Layout from '../components/layout/main';
import ProductPage from '../pages/ProductPage';
import Transaction from '../pages/Transaction';
import OrdersPage from '../pages/OrdersPage';
import Dashboard from '../pages/Dashboard';
import LoginPage from '../pages/LoginPage';
import PrivateRoute from './PrivateRoute';
import Mystore from '../pages/Mystore';

export const history = createBrowserHistory();

export const Routes = (
  <Router history={history}>
    <Layout history={history}>
      <Switch>
        <PrivateRoute path="/thankyou-page" component={ThankyouPage} />
        <PrivateRoute path="/complete-profile" component={CompleteProfile} />
        <PrivateRoute path="/orders/:orderType" component={OrdersPage} />
        <PrivateRoute path="/myStore" component={Mystore} />
        <PrivateRoute path="/dashboard" component={Dashboard} />
        <PrivateRoute path="/transaction" component={Transaction} />
        <PrivateRoute path="/select-business" component={SelectBusinessPage} />
        <PrivateRoute exact path="/products" component={ProductPage} />
        <PrivateRoute
          exact
          path="/products/import"
          component={ImportProductPage}
        />
        <Route exact path="/" component={LoginPage} />
        <Route exact path="/enter-phone-number" component={LoginFireBase} />
        <Route component={PageNotFound} />
      </Switch>
    </Layout>
  </Router>
);
