import { useMediaQuery, useTheme } from '@material-ui/core';

export const useIsMobile = () => {
  const theme = useTheme();
  return useMediaQuery(theme.breakpoints.down('md'));
};

export const useIsXs = () => {
  const theme = useTheme();
  return useMediaQuery(theme.breakpoints.only('xs'));
};
