import React, { useState, useEffect } from 'react';
import { isEmpty } from 'lodash';
import { connect } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import Container from '@material-ui/core/Container';
import Box from '@material-ui/core/Box';
import { fetchImportProducts } from 'maua-redux-core/actions/importProduct';
import { getUserProfile } from 'maua-redux-core/selectors/user';
import { toastMessage, TOAST_TYPE } from '../../utils/toastify-notify';
import { queryImportProducts } from '../../store/query/importProducts';
import { actSetLoading } from '../../store/actions/loading';
import { downloadFile } from '../../utils/common';
import FilterComponent from './Filter';
import TableList from './TableList';
import useStyle from './style';

const ImportProductPage = props => {
  const classes = useStyle();
  const location = useLocation();
  const { t } = useTranslation();

  const { profile, fetchImportProducts, setLoading } = props;

  const storeID = props.profile?.store?._id;

  const [filter, setFilter] = useState({
    name: '',
    from: null,
    to: null,
    sortBy: '-uploadAt'
  });

  const [data, setData] = useState({
    docs: [],
    limit: 0,
    page: 0,
    pages: 1,
    total: 0
  });

  useEffect(() => {
    if (!profile?._id) return;

    loadImportProducts();
  }, [profile, filter]);

  const loadImportProducts = async (page = 0) => {
    try {
      setLoading({ status: true });

      let payload = {
        ...filter,
        name: filter.name?.length < 3 ? '' : filter.name
      };

      payload = Object.keys(payload).reduce((result, key) => {
        return !isEmpty(payload[key])
          ? { ...result, [key]: payload[key] }
          : result;
      }, {});

      payload.page = page + 1 || 1;

      payload.limit = data?.limit || 20;

      const resp = await fetchImportProducts(payload);

      setData({
        docs: resp?.docs,
        limit: resp?.limit,
        page: resp?.page,
        pages: resp?.pages,
        total: resp?.total
      });
    } catch (error) {
      toastMessage(TOAST_TYPE.ERROR, error.message);
    } finally {
      setLoading({ status: false });
    }
  };

  const handleDownloadFile = location => () => {
    setLoading({ status: true });

    try {
      downloadFile(location);

      toastMessage(
        TOAST_TYPE.SUCCESS,
        `${t('toastMessage.success.content.Download file successfully')}`
      );
    } catch (error) {
      toastMessage(TOAST_TYPE.ERROR, error.message);
    } finally {
      setLoading({ status: false });
    }
  };

  return (
    <Box className={classes.root}>
      <FilterComponent
        filter={filter}
        storeID={storeID}
        setFilter={setFilter}
        setLoading={setLoading}
        statusMenu={location.state}
        loadImportProducts={loadImportProducts}
      />

      <Container maxWidth="xl">
        <Box className={classes.container}>
          <TableList
            loadImportProducts={loadImportProducts}
            data={data}
            handleDownloadFile={handleDownloadFile}
          />
        </Box>
      </Container>
    </Box>
  );
};

export default connect(
  state => ({
    profile: getUserProfile(state)
  }),
  dispatch => ({
    setLoading: status => dispatch(actSetLoading(status)),
    fetchImportProducts: payload =>
      dispatch(fetchImportProducts(payload, queryImportProducts))
  })
)(ImportProductPage);
