import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { getSettingByKey } from 'maua-redux-core/selectors/setting';
import { fetchSettingByKey } from 'maua-redux-core/actions/setting';
import { getUserProfile } from 'maua-redux-core/selectors/user';
import { logout } from 'maua-redux-core/actions/user';
import { actToggleDetailOrderItem } from '../../../store/actions/orders';
import { getOpenDetailOrderItem } from '../../../store/selectors/orders';
import { destroyCredentials } from '../../../shared/services/token';
import { actDestroyProfile } from '../../../store/actions/user';
import { MAUA_PHONE } from '../../../shared/constant/const';
import { history } from '../../../routes';
import { useIsMobile } from 'src/utils/hook';
import DesktopHeader from './DesktopHeader';
import MobileHeader from './MobileHeader';

function Header({
  onDestroyProfile,
  onLogout,
  profile,
  hotline,
  onFetchSetting,
  showDetailOrderItem,
  handleShowDetailOrderItem,
  ...otherProps
}) {
  const [open, setOpen] = useState(false);
  const [openSwitchBusiness, setOpenSwitchBusiness] = useState(false);
  const [routerActive, setrouterActive] = useState('orders/newOrders');
  const isMobile = useIsMobile();

  useEffect(() => {
    if (routerActive !== location.pathname) {
      setrouterActive(location.pathname);
    }
  }, [routerActive]);

  const handleOpenMenu = type => {
    if (routerActive !== type) {
      const { location } = otherProps;
      const isScreenProfileOrder = location.pathname.includes('/profile/order');

      if (
        (type !== 'orders/newOrders' && showDetailOrderItem) ||
        (isScreenProfileOrder &&
          type === 'orders/newOrders' &&
          showDetailOrderItem)
      ) {
        handleShowDetailOrderItem(false);
      }
      otherProps.history.push(`/${type}`);
      setrouterActive(type);
    }
  };

  const { location } = otherProps;
  const isHasHeader =
    location &&
    (location.pathname !== '/' &&
      location.pathname !== '/complete-profile' &&
      location.pathname !== '/thankyou-page' &&
      location.pathname !== '/enter-otp' &&
      location.pathname !== '/enter-phone-number' &&
      location.pathname !== '/select-business');

  const handleLogOut = () => {
    onDestroyProfile();
    onLogout();
    destroyCredentials();
    setOpen(false);
    history.replace('/');
  };

  const handleRedicrectHome = () => {
    window.location.href = `${window.location.origin}/orders/newOrders`;
  };

  const handleCall = () => {
    if (hotline !== '') {
      window.location.href = `tel:${hotline}`;
    }
  };

  const headerProps = {
    profile,
    hotline,
    handleOpenMenu,
    handleLogOut,
    open,
    setOpen,
    openSwitchBusiness,
    setOpenSwitchBusiness,
    handleCall,
    location,
    handleRedicrectHome
  };

  const HeaderComponent = !isMobile ? DesktopHeader : MobileHeader;
  return isHasHeader && <HeaderComponent {...headerProps} />;
}

const mapStateToProps = state => {
  return {
    profile: getUserProfile(state),
    hotline: getSettingByKey(state, { key: MAUA_PHONE }),
    showDetailOrderItem: getOpenDetailOrderItem(state)
  };
};

const mapDispatchToProps = dispatch => {
  return {
    onLogout: () => {
      dispatch(logout(null, null));
    },
    onDestroyProfile: () => {
      dispatch(actDestroyProfile());
    },
    onFetchSetting: params => {
      dispatch(fetchSettingByKey(params, null));
    },
    handleShowDetailOrderItem: value =>
      dispatch(actToggleDetailOrderItem(value))
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Header);
