import { makeStyles } from '@material-ui/styles';

export const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    border: `1px dashed transparent`,
    borderRadius: '10px',
    height: 124,
    width: '100%',
    position: 'relative',
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'cover',
    backgroundPosition: 'center',
    transition: 'all .35s',
    '&:hover': {
      boxShadow: `0px 4px 8px rgba(0, 0, 0, 0.1)`
    }
  },
  removeBtn: {
    position: 'absolute',
    bottom: -20,
    right: -20,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    width: 44,
    height: 44,
    borderRadius: '50%',
    backgroundColor: theme.palette.color.white,
    boxShadow: `0px 4px 8px rgba(0, 0, 0, 0.1)`,
    cursor: 'pointer',
    transition: 'all .35s',
    '&:hover': {
      backgroundColor: theme.palette.color.orange,
      '& svg': {
        fill: theme.palette.color.white,
        fontSize: 20
      }
    }
  },
  removeIcon: {
    color: theme.palette.color.orange,
    fontSize: 15
  }
}));
