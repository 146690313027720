import React from 'react';
import Dialog from '@material-ui/core/Dialog';
import ErrorIcon from '@material-ui/icons/Error';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Button from '../../components/Button';
import { useTranslation } from 'react-i18next';

import useStyle from './style';

const AlertDialog = React.memo(props => {
  const { open, handleClose, handleConfirm, textTileDialog } = props;
  const mainClass = useStyle();
  const { t } = useTranslation();

  return (
    <React.Fragment>
      <Dialog
        maxWidth="xs"
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        PaperProps={{
          classes: { root: mainClass.rootDialog }
        }}>
        <div className={mainClass.wrappError}>
          <ErrorIcon className={mainClass.iconError} />
        </div>
        <DialogTitle
          id="alert-dialog-title"
          classes={{ root: mainClass.title }}>
          {t('alertDialog.confirm.title.Are you sure ?')}
        </DialogTitle>
        <DialogContent>
          {textTileDialog ? (
            <DialogContentText
              id="alert-dialog-description"
              classes={{ root: mainClass.textDesc }}>
              {textTileDialog}
            </DialogContentText>
          ) : null}
        </DialogContent>
        <DialogActions classes={{ root: mainClass.wrappBtn }}>
          <Button onClick={handleClose} typeBtn="gray">
            {/* Cancel */}
            {t('action.Cancel')}
          </Button>
          <Button onClick={handleConfirm} typeBtn="yellow" autoFocus>
            {t('action.OK')}
          </Button>
        </DialogActions>
      </Dialog>
    </React.Fragment>
  );
});

export default AlertDialog;
