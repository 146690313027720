import React, { useRef, useState } from 'react';
import moment from 'moment';
import { cloneDeep } from 'lodash';
import { connect } from 'react-redux';
import { useTranslation } from 'react-i18next';

import Box from '@material-ui/core/Box';
import Container from '@material-ui/core/Container';
import SelectFilter from '../../../components/FieldWithoutFormik/Select';
import DatePicker from '../../../components/FieldWithoutFormik/DatePicker';
import TextFieldCustom from '../../../components/FieldWithoutFormik/TextField';
import BtnClearFilter from '../../../components/FieldWithoutFormik/ClearFilter';
import ExportIcon from '../../../assets/img/export-icon.svg';

import useStyle from './style';
import DateRangePickerUI from '../../../components/FieldWithoutFormik/DateRangePicker';

const OPTIONS_TRANSACTION_STATUS = [
  { label: 'All', value: undefined },
  { label: 'New', value: 'new' },
  { label: 'Waiting', value: 'waiting' },
  { label: 'Paid', value: 'paid' },
  { label: 'Failed', value: 'failed' },
  { label: 'Refunded', value: 'refunded' },
  { label: 'Pending payment', value: 'pending-payment' },
  { label: 'Pending refund', value: 'pending-refund' },
  { label: 'Request refund', value: 'request-refund' }
];

const OPTIONS_PAYMENT_METHOD = [
  { label: 'All', value: undefined },
  { value: 'digicel', label: 'Digicel' },
  { value: 'vodafone', label: 'Vodafone' },
  { value: 'cash', label: 'Cash' },
  { value: 'MauaPay', label: 'Maua Pay' },
  { value: 'bank-transfer', label: 'Bank Transfer' }
];

const Filter = props => {
  const { filter, setFilter, handleExport } = props;

  const [filterForm, setFilterForm] = useState(cloneDeep(filter));

  const timeout = useRef(null);

  const { t } = useTranslation();

  const classes = useStyle();

  const handleChangeInput = e => {
    const { name, value } = e?.target;

    setFilterForm(prevData => ({
      ...prevData,
      [name]: value
    }));

    timeout.current && clearTimeout(timeout.current);

    timeout.current = setTimeout(() => {
      setFilter(prevData => ({
        ...prevData,
        [name]: value
      }));
    }, 800);
  };

  const handleChangeSelect = (e, type) => {
    const value = e?.value;

    setFilterForm(prevData => ({
      ...prevData,
      [type]: e
    }));

    timeout.current && clearTimeout(timeout.current);

    timeout.current = setTimeout(() => {
      setFilter(prevData => ({
        ...prevData,
        [type]: value
      }));
    }, 800);
  };

  const handleDateChange = ({ startDate, endDate }) => {
    setFilterForm(prevData => {
      return {
        ...prevData,
        from: (startDate && startDate.startOf('day')) || null,
        to: (endDate && endDate.endOf('day')) || null
      };
    });

    if (timeout.current) clearTimeout(timeout.current);

    timeout.current = setTimeout(() => {
      setFilter(prevData => ({
        ...prevData,
        from: (startDate && startDate.startOf('day')?.toISOString()) || null,
        to: (endDate && endDate.endOf('day')?.toISOString()) || null
      }));
    }, 1000);
  };

  const handleClearFilter = () => {
    setFilterForm({
      of: '',
      type: 'vendor payment',
      paymentMethod: '',
      status: '',
      code: '', //transaction code is ID field on UI
      orderCode: '',
      from: null,
      to: null
    });

    timeout.current && clearTimeout(timeout.current);

    timeout.current = setTimeout(() => {
      setFilter({
        of: '',
        type: 'vendor payment',
        paymentMethod: '',
        status: '',
        code: '', //transaction code is ID field on UI
        orderCode: '',
        from: null,
        to: null
      });
    }, 800);
  };

  return (
    <Box className={classes.header}>
      <Container maxWidth="xl">
        <Box className={classes.contentHeader}>
          <Box mr={0.8} mb={0.8}>
            <TextFieldCustom
              variant="outlined"
              id="margin-none"
              name="code"
              width={135}
              value={filterForm.code}
              onChange={handleChangeInput}
              label={t('transaction.table.col.Transaction ID')}
            />
          </Box>

          <Box mr={0.8} mb={0.8}>
            <TextFieldCustom
              variant="outlined"
              id="margin-none"
              width={135}
              value={filterForm.orderCode}
              onChange={handleChangeInput}
              name="orderCode"
              label={t('transaction.table.col.Order ID')}
            />
          </Box>

          <Box mr={0.8} mb={0.8}>
            <SelectFilter
              width={170}
              value={filterForm.paymentMethod}
              label={t('transaction.table.col.Payment Method')}
              onChange={e => handleChangeSelect(e, 'paymentMethod')}
              options={OPTIONS_PAYMENT_METHOD}
              zIndex={1}
            />
          </Box>

          <Box mr={0.8} mb={0.8}>
            <SelectFilter
              width={140}
              value={filterForm.status}
              label={t('transaction.table.col.Status')}
              onChange={e => handleChangeSelect(e, 'status')}
              options={OPTIONS_TRANSACTION_STATUS}
              zIndex={1}
            />
          </Box>

          <Box mr={0.8} mb={0.8}>
            <DateRangePickerUI
              width={250}
              endlabel={t('order.filter.label.To')}
              startlabel={t('order.filter.label.From')}
              height={46}
              maxDate={new Date()}
              startDate={filterForm.from}
              endDate={filterForm.to}
              onChange={handleDateChange}
            />
          </Box>

          <Box mr={0.8} mb={0.8}>
            <BtnClearFilter handleClearFilter={handleClearFilter} />
          </Box>

          <Box mr={0.8} mb={0.8}>
            <Box className={classes.btnExport} onClick={handleExport}>
              <img
                src={ExportIcon}
                alt="export"
                style={{ marginRight: 8, width: 20 }}
              />
              <Box>{t(`exportModal.title.Export`)}</Box>
            </Box>
          </Box>
        </Box>
      </Container>
    </Box>
  );
};

const mapStateToProps = state => ({});

const mapDispatchToProps = {};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Filter);
