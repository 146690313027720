import React from 'react';
import { Typography } from '@material-ui/core';

import useStyle from './style';

const Button = React.memo(
  ({ handleClick, typeBtn, className, ...otherProps }) => {
    const mainClass = useStyle();
    // const { handleClick, typeBtn } = props;
    let colorBtn = null;
    switch (typeBtn) {
      case 'yellow':
        colorBtn = mainClass.yellowBtn;
        break;
      case 'green':
        colorBtn = mainClass.greenBtn;
        break;
      case 'gray':
        colorBtn = mainClass.grayBtn;
        break;
      case 'btnFail':
        colorBtn = mainClass.failBtn;
        break;
      default:
        colorBtn = mainClass.whiteBtn;
        break;
    }

    return (
      <Typography
        variant="subtitle2"
        component="button"
        onClick={handleClick}
        className={`${mainClass.btn} ${colorBtn} ${className}`}
        {...otherProps}>
        {otherProps.children}
      </Typography>
    );
  }
);

export default Button;
