import { makeStyles } from '@material-ui/core';
import { secondaryFont, primaryFont } from '../../../shared/theme';

const useStyle = makeStyles(theme => ({
  root: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
    position: 'relative',
    padding: 20,
    backgroundColor: theme.palette.color.white,
    boxShadow: `0px 10px 20px rgba(0, 0, 0, 0.05)`,
    borderRadius: '15px',
    [theme.breakpoints.down('md')]: {
      flexDirection: 'column'
    }
  },
  iconMore: {
    position: 'absolute',
    top: 10,
    right: 10,
    cursor: 'pointer',
    transition: 'all .35s',
    '&:hover': {
      color: theme.palette.color.orange,
      transform: 'scale(1.05)'
    },
    [theme.breakpoints.down('md')]: {
      alignSelf: 'end',
      position: 'initial'
    }
  },
  iconEdit: {
    '&:hover': {
      color: theme.palette.color.orange,
      transform: 'scale(1.05)'
    }
  },
  iconMoreActive: {
    color: theme.palette.color.orange,
    transform: 'scale(1.05)'
  },
  // iconMoreDefault: {
  //   color: theme.palette.color.white1
  // },
  moreOptWrapp: {
    position: 'absolute',
    right: 34,
    top: 58,
    zIndex: 1,
    background: theme.palette.color.white,
    boxShadow: `5px 5px 20px rgba(0, 0, 0, 0.12)`,
    borderRadius: `10px`
  },
  moreOpt: {
    margin: 0,
    padding: '13px 13px 0',
    listStyle: 'none'
  },
  moreOptItem: {
    paddingBottom: 10,
    paddingTop: 10,
    fontSize: 16,
    fontFamily: primaryFont,
    color: theme.palette.color.black2,
    transition: 'all .35s',
    cursor: 'pointer',
    '&:hover': {
      color: theme.palette.color.yellow
    }
  },
  imgBanner: {
    display: 'block',
    width: 465,
    maxHeight: 295,
    borderRadius: 15,
    [theme.breakpoints.only('xs')]: {
      objectFit: 'contain',
      minWidth: 260,
      width: '100%'
    }
  },
  contentBanner: {
    width: 'calc(100% - 465px)',
    paddingLeft: 35,
    [theme.breakpoints.down('md')]: {
      paddingLeft: 0,
      width: '100%'
    }
  },
  listCategory: { marginTop: 30 },
  categoryItem: {
    display: 'inline-block',
    border: `1px solid #E38D03`,
    borderRadius: '20px',
    padding: '5px 10px',
    fontSize: 16,
    fontFamily: primaryFont,
    color: theme.palette.color.black1,
    marginRight: 12,
    marginBottom: 12,
    '&:last-child': {
      marginRight: 0
    }
  },
  title: {
    color: theme.palette.color.black1
  },
  ratingAndOpenTime: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    marginTop: 8
  },
  ratingWrapp: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center'
  },
  ratingVal: {
    fontSize: 18,
    fontFamily: primaryFont,
    color: theme.palette.color.black1
  },
  ratingControl: {
    width: 120,
    marginLeft: 10
  },
  openTime: {
    position: 'relative',
    marginLeft: 10,
    paddingLeft: 10,
    display: 'flex',
    alignItems: 'center'
  },
  openTimeDesc: {
    fontSize: 18,
    fontFamily: primaryFont,
    color: theme.palette.color.green2
  },
  openTimeVal: {
    marginLeft: 10,
    fontSize: 18,
    fontFamily: primaryFont,
    color: theme.palette.color.gray
  },

  closeTime: {
    marginLeft: 10,
    fontSize: 18,
    fontFamily: primaryFont,
    color: theme.palette.color.red
  },

  info: {
    marginTop: 5,
    fontSize: 18,
    fontFamily: primaryFont,
    color: theme.palette.color.black1
  },
  wrappCarousel: {
    position: 'relative',
    width: 465,
    maxHeight: 295,
    borderRadius: 15,
    overflow: 'hidden',
    [theme.breakpoints.down('md')]: {
      width: '100%',
      marginTop: 8
    }
  },
  imgCarousel: {
    display: 'block',
    width: '100%',
    height: '100%',
    maxHeight: 295
    // borderRadius: 15
  },
  descriptionOfVendor: {
    marginTop: 8,
    height: 75,
    overflow: 'auto',
    fontFamily: primaryFont,

    '& p': {
      color: theme.palette.color.black1,
      margin: 0
    }
  }
}));
export default useStyle;
