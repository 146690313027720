import { modalSlice } from '../reducers/modal';

export const actToggleModalLogin = state => {
  return modalSlice.actions.toggleLoginModal({
    state
  });
};

export const actToggleModalChangeRegion = state => {
  return modalSlice.actions.toggleChangeRegionModal({
    state
  });
};

export const actToggleModalPickupConfirm = state => {
  return modalSlice.actions.toggleModalPickupConfirm({
    state
  });
};

export const actToggleModalReason = state => {
  return modalSlice.actions.toggleModalReasonOrder({
    state
  });
};

export const actToggleModalChoiseDelivery = state => {
  return modalSlice.actions.toggleModalChoiseDelivery({
    state
  });
};

export const actToggleModalAddProduct = state => {
  return modalSlice.actions.toggleModalAddProduct({
    state
  });
};

export const actToggleModalEditProduct = state => {
  return modalSlice.actions.toggleModalEditProduct({
    state
  });
};

export const actToggleModalEditYourStore = state => {
  return modalSlice.actions.toggleModalEditYourStore({
    state
  });
};

export const actToggleModalEditUser = state => {
  return modalSlice.actions.toggleModalEditUser({
    state
  });
};

export const actToggleMoadlSwitchBusiness = state => {
  return modalSlice.actions.toggleMoadlSwitchBusiness({
    state
  });
};

export const actToggleModalAddBusiness = state => {
  return modalSlice.actions.toggleModalAddBusiness({
    state
  });
};

export const actToggleModalThankyouAddBusiness = state => {
  return modalSlice.actions.toggleModalThankyouAddBusiness({
    state
  });
};

export const actToggleModalEnterStaffPhone = state => {
  return modalSlice.actions.toggleModalEnterStaffPhone({
    state
  });
};

export const actToggleModalWaitingStaffPhone = state => {
  return modalSlice.actions.toggleModalWaitingStaffPhone({
    state
  });
};

export const actToggleModalStaffNotFound = state => {
  return modalSlice.actions.toggleModalStaffNotFound({
    state
  });
};

export const actToggleModalFoundStaff = state => {
  return modalSlice.actions.toggleModalFoundStaff({
    state
  });
};
