import { makeStyles } from '@material-ui/core';
import { primaryFont } from '../../../shared/theme';

const useStyle = makeStyles(theme => ({
  detail: {},
  title: {
    margin: '30px 40px 17px',
    color: theme.palette.color.black
  },
  walletList: {
    margin: `0px 40px`
  },
  walletItem: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    '&:last-child': {
      marginTop: 25
    }
  },
  walletContent: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    width: 'calc(100% - 42px)',
    height: '65px',
    background: theme.palette.color.white,
    boxShadow: `0px 8px 16px rgba(0, 0, 0, 0.1)`,
    borderRadius: 10,
    padding: `10px 0px 10px 10px`
  },
  walletIcon: {
    width: 55,
    height: 55
  },
  walletInfo: {
    marginLeft: 10,
    width: 'calc(100% - 79px)'
  },
  textField: {
    width: '100%'
  },
  rootLabel: {
    fontSize: 12,
    fontFamily: primaryFont
  },
  inputStyle: {
    color: theme.palette.color.gray1
  },
  labelFocus: {
    color: `${theme.palette.color.gray1} !important`
  },
  rootInput: {
    borderBottom: `1px solid ${theme.palette.color.gray1}`,
    color: theme.palette.color.black1
  },
  focusedInputDigicel: {
    borderBottom: `1px solid ${theme.palette.color.red}`
  },
  focusedInputVodafone: {
    borderBottom: `1px solid ${theme.palette.color.blue}`
  },
  radio: {
    color: theme.palette.color.white1,
    '&:hover': {
      backgroundColor: `#e38d0314`
    }
  },
  checkedRadio: {
    color: `${theme.palette.color.orange} !important`
  },
  wrappBtn: {
    position: 'absolute',
    bottom: 0,
    left: 0,
    right: 0,
    padding: '0 63px 23px'
  },
  thumbAddWallet: {
    width: '400px',
    height: '210px',
    marginLeft: '65px'
  }
}));

export default useStyle;
