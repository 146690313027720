import { init } from '@sentry/browser';
import React from 'react';
import ReactDOM from 'react-dom';
// import * as ReactGA from 'react-ga';
import { Provider } from 'react-redux';
import App from './App';
import './index.css';
import './push-notification';
import * as serviceWorker from './serviceWorker';
import './utils/i18n.js';
import { store } from './store';

// init Sentry
init({
  enabled: process.env.NODE_ENV !== 'development',
  release: process.env.REACT_APP_VERSION,
  environment: process.env.REACT_APP_ENVIRONMENT,
  dsn: process.env.REACT_APP_SENTRY_DSN
});

// function initializeReactGA() {
//   ReactGA.initialize(process.env.REACT_APP_GA);
//   ReactGA.pageview('/');
// }

// if (process.env.NODE_ENV === 'production') {
//   initializeReactGA();
//   history.listen(location => ReactGA.pageview(location.pathname));
// }

ReactDOM.render(
  <Provider store={store}>
    <App />
  </Provider>,
  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
